import Dashboard from './dashboard/dashboard';
import ProjectInfo from './project-info/project-info';
import StandardPage from './standard/standard';
import SiteCondition from './site-condition/site-condition';
import SensorListPage from './sensor/sensor-list';
import DboxListPage from './dbox/dbox-list';
import DboxDetailPage from './dbox/dbox-detail';
import DashboardEvaluatePage from './dashboard/dashboard-evaluate';
import MobjectPage from './mobject/mobject-page';
import QuantityLimitPage from './mobject/quantity-limit-page';
import NodePage from './model/node-page';
import ElementPage from './model/element-page';
import SectionPage from './model/section-page';
import SectionDetail from './model/section-detail';
import MaterialPage from './model/material-page';
import MaterialDetail from './model/material-detail';
import ConstageListPage from './constage/constage-list';
import ConstageDetail from './constage/constage-detail';
import DrawingListPage from './drawing/drawing-list';
import SensorDetailPage from './sensor/sensor-detail';
import SiteManagePage from './sites/site-manage';
import QuantityLinkPage from './quantity-link/quantity-link';
import QuantityQueryPage from './quantity-query/quantity-query';
import ReportPage from './report/report-page';

import SettingQuantityPage from './setting-quantity/setting-quantity';
import SettingProjectPage from './setting-project/setting-project';
import SettingClientPage from './setting-client/setting-client';
import ProjectLogPage from './project-log/project-log';
import ProjectWarningPage from './project-warning/project-warning';

import ProjectListPage from './project-list/project-list';
import GlobalQuantityPage from './global-quantity/global-quantity';
import GlobalSectionPage from './global-section/global-section';
import GlobalMaterialPage from './global-material/global-material';
import GlobalDevicePage from './global-device/global-device';
import GlobalInstallPage from './global-install/global-install';
import GlobalStandardPage from './global-standard/global-standard';
import GlobalPlanbPage from './global-planb/global-planb';
import GlobalEnterprisePage from './global-enterprise/global-enterprise';
import SettingUserPage from './setting-user/setting-user';
import SettingPasswordPane from './setting-user/setting-pwd';

import EnterpriseListPage from './enterprise/enterprise-list';
import BackendUserListPage from './backend-user/backend-users';
import DashboardDark from './dashboard/dashboard-dark';

export default {
    // 项目
    Dashboard,
    DashboardDark,
    ProjectInfo,
    StandardPage,
    SiteCondition,
    SensorListPage,
    DboxListPage,
    DboxDetailPage,
    DashboardEvaluatePage,
    MobjectPage,
    QuantityLimitPage,
    NodePage,
    ElementPage,
    SectionPage,
    SectionDetail,
    MaterialPage,
    MaterialDetail,
    ConstageListPage,
    ConstageDetail,
    DrawingListPage,
    SensorDetailPage,
    SiteManagePage,
    QuantityLinkPage,
    QuantityQueryPage,
    ReportPage,

    SettingQuantityPage,
    SettingProjectPage,
    SettingClientPage,
    ProjectLogPage,
    ProjectWarningPage,

    // 全局库
    SettingUserPage,
    SettingPasswordPane,
    ProjectListPage,
    GlobalQuantityPage,
    GlobalSectionPage,
    GlobalMaterialPage,
    GlobalDevicePage,
    GlobalInstallPage,
    GlobalStandardPage,
    GlobalPlanbPage,
    GlobalEnterprisePage,
    
    // 后台
    EnterpriseListPage,
    BackendUserListPage,
} as any;