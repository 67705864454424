import React, { Component, Fragment } from 'react'
import { Table } from 'antd';
import { Button, Space } from 'antd';
import { quantity, globalQuantity, warningLimit } from '../../interface/quantity';
import { ProjectContext } from '../../config/context';
import axios from '@/config/axios';
import Column from 'antd/lib/table/Column';
import { mobjectQuantity } from '../dashboard-card/evaluate-board';
import AlertTag from '../alert-tag';
import { warningGradeDict, MobjectBaseType } from '@/utils/type';
import './evaluate-main.less';
import { Radio } from 'antd';
import { QuantityName } from '../QuantityName';
import { queryQuantityCurrentDataList } from '@/config/api/quantity-data';
import { Transfer2DisplayScale } from '@/utils/utils';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import { queryDashboardQuantities } from '@/config/api/dashboard';
import { QuantityLevelTypes } from '../mobject/quantity-pattern-setting';
import dayjs from 'dayjs';
import { constage } from '@/interface/constage';
import classnames from 'classnames';
import { Gprops } from '@/config/props';
import { withRouter } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { queryProjectDetail } from '@/config/api/project';
import { project } from '@/interface/project';

const QTtypes = {
    ALL: 0,
    STRUCTURE: 3,
    ELEMENT: 2,
    POINT: 1,
    ENVIRONMENT: 4,
}

interface IProps extends Gprops {
    rowSelected: quantity
    onRowSelectClick: (row: quantity) => void
    onMainBtnClick: () => void
}
interface IState {
    rowSensorSelectedId: number,
    radios: number;

    quantities: quantity[],
    displayQuantities: quantity[],
    evaluationCount: number[],
    loading: boolean,
    monitorType: number,
}


class EvaluateMainBoard extends Component<IProps, IState> {
    static contextType = ProjectContext;
    projectGlobalQuantities: globalQuantity[] = [];

    state = {
        rowSensorSelectedId: null,
        radios: QTtypes.ALL,

        quantities: [],
        displayQuantities: [],
        evaluationCount: [0, 0, 0, 0, 0],
        loading: false,
        monitorType: 2,
    }




    componentDidMount() {
        this.generateDataV2();
    }

    generateData = () => {
        this.setState({
            loading: true
        })
        const projectId = this.context;
        let queryProjectQuantitiesPromise = queryProjectGlobalQuantities(projectId);
        let queryQuantitiesPromise = queryDashboardQuantities({
            project_id: projectId,
            quantity_level: QuantityLevelTypes.OTHER_Q,
            activated: true,
            is_evaluate: true,
        });
        let queryProjectPromise = queryProjectDetail(projectId);
        axios.all([queryProjectQuantitiesPromise, queryQuantitiesPromise, queryProjectPromise])
            .then(res => {
                let projectInfo: project = res[2].data;
                this.setState({
                    monitorType: projectInfo?.monitor_type || 2,
                })
                let pQuantities: globalQuantity[] = res[0].data || [];
                this.projectGlobalQuantities = pQuantities;
                let data: mobjectQuantity[] = res[1].data;
                data = data.filter(item => !!item.mobject);
                let quantities = data.map((item: mobjectQuantity) => {
                    let q = item.quantity;
                    q.mobject = item.mobject;
                    q.mobject_name = item.mobject?.name || '';
                    let constages = item.mobject?.constages || [];
                    constages = constages.sort((a, b) => dayjs(a.start_time).valueOf() - dayjs(b.start_time).valueOf());
                    q.constages = [...constages];

                    let pQuantity = pQuantities.find(p => p.type === q.type);
                    q.unit_type = pQuantity?.unit_type || '';
                    q.chinese_name = pQuantity?.chinese_name || '';
                    q.precision = pQuantity?.precision || '1';
                    // let scale = this.unitmaps[gQuantity?.unit_type][quantity?.unit_type] || 1;
                    let scale = Transfer2DisplayScale(q.unit_type);
                    q.scale = scale;
                    q.valid_data = pQuantity.valid_data;
                    return q;
                });
                quantities = quantities.filter(item => item.is_evaluate) || [];
                this.setState({
                    quantities: quantities,
                })
                let qids = quantities.map(item => item.id);
                let queryQuantityCurrentDataPromise = queryQuantityCurrentDataList({
                    project_id: projectId,
                    quantity_ids: qids,
                })
                return queryQuantityCurrentDataPromise;
            }).then(res => {
                // 请求当前数值和评估等级
                let dataList = res.data || [];
                let evaluationCount = this.state.evaluationCount;
                evaluationCount = [0, 0, 0, 0, 0];
                dataList.forEach(item => {
                    if (!item) {
                        return;
                    }
                    let id = +item['id'];
                    let value = +item['value'];
                    let q: quantity = this.state.quantities.find(qs => qs.id === id);
                    q.current_value = value * q.scale;
                    if (q.valid_data != null && Math.abs(q.valid_data) < Math.abs(value)) {
                        q.current_grade = -1;
                    } else {
                        q.current_grade = this.checkQuantityEvaluation(q.warning_limit, value, q.constages);
                    }
                    if (q.current_grade > 0) {
                        evaluationCount[q.current_grade - 1]++;
                    }
                })
                let quantities: quantity[] = this.state.quantities;
                quantities = quantities.sort((a, b) => a.current_grade < b.current_grade || !a.current_grade ? 1 : -1);
                this.setState({
                    evaluationCount: [...evaluationCount],
                    quantities: [...quantities],
                    displayQuantities: [...quantities],
                    loading: false
                })
            })
    }

    generateDataV2 = () => {
        this.setState({
            loading: true
        })
        const projectId = this.context;
        let queryProjectQuantitiesPromise = queryProjectGlobalQuantities(projectId);
        let queryQuantitiesPromise = queryDashboardQuantities({
            project_id: projectId,
            quantity_level: QuantityLevelTypes.OTHER_Q,
            is_evaluate: true,
        });
        let queryProjectPromise = queryProjectDetail(projectId);
        let evaluationCount = this.state.evaluationCount;
        evaluationCount = [0, 0, 0, 0, 0];
        axios.all([queryProjectQuantitiesPromise, queryQuantitiesPromise, queryProjectPromise])
            .then(res => {
                let projectInfo: project = res[2].data;
                this.setState({
                    monitorType: projectInfo?.monitor_type || 2,
                })

                let pQuantities: globalQuantity[] = res[0].data || [];
                this.projectGlobalQuantities = pQuantities;
                let data: mobjectQuantity[] = res[1].data;
                data = data.filter(item => !!item.mobject);


                let quantities = data.map((item: mobjectQuantity) => {
                    let q = item.quantity;
                    q.mobject = item.mobject;
                    q.mobject_name = item.mobject?.name || '';
                    let pQuantity = pQuantities.find(p => p.type === q.type);
                    q.unit_type = pQuantity?.unit_type || '';
                    q.chinese_name = pQuantity?.chinese_name || '';
                    q.precision = pQuantity?.precision || '1';
                    let scale = Transfer2DisplayScale(q.unit_type);
                    q.scale = scale;
                    q.valid_data = pQuantity.valid_data;
                    // 后端直接传回当前等级
                    q.current_grade = item.grade;
                    // 统计总数
                    // if (q.current_grade > 0) {
                    //     evaluationCount[q.current_grade - 1]++;
                    // }

                    return q;
                });

                this.setState({
                    quantities: quantities,
                    // evaluationCount: [...evaluationCount],
                })
                let qids = quantities.map(item => item.id);
                let queryQuantityCurrentDataPromise = queryQuantityCurrentDataList({
                    project_id: projectId,
                    quantity_ids: qids,
                })
                return queryQuantityCurrentDataPromise;
            }).then(res => {
                // 请求当前数值和评估等级
                let dataList = res.data || [];
                // let evaluationCount = this.state.evaluationCount;
                // evaluationCount = [0, 0, 0, 0, 0];
                dataList.forEach(item => {
                    if (!item) {
                        return;
                    }
                    let id = +item['id'];
                    let value = +item['value'];
                    let q: quantity = this.state.quantities.find(qs => qs.id === id);
                    if (!!q) {
                        q.current_value = value * q?.scale || null;
                        // if (!q.is_evaluate) {
                        //     q.current_grade = 0;
                        // } else {
                        //     q.current_grade = this.checkQuantityEvaluation(q.warning_limit, value, q.constages);
                        // }
                        if (q.valid_data != null && Math.abs(q.valid_data) < Math.abs(value)) {
                            q.current_grade = -1;
                        } else if (q.current_grade > 0) {
                            evaluationCount[q.current_grade - 1]++;
                        }
                    }

                })
                let quantities: quantity[] = this.state.quantities;
                quantities = quantities.sort((a, b) => a.current_grade < b.current_grade || !a.current_grade ? 1 : -1);
                this.setState({
                    quantities: [...quantities],
                    displayQuantities: [...quantities],
                    loading: false,
                    evaluationCount: [...evaluationCount],
                })
            })
    }

    checkQuantityEvaluation = (warningLimits: warningLimit[], value: number, constages: constage[]) => {
        let time = dayjs().valueOf();
        if (constages.length === 0) return 0;
        let stage: constage = constages.find((item: constage) => dayjs(item.start_time).valueOf() <= time && dayjs(item.end_time).valueOf() >= time);
        if (!stage) stage = constages[constages.length - 1];
        let limit = warningLimits.find((item: warningLimit) => item.stage_id === stage.id)?.values;
        if (!limit)
            return 0;
        if (Math.abs(limit['t5']) < 1e-9)
            return 0;
        if (!value)
            return 0;
        if (limit['t5'] <= Math.abs(value)) {
            return 5;
        } else if (limit['t4'] <= Math.abs(value)) {
            return 4;
        } else if (limit['t3'] <= Math.abs(value)) {
            return 3;
        } else if (limit['t2'] <= Math.abs(value)) {
            return 2;
        } else {
            return 1;
        }
    }

    onChange = (quantityId: number) => {
        this.setState({
            rowSensorSelectedId: quantityId,
        });
    };

    onRadioChange = (e) => {
        this.filterDisplayQuantities(e.target.value)
    };

    filterDisplayQuantities = (value) => {
        if (value === QTtypes.ALL) {
            this.setState({
                displayQuantities: [...this.state.quantities]
            })
            return;
        }
        this.setState({
            displayQuantities: this.state.quantities.filter(item => item.qt_type === value)
        })
    }

    onRadioClick = (e, record: quantity) => {
        e.stopPropagation();
        this.props.onRowSelectClick(record);
    }

    goToMobjectDetail = (record: quantity) => {
        if (!record.mobject) return;
        if (!record.group_id) return;
        let mobject = record.mobject;
        let typeName = MobjectBaseType[mobject?.type]['name'];
        let url = `/projects/${this.context}/mobjects/${typeName}/${mobject.id}?tab=2&group=${record.group_id}`;
        this.props.history.push(url);
    }

    render() {
        let { displayQuantities } = this.state;
        return (
            <div className="dashboard-evaluate-card main">
                <div className="header">
                    <Space size={18} className="extra-button-group">
                        <Radio.Group defaultValue={this.state.radios} onChange={this.onRadioChange}>
                            <Radio.Button value={QTtypes.ALL}>全部指标</Radio.Button>
                            <Radio.Button value={QTtypes.STRUCTURE}>结构指标</Radio.Button>
                            <Radio.Button value={QTtypes.ELEMENT}>构件指标</Radio.Button>
                            <Radio.Button value={QTtypes.POINT}>点指标</Radio.Button>
                            <Radio.Button value={QTtypes.ENVIRONMENT}>环境指标</Radio.Button>
                        </Radio.Group>
                        {/* <Button type="primary" onClick={this.props.onMainBtnClick}>项目概览</Button> */}
                        {/* <Button type="primary">设备概览</Button> */}
                    </Space>
                </div>
                <div className="evaluate-content">
                    <div className="evaluate-table">
                        <Table
                            dataSource={displayQuantities}
                            rowKey="id"
                            loading={this.state.loading}
                            pagination={displayQuantities.length > 30 ? {
                                pageSize: 30
                            } : false}
                            rowClassName={(record, index) => classnames({
                                'ribbon-row': index % 2 !== 0,
                                'row-click': true,
                            })}
                        // onRow={record => {
                        //     return {
                        //         onClick: () => this.goToMobjectDetail(record),
                        //     }
                        // }}
                        >
                            <Column
                                title="监测对象"
                                key="mobject_name"
                                align="center"
                                width={250}
                                render={(record: quantity) => (
                                    <Fragment>
                                        <span style={{ marginRight: '4px' }}>{record.mobject_name}</span>
                                        <Button className="locale-btn" size="small" type="link" onClick={() => this.goToMobjectDetail(record)}>详情</Button>
                                    </Fragment>
                                )} />
                            <Column title="监测指标" key="quantity_name" align="center" render={(record: quantity) => <QuantityName record={record} />} />
                            <Column title="当前数值" key="current_value" align="right" render={(record: quantity) => (
                                `${record.current_value?.toFixed(+record.precision) || ''} ${record.unit_type}`
                            )} />
                            <Column title="评估" key="current_grade" align="center" render={(record: quantity) => (
                                <AlertTag current_grade={record.current_grade} />
                            )} />
                            <Column title="响应" key="current_grade_react" align="left" render={(record: quantity) => {
                                let desp = warningGradeDict[record.current_grade || 0]['description'] || '';
                                let desp_use = warningGradeDict[record.current_grade || 0]['description_use'] || '';
                                if (this.state.monitorType === 2) return desp;
                                return desp_use;
                                // warningGradeDict[record.current_grade || 0]['description'] || ''
                            }} />
                            {/* <Column title="" key="operations" align="center" render={record => (
                                <Button type="link" onClick={() => this.goToMobjectDetail(record)}>
                                    详情
                                    <RightOutlined />
                                </Button>
                            )} /> */}
                            <Column title="传感器分布" align="center" render={(record: quantity) => (
                                <Radio onChange={(e) => this.onRadioClick(e, record)} checked={this.props.rowSelected?.id === record.id} />
                            )} />
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(EvaluateMainBoard);