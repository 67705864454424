import axios from "../axios"
import { AxiosResponse } from "axios";
import qs from 'qs';

export function updateProjectSetting(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/projects/${projectId}/setting`, data, {
        params: params
    })
}

export function queryProjectWarningUsers(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/warning-users`, {
        params: params
    })
}

export function createProjectWarningUser(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/projects/${projectId}/warning-users`, data, {
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'comma'})
        }
    })
}

export function deleteProjectWarningUser(projectId: number, warningUserId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`/projects/${projectId}/warning-users/${warningUserId}`, {
        params: params
    })
}