import React, { Component } from 'react'
import { DataPoint, qDataBase } from '@/interface/quantity'
import QuantityDetailStore from '@/store/quantity-detail-store'
import { inject, observer } from 'mobx-react'
import LineChart from './line-chart'
import { constage } from '@/interface/constage'
import './line-chart-board.less';
import { QuantityName } from '../QuantityName'
import { Button, Modal, Form, Space, message, Tooltip, Input, Popconfirm } from 'antd'
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import DatePicker from '../DatePicker'
import { deleteQuantityData, deleteSensorQuantityData, updateQuantityData } from '@/config/api/quantity-data'
import dayjs from 'dayjs'
import UserStore from '@/store/user-store'
import { UserTypeEnum } from '@/routes/login/login'
import { MobjectBaseType } from '@/utils/type'
import { withRouter } from 'react-router-dom'
import { Gprops } from '@/config/props'
import { ProjectContext } from '@/config/context'

export const QuantityDataTypes = {
    NORMAL: 1,
    MIN: 2,
    MAX: 3,
    MEAN: 4,
}

interface IProps extends Gprops {
    quantityDetailStore?: QuantityDetailStore
    userStore?: UserStore
    q: qDataBase,
    constages?: constage[],
    afterDelete?: (params: any) => void,
    chartHeight?: number
    deleteDisabled?: boolean
    isSensor?: boolean
}

interface IState {
    clientWidth: number,
    onDeleteState: boolean,
    onUpdateState: boolean,
    deleteLoading: boolean,
    editLoading: boolean,
    filteredDs: DataPoint[],
}

@inject('quantityDetailStore', 'userStore')
@observer
class LineChartBoard extends Component<IProps, IState> {
    static contextType = ProjectContext;
    ref = React.createRef<HTMLDivElement>();
    state = {
        clientWidth: 0,
        onDeleteState: false,
        onUpdateState: false,
        deleteLoading: false,
        editLoading: false,
        filteredDs: [],
    }


    componentDidMount() {
        if (this.ref.current?.clientWidth) {
            let width = this.ref.current?.clientWidth - 140
            this.setState({
                clientWidth: width
            })
        }
    }

    onDelete = (params) => {
        let id = this.props.q?.id;
        if (!id) {
            message.error('删除失败');
            return;
        }
        this.setState({
            deleteLoading: true,
        })
        let start_time = dayjs(params['start_time']).valueOf();
        let end_time = dayjs(params['end_time']).valueOf();
        let queryParams = {
            start_time: start_time,
            end_time: end_time,
        }
        let promise = deleteQuantityData(id, queryParams);
        if (!!this.props.isSensor) {
            promise = deleteSensorQuantityData(id, queryParams)
        }
        promise.then(res => {
            this.setState({
                onDeleteState: false,
                deleteLoading: false,
                filteredDs: [],
            })
            if (this.props.afterDelete) {
                this.props.afterDelete(params);
            }
            this.setState({
                onDeleteState: false,
            })
        }).catch(err => {
            message.error('删除失败');
            console.log(err);
            this.setState({
                deleteLoading: false,
            })
        })
    }

    onUpdate = (values) => {
        // console.log(this.props.q)
        let id = this.props.q?.id;
        if (!id) {
            message.error('更新失败');
            this.setState({
                editLoading: false,
            })
            return;
        }
        this.setState({
            editLoading: true,
        })
        let scale = this.props.q?.scale || 1;
        let first: DataPoint = this.state.filteredDs[0];
        let last: DataPoint = this.state.filteredDs[this.state.filteredDs.length - 1];
        let delta = +values['delta'];
        if (isNaN(delta)) {
            delta = 0;
        }
        let params = {
            start_time: first.time,
            end_time: last.time,
            delta: delta / scale,
        }

        updateQuantityData(id, params).then(res => {
            this.setState({
                onUpdateState: false,
                editLoading: false,
                filteredDs: [],
            })
            if (this.props.afterDelete) {
                this.props.afterDelete(params);
            }
        }).catch(err => {
            message.error('更新失败');
            console.log(err);
            this.setState({
                editLoading: false,
            })
        })
    }

    goToMobjectDetail = () => {
        let record = this.props.q;
        if (!record.mobject_type || !record.group_id || !record.mobject_id) return;
        let typeName = MobjectBaseType[record.mobject_type]['name'];
        let url = `/projects/${this.context}/mobjects/${typeName}/${record.mobject_id}?tab=2&group=${record.group_id}`;
        this.props.history.push(url);
    }

    render() {
        if (!this.props.q) return null;
        let q = this.props.q;
        let userType = this.props.userStore._userInfo?.type;
        return (
            <div className="line-chart-container" ref={this.ref}>
                <div className="line-chart-header">
                    {/* {q.mobject_name && (
                        <div className="legend-group">
                            <div className="label">对象</div>
                            <div className="content">{q.mobject_name}</div>
                        </div>
                    )} */}
                    <div className="title" onClick={this.goToMobjectDetail}>
                        {q.mobject_name && (
                            <div className="content">{q.mobject_name}</div>
                        )}
                        <QuantityName showChineseName record={q} />
                    </div>
                </div>
                <LineChart
                    height={this.props.chartHeight || 300}
                    q_data={this.props.q}
                    precision={+this.props.q.precision}
                    constages={this.props.constages || []}
                    filter={(ds) => {
                        // console.log(ds)
                        this.setState({
                            filteredDs: ds,
                        })
                    }}
                />
                {/* <ChartLegend q={this.props.q}/> */}
                <div className="legend">
                    {!!userType && (userType === UserTypeEnum.ROOT || userType === UserTypeEnum.ADMIN) && !this.props.deleteDisabled && this.state.filteredDs.length > 0 && (
                        <div className="legend-group">
                            <Space size={4}>
                                {!this.props.isSensor && (
                                    <Tooltip title="平移数据">
                                        <Button
                                            onClick={e => this.setState({ onUpdateState: true })}
                                            type="link"
                                            icon={<FormOutlined />}
                                        ></Button>
                                    </Tooltip>
                                )}

                                <Popconfirm 
                                title="确认删除当前显示数据？"
                                onConfirm={e => {
                                    let first: DataPoint = this.state.filteredDs[0];
                                    let last: DataPoint = this.state.filteredDs[this.state.filteredDs.length - 1];
                                    let params = {
                                        start_time: first.time,
                                        end_time: last.time,
                                    }
                                    this.onDelete(params);
                                }}>
                                    <Button
                                        loading={this.state.deleteLoading}
                                        type="link"
                                        icon={<DeleteOutlined />}
                                        danger
                                    ></Button>
                                </Popconfirm>
                            </Space>

                        </div>
                    )}

                    <div className="legend-group">
                        <div className="label">最大值</div>
                        <div className="content">{q.max?.toFixed(+q.precision)}</div>
                    </div>
                    <div className="legend-group">
                        <div className="label">最小值</div>
                        <div className="content">{q.min?.toFixed(+q.precision)}</div>
                    </div>
                    <div className="legend-group">
                        <div className="label">平均值</div>
                        <div className="content">{q.mean?.toFixed(+q.precision)}</div>
                    </div>
                    {q.unit_type && q.unit_type !== '' && (
                        <div className="legend-group">
                            <div className="label">单位</div>
                            <div className="content">{q.unit_type}</div>
                        </div>
                    )}
                </div>
                <Modal
                    visible={this.state.onDeleteState}
                    footer={null}
                    title="请选择时间区间"
                    destroyOnClose
                    onCancel={() => this.setState({ onDeleteState: false })}>
                    <Form
                        onFinish={this.onDelete}
                        autoComplete="off">
                        <Form.Item label="开始时间" name="start_time" rules={[{ required: true }]}>
                            <DatePicker showTime />
                        </Form.Item>
                        <Form.Item label="结束时间" name="end_time" rules={[{ required: true }]}>
                            <DatePicker showTime />
                        </Form.Item>
                        <div>
                            <Space size="middle">
                                <Button htmlType="submit" type="primary" loading={this.state.deleteLoading}>确定</Button>
                                <Button onClick={() => this.setState({ onDeleteState: false })}>取消</Button>
                            </Space>
                        </div>
                    </Form>
                </Modal>

                <Modal
                    visible={this.state.onUpdateState}
                    footer={null}
                    title="平移数据"
                    className='update-quantity-delta'
                    destroyOnClose
                    onCancel={() => this.setState({ onUpdateState: false })}>
                    <Form
                        onFinish={this.onUpdate}
                        size="small"
                        autoComplete="off">
                        <Form.Item name="delta" rules={[{ required: true }]}>
                            <Input placeholder='数据平移量 向上为正，向下为负，按当前展示的数据单位填写' />
                        </Form.Item>
                        <div style={{ textAlign: 'right' }}>
                            <Space size="middle">
                                <Button htmlType="submit" type="primary" loading={this.state.editLoading}>确定</Button>
                                <Button onClick={() => this.setState({ onUpdateState: false })}>取消</Button>
                            </Space>
                        </div>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default withRouter(LineChartBoard);