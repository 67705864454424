import { observable, action } from "mobx";


class MenuStore {
    @observable _selectedKey: string = '';
    @action selectedKey = (value: string) => {
        this._selectedKey = value;
    }
}

export default MenuStore;