import React, { Component } from 'react'
import './sensor-formula.less';
import { sensorFormula, sensorQuantity, sensor } from '@/interface/sensor';
import { InlineMath } from 'react-katex';
import { Form, Select, Space, Button, message } from 'antd';
import { paramDict2Array, handleQuantityName, Transfer2DisplayScale, Transfer2BackEndScale, NumFixed } from '@/utils/utils';
import { SensorParamUnits } from '@/utils/unit-type';
import { querySensorQuantityDetail, updateSensorFormula, updateSensorQuantityDetail } from '@/config/api/sensor';
import { ProjectContext } from '@/config/context';
import { FormInstance } from 'antd/lib/form';
import { quantity, globalQuantity } from '@/interface/quantity';
import { queryQuantities } from '@/config/api/quantity';
import { QuantityLevelTypes } from '../mobject/quantity-pattern-setting';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import axios from '@/config/axios';
import { NumericInput } from '..';
import { QuantityName } from '../QuantityName';
import { Gprops } from '@/config/props';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import SensorStore from '@/store/sensor-store';
import { queryMobjectDetail } from '@/config/api/mobject';
import { MobjectBaseType } from '@/utils/type';
const { Option } = Select;

interface IProps extends Gprops {
    formula: sensorFormula
    sensorDetail: sensor
    afterSave?: (formula: sensorFormula) => void
    sensorStore?: SensorStore
}
interface IState {
    sQuantity: sensorQuantity
    dqList: quantity[]
    currentDqId: number
    isEdit: boolean
    saveLoading: boolean
}

@inject('sensorStore')
@observer
class SensorFormulaPane extends Component<IProps, IState> {
    static contextType = ProjectContext;
    formRef = React.createRef<FormInstance>();
    mobjectId: number;
    sensorId: number;
    state = {
        sQuantity: null,
        dqList: [],
        currentDqId: null,
        isEdit: false,
        saveLoading: false,
    }
    componentDidMount() {
        this.initialForm();
        this.findQuantities(this.props.sensorDetail.mobject_id);
    }

    componentDidUpdate() {
        let mobjectId = this.props.sensorDetail?.mobject_id;
        let sensorId = this.props.sensorDetail?.id;
        if (this.mobjectId !== mobjectId && mobjectId) {
            this.mobjectId = mobjectId;
            this.findQuantities(mobjectId);
        }
        if (this.sensorId !== sensorId) {
            this.sensorId = sensorId;
            this.initialForm();
        }

        let forceRender = this.props.sensorStore._forceRender;
        if (forceRender) {
            this.props.sensorStore.setForceRender(false);
            this.initialForm();
        }
    }

    initialForm = () => {
        let formula = this.props.formula;
        // 拿quantity
        let mq_id = formula.mq_id;
        this.mobjectId = this.props.sensorDetail?.mobject_id;
        this.sensorId = this.props.sensorDetail?.id;
        if (this.sensorId !== formula.sensor_id) return;
        querySensorQuantityDetail(this.context, mq_id)
            .then(res => {
                formula.sensorQuantity = res.data;
                this.setState({
                    sQuantity: res.data,
                })
                let params = {...formula.params};

                for (let key of Object.keys(params)) {
                    let value = params[key];
                    let unit = SensorParamUnits[key];
                    let scale = Transfer2DisplayScale(unit);
                    params[key] = NumFixed(value * scale, 8);
                }
                let dqId = this.state.sQuantity?.dq_id;
                let initValueUnit = formula.gQuantity?.unit_type;
                let initValueScale = Transfer2DisplayScale(initValueUnit);
                this.formRef.current?.setFieldsValue({
                    initial_value: formula.sensorQuantity?.initial_value * initValueScale,
                    dq_id: dqId || null,
                    ...params
                })
                this.setState({
                    currentDqId: dqId
                })
            })
    }


    // findDQuantity = (id) => {
    //     // 寻找当前关联的dq
    //     if (!id) return;
    //     let queryProjectQuantityPromise = queryProjectGlobalQuantities(this.context);
    //     let queryQuantityPromise = queryQuantityDetail(this.context, id)
    //     axios.all([queryProjectQuantityPromise, queryQuantityPromise])
    //         .then(res => {
    //             let currentDq: quantity = res[1].data;
    //             let pQuantities = res[0].data || [];
    //             if (currentDq) {
    //                 currentDq.unit_type = pQuantities.find(item => item.type === currentDq.type)?.unit_type;
    //             }
    //             this.setState({
    //                 currentDq: currentDq
    //             })
    //         })
    // }

    findQuantities = (mobjectId: number) => {
        if (!mobjectId) {
            return;
        }
        // 寻找可以关联的直接物理量
        let pQuantitiesPromise = queryProjectGlobalQuantities(this.context);
        let queryQuantitiesPromise = queryQuantities(this.context, {
            mobject_id: mobjectId,
            level: QuantityLevelTypes.DIRECT_Q,
        })
        axios.all([pQuantitiesPromise, queryQuantitiesPromise]).then(res => {
            let pQuantities: globalQuantity[] = res[0].data;
            let quantities: quantity[] = res[1].data;
            quantities.forEach(q => {
                let p = pQuantities.find(item => item.type === q.type);
                q.chinese_name = p.chinese_name;
                q.unit_type = p.unit_type;
                q.mobject_name = this.props.sensorDetail?.mobject?.name || '';
            })
            this.setState({
                dqList: quantities || [],
            })
        })
    }

    onDqSelectChange = (value) => {
        this.setState({
            currentDqId: value
        })
    }

    onCancel = () => {
        let sQuantity = this.state.sQuantity;
        let formula = this.props.formula;
        let params = formula.params;
        for (let key of Object.keys(params)) {
            let value = params[key];
            let unit = SensorParamUnits[key];
            let scale = Transfer2DisplayScale(unit);
            params[key] = Number((value * scale).toFixed(8));
        }
        let initValueUnit = formula.gQuantity?.unit_type;
        let initValueScale = Transfer2DisplayScale(initValueUnit);
        this.formRef.current.resetFields();
        this.formRef.current.setFieldsValue({
            initial_value: sQuantity?.initial_value * initValueScale,
            dq_id: sQuantity?.dq_id,
            ...params
        })
        this.setState({
            isEdit: false
        })
    }

    onSave = (values) => {
        let sensorDetail = this.props.sensorDetail;
        if (!sensorDetail || !sensorDetail.id) {
            message.error('发生错误');
            return;
        }
        this.setState({
            saveLoading: true,
        })
        let formula = this.props.formula;
        let { dq_id, initial_value, ...params } = values;
        // 将param单位换算回国际单位
        for (let key of Object.keys(params)) {
            let value = params[key];
            let unit = SensorParamUnits[key];
            let scaleBack = Transfer2BackEndScale(unit);
            params[key] = value * scaleBack;
        }

        let initValueUnit = formula.gQuantity?.unit_type;
        let initValueScaleBack = Transfer2BackEndScale(initValueUnit);
        // 将initial_value单位换算回国际单位
        let initial_value_back = initial_value * initValueScaleBack;

        let updateFormulaPromise = updateSensorFormula(sensorDetail.id, formula.mq_id, params);
        let updateSensorQuantityPromise = updateSensorQuantityDetail(this.context, formula.mq_id, {
            dq_id: dq_id || -1,
            initial_value: initial_value_back,
        })
        axios.all([updateFormulaPromise, updateSensorQuantityPromise])
            .then(res => {
                // sQuantity的初值一直保持国际单位
                this.state.sQuantity.initial_value = initial_value_back;
                this.setState({
                    sQuantity: { ...this.state.sQuantity },
                    currentDqId: dq_id,
                    isEdit: false,
                    saveLoading: false,
                })
                if (this.props.afterSave) {
                    this.props.afterSave({ ...formula, params: params });
                }
            }).catch(err => {
                message.error('更新失败');
                console.log(err);
                this.setState({
                    saveLoading: false,
                })
            })
    }

    gotoMobjectDetail = () => {
        let dqId = this.state.sQuantity?.dq_id;
        let sensorDetail = this.props.sensorDetail;
        let mobjectId = sensorDetail.mobject_id;
        if (!mobjectId) return;
        queryMobjectDetail(mobjectId, {
            project_id: this.context,
        }).then(res => {
            let m = res.data;
            let typeName = MobjectBaseType[m.type]['name'];
            let url = `/projects/${this.context}/mobjects/${typeName}/${mobjectId}?tab=2`;
            this.props.history.push(url);

        })
    }

    render() {
        let formula = this.props.formula;
        let sensorDetail = this.props.sensorDetail;
        let { dqList, isEdit } = this.state;
        let _ = this.props.sensorStore._forceRender;
        return (
            <div className="formula-pane">
                <div style={{ textAlign: "center" }}>
                    {formula.quantity_chinese_name}<InlineMath math={formula?.formula} />
                </div>
                <div className="params-pane">
                    <Form size="small" labelCol={{ span: 5 }} wrapperCol={{ span: 14 }} ref={this.formRef} onFinish={this.onSave}>

                        <Form.Item label="初值" name="initial_value">
                            <NumericInput readOnly={!isEdit} suffix={<span>{formula.gQuantity?.unit_type}</span>} />
                        </Form.Item>
                        {paramDict2Array(formula.params).map((item, idx) => (
                            <Form.Item
                                key={idx}
                                label={
                                    <InlineMath math={handleQuantityName(item.name)} />
                                }
                                name={item.name}>
                                <NumericInput readOnly={!isEdit} suffix={<span>{SensorParamUnits[item.name]}</span>} />
                            </Form.Item>
                        ))}
                        <Form.Item label="指标关联" name="dq_id">
                            <Select onChange={this.onDqSelectChange} disabled={!isEdit} allowClear>
                                {dqList.map((item, idx) => (
                                    <Option key={idx} value={item.id}>
                                        {(<span>{item?.mobject_name}-</span>)}
                                        {<QuantityName record={item} />}
                                    </Option>
                                ))}
                                <Option value={-1}>取消关联</Option>
                            </Select>
                        </Form.Item>
                        {!sensorDetail.mobject_id && (
                            <div style={{ fontSize: '12px', margin: '8px 16px' }}>*tips: 请先完成定位信息再进行关联</div>
                        )}
                        {isEdit && (
                            <div>
                                <Space>
                                    <Button type="primary" htmlType="submit" loading={this.state.saveLoading}>保存</Button>
                                    <Button onClick={this.onCancel}>取消</Button>
                                </Space>
                            </div>
                        )}
                        {!isEdit && (
                            <Space size="middle">
                                <Button type="primary" onClick={() => this.setState({ isEdit: true })}>编辑</Button>
                                <Button onClick={() => this.props.history.push(`/projects/${this.context}/quantity-link`)}>全局指标关联</Button>
                                {/* {!!this.state.sQuantity?.dq_id && (
                                    <Button onClick={this.gotoMobjectDetail}>指标详情</Button>
                                )} */}
                            </Space>
                        )}

                    </Form>
                </div>
            </div>
        )
    }
}

export default withRouter(SensorFormulaPane)