import React, { Component } from 'react'
import { multiDataPoint } from '@/interface/quantity'
import { Chart } from '@antv/g2';

interface IProps {
    q_data: multiDataPoint[],
    precision: number,
    width?: number
    height?: number
}

export default class MultiLineChart extends Component<IProps> {

    lineChart: Chart;

    componentDidMount() {
        const chart = new Chart({
            container: 'query-multi-container',
            autoFit: true,
            height: this.props.height || 600,
            width: this.props.width || null,
        });
        chart.data(this.props.q_data);
        chart.tooltip({
            showCrosshairs: true,
            shared: true,
        })
        chart.scale({
            time: {
                type: 'timeCat',
                range: [0, 0.95],
                tickCount: 5,
                alias: '时间',
                mask: 'YYYY-MM-DD HH:mm'
            },
            value: {
                nice: true,
                formatter: (val: number) => `${val.toFixed(this.props.precision)}`,
                alias: '数值',
                tickCount: 6,
            }
        });
        chart
            .line()
            .position('time*value')
            .color('name')
            ;
        this.lineChart = chart;
        chart.render();
    }

    componentDidUpdate() {
        if (this.lineChart) {
            this.lineChart.changeData(this.props.q_data);
        }
    }

    render() {
        return (
            <div id="query-multi-container"></div>
        )
    }
}
