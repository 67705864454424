import React, { Component } from 'react'
import { RProps } from '@/config/props'
import { withRoutePane } from '@/components/hoc/routePane';
import { Space, Button, Radio, Spin, Table, Empty, Select, Tooltip, message, Switch, Upload } from 'antd';
import { quantity, globalQuantity, warningLimit, quantityLimit } from '@/interface/quantity';
import { updateQuantities, queryQuantities, downloadQuantityWLCsv } from '@/config/api/quantity';
import axios from '@/config/axios';
import { constage } from '@/interface/constage';
import { ProjectContext } from '@/config/context';
import { queryConstages } from '@/config/api/constage';
import { queryMobjects } from '@/config/api/mobject';
import { mobject } from '@/interface/mobject';
import dayjs from 'dayjs';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import './quantity-limit-page.less';
import { Transfer2DisplayScale, NumFixed } from '@/utils/utils';
import { InfoCircleFilled, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { EditableRow, EditableCell } from '@/components';
import { QuantityName } from '@/components/QuantityName';
import { QuantityLevelTypes } from '@/components/mobject/quantity-pattern-setting';
import appStore, { AUTHORIZATION } from '@/store/app-store';
const { Option } = Select;
const { Column } = Table;

interface IProps extends RProps {
    // mobjectStore?: MobjectStore
}

interface IState {
    isEditting: boolean,
    // editLocation: number
    // quantities: quantity[],
    constages: constage[],
    currentConstage: constage,
    loading: boolean,
    saveLoading: boolean,
    exportLoading: boolean,

    displayQuantityLimits: quantityLimit[],

    uploadUrl: string,
}

class QuantityLimitPage extends Component<IProps, IState> {
    static contextType = ProjectContext;
    projectGlobalQuantities: globalQuantity[] = [];
    quantities: quantity[] = [];
    state = {
        // quantities: [],
        isEditting: false,
        constages: [],
        currentConstage: null,
        loading: false,
        saveLoading: false,
        exportLoading: false,
        displayQuantityLimits: [],

        uploadUrl: '',
    }

    componentDidMount() {
        let token = appStore.get(AUTHORIZATION) || '';
        let url = `/api/quantities/warning-limit/import?project_id=${this.context}&token=${token}`;
        this.setState({
            uploadUrl: url,
        })
        
        this.setState({
            loading: true,
        })
        queryProjectGlobalQuantities(this.context)
            .then(res => {
                this.projectGlobalQuantities = res.data || [];
                this.generateData();
            })

    }

    generateData = () => {
        this.setState({
            loading: true,
        })
        let mobjectsPromise = queryMobjects({
            project_id: this.context
        })
        let quantitiesPromise = queryQuantities(this.context, {
            activated: true,
            level: QuantityLevelTypes.OTHER_Q,
        });
        let constagesPromise = queryConstages(this.context);
        axios.all([mobjectsPromise, quantitiesPromise, constagesPromise])
            .then(res => {
                let mobjects: mobject[] = res[0].data;
                let quantities: quantity[] = res[1].data;
                let constages: constage[] = res[2].data;
                quantities.forEach((q: quantity) => {
                    let mobject = mobjects.find(item => item.id === q.mobject_id);
                    q.constages = mobject?.constages || [];
                    q.constages.sort((a, b) => dayjs(a.start_time).valueOf() - dayjs(b.start_time).valueOf());
                    q.mobject_name = mobject?.name;

                    let pQuantity: globalQuantity = this.projectGlobalQuantities.find(item => item.type === q.type);
                    q.chinese_name = pQuantity?.chinese_name || '';
                    q.unit_type = pQuantity?.unit_type || '';
                    q.precision = pQuantity?.precision || '1';
                    let scale = Transfer2DisplayScale(q.unit_type);
                    q.scale = scale;
                    // 将数据换算成展示单位对应数值
                    q.warning_limit.forEach((item: warningLimit) => {
                        for (let key of Object.keys(item.values)) {
                            item.values[key] = item.values[key] * q.scale;
                        }
                    })
                    q.initial_value = q.initial_value * q.scale;
                })
                this.quantities = [...quantities];
                constages.sort((a, b) => dayjs(a.start_time).valueOf() - dayjs(b.start_time).valueOf());
                let currentConstage: constage = constages[0] || null;
                this.setState({
                    // TODO: dev mock环境
                    // quantities: quantities.filter((q: quantity) => q.constages.findIndex((item: constage) => item.id === currentConstage?.id) >= 0) || [],
                    // quantities: quantities,
                    constages: constages,
                    currentConstage: currentConstage,
                    loading: false,
                })
                if (!!this.state.currentConstage) {
                    this.generateDisplayQuantityLimit(this.state.currentConstage.id);
                }
            })
    }

    generateDisplayQuantityLimit(constageId: number) {
        let quantities = this.quantities;
        let display: quantityLimit[] = quantities.map(q => {
            let warningLimit = q.warning_limit.find(item => item.stage_id === constageId)?.values;
            return {
                ...q,
                t2: warningLimit ? warningLimit['t2'] : null,
                t3: warningLimit ? warningLimit['t3'] : null,
                t4: warningLimit ? warningLimit['t4'] : null,
                t5: warningLimit ? warningLimit['t5'] : null,
            }
        }) || [];
        display = display.sort((a, b) => a.mobject_name > b.mobject_name ? 1 : -1);
        this.setState({
            displayQuantityLimits: display
        })
    }

    reFindQuantities = () => {
        this.setState({
            loading: true,
            isEditting: false,
        })
        let quantitiesPromise = queryQuantities(this.context, {
            activated: true,
            level: QuantityLevelTypes.OTHER_Q,
        });
        let mobjectsPromise = queryMobjects({
            project_id: this.context
        });
        axios.all([mobjectsPromise, quantitiesPromise])
            .then(res => {
                let mobjects: mobject[] = res[0].data;
                let quantities: quantity[] = res[1].data;
                quantities.forEach((q: quantity) => {
                    let mobject = mobjects.find(item => item.id === q.mobject_id);
                    q.constages = mobject?.constages || [];
                    q.constages.sort((a, b) => dayjs(a.start_time).valueOf() - dayjs(b.start_time).valueOf());
                    q.mobject_name = mobject?.name;

                    let pQuantity: globalQuantity = this.projectGlobalQuantities.find(item => item.type === q.type);
                    q.chinese_name = pQuantity?.chinese_name || '';
                    q.unit_type = pQuantity?.unit_type || '';
                    q.precision = pQuantity?.precision || '1';
                    let scale = Transfer2DisplayScale(q.unit_type);
                    q.scale = scale;
                    // 将数据换算成展示单位对应数值
                    q.warning_limit.forEach((item: warningLimit) => {
                        for (let key of Object.keys(item.values)) {
                            item.values[key] = item.values[key] * q.scale;
                        }
                    })
                    q.initial_value = q.initial_value * q.scale;
                })
                this.quantities = [...quantities];
                if (!!this.state.currentConstage) {
                    this.generateDisplayQuantityLimit(this.state.currentConstage.id);
                }
            }).finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    onConstageSelectChange = (e) => {
        this.setState({
            currentConstage: this.state.constages.find(item => item.id === e)
        })
        this.generateDisplayQuantityLimit(e);
    }

    handleRowEdit = (row: quantityLimit, tag: string) => {
        let id = row.id;
        let newData = [...this.state.displayQuantityLimits];
        let index = newData.findIndex(item => item.id === id);
        let item: quantityLimit = newData[index];

        newData.splice(index, 1, { ...item, ...row })
        this.setState({
            displayQuantityLimits: [...newData],
        })

        // let scale = row.scale;
        // let constageId = this.state.currentConstage.id;
        // if (!constageId) return;
        // let limit = {
        //     t2: row.t2 || 0,
        //     t3: row.t3 || 0,
        //     t4: row.t4 || 0,
        //     t5: row.t5 || 0,
        // }
        // let warningLimits = [...row.warning_limit];
        // let wl = warningLimits.find(item => item.stage_id === constageId);
        // if (wl) {
        //     wl.values = limit;
        // } else {
        //     warningLimits.push({
        //         values: limit,
        //         stage_id: constageId,
        //     })
        // }
        // warningLimits.forEach((item: warningLimit) => {
        //     for (let key of Object.keys(item.values)) {
        //         item.values[key] = NumFixed(item.values[key] / scale, 8);
        //     }
        // })
        // let data = {
        //     warning_limit: warningLimits,
        //     initial_value: NumFixed(row.initial_value / scale, 8),
        //     id: row.id,
        //     is_evaluate: row.is_evaluate || false,
        // }
        // const projectId = this.context;
        // updateQuantities(projectId, row.id, data)
        // .then(res => {
        //     // let q = this.quantities.find(item => item.id = row.id);
        //     // if (!q) return;
        //     // q.warning_limit = data.warning_limit;
        //     // q.initial_value = data
        //     this.reFindQuantities();
        // })
    }

    onEditCancel = () => {
        this.generateDisplayQuantityLimit(this.state.currentConstage?.id);
        this.setState({
            isEditting: false,
        })
    }

    onEditSave = () => {
        const projectId = this.context;
        this.setState({
            saveLoading: true,
        })
        let promiseList = [];
        this.state.displayQuantityLimits.forEach((qw: quantityLimit) => {
            let scale = qw.scale;
            let constageId = this.state.currentConstage.id;
            let limit = {
                t2: qw.t2 || 0,
                t3: qw.t3 || 0,
                t4: qw.t4 || 0,
                t5: qw.t5 || 0,
            }
            let warningLimits = [...qw.warning_limit];
            let wl = warningLimits.find(item => item.stage_id === constageId);
            if (wl) {
                wl.values = limit;
            } else {
                warningLimits.push({
                    values: limit,
                    stage_id: constageId,
                })
            }
            warningLimits.forEach((item: warningLimit) => {
                for (let key of Object.keys(item.values)) {
                    item.values[key] = NumFixed(item.values[key] / scale, 8);
                }
            })
            let data = {
                warning_limit: warningLimits,
                initial_value: NumFixed(qw.initial_value / scale, 8),
                id: qw.id,
                is_evaluate: qw.is_evaluate || false,
            }
            let promise = updateQuantities(projectId, qw.id, data);
            promiseList.push(promise);
        })
        axios.all([promiseList])
            .then(res => {
                this.reFindQuantities();
            }).catch(err => {
                message.error('保存失败');
                console.log(err);
                // 把单位换算回去
                this.state.displayQuantityLimits.forEach((q: quantityLimit) => {
                    let limit = Object.assign([], q.warning_limit);
                    if (!!limit) {
                        limit.forEach(item => {
                            for (let key of Object.keys(item.values)) {
                                item.values[key] = NumFixed(item.values[key] * q.scale, 8);
                            }
                        })
                    }
                })
            }).finally(() => {
                this.setState({
                    saveLoading: false,
                })
            })
    }

    exportWarningLimit = () => {
        let current: constage = this.state.currentConstage;
        if (!current) return;
        const projectId = this.context;
        this.setState({
            exportLoading: true,
        })
        downloadQuantityWLCsv(projectId, current.id, null).then(res => {
            const blob = new Blob([res.data]);
            const link = document.createElement('a');
            const filename = `${current.code}.csv`;
            // const filename = '传感器表.csv';
            link.setAttribute('href', window.URL.createObjectURL(blob));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(err => {
            console.log(err);
            message.error('导出失败');
        }).finally(() => {
            this.setState({
                exportLoading: false,
            })
        })
    }

    onImportWLCsvChange = (info) => {
        if (info.file.status !== 'uploading') {
            // console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            let current: constage = this.state.currentConstage;
            if (!current) return;
            this.reFindQuantities();
            message.success(`导入成功`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    beforeUpload = (file) => {
        let current: constage = this.state.currentConstage;
        if (!current) return false;
        return true;
    }


    render() {
        let { displayQuantityLimits, isEditting } = this.state;
        return (
            <div className="quantity-limit-all-container">
                <div>
                    <div className="operations">
                        <Space>
                            <Select
                                value={this.state.currentConstage?.id}
                                style={{ width: '280px' }}
                                disabled={isEditting}
                                onChange={this.onConstageSelectChange}>
                                {this.state.constages.map((item: constage, idx) => (
                                    <Option value={item.id} key={idx}>{`${item.code}${item.name}`}</Option>
                                ))}
                            </Select>
                            <Button shape="round" onClick={this.exportWarningLimit}>导出</Button>
                            <Upload
                                name="file"
                                action={this.state.uploadUrl}
                                accept='.csv, .txt'
                                showUploadList={false}
                                onChange={this.onImportWLCsvChange}
                                beforeUpload={this.beforeUpload}
                            >
                                <Button shape="round">导入</Button>
                            </Upload>
                            <Tooltip title="请以创建的施工步编号命名文件，如CS-01.csv">
                                <Button size="small" type="link" icon={<InfoCircleFilled />}></Button>
                            </Tooltip>
                        </Space>
                        {!this.state.isEditting ?
                            (
                                <Space>
                                    <Button style={{ marginRight: '10px' }} shape="round" type="primary" onClick={() => { this.setState({ isEditting: true }) }}>编辑</Button>
                                </Space>
                            ) :
                            (
                                <Space>
                                    <Button shape="round" onClick={this.onEditCancel}>取消</Button>
                                    <Button shape="round" type="primary" onClick={this.onEditSave} loading={this.state.saveLoading}>保存</Button>
                                </Space>
                            )}
                    </div>
                    <Spin spinning={this.state.loading}>
                        {displayQuantityLimits.length === 0 ?
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
                            (
                                <div className="quantity-limit-tables">
                                    <Table
                                        dataSource={displayQuantityLimits}
                                        rowKey="id"
                                        pagination={false}
                                        bordered
                                        components={{
                                            body: {
                                                row: EditableRow,
                                                cell: EditableCell,
                                            }
                                        }}
                                    // rowClassName={(record, index) => (
                                    //     index % 2 !== 0 ? 'ribbon-row' : ''
                                    // )}
                                    >
                                        <Column title="监测对象" dataIndex="mobject_name" align="center" />
                                        <Column
                                            title="指标"
                                            key="quantity_name"
                                            render={record => (
                                                <QuantityName record={record} />
                                            )} />
                                        <Column
                                            title="单位"
                                            dataIndex="unit_type"
                                            align="center"
                                            width={100} />
                                        <Column
                                            title={`初值(${this.state.constages[0]?.code || ''})`}
                                            dataIndex='initial_value'
                                            width="15%"
                                            align="right"
                                            onCell={(record: quantityLimit, idx) => ({
                                                record,
                                                editable: isEditting,
                                                dataIndex: 'initial_value',
                                                title: '初值',
                                                directEdit: true,
                                                handleSave: (e) => this.handleRowEdit(e, 'initial_value'),
                                            })}
                                        />
                                        <Column
                                            title="二级阈值"
                                            dataIndex='t2'
                                            width="12%"
                                            align="right"
                                            onCell={(record: quantityLimit, idx) => ({
                                                record,
                                                editable: isEditting,
                                                dataIndex: 't2',
                                                title: '二级阈值',
                                                directEdit: true,
                                                handleSave: (e) => this.handleRowEdit(e, 't2'),
                                            })}
                                        />
                                        <Column
                                            title="三级阈值"
                                            dataIndex='t3'
                                            width="12%"
                                            align="right"
                                            onCell={(record: quantityLimit, idx) => ({
                                                record,
                                                editable: isEditting,
                                                dataIndex: 't3',
                                                title: '三级阈值',
                                                directEdit: true,
                                                handleSave: (e) => this.handleRowEdit(e, 't3'),
                                            })}
                                        />
                                        <Column
                                            title="四级阈值"
                                            dataIndex='t4'
                                            width="12%"
                                            align="right"
                                            onCell={(record: quantityLimit, idx) => ({
                                                record,
                                                editable: isEditting,
                                                dataIndex: 't4',
                                                title: '四级阈值',
                                                directEdit: true,
                                                handleSave: (e) => this.handleRowEdit(e, 't4'),
                                            })}
                                        />
                                        <Column
                                            title="五级阈值"
                                            dataIndex='t5'
                                            width="12%"
                                            align="right"
                                            onCell={(record: quantityLimit, idx) => ({
                                                record,
                                                editable: isEditting,
                                                dataIndex: 't5',
                                                title: '五级阈值',
                                                directEdit: true,
                                                handleSave: (e) => this.handleRowEdit(e, 't5'),
                                            })}
                                        />
                                        <Column
                                            title="是否报警"
                                            key="is_evaluate"
                                            width={100}
                                            align="center"
                                            render={record => (
                                                isEditting ? (
                                                    <Switch
                                                        checkedChildren={<CheckOutlined />}
                                                        unCheckedChildren={<CloseOutlined />}
                                                        checked={record.is_evaluate}
                                                        onChange={(value) => this.handleRowEdit({ ...record, is_evaluate: value }, 'is_evaluate')} />
                                                ) :
                                                    <div>{record.is_evaluate ? <CheckOutlined /> : null}</div>
                                            )}
                                        />
                                    </Table>

                                </div>
                            )
                        }

                    </Spin>
                </div >
            </div >
        )
    }
}

export default withRoutePane(QuantityLimitPage, '初值阈值表');