import React, { Component, Fragment } from 'react'
import { withRoutePane } from '@/components/hoc/routePane'
import './section-page.less';
import { Space, Button, Table, Popconfirm, message } from 'antd';
import { section } from '@/interface/model';
import { ProjectContext } from '@/config/context';
import axios from '@/config/axios';
import { querySections, deleteSection } from '@/config/api/model';
import Column from 'antd/lib/table/Column';
import { SectionTypes, SectionShapeTypes } from '@/utils/type';
import { Gprops } from '@/config/props';
import { SectionAdd } from '@/components';
import { SourceType } from '@/components/model/section-add';
import classnames from 'classnames';

interface IProps extends Gprops {

}
interface IState {
    sectionList: section[],
    loading: boolean
    multiDeleteLoading: boolean
    onMultiDeleteState: boolean
    sectionIdToDelete: number[]
    onModalVisible: boolean
    isAdding: boolean
}


class SectionPage extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        sectionList: [],
        loading: false,
        multiDeleteLoading: false,
        onMultiDeleteState: false,
        sectionIdToDelete: [],
        onModalVisible: false,
        isAdding: false,
    }

    componentDidMount() {
        this.findSections();
    }

    findSections = () => {
        this.setState({
            loading: true,
        })
        querySections(this.context, {
            // page: this.state.page,
            // size: this.state.size,
        }).then(res => {
            let sections: section[] = res.data || [];
            sections = sections.sort((a, b) => a.sid - b.sid);
            this.setState({
                sectionList: sections,
            })
        }).finally(() => {
            this.setState({
                loading: false,
            })
        })
    }

    handleDelete = (e, values) => {
        e.stopPropagation();
        deleteSection(this.context, values.id)
            .then(res => {
                this.setState({
                    sectionList: this.state.sectionList.filter(item => item.id !== values.id)
                })
            }).catch(err => {
                message.error('删除失败')
            })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        // console.log(selectedRowKeys)
        this.setState({
            sectionIdToDelete: [...selectedRowKeys]
        })
    }


    onMultiDeleteCancel = () => {
        this.setState({
            sectionIdToDelete: [],
            onMultiDeleteState: false,
        })
    }

    onMultiDeleteConfirm = () => {
        this.setState({
            multiDeleteLoading: true,
        })
        let deletePromiseList = this.state.sectionIdToDelete.map(id => deleteSection(this.context, id))
        axios.all([...deletePromiseList])
            .then(res => {
                this.setState({
                    sectionIdToDelete: [],
                    onMultiDeleteState: false,
                })
                this.findSections();
            }).finally(() => {
                this.setState({
                    multiDeleteLoading: false,
                })
            })
    }

    afterSectionAdd = () => {
        this.findSections();
        this.setState({
            isAdding: false
        })
    }

    render() {
        let { isAdding } = this.state;
        if (isAdding) {
            return (
                <SectionAdd 
                onCancel={() => this.setState({isAdding: false})}
                afterSave={this.afterSectionAdd}/>
            )
        }
        return (
            <div className="section-page-container">
                <div className="header">
                    {!this.state.onMultiDeleteState && (
                        <Fragment>
                            {/* <Space>
                                <Button shape="round">导入</Button>
                                <Button shape="round">导出</Button>

                            </Space> */}
                            <Space style={{ marginLeft: '24px' }}>
                                <Button type="primary" onClick={() => this.setState({ isAdding: true })}>添加</Button>
                                <Button danger onClick={() => { this.setState({ onMultiDeleteState: true }) }}>批量删除</Button>
                            </Space>
                        </Fragment>
                    )}
                    {this.state.onMultiDeleteState && (
                        <Space size="large" style={{ marginLeft: '24px' }}>
                            <Button type="primary" onClick={this.onMultiDeleteConfirm} loading={this.state.multiDeleteLoading}>确定</Button>
                            <Button onClick={this.onMultiDeleteCancel}>取消</Button>
                        </Space>
                    )}
                </div>

                <Table
                    rowSelection={this.state.onMultiDeleteState && {
                        type: 'checkbox',
                        onChange: this.onSelectChange,
                        selectedRowKeys: this.state.sectionIdToDelete
                    }}
                    dataSource={this.state.sectionList}
                    rowKey="id"
                    loading={this.state.loading}
                    pagination={false}
                    rowClassName={(record, index) => (
                        classnames({
                            'ribbon-row': index % 2 !== 0,
                            'select-row': !this.state.onMultiDeleteState
                        })
                    )}
                    onRow={record => {
                        return {
                            onClick: _ => {
                                if (this.state.onMultiDeleteState) return;
                                this.props.history.push(`${this.props.match.url}/sid/${record.sid}`);
                            },
                        }
                    }}>
                    <Column title="截面号" dataIndex="sid"  width='20%'/>
                    <Column title="类型" dataIndex="type" width='20%' render={record => SectionTypes[record]?.alias} />
                    <Column title="形状" dataIndex="shape_type" width='20%' render={record => SectionShapeTypes[record]} />
                    <Column title="名称" dataIndex="name" />
                    <Column title="来源" dataIndex="source" render={record => record === SourceType.DB ? '数据库' : '自定义'} />
                    <Column title="操作" key="operations" align="center" width={150} render={record => (
                        <Space style={{zIndex: 2}}>
                            <Popconfirm title="确认删除" onCancel={e => e.stopPropagation()} onConfirm={(e) => this.handleDelete(e, record)}>
                                <Button type="link" size="small" onClick={(e) => e.stopPropagation()} disabled={this.state.onMultiDeleteState}>删除</Button>
                            </Popconfirm>
                        </Space>
                    )} />
                </Table>
            </div>
        )
    }
}

export default withRoutePane(SectionPage, '截面列表');