import { observable, action } from "mobx";
import { mobject } from "@/interface/mobject";


class MobjectStore {
    @observable _mobjectDetail: mobject = null;
    @action setMojectDetail = (value: mobject) => {
        this._mobjectDetail = value;
    }

    @observable _selectedMobjectId: number;
    @action changeSelectedMobjectId = (value: number) => {
        this._selectedMobjectId = value;
    }

    @observable _mobjectTabIndex: {[uuid: string]: string} = {};
    @action changeMobjectTabIndex = (uuid: string, value: string) => {
        this._mobjectTabIndex[uuid] = value;
    }

    // 监测对象个数
    @observable _stmCount: number = 0;
    @action changeStmCount = (value: number) => {
        this._stmCount = value;
    }
    @observable _mmCount: number = 0;
    @action changeMmCount = (value: number) => {
        this._mmCount = value;
    }
    @observable _jmCount: number = 0;
    @action changeJmCount = (value: number) => {
        this._jmCount = value;
    }
    @observable _emCount: number = 0;
    @action changeEmCount = (value: number) => {
        this._emCount = value;
    }

    mobjectDeleteCallbackFn: (id: number) => void;

    onMobjectDelete = (fn: (id: number) => void) => {
        this.mobjectDeleteCallbackFn = fn;
    }

    @observable _forceRender: boolean = false;
    @action setForceRender = (value: boolean) => {
        this._forceRender = value;
    }
}

export default MobjectStore;