import React, { Component } from 'react'
import { Avatar, Menu, Dropdown } from 'antd'
import { UserOutlined, LogoutOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { Gprops } from '@/config/props';
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';
import appStore from '@/store/app-store';

interface IProps extends Gprops {
    showProjectList?: boolean
    showUserInfo?: boolean
    userStore?: UserStore
    onUserSettingClick?: () => void
}

@inject('userStore')
@observer
class UserDropdown extends Component<IProps> {
    static defaultProps = {
        showProjectList: true,
        showUserInfo: false,
    };

    logOut = () => {
        appStore.clear();
        this.props.userStore.setIsLogin(false);
    }

    render() {
        const menu = (
            <Menu className="setting-menu">
                {this.props.showProjectList && (
                    <Menu.Item key="1" icon={<UnorderedListOutlined />} onClick={() => this.props.history.push(`/projects`)}>
                        项目列表
                    </Menu.Item>
                )}
                {this.props.showUserInfo && (
                    <Menu.Item key="2" icon={<UserOutlined />} onClick={this.props.onUserSettingClick}>
                        个人设置
                    </Menu.Item>
                )}
                <Menu.Item key="3" icon={<LogoutOutlined />} onClick={this.logOut}>
                    退出登录
                </Menu.Item>
            </Menu>
        );

        let userName = this.props.userStore._userInfo?.name || '';

        return (
            <Dropdown overlay={menu} placement="bottomRight">
                <span className="action account">
                    <Avatar style={{ backgroundColor: '#fde3cf' }} className="avatar" size="small" icon={<UserOutlined />} />
                    <span className="name">{userName}</span>
                </span>
            </Dropdown>
        )
    }
}

export default withRouter(UserDropdown);