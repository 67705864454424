import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import MobjectStore from '@/store/mobject-store'
import { quantityGroup, mobject, pattern, globalPattern } from '@/interface/mobject'
import { queryMobjectDetail, queryQuantityGroups, queryMobjectPatternList, deletePattern, createPattern } from '@/config/api/mobject';
import { querySectionBySid } from '@/config/api/model';
import { ProjectContext } from '@/config/context';
import axios from '@/config/axios';
import { section } from '@/interface/model';
import { queryGlobalPatterns } from '@/config/api/global-mobject';
import { queryQuantities, createQuantities } from '@/config/api/quantity';
import { Button, Space, message } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import { globalQuantity, simpleGlobalQuantity, quantity } from '@/interface/quantity';
import { paramDict2Array, paramArrary2Dict, handleQuantityName, transferParamsToDisplayScale, transferParamsToBackendScale } from '@/utils/utils';
import { paramObj } from '@/interface/std';
import { NumericInput } from '..';
import { InlineMath } from 'react-katex';
import { patternPicStaticLocation } from '@/config/api/document';
import { ParamUnits } from '@/utils/unit-type';
import { projectStart } from '@/config/api/project';
import ProjectStore from '@/store/project-store';

export const DistributionTypes = {
    MACRO: 1,
    SENSOR_DIRECT: 2, // 直接下属直接物理量
    SENSOR_MICRO: 3,  // 下属点指标
    MICRO: 4,
    MICRO_DYNAMIC: 5,   // 动态特性的点指标，因为计算公式体系不同
}

export const QuantityLevelTypes = {
    SENSOR_SQ: 1,
    SENSOR_MQ: 1,
    DIRECT_Q: 2,
    OTHER_Q: 3,
}

interface IProps {
    mobjectStore?: MobjectStore
    projectStore?: ProjectStore
    onSave?: () => void,
    onPrevious?: () => void,
}

interface IState {
    quantityGroups: quantityGroup[]
    patterns: pattern[]
    quantities: quantity[] // 当前已经存在的指标
    patternParamsDict: { [key: number]: paramObj[] }, // 缓存模式参数
    globalQuantitiesTree: simpleGlobalQuantity[], // 缓存模式指标列表及其相互依赖关系
    userDefineGlobalQuantities: simpleGlobalQuantity[], // 缓存用户自定义的指标
}

@inject('mobjectStore', 'projectStore')
@observer
export default class QuantityPatternSetting extends Component<IProps, IState> {
    static contextType = ProjectContext;
    previousMobjectId: number = 0;
    projectGlobalQuantities = [];
    patternParamsDict: { [key: number]: paramObj[] } = {};
    state = {
        quantityGroups: [],
        patterns: [],
        quantities: [],
        patternParamsDict: {},
        globalQuantitiesTree: [],
        userDefineGlobalQuantities: [],
    }

    componentDidMount() {
        this.generateData();
    }
    componentDidUpdate() {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        if (this.previousMobjectId !== mobjectId) {
            this.setState({
                quantityGroups: [],
                patterns: [],
                quantities: [],
                patternParamsDict: {},
                globalQuantitiesTree: [],
                userDefineGlobalQuantities: [],
            })
            this.generateData();
        }
    }
    generateData = () => {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        this.previousMobjectId = mobjectId;
        let mobjectObj: mobject;
        queryMobjectDetail(mobjectId)
            .then(res => {
                // 拿到对象
                let mobjectDetail: mobject = res.data;
                mobjectObj = mobjectDetail;
                // this.props.mobjectStore.setMojectDetail(mobjectDetail);
                return mobjectDetail;
            }).then((mobjectDetail: mobject) => {
                // 请求group、section、patterns、projectGlobalQuantities
                if (!!mobjectDetail.section_sid) {
                    this.findSectionsToGeneratePatterns(mobjectDetail);
                } else {
                    this.generatePatterns(mobjectDetail, null);
                }
            })
    }

    findSectionsToGeneratePatterns = (mobjectDetail: mobject) => {
        querySectionBySid(this.context, mobjectDetail.section_sid)
            .then(res => {
                let sectionDetail: section = res.data;
                this.generatePatterns(mobjectDetail, sectionDetail);
            })
    }

    generatePatterns = (mobjectDetail: mobject, sectionDetail: section) => {
        let mobjectId = mobjectDetail.id;
        let queryGroupsPromise = queryQuantityGroups(mobjectDetail.id, {
            project_id: this.context
        });
        let queryPatternsPromise = queryMobjectPatternList(mobjectDetail.id, {
            project_id: this.context
        });
        let globalQuantitiesPromise = queryProjectGlobalQuantities(this.context);
        axios.all([queryGroupsPromise, queryPatternsPromise, globalQuantitiesPromise])
            .then(res => {
                let quantityGroups: quantityGroup[] = res[0].data;
                quantityGroups.sort((a, b) => a.distribution_type - b.distribution_type);
                let patterns: pattern[] = res[1].data;
                this.projectGlobalQuantities = res[2].data;
                // group和pattern对号入座一一对应
                quantityGroups.forEach(group => {
                    group.current_pattern = patterns.find(item => item.quantity_group_id === group.id);
                })
                this.setState({
                    quantityGroups: quantityGroups,
                    patterns: patterns,
                })
                // 请求group对应的globalpatternlist
                let globalPatternsPromiseList = [];
                quantityGroups.forEach(item => {
                    let promise = queryGlobalPatterns({
                        mobject_type: mobjectDetail.type,
                        mobject_subtype: mobjectDetail.sub_type,
                        section_shape_type: sectionDetail?.shape_type || null,
                        group_type: item.type,
                    });
                    globalPatternsPromiseList.push(promise);
                })
                return axios.all([...globalPatternsPromiseList]);
            }).then(res => {
                this.state.quantityGroups.forEach((item: quantityGroup, idx: number) => {
                    item.global_patterns = res[idx].data;
                    // 配置pattern的params字典
                    item.global_patterns.forEach(pattern => {
                        let params = paramDict2Array(pattern.params);
                        params = transferParamsToDisplayScale(params)
                        this.patternParamsDict[pattern.type] = params;
                    })
                    // 把params当前值替换进入默认值
                    // this.patternParamsDict[item.current_global_pattern?.type] = paramDict2Array(item.current_pattern?.params);
                })
                // 请求group对应的quantitylist
                return queryQuantities(this.context, {
                    mobject_id: mobjectId,
                });
            }).then(res => {
                let quantities: quantity[] = res.data;
                this.setState({
                    // 放在这里存，后面的generate要用
                    quantities: quantities,
                })
                this.state.quantityGroups.forEach((item: quantityGroup, idx: number) => {
                    item.quantities = quantities.filter(q => q.group_id === item.id);
                })
                // 生成默认的slide位置, 即 current_global_pattern
                this.generateCurrentSlide();
                // 生成当前的指标关联树
                this.generateGlobalQuantitiesTree();
                // 生成当前的Micro指标关联树
                this.generateUserDefineTree();
                // 强制刷新
                this.setState({
                    quantityGroups: [...this.state.quantityGroups],
                    quantities: quantities,
                    patternParamsDict: Object.assign({}, this.patternParamsDict)
                })
            }).catch(err => {
                message.error('获取数据失败');
                console.log(err);
            })
    }

    generateCurrentSlide = () => {
        this.state.quantityGroups.forEach((item: quantityGroup, idx) => {
            let position = 0;
            if (!!item.current_pattern) {
                position = item.global_patterns.findIndex(pattern => pattern.type === item.current_pattern.type);
            }
            if (position < 0) position = 0;
            item.current_global_pattern = item.global_patterns[position];
            // 把params当前值替换进入默认值
            if (!!item.current_pattern?.params) {
                let params = paramDict2Array(item.current_pattern?.params);
                params = transferParamsToDisplayScale(params);
                this.patternParamsDict[item.current_global_pattern?.type] = params;
            }
        })
    }

    generateGlobalQuantitiesTree = () => {
        let qtree: simpleGlobalQuantity[] = [];
        let groups = this.state.quantityGroups;
        groups.forEach((group: quantityGroup) => {
            let gPattern = group.current_global_pattern;
            // 对于除了4类模式和5类模式，生成每个模式下的输出指标
            if (group.distribution_type !== DistributionTypes.MICRO && group.distribution_type !== DistributionTypes.MICRO_DYNAMIC) {
                let simpleQuantityList = gPattern?.quantity_out || [];
                // 遍历group下当前gPattern的所有输出量，添加至指标树中
                simpleQuantityList.forEach((simpleQuantity: simpleGlobalQuantity) => {

                    let input_keys: string[] = [];
                    simpleQuantity.quantity_in.forEach((q_in: simpleGlobalQuantity) => {
                        input_keys.push(`${q_in.type}_${q_in.name}_${q_in.location || ''}`)
                    })
                    let key = `${simpleQuantity.type}_${simpleQuantity.name}_${simpleQuantity.location || ''}`
                    let existed_quantity = this.state.quantities.find((q: quantity) => {
                        let q_key = `${q.type}_${q.name}_${q.location || ''}`;
                        if (q_key === key) return true;
                        return false;
                    })
                    let item = Object.assign({}, simpleQuantity, {
                        key: key,
                        input_keys: input_keys,
                        existed_id: !!existed_quantity ? existed_quantity.id : null,
                        activated: true,
                        group_id: group.id,
                        mobject_id: group.mobject_id,
                    })
                    qtree.push(item);
                    // 对于诸如挠度倾角传感器分布模式，需要同时生成对应的输入量
                    if (group.distribution_type === DistributionTypes.SENSOR_DIRECT || group.distribution_type === DistributionTypes.SENSOR_MICRO) {
                        // 遍历每个输入量
                        simpleQuantity.quantity_in.forEach((q_in: simpleGlobalQuantity) => {
                            let key = `${q_in.type}_${q_in.name}_${q_in.location}`;
                            // 寻找是否已经进入过qtree
                            if (qtree.findIndex(item => item.key === key) >= 0) {
                                return;
                            }
                            // 寻找是否已经激活过该输入量
                            let existed_q_in = this.state.quantities.find((q: quantity) => {
                                let q_key = `${q.type}_${q.name}_${q.location || ''}`;
                                if (q_key === key) return true;
                                return false;
                            })
                            let item = Object.assign({}, q_in, {
                                key: `${q_in.type}_${q_in.name}_${q_in.location || ''}`,
                                input_keys: [],
                                existed_id: !!existed_q_in ? existed_q_in.id : null,
                                activated: true,
                                group_id: group.id,
                                mobject_id: group.mobject_id,
                            })
                            qtree.push(item);
                        })
                    }
                })
            }
            // 对于4类模式和5类模式，寻找对应的所有上级group，生成对应的点指标和直接物理量
            if (group.distribution_type === DistributionTypes.MICRO || group.distribution_type === DistributionTypes.MICRO_DYNAMIC) {
                // 4类和5类模式的输入和输出量的simple-global-quantity均只有一个，输出量通过在tree中查找对应的type+name， 将其group_id转入到当前group中
                let q_out_origin = group.current_global_pattern.quantity_out[0];    // 拿到global-pattern的输出，只有1个
                let q_in_origin = q_out_origin?.quantity_in[0];                     // 拿到global-pattern的输入，只有1个
                let q_outList = qtree.filter(item => item.type === q_out_origin?.type && item.name === q_out_origin?.name);
                q_outList.forEach(item => {
                    let key = `${q_in_origin.type}_${q_in_origin.name}_${item.location || ''}`;
                    // 将其group_id转入到当前group中
                    item.group_id = group.id;
                    item.input_keys = [key];
                    // 寻找是否已经生成过该输入量
                    let existed_q_in = this.state.quantities.find((q: quantity) => {
                        let q_key = `${q.type}_${q.name}_${q.location || ''}`;
                        if (q_key === key) return true;
                        return false;
                    })
                    // 生成对应的q_in
                    let q_in = Object.assign({}, q_in_origin, {
                        location: item.location,
                        key: key,
                        input_keys: [],
                        existed_id: !!existed_q_in ? existed_q_in.id : null,
                        activated: true,
                        group_id: group.id,
                        mobject_id: group.mobject_id,
                        // level: 2 // TODO: 模拟数据时使用
                    })
                    qtree.push(q_in);
                })
            }
        })
        this.setState({
            globalQuantitiesTree: qtree
        })
    }

    // 对于4类和5类pattern，生成初始的user_define tree
    generateUserDefineTree = () => {
        let userQTree: simpleGlobalQuantity[] = [];
        let qTree = this.state.globalQuantitiesTree;
        let groups = this.state.quantityGroups.filter((group: quantityGroup) => group.distribution_type === DistributionTypes.MICRO || group.distribution_type === DistributionTypes.MICRO_DYNAMIC);
        groups.forEach((group: quantityGroup) => {
            let quantities = group.quantities;
            let q_out_origin = group.current_global_pattern.quantity_out[0];
            let q_in_origin = q_out_origin?.quantity_in[0];
            // 生成输出指标
            quantities.filter(q => q.level === QuantityLevelTypes.OTHER_Q)
                .filter(q => q.location.indexOf('user') >= 0)
                .forEach((q: quantity) => {
                    let key_out = `${q.type}_${q.name}_${q.location || ''}`;
                    let key_in = `${q_in_origin.type}_${q_in_origin.name}_${q.location || ''}`;
                    let q_out = Object.assign({}, q, {
                        key: key_out,
                        input_keys: [key_in],
                        existed_id: q.id,
                        activated: true,
                        group_id: group.id,
                        mobject_id: group.mobject_id,
                    });
                    userQTree.push(q_out);
                })
            quantities.filter(q => q.level === QuantityLevelTypes.DIRECT_Q)
                .filter(q => q.location.indexOf('user') >= 0)
                .forEach((q: quantity) => {
                    let key_in = `${q.type}_${q.name}_${q.location || ''}`;
                    let q_in = Object.assign({}, q, {
                        key: key_in,
                        input_keys: [],
                        existed_id: q.id,
                        activated: true,
                        group_id: group.id,
                        mobject_id: group.mobject_id,
                    });
                    qTree.push(q_in);
                })
        })
        this.setState({
            userDefineGlobalQuantities: userQTree,
            globalQuantitiesTree: [...qTree],
        })
    }

    // 查找distribution_typ为micro中需要自动展示的输出指标
    findMicroQuantityGlobalQuantityTree = (group: quantityGroup) => {
        return this.state.globalQuantitiesTree.filter((item: simpleGlobalQuantity) => (
            item.group_id === group.id &&
            item.level === QuantityLevelTypes.OTHER_Q
        ));
    }

    // 创建用户自定义指标， 特指distribution_Type为micro（4）的group
    createUserDefineQuantity = (group: quantityGroup) => {
        if (group.distribution_type !== DistributionTypes.MICRO && group.distribution_type !== DistributionTypes.MICRO_DYNAMIC) return;
        let q_out_origin = group.current_global_pattern.quantity_out[0];
        let q_in_origin = q_out_origin?.quantity_in[0];
        // 查找location的数字部分
        let locationIndex: number[] = [];
        this.state.userDefineGlobalQuantities.forEach((item: simpleGlobalQuantity) => {
            let idx = +item.location.replace(/user/, '');
            if (!isNaN(idx)) locationIndex.push(idx);
        })
        let current = 1;
        if (locationIndex.length > 0) current = Math.max(...locationIndex) + 1;
        // 生成指标
        let key_out = `${q_out_origin.type}_${q_out_origin.name}_user${current}`;
        let key_in = `${q_in_origin.type}_${q_in_origin.name}_user${current}`;
        let existed_q_out = this.state.quantities.find((q: quantity) => {
            let q_key = `${q.type}_${q.name}_${q.location || ''}`;
            if (q_key === key_out) return true;
            return false;
        })
        let q_out = Object.assign({}, q_out_origin, {
            location: `user${current}`,
            key: key_out,
            input_keys: [key_in],
            existed_id: !!existed_q_out ? existed_q_out.id : null,
            activated: true,
            group_id: group.id,
            mobject_id: group.mobject_id,
        });
        let existed_q_in = this.state.quantities.find((q: quantity) => {
            let q_key = `${q.type}_${q.name}_${q.location || ''}`;
            if (q_key === key_in) return true;
            return false;
        })
        let q_in = Object.assign({}, q_in_origin, {
            location: `user${current}`,
            key: key_in,
            input_keys: [],
            existed_id: !!existed_q_in ? existed_q_in.id : null,
            activated: true,
            group_id: group.id,
            mobject_id: group.mobject_id,
            level: 2 // TODO: 模拟数据时使用
        })
        this.setState({
            userDefineGlobalQuantities: [...this.state.userDefineGlobalQuantities, q_out],
            globalQuantitiesTree: [...this.state.globalQuantitiesTree, q_in],
        })
    }

    // 删除用户自定义指标
    deleteUserDefineQuantity = (quantity: simpleGlobalQuantity) => {
        let key_out = quantity.key;
        let key_in = quantity.input_keys[0];
        let userDefineTree = this.state.userDefineGlobalQuantities.filter(item => item.key !== key_out);
        let qTree = this.state.globalQuantitiesTree.filter(item => item.key !== key_in);
        this.setState({
            userDefineGlobalQuantities: userDefineTree,
            globalQuantitiesTree: qTree,
        })
    }

    gotoNext = (quantityGroup: quantityGroup) => {
        let globalGroups = quantityGroup.global_patterns;
        let current = quantityGroup.current_global_pattern
        let idx = globalGroups.findIndex(item => item.type === current.type);
        if (idx < 0) return;
        idx += 1;
        if (idx >= globalGroups.length) {
            idx = 0;
        }
        quantityGroup.current_global_pattern = globalGroups[idx];
        this.setState({
            quantityGroups: [...this.state.quantityGroups]
        })
        this.generateGlobalQuantitiesTree();
    }
    gotoPrevious = (quantityGroup: quantityGroup) => {
        let globalGroups = quantityGroup.global_patterns;
        let current = quantityGroup.current_global_pattern
        let idx = globalGroups.findIndex(item => item.type === current.type);
        if (idx < 0 || globalGroups.length <= 0) return;
        idx -= 1;
        if (idx < 0) {
            idx = globalGroups.length - 1;
        }
        quantityGroup.current_global_pattern = globalGroups[idx];
        this.setState({
            quantityGroups: [...this.state.quantityGroups]
        })
        this.generateGlobalQuantitiesTree();
    }

    // 查找指标的中文名
    findQuantityChineseName = (type: number) => {
        let gQuantity: globalQuantity = this.projectGlobalQuantities.find(item => item.type === type);
        if (gQuantity) {
            return gQuantity.chinese_name;
        }
        return '';
    }

    // 用来合并和展示常规模式的输入量，即 合并该模式的各输出指标所有输入量，根据type+name+location
    combineQuantityInName = (gPattern: globalPattern) => {
        let res = [];
        gPattern?.quantity_out.forEach(item => {
            item.quantity_in.forEach(quantity => {
                let name = quantity.location ?
                    `${this.findQuantityChineseName(quantity.type)}${quantity.name}-${quantity.location}` :
                    `${this.findQuantityChineseName(quantity.type)}${quantity.name}`
                res.push(name);
            })
        })
        res = Array.from(new Set(res));
        return res;
    }

    // 当模式参数发生变更时，重新生成并同步模式参数字典
    onParamsInputChange = (value, item: paramObj) => {
        item.value = value;
        this.setState({
            patternParamsDict: Object.assign({}, this.state.patternParamsDict)
        })
    }

    onSaveAndNext = () => {
        let groups = this.state.quantityGroups;
        // 删除旧的pattern
        let deletePatternPromiseList = [];
        // 创建新的pattern
        let createPatternPromiseList = [];
        groups.forEach((group: quantityGroup) => {
            let old_pattern = group.current_pattern;
            if (!!old_pattern) {
                let deletePromise = deletePattern(old_pattern?.id, {
                    project_id: this.context
                });
                deletePatternPromiseList.push(deletePromise);
            }
            let new_pattern: pattern = Object.assign({}, group.current_global_pattern);
            let params = this.state.patternParamsDict[new_pattern.type];
            params = transferParamsToBackendScale(params)
            new_pattern.params = paramArrary2Dict(params);
            new_pattern.quantity_group_id = group.id;
            new_pattern.mobject_id = group.mobject_id;
            let createPromise = createPattern(group.mobject_id, group.id, new_pattern, {
                project_id: this.context,
            });
            createPatternPromiseList.push(createPromise);
        })


        // 创建并关联新的quantity
        let quantityList = [...this.state.globalQuantitiesTree, ...this.state.userDefineGlobalQuantities];
        let quantity_new = quantityList.filter((item: simpleGlobalQuantity) => !item.existed_id);
        quantity_new.forEach(q => {
            if (!!q.id) q.id = null;
        });
        let quantity_activate = quantityList.filter((item: simpleGlobalQuantity) => !!item.existed_id);
        let quantity_inactivate = [];
        this.state.quantities.forEach((item: quantity) => {
            let key = `${item.type}_${item.name}_${item.location || ''}`;
            let idx = quantityList.findIndex((q: simpleGlobalQuantity) => q.key === key);
            if (idx < 0) quantity_inactivate.push(item);
        })
        quantity_activate.forEach((item: simpleGlobalQuantity) => item.activated = true);
        quantity_inactivate.forEach((item: simpleGlobalQuantity) => item.activated = false);
        let createQuantitiesPromise = createQuantities(this.context, {
            quantity_new: quantity_new,
            quantity_activate: quantity_activate,
            quantity_inactivate: quantity_inactivate,
        }, { mobject_id: +this.props.mobjectStore._selectedMobjectId })
        axios.all([...deletePatternPromiseList, ...createPatternPromiseList, createQuantitiesPromise])
            .then(res => {
                this.restartProject();
                this.props.onSave();
            }).catch(err => {
                message.error('更新失败');
            })

    }

    restartProject = () => {
        if (!this.props.projectStore._projectWorkStatus) {
            return;
        }
        const projectId = this.context;
        projectStart(projectId)
            .then(res => {
                if (res.data) {
                    this.props.projectStore.setProjectWorkStatus(true);
                } else {
                    throw new Error('开启失败');
                }
            }).catch(err => {
                // if (!!err.data) {
                //     message.error(err.data?.message);
                // } else {
                //     message.error(err);
                // }
                console.log(err);
            })
    }

    render() {
        let mobjectDetail = this.props.mobjectStore._mobjectDetail;
        if (this.previousMobjectId !== mobjectDetail?.id) return null;

        const NormalPane = (quantityGroup: quantityGroup) => (
            <Fragment>
                <div className="pattern-content">
                    <div className="chinese-name">{quantityGroup.current_global_pattern?.name}</div>
                    {/* <div className="english-name">{quantityGroup.current_global_pattern?.english_name}</div> */}
                    <div>
                        <div className="pattern-quantity-title">输出指标</div>
                        {quantityGroup.current_global_pattern?.quantity_out.map((quanitity_out, idx) => (
                            <div className="quantity-label" key={idx}>
                                {quanitity_out.location ?
                                    <span>{`${this.findQuantityChineseName(quanitity_out.type)}${quanitity_out.name}-${quanitity_out.location}`}</span> :
                                    <span>{quanitity_out.name}</span>
                                }

                            </div>
                        ))}
                    </div>
                    <div>
                        <div className="pattern-quantity-title">输入量</div>
                        {this.combineQuantityInName(quantityGroup.current_global_pattern).map((item, idx) => (
                            <div className="quantity-label" key={idx}>
                                <span>{item}</span>
                            </div>
                        ))}
                    </div>

                </div>
                <div className="pattern-params">
                    <div className="pattern-quantity-title">定位参数</div>
                    {this.state.patternParamsDict[quantityGroup.current_global_pattern?.type]?.map((item: paramObj, idx: number) => (
                        <div className="params-item" key={idx}>
                            <span className="label">{
                                <InlineMath math={handleQuantityName(item.name)} />
                            }</span>
                            <NumericInput
                                size="small"
                                value={item.value.toString()}
                                onChange={(value) => this.onParamsInputChange(value, item)}
                                suffix={<span>{ParamUnits[item.name]}</span>} />
                        </div>
                    ))}
                </div>
            </Fragment>
        );
        const MicroGroupPane = (quantityGroup: quantityGroup) => (
            <Fragment>
                <div className="pattern-content">
                    <div className="chinese-name">{quantityGroup.current_global_pattern?.name}</div>
                    {this.findMicroQuantityGlobalQuantityTree(quantityGroup).length > 0 ? (
                        <div>
                            <div className="pattern-quantity-title">输出指标（自动）</div>
                            {this.findMicroQuantityGlobalQuantityTree(quantityGroup)
                                .map((quantity: simpleGlobalQuantity, idx: number) => (
                                    <div className="quantity-label" key={idx}>
                                        {quantity.location ?
                                            <span>{`${this.findQuantityChineseName(quantity.type)}${quantity.name}-${quantity.location}`}</span> :
                                            <span>{quantity.name}</span>
                                        }

                                    </div>
                                ))}
                        </div>
                    ) : null}
                    <div>
                        <div className="pattern-quantity-title">自定义</div>
                        {this.state.userDefineGlobalQuantities
                            .filter((item: simpleGlobalQuantity) => item.group_id === quantityGroup.id && item.level === QuantityLevelTypes.OTHER_Q)
                            .map((quantity: simpleGlobalQuantity, idx: number) => (
                                <div className="quantity-label" key={idx}>
                                    {quantity.location ?
                                        <span>{`${this.findQuantityChineseName(quantity.type)}${quantity.name}-${quantity.location}`}</span> :
                                        <span>{quantity.name}</span>
                                    }
                                    <Button
                                        type="link"
                                        size="small"
                                        icon={<CloseOutlined />}
                                        style={{ marginLeft: '6px' }}
                                        onClick={() => this.deleteUserDefineQuantity(quantity)}>
                                    </Button>
                                </div>
                            ))}
                        <Button size="small" type="dashed" onClick={() => this.createUserDefineQuantity(quantityGroup)}><PlusOutlined />添加</Button>

                    </div>
                </div>
                <div className="pattern-params">
                    <div className="pattern-quantity-title">直接物理量（自动）</div>
                    {this.state.globalQuantitiesTree
                        .filter((item: simpleGlobalQuantity) => item.group_id === quantityGroup.id && item.level === QuantityLevelTypes.DIRECT_Q)
                        .map((quantity: simpleGlobalQuantity, idx: number) => (
                            <div className="quantity-label" key={idx}>
                                {quantity.location ?
                                    <span>{`${this.findQuantityChineseName(quantity.type)}${quantity.name}-${quantity.location}`}</span> :
                                    <span>{quantity.name}</span>
                                }

                            </div>
                        ))}
                </div>
                <div className="pattern-params">
                    <div className="pattern-quantity-title">参数</div>
                    {this.state.patternParamsDict[quantityGroup.current_global_pattern?.type]?.map((item: paramObj, idx: number) => (
                        <div className="params-item" key={idx}>
                            <span className="label">{
                                <InlineMath math={handleQuantityName(item.name)} />
                            }</span>
                            <NumericInput
                                size="small"
                                value={item.value.toString()}
                                onChange={(value) => this.onParamsInputChange(value, item)}
                                suffix={<span>{ParamUnits[item.name]}</span>} />
                        </div>
                    ))}
                </div>
            </Fragment>
        )
        return (
            <div className="quantity-step-item step-two">
                {this.state.quantityGroups?.map((quantityGroup: quantityGroup, idx: number) => (
                    <Fragment key={quantityGroup.id}>
                        <div className="quantity-group-title">{quantityGroup.name}</div>
                        <div className="quantity-group-item">
                            <div className="pattern-figure-select">
                                <Button className="change-figure-button" type="link" onClick={() => this.gotoPrevious(quantityGroup)} icon={<CaretLeftOutlined style={{ fontSize: '20px' }} />}></Button>
                                <div className="figure" style={{ height: '240px', width: '320px' }}>
                                    {
                                        quantityGroup.current_global_pattern?.figure_url ?
                                            <img
                                                width="100%"
                                                height="100%"
                                                alt="模式图"
                                                src={`${patternPicStaticLocation}${quantityGroup.current_global_pattern?.figure_url}`} />
                                            :
                                            null
                                    }
                                </div>

                                <Button className="change-figure-button" type="link" onClick={() => this.gotoNext(quantityGroup)} icon={<CaretRightOutlined style={{ fontSize: '20px' }} />}></Button>
                            </div>
                            {quantityGroup.distribution_type === DistributionTypes.MICRO ||
                                quantityGroup.distribution_type === DistributionTypes.MICRO_DYNAMIC ?
                                MicroGroupPane(quantityGroup) : NormalPane(quantityGroup)}
                        </div>
                    </Fragment>

                ))}
                <div className="steps-action">
                    <Space size="large">
                        <Button onClick={this.props.onPrevious}>上一步</Button>
                        <Button type="primary" onClick={this.onSaveAndNext}>保存并下一步</Button>
                    </Space>
                </div>
            </div>
        )
    }
}
