import React, { Component } from 'react';
import './device-manage-history.less';
import { Select, Timeline } from 'antd';
import { manageHistory } from '@/interface/dbox';
import dayjs from 'dayjs';

const { Option } = Select;

interface IProps {
    currentStatus?: number,
    statusHistory?: manageHistory[],
    onChange?: (value: number) => void,
}

export default class DeviceManageHistory extends Component<IProps> {
    render() {
        return (
            <div className="device-manage-history">
                <div className="title">现场状态</div>
                <Select 
                defaultValue={this.props.currentStatus} 
                style={{ width: '100%' }}
                onChange={this.props.onChange}>
                    <Option value={1}>在库</Option>
                    <Option value={2}>已安装</Option>
                    <Option value={3}>已验收</Option>
                    <Option value={4}>返工或维修</Option>
                    <Option value={5}>已拆除</Option>
                    <Option value={6}>已回收</Option>
                    <Option value={7}>报废或丢失</Option>
                </Select>
                <Timeline className="manage-timeline">
                    {this.props.statusHistory.map((item, index) => (
                        <Timeline.Item key={index}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <span>{`${item.content}`}</span>
                                <span>{`${dayjs(+item.create_time).format('YYYY-MM-DD HH:mm')}`}</span>
                                {/* <span>{item.create_time}</span> */}
                            </div>
                        </Timeline.Item>
                    ))}
                </Timeline>
            </div>
        )
    }
}
