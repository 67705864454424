import { updateNativeLicense } from '@/config/api/native-license';
import { Gprops } from '@/config/props';
import { Button, Form, Input, message, Space } from 'antd'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import './index.less';

const { TextArea } = Input;

class NativeAuthLayout extends Component<Gprops> {

    submitLicense = (values) => {
        const hide = message.loading('正在提交...');
        let license = values['auth_license'];
        console.log(license)
        updateNativeLicense(license).then(res => {
            setTimeout(hide, 0);
            message.success('证书添加成功！');
            // this.props.history.push('/projects');
        }).catch(err => {
            setTimeout(hide, 0);
            message.error('证书添加失败');
        })
    }

    render() {
        return (
            <div className="native-auth-layout">
                <div className="content">
                    <Form autoComplete="off" onFinish={this.submitLicense}>
                        <Form.Item name="auth_license" rules={[{required: true, message: '请输入证书'}]} >
                            <TextArea rows={8} placeholder="将licence复制入该对话框中"/>
                        </Form.Item>
                        <div>
                            <Space>
                                <Button type="primary" htmlType='submit'>保存</Button>
                            </Space>
                        </div>
                    </Form>
                </div>

            </div>
        )
    }
}

export default withRouter(NativeAuthLayout)