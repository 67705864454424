import React, { Component, Fragment } from 'react'
import { Space, Select } from 'antd'
import { DatePicker } from '..';
import { inject, observer } from 'mobx-react';
import SensorStore from '@/store/sensor-store';
import { QuantityDataTypes } from '../charts/line-chart-board';
import dayjs from 'dayjs';
const { Option } = Select;
const { RangePicker } = DatePicker;

interface IProps {
    sensorStore?: SensorStore;
    onQueryParamsChange?: () => void
}
@inject('sensorStore')
@observer
export default class SensorDataTool extends Component<IProps> {

    onSamplingChange = (value) => {
        this.props.sensorStore.setSamplingPeriod(value);
        this.props.onQueryParamsChange();
    }

    onDataTypeChange = (value) => {
        this.props.sensorStore.setDataType(value);
        this.props.onQueryParamsChange();
    }

    onRangePickerChange = (dates: [dayjs.Dayjs, dayjs.Dayjs]) => {
        if (!!dates) {
            this.props.sensorStore.setStartTime(dates[0]?.toDate() || null);
            this.props.sensorStore.setEndTime(dates[1]?.toDate() || null);
        } else {
            this.props.sensorStore.setStartTime(null);
            this.props.sensorStore.setEndTime(null);
        }

        this.props.onQueryParamsChange();
    }

    onStartTimeChange = (value: dayjs.Dayjs) => {
        this.props.sensorStore.setStartTime(value?.toDate() || null);
        this.props.onQueryParamsChange();
    }

    onEndTimeChange = (value: dayjs.Dayjs) => {
        this.props.sensorStore.setEndTime(value?.toDate() || null);
        this.props.onQueryParamsChange();
    }

    render() {
        let sensorStore = this.props.sensorStore;
        return (
            <Space size="middle">
                <Select
                    placeholder="显示降采样"
                    style={{ width: 150 }}
                    value={sensorStore._samplingPeriod}
                    allowClear
                    onChange={this.onSamplingChange}>
                    <Option value={-1}>原采样</Option>
                    <Option value={1}>1秒</Option>
                    <Option value={30}>30秒</Option>
                    <Option value={60}>1分钟</Option>
                    <Option value={300}>5分钟</Option>
                    <Option value={600}>10分钟</Option>
                    <Option value={900}>15分钟</Option>
                    <Option value={1800}>30分钟</Option>
                    <Option value={3600}>1小时</Option>
                    <Option value={43200}>12小时</Option>
                    <Option value={86400}>24小时</Option>
                    {/* <Option value={-1}>自动(默认)</Option> */}
                </Select>
                <Select
                    placeholder="降采样特征"
                    style={{ width: 150 }}
                    value={sensorStore._dataType}
                    onChange={this.onDataTypeChange}>
                    <Option value={QuantityDataTypes.MAX}>最大值</Option>
                    <Option value={QuantityDataTypes.MIN}>最小值</Option>
                    <Option value={QuantityDataTypes.MEAN}>平均值</Option>
                    <Option value={QuantityDataTypes.NORMAL}>最近值(默认)</Option>
                </Select>
                <Fragment>
                    <DatePicker
                        showTime
                        placeholder="开始时间"
                        onChange={this.onStartTimeChange}
                        value={sensorStore._startTime ? dayjs(sensorStore._startTime) : null} />
                    <DatePicker
                        showTime
                        placeholder="结束时间"
                        onChange={this.onEndTimeChange}
                        value={sensorStore._endTime ? dayjs(sensorStore._endTime) : null} />
                </Fragment>
                {/* <RangePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    value={[
                        sensorStore._startTime ? dayjs(sensorStore._startTime) : undefined,
                        sensorStore._startTime ? dayjs(sensorStore._endTime) : undefined]}
                    onChange={this.onRangePickerChange}
                /> */}
            </Space>
        )
    }
}
