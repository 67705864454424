import React, { Component, Fragment } from 'react'
import { withRoutePane } from '@/components/hoc/routePane'
import './setting-quantity.less';
import { globalQuantity } from '@/interface/quantity';
import { Table, message } from 'antd';
import { paramDict2Array, handleQuantityName, Transfer2DisplayScale, Transfer2BackEndScale } from '@/utils/utils';
import { UnitMap } from '@/utils/unit-type';
import { InlineMath } from 'react-katex';
import { EditableRow, EditableCell } from '@/components';
import { ProjectContext } from '@/config/context';
import { queryProjectGlobalQuantities, updateProjectGlobalQuantities } from '@/config/api/quantity-setting';
import { QuantityLevelAlias } from '@/utils/type';
const { Column, ColumnGroup } = Table;
interface IProps {

}
interface IState {
    gQuantites: globalQuantity[]
}

class SettingQuantityPage extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        gQuantites: []
    }

    componentDidMount() {
        this.findGlobalQuantities()
    }

    findGlobalQuantities = () => {
        queryProjectGlobalQuantities(this.context)
            .then(res => {
                let gQuantites = res.data.map((item: globalQuantity, idx) => {
                    let scale = Transfer2DisplayScale(item.unit_type);
                    let vd = item.valid_data != null ? item.valid_data * scale : null;
                    return Object.assign(item, {
                        t2: item.warning_limit_params['t2'],
                        t3: item.warning_limit_params['t3'],
                        t4: item.warning_limit_params['t4'],
                        t5: item.warning_limit_params['t5'],
                        valid_data: vd,
                    })
                })
                gQuantites = gQuantites.sort((a, b) => b.level - a.level);
                // console.log(gQuantites)
                this.setState({
                    gQuantites: gQuantites
                })
            })
    }

    handleSave = (row: globalQuantity) => {
        let id = row.id;
        let newData = [...this.state.gQuantites];
        let index = newData.findIndex(item => item.id === id);
        let item: globalQuantity = newData[index];
        let scale = Transfer2BackEndScale(row.unit_type);
        console.log(row.valid_data)
        console.log(scale)
        let valid_data = row.valid_data != null ? row.valid_data * scale : null;
        newData.splice(index, 1, { ...item, ...Object.assign(row, {
            warning_limit_params: {
            t2: row.t2,
            t3: row.t3,
            t4: row.t4,
            t5: row.t5,
            },
        })})
        this.setState({
            gQuantites: [...newData]
        })
        let data = {
            precision: row.precision,
            unit_type: row.unit_type,
            warning_limit_params: {
                t2: row.t2,
                t3: row.t3,
                t4: row.t4,
                t5: row.t5,
            },
            valid_data: valid_data,
        }
        updateProjectGlobalQuantities(this.context, id, data)
        .then(res => {
        }).catch(err => {
            message.error('更新失败');
            newData.splice(index, 1, { 
                ...row, 
                ...Object.assign(item, {
                    t2: item.warning_limit_params['t2'],
                    t3: item.warning_limit_params['t3'],
                    t4: item.warning_limit_params['t4'],
                    t5: item.warning_limit_params['t5'],
                    valid_data: valid_data ? valid_data / scale : null,
                })
            })
            this.setState({
                gQuantites: [...newData]
            })
        })
    }

    generateUnitOptions = (record: globalQuantity) => {
        let map = paramDict2Array(UnitMap[record.unit_type]).map(item => (
            { value: item.name, label: item.name }
        ))
        return map
    }

    handleLimitFunctionName = (name: string) => {
        if (!name) {
            return name;
        }
        let tmp = name.split(/\//);
        let lowers: string = '';
        for (let idx=0;idx<tmp.length;idx++) {
            let res = handleQuantityName(tmp[idx]);
            if (idx > 0) {
                lowers +='/';
            }
            lowers += `{${res}}`
        }
        return lowers
    }

    render() {
        return (
            <div className="global-quantity-container">
                <Table
                    dataSource={this.state.gQuantites}
                    rowKey="id"
                    bordered
                    pagination={false}
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        }
                    }}
                    rowClassName={(record, index) => (
                        'editable-row'
                    )}
                >
                    <Column title="代号" dataIndex="name" render={record => (
                        <InlineMath math={handleQuantityName(record.replace('_mq', ''))} />
                    )} />
                    <Column title="名称" dataIndex="chinese_name" />
                    <Column title="层级" dataIndex="level" align="center" render={record => QuantityLevelAlias[record]} />
                    <Column
                        title="显示精度"
                        dataIndex="precision"
                        width="10%"
                        onCell={(record, idx) => ({
                            record,
                            editable: true,
                            dataIndex: 'precision',
                            type: 'Select',
                            options: [
                                { value: '1', label: '0.1' },
                                { value: '2', label: '0.01' },
                                { value: '3', label: '0.001' },
                                { value: '4', label: '0.0001' },
                                { value: '5', label: '0.00001' },
                                { value: '6', label: '0.000001' },
                            ],
                            title: '显示精度',
                            handleSave: this.handleSave,
                        })}
                        render={(record, idx) => {
                            let tmp = Math.pow(10, record * -1);
                            return <div>{tmp.toFixed(record)}</div>
                        }} />

                    <Column title="评估阈值公式" dataIndex="formula" render={(record) => (
                        record ? <InlineMath math={this.handleLimitFunctionName(record)}/> : null
                    )} />
                    <ColumnGroup title={
                        <Fragment>
                            <span>评估阈值参数</span>
                            <InlineMath math='\alpha' />
                        </Fragment>
                    }>
                        <Column
                            title="二级"
                            dataIndex="t2"
                            width="8%"
                            align="right"
                            onCell={(record: globalQuantity, idx) => ({
                                record,
                                editable: true,
                                dataIndex: 't2',
                                title: '二级',
                                validateDisable: true,
                                handleSave: this.handleSave,
                            })} />
                        <Column
                            title="三级"
                            dataIndex="t3"
                            width="8%"
                            align="right"
                            onCell={(record: globalQuantity, idx) => ({
                                record,
                                editable: true,
                                dataIndex: 't3',
                                title: '三级',
                                validateDisable: true,
                                handleSave: this.handleSave,
                            })} />
                        <Column
                            title="四级"
                            dataIndex="t4"
                            width="8%"
                            align="right"
                            onCell={(record: globalQuantity, idx) => ({
                                record,
                                editable: true,
                                dataIndex: 't4',
                                title: '四级',
                                validateDisable: true,
                                handleSave: this.handleSave,
                            })} />
                        <Column
                            title="五级"
                            dataIndex="t5"
                            width="8%"
                            align="right"
                            onCell={(record: globalQuantity, idx) => ({
                                record,
                                editable: true,
                                dataIndex: 't5',
                                title: '五级',
                                validateDisable: true,
                                handleSave: this.handleSave,
                            })} />
                    </ColumnGroup>
                    <Column
                        title="有效限值"
                        dataIndex="valid_data"
                        width="10%"
                        align="right"
                        onCell={(record: globalQuantity, idx) => ({
                            record,
                            editable: true,
                            dataIndex: 'valid_data',
                            title: '有效限值',
                            validateDisable: true,
                            handleSave: this.handleSave,
                        })}/>
                    <Column
                        title="显示单位"
                        dataIndex="unit_type"
                        width="10%"
                        onCell={(record: globalQuantity, idx) => ({
                            record,
                            editable: true,
                            dataIndex: 'unit_type',
                            type: 'Select',
                            options: this.generateUnitOptions(record),
                            title: '显示单位',
                            handleSave: this.handleSave,
                        })} />

                </Table>
            </div>
        )
    }
}

export default withRoutePane(SettingQuantityPage, "指标设置");