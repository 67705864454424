import React, { Component, Fragment } from 'react'
import { ProjectContext } from '@/config/context';
import { inject, observer } from 'mobx-react';
import MobjectStore from '@/store/mobject-store';
import { mobject, quantityGroup, pattern } from '@/interface/mobject';
import { queryMobjectDetail, queryQuantityGroups, queryMobjectPatternList, updateMobjectDetail, queryMobjectFrequency, updateMobjectFrequency, copyMobject, deleteMobjectDetail } from '@/config/api/mobject';
import { constage } from '@/interface/constage';
import axios from '@/config/axios';
import { DistributionTypes } from './quantity-pattern-setting';
import './mobject-info.less';
import { Space, Button, Tag, Modal, Select, Form, Input, InputNumber, message, Col, Row, Descriptions } from 'antd';
import { paramDict2Array, paramArrary2Dict, handleQuantityName, Transfer2DisplayScale, NumFixed } from '@/utils/utils';
import { MobjectType } from '@/routes/mobject/mobject-page';
import { paramObj } from '@/interface/std';
import { queryQuantities } from '@/config/api/quantity';
import { quantity } from '@/interface/quantity';
import { querySensors } from '@/config/api/sensor';
import { sensor, globalSensor } from '@/interface/sensor';
import { queryGlobalSensors } from '@/config/api/global-device';
import classnames from 'classnames';
import MobjectInfoEdit from './mobject-info-edit';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { MobjectBaseType } from '@/utils/type';
import { section, material } from '@/interface/model';
import { queryMaterialBySid, querySectionBySid } from '@/config/api/model';
import { Gprops } from '@/config/props';
import { withRouter } from 'react-router-dom';
import { patternPicStaticLocation, mobjectPicStaticLocation } from '@/config/api/document';
import { InlineMath } from 'react-katex';
import { ParamUnits } from '@/utils/unit-type';
import ProjectStore from '@/store/project-store';
import { projectStart } from '@/config/api/project';
import dayjs from 'dayjs';
import { MobjectTypeEnum } from './quantity-group-setting';
import UserStore from '@/store/user-store';
import { UserTypeEnum } from '@/routes/login/login';
const { confirm } = Modal;
const { Option } = Select;
export const MobjectSubtypes = {
    BEAM: 201,
    COLUMN: 202,
    LINK: 203,
    CABLE: 204,
    PLATE: 205,
    WALL: 206,

    JOINT_PJ: 101,
    JOINT_LZ: 102,
    JOINT_XG: 103,
    JOINT_ZJ: 104,
    JOINT_ZZ: 105,
    JOINT_WG: 106,

    STRUCT_DK: 301,
    STRUCT_GC: 302,
    STRUCT_GS: 303,
    STRUCT_DC: 304,
    STRUCT_QL: 305,

    ENV_TEMP: 401,
    ENV_HUMID: 402,
    ENV_WIND: 403,
}

export const MobjectSubtypeName = {
    '1': {
        '101': '拼接节点',
        '102': '梁柱节点',
        '103': '相贯节点',
        '104': '柱脚节点',
        '105': '支座节点',
        '106': '网格结构节点',
    },
    '2': {
        '201': '梁',
        '202': '柱',
        '203': '杆',
        '204': '索',
        '205': '板',
        '206': '墙',
    },
    '3': {
        '301': '大跨度结构',
        '302': '多高层结构',
        '303': '高耸结构',
        '304': '单层结构',
        '305': '桥梁结构',
    },
    '4': {
        '401': '温度',
        '402': '湿度',
        '403': '风',
    }
}
interface IProps extends Gprops {
    mobjectStore?: MobjectStore,
    projectStore?: ProjectStore,
    userStore?: UserStore,
}

interface IState {
    mobjectDetail: mobject
    constages: constage[]
    quantityGroups: quantityGroup[],
    mobjectParams: paramObj[],
    editModalVisible: boolean,
    editSaveLoading: boolean,

    sectionDetail: section,
    materialDetail: material,

    frequency: number,

    mobjectCopyModalVisible: boolean,
    mobjectCopyLoading: boolean,
}
@inject('mobjectStore', 'projectStore', 'userStore')
@observer
class MobjectInfoPane extends Component<IProps, IState> {
    static contextType = ProjectContext;
    previousMobjectId: number = 0;
    quantities: quantity[] = [];
    globalSensors: globalSensor[] = [];
    state = {
        mobjectDetail: null,
        constages: [],
        quantityGroups: [],
        mobjectParams: [],
        editModalVisible: false,
        editSaveLoading: false,

        sectionDetail: null,
        materialDetail: null,

        frequency: null,

        mobjectCopyModalVisible: false,
        mobjectCopyLoading: false,
    }

    componentDidMount() {
        this.generateData();
    }

    componentDidUpdate() {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        if (this.previousMobjectId !== mobjectId) {
            this.previousMobjectId = mobjectId;
            this.generateData();
        }
    }

    generateData = () => {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        this.previousMobjectId = mobjectId;
        // let mobjectObj: mobject;
        let queryMobjectPromise = queryMobjectDetail(mobjectId);
        let queryQuantityGroupsPromise = queryQuantityGroups(mobjectId, {
            project_id: this.context,
        });
        let queryPatternsPromise = queryMobjectPatternList(mobjectId, {
            distribution_type: [DistributionTypes.SENSOR_DIRECT, DistributionTypes.SENSOR_MICRO],
            project_id: this.context,
        });
        let queryQuantitiesPromise = queryQuantities(this.context, {
            mobject_id: mobjectId,
            activated: true,
        });
        let queryGlobalSensorsPromise = queryGlobalSensors();
        let queryMobjectFrequencyPromise = queryMobjectFrequency(mobjectId)
        axios.all([
            queryMobjectPromise,
            queryQuantityGroupsPromise,
            queryPatternsPromise,
            queryQuantitiesPromise,
            queryGlobalSensorsPromise,
            queryMobjectFrequencyPromise]).then(res => {
                let mobjectDetail: mobject = res[0].data;
                let constages: constage[] = mobjectDetail?.constages || [];
                constages = constages.sort((a, b) => dayjs(a.start_time).valueOf() - dayjs(b.start_time).valueOf());
                let quantityGroups: quantityGroup[] = res[1].data;
                let patterns: pattern[] = res[2].data;
                let quantities: quantity[] = res[3].data;
                this.globalSensors = res[4].data;
                this.setState({
                    frequency: res[5].data,
                })
                // mobjectDetail.id = mobjectId; // TODO: 开发阶段模拟数据采用
                quantityGroups = quantityGroups.filter(item => item.distribution_type === DistributionTypes.SENSOR_DIRECT || item.distribution_type === DistributionTypes.SENSOR_MICRO);
                // mobjectObj = mobjectDetail;
                let querySensorsPromiseList = [];
                quantityGroups.forEach((group: quantityGroup) => {
                    let currentPattern = patterns.find(item => item.id === group.pattern_id);
                    group.current_pattern = currentPattern;
                    group.quantities = quantities.filter(item => item.group_id === group.id);
                    let promise = querySensors({
                        group_id: group.id,
                        project_id: this.context,
                    })
                    querySensorsPromiseList.push(promise);
                })
                this.quantities = quantities;
                this.setState({
                    mobjectDetail: mobjectDetail,
                    quantityGroups: quantityGroups,
                    mobjectParams: paramDict2Array(mobjectDetail?.params || {}),
                    constages: constages
                });
                this.findSecMat(mobjectDetail);
                // this.findSensors();
                return axios.all([...querySensorsPromiseList])
            }).then(res => {
                this.state.quantityGroups.forEach((group: quantityGroup, idx) => {
                    let sensors: sensor[] = res[idx]?.data || [];
                    group.sensors = sensors;
                })
                this.setState({
                    quantityGroups: [...this.state.quantityGroups]
                })
            })
    }

    findSecMat = (mobjectDetail: mobject) => {
        if (mobjectDetail?.material_sid) {
            let sid = mobjectDetail.material_sid;
            queryMaterialBySid(sid, this.context)
                .then(res => {
                    this.setState({
                        materialDetail: res.data
                    })
                })
        }
        if (mobjectDetail?.section_sid) {
            let sid = mobjectDetail.section_sid;
            querySectionBySid(this.context, sid)
                .then(res => {
                    this.setState({
                        sectionDetail: res.data
                    })
                })
        }
    }

    findSensorTypeName = (type: number) => {
        let global = this.globalSensors.filter(item => item.type === type)[0];
        return global ? global.name : '';
    }

    onEditModalCancel = () => {
        this.setState({
            editModalVisible: false
        })
    }

    onMobjectEditOpen = () => {
        this.setState({
            editModalVisible: true
        })
    }

    onMojectEditSave = (values) => {
        this.setState({
            editSaveLoading: true
        })
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        let data = Object.assign(values, {
            params: paramArrary2Dict(values.params)
        });
        updateMobjectDetail(mobjectId, data)
            .then(res => {
                this.setState({
                    editSaveLoading: false,
                    editModalVisible: false
                })
                this.generateData();
            })
    }

    onMobjectDelete = () => {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        let type = this.state.mobjectDetail?.type;
        let userType = this.props.userStore._userInfo?.type;
        if (!userType || userType === UserTypeEnum.CLIENT || userType === UserTypeEnum.SITE ) {
            message.warning('暂无权限');
            return;
        }
        deleteMobjectDetail(mobjectId)
            .then(res => {
                this.props.mobjectStore.mobjectDeleteCallbackFn(mobjectId);
                if (type === MobjectTypeEnum.MEMBER) {
                    let count = this.props.mobjectStore._mmCount;
                    this.props.mobjectStore.changeMmCount(count-1);
                } else if (type === MobjectTypeEnum.JOINT) {
                    let count = this.props.mobjectStore._jmCount;
                    this.props.mobjectStore.changeJmCount(count-1);
                } else if (type === MobjectTypeEnum.ENVIRONMENT) {
                    let count = this.props.mobjectStore._emCount;
                    this.props.mobjectStore.changeEmCount(count-1);
                } else if (type === MobjectTypeEnum.STRUCTURE) {
                    let count = this.props.mobjectStore._stmCount;
                    this.props.mobjectStore.changeStmCount(count-1);
                }
            }).catch(err => {
                message.error('删除失败');
                console.log(err);
            })
    }

    showDeleteConfirm = () => {
        let projectWorking = this.props.projectStore._projectWorkStatus;
        if (!!projectWorking) {
            Modal.warning({
                title: '当前项目正在监测，无法删除',
                content: '请先关闭项目监测...',
            });
        } else {
            confirm({
                title: '确认删除当前对象?',
                icon: <ExclamationCircleOutlined />,
                content: '',
                okText: '是',
                okType: 'danger',
                cancelText: '否',
                onOk: () => {
                    this.onMobjectDelete()
                },
                onCancel() {

                },
            });
        }

    }

    goToSectionDetail = () => {
        if (!this.state.sectionDetail) return;
        let url = `/projects/${this.context}/models/sections/sid/${this.state.sectionDetail?.sid}`;
        this.props.history.push(url);
    }

    goToMaterialDetail = () => {
        if (!this.state.materialDetail) return;
        let url = `/projects/${this.context}/models/materials/sid/${this.state.materialDetail?.sid}`;
        this.props.history.push(url);
    }

    onFrequencyChange = (value: number) => {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        updateMobjectFrequency(mobjectId, {
            frequency: value
        }).then(res => {
            this.setState({
                frequency: value
            })
            const projectWorkStatus = this.props.projectStore._projectWorkStatus;
            if (projectWorkStatus) {
                this.restartProject();
            }
        })
    }

    restartProject = () => {
        const projectId = this.context;
        projectStart(projectId)
            .then(res => {
                if (res.data) {
                    this.props.projectStore.setProjectWorkStatus(true);
                } else {
                    throw new Error('开启失败');
                }
            }).catch(err => {
                // if (!!err.data) {
                //     message.error(err.data?.message);
                // } else {
                //     message.error(err);
                // }
                console.log(err);
            })
    }

    onMobjectCopyConfirm = (values) => {
        this.setState({
            mobjectCopyLoading: true,
        })
        let mobjectId = this.state.mobjectDetail?.id;
        if (!mobjectId) {
            message.error('发生错误');
            this.setState({
                mobjectCopyLoading: false,
            })
            return;
        }
        let prefix = values.prefix;
        let start: number = +values.start;
        let count: number = +values.count;
        let promiseList = [];
        for (let idx = 0; idx < count; idx++) {
            let name = `${prefix}${start + idx}`;
            let promise = copyMobject(mobjectId, name);
            promiseList.push(promise);
        }
        axios.all([...promiseList])
            .then(res => {
                this.props.mobjectStore.setForceRender(true);
                this.setState({
                    mobjectCopyLoading: false,
                    mobjectCopyModalVisible: false,
                })
                const projectWorkStatus = this.props.projectStore._projectWorkStatus;
                if (projectWorkStatus) {
                    this.restartProject();
                }
            }).catch(err => {
                message.error(err?.data?.message);
                console.log(err);
                this.setState({
                    mobjectCopyLoading: false,
                })
            })
    }

    // TODO: 通过信号关联来间接查找group对应的传感器
    // findSensors = () => {
    //     this.state.quantityGroups.forEach((group: quantityGroup) => {
    //         let queryMQPromiseList = [];
    //         let sensorIds: number[] = [];
    //         if (group.distribution_type === DistributionTypes.SENSOR_DIRECT) {
    //             let res: number[] = this.findMqIdInDistribution2(group);
    //             res.forEach(mq_id => {
    //                 let promise = querySensorQuantityDetail(this.context, mq_id);
    //                 queryMQPromiseList.push(promise);

    //             })
    //         }
    //         if (group.distribution_type === DistributionTypes.SENSOR_MICRO) {
    //             let res: number[] = this.findMqIdInDistribution3(group);
    //             res.forEach(mq_id => {
    //                 let promise = querySensorQuantityDetail(this.context, mq_id);
    //                 queryMQPromiseList.push(promise);
    //             })
    //         }
    //         axios.all([...queryMQPromiseList])
    //             .then(res => {
    //                 sensorIds = res.map(item => item.data?.sensor_id);
    //                 sensorIds = Array.from(new Set(sensorIds));
    //                 console.log(sensorIds)
    //                 let promiseList = [];
    //                 sensorIds.forEach((id: number) => {
    //                     let promise = querySensorDetail(id);
    //                     promiseList.push(promise);
    //                 })
    //                 axios.all([...promiseList])
    //                     .then(res2 => {
    //                         console.log(res2)
    //                         let sensors: sensor[] = res2.map(item => item.data);
    //                         group.sensors = sensors;
    //                         this.setState({
    //                             quantityGroups: [...this.state.quantityGroups]
    //                         })
    //                     })
    //             })
    //     })
    // }

    // findMqIdInDistribution2 = (group: quantityGroup) => {
    //     let ids: number[] = [];
    //     let quantities = group.quantities;
    //     quantities.forEach((q: quantity) => {
    //         let in_ids: number[] = q.quantity_in_id;
    //         // 获得直接物理量列表
    //         // console.log(in_ids)
    //         // console.log(this.quantities)
    //         let q_ins: quantity[] = this.quantities.filter((item: quantity) => in_ids.indexOf(item.id) >= 0);
    //         q_ins.forEach(item => {
    //             if (item.mq_id) ids.push(item.mq_id);
    //         })
    //     })
    //     ids = Array.from(new Set(ids));
    //     return ids;
    // }

    // findMqIdInDistribution3 = (group: quantityGroup) => {
    //     let ids: number[] = [];
    //     let quantities = group.quantities;
    //     quantities.forEach((q: quantity) => {
    //         let in_ids: number[] = q.quantity_in_id;
    //         // 获得测点指标列表
    //         let q_ins: quantity[] = this.quantities.filter((item: quantity) => in_ids.indexOf(item.id) >= 0);
    //         // 获得直接物理量id列表
    //         in_ids = q_ins.map(item => item.quantity_in_id[0]);
    //         // 获得直接物理量列表
    //         q_ins = this.quantities.filter((item: quantity) => in_ids.indexOf(item.id) >= 0);
    //         q_ins.forEach(item => {
    //             if (item.mq_id) ids.push(item.mq_id);
    //         })
    //     })
    //     ids = Array.from(new Set(ids));
    //     return ids;
    // }

    render() {
        let mobjectDetail = this.props.mobjectStore._mobjectDetail;
        if (this.previousMobjectId !== mobjectDetail?.id) return null;
        if (!this.state.mobjectDetail) return null;
        return (
            <div className="mobject-info-container">
                <Row>
                    <Col xs={24}>
                        <div className="mobject-info-panel">
                            <div style={{ border: '1px solid #eaeaea', padding: '16px' }}>
                                <div className="info-panel-group">
                                    <Row gutter={[28, 10]}>
                                        <Col xs={12} xxl={10}>
                                            <div className="info-panel">
                                                <div className="title">信息</div>
                                                <div className="info-item-group">
                                                    <Descriptions bordered size="small" column={2}>
                                                        <Descriptions.Item label="名称" span={2}>
                                                            <div className="content" style={{ fontWeight: 'bold', fontSize: '16px' }}>{this.state.mobjectDetail?.name}</div>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="层级">
                                                            <div className="content">{MobjectBaseType[this.state.mobjectDetail?.type]['alias']}</div>                                                </Descriptions.Item>
                                                        <Descriptions.Item label="类别">
                                                            <div className="content">{MobjectSubtypeName[this.state.mobjectDetail?.type][this.state.mobjectDetail?.sub_type]}</div>
                                                        </Descriptions.Item>
                                                        {this.state.mobjectDetail?.type === MobjectTypeEnum.MEMBER && (
                                                            <Descriptions.Item label="截面">
                                                                {this.state.sectionDetail && (
                                                                    <div className="content can-click"
                                                                        onClick={this.goToSectionDetail}>
                                                                        {this.state.sectionDetail?.name}
                                                                    </div>
                                                                )}
                                                            </Descriptions.Item>
                                                        )}
                                                        {this.state.mobjectDetail?.type === MobjectTypeEnum.JOINT ||
                                                            this.state.mobjectDetail?.type === MobjectTypeEnum.MEMBER && (
                                                                <Descriptions.Item label="材料">
                                                                    {this.state.materialDetail && (
                                                                        <div className="content can-click"
                                                                            onClick={this.goToMaterialDetail}>
                                                                            {this.state.materialDetail?.name}
                                                                        </div>
                                                                    )}
                                                                </Descriptions.Item>
                                                            )}
                                                        {this.state.mobjectDetail?.type === MobjectTypeEnum.MEMBER && (
                                                            <Descriptions.Item label="单元号">
                                                                <div className="content">{this.state.mobjectDetail?.ref_member_sid}</div>
                                                            </Descriptions.Item>
                                                        )}
                                                        {this.state.mobjectDetail?.type === MobjectTypeEnum.JOINT && (
                                                            <Descriptions.Item label="节点号">
                                                                <div className="content">{this.state.mobjectDetail?.ref_node_sid}</div>
                                                            </Descriptions.Item>
                                                        )}
                                                    </Descriptions>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={12} xxl={6}>
                                            <div className="info-panel">
                                                <div className="title">参数</div>
                                                <div className="info-item-group">
                                                    {this.state.mobjectParams.map((item: paramObj, idx) => (
                                                        <div className="info-item" key={idx}>
                                                            {/if_fati/.test(item.name) ? (
                                                                <Fragment>
                                                                    <div className="label short">
                                                                        验算疲劳
                                                    </div>
                                                                    <div className="content">{item.value ? '是' : '否'}</div>
                                                                </Fragment>
                                                            ) : (

                                                                    <Fragment>
                                                                        <div className="label short">
                                                                            <InlineMath math={handleQuantityName(item.name)} />
                                                                        </div>
                                                                        <div className="content">
                                                                            {NumFixed(+item.value * Transfer2DisplayScale(ParamUnits[item.name]), 7)}
                                                                            {ParamUnits[item.name] || ''}
                                                                        </div>
                                                                    </Fragment>
                                                                )}

                                                        </div>
                                                    ))}
                                                    <div className="info-item">
                                                        <div className="label short">评估间隔</div>
                                                        <div className="content">
                                                            <Select
                                                                size="small"
                                                                style={{ width: '140px' }}
                                                                value={this.state.frequency}
                                                                onChange={this.onFrequencyChange}>
                                                                <Option value={1} label="1秒">1秒</Option>
                                                                <Option value={5} label="5秒">5秒</Option>
                                                                <Option value={10} label="10秒">10秒</Option>
                                                                <Option value={30} label="30秒">30秒</Option>
                                                                <Option value={60} label="1分钟">1分钟</Option>
                                                                <Option value={300} label="5分钟">5分钟</Option>
                                                                <Option value={600} label="10分钟">10分钟</Option>
                                                                <Option value={900} label="15分钟">15分钟</Option>
                                                                <Option value={1800} label="30分钟">30分钟</Option>
                                                                <Option value={3600} label="1小时">1小时</Option>
                                                                <Option value={21600} label="6小时">6小时</Option>
                                                                <Option value={43200} label="12小时">12小时</Option>
                                                                <Option value={86400} label="24小时">24小时</Option>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={24} xxl={8}>
                                            <div className="info-panel constage">
                                                <div className="title">施工步</div>
                                                <div className="content">
                                                    {this.state.constages.map((item: constage, idx) => (
                                                        <div
                                                            className="can-click constage-tag"
                                                            key={idx}
                                                            onClick={() => this.props.history.push(`/projects/${this.context}/constages/${item.id}`)}>
                                                            {`${item.code} ${item.name}`}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="operations">
                                    <Space>
                                        <Button onClick={this.onMobjectEditOpen}>编辑</Button>
                                        <Button onClick={() => this.setState({ mobjectCopyModalVisible: true })}>批量复制</Button>
                                        <Button danger onClick={this.showDeleteConfirm}>删除</Button>
                                    </Space>
                                </div>
                            </div>


                        </div>
                    </Col>
                    <Col xs={0} xxl={this.state.mobjectDetail?.type === MobjectTypeEnum.MEMBER ? 12 : 0}>
                        <div className="mobject-info-figure">
                            {this.state.mobjectDetail?.type === MobjectTypeEnum.MEMBER && (
                                <div className="figure">
                                    <img
                                        width="100%"
                                        height="100%"
                                        alt="对象示意图"
                                        src={`${mobjectPicStaticLocation}${this.state.mobjectDetail?.sub_type}.png`} />
                                </div>
                            )}

                        </div>
                    </Col>
                    <Col xs={24} xxl={this.state.mobjectDetail?.type === MobjectTypeEnum.MEMBER ? 12 : 24}>
                        <div className="info-panel-group pattern">
                            <div className="title">传感器分布</div>
                            <div className="patterns">
                                <Row>
                                    {this.state.quantityGroups.map((group: quantityGroup) =>
                                        group.current_pattern &&
                                        (
                                            <Col xs={24} xxl={this.state.mobjectDetail?.type === MobjectTypeEnum.MEMBER ? 24 : 12} key={group.id}>
                                                <div className={classnames({
                                                    'pattern-item': true,
                                                    'pattern-item-horizon': this.state.quantityGroups.length < 10,
                                                })}>
                                                    <div className="figure" style={{ height: '225px', width: '300px' }}>
                                                        <div>
                                                            <img
                                                                width="100%"
                                                                height="100%"
                                                                alt="模式图"
                                                                src={`${patternPicStaticLocation}${group.current_pattern?.figure_url}`} />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="pattern-name" >{group.current_pattern?.name}</div>
                                                        {group.sensors?.map((item: sensor, idx) => (
                                                            <div
                                                                className="can-click"
                                                                key={idx}
                                                                style={{ lineHeight: '1.8' }}
                                                                onClick={() => this.props.history.push(`/projects/${this.context}/sensors/${item.id}`)}>
                                                                <span>{this.findSensorTypeName(item.type)}</span>
                                                                <span>{`-${item.location}`}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    )}
                                </Row>

                            </div>

                        </div>
                    </Col>

                </Row>



                <Modal
                    closable={false}
                    title="编辑对象"
                    maskClosable={false}
                    destroyOnClose
                    visible={this.state.editModalVisible}
                    footer={null}
                    width={800}
                >
                    <MobjectInfoEdit
                        onCancel={this.onEditModalCancel}
                        onSave={this.onMojectEditSave}
                        saveLoading={this.state.editSaveLoading}
                        projectId={this.context}
                        mobjectDetail={this.state.mobjectDetail} />
                </Modal>

                <Modal
                    closable
                    title="对象复制"
                    destroyOnClose
                    visible={this.state.mobjectCopyModalVisible}
                    footer={null}
                    onCancel={() => this.setState({ mobjectCopyModalVisible: false })}
                >
                    <Form autoComplete="off" onFinish={this.onMobjectCopyConfirm} wrapperCol={{ span: 12 }}>
                        <Form.Item label="名称前缀" name="prefix" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="起始序号" name="start" rules={[{ required: true }]}>
                            <InputNumber min={0} />
                        </Form.Item>
                        <Form.Item label="复制个数" name="count" rules={[{ required: true }]} initialValue={1}>
                            <InputNumber min={1} max={99} />
                        </Form.Item>
                        <div style={{ textAlign: 'right' }}>
                            <Space size="large">
                                <Button type="primary" htmlType="submit" loading={this.state.mobjectCopyLoading}>确定</Button>
                                <Button onClick={() => this.setState({ mobjectCopyModalVisible: false })}>取消</Button>
                            </Space>
                        </div>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default withRouter(MobjectInfoPane);