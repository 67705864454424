import React, { Component, Fragment } from 'react'
import { withRoutePane } from '@/components/hoc/routePane'
import { element } from '@/interface/model';
import { ProjectContext } from '@/config/context';
import { queryElements, deleteElement, updateElement } from '@/config/api/model';
import { Space, Popconfirm, Button, message, Table, Modal } from 'antd';
import { EditableRow, EditableCell, ElementAddPanel } from '@/components';
import './element-page.less';
import axios from '@/config/axios';

const ElementTypes = {
    1: '梁单元',
    2: '杆单元',
    3: '索单元',
    4: '板单元',
}

interface IProps {
}
interface IState {
    elementList: element[],
    page: number
    size: number
    total: number
    loading: boolean
    multiDeleteLoading: boolean
    onMultiDeleteState: boolean
    elmementIdToDelete: number[]
    onModalVisible: boolean
}

class ElementPage extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        elementList: [],
        page: 1,
        size: 30,
        total: 0,
        loading: false,
        multiDeleteLoading: false,
        onMultiDeleteState: false,
        elmementIdToDelete: [],
        onModalVisible: false
    }

    componentDidMount() {
        this.findElements();
    }

    findElements = () => {
        this.setState({
            loading: true,
        })
        queryElements(this.context, {
            // page: this.state.page,
            // size: this.state.size,
        }).then(res => {
            this.setState({
                elementList: res.data?.data,
                total: res.data?.count,
            })
        }).finally(() => {
            this.setState({
                loading: false,
                page: 1
            })
        })
    }

    onPageChange = (page, size) => {
        this.setState({
            page: page,
            size: size,
        })
        // this.findNodes()
    }

    handleSave = (row: element) => {
        let id = row.id;
        let newData = [...this.state.elementList];
        let index = newData.findIndex(item => item.id === id);
        let item: element = newData[index];

        newData.splice(index, 1, { ...item, ...row })
        this.setState({
            elementList: newData
        })
        let data = {
            node_1_sid: row.node_1_sid || null,
            node_2_sid: row.node_2_sid || null,
            node_3_sid: row.node_3_sid || null,
            node_4_sid: row.node_4_sid || null,
            section_sid: row.section_sid || null,
            material_sid: row.material_sid || null,
        }
        updateElement(this.context, row.id, data)
            .then(res => {

            }).catch(err => {
                message.error('更新失败');
                // console.log(err)
                newData.splice(index, 1, { ...row, ...item })
                this.setState({
                    elementList: [...newData]
                })
            })
    }

    handleDelete = (values) => {
        deleteElement(this.context, values.id)
            .then(res => {
                this.setState({
                    elementList: this.state.elementList.filter(item => item.id !== values.id)
                })
            }).catch(err => {
                message.error('删除失败')
            })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        // console.log(selectedRowKeys)
        this.setState({
            elmementIdToDelete: [...selectedRowKeys]
        })
    }

    onMultiDeleteCancel = () => {
        this.setState({
            elmementIdToDelete: [],
            onMultiDeleteState: false,
        })
    }

    onMultiDeleteConfirm = () => {
        this.setState({
            multiDeleteLoading: true,
        })
        let deletePromiseList = this.state.elmementIdToDelete.map(id => deleteElement(this.context, id))
        axios.all([...deletePromiseList])
        .then(res => {
            this.setState({
                elmementIdToDelete: [],
                onMultiDeleteState: false,
            })
            this.findElements();
        }).finally(() => {
            this.setState({
                multiDeleteLoading: false,
            })
        })
    }

    afterPosted = (value) => {
        this.setState({
            onModalVisible: false,
            elementList : [value, ...this.state.elementList]
        })  
    }

    render() {
        let columns = [
            {
                title: '单元号',
                dataIndex: 'sid',
                width: '10%'
            },
            {
                title: '类别',
                dataIndex: 'type',
                editable: true,
                type: 'Select',
                options: [
                    {value: 1, label: '梁单元'},
                    {value: 2, label: '杆单元'},
                    {value: 3, label: '索单元'},
                    {value: 4, label: '板单元'},
                ],
                align: 'left' as 'left',
                width: '10%',
                render: (record, idx) => (
                    <div>{ElementTypes[record]}</div>
                )
            },
            {
                title: '节点1',
                dataIndex: 'node_1_sid',
                editable: true,
                // align: 'right' as 'right',
                width: '10%'
            },
            {
                title: '节点2',
                dataIndex: 'node_2_sid',
                editable: true,
                // align: 'right' as 'right',
                width: '10%'
            },
            {
                title: '节点3',
                dataIndex: 'node_3_sid',
                editable: true,
                // align: 'right' as 'right',
                width: '10%'
            },
            {
                title: '节点4',
                dataIndex: 'node_4_sid',
                editable: true,
                // align: 'right' as 'right',
                width: '10%'
            },
            {
                title: '截面号',
                dataIndex: 'section_sid',
                editable: true,
                // align: 'right' as 'right',
                width: '10%'
            },
            {
                title: '材料号',
                dataIndex: 'material_sid',
                editable: true,
                // align: 'right' as 'right',
                width: '10%'
            },
            {
                title: '操作',
                key: 'operations',
                width: 200,
                align: 'center' as 'center',
                render: (record, idx) => (
                    <div style={{ textAlign: "center" }}>
                        <Space>
                            <Popconfirm title="确认删除" onConfirm={() => this.handleDelete(record)}>
                                <Button type="link" size="small" disabled={this.state.onMultiDeleteState}>删除</Button>
                            </Popconfirm>
                        </Space>
                    </div>

                )
            },
        ];
        let columnRender = columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    type: col.type,
                    options: col.options,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        })

        return (
            <div className="element-page-container">
                <div className="header">
                    {!this.state.onMultiDeleteState && (
                        <Fragment>
                            <Space>
                                <Button shape="round">导入</Button>
                                <Button shape="round">导出</Button>
                            </Space>
                            <Space style={{ marginLeft: '24px' }}>
                                <Button type="primary" onClick={() => this.setState({ onModalVisible: true })}>添加</Button>
                                <Button danger onClick={() => { this.setState({ onMultiDeleteState: true }) }}>批量删除</Button>
                            </Space>
                        </Fragment>
                    )}
                    {this.state.onMultiDeleteState && (
                        <Space size="large" style={{ marginLeft: '24px' }}>
                            <Button type="primary" onClick={this.onMultiDeleteConfirm} loading={this.state.multiDeleteLoading}>确定</Button>
                            <Button onClick={this.onMultiDeleteCancel}>取消</Button>
                        </Space>
                    )}
                </div>
                <Table
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        }
                    }}
                    columns={columnRender}
                    rowSelection={this.state.onMultiDeleteState && {
                        type: 'checkbox',
                        onChange: this.onSelectChange,
                        selectedRowKeys: this.state.elmementIdToDelete
                    }}
                    dataSource={this.state.elementList}
                    rowKey="id"
                    loading={this.state.loading}
                    pagination={{
                        pageSize: this.state.size,
                        // total: this.state.total,
                        current: this.state.page,
                        onChange: this.onPageChange
                    }}
                    rowClassName={(record, index) => (
                        index % 2 !== 0 ? 'ribbon-row editable-row' : 'editable-row'
                    )}>
                </Table>

                <Modal
                    destroyOnClose
                    footer={null}
                    visible={this.state.onModalVisible}
                    closable={false}
                    onCancel={() => this.setState({onModalVisible: false})}>
                    <ElementAddPanel afterSave={this.afterPosted} onCancelClick={() => { this.setState({ onModalVisible: false }) }}/>
                </Modal>
            </div>
        )
    }
}

export default withRoutePane(ElementPage, '模型单元列表');