import React, { Component } from 'react'
import { withRoutePane } from '@/components/hoc/routePane';
import { ProjectInfoEdit } from '@/components';
import './project-info.less';
import ProjectInfoBaseBoard from '@/components/project-info/project-info-base';
import InfoRequirementBoard from '@/components/project-info/info-requirement';
import { Row, Col, message } from 'antd';
import InfoPeopleTableBoard from '@/components/project-info/info-people-table';
import InfoScheduleTableBoard from '@/components/project-info/info-schedule-table';
import InfoPlanbTableBoard from '@/components/project-info/info-planb-table';
import { project } from '@/interface/project';
import { projectPeople, projectSchedule, projectPlanb } from '@/interface/plan';
import { figure } from '@/interface/document';
import { ProjectContext } from '@/config/context';
import { queryProjectDetail, queryProjectFigures } from '@/config/api/project';
import { queryProjectPeople, queryProjectSchedules, queryProjectPlanbs } from '@/config/api/plan';
import axios from '@/config/axios';
import { StructureTypeOriginMap, ConstructionTypeOriginMap } from '@/components/project-info/project-info-edit';
interface IProps {

}
interface IState {
    isInfoEditting: boolean
    projectInfo: project,
    projectPeopleList: projectPeople[],
    projectScheduleList: projectSchedule[],
    projectPlanbList: projectPlanb[],
    projectFigures: figure[],
}
class ProjectInfo extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        isInfoEditting: false,
        projectInfo: null,
        projectPeopleList: [],
        projectScheduleList: [],
        projectPlanbList: [],
        projectFigures: [],
    }

    componentDidMount() {
        this.generateData();
    }

    generateData = () => {
        let projectId = this.context;
        let queryProjectPromise = queryProjectDetail(projectId);
        let queryProjectPeoplePromise = queryProjectPeople(projectId);
        let queryProjectSchedulePromise = queryProjectSchedules(projectId);
        let queryProjectPlanbPromise = queryProjectPlanbs(projectId);
        let queryProjectFiguresPromise = queryProjectFigures(projectId);
        axios.all([queryProjectPromise,
            queryProjectPeoplePromise,
            queryProjectSchedulePromise,
            queryProjectPlanbPromise,
            queryProjectFiguresPromise])
            .then(res => {
                let projectInfo: project = res[0].data;
                projectInfo.structure_type = Object.assign(StructureTypeOriginMap, projectInfo?.structure_type);
                projectInfo.construction_type = Object.assign(ConstructionTypeOriginMap, projectInfo?.construction_type);
                // 人员计划和应急预案排序并生成序列号
                let originSchedules: projectSchedule[] = res[2].data || [];
                let orderedSchedules = this.orderLinkedList(originSchedules);
                let originPlanbs: projectPlanb[] = res[3].data || [];
                let orderedPlanbs = this.orderLinkedList(originPlanbs);
                // 组织form-figure数据
                let projectFigures: figure[] = res[4].data || [];
                this.setState({
                    projectInfo: projectInfo,
                    projectPeopleList: res[1].data || [],
                    projectScheduleList: orderedSchedules,
                    projectPlanbList: orderedPlanbs,
                    projectFigures: projectFigures,
                })
            })
    }

    orderLinkedList = (originList: any[]) => {
        // 给时间计划和应急预案排序
        // 生成hash
        let listIdMap = {};
        originList.forEach(item => {
            listIdMap[item.id] = item;
        })
        let res = [];
        // 找到第一个
        for (let idx = 0; idx < originList.length; idx++) {
            if (originList[idx].previous_id === null) {
                res.push(Object.assign(originList[idx], { sid: 1 }));
                break;
            }
        }
        let idx = 0;
        while (res[idx] && res[idx].next_id) {
            let id = res[idx].next_id;
            if (!listIdMap[id]) {
                message.warning('时间计划或应急预案发生错误');
                break;
            }
            res.push(Object.assign(listIdMap[id], { sid: idx + 2 }));
            idx++;
        }
        return res;
    }

    render() {
        if (this.state.isInfoEditting) return <ProjectInfoEdit afterSave={() => { 
            this.setState({ isInfoEditting: false }) ;
            this.generateData();
        }} />;

        return (
            <div style={{marginBottom: '40px'}}>
                <ProjectInfoBaseBoard 
                projectInfo={this.state.projectInfo} 
                projectFigures={this.state.projectFigures} 
                onEditClick={() => { this.setState({ isInfoEditting: true }) }}/>
                <Row gutter={10} align="stretch">
                    <Col xs={24} xl={12}>
                        <InfoPeopleTableBoard projectPeopleList={this.state.projectPeopleList}/>
                    </Col>
                    <Col xs={24} xl={12}>
                        <InfoScheduleTableBoard projectScheduleList={this.state.projectScheduleList}/>
                    </Col>
                    <Col xl={8} xs={24} xxl={8}>
                        <InfoRequirementBoard title="技术要求" content={this.state.projectInfo?.technical_requirement}/>
                    </Col>
                    <Col xl={8} xs={24} xxl={8}>
                        <InfoRequirementBoard title="经济要求" content={this.state.projectInfo?.economic_requirement}/>
                    </Col>
                    <Col xl={8} xs={24} xxl={8}>
                        <InfoRequirementBoard title="进度要求" content={this.state.projectInfo?.schedule_requirement}/>
                    </Col>
                    <Col  xs={24}>
                        <InfoPlanbTableBoard projectPlanbList={this.state.projectPlanbList}/>
                    </Col>


                </Row>

            </div>
        )
    }
}

export default withRoutePane(ProjectInfo, '基本信息');