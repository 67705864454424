import React, { Component } from 'react'
import { qDataBase, DataPoint } from '@/interface/quantity'
import { Chart, registerInteraction } from '@antv/g2'
import dayjs from 'dayjs';
import { constage } from '@/interface/constage';

interface IProps {
    q_data: qDataBase,
    precision?: number,
    width?: number,
    height?: number,
    constages?: constage[]
    filter?: (ds: DataPoint[]) => void
}

export default class LineChart extends Component<IProps> {

    lineChart: Chart;



    componentDidMount() {

            // 定义新的交互
        registerInteraction('other-visible', {
            showEnable: [
                { trigger: 'plot:mouseenter', action: 'cursor:crosshair' },
                { trigger: 'mask:mouseenter', action: 'cursor:move' },
                { trigger: 'plot:mouseleave', action: 'cursor:default' },
                { trigger: 'mask:mouseleave', action: 'cursor:crosshair' },
              ],
              start: [
                {
                  trigger: 'plot:mousedown', isEnable(context) {
                    return !context.isInShape('mask');
                  }, action: ['rect-mask:start', 'rect-mask:show']
                },
                { trigger: 'mask:dragstart', action: 'rect-mask:moveStart' }
              ],
              processing: [
                { trigger: 'plot:mousemove', action: 'rect-mask:resize' },
                {
                  trigger: 'mask:drag', isEnable(context) {
                    return context.isInPlot();
                  }, action: 'rect-mask:move'
                },
                { trigger: 'mask:change', action: 'data-filter:filter' }
              ],
              end: [
                { trigger: 'plot:mouseup', action: 'rect-mask:end', callback(context) {
                    console.log(context.view)
                } },
                { trigger: 'mask:dragend', action: 'rect-mask:moveEnd' }
              ],
              rollback: [
                { trigger: 'dblclick', action: ['rect-mask:hide', 'element-sibling-filter:reset'] }
              ]
        });

        registerInteraction('brush', {
            // showEnable: [
            //   { trigger: 'plot:mouseenter', action: 'cursor:crosshair' },
            //   { trigger: 'plot:mouseleave', action: 'cursor:default' },
            // ],
            start: [
              {
                trigger: 'plot:mousedown',
                action: ['brush:start', 'rect-mask:start', 'rect-mask:show', 'cursor:crosshair'],
              },
            ],
            processing: [
              { trigger: 'plot:mousemove', action: ['rect-mask:resize', 'tooltip:hide'], },
            ],
            end: [
              {
                trigger: 'plot:mouseup',
                action: ['brush:filter', 'brush:end', 'rect-mask:end', 'rect-mask:hide', 'cursor:default', 'reset-button:show', 'tooltip:show'],
                callback: (context) => {
                    // filter
                    if (!!this.props.filter) {
                        this.props.filter(context.view?.getData());
                    }
                }
              },
            ],
            rollback: [
                { trigger: 'reset-button:click', action: ['brush:reset', 'reset-button:hide'], callback: (context) => {
                    // reset
                    if (!!this.props.filter) {
                        this.props.filter([]);
                    }
                } },
            ],
          });

        const chart = new Chart({
            container: this.props.q_data?.id.toString(),
            autoFit: true,
            height: this.props.height || 300,
            width: this.props.width || null,
            padding: [0,0,30,40],
        });
        const data = [...this.props.q_data?.data || []];
        // data.sort((a, b) => dayjs(a.time).valueOf() - dayjs(b.time).valueOf());
        chart.data(data);
        chart.tooltip({
            showCrosshairs: true,
        })

        // chart.option('slider', {
        //     end: 1,
        //     height: 28,
        //     trendCfg: {
        //         isArea: false,
        //     },
        // });
        chart.scale({
            time: {
                type: 'timeCat',
                range: [0.05, 0.95],
                tickCount: 5,
                alias: '时间',
                mask: 'YYYY-MM-DD HH:mm:ss'
            },
            value: {
                range: [0.05, 0.95],
                nice: true,
                formatter: (val: number) => `${val.toFixed(this.props.precision)}`,
                alias: '数值',
                tickCount: 6,
            }
        });
        chart.interaction('brush');
        chart
            .line()
            .position('time*value')
            // .tooltip('time*value');
        // let d = this.props.q_data.data;
        // chart.annotation().regionFilter({
        //     top: true,
        //     start: [d[0].time, 0.1],
        //     end: [d[d.length - 1].time, 1],
        //     color: '#FF0000'
        // })
        // if (!!this.props.constages && this.props.constages.length > 0 && this.props.q_data.warning_limit) {
        //     console.log(this.props.constages);
        //     console.log(this.props.q_data.warning_limit)
        //     this.props.constages.forEach(item => {
        //         let start = dayjs(item.start_time).valueOf();
        //         start = this.props.q_data.data.find(d => d.time >= start)?.time;
        //         if (!start) return;

        //         let end = dayjs(item.end_time).valueOf();
        //         end = this.props.q_data.data.find(d => d.time >= end)?.time;
        //         // if (!end)
        //         let id = item.id;
        //         let warninglimits = this.props.q_data.warning_limit;
        //         let wl = warninglimits.find(w => w.stage_id === id);
        //         if (!wl) return;
        //         console.log({
        //             top: true,
        //             start: [start, wl.values['t2']],
        //             end: [end, wl.values['t3']],
        //             color: '#eaeaea'
        //         })
        //         this.lineChart.annotation().line({
        //             start: ['min', 0.1],
        //             end: ['max', 0.1],
        //             style: {
        //               stroke: '#ff4d4f',
        //               lineWidth: 1,
        //               lineDash: [3, 3]
        //             },
        //             // text: {
        //             //   position: 'start',
        //             //   style: {
        //             //     fill: '#8c8c8c',
        //             //     fontSize: 15,
        //             //     fontWeight: 'normal'
        //             //   },
        //             //   content: '预警线 85%',
        //             //   offsetY: -5
        //             // }
        //           });
        //         // chart.annotation().regionFilter({
        //         //     top: true,
        //         //     start: [start, wl.values['t2']],
        //         //     end: [end, wl.values['t3']],
        //         //     color: '#eaeaea'
        //         // })
        //         // chart.annotation().regionFilter({
        //         //     top: true,
        //         //     start: [start, wl.values['t3']],
        //         //     end: [end, wl.values['t4']],
        //         //     color: '#eaeaea'
        //         // })
        //         // chart.annotation().regionFilter({
        //         //     top: true,
        //         //     start: [start, wl.values['t4']],
        //         //     end: [end, wl.values['t5']],
        //         //     color: '#eaeaea'
        //         // })
        //         // chart.annotation().regionFilter({
        //         //     top: true,
        //         //     start: [start, wl.values['t5']],
        //         //     end: [end, wl.values['t5']*10],
        //         //     color: '#eaeaea'
        //         // })
        //     })

        // }
        this.lineChart = chart;
        chart.render();
    }

    componentDidUpdate() {
        if (this.lineChart) {
            const data = [...this.props.q_data?.data || []];
            // this.lineChart.annotation().clear(true)
            // console.log('aaa')
            if (!!this.props.constages && this.props.constages.length > 0 && this.props.q_data.warning_limit && this.props.q_data.is_evaluate) {
                this.props.constages.forEach(item => {
                    if (data.length === 0) return;
                    let start = dayjs(item.start_time).valueOf();
                    let end = dayjs(item.end_time).valueOf();
                    let d_start = data[0].time;
                    let d_end = data[data.length - 1].time;
                    if (end < d_end) return;
                    if (start > d_start) return;
                    if (start < d_start) start = d_start;
                    if (end > d_end) end = d_end;
                    start = data.find(d => d.time >= start).time;
                    end = data.find(d => d.time >= end).time;
                    let id = item.id;
                    let warninglimits = this.props.q_data.warning_limit;
                    let wl = warninglimits.find(w => w.stage_id === id);
                    if (!wl) return;

                    let scale = this.props.q_data.scale || 1;
                    // console.log(wl.values['t2'])
                    // start = data[0].time;
                    this.lineChart.annotation().line({
                        start: [start, wl.values['t2']*scale],
                        end: [end, wl.values['t2']*scale],
                        style: {
                            stroke: '#4fb981',
                            lineWidth: 1,
                            lineDash: [3, 3]
                        },
                    })
                    this.lineChart.annotation().line({
                        start: [start, wl.values['t3']*scale],
                        end: [end, wl.values['t3']*scale],
                        style: {
                            stroke: '#efa829',
                            lineWidth: 1,
                            lineDash: [3, 3]
                        },
                    })
                    this.lineChart.annotation().line({
                        start: [start, wl.values['t4']*scale],
                        end: [end, wl.values['t4']*scale],
                        style: {
                            stroke: '#f68240',
                            lineWidth: 1,
                            lineDash: [3, 3]
                        },
                    })
                    this.lineChart.annotation().line({
                        start: [start, wl.values['t5']*scale],
                        end: [end, wl.values['t5']*scale],
                        style: {
                            stroke: '#e45331',
                            lineWidth: 1,
                            lineDash: [3, 3]
                        },
                    })
                })
            }
            // this.lineChart.annotation().regionFilter({
            //     top: true,
            //     start: [data[0].time, 1],
            //     end: [data[data.length - 1].time, 0.1],
            //     color: '#FFFF00'
            // })

            // data.sort((a, b) => dayjs(a.time).valueOf() - dayjs(b.time).valueOf());
            this.lineChart.changeData(data);
        }
    }


    render() {
        if (!this.props.q_data || !this.props.q_data?.id) {
            return null;
        }
        return (
            <div className="line-chart-canvas" id={this.props.q_data?.id.toString()}></div>
        )
    }
}
