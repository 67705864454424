import React, { Component } from 'react'
import { TweenOneGroup } from 'rc-tween-one';
import { Tag, Input, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
const { Search } = Input;
interface IProps {
    onTagsChange?: (tags: string[]) => void,
    onSearchContentChange?: (value: string) => void,
    onAddClick?: () => void,
    isEditAdding: boolean,
}
interface IState {
    tags: string[]
    tagsInputVisible: boolean
    tagsInputValue: string
    searchInpurValue: string,
}
export default class ConditionHeader extends Component<IProps, IState> {

    tagsInputRef = React.createRef<Input>();
    state = {
        tags: [],
        tagsInputVisible: false,
        tagsInputValue: '',
        searchInpurValue: null,
    }

    handleClose = removedTag => {
        const tags = this.state.tags.filter(tag => tag !== removedTag);
        this.setState({ tags });
        this.props.onTagsChange(tags);
    };

    showTagsInput = () => {
        this.setState({ tagsInputVisible: true }, () => this.tagsInputRef.current.focus());
    };

    handleTagsInputChange = e => {
        this.setState({ tagsInputValue: e.target.value });
    };

    handleTagsInputConfirm = () => {
        const { tagsInputValue } = this.state;
        let { tags } = this.state;
        if (tagsInputValue && tags.indexOf(tagsInputValue) === -1) {
            tags = [...tags, tagsInputValue];
        }
        this.props.onTagsChange(tags);
        this.setState({
            tags,
            tagsInputVisible: false,
            tagsInputValue: '',
        });
    };

    onSearchInputChange = (e) => {
        this.setState({
            searchInpurValue: e.target.value
        })
    }

    render() {
        const tags = this.state.tags;
        return (
            <div className="condition-header">
                <div className="title">筛选</div>
                <div className="tag-group">
                    <TweenOneGroup
                        enter={{
                            scale: 1,
                            opacity: 0,
                            type: 'from',
                            duration: 300,
                        }}
                        leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                        appear={false}>
                        {tags.map(tag => (
                            <span key={tag} style={{ display: 'inline-block' }}>
                                <Tag
                                    closable
                                    color="#0168B3"
                                    onClose={e => {
                                        e.preventDefault();
                                        this.handleClose(tag);
                                    }}>
                                    {tag}
                                </Tag>
                            </span>
                        ))}
                        {this.state.tagsInputVisible && (
                            <Input
                                ref={this.tagsInputRef}
                                type="text"
                                size="small"
                                style={{ width: 90 }}
                                value={this.state.tagsInputValue}
                                onChange={this.handleTagsInputChange}
                                onBlur={this.handleTagsInputConfirm}
                                onPressEnter={this.handleTagsInputConfirm}
                            />
                        )}
                        {!this.state.tagsInputVisible && (
                            <Tag onClick={this.showTagsInput} className="site-tag-plus">
                                <PlusOutlined /> 筛选标签
                            </Tag>
                        )}
                    </TweenOneGroup>
                </div>
                <div className="fill-remaining-space"></div>
                <Search
                    placeholder="输入检索内容"
                    onChange={this.onSearchInputChange}
                    onBlur={() => this.props.onSearchContentChange(this.state.searchInpurValue)}
                    onSearch={(value) => this.props.onSearchContentChange(value)}
                    onPressEnter={() => this.props.onSearchContentChange(this.state.searchInpurValue)}
                    style={{ width: 250 }}
                />
                {!this.props.isEditAdding && (
                    <Button type="primary" style={{ marginLeft: '24px' }} icon={<PlusOutlined />} onClick={this.props.onAddClick}>添加</Button>
                )}

            </div>
        )
    }
}
