import React, { Component } from 'react'
import { siteCondition } from '@/interface/site-condition'
import dayjs from 'dayjs'
import { Tag, Modal, Button, Row, Col } from 'antd'
import { EyeOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';
import { figureStaticLocation } from '@/config/api/document';
import { figure } from '@/interface/document';
import { transferFigPathToThumb } from '@/utils/utils';
const { confirm } = Modal;
interface IProps {
    siteConditionDetail: siteCondition
    userStore?: UserStore
    onDelete?: (id: number) => void
}

interface IState {
    modelVisible: boolean
    currentUrl: string
}

@inject('userStore')
@observer
export default class ConditionItem extends Component<IProps, IState> {
    state = {
        modelVisible: false,
        currentUrl: '',
    }
    showModal = (url) => {
        this.setState({
            modelVisible: true,
            currentUrl: `${figureStaticLocation}${url}`,
        });
    };

    handleCancel = e => {
        this.setState({
            modelVisible: false,
        });
    };


    showDeleteConfirm = () => {
        console.log(this.props.siteConditionDetail)
        confirm({
            title: '确认删除?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk: () => {
                this.props.onDelete(this.props.siteConditionDetail?.id)
            },
            onCancel() {

            },
        });
    }

    render() {
        let userId = this.props.userStore._userInfo?.id;
        if (!userId) return;
        let currentItemUserId = this.props.siteConditionDetail?.user_id;
        return (
            <div className="condition-item">
                <div className="condition-date">
                    {/* <div className="date-head"></div> */}
                    <span>{dayjs(+this.props.siteConditionDetail?.time).format('YYYY-MM-DD HH:mm')}</span>
                </div>
                <Row gutter={10}>
                    <Col xs={24} xxl={12}>
                        <div className="item-main">
                            <div className="content">
                                {this.props.siteConditionDetail?.content}
                            </div>
                            <div className="remark">
                                <div className="name">{this.props.siteConditionDetail?.user_name}</div>
                                <div>
                                    {this.props.siteConditionDetail?.tags.map((tag, idx) => (
                                        <Tag color="#0168B3" key={idx}>{tag}</Tag>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} xxl={12}>
                        <div className="figures" style={{ width: `${this.props.siteConditionDetail?.figures.length * 140 + (this.props.siteConditionDetail?.figures.length - 1) * 10}px` }}>
                            {this.props.siteConditionDetail?.figures.map((fig: figure, idx: number) => (
                                <div className="figure-shell" key={idx}>
                                    <div className="figure" onClick={() => this.showModal(fig.url)}>
                                        <img width="100%" height="100%" alt="现场条件照片" src={`${figureStaticLocation}${transferFigPathToThumb(fig.url)}`} />
                                    </div>

                                    <div className="figure-actions">
                                        <EyeOutlined className="eye" onClick={() => this.showModal(fig.url)} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>
                {/* {userId === currentItemUserId && (
                    <div style={{ position: 'absolute', left: '14px', bottom: '14px' }}>
                        <Button type="link" danger icon={<DeleteOutlined />} onClick={this.showDeleteConfirm}></Button>
                    </div>
                )} */}
                {userId === currentItemUserId && (
                    <div style={{ position: 'absolute', left: '14px', bottom: '14px' }}>
                        <Button type="link" danger icon={<DeleteOutlined />} onClick={this.showDeleteConfirm}></Button>
                    </div>
                )}
                <Modal
                    title="图片详览"
                    width={800}
                    footer={null}
                    visible={this.state.modelVisible}
                    //   onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <img width="100%" alt="照片" src={this.state.currentUrl} />
                </Modal>
            </div>
        )
    }
}
