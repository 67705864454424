import React, { Component } from 'react'
import { RProps } from '@/config/props';
import { inject, observer } from 'mobx-react';
import './mobject-page.less';
import { mobject } from '@/interface/mobject';
import { queryMobjects, createMobjects } from '@/config/api/mobject';
import { ProjectContext } from '@/config/context';
import { Route } from 'react-router-dom';
import { MobjectListPane, MobjectPane } from '@/components';
import MobjectStore from '@/store/mobject-store';
import querystring from 'querystring';
import { Modal } from 'antd';
import MobjectInfoEdit from '@/components/mobject/mobject-info-edit';
import { paramArrary2Dict } from '@/utils/utils';
import queryString from 'query-string';
import MobjectListHorizon from '@/components/mobject/mobject-list-horizon';

interface IProps extends RProps {
    mobjectStore?: MobjectStore
}

interface IState {
    mobjectType: number
    mobjects: mobject[]
    editModalVisible: boolean,
    editSaveLoading: boolean,

    loading?: boolean,
}

export const MobjectType = {
    'joints': {
        alias: '监测节点',
        type: 1
    },
    'members': {
        alias: '监测构件',
        type: 2
    },
    'structures': {
        alias: '监测结构',
        type: 3
    },
    'environments': {
        alias: '监测环境',
        type: 4
    },
}

@inject('routeStore', 'mobjectStore')
@observer
export default class MobjectPage extends Component<IProps, IState> {
    static contextType = ProjectContext;
    tab_uuid = '';

    constructor(props) {
        super(props);
        let url = this.props.location.pathname;
        let pattern = /members|joints|structures|environments/g;
        let mobjectTypeParam = url.match(pattern)[0];
        let mobjectTitle = MobjectType[mobjectTypeParam].alias;
        let mobjectType = MobjectType[mobjectTypeParam].type;
        let uuid = this.props.routeStore.addRoutePane(mobjectTitle, `${url}${this.props.location.search}`); //如果tab已存在，则返回现有tab的uuid，否则创建一个新tab
        this.tab_uuid = uuid;
        this.state = {
            mobjectType: mobjectType,
            mobjects: [],
            editModalVisible: false,
            editSaveLoading: false,
            loading: false,
        }
        let locationSearch = this.props.location.search.substr(1);
        let queryParams = querystring.parse(locationSearch);
        if (!!queryParams.tab) {
            this.props.mobjectStore.changeMobjectTabIndex(uuid, queryParams.tab.toString());
        } else {
            // let url = this.props.location.pathname;
            // let search = `?tab=1`;
            // this.props.routeStore.changeRoutePaneUrl(uuid, `${url}${search}`);
            // this.props.history.replace({ pathname: url, search: search });
            this.props.mobjectStore.changeMobjectTabIndex(uuid, '1');
        }
    }

    componentWillUnmount() {
        this.props.mobjectStore.changeSelectedMobjectId(null);
    }

    componentDidMount() {
        this.findMobjects(this.state.mobjectType);
        this.props.mobjectStore.onMobjectDelete((id: number) => {
            let idx = this.state.mobjects.findIndex(item => item.id === id);

            if (idx === 0 && this.state.mobjects.length === 1) {
                let url = `${this.props.match.url}`;
                this.props.routeStore.changeRoutePaneUrl(this.tab_uuid, url);
                // this.props.mobjectStore.addSelectedMobject(mobject);
                this.props.mobjectStore.changeSelectedMobjectId(null);
                this.props.history.push({ pathname: url });
            } else if (idx === 0) {
                let next = idx + 1;
                let nextMob = this.state.mobjects[next];
                console.log(nextMob)
                let url = `${this.props.match.url}/${nextMob.id}`;
                this.props.routeStore.changeRoutePaneUrl(this.tab_uuid, url);
                // this.props.mobjectStore.addSelectedMobject(mobject);
                this.props.mobjectStore.changeSelectedMobjectId(nextMob.id);
                this.props.history.push({ pathname: url, search: this.props.location.search });
            } else {
                let next = idx - 1 >= 0 ? idx - 1 : 0;
                let nextMob = this.state.mobjects[next];
                let url = `${this.props.match.url}/${nextMob.id}`;
                this.props.routeStore.changeRoutePaneUrl(this.tab_uuid, url);
                // this.props.mobjectStore.addSelectedMobject(mobject);
                this.props.mobjectStore.changeSelectedMobjectId(nextMob.id);
                this.props.history.push({ pathname: url, search: this.props.location.search });
            }
            this.setState({
                mobjects: this.state.mobjects.filter(item => item.id !== id)
            })
        })
    }

    componentDidUpdate() {
        let forceRender = this.props.mobjectStore._forceRender;
        if (forceRender) {
            this.props.mobjectStore.setForceRender(false);
            this.findMobjects(this.state.mobjectType);
        }
        let url = `${this.props.location.pathname}${this.props.location.search}`;
        let tabUrl = this.props.routeStore.getTabUrl(this.tab_uuid);
        if (url !== tabUrl) {
            this.props.history.push(tabUrl);
        }
    }

    findMobjects = (mobjectType: number) => {
        // let mobjectParamPrototypePromise = queryGlobalMobjectParams({
        //     type: mobjectType,
        //     sub_type: 101, // TODO: 把请求params放到新建的modal里
        // });
        this.setState({
            loading: true,
        })
        queryMobjects({
            type: mobjectType,
            project_id: this.context
        }).then(res => {
            let mobjects: mobject[] = res.data || [];
            mobjects = mobjects.sort((a, b) => a.name > b.name ? 1 : -1);
            let mobjectId = this.props.mobjectStore._selectedMobjectId;
            this.setState({
                mobjects: mobjects,
                loading: false,
            })
            if (!mobjectId && mobjects?.length > 0) {
                let mobject = mobjects[0];
                this.onItemSelectChange(mobject);
            }
            let count = mobjects.length;
            if (mobjectType === MobjectType.structures.type)
                this.props.mobjectStore.changeStmCount(count);
            else if (mobjectType === MobjectType.members.type)
                this.props.mobjectStore.changeMmCount(count);
            else if (mobjectType === MobjectType.joints.type)
                this.props.mobjectStore.changeJmCount(count);
            else if (mobjectType === MobjectType.environments.type)
                this.props.mobjectStore.changeEmCount(count);
        })
    }

    onItemSelectChange = (mobject: mobject) => {
        let url = `${this.props.match.url}/${mobject.id}`;
        let originSearch = querystring.parse(this.props.location.search.substr(1));
        let search = null;
        if (originSearch.tab) {
            search = queryString.stringify({
                tab: originSearch.tab,
            })
        }
        url = search ? `${url}?${search}` : url;
        this.props.routeStore.changeRoutePaneUrl(this.tab_uuid, url);
        this.props.mobjectStore.changeSelectedMobjectId(mobject.id);
        // this.props.history.push({ pathname: url, search: this.props.location.search });
        this.props.history.push(url);

    }

    onItemAddClick = () => {
        this.setState({
            editModalVisible: true
        })
    }

    onEditModalCancel = () => {
        this.setState({
            editModalVisible: false
        })
    }

    onMobjectEditOpen = () => {
        this.setState({
            editModalVisible: true
        })
    }

    onMojectEditSave = (values) => {
        this.setState({
            editSaveLoading: true
        })
        let data = Object.assign(values, {
            params: paramArrary2Dict(values.params),
            project_id: this.context,
        });
        createMobjects(data)
            .then(res => {
                let id = res.data;
                this.findMobjects(this.state.mobjectType);
                let url = `${this.props.match.url}/${id}`;
                this.props.routeStore.changeRoutePaneUrl(this.tab_uuid, url);
                // this.props.mobjectStore.addSelectedMobject(mobject);
                this.props.mobjectStore.changeSelectedMobjectId(id);
                this.props.history.push({ pathname: url });
                this.setState({
                    editSaveLoading: false,
                    editModalVisible: false
                })
            })
    }

    render() {
        let _ = this.props.mobjectStore._forceRender;
        return (
            <div className="mobject-container">
                <div style={{ display: 'none' }}>{_}</div>
                <div className="mobject-select large">
                    <MobjectListPane
                        selectedMobjectId={this.props.mobjectStore._selectedMobjectId}
                        onChange={this.onItemSelectChange}
                        mobjects={this.state.mobjects}
                        onItemAddClick={this.onItemAddClick}
                        loading={this.state.loading} />
                </div>
                <div className="mobject-select small">
                    <MobjectListHorizon
                        selectedMobjectId={this.props.mobjectStore._selectedMobjectId}
                        onChange={this.onItemSelectChange}
                        mobjects={this.state.mobjects}
                        onItemAddClick={this.onItemAddClick}
                        loading={this.state.loading} />
                </div>
                <Route path={`${this.props.match.url}/:mobjectId`} render={(props) => (
                    <MobjectPane {...props} tab_uuid={this.tab_uuid} mobjectType={this.state.mobjectType} />
                )} />

                <Modal
                    closable={false}
                    title="编辑对象"
                    maskClosable={false}
                    destroyOnClose
                    visible={this.state.editModalVisible}
                    footer={null}
                    width={800}
                >
                    <MobjectInfoEdit
                        onCancel={this.onEditModalCancel}
                        onSave={this.onMojectEditSave}
                        saveLoading={this.state.editSaveLoading}
                        projectId={this.context}
                        mobjectDetail={{
                            type: this.state.mobjectType,
                            // params: this.state.paramsPrototype,
                        }}
                    />
                </Modal>
            </div>
        )
    }
}
