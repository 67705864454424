import React, { Component } from 'react'
import ProcessBar from './process-bar'
import { queryProjectDetail } from '@/config/api/project';
import { ProjectContext } from '@/config/context';
import { project } from '@/interface/project';
import { queryDboxs } from '@/config/api/dbox';
import { querySensors } from '@/config/api/sensor';
import { sensor } from '@/interface/sensor';
import { dbox } from '@/interface/dbox';
import axios from '@/config/axios';
import dayjs from 'dayjs';
import { DeviceManageStatusEnum } from '@/routes/sites/site-manage';

interface IProps {

}
interface IState {
    projectDetail: project;
    sensors: sensor[];
    dboxs: dbox[];
}

export default class ProgressBoard extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        projectDetail: null,
        sensors: [],
        dboxs: [],
    }
    componentDidMount() {
        this.generateData();
    }

    generateData = () => {
        let projectId = this.context;
        let queryProjectPromise = queryProjectDetail(projectId);
        let queryDboxsPromise = queryDboxs({
            project_id: projectId,
        });
        let querySensorsPromise = querySensors({
            project_id: projectId,
        })
        axios.all([queryProjectPromise, queryDboxsPromise, querySensorsPromise])
            .then(res => {
                let projectDetail = res[0].data;
                let sensors = res[1].data;
                let dboxs = res[2].data;
                this.setState({
                    projectDetail,
                    sensors,
                    dboxs,
                })
            })
    }

    calMonitorProcess = (record: project) => {
        if (!record) return;
        let start_time = record.start_time || dayjs();
        let period = record.period;
        let percent = 0;
        let diff = dayjs().diff(dayjs(start_time), 'day') / period * 100;
        if (diff > 0) percent = +diff.toFixed(0);
        if (percent > 100) percent = 100
        return percent;
    }

    calInstallProcess = (dboxs: dbox[], sensors: sensor[]) => {
        let dboxCount = dboxs.filter(item => item.manage_status !== DeviceManageStatusEnum.SCRAP).length;
        let dboxInstalledCount = dboxs.filter(item =>
            item.manage_status >= DeviceManageStatusEnum.CHECKED &&
            item.manage_status !== DeviceManageStatusEnum.SCRAP &&
            item.manage_status !== DeviceManageStatusEnum.REWORK).length;
        let sensorCount = sensors.filter(item => item.manage_status !== DeviceManageStatusEnum.SCRAP).length;
        let sensorInstalledCount = sensors.filter(item =>
            item.manage_status >= DeviceManageStatusEnum.CHECKED &&
            item.manage_status !== DeviceManageStatusEnum.SCRAP &&
            item.manage_status !== DeviceManageStatusEnum.REWORK).length;
        let count = dboxCount + sensorCount;
        if (count === 0) return 0;
        let percent = (dboxInstalledCount + sensorInstalledCount) / count * 100;
        percent = +percent.toFixed(0);
        return percent;
    }

    render() {
        let { projectDetail, dboxs, sensors } = this.state;
        return (
            <div className="dashboard-card process">
                <div className="title">进度</div>
                <div className="bar-group" style={{ padding: '0 24px' }}>
                    <ProcessBar title='安装进度' percent={this.calInstallProcess(dboxs, sensors) || 0} />
                    <ProcessBar title='监测进度' percent={this.calMonitorProcess(projectDetail) || 0} />
                </div>
            </div>
        )
    }
}
