import React, { Component } from 'react'
import './constage-mobject.less';
import { constage } from '@/interface/constage';
import { queryMobjects } from '@/config/api/mobject';
import { ProjectContext } from '@/config/context';
import { mobject } from '@/interface/mobject';
import classnames from 'classnames';
import { quantity, globalQuantity } from '@/interface/quantity';
import { queryQuantities } from '@/config/api/quantity';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import axios from '@/config/axios';
import { Link, withRouter } from 'react-router-dom';
import { Gprops } from '@/config/props';
import { MobjectBaseType } from '@/utils/type';

interface IProps extends Gprops {
    constageDetail: constage
}
interface IState {
    mobjects: mobject[]
    selectedMobject: mobject
    quantities: quantity[]
    projectQuantities: globalQuantity[]
    clientHeight: number
}
class ConstageMobjectPanel extends Component<IProps, IState> {
    static contextType = ProjectContext;
    ref = React.createRef<HTMLDivElement>();
    state = {
        mobjects: [],
        selectedMobject: null,
        quantities: [],
        projectQuantities: [],
        clientHeight: 0,
    }
    componentDidMount() {
        this.findMobjects();
        if (this.ref.current.clientHeight) {
            this.setState({
                clientHeight: this.ref.current.clientHeight
            })
        }
    }

    findMobjects = () => {
        let stageId = this.props.constageDetail?.id;
        if (!stageId) return;
        let projectId = this.context;
        let queryProjectGlobalQuantitiesPromise = queryProjectGlobalQuantities(projectId);
        let queryMobjectsPromise = queryMobjects({
            project_id: projectId,
            stage_id: stageId,
        })
        axios.all([queryProjectGlobalQuantitiesPromise, queryMobjectsPromise]).then(res => {
            this.setState({
                mobjects: res[1].data,
                projectQuantities: res[0].data,
            })
        })
    }

    onMobjectSelect = (item) => {
        this.findQuantities(item.id);
        this.setState({
            selectedMobject: item
        });

    }

    findQuantities = (mobjectId: number) => {
        queryQuantities(this.context, {
            mobject_id: mobjectId,
            activated: true,
        }).then(res => {
            this.setState({
                quantities: res.data || []
            })
        })
    }

    findChineseName = (type: number) => {
        let pQuantity = this.state.projectQuantities.find(item => item.type === type);
        return pQuantity.chinese_name || '';
    }

    render() {
        let {selectedMobject} = this.state;
        return (
            <div className="constage-mobject-container" ref={this.ref}>
                <div className="header">
                    <div className="title">对象列表</div>
                    {/* <Button type="link">关联</Button> */}
                </div>
                <div style={{ display: 'flex' }}>
                    <div className="mobject-group-container" style={{height: this.state.clientHeight-53}}>
                        <div className="mobject-list">
                            {this.state.mobjects?.map((item, idx) => (
                                <div key={idx} className={classnames({
                                    'mobject-button': true,
                                    'mobject-button-active': this.state.selectedMobject?.id === item.id
                                })} onClick={() => this.onMobjectSelect(item)}>{item.name}</div>
                            ))}
                        </div>
                    </div>
                    <div className="mobject-quantity">
                        <div className="quantity-list">
                            {this.state.quantities.map((item, idx) => (
                                <div className="quantity-item" key={idx}>
                                    <Link to={`/projects/${this.context}/mobjects/${MobjectBaseType[selectedMobject.type].name}/${item.mobject_id}?tab=2&group=${item.group_id}`}>
                                        {`${this.findChineseName(item.type)}${item.name}`}
                                    </Link>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}

export default withRouter(ConstageMobjectPanel);