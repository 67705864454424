import React, { Component, Fragment } from 'react'
import './sensor-detail.less';
import { inject, observer } from 'mobx-react';
import { RProps } from '@/config/props';
import GlobalDeviceStore from '@/store/global-device';
import { Spin, Row, Col, Descriptions, Button, Radio, Tabs, Modal, message, Tooltip, Empty, Checkbox, Space } from 'antd';
import { deviceException } from '@/interface/exception';
import { sensor, sensorFormula, sensorQuantity, sensorQuantityData } from '@/interface/sensor';
import { manageHistory } from '@/interface/dbox';
import { queryGlobalDboxs, queryGlobalSensors } from '@/config/api/global-device';
import axios from '@/config/axios';
import { querySensorDetail, querySensorManageHistory, updateSensorManageHistory, querySensorFormulas, querySensorQuantities, updateSensor, sensorOffline, sensorWork, sensorAbnormal, sensorTest, sensorInitialize } from '@/config/api/sensor';
import dayjs from 'dayjs';
import { queryExceptions } from '@/config/api/exception';
import { DeviceVarietyLevel } from '../global-install/global-install';
import { deviceManageStatus, MobjectBaseType } from '@/utils/type';
import DeviceManageHistory from '@/components/device/device-manage-history';
import DeviceException from '@/components/device/device-exception';
import { queryEnterprisesInstallations } from '@/config/api/global-install';
import UserStore from '@/store/user-store';
import { globalInstall } from '@/interface/install';
import { ProjectContext } from '@/config/context';
import { queryProjectDetail } from '@/config/api/project';
import { queryMobjectDetail, queryMobjectPatternList } from '@/config/api/mobject';
import { EditOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import SensorDboxSelect from '@/components/device/sensor-dbox-select';
import IconFont from '@/config/iconfont';
import DeviceFigure from '@/components/device/device-figure';
import SensorFormulaPane from '@/components/device/sensor-formula';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import { globalQuantity, qDataBase, quantity } from '@/interface/quantity';
import SensorPositionPane from '@/components/device/sensor-position';
import { figureUploadUrl, patternPicStaticLocation } from '@/config/api/document';
import SensorDataTool from '@/components/device/sensor-data-tool';
import SensorStore, { Fiber_Sensor_types } from '@/store/sensor-store';
import { querySensorQuantityData } from '@/config/api/quantity-data';
import { Transfer2DisplayScale, NumFixed } from '@/utils/utils';
import LineChartBoard from '@/components/charts/line-chart-board';
import { SensorStatusTypesEnum } from './sensor-list';
import { QuantityName } from '@/components/QuantityName';
import { Link } from 'react-router-dom';
import { queryQuantities } from '@/config/api/quantity';
import { QuantityLevelTypes } from '@/components/mobject/quantity-pattern-setting';
import { SensorFiberParamsPane } from '@/components';
const { TabPane } = Tabs;

interface IProps extends RProps {
    globalDeviceStore?: GlobalDeviceStore,
    userStore?: UserStore,
    sensorStore?: SensorStore,
}
interface IState {
    sensorDetail: sensor,
    statusHistory: manageHistory[],
    sensorExceptions: deviceException[],
    statusLoading: boolean,
    frequencyLoading: boolean,
    initializeLoading: boolean,
    gInstalls: globalInstall[],
    sensorFormulas: sensorFormula[],
    sensorFormulaTabKey: string,
    sensorQuantities: sensorQuantity[],
    quantities: quantity[],
    isPositionEdit: boolean,

    sensorDataList: sensorQuantityData[],
    dataLoading: boolean,
    sensorTestLoading: boolean
    sensorInitialLoading: boolean

    pageLoading: boolean

    autoFlushData: boolean
}

@inject('routeStore', 'globalDeviceStore', 'userStore', 'sensorStore')
@observer
export default class SensorDetailPage extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        sensorDetail: null,
        statusHistory: [],
        sensorExceptions: [],
        statusLoading: false,
        frequencyLoading: false,
        initializeLoading: false,
        gInstalls: [],
        sensorFormulas: [],
        sensorFormulaTabKey: "0",
        sensorQuantities: [],
        quantities: [],
        isPositionEdit: false,

        sensorDataList: [],
        dataLoading: false,
        sensorTestLoading: false,
        sensorInitialLoading: false,

        pageLoading: false,

        autoFlushData: false,
    }
    sensorId: number;
    tab_uuid: string;
    timer: any;

    isAutoDataLoading = false;

    constructor(props) {
        super(props)
        let id = this.props.match.params['sensorId'];
        this.tab_uuid = this.props.routeStore.addRoutePane(`传感器${id}`, this.props.match.url);
        this.sensorId = id;
    }
    componentDidMount() {
        let id = this.props.match.params['sensorId'];
        this.generateData(id);
        this.findInstallList();

        this.timer = setInterval(
            () => {
                if (!this.state.autoFlushData) return;
                if (this.isAutoDataLoading) return;
                this.isAutoDataLoading = true;
                this.findSensorDataList(this.state.sensorQuantities || [], false);
            },
            1000 * 2,
        );
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    componentDidUpdate() {
        let id = this.props.match.params['sensorId'];
        this.tab_uuid = this.props.routeStore.addRoutePane(`传感器${id}`, this.props.match.url);
        if (this.sensorId !== id) {
            this.sensorId = id;
            this.generateData(id);
        }
    }

    generateData = (id) => {
        this.loadSensor(id);
        this.findSensorManageHistory(id);
        this.findSensorExceptionList(id);
        this.findSensorFormula(id);
        this.findSensorQuantities(id);
    }

    loadSensor = (id: number) => {
        if (this.props.globalDeviceStore._globalDboxs.length === 0 || this.props.globalDeviceStore._globalSensors.length === 0) {
            // 如果未加载过全局设备，则先请求
            let globalDboxPromise = queryGlobalDboxs();
            let globalSensorPromise = queryGlobalSensors();
            axios.all([globalDboxPromise, globalSensorPromise])
                .then(res => {
                    this.props.globalDeviceStore.globalDboxs(res[0].data);
                    this.props.globalDeviceStore.globalSensors(res[1].data);
                    this.findSensorDetail(id);
                })
        } else {
            this.findSensorDetail(id);
        }
    }


    findSensorDetail = (id: number) => {
        this.setState({
            pageLoading: true,
        })
        querySensorDetail(id)
            .then(res => {
                let sensorDetail: sensor = res.data;
                let global = this.props.globalDeviceStore._globalSensors.find(item => item.type === sensorDetail.type);
                sensorDetail.type_name = global ? global.name : '';
                this.setState({
                    sensorDetail: sensorDetail,
                })
                this.props.routeStore.changeRoutePaneName(this.tab_uuid, `传感器${sensorDetail?.name}`);
                let mobjectId = sensorDetail.mobject_id;
                let groupId = sensorDetail.group_id;
                if (!!mobjectId) {
                    queryMobjectDetail(mobjectId, {
                        project_id: this.context,
                    }).then(res => {
                        sensorDetail.mobject = res.data;
                        this.setState({
                            sensorDetail: { ...sensorDetail },
                        })
                        const projectId = this.context;
                        let queryPQuantitiesPromise = queryProjectGlobalQuantities(projectId);
                        let quantityListPromise = queryQuantities(projectId, {
                            mobject_id: mobjectId,
                            activated: true,
                            level: QuantityLevelTypes.OTHER_Q
                        });
                        let queryPatternListPromise = queryMobjectPatternList(mobjectId, {
                            project_id: projectId,
                        });
                        return axios.all([queryPQuantitiesPromise, quantityListPromise, queryPatternListPromise])
                    }).then(res => {
                        let pQuantities: globalQuantity[] = res[0].data || [];
                        let quantities: quantity[] = res[1].data || [];
                        quantities.forEach((q: quantity) => {
                            let pq = pQuantities.find(item => item.type === q.type);
                            if (!pq) return;
                            q.chinese_name = pq.chinese_name;
                            q.mobject_name = sensorDetail.mobject?.name || '';
                        })
                        quantities = quantities.sort((a, b) => b.qt_type - a.qt_type);
                        let patterns = res[2].data || [];
                        sensorDetail.pattern = patterns.find(item => !!groupId && item.quantity_group_id === groupId);
                        this.setState({
                            sensorDetail: { ...sensorDetail },
                            quantities: quantities,
                        })
                    })
                }
            }).finally(() => {
                this.setState({
                    pageLoading: false,
                })
            })
    }

    findSensorFormula = (id) => {
        let projectId = this.context;
        let queryPQuantitiesPromise = queryProjectGlobalQuantities(projectId);
        let querySensorFormulaPromise = querySensorFormulas(id);
        axios.all([queryPQuantitiesPromise, querySensorFormulaPromise])
            .then(res => {
                let pQuantities: globalQuantity[] = res[0].data || [];
                let sensorFormulas: sensorFormula[] = res[1].data || [];
                sensorFormulas = sensorFormulas.sort((a, b) => a.location > b.location ? 1 : -1)
                sensorFormulas.forEach(item => {
                    item.gQuantity = pQuantities.find(q => q.name === item.sensor_quantity_name);
                    item.quantity_chinese_name = item.gQuantity?.chinese_name || '';
                })
                this.setState({
                    sensorFormulas: sensorFormulas,
                })
                this.props.sensorStore.setForceRender(true);
            })
    }

    findSensorQuantities = (id) => {
        let projectId = this.context;
        let queryPQuantitiesPromise = queryProjectGlobalQuantities(projectId);
        let querySensorQuantitiesPromise = querySensorQuantities(this.context, {
            sensorId: id,
            // level: QuantityLevelTypes.SENSOR_MQ,
        })
        axios.all([queryPQuantitiesPromise, querySensorQuantitiesPromise]).then(res => {
            let pQuantities = res[0].data || [];
            let sensorQuantities: sensorQuantity[] = res[1].data || [];
            sensorQuantities = sensorQuantities.sort((a, b) => b.level - a.level);
            sensorQuantities.forEach(q => {
                let gQuantity: globalQuantity = pQuantities.find(item => item.type === q.type);
                if (gQuantity) {
                    q.chinese_name = gQuantity.chinese_name;
                    q.unit_type = gQuantity.unit_type;
                    q.precision = gQuantity.precision;
                    let scale = Transfer2DisplayScale(q.unit_type);
                    q.scale = scale;
                }
            })
            this.setState({
                sensorQuantities: sensorQuantities
            })
            this.findSensorDataList(sensorQuantities);
        })
    }

    findSensorDataList = (sensorQuantities: sensorQuantity[], loading: boolean=true) => {
        this.setState({
            dataLoading: loading,
        })
        let promiseList = sensorQuantities.map(item => {
            let id = item.id;
            let params = {
                start_time: this.props.sensorStore._startTime?.getTime() || null,
                end_time: this.props.sensorStore._endTime?.getTime() || null,
                sampling_period: this.props.sensorStore._samplingPeriod || null,
                data_type: this.props.sensorStore._dataType || 1,
            }
            if (params.start_time == null) {
                params['limit'] = 300;
            } else if (params.sampling_period == null) {
                let end = params.end_time;
                if (end == null) {
                    end = new Date().getTime();
                }
                let start = params.start_time;
                // TODO: 计算降采样
                params.sampling_period = this.calculateDownSample(start, end);
                this.props.sensorStore.setSamplingPeriod(params.sampling_period);
            }
            if (params.sampling_period === -1) {
                params.sampling_period = null;
            }
            return querySensorQuantityData(id, params);
        })
        axios.all([...promiseList])
            .then(res => {
                let data: sensorQuantityData[] = res.map(item => item.data) || [];
                sensorQuantities.forEach((q: sensorQuantity, idx) => {
                    let qData = data[idx];
                    qData.data = qData.data?.map(item => {
                        item.value *= q.scale
                        return item;
                    }) || [];
                    qData.max = qData.max ? qData.max * q.scale : null;
                    qData.min = qData.min ? qData.min * q.scale : null;
                    qData.mean = qData.mean ? qData.mean * q.scale : null;
                    qData.precision = q.precision;
                    qData.unit_type = q.unit_type;
                    qData.chinese_name = q.chinese_name;
                    // q.quantityData = data[idx];
                })
                this.setState({
                    sensorDataList: data,
                    dataLoading: false,
                })
            }).finally(() => {
                this.setState({
                    dataLoading: false,
                })
                this.isAutoDataLoading = false;
            })
    }

    calculateDownSample = (start: number, end: number) => {
        const date1 = dayjs(start);
        const date2 = dayjs(end);
        if (date2.diff(date1, 'M') >= 2) {
            return 86400;
        }
        if (date2.diff(date1, 'M') >= 1) {
            return 43200;
        }
        if (date2.diff(date1, 'd') >= 10) {
            return 3600;
        }
        if (date2.diff(date1, 'd') >= 1) {
            return 300;
        }
        return null;
    }

    findSensorManageHistory = (sensorId: number) => {
        querySensorManageHistory(sensorId, {
            size: 5,
        })
            .then(res => {
                let data: manageHistory[] = res.data;
                data = data.sort((a, b) => dayjs(b.create_time).valueOf() - dayjs(a.create_time).valueOf());
                this.setState({
                    statusHistory: data.slice(0, 5)
                })
            })
    }

    findInstallList = () => {
        let projectId = this.context;
        queryProjectDetail(projectId)
            .then(res => {
                let enterpriseId = res.data?.enterprise_id;
                return queryEnterprisesInstallations(enterpriseId)
            }).then(res => {
                let gInstalls = res.data || [];
                gInstalls = gInstalls.filter(item => item.level === DeviceVarietyLevel.SENSOR);
                this.setState({
                    gInstalls: gInstalls
                })
            })
    }

    findSensorExceptionList = (sensorId: number) => {
        queryExceptions({
            project_id: this.context,
            device_id: sensorId,
            device_variety: DeviceVarietyLevel.SENSOR,
            size: 7,
        }).then(res => {
            let data: deviceException[] = res.data;
            this.setState({
                sensorExceptions: data.slice(0, 7),
            })
        })
    }

    changeSensorManageStatus = (value) => {
        let manage_content = deviceManageStatus[value];
        updateSensorManageHistory(this.sensorId, null, {
            manage_status: value,
            manage_content: manage_content,
        }).then(res => {
            this.findSensorManageHistory(this.sensorId);
        })
    }

    findInstallName = () => {
        let install = this.state.gInstalls.find(item => item.id === this.state.sensorDetail?.install_id) || null;
        if (install) {
            return install.name;
        }
        return '';
    }

    afterDboxSave = (dboxId: number, channel: number) => {
        let sensorDetail: sensor = this.state.sensorDetail;
        sensorDetail.dbox_id = dboxId;
        sensorDetail.channel = channel;
        this.setState({
            sensorDetail: { ...sensorDetail }
        })
    }

    afterFomulaUpdate = (formula, idx) => {
        let sensorFormulas = this.state.sensorFormulas;
        sensorFormulas[idx] = formula;
        this.setState({
            sensorFormulas: [...sensorFormulas]
        })
    }
    afterFiberSensorParamsUpdate = (values) => {
        let sensorDetail = {
            ...this.state.sensorDetail,
            ...values,
        };
        this.setState({
            sensorDetail: sensorDetail
        })
    }

    afterPositionSave = (data) => {
        let sensorDetail = this.state.sensorDetail;
        sensorDetail.install_id = data.install_id;
        sensorDetail.mobject_id = data.mobject_id;
        sensorDetail.location = data.location;
        sensorDetail.group_id = data.group_id;
        if (!!data.mobject_id) {
            let mobject_id = data.mobject_id;
            let group_id = data.group_id;
            queryMobjectDetail(mobject_id, {
                project_id: this.context,
            }).then(res => {
                sensorDetail.mobject = res.data;
                this.setState({
                    sensorDetail: { ...sensorDetail },
                })
                return queryMobjectPatternList(mobject_id, {
                    project_id: this.context,
                })
            }).then(res => {
                let patterns = res.data || [];
                sensorDetail.pattern = patterns.find(item => !!group_id && item.quantity_group_id === group_id);
                this.setState({
                    sensorDetail: { ...sensorDetail },
                })
            })
        }
        this.setState({
            isPositionEdit: false,
        })
    }

    checkQuantityLink = () => {
        if (this.state.sensorQuantities.length === 0) return false;
        let res = false;
        this.state.sensorQuantities?.forEach(item => {
            if (!!item.dq_id) {
                res = true;
                return;
            }
        })
        return res;
    }

    saveSensorFigure = (url) => {
        updateSensor(this.sensorId, {
            figure_url: url
        }).then(res => {
            this.state.sensorDetail.figure_url = url;
            this.setState({
                sensorDetail: { ...this.state.sensorDetail }
            })
        })
    }

    goToMobjectDetail = () => {
        if (!this.state.sensorDetail?.mobject) return;
        let mobject = this.state.sensorDetail?.mobject;
        let typeName = MobjectBaseType[mobject.type]['name'];
        let url = `/projects/${this.context}/mobjects/${typeName}/${mobject.id}`;
        // console.log(url)
        this.props.history.push(url);
    }

    onQueryParamsChange = () => {
        this.findSensorDataList(this.state.sensorQuantities);
    }

    onWorkStatusChange = (e) => {
        let status = e.target.value;
        let id = this.sensorId;
        if (!id) return;
        if (status === SensorStatusTypesEnum.OFFLINE) {
            sensorOffline(id)
                .then(res => { })
        } else if (status === SensorStatusTypesEnum.ONLINE) {
            sensorWork(id)
                .then(res => { })
        } else if (status === SensorStatusTypesEnum.ABNORMAL) {
            sensorAbnormal(id)
                .then(res => { })
        }
    }

    onSensorTestClick = () => {
        this.setState({
            sensorTestLoading: true,
        })
        let id = this.sensorId;
        if (!id) return;
        sensorTest(id)
            .then(res => {
                let data: any[] = res.data;
                data = data.sort((a, b) => b.level - a.level);
                let result: qDataBase[] = [];
                data.forEach(item => {
                    let value: number = item.value;
                    let id = item.id;
                    let q: sensorQuantity = this.state.sensorQuantities.find(sq => sq.id === id);
                    if (!!q) {
                        q = { ...q, current: NumFixed(value * q.scale, 7) };
                        result.push(q);
                    }
                })
                Modal.info({
                    title: '手动测量结果',
                    content: (
                        <Descriptions column={1}>
                            {result.map(item => (
                                <Descriptions.Item key={item.id} label={<QuantityName record={item} />}>
                                    {item.current.toFixed(+item.precision)}
                                    {item.unit_type}
                                </Descriptions.Item>
                            ))}
                        </Descriptions>
                    )
                })
                console.log(data);
            }).catch(err => {
                console.log(err)
                // message.error(err);
                // console.log(err?.data?.message)
            })
            .finally(() => {
                this.setState({
                    sensorTestLoading: false,
                })
            })
    }

    onSensorInitialClick = () => {
        this.setState({
            sensorInitialLoading: true,
        })
        let id = this.sensorId;
        if (!id) return;
        sensorInitialize(id)
            .then(res => {
                // this.generateData(id);
                this.props.sensorStore.setForceRender(true);
                message.success('信号已置零');
            }).catch(err => {
                console.log(err);
                message.error(err.data?.message);
            }).finally(() => {
                this.setState({
                    sensorInitialLoading: false,
                })
            })
    }

    afterDataDelete = () => {
        this.findSensorDataList(this.state.sensorQuantities);
    }

    gotoMobjectQuantityDetail = () => {
        let sensorDetail = this.state.sensorDetail;
        let mobjectId = sensorDetail.mobject_id;
        if (!mobjectId) return;
        queryMobjectDetail(mobjectId, {
            project_id: this.context,
        }).then(res => {
            let m = res.data;
            let typeName = MobjectBaseType[m.type]['name'];
            let url = `/projects/${this.context}/mobjects/${typeName}/${mobjectId}?tab=2`;
            this.props.history.push(url);
        })
    }

    gotoDboxDetail = () => {
        let dboxId = this.state.sensorDetail?.dbox_id;
        if (!dboxId) return;
        let url = `/projects/${this.context}/dboxs/${dboxId}`;
        this.props.history.push(url);
    }

    gotoAnotherSensor = (id: number) => {
        if (!id) return;
        let url = `/projects/${this.context}/sensors/${id}`;
        this.props.routeStore.changeRoutePaneUrl(this.tab_uuid, url);
        this.props.history.push(url);
    }

    goToMobjectDetailQuantity = (record: quantity) => {
        if (!this.state.sensorDetail?.mobject) return;
        let mobject = this.state.sensorDetail?.mobject;
        let typeName = MobjectBaseType[mobject.type]['name'];
        let url = `/projects/${this.context}/mobjects/${typeName}/${mobject.id}?tab=2&group=${record.group_id}`;
        this.props.history.push(url);

        // if (!record.mobject) return;
        // let mobject = record.mobject;
        // let typeName = MobjectBaseType[mobject?.type]['name'];
        // let url = `/projects/${this.context}/mobjects/${typeName}/${mobject.id}?tab=2&group=${record.group_id}`;
        // this.props.history.push(url);
    }


    render() {
        let { sensorDetail, sensorFormulas, isPositionEdit, gInstalls } = this.state;
        if (!sensorDetail || this.state.pageLoading) {
            return <Spin size="large" />;
        }
        return (
            <div className="sensor-detail-container">
                <Row gutter={[10, 10]} className="content">
                    <Col xs={24} xl={14}>
                        <div className="card properties">
                            <Row>
                                <Col span={8}>
                                    <div className="title">基本信息</div>
                                    <Descriptions
                                        column={1}>
                                        <Descriptions.Item label="编号">
                                            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{sensorDetail.name}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="名称">
                                            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{sensorDetail.type_name}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="型号">{sensorDetail.marking}</Descriptions.Item>
                                        <Descriptions.Item label="厂家">{sensorDetail.manufacturer}</Descriptions.Item>
                                        <Descriptions.Item label="量程">{sensorDetail.range}</Descriptions.Item>
                                        <Descriptions.Item label="精度">{sensorDetail.precision}</Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col span={8}>
                                    <div className="edit-header">
                                        <div className="title">
                                            定位
                                        </div>
                                        {!!sensorDetail?.mobject && !isPositionEdit && (
                                            <Button
                                                type="link"
                                                size="small"
                                                icon={<EditOutlined />}
                                                onClick={() => this.setState({ isPositionEdit: true })}></Button>
                                        )}
                                    </div>
                                    <div>
                                        {!!sensorDetail?.mobject && !isPositionEdit && (
                                            <Fragment>
                                                {/* <div className="term">
                                                    <div className="label">安装方法</div>
                                                    <span className="detail">{this.findInstallName()}</span>
                                                </div> */}
                                                <div className="term">
                                                    <div className="label">监测对象</div>
                                                    <span
                                                        className="detail can-click"
                                                        onClick={this.goToMobjectDetail}>
                                                        {sensorDetail?.mobject?.name}
                                                    </span>
                                                </div>
                                                <div className="term">
                                                    <div className="label">位置</div>
                                                    <span className="detail">{sensorDetail?.location}</span>
                                                </div>
                                                <div>
                                                    <div className="figure" style={{ height: '150px', width: '200px' }}>
                                                        {
                                                            sensorDetail?.pattern?.figure_url ?
                                                                <img
                                                                    width="100%"
                                                                    height="100%"
                                                                    alt="模式图"
                                                                    src={`${patternPicStaticLocation}${sensorDetail?.pattern?.figure_url}`} />
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )}
                                        {!sensorDetail?.mobject && !isPositionEdit && (
                                            <Button
                                                type="dashed"
                                                size="large"
                                                style={{ height: '120px' }}
                                                onClick={() => this.setState({ isPositionEdit: true })}>
                                                <div>
                                                    <div>
                                                        <PlusOutlined />
                                                    </div>
                                                    <div>设置传感器定位</div>
                                                </div>
                                            </Button>
                                        )}
                                        {isPositionEdit && (
                                            <SensorPositionPane
                                                gInstalls={gInstalls}
                                                sensorDetail={sensorDetail}
                                                afterSave={this.afterPositionSave}
                                                onCancel={() => this.setState({ isPositionEdit: false })} />
                                        )}
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="operations">
                                        <div className="title">操作</div>
                                        <div className="operation-item">
                                            <Radio.Group
                                                defaultValue={sensorDetail?.work_status}
                                                onChange={this.onWorkStatusChange}
                                                className="sensor-work-radio"
                                                buttonStyle="solid">
                                                <Radio.Button value={0}>离线</Radio.Button>
                                                <Radio.Button value={1}>工作</Radio.Button>
                                                <Radio.Button value={2}>异常</Radio.Button>
                                            </Radio.Group>
                                        </div>
                                        <div className="operation-item">
                                            <Button
                                                type="primary"
                                                size="middle"
                                                loading={this.state.sensorTestLoading}
                                                onClick={this.onSensorTestClick}>手动测量</Button>
                                        </div>
                                        <div className="operation-item">
                                            <Button
                                                danger
                                                loading={this.state.sensorInitialLoading}
                                                onClick={this.onSensorInitialClick}>信号归零</Button>
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} xl={5}>
                        <DeviceManageHistory
                            onChange={this.changeSensorManageStatus}
                            currentStatus={sensorDetail?.manage_status}
                            statusHistory={this.state.statusHistory} />
                    </Col>
                    <Col xs={12} xl={5} >
                        <DeviceException deviceExceptions={this.state.sensorExceptions} />
                    </Col>
                    <Col span={24}>
                        <div>
                            <Row className="sensor-link-pane">
                                <Col xs={12} xxl={8}>
                                    <div className="dbox-pane">
                                        <SensorDboxSelect
                                            afterSave={this.afterDboxSave}
                                            onSensorChange={this.gotoAnotherSensor}
                                            sensorDetail={sensorDetail} />
                                    </div>
                                </Col>
                                <Col xs={12} xxl={8}>
                                    <div className="figure-pane">
                                        <div className="title">现场照片</div>
                                        <DeviceFigure
                                            uploadUrl={figureUploadUrl}
                                            imgUrl={sensorDetail.figure_url}
                                            afterUpload={this.saveSensorFigure} />
                                    </div>
                                </Col>
                                <Col xs={24} xxl={8}>
                                    <div className="params-pane">
                                        <div className="title">信号配置</div>
                                        <Tabs
                                            tabPosition="left"
                                            size="small"
                                            type="card"
                                            activeKey={this.state.sensorFormulaTabKey}
                                            onChange={(e) => this.setState({ sensorFormulaTabKey: e })}>
                                            {sensorFormulas.map((item, idx) => (
                                                <TabPane key={`${idx}`} tab={`物理量${idx + 1}`}>
                                                    <SensorFormulaPane
                                                        formula={item}
                                                        sensorDetail={sensorDetail}
                                                        afterSave={(value) => this.afterFomulaUpdate(value, idx)} />
                                                </TabPane>
                                            ))}
                                            <TabPane tab="指标" key="-1">
                                                <div className="quantity-list">
                                                    <h3>与该传感器相关的上层指标链接</h3>
                                                    {this.state.quantities.length === 0 && (
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    )}
                                                    <Row>
                                                        {this.state.quantities.map((q, idx) => (
                                                            <Col span={8} key={idx}>
                                                                <div className="quantity-item" onClick={() => this.goToMobjectDetailQuantity(q)}>
                                                                    <span style={{ marginRight: '4px' }}>{q.mobject_name}</span>
                                                                    <QuantityName showChineseName={false} record={q} />
                                                                </div>
                                                            </Col>
                                                        ))}
                                                    </Row>

                                                </div>
                                            </TabPane>
                                            {Fiber_Sensor_types.some(x => x === sensorDetail.type) && (
                                                <TabPane tab="初始波长" key="-2">
                                                    <SensorFiberParamsPane 
                                                        sensorDetail={sensorDetail}
                                                        afterSave={(value) => this.afterFiberSensorParamsUpdate(value)}/>
                                                </TabPane>
                                            )}

                                        </Tabs>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col span={24}>
                        <div >
                            <Row className="link-tooltip-pane">
                                <Col span={7}>
                                    <div className="link-pane" style={{ cursor: 'pointer' }} onClick={this.gotoDboxDetail}>
                                        <IconFont type="icon-xiangbox59" />
                                        <div className="link-footer">
                                            采集箱
                                        </div>
                                    </div>

                                </Col>
                                <Col span={1}>
                                    <div className="link-pane">
                                        {!!sensorDetail.dbox_id && !!sensorDetail.channel && (
                                            <IconFont className="link" type="icon-link" />
                                        )}
                                        {(!!!sensorDetail.dbox_id || !!!sensorDetail.channel) && (
                                            <IconFont className="unlink" type="icon-unlink" />
                                        )}
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="link-pane">
                                        <IconFont type="icon-zu" />
                                        <div className="link-footer">
                                            传感器
                                        </div>
                                    </div>
                                </Col>
                                <Col span={1}>
                                    <div className="link-pane">
                                        {this.checkQuantityLink() && (
                                            <IconFont className="link" type="icon-link" />
                                        )}
                                        {!this.checkQuantityLink() && (
                                            <IconFont className="unlink" type="icon-unlink" />
                                        )}
                                    </div>
                                </Col>
                                <Col span={7}>
                                    <div className="link-pane" style={{ cursor: 'pointer' }} onClick={this.gotoMobjectQuantityDetail}>
                                        <IconFont type="icon-xinhao" />
                                        <div className="link-footer">
                                            指标信号源
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </Col>
                </Row>

                <div className="data-display-pane">
                    <div style={{ display: 'flex', marginBottom: '16px' }}>
                        <Space>
                            <Tooltip title="更新数据">
                                <Button
                                    shape="circle"
                                    type="primary"
                                    icon={<SyncOutlined spin={this.state.dataLoading} />}
                                    onClick={() => this.findSensorDataList(this.state.sensorQuantities)}></Button>
                            </Tooltip>
                            <Checkbox checked={this.state.autoFlushData} onChange={() => { this.setState({ autoFlushData: !this.state.autoFlushData }) }}>后台刷新</Checkbox>
                        </Space>
                        <div className="fill-remaining-space"></div>
                        <SensorDataTool onQueryParamsChange={this.onQueryParamsChange} />

                    </div>
                    <Spin spinning={this.state.dataLoading}>
                        {this.state.sensorDataList.map((q: sensorQuantityData) => (
                            <div key={q.id}>
                                <LineChartBoard q={q} afterDelete={this.afterDataDelete} isSensor />
                            </div>
                        ))}
                    </Spin>
                </div>
            </div >
        )
    }
}
