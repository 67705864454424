import React, { Component } from 'react'
import './project-log.less';
import { withRoutePane } from '@/components/hoc/routePane';
import { pLog } from '@/interface/log';
import { ProjectContext } from '@/config/context';
import { DatePicker } from '@/components';
import { Table } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { queryLogs } from '@/config/api/log';
import classnames from 'classnames';
import Column from 'antd/lib/table/Column';
import { UserTypes } from '@/utils/type';

const { RangePicker } = DatePicker;
interface IProps {

}
interface IState {
    logs: pLog[],
    loading: boolean,
    startTime: Dayjs,
    endTime: Dayjs,
}
class ProjectLogPage extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        logs: [],
        loading: false,
        timeRange: [],
        startTime: null,
        endTime: null,
    }
    componentDidMount() {
        this.findLogs();
    }

    findLogs = () => {
        const projectId = this.context;
        this.setState({
            loading: true,
        })
        let params = {
            project_id: projectId,
            start_time: this.state.timeRange[0] || null,
            end_time: this.state.timeRange[1] || null,
        }
        queryLogs(params)
            .then(res => {
                let logs = res.data || [];
                this.setState({
                    logs: logs,
                    loading: false,
                })
            }).finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    onTimeChange = (date) => {
        this.setState({
            startTime: date[0],
            endTime: date[1],
        })
        this.findLogs();
    }

    render() {
        const { logs, loading, timeRange } = this.state;
        return (
            <div className="project-log-container">
                <div className="header">
                    <RangePicker value={[this.state.startTime, this.state.endTime]} onChange={this.onTimeChange}/>
                </div>
                <div className="log-main">
                    <Table
                        dataSource={logs}
                        loading={loading}
                        rowKey="id"
                        rowClassName={(record, index) => classnames({
                            'ribbon-row': index % 2 !== 0,
                        })}
                        pagination={logs.length < 30 ? false : ({
                            pageSize: 30
                        })}
                        >
                        <Column title="时间" dataIndex="create_time" width="12%" render={record => dayjs(record).format('YYYY-MM-DD HH:mm')} />
                        <Column title="用户" dataIndex="name" width="12%" />
                        <Column title="职位" dataIndex="position" width="12%" />
                        <Column title="类别" dataIndex="type" width="12%" render={record => UserTypes[record]}/>
                        <Column title="内容" dataIndex="content" ellipsis/>
                        <Column title="操作" dataIndex="action" width="10%"/>

                    </Table>
                </div>
            </div>
        )
    }
}

export default withRoutePane(ProjectLogPage, '日志');