import React, { Component } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { Gprops } from '@/config/props';
import { IFMenu, GlobalMenus } from '@/config/menus';
import { Menu } from 'antd';
import IconFont from '@/config/iconfont';
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';
import { UserTypeEnum } from '@/routes/login/login';
interface IProps extends Gprops {
    userStore?: UserStore
}

interface IState {
    defaultOpenKeys: string[]
    selectedKey: string,
}

@inject('userStore')
@observer
class GlobalMenu extends Component<IProps, IState> {

    state = {
        defaultOpenKeys: [],
        selectedKey: '',
    }

    componentDidMount() {
        let keys = [];
        GlobalMenus.forEach((item: IFMenu, index: number) => {
            if (item.subs && item.default_open) {
                keys.push(item.key);
            }
        })
        let selectedKey = this.props.location.pathname;
        this.setState({
            defaultOpenKeys: keys,
            selectedKey: selectedKey,
        })
    }

    componentDidUpdate() {
        let key = this.props.location.pathname;
        if (this.state.selectedKey === key) return;
        this.setState({
            selectedKey: key
        })
    }

    menuClick = (e: any) => {
        this.setState({
            selectedKey: e.key,
        });

        // let key: string = e.key;
        // key = key.replace(`${this.props.match.url}`, '');
        // let route = routeList.filter(item => item.url === key)[0];
        // this.props.onMenuClick(route.alias ? route.alias : e.item.node.innerText, e.key);
    }

    renderMenuItem = (item: IFMenu) => {
        let type = this.props.userStore._userInfo?.type;
        if (item.admin && type !== UserTypeEnum.ADMIN) {
            return null;
        }
        const key = `${item.key}`;
        return (
            <Menu.Item key={key} className="tn-menu-item">
                <NavLink to={(key) + (item.query || '')}>
                    {item.icon && <IconFont type={item.icon} />}
                    <span className="nav-text">{item.title}</span>
                </NavLink>
            </Menu.Item>
        );
        
    };

    renderSubMenu = (item: IFMenu) => {
        let type = this.props.userStore._userInfo?.type;
        if (item.admin && type !== UserTypeEnum.ADMIN) {
            return null;
        }
        const key = item.key;
        return (
            <Menu.SubMenu 
                className="tn-menu-submenu"
                key={key}
                title={
                    <span>
                        {item.icon && <IconFont type={item.icon} />}
                        <span className="nav-text">{item.title}</span>
                    </span>
                }
            >
                {item.subs!.map(sub => (sub.subs ? this.renderSubMenu(sub) : this.renderMenuItem(sub)))}
            </Menu.SubMenu>
        )
    };
    
    render() {
        return (
            <div style={{marginTop: '24px'}}>
                <Menu 
                mode='inline' 
                // theme='dark'
                onClick={this.menuClick}
                inlineIndent={28}
                openKeys={this.state.defaultOpenKeys}
                selectedKeys={[this.state.selectedKey]}
                className='fill-remaining-space'
            >
                {
                    GlobalMenus.map((item: IFMenu, index: number) => (
                        item.subs! ? this.renderSubMenu(item) : this.renderMenuItem(item)
                    ))
                }
            </Menu>
            </div>
        )
    }
}

export default withRouter(GlobalMenu);