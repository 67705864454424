import axios, { AxiosResponse } from 'axios';
import store from '../store/index';
import appStore, { AUTHORIZATION } from '@/store/app-store';

axios.defaults.timeout = 60000;
axios.defaults.baseURL = '/api';

// 状态码定义
// const codeMessage = {
//     200: 'ok',
//     400: '请求数据错误',
//     401: '用户没有权限',
//     404: '请求的资源不存在',
//     500: '服务器异常',
//     503: '服务器过载或维护',
//     504: '网关超时',
// }

// 请求拦截器
axios.interceptors.request.use(
    config => {
        // console.log(config);
        // 从本地LocalStorage获取token
        let token = appStore.get(AUTHORIZATION) || '';
        config.headers.token = token;
        return config;
    },
    err => {
        console.log(err);
    }
)

// 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.headers['content-type'].indexOf('application/json') >= 0) {
            return response.data;
        }
        return response;
    },
    (err) => {
        const error = err.response;
        if (!error) {
            return Promise.reject("发生其他异常");
        }
        if (error.statue === 401) {
            // 未授权登录
            appStore.clear();
            store.userStore.setIsLogin(false);
        }
        // if (error.status === 401) {
        //     // 未授权
        //     store.appStore.clear();
        //     // 设置token超时状态，
        //     store.passportStore.changeTokenTimeout(true);
        //     // window.location.href = '/login';
        // } else if (error.status === 404) {
        //     // 未找到资源
        //     pwdstore.passportStore.changePageError(404);
        // } else if (error.status !== 200) {
        //     // 其他错误
        //     console.log(error);
        //     pwdstore.passportStore.changePageError(500);
        // }
        return Promise.reject(error);
    }
)

export default axios;

export interface StdResponse<T = any> extends AxiosResponse<T> {
    message: string
}