export const UnitMap = {
    'rad': {
        'rad': 1,
        '°': 57.29578,
    },
    '°': {
        'rad': 0.017453,
        '°': 1,
    },
    '℃': {
        '℃': 1,
    },
    'm': {
        'm': 1,
        'cm': 100,
        'mm': 1000,
        'nm': 1e9,
    },
    'cm': {
        'm': 0.01,
        'cm': 1,
        'mm': 10,
        'nm': 1e7,
    },
    'mm': {
        'm': 0.001,
        'cm': 0.1,
        'mm': 1,
        'nm': 1e6
    },
    'nm': {
        'm': 1e-9,
        'cm': 1e-7,
        'mm': 1e-6,
        'nm': 1
    },
    'm^2': {
        'm^2': 1,
        'cm^2': 10000,
        'mm^2': 1000000,
    },
    'cm^2': {
        'm^2': 0.0001,
        'cm^2': 1,
        'mm^2': 100,
    },
    'mm^2': {
        'm^2': 0.000001,
        'cm^2': 0.01,
        'mm^2': 1,
    },
    'm^3': {
        'm^3': 1,
        'cm^3': 1000000,
        'mm^3': 1000000000,
    },
    'cm^3': {
        'm^3': 0.000001,
        'cm^3': 1,
        'mm^3': 1000,
    },
    'mm^3': {
        'm^3': 0.000000001,
        'cm^3': 0.001,
        'mm^3': 1,
    },
    'm^4': {
        'm^4': 1,
        'cm^4': 100000000,
        'mm^4': 1000000000000,
    },
    'cm^4': {
        'm^4': 0.00000001,
        'cm^4': 1,
        'mm^4': 10000,
    },
    'mm^4': {
        'm^4': 0.000000000001,
        'cm^4': 0.0001,
        'mm^4': 1,
    },
    'm/s': {
        'm/s': 1,
        'cm/s': 100,
        'mm/s': 1000,
    },
    'cm/s': {
        'm/s': 0.01,
        'cm/s': 1,
        'mm/s': 10,
    },
    'mm/s': {
        'm/s': 0.001,
        'cm/s': 0.1,
        'mm/s': 1,
    },
    'm/s^2': {
        'm/s^2': 1,
        'cm/s^2': 100,
        'mm/s^2': 1000,
    },
    'cm/s^2': {
        'm/s^2': 0.01,
        'cm/s^2': 1,
        'mm/s^2': 10,
    },
    'mm/s^2': {
        'm/s^2': 0.001,
        'cm/s^2': 0.1,
        'mm/s^2': 1,
    },
    'Pa': {
        'Pa': 1,
        'kPa': 0.001,
        'MPa': 0.000001,
    },
    'kPa': {
        'Pa': 1000,
        'kPa': 1,
        'MPa': 0.001,
    },
    'MPa': {
        'Pa': 1000000,
        'kPa': 1000,
        'MPa': 1,
    },
    'A': {
        'A': 1,
        'mA': 1000,
    },
    'mA': {
        'A': 0.001,
        'mA': 1,
    },
    'V': {
        'V': 1,
        'mV': 1000,
    },
    'mV': {
        'V': 0.001,
        'mV': 1,
    },
    'Ω': {
        'Ω': 1,
    },
    'N': {
        'N': 1,
        'kN': 0.001,
    },
    'kN': {
        'N': 1000,
        'kN': 1,
    },
    'N·m': {
        'N·m': 1,
        'kN·m': 0.001,
    },
    'kN·m': {
        'N·m': 1000,
        'kN·m': 1,
    },
    'kg/m': {
        'kg/m': 1,
    },
    'kg/m^3': {
        'kg/m^3': 1,
    },
    'm^2/m': {
        'm^2/m': 1,
    },
    'με': {
        'ε': 0.000001,
        'με': 1,
    },
    'ε': {
        'ε': 1,
        'με': 1000000,
    },
    'rad/V': {
        'rad/V': 1,
    },
    'mm/V': {
        'mm/V': 1,
        'm/V': 0.001,
    },
    'm/V': {
        'mm/V': 1000,
        'm/V': 1,
    },
    'mV/με': {
        'mV/με': 1,
        'V/ε': 1000,
    },
    'V/ε': {
        'mV/με': 0.001,
        'V/ε': 1,
    },
    'mV/m/s^2': {
        'mV/m/s^2': 1,
        'V/m/s^2': 0.001,
    },
    'V/m/s^2': {
        'mV/m/s^2': 1000,
        'V/m/s^2': 1,
    },
    'V/m/s': {
        'V/m/s': 1,
        'mV/m/s': 1000,
    },
    'mV/m/s': {
        'V/m/s': 0.001,
        'mV/m/s': 1,
    },
    'mA/m/s': {
        'mA/m/s': 1,
        'A/m/s': 0.001,
    },
    'A/m/s': {
        'mA/m/s': 1000,
        'A/m/s': 1,
    },
    'm/s/A': {
        'm/s/mA': 0.001,
        'm/s/A': 1,
    },
    'm/s/mA': {
        'm/s/mA': 1,
        'm/s/A': 1000,
    },
    'mA/°': {
        'mA/°': 1,
        'A/rad': 0.0573,
    },
    'A/rad': {
        'mA/°': 17.45329252,
        'A/rad': 1,
    },
    '°/mA': {
        '°/mA': 1,
        'rad/A': 17.45329252,
    },
    'rad/A': {
        '°/mA': 0.0573,
        'rad/A': 1,
    },
    'Pa/V': {
        'Pa/V': 1,
    },
    'mm/Hz^2': {
        'mm/Hz^2': 1,
        'm/Hz^2': 0.001,
    },
    'm/Hz^2': {
        'mm/Hz^2': 1000,
        'm/Hz^2': 1,
    },
    '1/℃': {
        '1/℃': 1,
    },
    'Hz': {
        'Hz': 1,
    },
    'με/℃': {
        'με/℃': 1,
        'ε/℃': 1e-6,
    },
    'ε/℃': {
        'με/℃': 1e6,
        'ε/℃': 1,
    },
    'nm/℃': {
        'm/℃': 1e-9,
        'nm/℃': 1,
    },
    'm/℃': {
        'm/℃': 1,
        'nm/℃': 1e9,
    },
    'με/Hz^2': {
        'με/Hz^2': 1,
        'ε/Hz^2': 1e-6,
    },
    'ε/Hz^2': {
        'με/Hz^2': 1e6,
        'ε/Hz^2': 1,
    },
    'N/mm^2': {
        'N/mm^2': 1,
        'N/m^2': 1e6,
    },
    'N/m^2': {
        'N/mm^2': 1e-6,
        'N/m^2': 1,
    },
    's': {
        's': 1,
    },
    'με/nm': {
        'με/nm': 1,
        'ε/m': 1000,
    },
    'ε/m': {
        'με/nm': 0.001,
        'ε/m': 1,
    },
    'mm/nm': {
        'mm/nm': 1,
        'm/m': 1e6,
    },
    'm/m': {
        'mm/nm': 1e-6,
        'm/m': 1,
    },
    'rad/m': {
        'rad/m': 1,
        '°/nm': 5.72957e-8,
    },
    '°/nm': {
        'rad/m': 1.745329e7,
        '°/nm': 1,
    }
}

export const ParamUnits = {
    //材料
    'f_d0': 'N/mm^2',
    'f_vy': 'N/mm^2',
    'f_y0': 'N/mm^2',
    'E': 'N/mm^2',
    'G': 'N/mm^2',
    'nu': ' ',
    'alpha': '1/℃',
    'rho': 'kg/m^3',
    'f_ck': 'N/mm^2',
    'f_tk': 'N/mm^2',
    'f_c': 'N/mm^2',
    'f_t': 'N/mm^2',
    'f_yk': 'N/mm^2',
    'f_stk': 'N/mm^2',
    'f_y': 'N/mm^2',
    'f_y1': 'N/mm^2',
    'E_s': 'N/mm^2',
    'E_c': 'N/mm^2',
    'f_u': 'N/mm^2',

    //截面
    'd': 'mm',
    'd_1': 'mm',
    'd_0': 'mm',
    't': 'mm',
    't_0': 'mm',
    't_1': 'mm',
    'h': 'mm',

    'h_1': 'mm',
    'h_2': 'mm',
    'b_0': 'mm',
    'b_00': 'mm',
    'b_1': 'mm',
    'b_2': 'mm',
    't_w': 'mm',
    't_w0': 'mm',
    't_f0': 'mm',
    't_f1': 'mm',
    't_f2': 'mm',
    't_w1': 'mm',
    't_w2': 'mm',
    'b': 'mm',
    'a': 'mm',
    'A': 'mm^2',
    'A_0': 'mm^2',
    'A_1': 'mm^2',
    'A_2': 'mm^2',
    'I_y': 'mm^4',
    'I_y1': 'mm^4',
    'I_y2': 'mm^4',
    'I_z': 'mm^4',
    'I_z1': 'mm^4',
    'I_z2': 'mm^4',
    'l_1': 'mm',
    'L': 'mm',
    'L_1': 'mm',
    'L_2': 'mm',
    'y_p': 'mm',
    'z_p': 'mm',
    'c_1': 'mm',
    'c_2': 'mm',
    'c_11': 'mm',
    'c_12': 'mm',
    'c_21': 'mm',
    'c_22': 'mm',
    'I_yz': 'mm^4',
    'I_x': 'mm^4',
    'I_w': 'mm^4',
    'I_p': 'mm^4',
    'W_yte': 'mm^3',
    'W_ybe': 'mm^3',
    'W_zLe': 'mm^3',
    'W_zRe': 'mm^3',
    'W_yp': 'mm^3',
    'W_zp': 'mm^3',
    'i_y': 'mm',
    'i_z': 'mm',
    'Q_y': 'mm^3',
    'Q_z': 'mm^3',
    'A_sy': 'mm^2',
    'A_sz': 'mm^2',
    'y_1': 'mm',
    'y_2': 'mm',
    'y_3': 'mm',
    'y_4': 'mm',
    'z_1': 'mm',
    'z_2': 'mm',
    'z_3': 'mm',
    'z_4': 'mm',
    'S_y': 'mm',
    'S_z': 'mm',
    'g': 'kg/m',
    's': 'm^2/m',

    //模式
    'x_0': 'mm',
    'x_1': 'mm',
    'x_2': 'mm',
    'x_3': 'mm',
    'x_4': 'mm',
    'x_5': 'mm',
    'e_1': 'mm',
    'e_2': 'mm',
    'e_3': 'mm',
    'e_4': 'mm',
    'e_5': 'mm',
    'e_6': 'mm',
    'e_7': 'mm',
    'T_sec': '',
    'T_sec_y': '',
    'T_sec_z': '',

    'beta_b': '',
    'M_iy': 'kN·m',
    'M_y__i': 'kN·m',
    'M_y__j': 'kN·m',
    'M_z__i': 'kN·m',
    'M_z__j': 'kN·m',
    'N__i': 'kN',
    'N__j': 'kN',

    'M_jy': 'kN·m',
    'M_iz': 'kN·m',
    'M_jz': 'kN·m',
    'N_i': 'kN',
    'N_j': 'kN',
    'H_1': 'mm',

    'dt': 's',

    'h_s0': 'mm',

    // 单元
    'miu_y': '',
    'miu_z': '',
    'l_0': 'mm',
    'H_0': 'mm',
    'h_0': 'mm',
    'L_0': 'mm',
    'l': 'mm',

}

export const SensorParamUnits = {
    // 传感器
    'k_G': 'με/Hz^2',
    'k_T': 'με/℃',
    'k_IN': 'rad/V',
    'k_INA': '°/mA',
    'k_DP': 'mm/V',
    's_VS': 'mV/με',
    's_VA': 'mV/m/s^2',
    's_VV': 'mV/m/s',
    'k_UB': 'm/s/mA',
    'k_US': '°/mA',
    'k_MB': 'm/s/mA',
    'k_MS': '°/mA',
    's_VP': 'Pa/V',
    'k_HL': 'mm/Hz^2',
    'k_FS': 'με/nm',
    'k_FD': 'mm/nm',
    'k_FN': '°/nm',
    'k': '',
    'A': '',
    'B': '',
    'C': '',
    'D': '',
    'freq_0': 'Hz',
    'res_0': 'Ω',
    'rres_0': '',
    'vol_0': 'V',
    'elec_0': 'mA',
    'λ_0': 'nm',
    'k_tλ': 'nm/℃',
}

export const SIUnit = {
    'rad': 'rad',
    '°': 'rad',
    '℃': '℃',
    'm': 'm',
    'cm': 'm',
    'mm': 'm',
    'nm': 'm',
    'm^2': 'm^2',
    'cm^2': 'm^2',
    'mm^2': 'm^2',
    'm^3': 'm^3',
    'cm^3': 'm^3',
    'mm^3': 'm^3',
    'm^4': 'm^4',
    'cm^4': 'm^4',
    'mm^4': 'm^4',
    'm/s': 'm/s',
    'cm/s': 'm/s',
    'mm/s': 'm/s',
    'm/s^2': 'm/s^2',
    'cm/s^2': 'm/s^2',
    'mm/s^2': 'm/s^2',
    'Pa': 'Pa',
    'kPa': 'Pa',
    'MPa': 'Pa',
    'A': 'A',
    'mA': 'A',
    'V': 'V',
    'mV': 'V',
    'Ω': 'Ω',
    'N': 'N',
    'kN': 'N',
    'N·m': 'N·m',
    'kN·m': 'N·m',
    'kg/m': 'kg/m',
    'kg/m^3': 'kg/m^3',
    'm^2/m': 'm^2/m',
    'με': 'ε',
    'ε': 'ε',
    'ε/Hz^2': 'ε/Hz^2',
    'με/Hz^2': 'ε/Hz^2',
    'ε/℃': 'ε/℃',
    'με/℃': 'ε/℃',
    'nm/℃': 'm/℃',
    'm/℃': 'm/℃',
    'rad/V': 'rad/V',
    'm/V': 'm/V',
    'mm/V': 'm/V',
    'V/ε': 'V/ε',
    'mV/με': 'V/ε',
    'V/m/s^2': 'V/m/s^2',
    'mV/m/s^2': 'V/m/s^2',
    'V/m/s': 'V/m/s',
    'mV/m/s': 'V/m/s',
    'A/m/s': 'A/m/s',
    'mA/m/s': 'A/m/s',
    'm/s/mA': 'm/s/A',
    'm/s/A': 'm/s/A',
    'A/rad': 'A/rad',
    'mA/°': 'A/rad',
    '°/mA': 'rad/A',
    'rad/A': 'rad/A',
    'Pa/V': 'Pa/V',
    'm/Hz^2': 'm/Hz^2',
    'mm/Hz^2': 'm/Hz^2',
    '1/℃': '1/℃',
    'Hz': 'Hz',
    'N/mm^2': 'N/m^2',
    's': 's',
    'με/nm': 'ε/m',
    'ε/m': 'ε/m',
    'mm/nm': 'm/m',
    'm/m': 'm/m',
    '°/nm': 'rad/m',
    'rad/m': 'rad/m',
}

export const ParamToLatex = {
    'miu': '\\mu',
    'mu': '\\mu',
    'niu': '\\nu',
    'nu': '\\nu',
    'beta': '\\beta',
    'gamma': '\\gamma',
    'alpha': '\\alpha',
    'rho': '\\rho',
    'freq': 'f',
    'res': 'R',
    'rres': 'r',
    'vol': 'V',
    'elec': 'I',
    '℃': '^{\\circ}{C}',
    '1/℃': '1/^\\circ C',
}