import React, { Component, Fragment } from 'react'
import { Table } from 'antd';
import './device-statistics.less';
import ColumnGroup from 'antd/lib/table/ColumnGroup';

const { Column } = Table;


export interface statisticItem {
    marking: string,
    count: number,
    name?: string,
}

interface IProps {
    statistic?: statisticItem[]
}



export default class DeviceStatistics extends Component<IProps> {

    total = () => {
        let count = 0;
        this.props.statistic.forEach(item => {
            count += item.count;
        })
        return count;
    }

    render() {
        return (
            <Fragment>
                {/* <div>设备统计</div> */}
                <Table dataSource={this.props.statistic} rowKey="marking" pagination={false} summary={() => (
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>总计：</Table.Summary.Cell>
                        <Table.Summary.Cell colSpan={2} index={2}>{this.total()}</Table.Summary.Cell>
                    </Table.Summary.Row>
                )}>
                    <ColumnGroup title="设备统计">
                        <Column title="设备名称" dataIndex="name" />
                        <Column title="型号" dataIndex="marking" />
                        <Column title="总数" dataIndex="count" />
                    </ColumnGroup>


                </Table>
            </Fragment>

        )
    }
}
