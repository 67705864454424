export const figureUploadUrl = '/api/figures';

export const docUploadUrl = '/api/documents';

export const figureMultiUploadUrl = '/api/figures/multi';

export const figureStaticLocation = process.env.NODE_ENV === 'development' ? 'http://monix.tncet.com:9770/api' : '/api';


export const patternPicStaticLocation = process.env.NODE_ENV === 'development' ? 'http://monix.tncet.com:9770/api/static/patterns/' : '/api/static/patterns/';

export const sectionPicStaticLocation = process.env.NODE_ENV === 'development' ? 'http://monix.tncet.com:9770/api/static/sections/' : '/api/static/sections/';

export const mobjectPicStaticLocation = process.env.NODE_ENV === 'development' ? 'http://monix.tncet.com:9770/api/static/mobjects/' : '/api/static/mobjects/';

export const materialPicStaticLocation = process.env.NODE_ENV === 'development' ? 'http://monix.tncet.com:9770/api/static/materials/' : '/api/static/materials/';

export const downloadUrlPrefix = 'https://192.168.0.3'