import React, { Component } from 'react'
import './setting-pwd.less';
import { Gprops } from '@/config/props';
import { withRouter } from 'react-router-dom';
import { PageHeader, Form, Input, Button, message, Alert, Result, Row, Col } from 'antd';
import CryptoJS from 'crypto-js';
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';
import { modifyUserPwd } from '@/config/api/user';

// const { Step } = Steps;
interface IProps extends Gprops {
    userStore?: UserStore
}
interface IState {
    currentStep: number,
    loading: boolean,
    errorMessage: string,
    showResult: boolean,
}

@inject('userStore')
@observer
class SettingPasswordPane extends Component<IProps, IState> {
    state = {
        currentStep: 0,
        loading: false,
        errorMessage: '',
        showResult: false,
    }

    onSave = (values) => {
        let uid = this.props.userStore._userInfo?.id;
        if (!uid) {
            message.error('修改失败');
            return;
        }
        this.setState({
            loading: true,
        })
        let data = {
            userId: uid,
            password: CryptoJS.MD5(values.origin_pwd).toString(),
            new_password: CryptoJS.MD5(values.new_password).toString(),
        };
        modifyUserPwd(null, data)
            .then(res => {
                if (!res.data) {
                    this.setState({
                        errorMessage: '密码错误，修改失败',
                        loading: false,
                    })
                    return;
                }
                this.setState({
                    showResult: true,
                    loading: false,
                })
            })
    }

    render() {
        let { loading, showResult } = this.state;
        if (showResult) {
            return (
                <Result
                    status="success"
                    title="密码修改成功"
                    extra={[
                        <Button type="primary" onClick={() => this.props.history.push('/global/settings/user')}>
                            返回个人信息
                        </Button>,
                    ]}
                />
            )
        }
        return (
            <div className="pwd-container">
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.props.history.push('/global/settings/user')}
                    title="修改密码"
                />
                {/* <Steps current={currentStep} className="pwd-steps">
                    <Step title="原密码" description="确认账户原密码" />
                    <Step title="新密码" description="设置新密码" />
                    <Step title="完成"/>
                </Steps> */}
                <div className="content">
                    <Form className="pwd-form" autoComplete="off" labelCol={{ span: 6 }} onFinish={this.onSave}>
                        {!!this.state.errorMessage && (
                            <Alert
                                style={{ marginBottom: '10px' }}
                                message={this.state.errorMessage}
                                type="error"
                                closable
                                onClose={() => this.setState({ errorMessage: '' })} />
                        )}
                        <Form.Item label="原密码" name="origin_pwd" rules={[{ required: true }]}>
                            <Input.Password placeholder="原密码" />
                        </Form.Item>
                        <Form.Item label="新密码" name="new_password" rules={[{ required: true }]}>
                            <Input.Password placeholder="新密码" />
                        </Form.Item>
                        <Form.Item
                            label="确认密码"
                            name="confirm_password"
                            rules={[
                                {
                                    required: true,
                                    message: '确认密码不能为空',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('new_password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('两次密码不一致');
                                    },
                                }),
                            ]}
                            dependencies={['new_password']}
                            hasFeedback>
                            <Input.Password placeholder="确认新密码" />
                        </Form.Item>
                        <Row>
                            <Col offset={6}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="pwd-btn" loading={loading}>
                                        提交
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </div>

            </div>
        )
    }
}

export default withRouter(SettingPasswordPane);