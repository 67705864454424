import React, { Component } from 'react'
import { constage } from '@/interface/constage'
import dayjs from 'dayjs'
import { Row, Col, Modal, Button, message } from 'antd'
import { figure } from '@/interface/document'
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { queryMobjects } from '@/config/api/mobject'
import { ProjectContext } from '@/config/context'
import { mobject } from '@/interface/mobject'
import { deleteConstage } from '@/config/api/constage'
import { Gprops } from '@/config/props'
import { withRouter } from 'react-router-dom'
import { figureStaticLocation } from '@/config/api/document'
import { transferFigPathToThumb } from '@/utils/utils'
interface IProps extends Gprops {
    constageDetail: constage
    manageState: boolean
    afterDelete: () => void
}

interface IState {
    modelVisible: boolean
    currentUrl: string
    mobjects: mobject[]
}

class ConstageListItem extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        modelVisible: false,
        currentUrl: '',
        mobjects: [],
    }

    componentDidMount() {
        let constageId = this.props.constageDetail?.id;
        queryMobjects({
            project_id: this.context,
            stage_id: constageId,
        }).then(res => {
            this.setState({
                mobjects: res.data || []
            })
        })
    }
    showModal = (e, url) => {
        e.stopPropagation();
        let currentUrl = `${figureStaticLocation}${url}`
        this.setState({
            modelVisible: true,
            currentUrl: currentUrl,
        });
    };

    handleCancel = e => {
        this.setState({
            modelVisible: false,
        });
    };

    onDelete = () => {
        if (this.state.mobjects.length > 0) {
            message.warning('施工步存在对象关联绑定，请先解除关联');
            return;
        }
        if (!this.props.constageDetail) {
            message.error('删除失败');
            return;
        }
        deleteConstage(this.context, this.props.constageDetail.id)
            .then(res => {
                this.props.afterDelete();
            }).catch(err => {
                message.error('删除失败');
                console.log(err);
            })
    }

    onItemClick = () => {
        this.props.history.push(`${this.props.match.url}/${this.props.constageDetail.id}`)
    }

    render() {
        return (
            <div className="item-group">
                <div className="constage-list-item fill-remaining-space" onClick={this.onItemClick}>
                    <Row gutter={10}>
                        <Col xs={24} xxl={12}>
                            <div className="item-main">
                                <div className="item-head">
                                    <div className="name">
                                        <span>{this.props.constageDetail?.code}</span>
                                    </div>
                                    <div>
                                        <span>{this.props.constageDetail?.name}</span>
                                    </div>
                                    <div className="time">
                                        <span>
                                            {dayjs(this.props.constageDetail?.start_time).format('YYYY-MM-DD HH:mm')}
                            ~
                            {dayjs(this.props.constageDetail?.end_time).format('YYYY-MM-DD HH:mm')}</span>
                                    </div>
                                </div>
                                <div className="item-body">
                                    <div className="title">内容描述</div>
                                    <span>{this.props.constageDetail?.content}</span>

                                </div>
                            </div>

                        </Col>
                        <Col xs={24} xxl={12}>
                            <div className="figures" style={{ width: `${this.props.constageDetail?.figures.length * 170 + (this.props.constageDetail?.figures.length - 1) * 10}px` }}>
                                {this.props.constageDetail?.figures.map((item: figure, idx: number) => (
                                    <div className="figure-shell" key={idx}>
                                        <div className="figure" onClick={(e) => this.showModal(e, item.url)}>
                                            <img width="100%" height="100%" alt="施工步图片" src={`${figureStaticLocation}${transferFigPathToThumb(item.url)}`} />
                                        </div>
                                        <div className="figure-actions">
                                            <EyeOutlined className="eye" onClick={(e) => this.showModal(e, item.url)} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </div>
                <div>
                    {this.props.manageState && (
                        <Button
                            className="delete-btn"
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={this.onDelete}></Button>
                    )}
                </div>


                <Modal
                    title="图片详览"
                    width={800}
                    footer={null}
                    visible={this.state.modelVisible}
                    //   onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <img width="100%" alt="照片" src={this.state.currentUrl} />
                </Modal>
            </div>
        )
    }
}

export default withRouter(ConstageListItem);