import React, { Component } from 'react'
import { Carousel, Button, Descriptions } from 'antd'
import { ProjectContext } from '../../config/context';
import { figure } from '../../interface/document';
import axios from '../../config/axios';
import { project } from '../../interface/project';
import {RightOutlined} from '@ant-design/icons';
import './project-board.less';
import { monitorType, monitorRank } from '../../utils/type';
import dayjs from 'dayjs';
import { figureStaticLocation } from '@/config/api/document';
import { withRouter } from 'react-router-dom';
import { Gprops } from '@/config/props';
import { transferFigPathToThumb } from '@/utils/utils';


interface IState {
    figures: figure[],
    project: project,

}
class ProjectBoard extends Component<Gprops, IState> {

    static contextType = ProjectContext;

    constructor(props) {
        super(props);
        this.state = {
            figures: [],
            project: null,
        }
    }

    componentDidMount() {
        const project_id = this.context;
        axios.get(`/projects/${project_id}/figures`)
            .then(res => {
                this.setState({
                    figures: res.data
                })
            })
        axios.get(`/projects/${project_id}`)
            .then(res => {
                this.setState({
                    project: res.data
                })
            })

    }

    render() {
        return (
            <div className="dashboard-card project">
                <div className="project-card-header">
                    <div className="title">项目信息</div>
                    <Button type="link" onClick={() => this.props.history.push(`/projects/${this.context}/information`)}>详情<RightOutlined /></Button>
                </div>
                <div className="figure-pane">
                    <Carousel autoplay>
                        {this.state.figures.length > 0 ? this.state.figures.map((figure: figure, idx: number) => (
                            <div key={idx}>
                                <div className="figure">
                                    {!!figure.url ? 
                                        <img width="100%" height="100%" alt="项目示意图" src={`${figureStaticLocation}${transferFigPathToThumb(figure.url)}`} />
                                        : null
                                    }
                                </div>
                            </div>
                        )) : (
                                <div >
                                    <div className="figure">
                                    </div>
                                </div>
                            )}
                    </Carousel>
                </div>
                <div className="content-pane">
                    {/* <span className="ellipsis-span term-title">{this.state.project?.name}</span> */}
                    <Descriptions column={2}>
                        <Descriptions.Item label="监测类别">
                            {this.state.project?.monitor_type ? monitorType[this.state.project?.monitor_type] : ''}监测
                        </Descriptions.Item>
                        <Descriptions.Item label="监测等级">
                            {this.state.project?.monitor_rank ? monitorRank[this.state.project?.monitor_rank] : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="开始时间">
                            {dayjs(this.state.project?.start_time).format('YYYY-MM-DD')}
                        </Descriptions.Item>
                        <Descriptions.Item label="周期">
                            {this.state.project?.period}天
                        </Descriptions.Item>
                    </Descriptions>
                    {/* <div className="term-group">
                        <div className="term">
                            <div className="label">监测类别</div>
                            <span className="detail">{this.state.project?.monitor_type ? monitorType[this.state.project?.monitor_type] : ''}</span>
                        </div>
                        <div className="term">
                            <div className="label">监测等级</div>
                            <span className="detail">{this.state.project?.monitor_rank ? monitorRank[this.state.project?.monitor_rank] : ''}</span>
                        </div>
                    </div>
                    <div className="term-group">
                        <div className="term">
                            <div className="label">开始时间</div>
                            <span className="detail">{dayjs(this.state.project?.start_time).format('YYYY-MM-DD')}</span>
                        </div>
                        <div className="term">
                            <div className="label">周期</div>
                            <span className="detail">{this.state.project?.period}天</span>
                        </div>
                    </div> */}

                </div>
            </div>
        )
    }
}

export default withRouter(ProjectBoard);