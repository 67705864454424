import axios from "../axios"
import { AxiosResponse } from "axios";

export function queryProjects(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/projects`, {
        params: params
    })
}

export function createProject(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/projects`, data, {
        params: params
    })
}

export function queryProjectFigures(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/figures`, {
        params: params
    })
}

export function queryProjectDetail(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}`, {
        params: params
    })
}

export function updateProjectDetail(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/projects/${projectId}`, data, {
        params: params
    })
}

export function createProjectFigures(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/projects/${projectId}/figures`, data, {
        params: params
    })
}

export function deleteProjectFigures(projectId: number, figureId: number): Promise<AxiosResponse<any>> {
    return axios.delete(`/projects/${projectId}/figures/${figureId}`)
}

export function projectStart(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/projects/${projectId}/start`, null, {
        params: params
    })
}

export function projectStop(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/projects/${projectId}/stop`, null, {
        params: params
    })
}