import React, { Component } from 'react'
import InfoBoardShell from './info-board-shell'
import { projectPlanb } from '@/interface/plan'

interface IProps {
    projectPlanbList: projectPlanb[]
}
export default class InfoPlanbTableBoard extends Component<IProps> {
    render() {
        return (
            <InfoBoardShell title="应急预案">
                {this.props.projectPlanbList.map((item, idx) => (
                    <div key={idx}>
                        <span>{item.sid}、</span>
                        <span>{item.content}</span>
                    </div>
                ))}
            </InfoBoardShell>
        )
    }
}
