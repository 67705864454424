import React, { Component, Fragment } from 'react'
import { withRoutePane } from '@/components/hoc/routePane'
import './node-page.less';
import { node } from '@/interface/model';
import { ProjectContext } from '@/config/context';
import { queryNodes, updateNode, deleteNode } from '@/config/api/model';
import { Table, Space, Button, Popconfirm, message, Modal } from 'antd';
import axios from '@/config/axios';
import { NodeAddPanel } from '@/components';
import { EditableRow, EditableCell } from '@/components/model/editable-cell';



interface IProps {

}
interface IState {
    nodeList: node[],
    page: number
    size: number
    total: number
    loading: boolean
    multiDeleteLoading: boolean
    onMultiDeleteState: boolean
    nodeIdToDelete: number[]
    onModalVisible: boolean
}
class NodePage extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        nodeList: [],
        page: 1,
        size: 30,
        total: 0,
        loading: false,
        multiDeleteLoading: false,
        onMultiDeleteState: false,
        nodeIdToDelete: [],
        onModalVisible: false,
    }

    componentDidMount() {
        this.findNodes();
    }

    findNodes = () => {
        this.setState({
            loading: true,
        })
        queryNodes(this.context, {
            // page: this.state.page,
            // size: this.state.size,
        }).then(res => {
            this.setState({
                nodeList: res.data?.data,
                total: res.data?.count,
            })
        }).finally(() => {
            this.setState({
                loading: false,
                page: 1
            })
        })
    }

    onPageChange = (page, size) => {
        this.setState({
            page: page,
            size: size,
        })
        // this.findNodes()
    }

    handleSave = (row: node) => {
        let id = row.id;
        let newData = [...this.state.nodeList];
        let index = newData.findIndex(item => item.id === id);
        let item: node = newData[index];
        let { x, y, z } = row;
        if (item && item.x === +row.x && item.y === +row.y && item.z === +row.z) {
            return;
        }

        newData.splice(index, 1, { ...item, ...row })
        this.setState({
            nodeList: newData
        })
        let data = {
            x: item.x === +x ? +x : null,
            y: item.y === +y ? +y : null,
            z: item.z === +z ? +z : null,
        }
        updateNode(this.context, row.id, data)
            .then(res => {

            }).catch(err => {
                message.error('更新失败');
                // console.log(err)
                newData.splice(index, 1, { ...row, ...item })
                this.setState({
                    nodeList: [...newData]
                })
            })
    }

    handleDelete = (values) => {
        deleteNode(this.context, values.id)
            .then(res => {
                this.setState({
                    nodeList: this.state.nodeList.filter(item => item.id !== values.id)
                })
            }).catch(err => {
                message.error('删除失败')
            })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        // console.log(selectedRowKeys)
        this.setState({
            nodeIdToDelete: [...selectedRowKeys]
        })
    }

    onMultiDeleteCancel = () => {
        this.setState({
            nodeIdToDelete: [],
            onMultiDeleteState: false,
        })
    }

    onMultiDeleteConfirm = () => {
        this.setState({
            multiDeleteLoading: true,
        })
        let deletePromiseList = this.state.nodeIdToDelete.map(id => deleteNode(this.context, id))
        axios.all([...deletePromiseList])
        .then(res => {
            this.setState({
                nodeIdToDelete: [],
                onMultiDeleteState: false,
            })
            this.findNodes();
        }).finally( () => {
            this.setState({
                multiDeleteLoading: false,
            })
        })
    }
    afterPosted = (value) => {
        this.setState({
            onModalVisible: false,
            nodeList : [value, ...this.state.nodeList]
        })  
    }

    render() {

        let columns = [
            {
                title: '节点号',
                dataIndex: 'sid',
            },
            {
                title: 'X(mm)',
                dataIndex: 'x',
                editable: true,
                // align: 'right' as 'right',
                width: '25%'
            },
            {
                title: 'Y(mm)',
                dataIndex: 'y',
                editable: true,
                // align: 'right' as 'right',
                width: '25%'
            },
            {
                title: 'Z(mm)',
                dataIndex: 'z',
                editable: true,
                // align: 'right' as 'right',
                width: '25%'
            },
            {
                title: '操作',
                key: 'operations',
                width: 200,
                align: 'center' as 'center',
                render: (record, idx) => (
                    <div style={{ textAlign: "center" }}>
                        <Space>
                            <Popconfirm title="确认删除" onConfirm={() => this.handleDelete(record)}>
                                <Button type="link" size="small" disabled={this.state.onMultiDeleteState}>删除</Button>
                            </Popconfirm>
                        </Space>
                    </div>

                )
            },
        ];
        let columnRender = columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        })
        return (
            <div className="node-page-container">
                <div className="header">
                    {!this.state.onMultiDeleteState && (
                        <Fragment>
                            <Space>
                                <Button shape="round">导入</Button>
                                <Button shape="round">导出</Button>

                            </Space>
                            <Space style={{ marginLeft: '24px' }} >
                                <Button type="primary" onClick={() => this.setState({onModalVisible: true})}>添加</Button>
                                <Button danger onClick={() => { this.setState({ onMultiDeleteState: true }) }}>批量删除</Button>
                            </Space>
                        </Fragment>
                    )}
                    {this.state.onMultiDeleteState && (
                        <Space size="large" style={{ marginLeft: '24px' }}>
                            <Button type="primary" onClick={this.onMultiDeleteConfirm} loading={this.state.multiDeleteLoading}>确定</Button>
                            <Button onClick={this.onMultiDeleteCancel}>取消</Button>
                        </Space>
                    )}
                </div>
                <Table
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        }
                    }}
                    columns={columnRender}
                    rowSelection={this.state.onMultiDeleteState && {
                        type: 'checkbox',
                        onChange: this.onSelectChange,
                        selectedRowKeys: this.state.nodeIdToDelete
                    }}
                    dataSource={this.state.nodeList}
                    rowKey="id"
                    loading={this.state.loading}
                    pagination={{
                        pageSize: this.state.size,
                        // total: this.state.total,
                        current: this.state.page,
                        onChange: this.onPageChange
                    }}
                    rowClassName={(record, index) => (
                        index % 2 !== 0 ? 'ribbon-row editable-row' : 'editable-row'
                    )}>
                </Table>

                <Modal
                    destroyOnClose
                    footer={null}
                    visible={this.state.onModalVisible}
                    title="添加节点"
                    onCancel={() => this.setState({onModalVisible: false})}
                    >
                    <NodeAddPanel  afterSave={this.afterPosted} onCancelClick={()=> {this.setState({onModalVisible: false})}}/>
                </Modal>
            </div>
        )
    }
}

export default withRoutePane(NodePage, '模型节点列表');