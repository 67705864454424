import React, { Component, Fragment } from 'react'
import './drawing-mobject.less';
import { drawing } from '@/interface/constage';
import { mobject } from '@/interface/mobject';
import { quantity, globalQuantity } from '@/interface/quantity';
import { ProjectContext } from '@/config/context';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import { queryMobjectsByDrawingId, LinkMobjectsByDrawingId } from '@/config/api/constage';
import axios from '@/config/axios';
import { Button, Space, Empty, Checkbox, Spin, message } from 'antd';
import classnames from 'classnames';
import { queryQuantities } from '@/config/api/quantity';
import { Link, withRouter } from 'react-router-dom';
import { MobjectBaseType } from '@/utils/type';
import { queryMobjects } from '@/config/api/mobject';
import { MobjectSubtypeName } from '../mobject/mobject-info';
import { Gprops } from '@/config/props';

interface IProps extends Gprops {
    currentDrawing: drawing,
    onLinkState?: boolean,
    beginLink?: () => void,
    endLink?: () => void,
}
interface IState {
    mobjects: mobject[]
    mobjectsAll: mobject[]
    quantities: quantity[]
    selectedMobject: mobject
    loading: boolean
    checkedList: number[]
    indeterminate: boolean // 控制全选按钮的方块样式
    checkAll: boolean // 控制全选按钮的选择样式
    clientHeight: number
}

class DrawingMobjectPanel extends Component<IProps, IState> {
    static contextType = ProjectContext;
    projectQuantities: globalQuantity[] = [];
    currentDrawing: drawing = null;
    ref = React.createRef<HTMLDivElement>();
    state = {
        mobjects: [],
        mobjectsAll: [],
        quantities: [],
        selectedMobject: null,
        loading: false,
        checkedList: [],
        indeterminate: false,
        checkAll: false,
        clientHeight: 0,
    }

    componentDidMount() {
        this.currentDrawing = this.props.currentDrawing;
        this.findMobjects();
        if (this.ref.current?.clientHeight) {
            this.setState({
                clientHeight: this.ref.current.clientHeight
            })
        }
    }
    componentDidUpdate() {
        if (!this.state.clientHeight && this.ref.current?.clientHeight) {
            this.setState({
                clientHeight: this.ref.current.clientHeight
            })
        }
        if (this.currentDrawing?.id !== this.props.currentDrawing?.id) {
            this.currentDrawing = this.props.currentDrawing;
            this.findMobjects();
        }
    }

    findMobjects = () => {
        let drawingId = this.props.currentDrawing?.id;
        if (!drawingId) return;
        let projectId = this.context;
        let queryProjectGlobalQuantitiesPromise = queryProjectGlobalQuantities(projectId);
        let queryMobjectsPromise = queryMobjectsByDrawingId(projectId, drawingId);
        axios.all([queryProjectGlobalQuantitiesPromise, queryMobjectsPromise]).then(res => {
            this.projectQuantities = res[0].data || [];
            this.setState({
                mobjects: res[1].data,
                selectedMobject: null,
                quantities: []
            })
        })
    }

    onMobjectSelect = (item) => {
        this.findQuantities(item.id);
        this.setState({
            selectedMobject: item
        });

    }

    findQuantities = (mobjectId: number) => {
        queryQuantities(this.context, {
            mobject_id: mobjectId,
            activated: true,
        }).then(res => {
            this.setState({
                quantities: res.data || []
            })
        })
    }

    findChineseName = (type: number) => {
        let pQuantity = this.projectQuantities.find(item => item.type === type);
        return pQuantity?.chinese_name || '';
    }

    onLinkConfirm = () => {
        // 关联drawing和对象
        let drawingId = this.currentDrawing?.id;
        if (!drawingId) {
            message.error('当前没有施工图');
            return;
        }
        this.setState({
            loading: true,
        })
        LinkMobjectsByDrawingId(this.context, drawingId, this.state.checkedList)
            .then(res => {
                this.setState({
                    loading: false,
                    selectedMobject: null,
                    quantities: [],
                })
                this.props.endLink();
                this.findMobjects();
            })

    }

    onLinkClick = () => {
        this.setState({
            loading: true,
        })
        this.props.beginLink();
        queryMobjects({
            project_id: this.context,
        }).then(res => {
            let selected = this.state.mobjects.map(item => item.id) || [];
            let mobjectsAll = res.data || [];
            this.setState({
                loading: false,
                mobjectsAll: mobjectsAll,
                checkedList: selected,
                indeterminate: !!selected.length && selected.length < mobjectsAll.length,
                checkAll: !!selected.length && selected.length === mobjectsAll.length
            })
        })
    }

    onCheckChange = (values) => {
        this.setState({
            checkedList: values,
            indeterminate: !!values.length && values.length < this.state.mobjectsAll.length,
            checkAll: !!values.length && values.length === this.state.mobjectsAll.length
        })
    }

    onCheckAllChange = e => {
        let checkedList = this.state.mobjectsAll.map(item => item.id) || [];
        this.setState({
            checkedList: e.target.checked ? checkedList : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };

    // gotoMobject = (record: quantity) => {
    //     let selectedMobject = this.state.selectedMobject;
    //     if (!selectedMobject) return;
    //     let groupId = record.group_id;
    //     let url = `/projects/${this.context}/mobjects/${MobjectBaseType[selectedMobject.type].name}/${record.mobject_id}?tab=2&group=${groupId}`;
    //     this.props.history.push(url);
    // }

    render() {
        let { selectedMobject } = this.state;
        let onLinkState = this.props.onLinkState;
        if (!this.props.currentDrawing) {
            return null;
        }
        return (
            <div className="drawing-mobject-container" ref={this.ref}>
                <div className="header">
                    {!onLinkState && (
                        <Fragment>
                            <div className="title">对象列表</div>
                            <Button type="link" onClick={this.onLinkClick}>关联</Button>
                        </Fragment>

                    )}
                    {onLinkState && (
                        <Fragment>
                            <Checkbox
                                indeterminate={this.state.indeterminate}
                                onChange={this.onCheckAllChange}
                                checked={this.state.checkAll}>全选</Checkbox>
                            <Space>
                                <Button type="link" onClick={this.onLinkConfirm} loading={this.state.loading}>完成关联</Button>
                            </Space>
                        </Fragment>
                    )}
                </div>
                <div style={{ display: 'flex' }}>
                    {!onLinkState && (
                        <Fragment>
                            <div className="mobject-group-container" style={{ height: this.state.clientHeight - 53 }}>
                                <div className="mobject-list">
                                    {this.state.mobjects?.map((item, idx) => (
                                        <div key={idx} className={classnames({
                                            'mobject-button': true,
                                            'mobject-button-active': this.state.selectedMobject?.id === item.id
                                        })} onClick={() => this.onMobjectSelect(item)}>{item.name}</div>
                                    ))}
                                </div>
                            </div>
                            <div className="mobject-quantity">
                                <div className="quantity-list">
                                    {this.state.quantities.map((item, idx) => (
                                        <div className="quantity-item" key={idx}>
                                            {/* <span onClick={() => this.gotoMobject(item)}>
                                                {`${this.findChineseName(item.type)}${item.name}`}
                                            </span> */}
                                            <Link to={`/projects/${this.context}/mobjects/${MobjectBaseType[selectedMobject.type].name}/${item.mobject_id}?tab=2&group=${item.group_id}`}>
                                                {`${this.findChineseName(item.type)}${item.name}`}
                                            </Link>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </Fragment>
                    )}
                    {onLinkState && (
                        <div className="list-pane" style={{ height: this.state.clientHeight - 53 }}>
                            <Spin spinning={this.state.loading}>
                                {this.state.mobjectsAll.length <= 0 ?
                                    <Empty description='暂无监测对象' />
                                    :
                                    <Checkbox.Group style={{ width: '100%' }} value={this.state.checkedList} onChange={this.onCheckChange}>
                                        {this.state.mobjectsAll.map((item: mobject, idx) => {
                                            return (
                                                <div className="mobject-item" key={idx}>
                                                    <Checkbox value={item.id}>{item.name}</Checkbox>
                                                    <div className="fill-remaining-space mobject-item-right">
                                                        {`${MobjectBaseType[item.type]?.alias}-${MobjectSubtypeName[item.type][item.sub_type]}`}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Checkbox.Group>
                                }
                            </Spin>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default withRouter(DrawingMobjectPanel);