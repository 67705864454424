import React, { Component } from 'react'
import InfoBoardShell from './info-board-shell'
import { projectPeople } from '@/interface/plan'
import { Table } from 'antd'
const { Column } = Table;
interface IProps {
    projectPeopleList: projectPeople[]
}

export default class InfoPeopleTableBoard extends Component<IProps> {
    render() {
        return (
            <InfoBoardShell title="人员计划">
                <Table
                    className="info-table-display fill-remaining-space"
                    dataSource={this.props.projectPeopleList}
                    rowKey="id"
                    pagination={false}
                    rowClassName={(record, index) => (
                        index % 2 !== 0 ? 'ribbon-row' : ''
                    )}>
                    <Column title="姓名" dataIndex="name"/>
                    <Column title="职务" dataIndex="duty"/>
                    <Column title="职称" dataIndex="title"/>
                    <Column title="工作方式" dataIndex="work_type"/>
                </Table>
            </InfoBoardShell>
        )
    }
}
