import React, { useRef, useState, useContext, useEffect } from "react";
import { node } from "@/interface/model";
import { Input, Form, Select } from "antd";
const { Option } = Select;

const EditableContext = React.createContext<any>(null);

interface EditableRowProps {
    index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false} autoComplete="off">
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: string;
    record: node;
    type: 'Input' | 'Select';
    options: any[];
    validateDisable: boolean;
    showSelectSearch: boolean;
    directEdit: boolean;
    handleSave: (record: node) => void;
    onSelectChange: (value: any) => void;
    onDoubleClick: () => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    type,
    options,
    validateDisable,
    showSelectSearch,
    directEdit,
    handleSave,
    onSelectChange,
    onDoubleClick,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    // const [displayOptions, setDisplayOptions] = useState(options);
    const inputRef = useRef<Input>();
    const selectRef = useRef<Select>();
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing && type === 'Select') {
            selectRef.current.focus();
        } else if (editing) {
            inputRef.current.focus();
        }
    }, [editing, type]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async e => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            // console.log('Save failed:', errInfo);
        }
    };

    const onKeyPress = (e) => {
        if (e.keyCode === 27) {
            // esc
            toggleEdit();
        }
    }

    const doubleClick = () => {
        if (directEdit) return;
        toggleEdit();
        if (onDoubleClick) {
            onDoubleClick();
        }
    }

    const singleClick = () => {
        if(!directEdit) {
            return;
        }
        toggleEdit();
    }

    // const onSearch = (value) => {
    //     setDisplayOptions(options.filter(item => item.label.indexOf(value) >= 0));
    // }

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: !validateDisable,
                        message: `${title} 不能为空.`,
                    },
                ]}
            >
                {type === 'Select' ? 
                <Select 
                ref={selectRef} 
                onBlur={save} 
                onKeyUp={onKeyPress} 
                onChange={onSelectChange}
                showSearch={showSelectSearch || false}
                optionFilterProp="children"
                filterOption={(input, option) =>
                    {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                }>
                    {options.map((item, idx) => (
                        <Option value={item.value} key={idx}>{item.label}</Option>
                    ))}
                </Select>
                :
                <Input ref={inputRef} onPressEnter={save} onBlur={save} onKeyUp={onKeyPress}/>
            }
            </Form.Item>
        ) : (
                <div className="editable-cell-value-wrap" onClick={singleClick} onDoubleClick={doubleClick}>
                    {children}
                </div>
            );
    }

    return <td {...restProps}>{childNode}</td>;
};