import { observable, action } from "mobx";
import { globalSensor } from "@/interface/sensor";
import { globalDbox } from "@/interface/dbox";


class GlobalDeviceStore {
    @observable _globalSensors: globalSensor[] = [];
    @action globalSensors = (value: globalSensor[]) => {
        this._globalSensors = [...value];
    }

    @observable _globalDboxs: globalDbox[] = [];
    @action globalDboxs = (value: globalDbox[]) => {
        this._globalDboxs = [...value];
    }
}

export default GlobalDeviceStore;