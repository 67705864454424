import React, { Component } from 'react'
import './device-main.less';
import { sensor } from '@/interface/sensor';
import { querySensors } from '@/config/api/sensor';
import { queryDboxs } from '@/config/api/dbox';
import { dbox, globalDbox } from '@/interface/dbox';
import { Table, Button, Space, Tag} from 'antd';
import Column from 'antd/lib/table/Column';
import { ProjectContext } from '@/config/context';
import ButtonGroup from 'antd/lib/button/button-group';
import axios from '@/config/axios';
import { queryGlobalDboxs } from '@/config/api/global-device';
import { deviceManageStatus, sensorWorkStatus } from '@/utils/type';


interface IProps {
}
interface IState {
    dboxs: dbox[]
    sensors: sensor[]
    currentDbox: dbox
    visible: boolean
    globalDboxs:  globalDbox[]
}


export default class DeviceMainBoard extends Component<IProps, IState> {
    static contextType = ProjectContext;
    constructor(props) {
        super(props);
        this.state = {
            dboxs: [],
            sensors: [],
            currentDbox: null,
            visible: false,
            globalDboxs: [],
        }

    }


    componentDidMount() {
        this.findDboxs();
    }

    findDboxs = () => {
        let dboxsPromise = queryDboxs({project_id: this.context});
        let globalDboxsPromise = queryGlobalDboxs();
        // let dboxstype = [];
        axios.all([dboxsPromise,globalDboxsPromise])
        .then((res) => {
            let dboxs: dbox[] = res[0].data;
            let globalDboxs: globalDbox[] = res[1].data;
             dboxs.forEach(value=> {
                let data = globalDboxs.filter(item => item.type === value.type)[0];
                value.type_name = data?.name;
                // dboxstype.push(data);
            })

            this.setState({
                dboxs: dboxs,
                globalDboxs: globalDboxs,
                currentDbox: dboxs.length > 0 ? dboxs[0] : null,
            })
            });
            
            if (this.state.currentDbox)
                    this.findSensors(this.state.currentDbox);
            }

    findSensors = (value: dbox) => {
        querySensors({
            project_id: this.context,
            dbox_id: value.id,
        })
            .then(res => {
                let sensors: sensor[] = res.data;
                this.setState({
                    sensors: sensors,

                })
                console.log(sensors)
            })
    }

    dboxChange = (currentDbox: dbox) => {
        this.setState({
            currentDbox: currentDbox,
        })
    }

    render() {
        return (
            <div className="dashboard-device-card">
                <div className="extra-button-group-main">
                    采集箱概览
                <ButtonGroup className="extra-button-group">
                        <Space size={17}>
                            <Button type="primary">项目概览</Button>
                            <Button type="primary">评估概览</Button>
                        </Space>
                    </ButtonGroup>  
                </div>
                <div className="extra-button-groupdbox">
                    <Space size={8} >
                        {this.state.dboxs.map((item: dbox, index) => (
                            <Button type="primary" 
                            className={item.id === this.state.currentDbox.id ? 'active' : 'unactive'}
                                onClick={() => this.dboxChange(item)}
                                key={index}
                                >
                                    {`${item.type_name}${item.id}`}
                            </Button>
                        ))} 
                    </Space>


                </div>
                <div className="device-content">
                    <Table dataSource={this.state.sensors} rowKey="id">
                        <Column title="传感器编号" key="name" render={(record: sensor) => (record.id)} />
                        <Column title="类型" key="type" align="right" render={(record: sensor) => (record.name)} />
                        <Column title="现场状态" key="device_status" render={(record: sensor) => (deviceManageStatus[record.type])} />
                        <Column title="设备状态" dataIndex="work_status" render={(record) => (
                            <Tag color={sensorWorkStatus[record]['color']}>{sensorWorkStatus[record]['value']}</Tag>
                        )} />
                        <Column title="通道" key="quantity_name" render={(record: sensor) => (record.channel)} />
                        <Column title="直接物理量" key="quantity_name" align="right" />
                        <Column title="检测对象" key="quantity_name" render={(record: sensor) => (record.mobject_id)} />
                        <Column title="当前值" key="quantity_name" align="right" />
                        <Column title="单位" dataIndex="unit_type" align="right" />
                        <Column title="对应指标" key="current_grade" align="center" />
                    </Table>
                </div>
            </div>
        )
    }
}
