import React, { Component } from 'react'
import { Form, Space, Button } from 'antd'
import { createNode, queryNodes } from '@/config/api/model';
import { ProjectContext } from '@/config/context';
import { node } from '@/interface/model';
import { FormInstance } from 'antd/lib/form';
import { NumericInput } from '..';

interface IProps {
    afterSave?: (value) => void
    onCancelClick?: () => void
}
interface IState {
    nodeList: node[]
}

export default class NodeAddPanel extends Component<IProps, IState> {
    static contextType = ProjectContext;
    formRef = React.createRef<FormInstance>();
    state = {
        nodeList: [],
    }

    findLastNodes = () => {
        queryNodes(this.context)
            .then(res => {
                this.setState({
                    nodeList: res.data?.data,
                })
                var max = this.state.nodeList[0]?.sid || 0;
                for (var i = 0; i < this.state.nodeList.length; i++) {
                    var cur = this.state.nodeList[i].sid;
                    max = max < cur ? cur : max;
                }
                this.formRef.current?.setFieldsValue({
                    sid: max + 1,
                })
            })
    }

    onSave = (value) => {
        let projectId = this.context;
        let data = value;
        // let sid = this.state.total + 1
        createNode(projectId, data)
            .then(res => {
                this.props.afterSave(Object.assign(value, {
                    id: res.data,
                    project_id: projectId
                }));
            })

    }

    // onChange = (value) => {
    //     this.state.nodeList = value +1;
    //     this.setState({
    //         nodeList: [...this.state.nodeList]
    //     })
    // }
    componentDidMount() {
        
        this.findLastNodes();
    }

    render() {
        return (
            <div>
                <Form onFinish={this.onSave} autoComplete="off" ref={this.formRef}>
                    <Form.Item
                        name="sid"
                        label="节点号"
                        rules={[{ required: true, message: '节点号不能为空' }]} >   
                        <NumericInput  />   
                    </Form.Item>
                    <Form.Item
                        name="x"
                        label="X(mm)"
                        rules={[{ required: true, message: 'x不能为空' }]}>
                        <NumericInput />
                    </Form.Item>
                    <Form.Item
                        name="y"
                        label="Y(mm)"
                        rules={[{ required: true, message: 'y不能为空' }]}>
                        <NumericInput />
                    </Form.Item>
                    <Form.Item
                        name="z"
                        label="Z(mm)"
                        rules={[{ required: true, message: 'z不能为空' }]}>
                        <NumericInput />
                    </Form.Item>
                    <div style={{ textAlign: 'right', marginTop: '24px' }}>
                        <Space size="large">
                            <Button  onClick={this.props.onCancelClick}>取消</Button>
                            <Button type="primary" htmlType="submit">保存</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        )
    }
}
