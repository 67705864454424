import React, { Component } from 'react'
import { Gprops } from '@/config/props'
import { IFRouteBase } from '@/config/route-list'
import UserStore from '@/store/user-store'
import { inject, observer } from 'mobx-react'
import { withRouter, Route, Redirect, Switch } from 'react-router-dom'
import AllRoutes from '../../routes';

interface GRouterProps extends Gprops {
    routeList: IFRouteBase[],
    userStore?: UserStore
}
@inject('userStore')
@observer
class GRouter extends Component<GRouterProps> {

    createRoute = (item: IFRouteBase) => {
        if (item.component) {
            // const MyComponent = item.component && AllRoutes[item.component];
            const MyComponent = AllRoutes[item.component];
            return (
                <Route exact={item.exact} key={item.url} path={`${item.url}`} render={(props) => (
                    <MyComponent {...props}/>
                )}/>
            )
        }
        return null;
    }

    render() {
        const { _isLogin } = this.props.userStore;
        if (!_isLogin) {
            return <Redirect to='/passport/login'/>
        }
        return (
            <Switch>
                {this.props.routeList.map((item: IFRouteBase, index: number) => this.createRoute(item))}
            </Switch>
        )
    }
}

export default withRouter(GRouter);