import React, { Component } from 'react'
import './global-device.less'
import { globalDbox } from '@/interface/dbox'
import { globalSensor } from '@/interface/sensor'
import { queryGlobalDboxs, queryGlobalSensors } from '@/config/api/global-device'
import axios from '@/config/axios'
import { Table, Button, Modal } from 'antd'
import GlobalDboxSensorPanel from './global-dbox-sensor'
const { Column } = Table;
interface IProps {

}

interface IState {
    gDboxs: globalDbox[]
    gSensors: globalSensor[],
    visible: boolean,
    currentMarking: string,
}
export default class GlobalDevicePage extends Component<IProps, IState> {

    state = {
        gDboxs: [],
        gSensors: [],
        visible: false,
        currentMarking: null,
    }
    componentDidMount() {
        this.generateData();
    }

    generateData = () => {

        let queryGlobalDboxPromise = queryGlobalDboxs();
        let queryGlobalSensorPromise = queryGlobalSensors();
        axios.all([queryGlobalDboxPromise, queryGlobalSensorPromise]).then(res => {
            this.setState({
                gDboxs: res[0].data,
                gSensors: res[1].data,
            })
        })
    }

    showModal = (row) => {
        let marking = row.marking;
        this.setState({
            currentMarking: marking,
            visible: true,
        })
    }


    render() {
        return (
            <div className="device-container">
                <div className="dbox-list-container">
                    <div className="title">采集箱类型</div>
                    <div>
                        <Table
                            dataSource={this.state.gDboxs}
                            rowKey="type"
                            bordered
                            pagination={false}>
                            <Column title="类型" dataIndex="name" width="15%" />
                            <Column title="型号" dataIndex="marking" width="15%" />
                            <Column title="厂家" dataIndex="manufacturer" />
                            <Column title="通道数" dataIndex="channel_count" width="15%" />
                            <Column title="通信类型" dataIndex="wireless_type" width="15%" />
                            <Column title="" key="operations" width="15%" render={record => (
                                <Button type="link" onClick={() => this.showModal(record)}>可关联传感器</Button>
                            )} />
                        </Table>
                    </div>
                </div>
                <div className="sensor-list-container">
                    <div className="title">传感器类型</div>
                    <div>
                        <Table
                            dataSource={this.state.gSensors}
                            rowKey="type"
                            bordered
                            pagination={false}>
                            <Column title="类型" dataIndex="name" width="15%" />
                            <Column title="型号" dataIndex="marking" width="15%" />
                            <Column title="厂家" dataIndex="manufacturer" />
                            <Column title="量程" dataIndex="range" width="15%" />
                            <Column title="精度" dataIndex="precision" width="15%" />
                        </Table>
                    </div>
                </div>

                <Modal
                    visible={this.state.visible}
                    destroyOnClose
                    title={this.state.currentMarking}
                    footer={null}
                    width={1000}
                    onCancel={() => this.setState({visible: false})}>
                    <GlobalDboxSensorPanel dboxMarking={this.state.currentMarking} />
                </Modal>
            </div>
        )
    }
}
