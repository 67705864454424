import React, { Component } from 'react'
import './setting-user.less';
import { user } from '@/interface/user';
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';
import { Form, Input, Modal, Spin, Descriptions, Space, Button } from 'antd';
import dayjs from 'dayjs';
import { UserTypeEnum } from '../login/login';
import { UserTypes } from '@/utils/type';
import { queryUserDetail, updateUser } from '@/config/api/user';
import { EditOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { withRouter } from 'react-router-dom';
import { Gprops } from '@/config/props';
interface IProps extends Gprops {
    userStore?: UserStore
}
interface IState {
    userInfo: user

    onEditState: boolean
    loading: boolean
    saveLoading: boolean
}
@inject('userStore')
@observer
class SettingUserPage extends Component<IProps, IState> {
    userId: number;
    formRef = React.createRef<FormInstance>();
    state = {
        userInfo: null,

        onEditState: false,
        loading: false,
        saveLoading: false,
    }

    componentDidMount() {
        let userId = this.props.userStore._userInfo?.id;
        this.userId = userId;
        this.findUserInfo();
    }

    componentDidUpdate() {
        let userId = this.props.userStore._userInfo?.id;
        if (this.userId !== userId) {
            this.userId = userId;
            this.findUserInfo();
        }
    }

    findUserInfo = () => {
        let userId = this.props.userStore._userInfo?.id;
        if (!userId) {
            return;
        }
        queryUserDetail(userId)
            .then(res => {
                let userInfo = res.data;
                console.log(userInfo);
                this.setState({
                    userInfo: userInfo
                })
            })
    }

    showEditForm = () => {
        let userInfo: user = this.state.userInfo;
        if (!userInfo) return;
        this.setState({
            onEditState: true,
        })
        this.formRef.current?.setFieldsValue({
            name: userInfo.name,
            mobile: userInfo.mobile,
            email: userInfo.email,
            duty: userInfo.duty,
            title: userInfo.title,
            address: userInfo.address,
            company: userInfo.company,
        })


    }

    onInfoSave = (values) => {
        let userId = this.userId;
        if (!userId) return;
        this.setState({
            saveLoading: true,
        })
        updateUser(userId, values)
        .then(res => {
            this.setState({
                onEditState: false,
            })
            this.findUserInfo();
        }).finally(() => {
            this.setState({
                saveLoading: false,
            })
        })
    }

    render() {
        let uid = this.props.userStore._userInfo?.id;
        let { userInfo, onEditState, saveLoading } = this.state;
        console.log(userInfo?.last_login_time)
        return (
            <div className="user-setting-container">
                <Form
                    ref={this.formRef}
                    autoComplete="off"
                    onFinish={this.onInfoSave}>
                    <div className="header">
                        <div className="title">
                            个人信息
                    </div>
                        <div className="fill-remaining-space"></div>
                        {!onEditState && (
                            <Space size="middle">
                                <Button type="primary" icon={<EditOutlined />} onClick={this.showEditForm}></Button>
                                <Button onClick={() => this.props.history.push(`/global/settings/pwd`)}>修改密码</Button>
                            </Space>
                        )}
                        {onEditState && (
                            <Space size="middle">
                                <Button type="primary" icon={<EditOutlined />} loading={saveLoading} htmlType="submit">保存</Button>
                                <Button onClick={() => this.setState({ onEditState: false })}>取消</Button>
                            </Space>
                        )}
                    </div>
                    <Spin spinning={this.state.loading}>
                        <Descriptions column={2} bordered>
                            <Descriptions.Item label="用户名">
                                {userInfo?.username}
                            </Descriptions.Item>
                            <Descriptions.Item label="姓名">
                                {!onEditState && (
                                    userInfo?.name
                                )}
                                {onEditState && (
                                    <Form.Item name="name" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label="类别">{UserTypes[userInfo?.type]}</Descriptions.Item>
                            <Descriptions.Item label="电话">
                                {!onEditState && (
                                    userInfo?.mobile
                                )}
                                {onEditState && (
                                    <Form.Item name="mobile" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label="邮箱">
                                {!onEditState && (
                                    userInfo?.email
                                )}
                                {onEditState && (
                                    <Form.Item name="email" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label="职位">
                                {!onEditState && (
                                    userInfo?.duty
                                )}
                                {onEditState && (
                                    <Form.Item name="duty">
                                        <Input />
                                    </Form.Item>
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label="头衔">
                                {!onEditState && (
                                    userInfo?.title
                                )}
                                {onEditState && (
                                    <Form.Item name="title">
                                        <Input />
                                    </Form.Item>
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label="上次登录时间">{userInfo?.last_login_time ? dayjs(userInfo?.last_login_time).format('YYYY-MM-DD HH:mm') : null}</Descriptions.Item>
                            <Descriptions.Item label="地址" span={2}>{userInfo?.address}</Descriptions.Item>
                            {userInfo?.type === UserTypeEnum.CLIENT && (
                                <Descriptions.Item label="公司" span={2}>
                                    {!onEditState && (
                                        userInfo?.company
                                    )}
                                    {onEditState && (
                                        <Form.Item name="company">
                                            <Input />
                                        </Form.Item>
                                    )}
                                </Descriptions.Item>
                            )}
                        </Descriptions>
                    </Spin>
                </Form>

                {/* <Modal
                    visible={this.state.onEditState}
                    width={700}
                    title="编辑信息"
                    onCancel={() => this.setState({ onEditState: false })}>

                </Modal> */}
            </div>
        )
    }
}

export default withRouter(SettingUserPage);