import React, { Component } from 'react'
import { Button, Table, Tabs, Row, Col, Modal, message, Input } from 'antd'
import './section-add.less';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { paramDict2Array } from '@/utils/utils';
import { SectionTypes, SectionShapeTypes } from '@/utils/type';
import { queryGlobalSections, queryEnterprisesSections, createEnterprisesSection } from '@/config/api/global-secmat';
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';
import axios from '@/config/axios';
import { globalSection, section } from '@/interface/model';
import Column from 'antd/lib/table/Column';
import { SectionPanel } from '..';
import { ProjectContext } from '@/config/context';
import { querySections, createSection } from '@/config/api/model';
const { Search } = Input;
const { TabPane } = Tabs;
export const SourceType = {
    DB: 1,
    CUSTOM: 2
}
interface IProps {
    onCancel?: () => void
    afterSave?: () => void
    userStore?: UserStore
}

interface IState {
    selectedSectionType: number,
    gSections: globalSection[],
    displayGSections: globalSection[],
    selectedGlobalSections: globalSection[],
    selectedRowKeys: string[],

    readOnlyModalVisible: boolean
    createModalVisible: boolean
    currentSection: section

    eSectionSaveLoading: boolean // 控制截面弹窗保存键的loading
    saveLoading: boolean

    pageSize: number

    searchValue: string
}

@inject('userStore')
@observer
export default class SectionAdd extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        selectedSectionType: 1,
        gSections: [],
        selectedGlobalSections: [],
        selectedRowKeys: [],
        displayGSections: [],

        readOnlyModalVisible: false,
        createModalVisible: false,
        currentSection: null,

        eSectionSaveLoading: false,
        saveLoading: false,

        pageSize: 30,

        searchValue: null,
    }
    enterPriseId: number;
    maxSid: number = 0;

    componentDidMount() {
        this.enterPriseId = this.props.userStore._enterpriseId;
        this.findSections(this.state.selectedSectionType)
    }

    componentDidUpdate() {
        let enterPriseId = this.props.userStore._enterpriseId;
        if (enterPriseId !== this.enterPriseId) {
            this.enterPriseId = this.props.userStore._enterpriseId;
            this.findSections(this.state.selectedSectionType)
        }
    }

    findSections = (type: number) => {
        if (!this.enterPriseId) {
            return;
        }
        let gSectionsPromise = queryGlobalSections();
        let eSectionsPromise = queryEnterprisesSections(this.enterPriseId);

        axios.all([gSectionsPromise, eSectionsPromise])
            .then(res => {
                let gSections: globalSection[] = res[0].data || [];
                let eSections: globalSection[] = res[1].data || [];
                gSections = gSections.map(item => Object.assign(item, { key: `g${item.type}-${item.id}`, source: SourceType.DB }))
                eSections = eSections.map(item => Object.assign(item, { key: `e${item.type}-${item.id}`, source: SourceType.CUSTOM }))
                let sections = [...gSections, ...eSections]
                this.setState({
                    gSections: sections,
                    displayGSections: sections.filter(item => item.type === this.state.selectedSectionType),
                })
            })
    }



    onTableRowSelectChange = (values) => {
        let sections = values.map(key => this.state.gSections.find(item => item.key === key)) || [];
        this.setState({
            selectedRowKeys: values,
            selectedGlobalSections: sections,
        })
    }

    onSectionTypeChange = (key) => {
        let type = +key;
        this.setState({
            displayGSections: this.state.gSections.filter(item => item.type === type),
            selectedSectionType: type,
            searchValue: null,
        })
    }

    onDeleteClick = (row) => {
        let key = row.key;
        this.setState({
            selectedRowKeys: this.state.selectedRowKeys.filter(item => item !== key),
            selectedGlobalSections: this.state.selectedGlobalSections.filter(item => item.key !== key),
        })
    }

    showSectionModal = (row) => {
        this.setState({
            readOnlyModalVisible: true,
            currentSection: row
        })
    }

    showCreateSectionModal = () => {
        this.setState({
            createModalVisible: true,
        })
    }

    addNewEnterpriseSection = (value) => {
        this.setState({
            eSectionSaveLoading: true,
        })
        let data = Object.assign(value, { enterprise_id: this.props.userStore._enterpriseId })
        createEnterprisesSection(this.props.userStore._enterpriseId, data)
            .then(res => {
                let id = res.data;
                let key = `e${data.type}-${id}`;
                data = Object.assign(data, {
                    id: res.data,
                    key: key,
                    source: SourceType.CUSTOM,
                })
                this.setState({
                    createModalVisible: false,
                    gSections: [data, ...this.state.gSections],
                    selectedGlobalSections: [data, ...this.state.selectedGlobalSections],
                    selectedRowKeys: [key, ...this.state.selectedRowKeys]
                })
                if (data.type === this.state.selectedSectionType) {
                    this.setState({
                        displayGSections: [data, ...this.state.displayGSections]
                    })
                }
            }).finally(() => {
                this.setState({
                    eSectionSaveLoading: false,
                })
            })

    }


    onSave = () => {
        if (this.state.selectedGlobalSections.length === 0) {
            this.props.onCancel();
        }
        this.setState({
            saveLoading: true,
        })
        querySections(this.context)
            .then(res => {
                let pSections: section[] = res.data || [];
                if (pSections.length === 0) {
                    this.maxSid = 0;
                } else {
                    let maxSid = pSections.map(item => item.sid).reduce((a, b) => a > b ? a : b);
                    this.maxSid = maxSid;
                }

                let promiseList = this.state.selectedGlobalSections.map((item, idx) => {
                    let sid = this.maxSid + idx + 1;
                    let data = Object.assign(item, {
                        sid: sid,
                        project_id: this.context,
                    })
                    return createSection(this.context, data);
                })
                return axios.all([promiseList]);
            }).then(res => {
                this.setState({
                    saveLoading: false,
                })
                this.props.afterSave();
            }).catch(err => {
                console.log(err);
                message.error('创建失败');
                this.setState({
                    saveLoading: false,
                })
            })
    }

    onSearchInputChange = (e) => {
        this.setState({
            searchValue: e.target.value
        })
    }


    onSearch = () => {
        let type = this.state.selectedSectionType;
        let searchValue = this.state.searchValue;
        this.setState({
            displayGSections: this.state.gSections.filter(item => item.type === type && item.name.indexOf(searchValue) >= 0),
        })
    }

    render() {
        let { selectedSectionType, saveLoading, pageSize } = this.state
        // let radios = paramDict2Array(SectionTypes).filter(item => item.value.active).map((item, idx) => (
        //     <Radio.Button key={idx} value={+item.name}>{item.value.alias}</Radio.Button>
        // ))
        let tabbars = paramDict2Array(SectionTypes).filter(item => item.value.active).map((item, idx) => (
            <TabPane tab={item.value.alias} key={item.name} />
        ))
        return (
            <div className="section-add-container">
                <div className="page-header">
                    <Button type="link" size="large" icon={<ArrowLeftOutlined />} onClick={this.props.onCancel}>返回</Button>
                    <Search
                        placeholder="检索截面"
                        style={{ width: '200px' }}
                        value={this.state.searchValue}
                        onChange={this.onSearchInputChange}
                        onBlur={this.onSearch}
                        onPressEnter={this.onSearch} />
                    <div className="fill-remaining-space"></div>
                    <Button type="primary" loading={saveLoading} onClick={this.onSave}>保存</Button>
                </div>
                <div className="main">
                    <Row gutter={24}>
                        <Col xs={24} xl={12}>
                            <div className="section-library">
                                {/* <Radio.Group defaultValue={selectedSectionType} buttonStyle="solid" onChange={this.onSectionTypeChange}>
                            {radios}
                        </Radio.Group> */}
                                <Tabs
                                    defaultActiveKey={selectedSectionType.toString()}
                                    type="card"
                                    onChange={this.onSectionTypeChange}
                                    tabBarExtraContent={(
                                        <Button size="small" type="link" icon={<PlusOutlined />} onClick={this.showCreateSectionModal}>自定义</Button>
                                    )}>
                                    {tabbars}
                                </Tabs>
                                <Table
                                    dataSource={this.state.displayGSections}
                                    rowKey="key"
                                    pagination={{ pageSize: pageSize, onShowSizeChange: (current, size) => this.setState({ pageSize: size }) }}
                                    rowSelection={{
                                        type: 'checkbox',
                                        onChange: this.onTableRowSelectChange,
                                        selectedRowKeys: this.state.selectedRowKeys
                                    }}>
                                    <Column title="编号" dataIndex="key" />
                                    <Column title="截面名称" dataIndex="name" />
                                    <Column title="形状" dataIndex="shape_type" render={record => SectionShapeTypes[record]} />
                                    <Column title="来源" dataIndex="source" render={(record, idx) => record === SourceType.DB ? '数据库' : '自定义'} />
                                    <Column title="" key="operations" render={(record, idx) => (
                                        <Button type="link" size="small" onClick={() => this.showSectionModal(record)}>详情</Button>
                                    )} />
                                </Table>
                            </div>
                        </Col>
                        <Col xs={24} xl={12}>
                            <div className="section-selected">
                                <div className="title">选中的截面</div>
                                <Table
                                    dataSource={this.state.selectedGlobalSections}
                                    rowKey="key"
                                    pagination={false}
                                >
                                    <Column title="编号" dataIndex="key" />
                                    <Column title="类别" dataIndex="type" render={record => SectionTypes[record]?.alias} />
                                    <Column title="截面名称" dataIndex="name" />
                                    <Column title="形状" dataIndex="shape_type" render={record => SectionShapeTypes[record]} />
                                    <Column title="来源" dataIndex="source" render={(record, idx) => record === SourceType.DB ? '数据库' : '自定义'} />
                                    <Column title="" key="delete" render={(record, idx) => (
                                        <Button type="link" size="small" onClick={() => this.onDeleteClick(record)}>删除</Button>
                                    )} />
                                </Table>
                            </div>
                        </Col>
                    </Row>


                </div>

                <Modal
                    visible={this.state.readOnlyModalVisible}
                    destroyOnClose
                    width={800}
                    closable={false}
                    style={{ top: '20px' }}
                    onCancel={() => this.setState({ readOnlyModalVisible: false })}
                    footer={null}>
                    <SectionPanel
                        isEditing={false}
                        sectionDetail={this.state.currentSection}
                        readOnly />
                </Modal>

                <Modal
                    visible={this.state.createModalVisible}
                    destroyOnClose
                    width={800}
                    closable={false}
                    style={{ top: '20px' }}
                    footer={null}>
                    <SectionPanel
                        isEditing={false}
                        saveLoading={this.state.eSectionSaveLoading}
                        sectionDetail={this.state.currentSection}
                        newType={this.state.selectedSectionType}
                        onCancel={() => this.setState({ createModalVisible: false })}
                        onSave={this.addNewEnterpriseSection} />
                </Modal>
            </div>
        )
    }
}
