import React, { Component, Fragment } from 'react'
import { Table, Row, Col, Input, Space, Button, message, Tooltip, Upload } from 'antd';
import GlobalDeviceStore from '@/store/global-device';
import { dbox } from '@/interface/dbox';
import { inject, observer } from 'mobx-react';
import { ProjectContext } from '@/config/context';
import { queryGlobalDboxs } from '@/config/api/global-device';
import { queryDboxs, deleteDbox, updateDbox, dboxSynch, downloadDboxsCsv } from '@/config/api/dbox';
import { SearchOutlined, PlusOutlined, EditOutlined, DeleteOutlined, SyncOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { DeviceStatistics, DboxAddPanel, EditableRow, EditableCell } from '@/components';
import './dbox-list.less';
import { Gprops } from '@/config/props';
import { withRoutePane } from '@/components/hoc/routePane';
import { Tag } from 'antd';
import { dboxWorkStatus } from '@/utils/type';
import axios from '@/config/axios';
import axiosOrigin from 'axios';
import classnames from 'classnames';
import appStore, { AUTHORIZATION } from '@/store/app-store';
const { Column } = Table;


export const DboxTypesEnum = {
    STATIC: 1,
    DYNAMIC: 2,
}

export const DboxStatusTypesEnum = {
    OFFLINE: 0,
    ONLINE: 1,
}

interface IProps extends Gprops {
    globalDeviceStore?: GlobalDeviceStore,
}

interface IState {
    dboxs: dbox[],
    displayDboxs: dbox[],
    pagination: {},
    statistics: any[],
    inputValue: string,
    onDeleteState: boolean  // 切换删除状态
    dboxIdToDelete: number[]  // 缓存即将删除的采集箱
    deleteLoading: boolean
    onAddState: boolean  // 切换添加状态
    onEditState: boolean

    dboxSyncLoading: {}
    dboxDownloadLoading: boolean
}

@inject('globalDeviceStore')
@observer
class DboxListPage extends Component<IProps, IState> {

    static contextType = ProjectContext;
    state = {
        dboxs: [],
        displayDboxs: [],
        pagination: {},
        statistics: [],
        inputValue: null,
        onDeleteState: false,
        dboxIdToDelete: [],
        deleteLoading: false,
        onAddState: false,
        onEditState: false,

        dboxSyncLoading: {},
        dboxDownloadLoading: false,
    }

    componentDidMount() {
        this.loadDboxs();
    }

    loadDboxs = () => {
        let queryGlobalDboxsPromise = queryGlobalDboxs();
        let queryDboxsPromise = queryDboxs({
            project_id: this.context,
        });
        axios.all([queryGlobalDboxsPromise, queryDboxsPromise])
            .then(res => {
                let gDboxs = res[0].data || [];
                this.props.globalDeviceStore.globalDboxs(gDboxs);
                let dboxs = res[1].data;
                // 设备统计
                let count = this.countStatistic(dboxs);
                let display = dboxs.filter(item => item.name.indexOf(this.state.inputValue || '') >= 0) || [];
                this.setState({
                    dboxs: dboxs,
                    statistics: count,
                    displayDboxs: display,
                })
            })
    }

    countStatistic = (value: dbox[]) => {
        let statistics = new Map();
        value.forEach((dbox: dbox) => {
            if (!statistics[dbox.marking]) {
                statistics[dbox.marking] = 1;
            } else {
                statistics[dbox.marking]++;
            }
        });
        let res = [];
        for (let key of Object.keys(statistics).sort()) {
            let name = this.props.globalDeviceStore._globalDboxs.filter(item => item.marking === key)[0];
            res.push({ marking: key, count: statistics[key], name: name ? name.name : '' });
        }
        return res;
    }

    handleSearchChange = (value) => {
        let display = this.state.dboxs.filter(item => item.name.indexOf(value) >= 0) || [];
        this.setState({
            displayDboxs: display
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            dboxIdToDelete: [...selectedRowKeys]
        })
    }

    onDelete = () => {
        this.setState({
            deleteLoading: true,
        })
        let deletePromiseList = this.state.dboxIdToDelete.map(id => deleteDbox(id));
        axios.all([...deletePromiseList])
            .then(res => {
                this.loadDboxs();
                this.setState({
                    dboxIdToDelete: [],
                    onDeleteState: false,
                    deleteLoading: false,
                })
            })
    }

    onSave = () => {
        this.setState({
            onAddState: false,
        })
        this.loadDboxs();
    }


    handleRowSave = (row: dbox) => {
        let id = row.id;
        let newData = [...this.state.dboxs];
        let index = newData.findIndex(item => item.id === id);
        let item: dbox = newData[index];

        newData.splice(index, 1, { ...item, ...row })
        let display = newData.filter(item => item.name.indexOf(this.state.inputValue || '') >= 0) || [];
        this.setState({
            dboxs: [...newData],
            displayDboxs: display,
        })
        let data = {
            name: row.name,
            address_code: row.address_code,
        }
        updateDbox(id, data)
            .then(res => { }).catch(err => {
                message.error('更新失败');
                newData.splice(index, 1, {
                    ...row,
                    ...item,
                })
                let display = newData.filter(item => item.name.indexOf(this.state.inputValue || '') >= 0) || [];
                this.setState({
                    dboxs: [...newData],
                    displayDboxs: display,
                })
            })
    }

    onDboxSyncClick = (e, id: number) => {
        e.stopPropagation();
        let dboxSyncLoading = { ...this.state.dboxSyncLoading };
        dboxSyncLoading[id] = true;
        this.setState({
            dboxSyncLoading: dboxSyncLoading,
        });
        let d: dbox = this.state.dboxs.find(item => item.id === id);
        dboxSynch(id)
            .then(res => {
                if (!res.data) {
                    throw new Error('采集箱同步失败');
                }
                message.success('数据上载同步成功!');
                if (!!d) {
                    d.is_synch = true;
                    this.setState({
                        dboxs: [...this.state.dboxs],
                    })
                }

            }).catch(err => {
                // if (!!err.data) {
                //     message.error(err.data?.message);
                // } else {
                //     message.error(err || );
                // }
                console.log(err);
                d.is_synch = false;
                this.setState({
                    dboxs: [...this.state.dboxs],
                })
            }).finally(() => {
                let dboxSyncLoading = { ...this.state.dboxSyncLoading };
                dboxSyncLoading[id] = false;
                this.setState({
                    dboxSyncLoading: dboxSyncLoading,
                });
            })
    }

    downloadCsv = () => {
        const projectId = this.context;
        this.setState({
            dboxDownloadLoading: true,
        })

        downloadDboxsCsv(projectId).then(res => {
            const blob = new Blob([res.data]);
            const link = document.createElement('a');
            // const finename = res.headers['filename'];
            const filename = '采集箱表.csv';
            link.setAttribute('href', window.URL.createObjectURL(blob));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(err => {
            console.log(err);
            message.error('导出失败');
        }).finally(() => {
            this.setState({
                dboxDownloadLoading: false,
            })
        })
    }

    render() {
        let { onDeleteState, dboxIdToDelete, onAddState, onEditState, dboxDownloadLoading } = this.state;
        return (
            <Row className="dbox-list-page">
                <Col xs={24} xxl={18}>
                    <div className="left">
                        <div className="header-tools">
                            {!onAddState && (
                                <Input
                                    placeholder="搜索采集箱"
                                    suffix={<SearchOutlined />}
                                    className="search-input"
                                    value={this.state.inputValue}
                                    onChange={(e) => this.setState({ inputValue: e.target.value })}
                                    onPressEnter={() => this.handleSearchChange(this.state.inputValue)}
                                    onBlur={() => this.handleSearchChange(this.state.inputValue)} />
                            )}
                            <div className="fill-remaining-space"></div>
                            {!onDeleteState && !onAddState && !onEditState && (
                                <Space size='middle'>
                                    {/* <Upload showUploadList={false}>
                                        <Button>导入csv</Button>
                                    </Upload> */}
                                    <Button loading={dboxDownloadLoading} onClick={this.downloadCsv}>导出csv</Button>
                                    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.setState({ onAddState: true })}></Button>
                                    <Button icon={<EditOutlined />} onClick={() => this.setState({ onEditState: true })}></Button>
                                    <Button danger icon={<DeleteOutlined />} onClick={() => this.setState({ onDeleteState: true })}></Button>
                                </Space>
                            )}
                            {onDeleteState && (
                                <Space>
                                    <Button danger onClick={this.onDelete} loading={this.state.deleteLoading}>确定</Button>
                                    <Button onClick={() => this.setState({ onDeleteState: false })}>取消</Button>
                                </Space>
                            )}
                            {onEditState && (
                                <Space>
                                    <Button type="primary" onClick={() => this.setState({ onEditState: false })}>完成</Button>
                                </Space>
                            )}
                        </div>
                        {onAddState && (
                            <DboxAddPanel
                                gDboxs={this.props.globalDeviceStore._globalDboxs}
                                onCancel={() => this.setState({ onAddState: false })}
                                onSave={this.onSave} />
                        )}
                        <div className="list-content">
                            <Table
                                dataSource={this.state.displayDboxs}
                                rowKey="id"
                                pagination={false}
                                rowClassName={classnames({
                                    'click-row': !onEditState && !onDeleteState
                                })}
                                components={{
                                    body: {
                                        row: EditableRow,
                                        cell: EditableCell,
                                    }
                                }}
                                onRow={record => {
                                    if (onEditState || onDeleteState) {
                                        return null;
                                    }
                                    return {
                                        onClick: _ => {
                                            this.props.history.push(`${this.props.match.url}/${record.id}`);
                                        },
                                    }
                                }}
                                rowSelection={onDeleteState && {
                                    type: 'checkbox',
                                    onChange: this.onSelectChange,
                                    selectedRowKeys: dboxIdToDelete
                                }}>
                                <Column
                                    title="编号"
                                    dataIndex="name"
                                    width="15%"
                                    onCell={(record: dbox, idx) => ({
                                        record,
                                        editable: onEditState,
                                        dataIndex: 'name',
                                        title: '编号',
                                        handleSave: this.handleRowSave,
                                    })} />
                                <Column title="类型" dataIndex="type" render={(record) => {
                                    let global = this.props.globalDeviceStore._globalDboxs.filter(item => item.type === record)[0];
                                    return global ? global.name : '';
                                }} />
                                <Column title="型号" dataIndex="marking" />
                                <Column title="厂家" dataIndex="manufacturer" />
                                <Column title="通道数" dataIndex="channel_count" />
                                <Column title="自定义通道" dataIndex="channel_range" />
                                <Column
                                    title="地址码"
                                    dataIndex="address_code"
                                    width="10%"
                                    onCell={(record: dbox, idx) => ({
                                        record,
                                        editable: onEditState,
                                        dataIndex: 'address_code',
                                        title: '地址码',
                                        handleSave: this.handleRowSave,
                                    })} />
                                <Column title="状态" key="work_status" render={(record: dbox) => {
                                    if (!record.is_synch) {
                                        return (
                                            <div className="term">
                                                {!this.state.dboxSyncLoading[record.id] && (
                                                    <Fragment>
                                                        <Tooltip title="指：数据库中的配置与采集箱硬件配置不一致">
                                                            <InfoCircleOutlined style={{ color: '#ff4d4f' }} />
                                                        </Tooltip>
                                                        <div>未与硬件同步</div>
                                                    </Fragment>
                                                )}
                                                {this.state.dboxSyncLoading[record.id] && (
                                                    <div>正在与硬件同步</div>
                                                )}

                                                <span className="detail">
                                                    <Tooltip title="重新上载">
                                                        <Button
                                                            type="link"
                                                            onClick={(e) => this.onDboxSyncClick(e, record.id)}
                                                            icon={<SyncOutlined spin={this.state.dboxSyncLoading[record.id]} />}></Button>
                                                    </Tooltip>
                                                </span>
                                            </div>
                                        )
                                    }
                                    return <Tag color={dboxWorkStatus[record.work_status]['color']}>{dboxWorkStatus[record.work_status]['value']}</Tag>
                                }} />
                            </Table>
                        </div>
                    </div>
                </Col>
                <Col xs={24} xxl={6}>
                    <div className="right device-statistics">
                        <DeviceStatistics statistic={this.state.statistics} />
                    </div>
                </Col>
            </Row>
        )
    }
}

export default withRoutePane(DboxListPage, '采集箱列表');