import React, { Component } from 'react'
import { Row, Col, Input, Button, Space, Table, message } from 'antd';
import './sensor-list.less';
import { sensor } from '@/interface/sensor';
import { inject, observer } from 'mobx-react';
import { ProjectContext } from '@/config/context';
import { querySensors, deleteSensor, updateSensor, downloadSensorsCsv } from '@/config/api/sensor';
import GlobalDeviceStore from '@/store/global-device';
import { queryGlobalSensors } from '@/config/api/global-device';
import { sensorWorkStatus } from '@/utils/type';
import { queryMobjects } from '@/config/api/mobject';
import axios from '@/config/axios';
import { mobject } from '@/interface/mobject';
import SensorStatistics from '@/components/device-list/device-statistics';
import { Tag } from 'antd';
import { withRoutePane } from '@/components/hoc/routePane';
import { SearchOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { EditableRow, EditableCell, SensorAddPanel } from '@/components';
import classnames from 'classnames';
import { Gprops } from '@/config/props';

const { Column } = Table;

export const SensorStatusTypesEnum = {
    OFFLINE: 0,
    ONLINE: 1,
    ABNORMAL: 2,
}

interface IProps extends Gprops {
    globalDeviceStore?: GlobalDeviceStore,
}

interface IState {
    sensors: sensor[]
    displaySensors: sensor[],
    loading: boolean,
    pagination: any
    statistics: any[]
    inputValue: string,
    onDeleteState: boolean  // 切换删除状态
    sensorIdToDelete: number[]  // 缓存即将删除的采集箱
    deleteLoading: boolean
    onAddState: boolean  // 切换添加状态
    onEditState: boolean
    sensorDownloadLoading: boolean
}

@inject('globalDeviceStore')
@observer
class SensorListPage extends Component<IProps, IState> {

    static contextType = ProjectContext;
    projectMobjects: mobject[] = [];

    constructor(props) {
        super(props);
        this.state = {
            sensors: [],
            displaySensors: [],
            pagination: {
                pageSize: 30,
            },
            loading: false,

            statistics: [],
            inputValue: null,
            onDeleteState: false,
            sensorIdToDelete: [],
            deleteLoading: false,
            onAddState: false,
            onEditState: false,
            sensorDownloadLoading: false,
        }
    }

    componentDidMount() {
        this.loadSensors();
    }

    loadSensors = () => {
        let queryGlobalSensorsPromise = queryGlobalSensors();
        let queryMobjectsPromise = queryMobjects({ project_id: this.context });
        let querySensorsPromise = querySensors({
            project_id: this.context,
        });
        this.setState({
            loading: true,
        })
        axios.all([queryGlobalSensorsPromise, queryMobjectsPromise, querySensorsPromise])
            .then(res => {
                let gSensors = res[0].data || [];
                this.projectMobjects = res[1].data || [];
                let sensors = res[2].data || [];
                sensors = sensors.sort((a, b) => a.name > b.name ? 1 : -1);
                this.props.globalDeviceStore.globalSensors(gSensors);
                // 设备统计
                let count = this.countStatistic(sensors);
                let display = sensors.filter(item => item.name.indexOf(this.state.inputValue || '') >= 0) || [];
                // 更新数据渲染
                this.setState({
                    sensors: sensors,
                    statistics: count,
                    displaySensors: display,
                    pagination: {
                        pageSize: 18,
                        total: sensors.length
                    },
                    loading: false,
                })
            }).finally(() =>{
                this.setState({
                    loading: false,
                })
            })
    }

    // findProjectSensors = (search_name?: string) => {
    //     // 获取项目的设备列表
    //     let qm = queryMobjects({ project_id: this.context });
    //     let qs = querySensors({
    //         project_id: this.context,
    //         search_name: search_name
    //     });
    //     axios.all([qm, qs]).then(res => {
    //         let sensors = res[1].data;
    //         this.projectMobjects = res[0].data;
    //         // 设备统计
    //         let count = this.countStatistic(sensors);
    //         // 更新数据渲染
    //         this.setState({
    //             sensors: sensors,
    //             statistics: count,
    //             pagination: {
    //                 pageSize: 18,
    //                 total: sensors.length
    //             }
    //         })
    //     })
    // }

    countStatistic = (value: sensor[]) => {
        let statistics = new Map();
        value.forEach((sensor: sensor) => {
            if (!statistics[sensor.marking]) {
                statistics[sensor.marking] = 1;
            } else {
                statistics[sensor.marking]++;
            }
        });
        let res = [];
        for (let key of Object.keys(statistics).sort()) {
            let name = this.props.globalDeviceStore._globalSensors.filter(item => item.marking === key)[0];
            res.push({ marking: key, count: statistics[key], name: name ? name.name : '' });
        }
        return res;
    }

    handleSearchChange = (value) => {
        let display = this.state.sensors.filter(item => item.name.indexOf(value) >= 0) || [];
        this.setState({
            displaySensors: display
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            sensorIdToDelete: [...selectedRowKeys]
        })
    }

    onDelete = () => {
        this.setState({
            deleteLoading: true,
        })
        let deletePromiseList = this.state.sensorIdToDelete.map(id => deleteSensor(id));
        axios.all([...deletePromiseList])
            .then(res => {
                this.loadSensors();
                this.setState({
                    sensorIdToDelete: [],
                    onDeleteState: false,
                    deleteLoading: false,
                })
            })
    }

    onSave = () => {
        this.setState({
            onAddState: false,
        })
        this.loadSensors();
    }


    handleRowSave = (row: sensor) => {
        let id = row.id;
        let newData = [...this.state.sensors];
        let index = newData.findIndex(item => item.id === id);
        let item: sensor = newData[index];

        newData.splice(index, 1, { ...item, ...row })
        let display = newData.filter(item => item.name.indexOf(this.state.inputValue || '') >= 0) || [];
        this.setState({
            sensors: [...newData],
            displaySensors: display,
        })
        let data = {
            name: row.name,
            // address_code: row.address_code,
        }
        updateSensor(id, data)
            .then(res => { }).catch(err => {
                message.error('更新失败');
                newData.splice(index, 1, {
                    ...row,
                    ...item,
                })
                let display = newData.filter(item => item.name.indexOf(this.state.inputValue || '') >= 0) || [];
                this.setState({
                    sensors: [...newData],
                    displaySensors: display,
                })
            })
    }

    downloadCsv = () => {
        const projectId = this.context;
        this.setState({
            sensorDownloadLoading: true,
        })

        downloadSensorsCsv(projectId).then(res => {
            const blob = new Blob([res.data]);
            const link = document.createElement('a');
            // const finename = res.headers['filename'];
            const filename = '传感器表.csv';
            link.setAttribute('href', window.URL.createObjectURL(blob));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(err => {
            console.log(err);
            message.error('导出失败');
        }).finally(() => {
            this.setState({
                sensorDownloadLoading: false,
            })
        })
    }

    render() {
        let { onDeleteState, sensorIdToDelete, onAddState, onEditState } = this.state;
        return (
            <Row className="sensor-list-page">
                <Col xs={24} xxl={18}>
                    <div className="left">
                        <div className="header-tools">
                            {!onAddState && (
                                <Input
                                    placeholder="搜索传感器"
                                    suffix={<SearchOutlined />}
                                    className="search-input"
                                    value={this.state.inputValue}
                                    onChange={(e) => this.setState({ inputValue: e.target.value })}
                                    onPressEnter={() => this.handleSearchChange(this.state.inputValue)}
                                    onBlur={() => this.handleSearchChange(this.state.inputValue)} />
                            )}

                            <div className="fill-remaining-space"></div>
                            {!onDeleteState && !onAddState && !onEditState && (
                                <Space size='middle'>
                                    {/* <Button>导入csv</Button> */}
                                    <Button loading={this.state.sensorDownloadLoading} onClick={this.downloadCsv}>导出csv</Button>
                                    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.setState({ onAddState: true })}></Button>
                                    <Button icon={<EditOutlined />} onClick={() => this.setState({ onEditState: true })}></Button>
                                    <Button danger icon={<DeleteOutlined />} onClick={() => this.setState({ onDeleteState: true })}></Button>
                                </Space>
                            )}
                            {onDeleteState && (
                                <Space>
                                    <Button danger onClick={this.onDelete} loading={this.state.deleteLoading}>确定</Button>
                                    <Button onClick={() => this.setState({ onDeleteState: false })}>取消</Button>
                                </Space>
                            )}
                            {onEditState && (
                                <Space>
                                    <Button type="primary" onClick={() => this.setState({ onEditState: false })}>完成</Button>
                                </Space>
                            )}

                        </div>
                        {onAddState && (
                            <SensorAddPanel
                                gSensors={this.props.globalDeviceStore._globalSensors}
                                onCancel={() => this.setState({ onAddState: false })}
                                onSave={this.onSave} />
                        )}
                        <div className="list-content">
                            <Table 
                            dataSource={this.state.displaySensors} 
                            rowKey="id" 
                            pagination={this.state.sensors?.length > 30 ? {
                                pageSize: 30
                            } : false }
                            loading={this.state.loading}
                            rowClassName={classnames({
                                'click-row': !onEditState && !onDeleteState
                            })}
                            components={{
                                body: {
                                    row: EditableRow,
                                    cell: EditableCell,
                                }
                            }}
                            onRow={record => {
                                if (onEditState || onDeleteState) {
                                    return null;
                                }
                                return {
                                    onClick: _ => {
                                        this.props.history.push(`${this.props.match.url}/${record.id}`);
                                    },
                                }
                            }}
                            rowSelection={onDeleteState && {
                                type: 'checkbox',
                                onChange: this.onSelectChange,
                                selectedRowKeys: sensorIdToDelete
                            }}>
                                <Column 
                                title="编号"
                                dataIndex="name" 
                                width="15%"
                                onCell={(record: sensor, idx) => ({
                                    record,
                                    editable: onEditState,
                                    dataIndex: 'name',
                                    title: '编号',
                                    handleSave: this.handleRowSave,
                                })} />
                                <Column title="类型" dataIndex="type" render={(record) => {
                                    let global = this.props.globalDeviceStore._globalSensors.filter(item => item.type === record)[0];
                                    return global ? global.name : '';
                                }} />
                                <Column title="型号" dataIndex="marking" />
                                <Column title="量程" dataIndex="range" />
                                <Column title="精度" dataIndex="precision" />
                                <Column title="定位" key="mobject_id" render={(record: sensor) => {
                                    let mobject = this.projectMobjects.filter(item => item.id === record.mobject_id)[0];
                                    return mobject ? `${mobject.name}-${record.location}` : '';
                                }} />
                                <Column title="状态" dataIndex="work_status" render={(record) => (
                                    <Tag color={sensorWorkStatus[record]['color']}>{sensorWorkStatus[record]['value']}</Tag>
                                )} />
                            </Table>
                        </div>
                    </div>
                </Col>
                <Col xs={24} xxl={6}>
                    <div className="right device-statistics">
                        <SensorStatistics statistic={this.state.statistics} />
                    </div>
                </Col>
            </Row>
        )
    }
}

export default withRoutePane(SensorListPage, '传感器列表');