import React, { Component } from 'react'
import { constage } from '@/interface/constage'
import dayjs from 'dayjs';
import { Button, Space, Modal } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { figure } from '@/interface/document';
import { figureStaticLocation } from '@/config/api/document';
import { Gprops } from '@/config/props';
import { withRouter } from 'react-router-dom';
import { ProjectContext } from '@/config/context';
const { confirm } = Modal;
interface IProps extends Gprops {
    constageDetail: constage
    editClick: () => void
    onDelete: () => void
}
interface IState {
    currentFigure: figure;
}
class ConstageDetailInfo extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        currentFigure: null,
    }

    componentDidMount() {
        let stage = this.props.constageDetail;
        let fig = stage?.figures[0] || null;
        this.setState({
            currentFigure: fig
        })
    }

    goNext = () => {
        let figures: figure[] = this.props.constageDetail?.figures;
        if (figures.length === 0) return;
        let idx = figures.findIndex(item => item.id === this.state.currentFigure.id);
        if (idx < 0) return;
        idx += 1;
        if (idx >= figures.length) {
            idx = 0;
        }
        let newData = figures[idx];
        this.setState({
            currentFigure: newData,
        })
    }

    goPrevious = () => {
        let figures: figure[] = this.props.constageDetail?.figures;
        if (figures.length === 0) return;
        let idx = figures.findIndex(item => item.id === this.state.currentFigure.id);
        if (idx < 0) return;
        idx -= 1;
        if (idx < 0) {
            idx = figures.length - 1;
        }
        let newData = figures[idx];
        this.setState({
            currentFigure: newData,
        })
    }

    showDeleteConfirm = () => {
        confirm({
            title: '确认删除?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk: () => {
                this.props.onDelete();
            },
            onCancel() {
              
            },
          });
    }

    render() {
        let constageDetail = this.props.constageDetail;
        let currentFigure = this.state.currentFigure;
        return (
            <div className="constage-info">
                <div className="header">
                    <div className="title">
                        <div>
                            <span>{constageDetail.code}</span>
                            <span>{constageDetail.name}</span>
                        </div>
                        <div>
                            <span>{dayjs(constageDetail.start_time).format('YYYY-MM-DD HH:mm')}~</span>
                            <span>{dayjs(constageDetail.end_time).format('YYYY-MM-DD HH:mm')}</span>
                        </div>

                    </div>
                    <div className="operation">
                        <Space>
                            <Button onClick={() => this.props.history.push(`/projects/${this.context}/drawings`)}>监测施工图</Button>
                            <Button type="primary" icon={<EditOutlined />} onClick={this.props.editClick}>编辑</Button>
                            <Button danger icon={<DeleteOutlined />} onClick={this.showDeleteConfirm}>删除</Button>
                        </Space>
                    </div>
                </div>
                <div className="main">
                    <div>
                        <Button
                            type="link"
                            size="large"
                            style={{ height: '100%' }}
                            onClick={this.goPrevious}
                            icon={<CaretLeftOutlined />}></Button>
                    </div>
                    <div className="fill-remaining-space">
                        <div className="figure">
                            {
                                currentFigure ?
                                    <img width="100%" height="100%" alt="模式图" src={`${figureStaticLocation}${currentFigure?.url}`} />
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div>
                        <Button
                            type="link"
                            size="large"
                            style={{ height: '100%' }}
                            onClick={this.goNext}
                            icon={<CaretRightOutlined />}></Button>
                    </div>
                </div>
                <div className="footer">
                    <div style={{ fontSize: '16px' }}>
                        {constageDetail.content}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ConstageDetailInfo);