import React, { Component } from 'react'
import { Tabs, message } from 'antd'
import { RProps } from '@/config/props';
import { inject, observer } from 'mobx-react';
import MobjectStore from '@/store/mobject-store';
import { queryMobjectDetail, updateMobjectDetail } from '@/config/api/mobject';
import { mobject } from '@/interface/mobject';
import MobjectInfoPane from './mobject-info';
import QuantityStepPane, { SettingStepType } from './quantity-step';
import QuantityDetailPane from './quantity-detail';
const { TabPane } = Tabs;

interface IProps extends RProps {
    mobjectStore?: MobjectStore
    tab_uuid?: string
    mobjectType?: number
}
interface IState {
    mobjectDetail: mobject
    settingState: boolean
}

@inject('routeStore', 'mobjectStore')
@observer
export default class MobjectPane extends Component<IProps, IState> {

    state = {
        mobjectDetail: null,
        settingState: false,
    }
    previousMobjectId: number;

    UNLISTEN_FN: any;

    constructor(props) {
        super(props);
        // 初始刷新时，向父组件传递当前详情id参数
        let mobjectId = +this.props.match.params['mobjectId'];
        this.props.mobjectStore.changeSelectedMobjectId(mobjectId);
        this.findMobjectById(mobjectId);
    }

    componentDidUpdate() {
        let mobjectId = this.props.mobjectStore._selectedMobjectId;
        if (this.previousMobjectId !== mobjectId) {
            this.findMobjectById(mobjectId);
        }
    }

    findMobjectById = (mobjectId: number) => {
        // 每次获取详情时，为了同步，从store中获取id
        this.previousMobjectId = mobjectId;
        queryMobjectDetail(mobjectId)
            .then(res => {
                let mobjectDetail: mobject = res.data;
                mobjectDetail.id = mobjectId; // TODO: 开发阶段模拟数据采用
                this.props.mobjectStore.setMojectDetail(mobjectDetail);
                this.setState({
                    mobjectDetail: mobjectDetail
                })
            })
    }

    onTabChange = (key: string) => {
        // 在url中的参数里记录当前tab位置
        let url = this.props.match.url;
        let search = `?tab=${key}`;
        this.props.routeStore.changeRoutePaneUrl(this.props.tab_uuid, `${url}${search}`);
        this.props.history.replace({ pathname: url, search: search });
        this.props.mobjectStore.changeMobjectTabIndex(this.props.tab_uuid, key);
    }

    onSettingCompleted = () => {
        let mobjectDetail = this.props.mobjectStore._mobjectDetail;
        if (mobjectDetail?.initial_setting) {
            updateMobjectDetail(mobjectDetail.id, {
                initial_setting: false,
                setting_step: SettingStepType.Step_1
            }).then(res => {
                this.props.mobjectStore.setMojectDetail(Object.assign({}, mobjectDetail, {
                    initial_setting: false
                }))
                this.setState({
                    settingState: false,
                })
            }).catch(err => message.error('更新配置失败'));
        } else {
            this.setState({
                settingState: false
            })
        }
    }

    changeGroupTabUrl = (key: string) => {
        // 在url中的参数里记录当前tab位置
        let url = this.props.match.url;
        let search = `?tab=2&group=${key}`;
        this.props.routeStore.changeRoutePaneUrl(this.props.tab_uuid, `${url}${search}`);
        this.props.history.replace({ pathname: url, search: search });
    }

    render() {
        if (!this.props.mobjectStore._mobjectDetail) {
            return null;
        }
        return (
            <div className="mobject-tabs">
                <Tabs
                    tabBarGutter={56}
                    onChange={this.onTabChange}
                    defaultActiveKey={this.props.mobjectStore._mobjectTabIndex[this.props.tab_uuid]}>
                    <TabPane tab="详情" key="1">
                        {/* <MobjectInfoPane /> */}
                    </TabPane>
                    <TabPane tab="指标" key="2">
                        {/* {
                            !!!this.props.mobjectStore._mobjectDetail?.initial_setting && !this.state.settingState ?
                                <QuantityDetailPane />
                                :
                                <QuantityStepPane onMissionCompleted={this.onSettingCompleted} />
                        } */}
                    </TabPane>
                </Tabs>
                {this.props.mobjectStore._mobjectTabIndex[this.props.tab_uuid] === '2' ? (
                    !this.props.mobjectStore._mobjectDetail?.initial_setting && !this.state.settingState ?
                        <QuantityDetailPane
                            onSettingStateClick={() => this.setState({ settingState: true })}
                            onGroupTabChange={this.changeGroupTabUrl} />
                        :
                        <QuantityStepPane onMissionCompleted={this.onSettingCompleted} />
                ) : (
                        <MobjectInfoPane />
                    )}
            </div>
        )
    }
}

