import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import MobjectStore from '@/store/mobject-store'
import QuantityDetailStore from '@/store/quantity-detail-store'
import { Space, Select, Button, Tooltip } from 'antd';
import { QuantityDataTypes } from '../charts/line-chart-board';
import { constage } from '@/interface/constage';
import dayjs from 'dayjs';
import { DatePicker } from '..';
import { SwapOutlined } from '@ant-design/icons';

const { Option } = Select;
const { RangePicker } = DatePicker;
interface IProps {
    mobjectStore?: MobjectStore
    quantityDetailStore?: QuantityDetailStore,
    constages: constage[],
    onQueryParamsChange?: () => void,
}

interface IState {
    showConstageSelect: boolean
}

@inject('mobjectStore', 'quantityDetailStore')
@observer
export default class QuantityDataTool extends Component<IProps, IState> {

    state = {
        showConstageSelect: false,
    }
    onRangePickerChange = (dates: [dayjs.Dayjs, dayjs.Dayjs]) => {
        if (!!dates) {
            this.props.quantityDetailStore.setStartTime(dates[0]?.toDate() || null);
            this.props.quantityDetailStore.setEndTime(dates[1]?.toDate() || null);
        } else {
            this.props.quantityDetailStore.setStartTime(null);
            this.props.quantityDetailStore.setEndTime(null);
        }

        this.props.onQueryParamsChange();
    }

    onStartTimeChange = (value: dayjs.Dayjs) => {
        this.props.quantityDetailStore.setStartTime(value?.toDate() || null);
        this.props.onQueryParamsChange();
    }

    onEndTimeChange = (value: dayjs.Dayjs) => {
        this.props.quantityDetailStore.setEndTime(value?.toDate() || null);
        this.props.onQueryParamsChange();
    }

    onConstageSelectChange = (values: number[]) => {
        this.props.quantityDetailStore.setConstageIdList(values);
        let startTime = 29979043200000;
        let endTime =   10000000000;
        values.forEach(value => {
            let constage = this.props.constages.find(item => item.id === value);
            startTime = dayjs(constage?.start_time).valueOf() < startTime ? dayjs(constage?.start_time).valueOf() : startTime;
            endTime = dayjs(constage?.end_time).valueOf() > endTime ? dayjs(constage?.end_time).valueOf() : endTime;
        })
        this.props.quantityDetailStore.setStartTime(startTime === 29979043200000 ? null : dayjs(startTime).toDate());
        this.props.quantityDetailStore.setEndTime(endTime === 10000000000 ? null : dayjs(endTime).toDate());
        this.props.onQueryParamsChange();
    }

    onSamplingChange = (value) => {
        this.props.quantityDetailStore.setSamplingPeriod(value);
        this.props.onQueryParamsChange();
    }

    onDataTypeChange = (value) => {
        this.props.quantityDetailStore.setDataType(value);
        this.props.onQueryParamsChange();
    }

    render() {
        let { showConstageSelect } = this.state;
        return (
            <Fragment>
                <Space size={10}>
                    <Select
                        placeholder="显示降采样"
                        style={{ width: 150 }}
                        value={this.props.quantityDetailStore._samplingPeriod}
                        allowClear
                        onChange={this.onSamplingChange}>
                        <Option value={-1}>原采样</Option>
                        <Option value={1}>1秒</Option>
                        <Option value={5}>5秒</Option>
                        <Option value={10}>10秒</Option>
                        <Option value={30}>30秒</Option>
                        <Option value={60}>1分钟</Option>
                        <Option value={300}>5分钟</Option>
                        <Option value={600}>10分钟</Option>
                        <Option value={900}>15分钟</Option>
                        <Option value={1800}>30分钟</Option>
                        <Option value={3600}>1小时</Option>
                        <Option value={21600}>6小时</Option>
                        <Option value={43200}>12小时</Option>
                        <Option value={86400}>24小时</Option>
                        {/* <Option value={-1}>自动(默认)</Option> */}
                    </Select>
                    <Select
                        placeholder="降采样特征"
                        style={{ width: 150 }}
                        value={this.props.quantityDetailStore._dataType}
                        onChange={this.onDataTypeChange}>
                        <Option value={QuantityDataTypes.MAX}>最大值</Option>
                        <Option value={QuantityDataTypes.MIN}>最小值</Option>
                        <Option value={QuantityDataTypes.MEAN}>平均值</Option>
                        <Option value={QuantityDataTypes.NORMAL}>最近值(默认)</Option>
                    </Select>
                    {showConstageSelect && (
                        <Select
                            placeholder="施工阶段"
                            mode="multiple"
                            style={{ width: 400 }}
                            onChange={this.onConstageSelectChange}
                        >
                            {this.props.constages.map((item: constage, idx: number) => (
                                <Option value={item.id} key={item.id}>{item.code}</Option>
                            ))}
                        </Select>
                    )}
                    {!showConstageSelect && (
                        // <RangePicker
                        //     showTime={{ format: 'HH:mm' }}
                        //     format="YYYY-MM-DD HH:mm"
                        //     value={[
                        //         this.props.quantityDetailStore._startTime ? dayjs(this.props.quantityDetailStore._startTime) : undefined,
                        //         this.props.quantityDetailStore._startTime ? dayjs(this.props.quantityDetailStore._endTime) : undefined]}
                        //     onChange={this.onRangePickerChange}
                        // />
                        <Fragment>
                            <DatePicker 
                            showTime 
                            placeholder="开始时间" 
                            onChange={this.onStartTimeChange}
                            value={this.props.quantityDetailStore._startTime ? dayjs(this.props.quantityDetailStore._startTime) : null}/>
                            <DatePicker 
                            showTime 
                            placeholder="结束时间" 
                            onChange={this.onEndTimeChange}
                            value={this.props.quantityDetailStore._endTime ? dayjs(this.props.quantityDetailStore._endTime) : null}/>
                        </Fragment>
                    )}
                </Space>
                <Tooltip title={showConstageSelect ? '切换时间' : '切换施工步'}>
                    <Button 
                    type="link" 
                    icon={<SwapOutlined />} 
                    style={{ marginLeft: '6px' }} 
                    onClick={() => this.setState({showConstageSelect: !showConstageSelect})}></Button>
                </Tooltip>

            </Fragment>
        )
    }
}
