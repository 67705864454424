import { paramObj } from "@/interface/std";
import { UnitMap, SIUnit, ParamToLatex, ParamUnits } from "./unit-type";

export function paramDict2Array(source: {}) {
    let res = [];
    if (!!!source) return res;
    for (let key of Object.keys(source)) {
        res.push({name: key, value: source[key]});
    }
    return res;
}

export function paramArrary2Dict(source: paramObj[]) {
    let res = {};
    if (!source || source?.length === 0) return res;
    source.forEach((item: paramObj) => {
        res[item.name] = item.value;
    })
    return res;
}

export function transferParamsToDisplayScale(params: paramObj[]) {
    let res = params.map(item => {
        let unit = ParamUnits[item.name];
        let scale = Transfer2DisplayScale(unit);
        return {
            name: item.name, 
            value: NumFixed(+item.value * scale, 7)
        };
    })
    return res;
}

export function transferParamsToBackendScale(params: paramObj[]) {
    let res = params.map(item => {
        let unit = ParamUnits[item.name];
        let scale = Transfer2BackEndScale(unit);
        return {name: item.name, value: +item.value * scale};
    })
    return res;
}

export const leftZeroInt = (value: any, n: number) => {
    if (value == null) {
        return null;
    }
    return (Array(n).join('0') + value).slice(-n);
}

// 将指标名称转换为latex格式
export const handleQuantityName = (name: string) => {
    if (!name) {
        return '';
    }
    // 去掉_mq
    name = name.replace('_mq', '');
    // 处理M_y__i 变成M_yi
    name = name.replace('__', '');
    // TODO: 这里暂时把代码注释了，因为safari不兼容
    // name = name.replace(/(?<=_.)_/, '');
    // 处理下划线为下标
    let tmp = name.split(/_/);
    let lowers: string = tmp[0];
    if (!lowers) {
        return name;
    }
    let mainString = ParamToLatex[lowers];
    if (mainString) {
        lowers = mainString
    }
    for (let idx=1;idx<tmp.length;idx++) {
        lowers += `_{${tmp[idx]}}`
    }
    // 处理上标
    tmp = lowers.split(/^/);
    let uppers: string = tmp[0];
    if (!uppers) return lowers;
    for (let idx=1;idx<tmp.length;idx++) {
        uppers += `^{${tmp[idx]}}`
    }
    return uppers;
}

export const Transfer2DisplayScale = (unit: string) => {
    let siUnit = SIUnit[unit];
    if (!siUnit) return 1;
    return UnitMap[siUnit][unit] || 1;
}

export const Transfer2BackEndScale = (unit: string) => {
    let siUnit = SIUnit[unit];
    if (!siUnit) return 1;
    return UnitMap[unit][siUnit] || 1;
}

export const NumFixed = (value: number, n: number) => {
    return Math.round(value * Math.pow(10, n))/Math.pow(10, n);
}

export const transferFigPathToThumb = (url: string) => {
    let res = url.replace('/static/', '/static/thumb/');
    return res;
}

export async function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const sleepFor = (sleepDuration) => {
    var now = new Date().getTime();
    while(new Date().getTime() < now + sleepDuration){ 
        /* Do nothing */ 
    }
}