import React, { Component } from 'react'
import { withRoutePane } from '@/components/hoc/routePane'
import { Gprops } from '@/config/props';
import UserStore from '@/store/user-store';
import appStore, { SITE_DISPLAY_STYLE } from '@/store/app-store';
import { Space, Input, Button, Radio, Table, Tag, Modal, Upload, message } from 'antd';
import { inject, observer } from 'mobx-react';
import './site-manage.less';
import { ProjectContext } from '@/config/context';
import { queryDboxs, updateDboxManageHistory, updateDbox } from '@/config/api/dbox';
import { querySensors, updateSensor, updateSensorManageHistory } from '@/config/api/sensor';
import axios from '@/config/axios';
import { dbox, globalDbox } from '@/interface/dbox';
import { sensor, globalSensor } from '@/interface/sensor';
import { DeviceVarietyLevel } from '../global-install/global-install';
import { EditableRow, EditableCell } from '@/components';
import { queryGlobalDboxs, queryGlobalSensors } from '@/config/api/global-device';
import { paramDict2Array } from '@/utils/utils';
import { deviceManageStatus, dboxWorkStatus } from '@/utils/type';
import ImgCrop from 'antd-img-crop';
import { UploadOutlined, RightOutlined, InboxOutlined } from '@ant-design/icons';
import { figureUploadUrl, figureStaticLocation } from '@/config/api/document';
import { figure } from '@/interface/document';
import Dragger from 'antd/lib/upload/Dragger';
import lodash from 'lodash';
import { queryMobjects, queryMobjectPatternList, queryGroupPatternDetail } from '@/config/api/mobject';
import { mobject, pattern } from '@/interface/mobject';
import { DistributionTypes } from '@/components/mobject/quantity-pattern-setting';
const { Column } = Table;
const { Search } = Input;

export const DeviceManageStatusEnum = {
    REPOSITORY: 1,
    INSTALLED: 2,
    CHECKED: 3,
    REWORK: 4,
    REMOVE: 5,
    RECYCLE: 6,
    SCRAP: 7,
}

export declare type SiteDisplayType = 'Card' | 'List';
interface IProps extends Gprops {
    userStore?: UserStore
}
interface IState {
    onManageEdit: boolean
    dboxs: dbox[],
    sensors: sensor[],
    deviceVariety: number,
    gSensors: globalSensor[],
    gDboxs: globalDbox[],
    displaySensors: sensor[],
    displayDboxs: dbox[],

    tableLoading: boolean
    uploading: boolean // 单一上传的loading
    modelVisible: boolean
    currentUrl: string // 预览图片url缓存

    onMultiUploadState: boolean //批量上传图片状态
    fileList: any[] // 批量上传缓存
    onMultiUploadSaveLoading: boolean // 批量上传的保存

    // 批量管理状态修改的id缓存
    sensorIdToManage: number[],
    dboxIdToManage: number[],

    searchValue: string,

    mobjects: mobject[],
    currentPatternOptions: pattern[],
}
@inject('userStore')
@observer
class SiteManagePage extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        onManageEdit: false,
        dboxs: [],
        sensors: [],
        deviceVariety: DeviceVarietyLevel.SENSOR,
        gSensors: [],
        gDboxs: [],
        displaySensors: [],
        displayDboxs: [],

        tableLoading: false,
        uploading: false,
        modelVisible: false,
        currentUrl: '',

        onMultiUploadState: false,
        fileList: [],
        onMultiUploadSaveLoading: false,

        sensorIdToManage: [],
        dboxIdToManage: [],

        searchValue: '',

        mobjects: [],
        currentPatternOptions: [],
    }

    constructor(props) {
        super(props);
        let displayStyle = appStore.get(SITE_DISPLAY_STYLE);
        this.props.userStore.setProjectSiteDisplay(displayStyle || 'List')
    }

    componentDidMount() {
        this.generateData();
    }

    generateData = () => {
        this.setState({
            tableLoading: true,
        })
        let project_id = this.context;
        let queryDboxPromise = queryDboxs({
            project_id: project_id,
        });
        let querySensorPromise = querySensors({
            project_id: project_id,
        });
        let queryGlobalDboxsPromise = queryGlobalDboxs();
        let queryGlobalSensorsPromise = queryGlobalSensors();
        let queryMobjectsPromise = queryMobjects({
            project_id: this.context,
        })
        axios.all([queryDboxPromise, querySensorPromise, queryGlobalDboxsPromise, queryGlobalSensorsPromise, queryMobjectsPromise])
            .then(res => {
                let sensors: sensor[] = res[1].data || [];
                let gSensors = res[3].data || [];
                sensors.forEach(item => {
                    let gSensor = gSensors.find(gs => gs.type === item.type);
                    item.type_name = gSensor?.name;
                })
                sensors = sensors.sort((a, b) => (a.dbox_id > b.dbox_id) || (a.name > b.name) ? 1 : -1);
                let dboxs = res[0].data || [];
                let gDboxs = res[2].data || [];
                dboxs.forEach(item => {
                    let gDbox = gDboxs.find(gd => gd.type === item.type);
                    item.type_name = gDbox?.name;
                })
                // console.log(dboxs)
                let mobjects = res[4].data || [];
                this.setState({
                    dboxs: dboxs,
                    gDboxs: gDboxs,
                    sensors: sensors,
                    gSensors: gSensors,
                    mobjects: mobjects
                })

                let patternPromiseList = sensors
                    .filter(item => !!item.mobject_id && !!item.group_id)
                    .map(item => queryGroupPatternDetail(item.mobject_id, item.group_id));
                return axios.all([...patternPromiseList]);
            }).then(res => {
                let patterns: pattern[] = res.map(item => item.data) || [];
                this.state.sensors.forEach((item: sensor) => {
                    if (!item.group_id) {
                        return;
                    }
                    let pattern = patterns.find(p => p?.quantity_group_id === item.group_id);
                    if (!!pattern) {
                        item.pattern = pattern;
                    }
                })
                let displayDboxs = this.state.dboxs.filter(item => item.name.indexOf(this.state.searchValue) >= 0) || [];
                let displaySensors = this.state.sensors.filter(item => item.name.indexOf(this.state.searchValue) >= 0) || [];
                this.setState({
                    tableLoading: false,
                    displayDboxs: displayDboxs,
                    displaySensors: displaySensors,
                    sensors: [...this.state.sensors]
                })
            }).catch(err => {
                console.log(err);
                message.error('获取数据失败')
                this.setState({
                    tableLoading: false,
                })
            })
    }

    handleSearchChange = (value) => {
        let displayDboxs = this.state.dboxs.filter(item => item.name.indexOf(value) >= 0) || [];
        let displaySensors = this.state.sensors.filter(item => item.name.indexOf(value) >= 0) || [];
        this.setState({
            displayDboxs: displayDboxs,
            displaySensors: displaySensors
        })
        // this.findProjects(this.state.searchValue);
    }

    onDisplayChange = (e) => {
        this.props.userStore.setProjectSiteDisplay(e.target.value)
    }

    handleSensorSave = (row: sensor, tag: string) => {
        let id = row.id;
        let newData = [...this.state.sensors];
        let index = newData.findIndex(item => item.id === id);
        let item: sensor = newData[index];
        if (tag === 'group_id') {
            let pattern = this.state.currentPatternOptions.find((item: pattern) => item.quantity_group_id === row[tag]);
            item.pattern = pattern;
        }
        newData.splice(index, 1, { ...item, ...row })
        let display = newData.filter(item => item.name.indexOf(this.state.searchValue || '') >= 0) || [];
        this.setState({
            sensors: [...newData],
            displaySensors: display
        })
        // let data = {
        //     name: row.name,
        //     work_status: row.work_status,
        // }
        let data = {};
        data[tag] = row[tag];

        updateSensor(row.id, data)
            .then(res => { })
            .catch(err => {
                message.error('更新失败');
                newData.splice(index, 1, {
                    ...row,
                    ...item
                })
                item.pattern = null;
                let display = newData.filter(item => item.name.indexOf(this.state.searchValue || '') >= 0) || [];
                this.setState({
                    sensors: [...newData],
                    displaySensors: display
                })
            })
    }

    handleSensorDboxSelectSave = (row: sensor) => {
        let id = row.id;
        let newData = [...this.state.sensors];
        let index = newData.findIndex(item => item.id === id);
        let item: sensor = newData[index];

        newData.splice(index, 1, { ...item, ...row })
        let display = newData.filter(item => item.name.indexOf(this.state.searchValue || '') >= 0) || [];
        this.setState({
            sensors: [...newData],
            displaySensors: display
        })
        let data = {
            dbox_id: row.dbox_id,
            channel: -1,
        }
        updateSensor(row.id, data)
            .then(res => { })
            .catch(err => {
                message.error('更新失败');
                newData.splice(index, 1, {
                    ...row,
                    ...item
                })
                let display = newData.filter(item => item.name.indexOf(this.state.searchValue || '') >= 0) || [];
                this.setState({
                    sensors: [...newData],
                    displaySensors: display
                })
            })
    }

    handleSensorChannelSelectSave = (row: sensor) => {
        let id = row.id;
        let newData = [...this.state.sensors];
        let index = newData.findIndex(item => item.id === id);
        let item: sensor = newData[index];

        newData.splice(index, 1, { ...item, ...row })
        let display = newData.filter(item => item.name.indexOf(this.state.searchValue || '') >= 0) || [];
        this.setState({
            sensors: [...newData],
            displaySensors: display
        })
        let data = {
            channel: row.channel,
        }
        updateSensor(row.id, data)
            .then(res => { })
            .catch(err => {
                message.error('更新失败');
                newData.splice(index, 1, {
                    ...row,
                    ...item
                })
                let display = newData.filter(item => item.name.indexOf(this.state.searchValue || '') >= 0) || [];
                this.setState({
                    sensors: [...newData],
                    displaySensors: display
                })
            })
    }

    handleDboxSave = (row: dbox) => {
        let id = row.id;
        let newData = [...this.state.dboxs];
        let index = newData.findIndex(item => item.id === id);
        let item: dbox = newData[index];

        newData.splice(index, 1, { ...item, ...row })
        let display = newData.filter(item => item.name.indexOf(this.state.searchValue || '') >= 0) || [];
        this.setState({
            dboxs: [...newData],
            displayDboxs: display
        })
        let data = {
            name: row.name,
            work_status: row.work_status,
        }
        updateDbox(row.id, data)
            .then(res => { })
            .catch(err => {
                message.error('更新失败');
                newData.splice(index, 1, {
                    ...row,
                    ...item
                })
                let display = newData.filter(item => item.name.indexOf(this.state.searchValue || '') >= 0) || [];
                this.setState({
                    dboxs: [...newData],
                    displayDboxs: display
                })
            })
    }

    handleSensorManageSave = (row: sensor) => {
        let id = row.id;
        let newData = [...this.state.sensors];
        let index = newData.findIndex(item => item.id === id);
        let item: sensor = newData[index];

        newData.splice(index, 1, { ...item, ...row })
        let display = newData.filter(item => item.name.indexOf(this.state.searchValue || '') >= 0) || [];
        this.setState({
            sensors: [...newData],
            displaySensors: display
        })
        let manage_content = deviceManageStatus[row.manage_status];
        updateSensorManageHistory(row.id, null, {
            manage_status: row.manage_status,
            manage_content: manage_content,
        }).then(res => { }).catch(err => {
            message.error('更新失败');
            newData.splice(index, 1, {
                ...row,
                ...item
            })
            let display = newData.filter(item => item.name.indexOf(this.state.searchValue || '') >= 0) || [];
            this.setState({
                sensors: [...newData],
                displaySensors: display
            })
        })
    }
    handleDboxManageSave = (row: dbox) => {
        let id = row.id;
        let newData = [...this.state.dboxs];
        let index = newData.findIndex(item => item.id === id);
        let item: dbox = newData[index];

        newData.splice(index, 1, { ...item, ...row })
        let display = newData.filter(item => item.name.indexOf(this.state.searchValue || '') >= 0) || [];
        this.setState({
            dboxs: [...newData],
            displayDboxs: display
        })
        let manage_content = deviceManageStatus[row.manage_status];
        updateDboxManageHistory(row.id, null, {
            manage_status: row.manage_status,
            manage_content: manage_content,
        }).then(res => { }).catch(err => {
            message.error('更新失败');
            newData.splice(index, 1, {
                ...row,
                ...item
            })
            let display = newData.filter(item => item.name.indexOf(this.state.searchValue || '') >= 0) || [];
            this.setState({
                dboxs: [...newData],
                displayDboxs: display
            })
        })
    }

    // upload
    handleSensorFigureChange = (info, record: sensor) => {
        console.log(info.file.status);
        if (info.file.status !== 'uploading') {
            this.setState({
                uploading: false
            })
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            let pic: figure = info.file.response.data;
            updateSensor(record.id, {
                figure_url: pic.url
            }).then(res => {
                record.figure_url = pic.url;
                this.setState({
                    sensors: [...this.state.sensors]
                })
            })
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handleDboxFigureChange = (info, record: dbox) => {
        console.log(info.file.status);
        if (info.file.status !== 'uploading') {
            this.setState({
                uploading: false
            })
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            let pic: figure = info.file.response.data;
            updateDbox(record.id, {
                figure_url: pic.url
            }).then(res => {
                record.figure_url = pic.url;
                this.setState({
                    dboxs: [...this.state.dboxs]
                })
            })
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    beforeUpload = (file) => {
        this.setState({
            uploading: true,
        })
        return true;
    }

    showModal = (url) => {
        let currentUrl = `${figureStaticLocation}${url}`
        this.setState({
            currentUrl: currentUrl,
            modelVisible: true,
        });
    };

    handleCancel = e => {
        this.setState({
            modelVisible: false,
            currentUrl: null,
        });
    };

    goToSensorDetail = (record) => {
        let url = `/projects/${this.context}/sensors/${record.id}`;
        this.props.history.push(url);
    }

    onMultiFileChange = (info) => {
        const { status } = info.file;
        if (status !== 'uploading') {
            // console.log(info.file, info.fileList);
            this.setState({
                fileList: [...this.state.fileList, info.file]
            })
        }
        if (status === 'done') {
            console.log(this.state.fileList);
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handleUploadingRemove = (file) => {
        this.setState({
            fileList: this.state.fileList.filter(item => item.uid !== file.uid)
        })
        return true;
    }

    onMultiUploadingCancel = () => {
        if (this.state.onMultiUploadSaveLoading) return;
        this.setState({
            fileList: [],
            onMultiUploadState: false,
        })
    }

    onMultiUploadSave = () => {
        this.setState({
            onMultiUploadSaveLoading: true,
        })
        let pics: figure[] = this.state.fileList.filter(item => item.response).map(item => item.response.data);
        let sensorFiguresPromiseList = [];
        let dboxFiguresPromiseList = [];
        pics.forEach(item => {
            let name = item.name.substring(0, item.name.indexOf('.'));
            let sensor = this.state.sensors.find(item => item.name === name);
            if (!!sensor) {
                sensorFiguresPromiseList.push(updateSensor(sensor.id, {
                    figure_url: item.url
                }))
            }
            let dbox = this.state.dboxs.find(item => item.name === name);
            if (!!dbox) {
                dboxFiguresPromiseList.push(updateDbox(dbox.id, {
                    figure_url: item.url
                }))
            }
        })
        axios.all([sensorFiguresPromiseList, dboxFiguresPromiseList])
            .then(res => {
                this.setState({
                    fileList: [],
                    onMultiUploadState: false,
                    onMultiUploadSaveLoading: false,
                })
                this.generateData();
            }).catch(err => {
                message.error('保存失败');
                console.log(err);
                this.setState({
                    onMultiUploadSaveLoading: false,
                })
            })
    }


    onSensorSelectChange = (selectedRowKeys, selectedRows) => {
        // console.log(selectedRowKeys)
        this.setState({
            sensorIdToManage: [...selectedRowKeys]
        })
    }

    onDboxSelectChange = (selectedRowKeys, selectedRows) => {
        // console.log(selectedRowKeys)
        this.setState({
            dboxIdToManage: [...selectedRowKeys]
        })
    }

    onMultiManageStatusSave = (value) => {
        let sensorManageUpdatePromiseList = this.state.sensorIdToManage.map(id => updateSensorManageHistory(id, null, {
            manage_status: value,
            manage_content: deviceManageStatus[value],
        }));
        let dboxManageUpdatePromiseList = this.state.dboxIdToManage.map(id => updateDboxManageHistory(id, null, {
            manage_status: value,
            manage_content: deviceManageStatus[value],
        }));
        axios.all([sensorManageUpdatePromiseList, dboxManageUpdatePromiseList])
            .then(res => {
                this.generateData();
                this.setState({
                    // onManageEdit: false,
                    sensorIdToManage: [],
                    dboxIdToManage: [],
                })
            })
    }

    // 寻找采集箱未关联的通道
    findDboxBlankChannelList = (dboxId: number) => {
        let dboxDetail: dbox = this.state.dboxs.find(item => item.id === dboxId);
        if (!dboxDetail) {
            return [];
        }
        let channels: number[] = [];
        this.state.sensors.forEach((item: sensor) => {
            if (item.dbox_id === dboxId && !!item.channel) {
                channels.push(item.channel)
            }
        });
        let allChannels: number[] = lodash.range(1, dboxDetail.channel_count + 1);
        let res = allChannels.filter(x => !channels.some(y => y === x));
        return res;
    }

    onGroupCellDoubleClick = (row: sensor) => {
        let mobjectId = row.mobject_id;
        queryMobjectPatternList(mobjectId, {
            project_id: this.context
        }).then(res => {
            let patterns = res.data || [];
            patterns = patterns.filter(item =>
                item.distribution_type === DistributionTypes.SENSOR_DIRECT ||
                item.distribution_type === DistributionTypes.SENSOR_MICRO);
            this.setState({
                currentPatternOptions: patterns
            })
        })
    }

    render() {
        // let displayStyle = this.props.userStore._projectSiteDisplay;
        let { onManageEdit, deviceVariety, onMultiUploadState } = this.state;
        return (
            <div className="site-manage-container">
                <div className="page-header">
                    {!onMultiUploadState && (
                        <Space size="large">
                            <Search
                                placeholder="检索设备编号"
                                value={this.state.searchValue}
                                onChange={(e) => this.setState({ searchValue: e.target.value })}
                                onPressEnter={() => this.handleSearchChange(this.state.searchValue)}
                                onBlur={() => this.handleSearchChange(this.state.searchValue)} />
                            <Radio.Group
                                value={deviceVariety}
                                onChange={() => this.setState({
                                    deviceVariety: deviceVariety === DeviceVarietyLevel.DBOX ? DeviceVarietyLevel.SENSOR : DeviceVarietyLevel.DBOX
                                })}>
                                <Radio.Button value={DeviceVarietyLevel.SENSOR}>
                                    传感器
                                </Radio.Button>
                                <Radio.Button value={DeviceVarietyLevel.DBOX}>
                                    采集箱
                                </Radio.Button>
                            </Radio.Group>
                            {/* <Radio.Group
                        defaultValue={displayStyle}
                        buttonStyle="solid"
                        onChange={this.onDisplayChange}>
                        <Radio.Button value="Card">
                            <IconFont type="icon-qiapianmoshi_kuai" />
                        </Radio.Button>
                        <Radio.Button value="List">
                            <IconFont type="icon-liebiao" />
                        </Radio.Button>
                    </Radio.Group> */}

                        </Space>
                    )}
                    <div className="fill-remaining-space"></div>

                    {!onManageEdit && !onMultiUploadState && (
                        <Space size="large">
                            <Button type="primary" onClick={() => this.setState({ onManageEdit: true })}>编辑</Button>
                            <Button onClick={() => { this.setState({ onMultiUploadState: true }) }}>批量上传照片</Button>
                            <Button onClick={() => this.props.history.push(`/projects/${this.context}/quantity-link`)}>指标关联</Button>
                        </Space>
                    )}
                    {onManageEdit && (
                        <Space>
                            <Button onClick={() => this.onMultiManageStatusSave(DeviceManageStatusEnum.INSTALLED)}>批量安装</Button>
                            <Button onClick={() => this.onMultiManageStatusSave(DeviceManageStatusEnum.CHECKED)}>批量验收</Button>
                            <Button onClick={() => this.onMultiManageStatusSave(DeviceManageStatusEnum.REWORK)}>批量返工</Button>
                            <Button onClick={() => this.onMultiManageStatusSave(DeviceManageStatusEnum.SCRAP)}>批量报废</Button>
                            <Button onClick={() => this.onMultiManageStatusSave(DeviceManageStatusEnum.RECYCLE)}>批量回收</Button>
                            <Button type="link" onClick={() => this.setState({ onManageEdit: false })}>返回</Button>
                        </Space>
                    )}
                    {onMultiUploadState && (
                        <Space size="large">
                            <Button type="primary" onClick={this.onMultiUploadSave} loading={this.state.onMultiUploadSaveLoading}>完成</Button>
                            <Button onClick={this.onMultiUploadingCancel}>取消</Button>
                        </Space>
                    )}

                </div>
                {onMultiUploadState && (
                    <div className="upload-file">
                        <Dragger
                            name="file"
                            multiple={true}
                            action={figureUploadUrl}
                            accept="image/*"
                            onChange={this.onMultiFileChange}
                            onRemove={this.handleUploadingRemove}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">点击或拖拽图片格式文件到此区域以上传</p>
                            <p className="ant-upload-hint">
                                支持一次上传多个文件
                            </p>
                        </Dragger>
                    </div>
                )}
                <div className="main">
                    {deviceVariety === DeviceVarietyLevel.SENSOR && (
                        <Table
                            rowKey="id"
                            pagination={false}
                            components={{
                                body: {
                                    row: EditableRow,
                                    cell: EditableCell,
                                }
                            }}
                            loading={this.state.tableLoading}
                            rowClassName={(record, index) => (
                                'editable-row'
                            )}
                            rowSelection={this.state.onManageEdit && {
                                type: 'checkbox',
                                onChange: this.onSensorSelectChange,
                                selectedRowKeys: this.state.sensorIdToManage
                            }}
                            dataSource={this.state.displaySensors}>
                            <Column
                                title="设备编号"
                                dataIndex="name"
                                width="12%"
                                onCell={(record: sensor, idx) => ({
                                    record,
                                    editable: onManageEdit,
                                    dataIndex: 'name',
                                    title: '设备编号',
                                    handleSave: (e) => this.handleSensorSave(e, 'name'),
                                })} />
                            <Column title="类型" key="type_name" render={record => `${record.type_name}${record.marking}`} />
                            {/* <Column title="型号" dataIndex="marking" /> */}
                            <Column
                                title="所属采集箱"
                                align="center"
                                dataIndex="dbox_id"
                                width="10%"
                                onCell={(record, idx) => ({
                                    record,
                                    editable: onManageEdit,
                                    dataIndex: 'dbox_id',
                                    type: 'Select',
                                    options: this.state.dboxs.map(item => ({ label: item.name, value: item.id })),
                                    validateDisable: true,
                                    title: '所属采集箱',
                                    handleSave: this.handleSensorDboxSelectSave,
                                })}
                                render={record => {
                                    let dbox = this.state.dboxs.find(item => item.id === record);
                                    return dbox?.name || ''
                                }} />
                            <Column
                                title="通道号"
                                align="center"
                                dataIndex="channel"
                                width="8%"
                                onCell={((record: sensor, idx) => ({
                                    record,
                                    editable: onManageEdit,
                                    dataIndex: 'channel',
                                    type: 'Select',
                                    options: record.dbox_id ? this.findDboxBlankChannelList(record.dbox_id).map(item => ({ label: item, value: item })) : [],
                                    validateDisable: true,
                                    title: '通道号',
                                    handleSave: (e) => this.handleSensorSave(e, 'channel'),
                                }))} />
                            <Column
                                title="管理状态"
                                dataIndex="manage_status"
                                width="8%"
                                onCell={(record, idx) => ({
                                    record,
                                    editable: onManageEdit,
                                    dataIndex: 'manage_status',
                                    type: 'Select',
                                    options: paramDict2Array(deviceManageStatus).map(item => ({ value: +item.name, label: item.value })),
                                    title: '管理状态',
                                    handleSave: this.handleSensorManageSave,
                                })}
                                render={(record, idx) => deviceManageStatus[record]} />
                            {/* <Column
                                title="工作状态"
                                dataIndex="work_status"
                                width="5%"
                                onCell={(record, idx) => ({
                                    record,
                                    editable: !onManageEdit,
                                    dataIndex: 'work_status',
                                    type: 'Select',
                                    options: paramDict2Array(sensorWorkStatus).map(item => ({ value: +item.name, label: item.value['value'] })),
                                    title: '工作状态',
                                    handleSave: this.handleSensorSave,
                                })}
                                render={(record, idx) => (
                                    <Tag color={sensorWorkStatus[record]['color']}>{sensorWorkStatus[record]['value']}</Tag>
                                )} /> */}
                            <Column
                                title="监测对象"
                                key="mobject_id"
                                width="10%"
                                onCell={(record, idx) => ({
                                    record,
                                    editable: onManageEdit,
                                    dataIndex: 'mobject_id',
                                    type: 'Select',
                                    options: this.state.mobjects.map(item => ({ value: item.id, label: item.name })),
                                    title: '监测对象',
                                    validateDisable: true,
                                    showSelectSearch: true,
                                    handleSave: (e) => this.handleSensorSave(e, 'mobject_id'),
                                })}
                                render={(record: sensor) => {
                                    let mobject = this.state.mobjects.find(item => record?.mobject_id === item.id);
                                    if (!mobject) return null;
                                    return mobject.name;
                                }}
                            />
                            <Column
                                title="分布模式"
                                key="group_id"
                                width="10%"
                                onCell={(record: sensor, idx) => ({
                                    record,
                                    editable: onManageEdit,
                                    dataIndex: 'group_id',
                                    type: 'Select',
                                    options: this.state.currentPatternOptions.map((item: pattern) => ({ value: item.quantity_group_id, label: item.name })),
                                    title: '分布模式',
                                    validateDisable: true,
                                    handleSave: (e) => this.handleSensorSave(e, 'group_id'),
                                    onDoubleClick: () => this.onGroupCellDoubleClick(record)
                                })}
                                render={(record: sensor) => {
                                    if (!!record.pattern) {
                                        return record.pattern?.name;
                                    }
                                    return null;
                                }}
                            />
                            <Column
                                title="位置"
                                dataIndex="location"
                                width="8%"
                                onCell={(record: sensor, idx) => ({
                                    record,
                                    editable: onManageEdit,
                                    dataIndex: 'location',
                                    title: '位置',
                                    validateDisable: true,
                                    handleSave: (e) => this.handleSensorSave(e, 'location'),
                                })}
                            />
                            {!onManageEdit && (
                                <Column
                                    title="现场照片"
                                    key="figure_url"
                                    align="center"
                                    render={(record, idx) => {
                                        if (record.figure_url) {
                                            return (
                                                <Space>
                                                    <Button size="small" onClick={() => this.showModal(record.figure_url)} >预览</Button>
                                                    <ImgCrop aspect={4 / 3} modalTitle="图片裁剪">
                                                        <Upload name='file'
                                                            disabled={this.state.uploading || onManageEdit}
                                                            showUploadList={false}
                                                            action={figureUploadUrl}
                                                            onChange={(info) => this.handleSensorFigureChange(info, record)}
                                                            beforeUpload={this.beforeUpload}>
                                                            <Button
                                                                disabled={this.state.uploading || onManageEdit}
                                                                type="dashed"
                                                                size="small"
                                                                icon={<UploadOutlined />}>重新上传</Button>
                                                        </Upload>
                                                    </ImgCrop>
                                                </Space>
                                            )
                                        }
                                        return (
                                            <ImgCrop aspect={4 / 3} modalTitle="图片裁剪">
                                                <Upload name='file'
                                                    disabled={this.state.uploading || onManageEdit}
                                                    showUploadList={false}
                                                    action={figureUploadUrl}
                                                    onChange={(info) => this.handleSensorFigureChange(info, record)}
                                                    beforeUpload={this.beforeUpload}>
                                                    <Button
                                                        disabled={this.state.uploading || onManageEdit}
                                                        type="dashed"
                                                        size="small"
                                                        icon={<UploadOutlined />}>上传</Button>
                                                </Upload>
                                            </ImgCrop>
                                        )
                                    }} />
                            )}

                            <Column
                                title=""
                                key="operation"
                                align="center"
                                render={(record) => (
                                    <Button type="link" onClick={() => this.goToSensorDetail(record)}>
                                        详情
                                        <RightOutlined />
                                    </Button>
                                )} />
                        </Table>
                    )}

                    {deviceVariety === DeviceVarietyLevel.DBOX && (
                        <Table
                            rowKey="id"
                            pagination={false}
                            components={{
                                body: {
                                    row: EditableRow,
                                    cell: EditableCell,
                                }
                            }}
                            loading={this.state.tableLoading}
                            rowClassName={(record, index) => (
                                'editable-row'
                            )}
                            rowSelection={this.state.onManageEdit && {
                                type: 'checkbox',
                                onChange: this.onDboxSelectChange,
                                selectedRowKeys: this.state.dboxIdToManage
                            }}
                            dataSource={this.state.displayDboxs}>
                            <Column
                                title="设备编号"
                                dataIndex="name"
                                width="15%"
                                onCell={(record: sensor, idx) => ({
                                    record,
                                    editable: !onManageEdit,
                                    dataIndex: 'name',
                                    title: '设备编号',
                                    handleSave: this.handleDboxSave,
                                })} />
                            <Column title="类别" dataIndex="type_name" />
                            <Column title="型号" dataIndex="marking" />
                            <Column
                                title="管理状态"
                                dataIndex="manage_status"
                                width="15%"
                                onCell={(record, idx) => ({
                                    record,
                                    editable: !onManageEdit,
                                    dataIndex: 'manage_status',
                                    type: 'Select',
                                    options: paramDict2Array(deviceManageStatus).map(item => ({ value: +item.name, label: item.value })),
                                    title: '管理状态',
                                    handleSave: this.handleDboxManageSave,
                                })}
                                render={(record, idx) => deviceManageStatus[record]} />
                            <Column
                                title="工作状态"
                                dataIndex="work_status"
                                width="15%"
                                onCell={(record, idx) => ({
                                    record,
                                    editable: !onManageEdit,
                                    dataIndex: 'work_status',
                                    type: 'Select',
                                    options: paramDict2Array(dboxWorkStatus).map(item => ({ value: +item.name, label: item.value['value'] })),
                                    title: '工作状态',
                                    handleSave: this.handleDboxSave,
                                })}
                                render={(record, idx) => {
                                    return <Tag color={dboxWorkStatus[record]['color']}>{dboxWorkStatus[record]['value']}</Tag>;
                                }}
                            // render={(record, idx) => (
                            //     <Tag color={dboxWorkStatus[record]['color']}>{dboxWorkStatus[record]['value']}</Tag>
                            // )} 
                            />
                            <Column
                                title="现场照片"
                                key="figure_url"
                                align="center"
                                render={(record, idx) => {
                                    if (record.figure_url) {
                                        return (
                                            <Space>
                                                <Button size="small" onClick={() => this.showModal(record.figure_url)} >预览</Button>
                                                <ImgCrop aspect={4 / 3} modalTitle="图片裁剪">
                                                    <Upload name='file'
                                                        disabled={this.state.uploading || onManageEdit}
                                                        showUploadList={false}
                                                        action={figureUploadUrl}
                                                        onChange={(info) => this.handleDboxFigureChange(info, record)}
                                                        beforeUpload={this.beforeUpload}>
                                                        <Button
                                                            disabled={this.state.uploading || onManageEdit}
                                                            type="dashed"
                                                            size="small"
                                                            icon={<UploadOutlined />}>重新上传</Button>
                                                    </Upload>
                                                </ImgCrop>
                                            </Space>
                                        )
                                    }
                                    return (
                                        <ImgCrop aspect={4 / 3} modalTitle="图片裁剪">
                                            <Upload name='file'
                                                disabled={this.state.uploading || onManageEdit}
                                                showUploadList={false}
                                                action={figureUploadUrl}
                                                onChange={(info) => this.handleDboxFigureChange(info, record)}
                                                beforeUpload={this.beforeUpload}>
                                                <Button
                                                    disabled={this.state.uploading || onManageEdit}
                                                    type="dashed"
                                                    size="small"
                                                    icon={<UploadOutlined />}>上传</Button>
                                            </Upload>
                                        </ImgCrop>
                                    )
                                }} />
                            <Column
                                title=""
                                key="operation"
                                align="center"
                                render={(record) => (
                                    <Button type="link" onClick={() => {
                                        this.props.history.push(`/projects/${this.context}/dboxs/${record.id}`)
                                    }}>
                                        详情
                                        <RightOutlined />
                                    </Button>
                                )} />
                        </Table>
                    )}
                </div>

                <Modal
                    title="图片详览"
                    width={900}
                    footer={null}
                    visible={this.state.modelVisible}
                    style={{ top: '20px' }}
                    onCancel={this.handleCancel}
                    destroyOnClose
                >
                    <img width="100%" alt="照片" src={this.state.currentUrl} />
                </Modal>
            </div>
        )
    }
}

export default withRoutePane(SiteManagePage, '现场管理');