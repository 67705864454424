import React, { Component } from 'react'
import { Gprops } from '@/config/props'
import { Form, Input, Select, Button, message } from 'antd'
import './forget.less';
import { FormInstance } from 'antd/lib/form';
import { sendCodeSmsMsg, forgetUserPasswordSubmit } from '@/config/api/user';
import CryptoJS from 'crypto-js';

const { Option } = Select;

interface IState {
    sendCodeLoading: boolean
    timeCount: number
    saveLoading: boolean
}
export default class ForgetPwdPage extends Component<Gprops, IState> {
    state = {
        sendCodeLoading: false,
        timeCount: 0,
        saveLoading: false,
    }
    formRef = React.createRef<FormInstance>();
    timer$: any;

    onSendCodeMsg = () => {
        this.setState({
            sendCodeLoading: true,
        })
        this.formRef.current?.validateFields(['mobile']).then(res => {
            let mobile: string = this.formRef.current?.getFieldValue('mobile');
            return sendCodeSmsMsg(mobile);
        }).then(res => {
            this.setState({
                timeCount: 60,
            })
            this.timer$ = setInterval(() => {
                let count = this.state.timeCount - 1;
                this.setState({
                    timeCount: count,
                })
                if (count <= 0) {
                    clearInterval(this.timer$);
                }
            }, 1000)
        })
            .catch(err => {
                console.log(err);
                return;
            }).finally(() => {
                this.setState({
                    sendCodeLoading: false,
                })
            })
    }

    onSubmit = (values) => {
        this.setState({
            saveLoading: true,
        })
        let data = {
            ...values,
            password: CryptoJS.MD5(values.password).toString(),
        };
        forgetUserPasswordSubmit(data)
        .then(res => {
            this.setState({
                saveLoading: false,
            })
            message.success('密码修改成功！');
            this.props.history.push('/passport/login');
        }).catch(err => {
            message.error('密码修改失败');
            this.setState({
                saveLoading: false,
            })
        })
    }

    render() {
        let { timeCount } = this.state;

        const prefixSelector = (
            <Form.Item name="prefix" noStyle initialValue="86">
                <Select style={{ width: 80 }}>
                    <Option value="86">+86</Option>
                </Select>
            </Form.Item>
        );

        const codeSufix = (
            <Button
                type="link"
                size="small"
                loading={this.state.sendCodeLoading}
                onClick={this.onSendCodeMsg}
                style={{ minWidth: '80px' }}
            >
                {timeCount > 0 ? `${timeCount}s` : '获取验证码'}
            </Button>
        )

        return (
            <div className="forget-pwd-container">
                <h3 className="title">密码找回</h3>
                <Form autoComplete="off" ref={this.formRef} onFinish={this.onSubmit}>
                    <Form.Item name="username" rules={[{ required: true, message: '用户名不能为空' }]}>
                        <Input placeholder="输入用户名" />
                    </Form.Item>
                    <Form.Item
                        name="mobile"
                        rules={[{ required: true, message: '手机号不能为空' }, {len: 11, message: '手机号格式不正确'}]}
                    >
                        <Input addonBefore={prefixSelector} style={{ width: '100%' }} placeholder="手机号" />
                    </Form.Item>
                    <Form.Item name="code" rules={[{ required: true, len: 6, message: '验证码格式不正确' }]}>
                        <Input style={{ width: '100%' }} placeholder="验证码" addonAfter={codeSufix} />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: '密码不能为空' }, {min: 6, message: '密码不能小于6位'}]}>
                        <Input.Password placeholder="密码" />
                    </Form.Item>
                    <Form.Item
                        name="confirm_password"
                        rules={[
                            {
                                required: true,
                                message: '确认密码不能为空',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('两次密码不一致');
                                },
                            }),
                        ]}
                        dependencies={['password']}
                        hasFeedback>
                        <Input.Password placeholder="再输一次密码" />
                    </Form.Item>
                    <div className="submit-group">
                        <Button type="primary" htmlType="submit" className="submit-button" loading={this.state.saveLoading}>
                            提交
                        </Button>
                        <Button type="link" onClick={() => this.props.history.push(`/passport/login`)}>
                            使用已有账户登录
                        </Button>
                    </div>
                    <Form.Item>


                    </Form.Item>
                </Form>
            </div>
        )
    }
}
