import axios from "../axios"
import { AxiosResponse } from "axios"

export function queryConstages(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/constages`, {
        params: params
    })
}

export function queryConstageDetail(projectId: number, stageId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/constages/${stageId}`, {
        params: params
    })
}

export function createConstage(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/projects/${projectId}/constages`, data, {
        params: params
    })
}

export function createConstageFigures(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/projects/${projectId}/constages/figures`, data, {
        params: params
    })
}

export function updateConstage(projectId: number, stageId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/projects/${projectId}/constages/${stageId}`, data, {
        params: params
    })
}

export function deleteConstage(projectId: number, stageId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`/projects/${projectId}/constages/${stageId}`, {
        params: params
    })
}

export function queryDrawings(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/drawings`, {
        params: params
    })
}

export function queryDrawingsByConstage(projectId: number, constageId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/constages/${constageId}/drawings`, {
        params: params
    })
}

export function queryMobjectsByDrawingId(projectId: number, drawingId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/drawings/${drawingId}/mobjects`, {
        params: params
    })
}

export function LinkMobjectsByDrawingId(projectId: number, drawingId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/projects/${projectId}/drawings/${drawingId}/mobjects`, data, {
        params: params
    })
}

export function createDrawingsInConstage(projectId: number, constageId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/projects/${projectId}/constages/${constageId}/drawings`, data, {
        params: params
    })
}

export function createDrawings(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/projects/${projectId}/drawings`, data, {
        params: params
    })
}

export function updateDrawing(projectId: number, drawingId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/projects/${projectId}/drawings/${drawingId}`, data, {
        params: params
    })
}

export function deleteDrawing(projectId: number, drawingId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`/projects/${projectId}/drawings/${drawingId}`, {
        params: params
    })
}