import React, { Component } from 'react'
import { withRoutePane } from '@/components/hoc/routePane';
import './standard.less';
import { Row, Col } from 'antd';
import ProcedureTable from '@/components/standard/procedure-table';
import StandardStatistic from '@/components/standard/standard-statistic';

class StandardPage extends Component {


    render() {
        return (
            <div className="project-standard-container">
                <Row gutter={10}>
                    <Col xs={24} xl={17}>
                        <ProcedureTable/>
                    </Col>
                    <Col xs={24} xl={7}> 
                        <StandardStatistic/>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withRoutePane(StandardPage, '规范依据');