import React, { Component } from 'react'
import './enterprise-list.less';
import { Space, Button, Table, Form, Input, Upload, Modal, message } from 'antd';
import { queryEnterprises, createEnterprise } from '@/config/api/enterprise';
import { enterprise } from '@/interface/enterprise';
import { EditableRow, EditableCell } from '@/components';
import ImgCrop from 'antd-img-crop';
import { PlusOutlined } from '@ant-design/icons';
import { figureUploadUrl } from '@/config/api/document';

const { Column } = Table;
interface IProps {

}
interface IState {
    enterprises: enterprise[],
    isAddState: boolean

    fileList: any[]
    previewVisible: boolean
    previewImage: string
    loading: boolean
}
export default class EnterpriseListPage extends Component<IProps, IState> {

    state = {
        enterprises: [],
        isAddState: false,

        fileList: [],
        previewVisible: false,
        previewImage: '',
        loading: false,
    }
    componentDidMount() {
        this.findEnterprises();
    }

    findEnterprises = () => {
        queryEnterprises()
            .then(res => {
                this.setState({
                    enterprises: res.data || []
                })
            })
    }

    // ============== upload figures ================
    normFile = e => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        this.setState({
            previewImage: file.url,
            previewVisible: true,
        });
    };

    handleChange = ({ file, fileList }) => {
        this.setState({
            fileList: fileList
        })
    }

    onSave = (values) => {

        // if (this.state.fileList.length === 0) {
        //     message.warning('未上传logo');
        //     return;
        // }
        this.setState({
            loading: true,
        })
        let logo = this.state.fileList.length > 0 ? this.state.fileList[0].response.data?.url : null;
        let data = {
            name: values.name,
            logo: logo
        }
        createEnterprise(data)
        .then(res => {
            this.setState({
                isAddState: false,
                fileList: [],
                loading: false,
            })
            this.findEnterprises();
        }).catch(err => {
            message.error('保存失败');
            console.log(err);
            this.setState({
                loading: false,
            })
        })
    }

    render() {
        let { isAddState } = this.state;
        return (
            <div className="enterprise-list-container">
                <div className="page-header">
                    {!isAddState && (
                        <Space>
                            <Button type="primary" onClick={() => this.setState({ isAddState: true })}>添加</Button>
                        </Space>
                    )}
                </div>
                {isAddState && (
                    <div className="add-form">
                        <Form onFinish={this.onSave} labelCol={{span: 2}} wrapperCol={{span: 10}}>
                            <Form.Item label="企业名称" name="name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="fileList"
                                label="上传logo"
                                valuePropName="fileList"
                                getValueFromEvent={this.normFile}
                            >
                                <ImgCrop aspect={2 / 1} modalTitle="图片裁剪">
                                    <Upload
                                        action={figureUploadUrl}
                                        listType="picture-card"
                                        fileList={this.state.fileList}
                                        onPreview={this.handlePreview}
                                        onChange={this.handleChange}
                                    >
                                        {this.state.fileList.length >= 1 ? null : (
                                            <div>
                                                <PlusOutlined />
                                                <div className="ant-upload-text">Upload</div>
                                            </div>
                                        )}
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                            <div className="operations">
                                <Space>
                                    <Button type="primary" htmlType="submit" loading={this.state.loading}>保存</Button>
                                    <Button onClick={() => this.setState({ isAddState: false })}>取消</Button>
                                </Space>
                            </div>
                        </Form>
                    </div>
                )}
                <div className="main">
                    <Table
                        rowKey="id"
                        pagination={false}
                        components={{
                            body: {
                                row: EditableRow,
                                cell: EditableCell,
                            }
                        }}
                        rowClassName={(record, index) => (
                            'editable-row'
                        )}
                        dataSource={this.state.enterprises}>
                        <Column title="编号" dataIndex="id" />
                        <Column title="名称" dataIndex="name" />
                        <Column title="logo" key="logo" />
                    </Table>
                </div>

                <Modal
                    title="图片详览"
                    width={800}
                    footer={null}
                    visible={this.state.previewVisible}
                    //   onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <img width="100%" alt="照片" src={this.state.previewImage} />
                </Modal>
            </div>
        )
    }
}
