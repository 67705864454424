import axios from "../axios"
import { AxiosResponse } from "axios"

export function queryProjectPeople(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`projects/${projectId}/people`, {
        params: params
    })
}

export function createProjectPeople(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`projects/${projectId}/people`, data, {
        params: params
    })
}

export function updateProjectPeople(projectId: number, peopleId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`projects/${projectId}/people/${peopleId}`, data, {
        params: params
    })
}

export function deleteProjectPeople(projectId: number, peopleId: number): Promise<AxiosResponse<any>> {
    return axios.delete(`projects/${projectId}/people/${peopleId}`)
}

export function queryProjectSchedules(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`projects/${projectId}/schedules`, {
        params: params
    })
}

export function createProjectSchedule(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`projects/${projectId}/schedules`, data, {
        params: params
    })
}

export function updateProjectSchedule(projectId: number, scheduleId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`projects/${projectId}/schedules/${scheduleId}`, data, {
        params: params
    })
}

export function deleteProjectSchedule(projectId: number, scheduleId: number): Promise<AxiosResponse<any>> {
    return axios.delete(`projects/${projectId}/schedules/${scheduleId}`)
}

export function queryProjectPlanbs(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`projects/${projectId}/planb`, {
        params: params
    })
}

export function createProjectPlanb(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`projects/${projectId}/planb`, data, {
        params: params
    })
}

export function updateProjectPlanb(projectId: number, planbId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`projects/${projectId}/planb/${planbId}`, data, {
        params: params
    })
}

export function deleteProjectPlanb(projectId: number, planbId: number): Promise<AxiosResponse<any>> {
    return axios.delete(`projects/${projectId}/planb/${planbId}`)
}