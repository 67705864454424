import axios, { StdResponse } from "../axios"
import { AxiosResponse } from "axios"

export function queryUsers(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/users`, {
        params: params
    })
}

export function createUsers(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/users`, data, {
        params: params
    })
}

export function queryUserDetail(userId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/users/${userId}`, {
        params: params
    })
}

export function updateUser(userId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/users/${userId}`, data, {
        params: params
    })
}

export function deleteUser(userId: number,): Promise<AxiosResponse<any>> {
    return axios.delete(`/users/${userId}`)
}

export function queryUsersProjects(userId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/users/${userId}/projects`, {
        params: params
    })
}

export function linkUsersProjects(userId: number, projectId: number): Promise<AxiosResponse<any>> {
    return axios.post(`/users/${userId}/projects`, null, {
        params: {
            project_id: projectId
        }
    })
}

export function releaseUsersProjects(userId: number, projectId: number): Promise<AxiosResponse<any>> {
    return axios.delete(`/users/${userId}/projects`, {
        params: {
            project_id: projectId
        }
    })
}

export function userLogin(data: any, params?: {}): Promise<StdResponse<any>> {
    return axios.post(`/users/login`, data, {
        params: params
    })
}

export function userCheckLogin(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/users/check-login`, {
        params: params
    })
}

export function modifyUserPwd(data: any, params?: {}): Promise<StdResponse<any>> {
    return axios.post(`/users/password`, data, {
        params: params
    })
}

export function sendCodeSmsMsg(mobile: string): Promise<StdResponse<any>> {
    return axios.post(`/users/code`, null, {
        params: {
            mobile: mobile,
        }
    })
}

export function forgetUserPasswordSubmit(data: any, params?: {}): Promise<StdResponse<any>> {
    return axios.post(`/users/password/forget`, data, {
        params: params
    })
}