import React, { Component } from 'react'
import { Chart } from '@antv/g2'

interface IProps {
    data: any[]
    height?: number
}
export default class IntervalChart extends Component<IProps> {

    intervalChart: Chart;

    componentDidMount() {
        const chart = new Chart({
            container: 'container',
            // width: 500,
            height: this.props.height || 300,
            autoFit: true,
        });
        // chart.data(this.props.data);
        let data = [...this.props.data];
        chart.data(data);
        chart.scale({
            value: {
                nice: true,
                alias: '数量'
            },
            type: {
                alias: '指标区间统计'
            }
        });
        chart.axis('type', {
            tickLine: null,
            title: {
                style: {
                    fontSize: 14
                }
            },
          });
        // chart.axis('value', false);

        chart.tooltip({
          showMarkers: false,
        });

        chart
        .interval()
        .position('type*value')
        .size(30);
        chart.interaction('active-region');
        this.intervalChart = chart;
        chart.render();
    }

    componentDidUpdate() {
        if (this.intervalChart) {
            const data = this.props.data;
            this.intervalChart.changeData(data);
        }
    }
    render() {
        return (
            <div id="container"></div>
        )
    }
}
