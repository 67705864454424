import React, { Component } from 'react'
import './constage-add.less';
import { Space, Button, Form, Input, Upload, message, Modal } from 'antd';
import { DatePicker } from '..';
import ImgCrop from 'antd-img-crop';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { createConstage } from '@/config/api/constage';
import { ProjectContext } from '@/config/context';
import { figureUploadUrl } from '@/config/api/document';

const { TextArea } = Input;
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

interface IProps {
    onCancel?: () => void,
    onSave?: () => void,
}

interface IState {
    fileList: any[]
    previewVisible: boolean
    previewImage: string
    loading: boolean
}
export default class ConstageAdd extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        fileList: [],
        previewVisible: false,
        previewImage: '',
        loading: false,
    }
    // ============== upload figures ================
    normFile = e => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange = ({ file, fileList }) => {
        this.setState({
            fileList: fileList
        })
    }

    onSave = (values) => {
        this.setState({
            loading: true,
        })
        let figures = this.state.fileList.filter(item => item.response).map(item => item.response.data) || [];
        let data = {
            name: values.name,
            code: values.code,
            content: values.content,
            start_time: dayjs(values.start_time).valueOf(),
            end_time: dayjs(values.end_time).valueOf(),
            figures: figures
        }
        createConstage(this.context, data)
        .then(res => {
            this.setState({
                loading: false,
            })
            this.props.onSave();
        }).catch(err => {
            message.error('更新失败');
            console.log(err);
            this.setState({
                loading: false,
            })
        })
    }

    render() {
        return (
            <div className="constage-add-container">
                <Form labelCol={{ span: 3 }} onFinish={this.onSave} autoComplete="off">
                    <div className="header">
                        <div className="title">新建施工步</div>
                        <Space>
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>保存</Button>
                            <Button onClick={this.props.onCancel}>取消</Button>
                        </Space>
                    </div>
                    <div className="add-content">
                        <Form.Item label="施工步编号" name="code" rules={[{ required: true }]}>
                            <Input placeholder="如：CS01/LS01等" />
                        </Form.Item>
                        <Form.Item label="名称" name="name" rules={[{ required: true }]}>
                            <Input placeholder="如：卸载" />
                        </Form.Item>
                        <Form.Item label="内容描述" name="content" rules={[{ required: true }]}>
                            <TextArea rows={4} />
                        </Form.Item>
                        <Form.Item label="起始时间" name="start_time" rules={[{ required: true }]}>
                            <DatePicker showTime />
                        </Form.Item>
                        <Form.Item label="结束时间" name="end_time" rules={[{ required: true }]}>
                            <DatePicker showTime />
                        </Form.Item>

                        <Form.Item
                            name="fileList"
                            label="上传示意图"
                            valuePropName="fileList"
                            getValueFromEvent={this.normFile}
                        >
                            <ImgCrop aspect={16 / 9} modalTitle="图片裁剪">
                                <Upload
                                    action={figureUploadUrl}
                                    listType="picture-card"
                                    fileList={this.state.fileList}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleChange}
                                >
                                    {this.state.fileList.length >= 3 ? null : (
                                        <div>
                                            <PlusOutlined />
                                            <div className="ant-upload-text">Upload</div>
                                        </div>
                                    )}
                                </Upload>
                            </ImgCrop>
                        </Form.Item>
                    </div>
                </Form>
                <Modal
                    title="图片详览"
                    width={800}
                    footer={null}
                    visible={this.state.previewVisible}
                    //   onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <img width="100%" alt="照片" src={this.state.previewImage} />
                </Modal>
            </div>
        )
    }
}
