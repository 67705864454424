import React, { Component } from 'react'
import { Row, Col } from 'antd'
import { EvaluateMainBoard, EvaluateSensorBoard } from '@/components'
import './dashboard-evaluate.less';
import { quantity } from '@/interface/quantity';
import { inject, observer } from 'mobx-react';
import { RProps } from '@/config/props';

interface IProps extends RProps {
    onMainBtnClick: () => void
}

interface IState {
    selectedQuantity: quantity
}

@inject('routeStore')
@observer
export default class DashboardEvaluatePage extends Component<IProps, IState> {
    state = {
        selectedQuantity: null,
    }
    constructor(props) {
        super(props);
        this.props.routeStore.addRoutePane(`指标评估`, this.props.match.url);
    }
    render() {

        let {selectedQuantity} = this.state;
        return (
            <Row gutter={10} className="dashboard-evaluate-container">
                <Col span={!!selectedQuantity ? 18 : 24}>
                    <EvaluateMainBoard 
                    onMainBtnClick={this.props.onMainBtnClick}
                    onRowSelectClick={(row) => this.setState({selectedQuantity: row})} 
                    rowSelected={this.state.selectedQuantity}/>
                </Col>
                <Col span={!!selectedQuantity ? 6: 0}>
                    <EvaluateSensorBoard selectedQuantity={this.state.selectedQuantity}/>
                </Col>
            </Row>
        )
    }
}
