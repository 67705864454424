import { qDataBase } from "@/interface/quantity";
import React, { Fragment } from "react";
import { handleQuantityName } from "@/utils/utils";
import { InlineMath } from 'react-katex';
interface IProps {
    record: qDataBase,
    showChineseName?: boolean
}

export const QuantityName: React.FC<IProps> = ({
    record,
    showChineseName,
    ...restProps
}) => {
    if (!record) {
        return null;
    }
    if(showChineseName == null) {
        showChineseName = true;
    }
    if (record?.location && record?.location !== '') {
        return (
            <Fragment>
                {!!showChineseName && (
                    <span>{record?.chinese_name}</span>
                )}
                <InlineMath math={handleQuantityName(record?.name)} />
                <span>-{record?.location}</span>
            </Fragment>
        )
    } else {
        return (
            <Fragment>
                {!!showChineseName && (
                    <span>{record?.chinese_name}</span>
                )}
                <InlineMath math={handleQuantityName(record?.name)} />
            </Fragment>
        )
    }
};