import React, { Component } from 'react'
import './index.less'
import { Layout, Button, Modal } from 'antd';
import GlobalMenu from './global-menu';
import { GlobalRouteList } from '@/config/route-list';
import GRouter from './groute';
import { UserDropdown } from '@/components';
import { withRouter } from 'react-router-dom';
import { Gprops } from '@/config/props';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';
import { queryEnterprisesDetail } from '@/config/api/enterprise';
import { enterprise } from '@/interface/enterprise';
import {
    PlayCircleOutlined,
} from '@ant-design/icons'
import { Player } from 'video-react';

const { Header, Content, Sider } = Layout;


interface IProps extends Gprops {
    userStore?: UserStore
}

interface IState {
    enterpriseDetail: enterprise
    logo: string
    videoOpen: boolean
}

@inject('userStore')
@observer
class GlobalLayout extends Component<IProps, IState> {
    enterpriseId: number;
    state = {
        enterpriseDetail: null,
        logo: '',
        videoOpen: false,
    }

    componentDidMount() {
        let eid = this.props.userStore._enterpriseId;
        this.enterpriseId = eid;
        this.findEnterprise();
    }

    componentDidUpdate() {
        let eid = this.props.userStore._enterpriseId;
        if (eid !== this.enterpriseId) {
            this.enterpriseId = eid;
            this.findEnterprise();
        }
    }

    downloadHelpFile = () => {
        const link = document.createElement('a');
        const filename = '同恩在线监测系统V2.0使用手册.pdf';
        link.setAttribute('href', 'http://monix.tncet.com:9770/api/static/docs/同恩在线监测系统V2.0使用手册.pdf');
        link.setAttribute('download', filename);
        link.setAttribute('target', '_blank');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    findEnterprise = () => {
        if (!this.enterpriseId) return;
        queryEnterprisesDetail(this.enterpriseId)
            .then(res => {
                this.setState({
                    enterpriseDetail: res.data?.enterprise,
                    logo: res.data?.enterprise?.logo,
                })
            })
    }

    render() {
        let eid = this.props.userStore._enterpriseId;
        let { enterpriseDetail } = this.state;
        return (
            <Layout className="global-container">
                <div style={{ display: 'none' }}>{eid}</div>
                <Header className="header">
                    <div className="logo" onClick={() => this.props.history.push(`/projects`)}>
                        <img src="/assets/logo-white.png" alt="tncet" height="40px" />
                    </div>
                    <div className="title">
                        {enterpriseDetail?.name}
                    </div>
                    <div className="fill-remaining-space"></div>

                    <div className="right">
                        <div>
                            <Button
                                title='宣传视频'
                                type="link"
                                icon={<PlayCircleOutlined style={{ 'fontSize': '18px', 'color': 'white' }} />}
                                className='video-btn'
                                onClick={() => {
                                    this.setState({
                                        videoOpen: true
                                    })
                                }}></Button>
                        </div>
                        <Button
                            type="link"
                            size="large"
                            icon={<QuestionCircleOutlined />}
                            className="action"
                            ghost
                            onClick={this.downloadHelpFile}
                        >
                            <span>帮助</span>
                        </Button>
                        <UserDropdown
                            showProjectList={false}
                            showUserInfo
                            onUserSettingClick={() => this.props.history.push(`/global/settings/user`)} />
                    </div>
                </Header>
                <Layout>
                    <Sider theme="light" className="global-sider" width={200}>
                        <GlobalMenu />
                    </Sider>
                    <Content className="global-content">
                        <div className="page-route">
                            <GRouter routeList={GlobalRouteList} />
                        </div>
                    </Content>
                </Layout>

                <Modal
                    visible={this.state.videoOpen}
                    footer={null}
                    title={null}
                    // style={{
                    //     height: '100vh',
                    //     width: '100vw',
                    // }}
                    width="1000px"
                    centered={false}
                    onCancel={() => { this.setState({
                        videoOpen: false
                    }) }}
                    destroyOnClose
                // closable={false}
                >
                    <Player
                        src="/api/static/docs/监测宣传视频.mp4" />
                </Modal>
            </Layout>
        )
    }
}

export default withRouter(GlobalLayout);