
export interface IFRouteBase {
    url: string,
    component: string,
    alias?: string,
    exact?: boolean
}


const routeList: IFRouteBase[] = [
    // {url: '/dashboard', component: 'Dashboard'},
    // {url: '/dashboard', component: 'DashboardDark'},
    {url: '/evaluations', component: 'DashboardEvaluatePage'},
    {url: '/information', component: 'ProjectInfo'},
    {url: '/standards', component: 'StandardPage'},
    {url: '/conditions', component: 'SiteCondition'},
    {url: '/sensors/:sensorId', component: 'SensorDetailPage'},
    {url: '/sensors', component: 'SensorListPage'},
    {url: '/dboxs/:dboxId', component: 'DboxDetailPage'},
    {url: '/dboxs', component: 'DboxListPage'},
    {url: '/mobjects/joints', component: 'MobjectPage'},
    {url: '/mobjects/members', component: 'MobjectPage'},
    {url: '/mobjects/structures', component: 'MobjectPage'},
    {url: '/mobjects/environments', component: 'MobjectPage'},
    {url: '/quantities/limits/table', component: 'QuantityLimitPage'},
    {url: '/models/nodes', component: 'NodePage'},
    {url: '/models/elements', component: 'ElementPage'},
    {url: '/models/sections/sid/:sid', component: 'SectionDetail'},
    {url: '/models/sections', component: 'SectionPage'},
    {url: '/models/materials/sid/:sid', component: 'MaterialDetail'},
    {url: '/models/materials', component: 'MaterialPage'},
    {url: '/constages/:stageId', component: 'ConstageDetail'},
    {url: '/constages', component: 'ConstageListPage'},
    {url: '/drawings', component: 'DrawingListPage'},
    {url: '/setting/quantities', component: 'SettingQuantityPage'},
    {url: '/setting/clients', component: 'SettingClientPage'},
    {url: '/setting', component: 'SettingProjectPage'},
    {url: '/logs', component: 'ProjectLogPage'},
    {url: '/sites', component: 'SiteManagePage'},
    {url: '/quantity-link', component: 'QuantityLinkPage'},
    {url: '/quantity-query', component: 'QuantityQueryPage'},
    {url: '/warnings', component: 'ProjectWarningPage'},
    {url: '/reports', component: 'ReportPage'},
]

export default routeList;

export const GlobalRouteList: IFRouteBase[] = [
    {url: '/projects', component: 'ProjectListPage'},
    {url: '/global/quantities', component: 'GlobalQuantityPage'},
    {url: '/global/sections', component: 'GlobalSectionPage'},
    {url: '/global/materials', component: 'GlobalMaterialPage'},
    {url: '/global/devices', component: 'GlobalDevicePage'},
    {url: '/global/installs', component: 'GlobalInstallPage'},
    {url: '/global/standards', component: 'GlobalStandardPage'},
    {url: '/global/planbs', component: 'GlobalPlanbPage'},
    {url: '/enterprise', component: 'GlobalEnterprisePage'},
    {url: '/global/settings/user', component: 'SettingUserPage'},
    {url: '/global/settings/pwd', component: 'SettingPasswordPane'},

]

export const BackendRouteList: IFRouteBase[] = [
    {url: '/enterprises', component: 'EnterpriseListPage'},
    {url: '/users', component: 'BackendUserListPage'},
]