import React, { Component } from 'react'
import { globalSensor } from '@/interface/sensor'
import { queryGlobalSensorsInDboxs } from '@/config/api/global-device'
import { Table } from 'antd'
import './global-dbox-sensor.less';
const {Column} = Table;
interface IProps {
    dboxMarking: string
}
interface IState {
    gSensors: globalSensor[]
}
export default class GlobalDboxSensorPanel extends Component<IProps, IState> {

    state = {
        gSensors: [],
    }

    componentDidMount() {
        queryGlobalSensorsInDboxs(this.props.dboxMarking)
            .then(res => {
                this.setState({
                    gSensors: res.data || [],
                })
            })
    }

    render() {
        return (
            <div className="show-sensor-modal">
                <Table
                    dataSource={this.state.gSensors}
                    rowKey="type"
                    bordered
                    pagination={false}>
                    <Column title="类型" dataIndex="name" width="20%" />
                    <Column title="型号" dataIndex="marking" width="20%" />
                    <Column title="厂家" dataIndex="manufacturer" />
                    <Column title="量程" dataIndex="range" width="15%" />
                    <Column title="精度" dataIndex="precision" width="15%" />
                </Table>
            </div>
        )
    }
}
