import React, { Component } from 'react'
import './device-figure.less';
import { EyeOutlined, UploadOutlined } from '@ant-design/icons';
import { Upload, message, Spin, Modal } from 'antd';
import classnames from 'classnames';
import ImgCrop from 'antd-img-crop';
import { figureStaticLocation } from '@/config/api/document';
import { transferFigPathToThumb } from '@/utils/utils';
interface IProps {
    uploadUrl: string
    imgUrl?: string
    afterUpload?: (url: string) => void
}

interface IState {
    uploading: boolean
    modelVisible: boolean
}

export default class DeviceFigure extends Component<IProps, IState> {

    state = {
        uploading: false,
        modelVisible: false,
    }

    handleChange = (info) => {
        console.log(info.file.status);
        if (info.file.status !== 'uploading') {
            this.setState({
                uploading: false
            })
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            if (this.props.afterUpload) {
                this.props.afterUpload(info.file.response.data.url);
            }
            // message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    beforeUpload = (file) => {
        this.setState({
            uploading: true,
        })
        return true;
    }

    showModal = () => {
        this.setState({
            modelVisible: true,
        });
    };

    handleCancel = e => {
        this.setState({
            modelVisible: false,
        });
    };

    render() {
        return (
            <div className="device-figure-container">
                <div className="figure-card">
                    <div className={classnames({
                        'figure-card-item': true,
                        'uploading': this.state.uploading
                    })}>
                        {!!this.props.imgUrl && (
                            <img width="100%" height="100%" alt="照片" src={`${figureStaticLocation}${transferFigPathToThumb(this.props.imgUrl)}`} />
                        )}

                    </div>
                    <div className={classnames({
                        'uploading': this.state.uploading,
                        'spin': true,
                    })}>
                        <Spin />
                    </div>
                    <div className="figure-card-item-actions">
                        <EyeOutlined className="eye" onClick={this.showModal} />
                        <ImgCrop aspect={4 / 3} modalTitle="图片裁剪">
                            <Upload name='file'
                                showUploadList={false}
                                action={this.props.uploadUrl}
                                onChange={this.handleChange}
                                beforeUpload={this.beforeUpload}>
                                <UploadOutlined className="upload" />
                            </Upload>
                        </ImgCrop>
                        <Modal
                            title="图片详览"
                            width={900}
                            footer={null}
                            visible={this.state.modelVisible}
                            //   onOk={this.handleOk}
                            onCancel={this.handleCancel}
                        >
                            <img width="100%" alt="照片" src={`${figureStaticLocation}${this.props.imgUrl}`} />
                        </Modal>
                    </div>

                </div>
            </div>
        )
    }
}
