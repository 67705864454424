import React, { Component } from 'react'
import { Layout } from 'antd';
import './index.less';
import BRouter from './broute';
import { BackendRouteList } from '@/config/route-list';
import BackendMenu from './backend-menu';
import { UserDropdown } from '@/components';
const { Content, Sider, Header } = Layout;

export default class BackendLayout extends Component {
    render() {
        return (

            <Layout className="backend-container">
                <Header className="header">
                    <div className="logo">
                        <img src="/assets/logo-white.png" alt="tncet" height="40px" />
                    </div>
                    <div className="fill-remaining-space"></div>
                    <div className="right">
                        <UserDropdown showProjectList={false}/>
                    </div>
                </Header>
                <Layout>
                    <Sider theme="light" className="global-sider" width={200}>
                        <BackendMenu />
                    </Sider>
                    <Content className="global-content">
                        <div className="page-route">
                            <BRouter routeList={BackendRouteList} />
                        </div>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}
