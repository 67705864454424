import React, { Component } from 'react'
import './global-enterprise.less';
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';
import { enterprise } from '@/interface/enterprise';
import { queryEnterprisesDetail, updateEnterprise } from '@/config/api/enterprise';
import { LoadingOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Upload, message, Modal, Table, Input, Select, Button, Space, Form } from 'antd';
import { figureUploadUrl, figureStaticLocation } from '@/config/api/document';
import ImgCrop from 'antd-img-crop';
import { user } from '@/interface/user';
import { queryUsers, updateUser, createUsers, deleteUser } from '@/config/api/user';
import axios from '@/config/axios';
import { UserTypeEnum } from '../login/login';
import CryptoJS from 'crypto-js';
import { EditableRow, EditableCell } from '@/components';
import { UserTypes } from '@/utils/type';
const { confirm } = Modal;
const { Column } = Table;
const { Search } = Input;
const { Option } = Select;
interface IProps {
    userStore?: UserStore
}


interface IState {
    enterpriseDetail: enterprise
    iconLoading: boolean
    logo: string

    users: user[],

    onManageState: boolean,
    onAddState: boolean,
    clientIdSelected: number[],

    searchInputValue: string,

    onUserAddSaveLoading: boolean
    onUserDeleteSaveLoading: boolean
}

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

@inject('userStore')
@observer
export default class GlobalEnterprisePage extends Component<IProps, IState> {
    enterpriseId: number;
    state = {
        enterpriseDetail: null,
        iconLoading: false,
        logo: '',

        users: [],

        onManageState: false,
        onAddState: false,
        clientIdSelected: [],

        searchInputValue: '',

        onUserAddSaveLoading: false,
        onUserDeleteSaveLoading: false,
    }
    componentDidMount() {
        let eid = this.props.userStore._enterpriseId;
        this.enterpriseId = eid;
        this.findEnterprise();
        this.findUsers();
    }

    componentDidUpdate() {
        let eid = this.props.userStore._enterpriseId;
        if (eid !== this.enterpriseId) {
            this.enterpriseId = eid;
            this.findEnterprise();
            this.findUsers();
        }
    }

    findEnterprise = () => {
        if (!this.enterpriseId) return;
        queryEnterprisesDetail(this.enterpriseId)
            .then(res => {
                this.setState({
                    enterpriseDetail: res.data?.enterprise,
                    logo: res.data?.enterprise?.logo,
                })
            })
    }

    findUsers = () => {
        if (!this.enterpriseId) return;
        let value = this.state.searchInputValue;
        // 检索姓名
        let queryUsersPromise1 = queryUsers({
            enterprise_id: this.enterpriseId,
            search_name: value,
        });
        // 检索公司名
        let queryUsersPromise2 = queryUsers({
            enterprise_id: this.enterpriseId,
            search_company: value,
        });
        axios.all([queryUsersPromise1, queryUsersPromise2])
            .then(res => {
                let clients1: user[] = res[0].data || [];
                let clients2: user[] = res[1].data || [];
                // 交集
                let mixes = clients2.filter(x => clients1.some(y => y.id === x.id));
                // 差集
                let cuts = clients2.filter(x => !mixes.some(y => y.id === x.id));
                // 并集
                let users = clients1.concat(cuts);
                users = users.filter(item => item.type !== UserTypeEnum.ROOT);
                this.setState({
                    users: users
                })
            })
    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ iconLoading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            let logo = info.file.response?.data.url;
            updateEnterprise(this.enterpriseId, {
                logo: logo
            }).then(res => {
                getBase64(info.file.originFileObj, imageUrl =>
                    this.setState({
                        logo: imageUrl,
                        iconLoading: false,
                    }),
                );
            }).catch(err => {
                this.setState({
                    iconLoading: false,
                })
            })

        }
    };

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            clientIdSelected: [...selectedRowKeys]
        })
    }

    onSearchContentChange = (value) => {
        this.findUsers();
    }

    onSearchInputChange = (e) => {
        this.setState({
            searchInputValue: e.target.value
        })
    }

    handleCellSave = (row: user, tag: string) => {
        let id = row.id;
        // let key = row.key;
        let newData = [...this.state.users];
        let index = newData.findIndex(item => item.id === id);
        let item: user = newData[index];

        newData.splice(index, 1, { ...item, ...row })
        this.setState({
            users: [...newData]
        })
        let data = {};
        data[tag] = row[tag];
        updateUser(row.id, data)
            .then(res => { })
            .catch(err => {
                message.error('更新失败');
                newData.splice(index, 1, { ...row, ...item })
                this.setState({
                    users: [...newData]
                })
            })
    }

    onUserAddSave = (values) => {
        if (!this.enterpriseId) {
            message.error('无法获取企业id');
            return;
        }
        this.setState({
            onUserAddSaveLoading: true,
        })
        let password = CryptoJS.MD5(values.password).toString();
        let data = {
            ...values,
            confirm_password: null,
            enterprise_id: this.enterpriseId,
            password: password,
        };
        createUsers(data)
            .then(res => {
                if (res.data === -1) {
                    message.error('邮箱或电话已经被注册');
                    this.setState({
                        onUserAddSaveLoading: false,
                    })
                    return;
                }
                this.setState({
                    onUserAddSaveLoading: false,
                    onAddState: false,
                })
                this.findUsers();
            }).catch(err => {
                message.error('添加失败');
                console.log(err);
                this.setState({
                    onUserAddSaveLoading: false,
                })
            })
    }

    onUserDelete = () => {
        let promiseList = this.state.clientIdSelected.map(item => deleteUser(item));
        axios.all([...promiseList])
            .then(res => {
                this.setState({
                    clientIdSelected: [],
                    onManageState: false,
                })
                this.findUsers();
            })
    }

    showDeleteConfirm = () => {
        if (this.state.clientIdSelected.length === 0) {
            message.warning('请选择至少一条');
            return;
        }
        confirm({
            title: '确认删除吗?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk: () => this.onUserDelete(),
        });
    }

    onUserBlock = () => {
        if (this.state.clientIdSelected.length === 0) {
            message.warning('请选择至少一条');
            return;
        }
        let promiseList = this.state.clientIdSelected.map(item => updateUser(item, {
            is_block: true,
        }));
        axios.all([...promiseList])
            .then(res => {
                this.setState({
                    clientIdSelected: [],
                    onManageState: false,
                })
                this.findUsers();
            })
    }

    onUnBlock = (row) => {
        let id = row.id;
        updateUser(id, {
            is_block: false,
        }).then(res => {
            let newData = [...this.state.users];
            let index = newData.findIndex(item => item.id === id);
            let item: user = newData[index];

            newData.splice(index, 1, { ...item, is_block: false })
            this.setState({
                users: [...newData]
            })
        })
    }

    render() {
        let eid = this.props.userStore._enterpriseId;
        let { enterpriseDetail, logo, users, onAddState, onManageState, clientIdSelected } = this.state;
        const uploadButton = (
            <div>
                {this.state.iconLoading ? <LoadingOutlined /> : <PlusOutlined />}
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            <div className="global-enterprise-container">
                <div style={{ display: 'none' }}>{eid}</div>
                <div className="page-header ant-page-header">
                    <ImgCrop aspect={2 / 1} modalTitle="图片裁剪">
                        <Upload
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={figureUploadUrl}
                            beforeUpload={beforeUpload}
                            onChange={this.handleChange}
                        >
                            {logo ? <img src={`${figureStaticLocation}${logo}`} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </ImgCrop>

                    <div className="title ant-page-header-heading-title">{enterpriseDetail?.name}</div>
                </div>
                <div className="user-main">
                    <div className="user-main-header">
                        {!onManageState && (
                            <Space size="large">
                                <Button onClick={() => this.setState({ onManageState: true })}>管理</Button>
                                <Button type="primary" onClick={() => this.setState({ onAddState: true })}>添加</Button>
                            </Space>
                        )}
                        {onManageState && (
                            <Space >
                                <Button type="link" onClick={() => this.setState({ onManageState: false, clientIdSelected: [] })}>返回</Button>
                                <Button danger onClick={this.showDeleteConfirm}>删除</Button>
                                <Button onClick={this.onUserBlock}>冻结</Button>
                                <span>*tips: 双击单元格可编辑</span>
                            </Space>
                        )}
                        <div className="fill-remaining-space"></div>
                        <Search
                            placeholder="输入检索内容"
                            onChange={this.onSearchInputChange}
                            onBlur={() => this.onSearchContentChange(this.state.searchInputValue)}
                            onSearch={(value) => this.onSearchContentChange(value)}
                            onPressEnter={() => this.onSearchContentChange(this.state.searchInputValue)}
                            style={{ width: 250 }} />
                    </div>
                    <div className="main">
                        <Table
                            className="client-table"
                            rowKey="id"
                            pagination={false}
                            components={{
                                body: {
                                    row: EditableRow,
                                    cell: EditableCell,
                                }
                            }}
                            rowSelection={onManageState && {
                                type: 'checkbox',
                                onChange: this.onSelectChange,
                                selectedRowKeys: clientIdSelected
                            }}
                            dataSource={users}>
                            <Column
                                title="用户名"
                                dataIndex="username"
                            />
                            <Column
                                title="姓名"
                                key="name"
                                width="15%"
                                onCell={(record: user, idx) => ({
                                    record,
                                    editable: onManageState,
                                    dataIndex: 'name',
                                    title: '姓名',
                                    handleSave: (e) => this.handleCellSave(e, 'name'),
                                })}
                                render={record => (
                                    record.is_block ?
                                        `${record.name}(已冻结)` :
                                        `${record.name}`
                                )} />
                            <Column
                                title="类别"
                                dataIndex="type"
                                width="10%"
                                onCell={(record: user, idx) => ({
                                    record,
                                    editable: onManageState,
                                    type: 'Select',
                                    options: [
                                        { label: '工程师', value: UserTypeEnum.ENGINEER },
                                        { label: '现场', value: UserTypeEnum.SITE },
                                        { label: '客户', value: UserTypeEnum.CLIENT },
                                        { label: 'Admin', value: UserTypeEnum.ADMIN },
                                    ],
                                    dataIndex: 'type',
                                    title: '类别',
                                    handleSave: (e) => this.handleCellSave(e, 'type'),
                                })}
                                render={record => UserTypes[record]} />
                            <Column
                                title="电话"
                                dataIndex="mobile"
                                width="10%"
                                onCell={(record: user, idx) => ({
                                    record,
                                    editable: onManageState,
                                    dataIndex: 'mobile',
                                    title: '电话',
                                    handleSave: (e) => this.handleCellSave(e, 'mobile'),
                                })} />
                            <Column
                                title="邮箱"
                                dataIndex="email"
                                width="15%"
                                onCell={(record: user, idx) => ({
                                    record,
                                    editable: onManageState,
                                    dataIndex: 'email',
                                    title: '邮箱',
                                    handleSave: (e) => this.handleCellSave(e, 'email'),
                                })} />
                            <Column
                                title="职位"
                                dataIndex="duty"
                                width="10%"
                                onCell={(record: user, idx) => ({
                                    record,
                                    editable: onManageState,
                                    dataIndex: 'duty',
                                    title: '职位',
                                    handleSave: (e) => this.handleCellSave(e, 'duty'),
                                })} />
                            <Column
                                title="头衔"
                                dataIndex="title"
                                width="10%"
                                onCell={(record: user, idx) => ({
                                    record,
                                    editable: onManageState,
                                    dataIndex: 'title',
                                    title: '头衔',
                                    handleSave: (e) => this.handleCellSave(e, 'title'),
                                })} />
                            <Column
                                title="公司"
                                dataIndex="company"
                                width="15%"
                                onCell={(record: user, idx) => ({
                                    record,
                                    editable: onManageState,
                                    dataIndex: 'company',
                                    title: '公司',
                                    handleSave: (e) => this.handleCellSave(e, 'company'),
                                })} />
                            {/* <Column
                                title="上次登录时间"
                                dataIndex="last_login_time"
                                width="15%"
                                render={record => dayjs(record).format('YYYY-MM-DD HH:mm')} /> */}
                            <Column
                                title=""
                                key="operations"
                                render={(record: user) => (
                                    record.is_block ?
                                        <Button type="link" onClick={() => this.onUnBlock(record)}>解冻</Button>
                                        :
                                        null
                                )} />
                        </Table>
                    </div>
                </div>

                <Modal
                    visible={onAddState}
                    onCancel={() => this.setState({ onAddState: false })}
                    title="添加用户"
                    footer={null}>
                    <div className="user-add">
                        <Form onFinish={this.onUserAddSave} labelCol={{ span: 4 }} autoComplete="off">

                            <Form.Item label="用户名" name="username" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="密码" name="password" rules={[{ required: true }]}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                label="确认密码"
                                name="confirm_password"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入确认密码',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('两次密码不一致');
                                        },
                                    }),
                                ]}
                                dependencies={['password']}
                                hasFeedback>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item label="姓名" name="name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="类别" name="type" rules={[{ required: true }]}>
                                <Select>
                                    <Option value={2}>工程师</Option>
                                    <Option value={3}>现场</Option>
                                    <Option value={4}>客户</Option>
                                    <Option value={1}>Admin</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="电话" name="mobile">
                                <Input />
                            </Form.Item>
                            <Form.Item label="邮箱" name="email">
                                <Input />
                            </Form.Item>
                            <Form.Item label="职位" name="duty">
                                <Input />
                            </Form.Item>
                            <Form.Item label="头衔" name="title">
                                <Input />
                            </Form.Item>
                            <Form.Item label="公司" name="company">
                                <Input />
                            </Form.Item>

                            <div className="operations" style={{textAlign: 'right'}}>
                                <Space size="large">
                                    <Button type="primary" htmlType="submit" loading={this.state.onUserAddSaveLoading}>保存</Button>
                                    <Button onClick={() => this.setState({ onAddState: false })}>取消</Button>
                                </Space>
                            </div>
                        </Form>
                    </div>
                </Modal>
            </div>
        )
    }
}
