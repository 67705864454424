import axios from "../axios"
import { AxiosResponse } from "axios"

export function queryDboxs(params: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/dboxs`, {
        params: params
    })
}

export function downloadDboxsCsv(projectId: number): Promise<AxiosResponse<any>> {
    return axios.get(`/dboxs/export`, {
        params: {
            project_id: projectId
        },
        responseType: 'blob'
    })
}

export function queryDboxDetail(id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/dboxs/${id}`, {
        params: params
    })
}

export function createDbox(data: any, params?: {}): Promise<AxiosResponse<any>> {
    if (!!data.id) {data.id = null};
    return axios.post(`/dboxs`, data, {
        params: params
    })
}

export function updateDbox(id: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/dboxs/${id}`, data, {
        params: params
    })
}

export function deleteDbox(id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`/dboxs/${id}`, {
        params: params
    })
}

export function queryDboxManageHistory(id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/dboxs/${id}/dbox-manage`, {
        params: params
    })
}

export function updateDboxManageHistory(id: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/dboxs/${id}/dbox-manage`, data, {
        params: params
    })
}


export function dboxOnline(id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/dboxs/${id}/online`, null, {
        params: params
    })
}

export function dboxOffline(id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/dboxs/${id}/offline`, null, {
        params: params
    })
}

export function dboxInitialize(id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/dboxs/${id}/initialize`, null, {
        params: params
    })
}

export function dboxSynch(id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/dboxs/${id}/synch`, null, {
        params: params
    })
}