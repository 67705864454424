import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Gprops } from '../../config/props';
import menus, { IFMenu } from '../../config/menus';
import { observable, action } from 'mobx';
import { Menu } from 'antd';
import IconFont from '../../config/iconfont';
import './menu.less';
import { inject, observer } from 'mobx-react';
import MobjectStore from '@/store/mobject-store';
import { queryMobjects } from '@/config/api/mobject';
import { mobject } from '@/interface/mobject';
import { MobjectType } from '@/routes/mobject/mobject-page';
import { ProjectContext } from '@/config/context';

interface SiderMenuProps extends Gprops {
    // onMenuClick?: (title: string, url: string) => void
    mobjectStore?: MobjectStore
}

interface IState {
    selectedKey: string,
}
@inject('mobjectStore')
@observer
class SiderMenu extends Component<SiderMenuProps, IState> {
    static contextType = ProjectContext;
    state = {
        selectedKey: '',
    }
    routeKeys: string[] = [];
    @observable _defaultOpenKeys: string[] = [];
    @action defaultOpenKeys = (value: string[]) => {
        this._defaultOpenKeys = value;
    }
    // @observable _selectedKey: string = '';
    // @action selectedKey = (value: string) => {
    //     this._selectedKey = value;
    // }

    constructor(props) {
        super(props);
        let defaultOpenKeys = [];
        menus.forEach((item: IFMenu, index: number) => {
            if (item.subs && item.default_open) {
                defaultOpenKeys.push(item.key);
            }
        })
        this.defaultOpenKeys(defaultOpenKeys);

        let routeKeys = [];
        menus.forEach((item: IFMenu, index: number) => {
            if (item.subs) {
                item.subs.forEach(sub => {
                    routeKeys.push(sub.key);
                })
            } else {
                routeKeys.push(item.key);
            }
        })
        this.routeKeys = routeKeys;
        // this.selectedKey(this.props.location.pathname);
    }

    componentDidMount() {
        const projectId = this.context;
        this.findMobjectsCount(projectId);
    }

    componentDidUpdate() {
        let key = this.props.location.pathname;
        let targetKey = '';
        this.routeKeys.forEach(routeKey => {
            let tmp = `/projects/${this.context}${routeKey}`
            if (key.indexOf(tmp) >= 0) {
                targetKey = tmp;
            }
        })
        if (this.state.selectedKey === targetKey) return;
        this.setState({
            selectedKey: targetKey
        })
    }

    menuClick = (e: any) => {
        // this.selectedKey(e.key);

        // let key: string = e.key;
        // key = key.replace(`${this.props.match.url}`, '');
        // let route = routeList.filter(item => item.url === key)[0];
        // this.props.onMenuClick(route.alias ? route.alias : e.item.node.innerText, e.key);
    }

    renderMenuItem = (item: IFMenu) => {
        const key = `${this.props.match.url}${item.key}`;
        const stmCount = this.props.mobjectStore._stmCount;
        const mmCount = this.props.mobjectStore._mmCount;
        const jmCount = this.props.mobjectStore._jmCount;
        const emCount = this.props.mobjectStore._emCount;

        if (item.key?.indexOf('structures') >= 0) item.title = `结构 ${stmCount}`;
        if (item.key?.indexOf('members') >= 0) item.title = `构件 ${mmCount}`;
        if (item.key?.indexOf('joints') >= 0) item.title = `节点 ${jmCount}`;
        if (item.key?.indexOf('environments') >=0) item.title = `环境 ${emCount}`;
        return (
            <Menu.Item key={key} className="tn-menu-item">
                <Link to={(key) + (item.query || '')}>
                    {item.icon && <IconFont type={item.icon} />}
                    <span className="nav-text">{item.title}</span>
                </Link>
            </Menu.Item>
        );
        
    };

    renderSubMenu = (item: IFMenu) => {
        const key = item.key;
        return (
            <Menu.SubMenu 
                className="tn-menu-submenu"
                key={key}
                title={
                    <span>
                        {item.icon && <IconFont type={item.icon} />}
                        <span className="nav-text">{item.title}</span>
                    </span>
                }
            >
                {item.subs!.map(sub => (sub.subs ? this.renderSubMenu(sub) : this.renderMenuItem(sub)))}
            </Menu.SubMenu>
        )
    };

    findMobjectsCount = (projectId: number) => {
        queryMobjects({
            project_id: projectId,
        })
        .then(res => {
            let mobjects: mobject[] = res.data || [];
            let stmCount = mobjects.filter(m => m.type === MobjectType.structures.type).length;
            let mmCount = mobjects.filter(m => m.type === MobjectType.members.type).length;
            let jmCount = mobjects.filter(m => m.type === MobjectType.joints.type).length;
            let emCount = mobjects.filter(m => m.type === MobjectType.environments.type).length;
            this.props.mobjectStore.changeStmCount(stmCount);
            this.props.mobjectStore.changeMmCount(mmCount);
            this.props.mobjectStore.changeJmCount(jmCount);
            this.props.mobjectStore.changeEmCount(emCount);
        })
    }

    render() {
        return (
            <Menu 
                mode='inline' 
                theme='dark'
                inlineIndent={28}
                defaultOpenKeys={this._defaultOpenKeys}
                selectedKeys={[this.state.selectedKey]}
                onClick={this.menuClick}
                className='fill-remaining-space'
            >
                {
                    menus.map((item: IFMenu, index: number) => (
                        item.subs! ? this.renderSubMenu(item) : this.renderMenuItem(item)
                    ))
                }
            </Menu>
        )
    }
}

export default withRouter(SiderMenu);