import React, { Component } from 'react'
import { Tabs, Button, Table, Modal, message, Space, Popconfirm } from 'antd'
import { paramDict2Array } from '@/utils/utils'
import { SectionTypes, SectionShapeTypes } from '@/utils/type'
import { globalSection, section } from '@/interface/model';
import UserStore from '@/store/user-store';
import { inject, observer } from 'mobx-react';
import { queryGlobalSections, queryEnterprisesSections, createEnterprisesSection, updateEnterpriseSectionDetail, deleteEnterpriseSectionDetail } from '@/config/api/global-secmat';
import axios from '@/config/axios';
import { PlusOutlined } from '@ant-design/icons';
import { SourceType } from '@/components/model/section-add';
import './global-section.less';
import { SectionPanel } from '@/components';
import { UserTypeEnum } from '../login/login';

const { TabPane } = Tabs;
const { Column } = Table;
interface IProps {
    userStore?: UserStore
}

interface IState {
    selectedSectionType: number,
    gSections: globalSection[],
    displayGSections: globalSection[],

    readOnlyModalVisible: boolean
    createModalVisible: boolean
    currentSection: section

    eSectionSaveLoading: boolean // 控制截面弹窗保存键的loading
    pageSize: number
    loading: boolean
    saveLoading: boolean // 自定义截面保存loading
    isEditing: boolean // 截面编辑状态

}

@inject('userStore')
@observer
export default class GlobalSectionPage extends Component<IProps, IState> {

    state = {
        selectedSectionType: 1,
        gSections: [],
        selectedGlobalSections: [],
        selectedRowKeys: [],
        displayGSections: [],

        readOnlyModalVisible: false,
        createModalVisible: false,
        currentSection: null,

        eSectionSaveLoading: false,

        pageSize: 30,
        loading: false,
        saveLoading: false,
        isEditing: false,
    }
    enterPriseId: number;

    componentDidMount() {
        this.enterPriseId = this.props.userStore._enterpriseId;
        this.findSections(this.state.selectedSectionType)
    }

    componentDidUpdate() {
        let enterPriseId = this.props.userStore._enterpriseId;
        if (enterPriseId !== this.enterPriseId) {
            this.enterPriseId = this.props.userStore._enterpriseId;
            this.findSections(this.state.selectedSectionType)
        }
    }

    findSections = (type: number) => {
        if (!this.enterPriseId) {
            return;
        }
        this.setState({
            loading: true,
        })
        let gSectionsPromise = queryGlobalSections();
        let eSectionsPromise = queryEnterprisesSections(this.enterPriseId);

        axios.all([gSectionsPromise, eSectionsPromise])
            .then(res => {
                let gSections: globalSection[] = res[0].data || [];
                let eSections: globalSection[] = res[1].data || [];

                gSections = gSections.map(item => Object.assign(item, { key: `g${item.type}-${item.id}`, source: SourceType.DB }))
                eSections = eSections.map(item => Object.assign(item, { key: `e${item.type}-${item.id}`, source: SourceType.CUSTOM }))
                let sections = [...gSections, ...eSections]
                this.setState({
                    gSections: sections,
                    displayGSections: sections.filter(item => item.type === this.state.selectedSectionType),
                })
            }).finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    showSectionModal = (row) => {
        this.setState({
            readOnlyModalVisible: true,
            currentSection: row
        })
    }

    showCreateSectionModal = () => {
        this.setState({
            createModalVisible: true,
        })
    }

    onSectionTypeChange = (key) => {
        let type = +key;
        this.setState({
            displayGSections: this.state.gSections.filter(item => item.type === type),
            selectedSectionType: type
        })
    }

    addNewEnterpriseSection = (value) => {
        this.setState({
            eSectionSaveLoading: true,
        })
        let data = Object.assign(value, { enterprise_id: this.props.userStore._enterpriseId })
        createEnterprisesSection(this.props.userStore._enterpriseId, data)
            .then(res => {
                let id = res.data;
                let key = `e${data.type}-${id}`;
                data = Object.assign(data, {
                    id: res.data,
                    key: key,
                    source: SourceType.CUSTOM,
                })
                this.setState({
                    createModalVisible: false,
                    gSections: [data, ...this.state.gSections],
                })
                if (data.type === this.state.selectedSectionType) {
                    this.setState({
                        displayGSections: [data, ...this.state.displayGSections]
                    })
                }
            }).finally(() => {
                this.setState({
                    eSectionSaveLoading: false,
                })
            })

    }

    onEnterpriseSectionSave = (values) => {
        if (!this.state.currentSection) return;
        let sec = this.state.currentSection;
        let id = this.state.currentSection?.id;
        if (!id) {
            return;
        }
        if (this.state.currentSection.source === SourceType.DB) return;
        if (!this.enterPriseId) return;
        this.setState({
            saveLoading: true,
        })
        updateEnterpriseSectionDetail(this.enterPriseId, id, values)
            .then(res => {
                sec.name = values.name;
                sec.param_in = values.param_in;
                sec.param_out = values.param_out;
                this.setState({
                    saveLoading: false,
                    isEditing: false,
                    gSections: [...this.state.gSections]
                })
                message.success('保存成功');
            }).catch(err => {
                message.error('保存失败');
                this.setState({
                    saveLoading: false,
                    isEditing: false,
                })
            })
    }

    onSectionDelete = (record: globalSection) => {
        if (record.source === SourceType.DB) return;
        if (!this.enterPriseId) return;
        deleteEnterpriseSectionDetail(this.enterPriseId, record.id)
        .then(res => {
            const key = `e${record.type}-${record.id}`;
            let gSections = this.state.gSections.filter(item => item.key !== key);
            this.setState({
                gSections: gSections,
                displayGSections: gSections.filter(item => item.type === this.state.selectedSectionType),
            })
        })
    }

    render() {
        let eid = this.props.userStore._enterpriseId;
        let userType = this.props.userStore._userInfo?.type;
        let { selectedSectionType, pageSize } = this.state
        let tabbars = paramDict2Array(SectionTypes).filter(item => item.value.active).map((item, idx) => (
            <TabPane tab={item.value.alias} key={item.name} />
        ))
        return (
            <div className="global-section-container">
                <div style={{ display: 'none' }}>{eid}</div>
                <div className="section-library">
                    <Tabs
                        defaultActiveKey={selectedSectionType.toString()}
                        type="card"
                        onChange={this.onSectionTypeChange}
                        tabBarExtraContent={(
                            <Button size="small" type="link" icon={<PlusOutlined />} onClick={this.showCreateSectionModal}>自定义</Button>
                        )}>
                        {tabbars}
                    </Tabs>
                    <Table
                        dataSource={this.state.displayGSections}
                        rowKey="key"
                        loading={this.state.loading}
                        pagination={{ pageSize: pageSize, onShowSizeChange: (current, size) => this.setState({ pageSize: size }) }}>
                        <Column title="编号" dataIndex="key" />
                        <Column title="截面名称" dataIndex="name" />
                        <Column title="形状" dataIndex="shape_type" render={record => SectionShapeTypes[record]} />
                        <Column
                            title="来源"
                            dataIndex="source"
                            width={120}
                            filters={[
                                { text: '数据库', value: SourceType.DB },
                                { text: '自定义', value: SourceType.CUSTOM },
                            ]}
                            onFilter={(value, record: globalSection) => record.source === value}
                            render={(record, idx) => record === SourceType.DB ? '数据库' : '自定义'} />
                        <Column title="" key="operations" align="center" render={(record, idx) => (
                            <Space>
                                <Button type="link" size="small" onClick={() => this.showSectionModal(record)}>详情</Button>
                                {record.source === SourceType.CUSTOM && userType<=UserTypeEnum.ENGINEER && (
                                    <Popconfirm title="确认删除吗?" okText="是" cancelText="否" onConfirm={() => this.onSectionDelete(record)}>
                                        <Button type="link" size="small" danger>删除</Button>
                                    </Popconfirm>
                                )}
                            </Space>

                        )} />
                    </Table>
                </div>

                <Modal
                    visible={this.state.readOnlyModalVisible}
                    destroyOnClose
                    width={800}
                    closable={false}
                    style={{ top: '20px' }}
                    onCancel={() => this.setState({ readOnlyModalVisible: false })}
                    footer={null}>
                    <SectionPanel
                        isEditing={this.state.isEditing}
                        sectionDetail={this.state.currentSection}
                        readOnly={this.state.currentSection?.source === SourceType.DB}
                        onSave={this.onEnterpriseSectionSave}
                        onCancel={() => this.setState({ isEditing: false })}
                        onEdit={() => this.setState({ isEditing: true })}
                        saveLoading={this.state.saveLoading}
                        sidHidden />
                </Modal>

                <Modal
                    visible={this.state.createModalVisible}
                    destroyOnClose
                    width={800}
                    closable={false}
                    style={{ top: '20px' }}
                    footer={null}>
                    <SectionPanel
                        isEditing={false}
                        saveLoading={this.state.eSectionSaveLoading}
                        sectionDetail={this.state.currentSection}
                        newType={this.state.selectedSectionType}
                        onCancel={() => this.setState({ createModalVisible: false })}
                        onSave={this.addNewEnterpriseSection} />
                </Modal>
            </div>
        )
    }
}
