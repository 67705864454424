import React, { Component, Fragment } from 'react'
import { sensor } from '@/interface/sensor'
import { dbox, globalDbox } from '@/interface/dbox'
import { Select, Row, Col, Empty, Button, Space, message, Tooltip } from 'antd';
import './sensor-dbox-select.less';
import { queryDboxs, dboxSynch } from '@/config/api/dbox';
import { inject, observer } from 'mobx-react';
import GlobalDeviceStore from '@/store/global-device';
import { ProjectContext } from '@/config/context';
import { queryGlobalDboxs } from '@/config/api/global-device';
import axios from '@/config/axios';
import lodash from 'lodash';
import classnames from 'classnames';
import { Gprops } from '@/config/props';
import { withRouter } from 'react-router-dom';
import { EditOutlined, SyncOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { updateSensor, querySensors } from '@/config/api/sensor';
import { SensorStatusTypesEnum } from '@/routes/sensor/sensor-list';

const { Option } = Select;

interface IProps extends Gprops {
    sensorDetail: sensor
    globalDeviceStore?: GlobalDeviceStore,
    afterSave?: (dboxId: number, channel: number) => void,
    onSensorChange?: (sensorId: number) => void,
}

interface IState {
    dboxDetail?: dbox
    dboxList?: dbox[]
    currentDboxId?: number
    currentChannel?: number
    isEdit?: boolean
    saveLoading: boolean

    currentDboxSensors: sensor[]
    dboxSyncLoading: boolean
}

@inject('globalDeviceStore')
@observer
class SensorDboxSelect extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        dboxDetail: null,
        dboxList: [],
        currentDboxId: null,
        currentChannel: null,
        isEdit: false,
        saveLoading: false,

        currentDboxSensors: [],
        dboxSyncLoading: false,
    }
    sensorId: number;
    componentDidUpdate() {
        if (this.sensorId !== this.props.sensorDetail?.id) {
            this.sensorId = this.props.sensorDetail?.id;
            this.setState({
                currentDboxId: this.props.sensorDetail?.dbox_id,
                currentChannel: this.props.sensorDetail?.channel,
            })
            this.findDboxlist();
        }

    }

    componentDidMount() {
        // let sensor = this.props.sensorDetail;
        // this.setState({
        //     dboxDetail: this.props.dboxList?.find(item => item.id === sensor?.dbox_id)
        // })
        // console.log(this.state.dboxDetail)
        this.sensorId = this.props.sensorDetail?.id;
        this.setState({
            currentDboxId: this.props.sensorDetail?.dbox_id,
            currentChannel: this.props.sensorDetail?.channel,
        })
        this.findDboxlist();
    }

    findDboxlist = () => {
        let projectId = this.context;
        let globalDboxPromise = queryGlobalDboxs();
        let queryDboxsPromise = queryDboxs({
            project_id: projectId,
        });
        axios.all([globalDboxPromise, queryDboxsPromise]).then(res => {
            let gDboxs: globalDbox[] = res[0].data || [];
            let dboxs: dbox[] = res[1].data || [];
            this.props.globalDeviceStore.globalDboxs(gDboxs);
            dboxs.forEach(dbox => {
                let global = gDboxs.find(item => item.type === dbox.type);
                dbox.type_name = global?.name || '';
            })
            let sensor = this.props.sensorDetail;
            let dboxDetail = dboxs?.find(item => item.id === sensor.dbox_id);
            this.setState({
                dboxList: dboxs,
                dboxDetail: dboxDetail,
            })
            if (!!dboxDetail) {
                this.findCurrentDboxSensors(dboxDetail.id);
            }
        })
    }

    findCurrentDboxSensors = (dboxId: number) => {
        if (!dboxId) {
            return;
        }
        querySensors({
            project_id: this.context,
            dbox_id: dboxId,
        }).then(res => {
            let sensors = res.data || [];
            this.setState({
                currentDboxSensors: sensors,
            })
            // this.sensors = res.data;
            // this.sensors = this.sensors.filter(item => !!item.channel);
            // this.generateDboxChannel(channelCount);
        })
    }

    onDboxSelectChange = (value) => {
        let dbox = this.state.dboxList.find(item => item.id === value);
        this.setState({
            currentDboxId: value,
            dboxDetail: dbox,
            currentChannel: null,
        })

    }

    onChannelSelect = (value) => {
        if (this.state.currentChannel === value) return;

        // 非编辑状态下，点击跳转进入详情
        if (!this.state.isEdit) {
            let sensor = this.state.currentDboxSensors?.find((s: sensor) => s.channel === value);
            if (!sensor) return;
            if (!this.props.onSensorChange) return;
            this.props.onSensorChange(sensor.id);
            // this.props.history.push(`/projects/${this.context}/sensors/${sensor.id}`);
            return;
        }

        // 编辑操作下，点击切换通道
        if (this.state.currentDboxSensors.some(s => s.channel === value)) return;
        this.setState({
            currentChannel: value,
        })
    }

    onCancel = () => {
        let dboxId = this.props.sensorDetail?.dbox_id
        let dbox = this.state.dboxList.find(item => item.id === dboxId);
        this.setState({
            currentDboxId: dboxId,
            currentChannel: this.props.sensorDetail?.channel,
            dboxDetail: dbox,
            isEdit: false,
        })
    }

    onSave = () => {
        this.setState({
            saveLoading: true,
        })
        let data = {
            channel: this.state.currentChannel || -1,
            dbox_id: this.state.currentDboxId || -1,
        }
        updateSensor(this.sensorId, data)
            .then(res => {
                this.setState({
                    isEdit: false,
                    saveLoading: false,
                })
                if (data.dbox_id > 0) {
                    // 同步硬件
                    this.onDboxSyncClick();
                }
                this.props.afterSave(this.state.currentDboxId, this.state.currentChannel)
            }).catch(err => {
                message.error('关联通道失败');
                console.log(err);
                this.setState({
                    saveLoading: false,
                })
            })
    }

    onRelease = () => {
        this.setState({
            currentDboxId: null,
            currentChannel: null,
            dboxDetail: null,
        })
    }

    onDboxBtnClick = () => {
        let dboxDetail = this.state.dboxDetail;
        if (dboxDetail) {
            this.props.history.push(`/projects/${this.context}/dboxs/${dboxDetail.id}`)
        }
    }

    onDboxSyncClick = () => {
        let id = this.state.dboxDetail?.id;
        if (!id) return;
        this.setState({
            dboxSyncLoading: true,
        });
        dboxSynch(id)
            .then(res => {
                if (!res.data) {
                    throw new Error('采集箱同步失败');
                }
                message.success('数据上载同步成功!');
                let d: dbox = { ...this.state.dboxDetail };
                d.is_synch = true;
                this.setState({
                    dboxDetail: d,
                })
            }).catch(err => {
                console.log(err);
                let d: dbox = { ...this.state.dboxDetail };
                d.is_synch = false;
                this.setState({
                    dboxDetail: d,
                })
            }).finally(() => {
                this.setState({
                    dboxSyncLoading: false,
                });
            })
    }

    generateDboxChannelDIY = (channelRange: string) => {
        // 1-5,10,15-20
        if (channelRange == null || channelRange == '') return;
        let tmp = channelRange.split(',') || [];
        let channelList: number[] = [];
        tmp.forEach(str => {
            if (!!+str) {
                channelList.push(+str);
            } else if (str.indexOf('-') > 0) {
                let tmp2 = str.split('-') || [];
                if (tmp2.length !== 2 || !!!+tmp2[0] || !!!+tmp2[1]) {
                    return;
                }
                for(let i=+tmp2[0]; i<= +tmp2[1]; i++) {
                    channelList.push(i);
                }
            }
        })
        return channelList;
    }

    render() {
        let sensor = this.props.sensorDetail;
        if (!sensor) {
            return null;
        }
        let { dboxDetail, isEdit, currentChannel, dboxSyncLoading } = this.state;

        let channels = lodash.range(1, dboxDetail?.channel_count + 1);
        if (!!dboxDetail?.channel_range) {
            channels = this.generateDboxChannelDIY(dboxDetail?.channel_range);
        }
        let renderchannels = channels.map(idx => ({channel: idx, s: this.state.currentDboxSensors.find(s => s.channel === idx)}));
        return (
            <div className="sensor-dbox-select-panel">
                <div className="header">
                    <div className="title">采集箱&通道</div>
                    {!isEdit && (
                        <Button onClick={() => this.setState({ isEdit: true })} type="link" size="small" icon={<EditOutlined />}></Button>
                    )}
                </div>
                {isEdit && (
                    <div className="operations">
                        <Space>
                            <Button type="primary" size="small" onClick={this.onSave}>保存</Button>
                            <Button size="small" onClick={this.onCancel}>取消</Button>
                        </Space>
                        <Button size="small" type="link" onClick={this.onRelease}>释放</Button>
                    </div>
                )}
                <div className="dbox-select">
                    {isEdit && (
                        <Select
                            className="dbox-select-com"
                            placeholder="选择采集箱"
                            value={this.state.currentDboxId}
                            onChange={this.onDboxSelectChange}
                        >
                            {this.state.dboxList?.map(item => (
                                <Option key={item.id} value={item.id}>{`${item.name}-${item.type_name}`}</Option>
                            ))}
                        </Select>
                    )}
                    {!isEdit && dboxDetail && (
                        <Fragment>
                            <Button
                                block
                                className="dbox-btn"
                                type="dashed"
                                onClick={this.onDboxBtnClick}
                                disabled={!dboxDetail}>
                                {dboxDetail && (`${dboxDetail.name}-${dboxDetail.type_name}`)}
                            </Button>
                            {(!dboxDetail.is_synch || dboxSyncLoading) && (
                                <div className="term">
                                    {!dboxSyncLoading && (
                                        <Fragment>
                                            <Tooltip title="指：数据库中的配置与采集箱硬件配置不一致">
                                                <InfoCircleOutlined style={{ color: '#ff4d4f' }} />
                                            </Tooltip>
                                            <div>未与硬件同步</div>
                                        </Fragment>
                                    )}
                                    {dboxSyncLoading && (
                                        <div>正在与硬件同步</div>
                                    )}
                                    <span className="detail">
                                        <Tooltip title="重新上载">
                                            <Button
                                                type="link"
                                                onClick={this.onDboxSyncClick}
                                                icon={<SyncOutlined spin={dboxSyncLoading} />}></Button>
                                        </Tooltip>
                                    </span>
                                </div>
                            )}
                        </Fragment>


                    )}
                </div>
                <div className="dbox-channel-select">
                    <div className="channel-group">
                        <Row gutter={[4, 4]} style={{ width: '100%' }}>
                            {dboxDetail && dboxDetail.channel_count > 0 && (
                                renderchannels.map((c: any, idx: number) => {
                                    let s = c?.s;
                                    let channel = c?.channel;
                                    return (
                                        <Col key={channel} span={3}>
                                            <div
                                                onClick={() => this.onChannelSelect(channel)}
                                                className={classnames({
                                                    'channel-cell-select-enabled': isEdit && !s,
                                                    'channel-cell-select-disabled': isEdit && !!s,
                                                    'channel-cell-can-go': !isEdit && !!s && s.channel !== currentChannel,
                                                    'channel-cell-active': currentChannel === channel,
                                                    "channel-cell": true,
                                                    'channel-cell-odd': (Math.floor(idx / 8) % 2 === 0 && idx % 2 !== 0) || (Math.floor(idx / 8) % 2 !== 0 && idx % 2 === 0),
                                                })}>
                                                <div className={classnames({
                                                    "content": true,
                                                    'content-online': s?.work_status === SensorStatusTypesEnum.ONLINE && s?.channel !== currentChannel,
                                                    'content-abnormal': s?.work_status === SensorStatusTypesEnum.ABNORMAL && s?.channel !== currentChannel
                                                })}>{channel}</div>
                                            </div>
                                        </Col>
                                    )
                                })
                            )}

                        </Row>
                        {(!dboxDetail) && (
                            <Empty
                                className="channel-blank"
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="暂无采集箱">
                                {!isEdit && (
                                    <Button type="primary" onClick={() => this.setState({ isEdit: true })}>关联采集箱</Button>
                                )}
                            </Empty>
                        )}
                    </div>

                </div>
            </div>
        )
    }
}

export default withRouter(SensorDboxSelect);