import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
// import styles from './index.module.less'
import './index.less'
import { CopyrightCircleOutlined } from '@ant-design/icons'
import { Gprops } from '../../config/props'
import Login from '../../routes/login/login'
import classnames from 'classnames'
import appStore from '@/store/app-store'
import { inject, observer } from 'mobx-react'
import UserStore from '@/store/user-store'
import ForgetPwdPage from '@/routes/login/forget'

interface IProps extends Gprops {
    userStore?: UserStore
}

@inject('userStore')
@observer
export default class PassportLayout extends Component<IProps> {
    render() {
        // console.log(this.props.match.path);
        const { _isLogin } = this.props.userStore;
        if (_isLogin) {
            return <Redirect to='/projects'/>
        }
        appStore.clear();
        return (
            <div className={classnames('passport', 'container')}>
                <div className="content">
                    <Switch>
                        <Route path={`${this.props.match.url}/login`} component={Login}/>
                        <Route path={`${this.props.match.url}/forget`} component={ForgetPwdPage}/>
                    </Switch>
                </div>
                <div className="footer">
                    <div className="copyright">
                        Copyright <CopyrightCircleOutlined /> 2020
                        <a href="/" target="_blank">同恩工程技术</a>出品
                    </div>
                </div>
            </div>
        )
    }
}
