import { observable, action } from "mobx";
import { project } from "@/interface/project";


class ProjectStore {
    @observable _projectName: string = null;
    @action setProjectName = (value: string) => {
        this._projectName = value;
        if (!!this._project) {
            this._project.name = value;
        }
    }

    @observable _project: project = null;
    @action setProject = (value: project) => {
        this._project = value;
        this._projectWorkStatus = value.working;
    }

    @observable _projectWorkStatus: boolean = false;
    @action setProjectWorkStatus = (value: boolean) => {
        this._projectWorkStatus = value;
        if (!!this._project) {
            this._project.working = value;
        }
    }
}

export default ProjectStore;