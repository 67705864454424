import React, { Component } from 'react'
import { IFRouteBase } from '../../config/route-list'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import AllRoutes from '../../routes';
import { Gprops } from '../../config/props';
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';

interface CRouterProps extends Gprops {
    routeList: IFRouteBase[],
    userStore?: UserStore
}
@inject('userStore')
@observer
class CRouter extends Component<CRouterProps> {

    createRoute = (item: IFRouteBase) => {
        if (item.component) {
            // const MyComponent = item.component && AllRoutes[item.component];
            const MyComponent = AllRoutes[item.component];
            return (
                <Route exact={item.exact} key={item.url} path={`${this.props.match.url}${item.url}`} render={(props) => (
                    <MyComponent {...props}/>
                )}/>
            )
        }
        return null;
    }
    render() {
        // console.log(this.props.userStore._isLogin)
        const { _isLogin } = this.props.userStore;
        if (!_isLogin) {
            return <Redirect to='/passport/login'/>
        }
        return (
            <Switch>
                {this.props.routeList.map((item: IFRouteBase, index: number) => this.createRoute(item))}
                <Redirect to={`${this.props.match.url}/dashboard`}/>
            </Switch>
        )
    }
}

export default withRouter(CRouter);