import React, { Component } from 'react'
import { List } from 'antd'
import { procedure } from '@/interface/procedure'
import { ProjectContext } from '@/config/context';
import { queryProcedures } from '@/config/api/standard';
import axios from '@/config/axios';
import './standard-statistic.less';
import { inject, observer } from 'mobx-react';
import StandardStore from '@/store/standard-store';
// import './standard-statistic.less';

interface IProps {
    standardStore?: StandardStore
}
interface IState {
    procedures: procedure[]
    standards: any[]
}

@inject('standardStore')
@observer
export default class StandardStatistic extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        procedures: [],
        standards: [],
    }

    componentDidMount() {
        this.findProcedures();
        
    }

    componentDidUpdate() {
        let forceRender = this.props.standardStore._forceRender;
        if (forceRender) {
            this.props.standardStore.setForceRender(false);
            this.findProcedures();
        }
    }

    findProcedures = () => {
        queryProcedures(this.context)
        .then((res) => {
                let procedures: procedure[] = res.data;
                this.setState({ 
                    procedures: procedures,
                })
                this.combineCodeName(procedures)
            })
    }

    combineCodeName = (procedures: procedure[]) => {
        let unitCodeNames = Array.from(new Set(procedures.map(item => `${item.standard_name}__${item.standard_code}`)));
        let res = unitCodeNames.map((item: string) => ({name: item.split('__')[0], code: item.split('__')[1]}));
        this.setState({
            standards: res,
        })
    }

    render() {
        let forceRender = this.props.standardStore._forceRender;
        return (
            <div className="standard-card">
                <div style={{display: 'none'}}>{forceRender}</div>
                <div className="header">
                    <div className="title">规范统计 </div> </div>
                <div className="standard-content" >
                    <List dataSource={this.state.standards} split={false} renderItem={item => (
                        <List.Item key={item.id} style={{ padding: '8px 0' }}>
                            <span>{item.name}</span>
                            <span>{item.code}</span>
                        </List.Item>
                    )} />
                </div>
            </div>
        )
    }
}
