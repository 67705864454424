import React, { Component } from 'react'
import { Progress } from 'antd';
import './process-bar.less';

interface Iprops {
    title: string,
    percent: number,
}

export default class ProcessBar extends Component<Iprops> {
    render() {
        return (
            <div className="process-bar">
                <div className="info">
                    <span className="title">{this.props.title}</span>
                    <span className="process">{`${this.props.percent}%`}</span>
                </div>
                <Progress percent={this.props.percent} status="active" showInfo={false}/>
            </div>
        )
    }
}
