import axios from "../axios"
import { AxiosResponse } from "axios"

export function queryEnterprises(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/enterprises`, {
        params: params
    })
}

export function createEnterprise(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/enterprises`, data, {
        params: params
    })
}

export function queryEnterprisesDetail(enterprisesId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/enterprises/${enterprisesId}`, {
        params: params
    })
}

export function updateEnterprise(enterprisesId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/enterprises/${enterprisesId}`, data, {
        params: params
    })
}

export function deleteEnterprise(enterprisesId: number): Promise<AxiosResponse<any>> {
    return axios.delete(`/enterprises/${enterprisesId}`)
}

