import React, { Component } from 'react'
import { quantity, globalQuantity, warningLimit, quantityLimit } from '@/interface/quantity'
import { Table, Space, Button, Spin, Empty, Switch, Select, message, Upload, Tooltip } from 'antd';
import { constage } from '@/interface/constage';
import { EditableRow, EditableCell } from '..';
import { CheckOutlined, CloseOutlined, InfoCircleFilled } from '@ant-design/icons';
import axios from '@/config/axios';
import MobjectStore from '@/store/mobject-store';
import { inject, observer } from 'mobx-react';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import { queryQuantities, updateQuantities, downloadQuantityWLCsv } from '@/config/api/quantity';
import { ProjectContext } from '@/config/context';
import './quantity-detail-limit.less';
import { Gprops } from '@/config/props';
import { withRouter } from 'react-router-dom';
import { Transfer2DisplayScale, NumFixed } from '@/utils/utils';
import { QuantityLevelTypes } from './quantity-pattern-setting';
import { queryConstages } from '@/config/api/constage';
import { QuantityName } from '../QuantityName';
import appStore, { AUTHORIZATION } from '@/store/app-store';
const { Column } = Table;
const { Option } = Select;

interface IProps extends Gprops {
    constages: constage[],
    mobjectStore?: MobjectStore,
}

interface IState {
    quantities: quantity[],
    currentConstage: constage,
    loading: boolean,
    isEditting: boolean,

    displayQuantityLimits: quantityLimit[]
    constages: constage[]

    exportLoading: boolean,

    uploadUrl: string,
}

@inject('mobjectStore', 'quantityDetailStore')
@observer
class QuantityDetailLimitPane extends Component<IProps, IState> {
    static contextType = ProjectContext;
    projectGlobalQuantities = [];
    quantities: quantity[] = [];
    state = {
        quantities: [],
        currentConstage: null,
        loading: false,
        isEditting: false,

        displayQuantityLimits: [],
        constages: [],

        exportLoading: false,

        uploadUrl: '',
    }

    componentDidMount() {
        let token = appStore.get(AUTHORIZATION) || '';
        let url = `/api/quantities/warning-limit/import?project_id=${this.context}&token=${token}`;
        this.setState({
            uploadUrl: url,
        })
        this.generateData();
    }

    generateData = () => {
        const projectId = this.context;
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        if (!mobjectId) return;
        let queryPQuantitiesPromise = queryProjectGlobalQuantities(projectId);
        let queryConstagesPromise = queryConstages(projectId, {
            mobject_id: mobjectId,
        });
        this.setState({
            loading: true,
        })
        axios.all([queryPQuantitiesPromise, queryConstagesPromise])
            .then(res => {
                this.projectGlobalQuantities = res[0].data || [];
                let constages = res[1].data || [];
                this.setState({
                    constages: constages,
                    currentConstage: constages[0] || null,
                })
                this.findQuantities();
            })
    }

    findQuantities = () => {
        this.setState({
            loading: true,
        })
        const projectId = this.context;
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        if (!mobjectId) return;

        queryQuantities(this.context, {
            mobject_id: mobjectId,
            activated: true,
            level: QuantityLevelTypes.OTHER_Q
        }).then(res => {
            let quantities: quantity[] = res.data || [];
            quantities = quantities.sort((a, b) => b.qt_type - a.qt_type);
            quantities.forEach((q: quantity) => {
                let pQuantity: globalQuantity = this.projectGlobalQuantities.find(item => item.type === q.type);
                q.chinese_name = pQuantity?.chinese_name || '';
                q.unit_type = pQuantity?.unit_type || '';
                q.precision = pQuantity?.precision || '1';
                let scale = Transfer2DisplayScale(q.unit_type);
                q.scale = scale;
                // 将数据换算成展示单位对应数值
                q.warning_limit.forEach((item: warningLimit) => {
                    for (let key of Object.keys(item.values)) {
                        item.values[key] = NumFixed(item.values[key] * q.scale, 8);
                    }
                })
                q.initial_value = NumFixed(q.initial_value * q.scale, 8);
            })
            quantities = quantities.sort((a, b) => b.qt_type - a.qt_type);
            this.quantities = quantities;
            this.setState({
                quantities: quantities,
            })
            if (!!this.state.currentConstage) {
                this.generateDisplayQuantityLimit(this.state.currentConstage.id);
            }
        }).finally(() => {
            this.setState({
                loading: false,
                isEditting: false,
            })
        })
    }

    generateDisplayQuantityLimit(constageId: number) {
        let quantities = this.state.quantities;
        let display = quantities.map(q => {
            let warningLimit = q.warning_limit.find(item => item.stage_id === constageId)?.values;
            return {
                ...q,
                t2: warningLimit ? warningLimit['t2'] : null,
                t3: warningLimit ? warningLimit['t3'] : null,
                t4: warningLimit ? warningLimit['t4'] : null,
                t5: warningLimit ? warningLimit['t5'] : null,
            }
        }) || [];
        this.setState({
            displayQuantityLimits: display
        })
    }

    onConstageSelectChange = (e) => {
        this.setState({
            currentConstage: this.state.constages.find(item => item.id === e)
        })
        this.generateDisplayQuantityLimit(e);
    }

    handleRowEdit = (row: quantityLimit, tag: string) => {
        let id = row.id;
        let newData = [...this.state.displayQuantityLimits];
        let index = newData.findIndex(item => item.id === id);
        let item: quantityLimit = newData[index];

        newData.splice(index, 1, { ...item, ...row })
        this.setState({
            displayQuantityLimits: [...newData],
        })

    }

    onEditCancel = () => {
        this.generateDisplayQuantityLimit(this.state.currentConstage?.id);
        this.setState({
            isEditting: false,
        })
    }

    onEditSave = () => {
        const projectId = this.context;
        let promiseList = [];
        this.state.displayQuantityLimits.forEach((qw: quantityLimit) => {
            let scale = qw.scale;
            let constageId = this.state.currentConstage.id;
            let limit = {
                t2: qw.t2 || 0,
                t3: qw.t3 || 0,
                t4: qw.t4 || 0,
                t5: qw.t5 || 0,
            }
            let warningLimits = [...qw.warning_limit];
            let wl = warningLimits.find(item => item.stage_id === constageId);
            if (wl) {
                wl.values = limit;
            } else {
                warningLimits.push({
                    values: limit,
                    stage_id: constageId,
                })
            }
            warningLimits.forEach((item: warningLimit) => {
                for (let key of Object.keys(item.values)) {
                    item.values[key] = NumFixed(item.values[key] / scale, 8);
                }
            })
            let data = {
                warning_limit: warningLimits,
                initial_value: NumFixed(qw.initial_value / scale, 8) || 0,
                id: qw.id,
                is_evaluate: qw.is_evaluate || false,
            }
            let promise = updateQuantities(projectId, qw.id, data);
            promiseList.push(promise);
        })
        axios.all([promiseList])
            .then(res => {
                this.findQuantities();
            }).catch(err => {
                message.error('保存失败');
                console.log(err);
                // 把单位换算回去
                this.state.displayQuantityLimits.forEach((q: quantityLimit) => {
                    let limit = Object.assign([], q.warning_limit);
                    if (!!limit) {
                        limit.forEach(item => {
                            for (let key of Object.keys(item.values)) {
                                item.values[key] = NumFixed(item.values[key] * q.scale, 8);
                            }
                        })
                    }
                })
            })
    }



    goToAllQuantityLimitTable = () => {
        let projectId = this.context;
        this.props.history.push(`/projects/${projectId}/quantities/limits/table`);
    }

    // onFrequencyChange = (value: number) => {
    //     let mobjectId = +this.props.mobjectStore._selectedMobjectId;
    //     updateMobjectFrequency(mobjectId, value)
    //     .then(res => {
    //         this.setState({
    //             frequency: value
    //         })
    //     })
    // }

    exportWarningLimit = () => {
        let current: constage = this.state.currentConstage;
        if (!current) return;
        const projectId = this.context;
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        this.setState({
            exportLoading: true,
        })
        downloadQuantityWLCsv(projectId, current.id, mobjectId).then(res => {
            const blob = new Blob([res.data]);
            const link = document.createElement('a');
            const filename = `${current.code}.csv`;
            // const filename = '传感器表.csv';
            link.setAttribute('href', window.URL.createObjectURL(blob));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(err => {
            console.log(err);
            message.error('导出失败');
        }).finally(() => {
            this.setState({
                exportLoading: false,
            })
        })
    }

    onImportWLCsvChange = (info) => {
        if (info.file.status !== 'uploading') {
            // console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            let current: constage = this.state.currentConstage;
            if (!current) return;
            this.findQuantities();
            message.success(`导入成功`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    beforeUpload = (file) => {
        let current: constage = this.state.currentConstage;
        if (!current) return false;
        return true;
    }

    render() {
        let { isEditting, displayQuantityLimits } = this.state;
        return (
            <div className="quantity-detail-limit-pane">
                <div>
                    <div className="operations">
                        <Select
                            value={this.state.currentConstage?.id}
                            style={{ width: '280px' }}
                            disabled={isEditting}
                            onChange={this.onConstageSelectChange}>
                            {this.props.constages.map((item: constage, idx) => (
                                <Option value={item.id} key={idx}>{`${item.code}${item.name}`}</Option>
                            ))}
                        </Select>
                        {!this.state.isEditting ?
                            (
                                <Space>
                                    <Button style={{marginRight: '10px'}} shape="round" type="primary" onClick={() => { this.setState({ isEditting: true }) }}>编辑</Button>
                                    <Button shape="round" onClick={this.goToAllQuantityLimitTable}>查看全部</Button>
                                    <Button shape="round" onClick={this.exportWarningLimit}>导出</Button>
                                    <Upload
                                        name="file"
                                        action={this.state.uploadUrl}
                                        accept='.csv, .txt'
                                        showUploadList={false}
                                        onChange={this.onImportWLCsvChange}
                                        beforeUpload={this.beforeUpload}
                                    >
                                        <Button shape="round">导入</Button>
                                    </Upload>
                                    <Tooltip title="请以创建的施工步编号命名文件，如CS-01.csv">
                                        <Button size="small" type="link" icon={<InfoCircleFilled />}></Button>
                                    </Tooltip>
                                    {/* <Button shape="round" onClick={this.goToAllQuantityLimitTable}>全部表格</Button> */}
                                </Space>
                            ) :
                            (
                                <Space>
                                    <Button shape="round" onClick={this.onEditCancel}>取消</Button>
                                    <Button shape="round" type="primary" onClick={this.onEditSave}>保存</Button>
                                </Space>
                            )}

                    </div>
                    <Spin spinning={this.state.loading}>
                        {this.state.quantities.length === 0 ?
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
                            (
                                <div className="quantity-limit-tables">
                                    <Table
                                        dataSource={displayQuantityLimits}
                                        rowKey="id"
                                        pagination={false}
                                        bordered
                                        components={{
                                            body: {
                                                row: EditableRow,
                                                cell: EditableCell,
                                            }
                                        }}
                                    // rowClassName={(record, index) => (
                                    //     index % 2 !== 0 ? 'ribbon-row' : ''
                                    // )}
                                    >
                                        <Column
                                            title="指标"
                                            key="quantity_name"
                                            render={record => (
                                                <QuantityName record={record} />
                                            )} />
                                        <Column
                                            title="单位"
                                            dataIndex="unit_type"
                                            align="center"
                                            width={100} />
                                        <Column
                                            title={`初值(${this.state.constages[0]?.code || ''})`}
                                            dataIndex='initial_value'
                                            width="15%"
                                            align="right"
                                            onCell={(record: quantityLimit, idx) => ({
                                                record,
                                                editable: isEditting,
                                                validateDisable: true,
                                                dataIndex: 'initial_value',
                                                title: '初值',
                                                directEdit: true,
                                                handleSave: (e) => this.handleRowEdit(e, 'initial_value'),
                                            })}
                                        />
                                        <Column
                                            title="二级阈值"
                                            dataIndex='t2'
                                            width="12%"
                                            align="right"
                                            onCell={(record: quantityLimit, idx) => ({
                                                record,
                                                editable: isEditting,
                                                validateDisable: true,
                                                dataIndex: 't2',
                                                title: '二级阈值',
                                                directEdit: true,
                                                handleSave: (e) => this.handleRowEdit(e, 't2'),
                                            })}
                                        />
                                        <Column
                                            title="三级阈值"
                                            dataIndex='t3'
                                            width="12%"
                                            align="right"
                                            onCell={(record: quantityLimit, idx) => ({
                                                record,
                                                editable: isEditting,
                                                validateDisable: true,
                                                dataIndex: 't3',
                                                title: '三级阈值',
                                                directEdit: true,
                                                handleSave: (e) => this.handleRowEdit(e, 't3'),
                                            })}
                                        />
                                        <Column
                                            title="四级阈值"
                                            dataIndex='t4'
                                            width="12%"
                                            align="right"
                                            onCell={(record: quantityLimit, idx) => ({
                                                record,
                                                editable: isEditting,
                                                validateDisable: true,
                                                dataIndex: 't4',
                                                title: '四级阈值',
                                                directEdit: true,
                                                handleSave: (e) => this.handleRowEdit(e, 't4'),
                                            })}
                                        />
                                        <Column
                                            title="五级阈值"
                                            dataIndex='t5'
                                            width="12%"
                                            align="right"
                                            onCell={(record: quantityLimit, idx) => ({
                                                record,
                                                editable: isEditting,
                                                validateDisable: true,
                                                dataIndex: 't5',
                                                title: '五级阈值',
                                                directEdit: true,
                                                handleSave: (e) => this.handleRowEdit(e, 't5'),
                                            })}
                                        />
                                        <Column
                                            title="是否报警"
                                            key="is_evaluate"
                                            width={100}
                                            align="center"
                                            render={record => (
                                                isEditting ? (
                                                    <Switch
                                                        checkedChildren={<CheckOutlined />}
                                                        unCheckedChildren={<CloseOutlined />}
                                                        checked={record.is_evaluate}
                                                        onChange={(value) => this.handleRowEdit({ ...record, is_evaluate: value }, 'is_evaluate')} />
                                                ) :
                                                    <div>{record.is_evaluate ? <CheckOutlined /> : null}</div>
                                            )}
                                        />
                                    </Table>
                                </div>
                            )
                        }

                    </Spin>
                </div>
            </div>

        )
    }
}

export default withRouter(QuantityDetailLimitPane);