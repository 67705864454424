import React, { Component } from 'react'
import { withRoutePane } from '@/components/hoc/routePane'
import { Space, Button, Timeline, message, Upload } from 'antd'
import './constage-list.less';
import { constage } from '@/interface/constage';
import { ProjectContext } from '@/config/context';
import { queryConstages, createConstageFigures } from '@/config/api/constage';
import dayjs from 'dayjs';
import { ConstageListItem, ConstageAdd } from '@/components';
import { figureUploadUrl } from '@/config/api/document';
import {InboxOutlined} from '@ant-design/icons';
const { Dragger } = Upload;
interface IProps {

}

interface IState {
    constages: constage[]
    manageState: boolean
    isAdd: boolean

    // 批量上传
    fileList: any[]
    onUploadState: boolean
    createConstageFigureLoading: boolean
}
class ConstageListPage extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        constages: [],
        manageState: false,
        isAdd: false,

        fileList: [],
        onUploadState: false,
        createConstageFigureLoading: false,
    }

    componentDidMount() {
        this.findConstages();
    }

    findConstages = () => {
        let projectId = this.context;
        queryConstages(projectId)
            .then(res => {
                let constages = res.data || [];
                constages = constages.sort((a, b) => dayjs(a.start_time).valueOf() - dayjs(b.start_time).valueOf());
                this.setState({
                    constages: constages
                })
                if (constages.length === 0) {
                    this.setState({
                        isAdd: true
                    })
                }
            })
    }

    afterDelete = (row) => {
        this.setState({
            constages: this.state.constages.filter(item => item.id !== row.id)
        })
    }

    afterSave = () => {
        this.setState({
            isAdd: false,
        })
        this.findConstages();
    }

    // upload =================
    onFileChange = (info) => {
        const { status } = info.file;
        if (status !== 'uploading') {
            // console.log(info.file, info.fileList);
            this.setState({
                fileList: [...this.state.fileList, info.file]
            })
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handleUploadingRemove = (file) => {
        this.setState({
            fileList: this.state.fileList.filter(item => item.uid !== file.uid)
        })
        return true;
    }

    onUploadingCancel = () => {
        if (this.state.createConstageFigureLoading) return;
        this.setState({
            fileList: [],
            onUploadState: false,
        })
    }

    onMultiConstageFiguresSave = () => {
        this.setState({
            createConstageFigureLoading: true,
        })
        let figures = this.state.fileList
        .filter(item => item.response)
        .map(item => item.response.data);
        if (figures.length === 0) {
            this.setState({
                createConstageFigureLoading: false,
                fileList: [],
                onUploadState: false,
            })
            return;
        }
        createConstageFigures(this.context, figures)
        .then(res => {
            this.setState({
                createConstageFigureLoading: false,
                fileList: [],
                onUploadState: false,
            })
            this.findConstages();
        }).catch(err => {
            console.log(err);
            message.error('更新失败');
            this.setState({
                createConstageFigureLoading: false,
            })
        })
    }


    render() {
        let {onUploadState, manageState, isAdd} = this.state;
        return (
            <div className="constage-list-container">
                <div className="header">
                    {!manageState && !isAdd && !onUploadState && (
                        <Space>
                            <Button type="primary" onClick={() => this.setState({isAdd: true})}>添加</Button>
                            <Button onClick={() => this.setState({ onUploadState: true })}>批量上传图片</Button>
                            <Button onClick={() => this.setState({ manageState: true })}>管理</Button>
                        </Space>
                    )}
                    {manageState && !onUploadState && (
                        <Space>
                            <Button onClick={() => this.setState({ manageState: false })}>完成</Button>
                        </Space>
                    )}
                    {onUploadState && (
                        <Space size="large" style={{ marginLeft: '24px' }}>
                            <Button type="primary" onClick={this.onMultiConstageFiguresSave} loading={this.state.createConstageFigureLoading}>完成</Button>
                            <Button onClick={this.onUploadingCancel}>取消</Button>
                        </Space>
                    )}
                </div>
                {onUploadState && (
                    <div className="upload-file">
                        <Dragger
                            name="file"
                            multiple={true}
                            action={figureUploadUrl}
                            accept="image/*"
                            onChange={this.onFileChange}
                            onRemove={this.handleUploadingRemove}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">点击或拖拽图片格式文件到此区域以上传</p>
                            <p className="ant-upload-hint">
                                请使用横版16/9比例图片,图片按照“施工步编号 图片名称”的方式命名，系统会根据编号自动关联
                            </p>
                        </Dragger>
                    </div>
                )}
                <div className="content">
                    {this.state.isAdd && (
                        <ConstageAdd
                        onSave={this.afterSave}
                        onCancel={() => this.setState({isAdd: false})}/>
                    )}
                    <Timeline>
                        {this.state.constages.map((item, idx) => (
                            <Timeline.Item key={idx} >
                                <ConstageListItem 
                                constageDetail={item} 
                                manageState={this.state.manageState} 
                                afterDelete={() => this.afterDelete(item)}/>
                            </Timeline.Item>
                        ))}
                    </Timeline>

                </div>
            </div>
        )
    }
}

export default withRoutePane(ConstageListPage, '施工步列表');