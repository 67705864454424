import axios from "../axios"
import { AxiosResponse } from "axios"

export function queryQuantities(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/quantities`, {
        params: params
    })
}

export function queryQuantityDetail(projectId: number, quantityId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/quantities/${quantityId}`, {
        params: params
    })
}

export function createQuantities(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    console.log(data);
    return axios.post(`/projects/${projectId}/quantities`, data, {
        params: params
    })
}

export function updateQuantities(projectId: number, quantityId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/projects/${projectId}/quantities/${quantityId}`, data, {
        params: params
    })
}

export function downloadQuantityWLCsv(projectId: number, constageId: number, mobjectId?: number): Promise<AxiosResponse<any>> {
    return axios.get(`/quantities/warning-limit/export`, {
        params: {
            project_id: projectId,
            constage_id: constageId,
            mobject_id: mobjectId || null,
        },
        responseType: 'blob'
    })
}