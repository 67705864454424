import React, { Component } from 'react'
import { Dropdown, Menu } from 'antd'
import IconFont from '../../config/iconfont';
import { UsergroupAddOutlined, NodeIndexOutlined, ProjectOutlined } from '@ant-design/icons';
import { Gprops } from '@/config/props';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';
import { UserTypeEnum } from '@/routes/login/login';

interface IProps extends Gprops {
    userStore?: UserStore
}
@inject('userStore')
@observer
class Setting extends Component<IProps> {


    render() {
        let userType = this.props.userStore._userInfo?.type || null;
        const menu = (
            <Menu className="setting-menu">
                <Menu.Item
                    key="1"
                    icon={<ProjectOutlined />}
                    onClick={() =>
                        this.props.history.push(`${this.props.match.url}/setting`)
                    }>
                    项目设置
            </Menu.Item>
                <Menu.Item
                    key="2"
                    icon={<NodeIndexOutlined />}
                    onClick={() =>
                        this.props.history.push(`${this.props.match.url}/setting/quantities`)
                    }>
                    指标设置
            </Menu.Item>
                {userType && userType !== UserTypeEnum.CLIENT && (
                    <Menu.Item
                        key="3"
                        icon={<UsergroupAddOutlined />}
                        onClick={() =>
                            this.props.history.push(`${this.props.match.url}/setting/clients`)
                        }>
                        客户管理
                    </Menu.Item>
                )}
            </Menu>
        );

        return (
            <Dropdown overlay={menu} placement="bottomRight">
                <span className="action primary">
                    <IconFont type="icon-shezhi" className="avatar" />
                    <span className="name">设置</span>
                </span>
                {/* <Button type="link" size="large" icon={<IconFont type="icon-shezhi"/>}>设置</Button> */}
            </Dropdown>

        )
    }
}

export default withRouter(Setting);