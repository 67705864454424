import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import UserStore from '@/store/user-store'
import { globalMaterial, material } from '@/interface/model'
import { ProjectContext } from '@/config/context';
import { queryGlobalMaterials, queryEnterprisesMaterials, createEnterprisesMaterial } from '@/config/api/global-secmat';
import axios from '@/config/axios';
import { SourceType } from './section-add';
import { paramDict2Array } from '@/utils/utils';
import { MaterialTypes } from '@/utils/type';
import { Tabs, Button, message, Row, Col, Table, Modal } from 'antd';
import { queryMaterials, createMaterial } from '@/config/api/model';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import './material-add.less'
import MaterialPanel from './material-panel';

const { TabPane } = Tabs;
const { Column } = Table;
interface IProps {
    onCancel?: () => void
    afterSave?: () => void
    userStore?: UserStore
}

interface IState {
    selectedMaterialType: number,
    gMaterials: globalMaterial[],
    displayGMaterials: globalMaterial[],
    selectedGlobalMaterials: globalMaterial[],
    selectedRowKeys: string[],

    readOnlyModalVisible: boolean
    createModalVisible: boolean
    currentMaterial: material // 控制查看详情

    eMaterialSaveLoading: boolean // 控制截面弹窗保存键的loading
    saveLoading: boolean

    pageSize: number
}

@inject('userStore')
@observer
export default class MaterialAdd extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        selectedMaterialType: 1,
        gMaterials: [],
        selectedGlobalMaterials: [],
        selectedRowKeys: [],
        displayGMaterials: [],

        readOnlyModalVisible: false,
        createModalVisible: false,
        currentMaterial: null,

        eMaterialSaveLoading: false,
        saveLoading: false,

        pageSize: 30,
    }
    enterPriseId: number;
    maxSid: number = 0;

    componentDidMount() {
        this.enterPriseId = this.props.userStore._enterpriseId;
        this.findMaterials(this.state.selectedMaterialType)
    }

    componentDidUpdate() {
        let enterPriseId = this.props.userStore._enterpriseId;
        if (enterPriseId !== this.enterPriseId) {
            this.enterPriseId = this.props.userStore._enterpriseId;
            this.findMaterials(this.state.selectedMaterialType)
        }
    }

    findMaterials = (type: number) => {
        if (!this.enterPriseId) {
            return;
        }
        let gMaterialsPromise = queryGlobalMaterials();
        let eMaterialsPromise = queryEnterprisesMaterials(this.enterPriseId);

        axios.all([gMaterialsPromise, eMaterialsPromise])
            .then(res => {
                let gMaterials: globalMaterial[] = res[0].data || [];
                let eMaterials: globalMaterial[] = res[1].data || [];

                gMaterials = gMaterials.map(item => Object.assign(item, { key: `g${item.type}-${item.id}`, source: SourceType.DB }))
                eMaterials = eMaterials.map(item => Object.assign(item, { key: `e${item.type}-${item.id}`, source: SourceType.CUSTOM }))
                let materials = [...gMaterials, ...eMaterials]
                this.setState({
                    gMaterials: materials,
                    displayGMaterials: materials.filter(item => item.type === this.state.selectedMaterialType),
                })
            })
    }

    onTableRowSelectChange = (values) => {
        let materials = values.map(key => this.state.gMaterials.find(item => item.key === key)) || [];
        this.setState({
            selectedRowKeys: values,
            selectedGlobalMaterials: materials,
        })
    }

    onMaterialTypeChange = (key) => {
        let type = +key;
        this.setState({
            displayGMaterials: this.state.gMaterials.filter(item => item.type === type),
            selectedMaterialType: type
        })
    }

    onDeleteClick = (row) => {
        let key = row.key;
        this.setState({
            selectedRowKeys: this.state.selectedRowKeys.filter(item => item !== key),
            selectedGlobalMaterials: this.state.selectedGlobalMaterials.filter(item => item.key !== key),
        })
    }

    showMaterialModal = (row) => {
        this.setState({
            readOnlyModalVisible: true,
            currentMaterial: row
        })
    }

    showCreateMaterialModal = () => {
        this.setState({
            createModalVisible: true,
        })
    }

    addNewEnterpriseMaterial = (value) => {
        this.setState({
            eMaterialSaveLoading: true,
        })
        let data = Object.assign(value, {enterprise_id: this.props.userStore._enterpriseId})
        createEnterprisesMaterial(this.props.userStore._enterpriseId, data)
        .then(res => {
            let id = res.data;
            let key = `e${data.type}-${id}`;
            data = Object.assign(data, {
                id: res.data,
                key: key, 
                source: SourceType.CUSTOM,
            })
            this.setState({
                createModalVisible: false,
                gMaterials: [data, ...this.state.gMaterials],
                selectedGlobalMaterials: [data, ...this.state.selectedGlobalMaterials],
                selectedRowKeys: [key, ...this.state.selectedRowKeys]
            })
            if (data.type === this.state.selectedMaterialType) {
                this.setState({
                    displayGMaterials: [data, ...this.state.displayGMaterials]
                })
            }
        }).finally(() => {
            this.setState({
                eMaterialSaveLoading: false,
            })
        })

    }

    onSave = () => {
        if (this.state.selectedGlobalMaterials.length === 0) {
            this.props.onCancel();
        }
        this.setState({
            saveLoading: true,
        })
        queryMaterials(this.context)
        .then(res => {
            let pMaterials: material[] = res.data || [];
            if (pMaterials.length === 0) {
                this.maxSid = 0;
            } else {
                let maxSid = pMaterials.map(item => item.sid).reduce((a,b) => a > b ? a : b);
                this.maxSid = maxSid;
            }
            let promiseList = this.state.selectedGlobalMaterials.map((item, idx) => {
                let sid = this.maxSid + idx + 1;
                let data = Object.assign(item, {
                    sid: sid,
                    project_id: this.context,
                })
                return createMaterial(this.context, data);
            })
            return axios.all([promiseList]);
        }).then(res => {
            this.setState({
                saveLoading: false,
            })
            this.props.afterSave();
        }).catch( err => {
            console.log(err);
            message.error('创建失败');
            this.setState({
                saveLoading: false,
            })
        })
    }

    render() {
        let { selectedMaterialType, saveLoading, pageSize } = this.state
        let tabbars = paramDict2Array(MaterialTypes).filter(item => item.value.active).map((item, idx) => (
            <TabPane tab={item.value.alias} key={item.name} />
        ))
        return (
            <div className="material-add-container">
                <div className="page-header">
                    <Button type="link" size="large" icon={<ArrowLeftOutlined />} onClick={this.props.onCancel}>返回</Button>
                    <Button type="primary" loading={saveLoading} onClick={this.onSave}>保存</Button>
                </div>

                <div className="main">
                    <Row gutter={24}>
                        <Col xs={24} xl={12}>
                            <div className="section-library">
                                <Tabs
                                    defaultActiveKey={selectedMaterialType.toString()}
                                    type="card"
                                    onChange={this.onMaterialTypeChange}
                                    tabBarExtraContent={(
                                        <Button size="small" type="link" icon={<PlusOutlined />} onClick={this.showCreateMaterialModal}>自定义</Button>
                                    )}>
                                    {tabbars}
                                </Tabs>
                                <Table
                                    dataSource={this.state.displayGMaterials}
                                    rowKey="key"
                                    pagination={{pageSize: pageSize, onShowSizeChange: (current, size) => this.setState({pageSize: size})}}
                                    rowSelection={{
                                        type: 'checkbox',
                                        onChange: this.onTableRowSelectChange,
                                        selectedRowKeys: this.state.selectedRowKeys
                                    }}>
                                    <Column title="编号" dataIndex="key" />
                                    <Column title="材料名称" dataIndex="name" />
                                    <Column title="来源" dataIndex="source" render={(record, idx) => record === SourceType.DB ? '数据库' : '自定义'} />
                                    <Column title="" key="operations" render={(record, idx) => (
                                        <Button type="link" size="small" onClick={() => this.showMaterialModal(record)}>详情</Button>
                                    )} />
                                </Table>
                            </div>
                        </Col>
                        <Col xs={24} xl={12}>
                            <div className="section-selected">
                                <div className="title">选中的截面</div>
                                <Table
                                    dataSource={this.state.selectedGlobalMaterials}
                                    rowKey="key"
                                    pagination={false}
                                >
                                    <Column title="编号" dataIndex="key" />
                                    <Column title="类别" dataIndex="type" render={record => MaterialTypes[record]?.alias} />
                                    <Column title="材料名称" dataIndex="name" />
                                    <Column title="来源" dataIndex="source" render={(record, idx) => record === SourceType.DB ? '数据库' : '自定义'} />
                                    <Column title="" key="delete" render={(record, idx) => (
                                        <Button type="link" size="small" onClick={() => this.onDeleteClick(record)}>删除</Button>
                                    )} />
                                </Table>
                            </div>
                        </Col>
                    </Row>


                </div>


                <Modal
                    visible={this.state.readOnlyModalVisible}
                    destroyOnClose
                    width={800}
                    closable={false}
                    style={{top: '20px'}}
                    onCancel={() => this.setState({readOnlyModalVisible: false})}
                    footer={null}>
                    <MaterialPanel materialDetail={this.state.currentMaterial} readOnly/>
                </Modal>

                <Modal
                    visible={this.state.createModalVisible}
                    destroyOnClose
                    width={800}
                    closable={false}
                    style={{top: '20px'}}
                    footer={null}>
                    <MaterialPanel 
                    saveLoading={this.state.eMaterialSaveLoading}
                    materialDetail={this.state.currentMaterial} 
                    newType={this.state.selectedMaterialType}
                    onCancel={() => this.setState({createModalVisible: false})}
                    onSave={this.addNewEnterpriseMaterial}/>
                </Modal>
            </div>
        )
    }
}
