import axios from "../axios"
import { AxiosResponse } from "axios"

export function queryDashboardEvaluationsStatistics(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/dashboard/evaluations/statistics`, {
        params: params
    })
}

export function queryDashboardQuantities(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/v2/dashboard/quantities`, {
        params: params
    })
}

export function queryDashboardQuantitiesTypes(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/v2/dashboard/quantities/types`, {
        params: params
    })
}

export function queryDashboardQuantitiesStatistics(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/dashboard/quantities/statistics`, {
        params: params
    })
}

export function queryDashboardQuantitiesMax(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/dashboard/quantities/max`, {
        params: params
    })
}

export function queryDashboardSensors(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/dashboard/sensors`, {
        params: params
    })
}


