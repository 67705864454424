import axios from "../axios"
import { AxiosResponse } from "axios"

export function queryWarnings(params: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/warnings`, {
        params: params
    })
}

export function queryWarningRemindCount(userId: number, projectId: number): Promise<AxiosResponse<any>> {
    return axios.get(`/warnings/remind/${userId}`, {
        params: {
            projectId: projectId
        }
    })
}

// 发送报警已读
export function readWarningRemind(userId: number, data: any): Promise<AxiosResponse<any>> {
    return axios.post(`/warnings/remind/${userId}/read`, data)
}

export function sendWarningMessage(warningId: number, project_id: number): Promise<AxiosResponse<any>> {
    return axios.post(`/warnings/${warningId}/message`, null, {
        params: {
            project_id: project_id
        }
    })
}