import React, { Component } from 'react'
import './material-panel.less';
import { material } from '@/interface/model';
import { Form, Button, Space, Input, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { paramArrary2Dict, paramDict2Array, handleQuantityName, Transfer2DisplayScale, NumFixed, Transfer2BackEndScale } from '@/utils/utils';
import { EditOutlined } from '@ant-design/icons';
import { queryPrototypeMaterialsParams } from '@/config/api/global-secmat';
import { MaterialTypes } from '@/utils/type';
import { NumericInput } from '..';
import {InlineMath} from 'react-katex';
import { ParamUnits } from '@/utils/unit-type';
import { materialPicStaticLocation } from '@/config/api/document';
const { Option } = Select;

interface IProps {
    materialDetail: material
    onSave?: (values: material) => void
    onEdit?: () => void
    onCancel?: () => void,
    readOnly?: boolean
    newType?: number // 创建新截面时传默认type
    saveLoading?: boolean

    isEditing?: boolean

    sidHidden?: boolean
}

interface IState {
    // isEditing: boolean

}

export default class MaterialPanel extends Component<IProps, IState> {

    formRef = React.createRef<FormInstance>();
    matDetail: material = null;
    paramIn: any[] = []
    // state = {
    //     isEditing: false,
    // }

    componentDidMount() {
        this.matDetail = this.props.materialDetail;
        this.initForm()
    }

    componentDidUpdate() {
        if (!!this.props.newType) {
            return;
        }
        let matDetail = this.props.materialDetail;
        if (this.matDetail?.sid !== matDetail.sid) {
            this.matDetail = this.props.materialDetail
            this.initForm()
        }
    }

    initForm = () => {
        if (!!this.props.newType) {
            let type = this.props.newType
            // 获取当前type对应的params
            queryPrototypeMaterialsParams({
                type: type,
            }).then(res => {
                // let maxSid = pSections.map(item => item.sid).reduce((a,b) => a > b ? a : b);
                let param_in = paramDict2Array(res.data).map(item => Object.assign(item, { value: null }));
                this.paramIn = param_in;
                this.formRef.current?.setFieldsValue({
                    type: type,
                    param_in: param_in
                })
            })
        } else {
            let matDetail = this.props.materialDetail;
            let paramIn = paramDict2Array(matDetail?.param_in) || [];
            paramIn.forEach(item => {
                let name = item.name;
                let unit = ParamUnits[name];
                let scale = Transfer2DisplayScale(unit);
                item.value *= scale;
                item.value = NumFixed(item.value, 6);
                // item.value = +item.value.toFixed(1);
            })
            this.paramIn = paramIn;
            this.formRef.current?.setFieldsValue({
                name: matDetail?.name,
                type: matDetail?.type,
                sid: matDetail?.sid,
                param_in: paramIn,
            })
        }

    }


    onCancel = () => {
        if (!!this.props.newType) {
            this.props.onCancel();
        } else {
            this.initForm()
            // this.setState({
            //     isEditing: false,
            // })
            this.props.onCancel();
        }
    }

    onSave = (values) => {
        let param_in = values.param_in;
        param_in.forEach(item => {
            let name = item.name;
            let unit = ParamUnits[name];
            let scale = Transfer2BackEndScale(unit);
            item.value *= scale;
            // item.value = +item.value.toFixed(1);
        })
        let data = Object.assign(values, {
            param_in: paramArrary2Dict(param_in),
        })
        if (this.props.onSave) {
            this.props.onSave(data)
        }
    }

    onEditClick = () => {
        if (this.props.onEdit) {
            this.props.onEdit();
        }
    }

    render() {
        let { isEditing } = this.props;
        isEditing = isEditing || !!this.props.newType;
        let typeOptions = paramDict2Array(MaterialTypes).filter(item => item.value.active).map((item, idx) => (
            <Option key={idx} value={+item.name}>{item.value.alias}</Option>
        ))
        return (
            <div className="material-panel">
                <Form ref={this.formRef} labelCol={{ span: 6 }} autoComplete="off" onFinish={this.onSave}>
                    {!this.props.readOnly && (
                        <div className="operations">
                            <div className="title">信息</div>
                            {!isEditing && <Button type="primary" size="small" icon={<EditOutlined />} onClick={this.onEditClick}></Button>}
                            {isEditing && (
                                <Space>
                                    <Button type="primary" htmlType="submit" loading={this.props.saveLoading}>保存</Button>
                                    <Button onClick={this.onCancel}>取消</Button>
                                </Space>
                            )}
                        </div>
                    )}
                    <div className="top">
                        <div className="figure-shell">
                            <div className="figure">
                                {this.props.materialDetail ? (
                                    <img width="100%" height="100%" alt="材料图片" src={`${materialPicStaticLocation}${this.props.materialDetail.type}.png`} />
                                ) : (
                                    <img width="100%" height="100%" alt="材料图片" src={`${materialPicStaticLocation}${this.props.newType}.png`} />
                                )}
                            </div>
                        </div>
                        <div className="properties">
                            {(!this.props.newType && !this.props.readOnly) && !this.props.sidHidden && (
                                <Form.Item label="截面号" name="sid" rules={[{ required: true, message: '材料号不能为空' }]}>
                                    <Input readOnly />
                                </Form.Item>
                            )}
                            <Form.Item label="名称" name="name" rules={[{ required: true, message: '材料名称不能为空' }]}>
                                <Input readOnly={!isEditing} />
                            </Form.Item>
                            <Form.Item label="类别" name="type">
                                <Select disabled showArrow={false}>
                                    {typeOptions}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>

                    <div className="param-in">
                        <div className="title">输入参数</div>
                        <div>
                            <Form.List name="param_in">
                                {(fields, _) => (
                                    fields.map((field, idx) => (
                                        <Form.Item
                                            key={field.key}
                                            {...field}
                                            label={<InlineMath math={handleQuantityName(this.paramIn[idx]?.name)} />}
                                            name={[field.name, 'value']}
                                            rules={[{ required: true, message: '参数不能为空' }]}>
                                            <NumericInput 
                                            readOnly={!isEditing}
                                            suffix={<InlineMath math={handleQuantityName(ParamUnits[this.paramIn[idx]?.name])} />}/>
                                        </Form.Item>
                                    ))
                                )}
                            </Form.List>
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}
