import React, { Component } from 'react'
import { withRoutePane } from '@/components/hoc/routePane'
import './setting-client.less';
import { queryUsers, updateUser, createUsers, queryUsersProjects, linkUsersProjects, releaseUsersProjects } from '@/config/api/user';
import { ProjectContext } from '@/config/context';
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';
import { UserTypeEnum } from '../login/login';
import { user } from '@/interface/user';
import { Space, Button, Table, Input, message, Form, Modal } from 'antd';
import { EditableRow, EditableCell } from '@/components';
import axios from '@/config/axios';
import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { project } from '@/interface/project';
const { Column } = Table;
const { Search } = Input;
const { confirm } = Modal;
interface IProps {
    userStore?: UserStore
}
interface IState {
    clients: user[],    // 当前项目的客户
    clientsNotImport: user[], // 未导入的企业客户

    onManageState: boolean,
    onAddState: boolean,
    onImportState: boolean,
    clientIdSelected: number[],
    clientIdsImport: number[], // 即将导入的用户id

    searchInputValue: string,

    onUserAddSaveLoading: boolean
    onUserDeleteSaveLoading: boolean
    loading: boolean
    onUserImportSaveLoading: boolean
}
@inject('userStore')
@observer
class SettingClientPage extends Component<IProps, IState> {
    static contextType = ProjectContext;
    enterpriseId: number;
    state = {
        clients: [],
        clientsNotImport: [],

        onManageState: false,
        onAddState: false,
        onImportState: false,
        clientIdSelected: [],
        clientIdsImport: [],

        searchInputValue: '',

        onUserAddSaveLoading: false,
        onUserDeleteSaveLoading: false,
        loading: false,
        onUserImportSaveLoading: false,
    }
    componentDidMount() {
        let enterpriseId = this.props.userStore._enterpriseId;
        this.enterpriseId = enterpriseId;
        this.findUsers();
    }

    componentDidUpdate() {
        let enterpriseId = this.props.userStore._enterpriseId;
        if (this.enterpriseId !== enterpriseId) {
            this.enterpriseId = enterpriseId;
            this.findUsers();
        }
    }
    findUsers = () => {
        if (!this.enterpriseId) return;
        let value = this.state.searchInputValue;
        this.setState({
            loading: true,
        })
        // queryUsers({
        //     enterprise_id: this.enterpriseId,
        //     type: UserTypeEnum.CLIENT,
        // }).then(res => {
        //     let clients = res.data || [];
        //     this.setState({
        //         clients: clients
        //     })
        // })
        // 检索姓名
        let queryUsersPromise1 = queryUsers({
            enterprise_id: this.enterpriseId,
            type: UserTypeEnum.CLIENT,
            search_name: value,
        });
        // 检索公司名
        let queryUsersPromise2 = queryUsers({
            enterprise_id: this.enterpriseId,
            type: UserTypeEnum.CLIENT,
            search_company: value,
        });
        let clientsAll = []
        axios.all([queryUsersPromise1, queryUsersPromise2])
            .then(res => {
                let clients1: user[] = res[0].data || [];
                let clients2: user[] = res[1].data || [];
                // 交集
                let mixes = clients2.filter(x => clients1.some(y => y.id === x.id));
                // 差集
                let cuts = clients2.filter(x => !mixes.some(y => y.id === x.id));
                // 并集
                clientsAll = clients1.concat(cuts);
                let queryProjectsPromise = clientsAll.map(item => queryUsersProjects(item.id));
                return axios.all([...queryProjectsPromise])
            }).then(res => {
                let users = [];
                res.forEach((item, idx) => {
                    let projects: project[] = item.data;
                    let projectId = +this.context;
                    if (projects.some(p => p.id === projectId)) {
                        users.push(clientsAll[idx]);
                    }
                })
                let clientsNotImport = clientsAll.filter(x => !users.some(y => y.id === x.id));
                this.setState({
                    clients: users,
                    clientsNotImport: clientsNotImport,
                    loading: false,
                })
            })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            clientIdSelected: [...selectedRowKeys]
        })
    }

    onSearchContentChange = (value) => {
        this.findUsers();
    }

    onSearchInputChange = (e) => {
        this.setState({
            searchInputValue: e.target.value
        })
    }

    handleCellSave = (row: user, tag: string) => {
        let id = row.id;
        // let key = row.key;
        let newData = [...this.state.clients];
        let index = newData.findIndex(item => item.id === id);
        let item: user = newData[index];

        newData.splice(index, 1, { ...item, ...row })
        this.setState({
            clients: [...newData]
        })
        let data = {};
        data[tag] = row[tag];
        updateUser(row.id, data)
            .then(res => { })
            .catch(err => {
                message.error('更新失败');
                newData.splice(index, 1, { ...row, ...item })
                this.setState({
                    clients: [...newData]
                })
            })
    }

    onUserAddSave = (values) => {
        if (!this.enterpriseId) {
            message.error('无法获取企业id');
            return;
        }
        this.setState({
            onUserAddSaveLoading: true,
        })
        let password = CryptoJS.MD5(values.password).toString();
        let data = {
            ...values,
            confirm_password: null,
            type: UserTypeEnum.CLIENT,
            enterprise_id: this.enterpriseId,
            password: password,
        };
        createUsers(data)
            .then(res => {
                if (res.data === -1) {
                    message.error('邮箱或电话已经被注册');
                    this.setState({
                        onUserAddSaveLoading: false,
                    })
                    return;
                }
                // 关联本项目
                let userId = res.data;
                return linkUsersProjects(userId, this.context);
            }).then(res => {
                this.setState({
                    onUserAddSaveLoading: false,
                    onAddState: false,
                })
                this.findUsers();
            }).catch(err => {
                message.error('添加失败');
                console.log(err);
                this.setState({
                    onUserAddSaveLoading: false,
                })
            })
    }

    onUserDelete = () => {
        let promiseList = this.state.clientIdSelected.map(item => releaseUsersProjects(item, this.context));
        axios.all([...promiseList])
            .then(res => {
                this.setState({
                    clientIdSelected: [],
                    onManageState: false,
                })
                this.findUsers();
            })
    }

    showDeleteConfirm = () => {
        if (this.state.clientIdSelected.length === 0) {
            message.warning('请选择至少一条');
            return;
        }
        confirm({
            title: '确认移除吗?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk: () => this.onUserDelete(),
        });
    }

    onUserBlock = () => {
        if (this.state.clientIdSelected.length === 0) {
            message.warning('请选择至少一条');
            return;
        }
        let promiseList = this.state.clientIdSelected.map(item => updateUser(item, {
            is_block: true,
        }));
        axios.all([...promiseList])
            .then(res => {
                this.setState({
                    clientIdSelected: [],
                    onManageState: false,
                })
                this.findUsers();
            })
    }

    onUnBlock = (row) => {
        let id = row.id;
        updateUser(id, {
            is_block: false,
        }).then(res => {
            let newData = [...this.state.clients];
            let index = newData.findIndex(item => item.id === id);
            let item: user = newData[index];

            newData.splice(index, 1, { ...item, is_block: false })
            this.setState({
                clients: [...newData]
            })
        })
    }

    onClientsAllSelectedChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            clientIdsImport: selectedRowKeys
        })
    }

    onUserImportSave = () => {
        this.setState({
            onUserImportSaveLoading: true,
        })
        let promiseList = this.state.clientIdsImport.map(id => linkUsersProjects(id, this.context));
        axios.all([...promiseList])
        .then(res => {
            this.setState({
                onUserImportSaveLoading: false,
                onImportState: false,
            })
            this.findUsers();
        })

    }

    render() {
        let eid = this.props.userStore._enterpriseId;
        let { clients, onAddState, onImportState, onManageState, clientIdSelected } = this.state;
        return (
            <div className="setting-client-container">
                <div style={{ display: 'none' }}>{eid}</div>
                <div className="page-header">
                    {!onManageState && (
                        <Space size="large">
                            <Button onClick={() => this.setState({ onManageState: true })}>管理</Button>
                            <Button onClick={() => this.setState({ onImportState: true})}>导入客户</Button>
                            <Button type="primary" onClick={() => this.setState({ onAddState: true })}>新客户</Button>
                        </Space>
                    )}
                    {onManageState && (
                        <Space >
                            <Button type="link" onClick={() => this.setState({ onManageState: false, clientIdSelected: [] })}>返回</Button>
                            <Button danger onClick={this.showDeleteConfirm}>移除</Button>
                            <Button onClick={this.onUserBlock}>冻结</Button>
                            <span>*tips: 双击单元格可编辑</span>
                        </Space>
                    )}
                    <div className="fill-remaining-space"></div>
                    <Search
                        placeholder="输入检索内容"
                        onChange={this.onSearchInputChange}
                        onBlur={() => this.onSearchContentChange(this.state.searchInputValue)}
                        onSearch={(value) => this.onSearchContentChange(value)}
                        onPressEnter={() => this.onSearchContentChange(this.state.searchInputValue)}
                        style={{ width: 250 }} />
                </div>
                <div className="main">
                    <Table
                        className="client-table"
                        rowKey="id"
                        pagination={false}
                        loading={this.state.loading}
                        components={{
                            body: {
                                row: EditableRow,
                                cell: EditableCell,
                            }
                        }}
                        rowSelection={onManageState && {
                            type: 'checkbox',
                            onChange: this.onSelectChange,
                            selectedRowKeys: clientIdSelected
                        }}
                        dataSource={clients}>
                        <Column
                            title="用户名"
                            dataIndex="username"
                        />
                        <Column
                            title="姓名"
                            key="name"
                            width="15%"
                            onCell={(record: user, idx) => ({
                                record,
                                editable: onManageState,
                                dataIndex: 'name',
                                title: '姓名',
                                handleSave: (e) => this.handleCellSave(e, 'name'),
                            })}
                            render={record => (
                                record.is_block ?
                                    `${record.name}(已冻结)` :
                                    `${record.name}`
                            )} />
                        <Column
                            title="电话"
                            dataIndex="mobile"
                            width="10%"
                            onCell={(record: user, idx) => ({
                                record,
                                editable: onManageState,
                                dataIndex: 'mobile',
                                title: '电话',
                                handleSave: (e) => this.handleCellSave(e, 'mobile'),
                            })} />
                        <Column
                            title="邮箱"
                            dataIndex="email"
                            width="15%"
                            onCell={(record: user, idx) => ({
                                record,
                                editable: onManageState,
                                dataIndex: 'email',
                                title: '邮箱',
                                handleSave: (e) => this.handleCellSave(e, 'email'),
                            })} />
                        <Column
                            title="职位"
                            dataIndex="duty"
                            width="10%"
                            onCell={(record: user, idx) => ({
                                record,
                                editable: onManageState,
                                dataIndex: 'duty',
                                title: '职位',
                                handleSave: (e) => this.handleCellSave(e, 'duty'),
                            })} />
                        <Column
                            title="头衔"
                            dataIndex="title"
                            width="10%"
                            onCell={(record: user, idx) => ({
                                record,
                                editable: onManageState,
                                dataIndex: 'title',
                                title: '头衔',
                                handleSave: (e) => this.handleCellSave(e, 'title'),
                            })} />
                        <Column
                            title="公司"
                            dataIndex="company"
                            width="15%"
                            onCell={(record: user, idx) => ({
                                record,
                                editable: onManageState,
                                dataIndex: 'company',
                                title: '公司',
                                handleSave: (e) => this.handleCellSave(e, 'company'),
                            })} />
                        <Column
                            title="上次登录时间"
                            dataIndex="last_login_time"
                            width="15%"
                            render={record => dayjs(record).format('YYYY-MM-DD HH:mm')} />
                        <Column
                            title=""
                            key="operations"
                            render={(record: user) => (
                                record.is_block ?
                                    <Button type="link" onClick={() => this.onUnBlock(record)}>解冻</Button>
                                    :
                                    null
                            )} />
                    </Table>
                </div>


                <Modal
                    visible={onAddState}
                    onCancel={() => this.setState({ onAddState: false })}
                    title="添加用户"
                    footer={null}>
                    <div className="client-setting-user-add">
                        <Form onFinish={this.onUserAddSave} labelCol={{ span: 4 }} autoComplete="off">

                            <Form.Item label="用户名" name="username" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="密码" name="password" rules={[{ required: true }]}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                label="确认密码"
                                name="confirm_password"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入确认密码',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('两次密码不一致');
                                        },
                                    }),
                                ]}
                                dependencies={['password']}
                                hasFeedback>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item label="姓名" name="name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="电话" name="mobile">
                                <Input />
                            </Form.Item>
                            <Form.Item label="邮箱" name="email">
                                <Input />
                            </Form.Item>
                            <Form.Item label="职位" name="duty">
                                <Input />
                            </Form.Item>
                            <Form.Item label="头衔" name="title">
                                <Input />
                            </Form.Item>
                            <Form.Item label="公司" name="company">
                                <Input />
                            </Form.Item>
                            <div className="operations" style={{ textAlign: 'right' }}>
                                <Space>
                                    <Button type="primary" htmlType="submit" loading={this.state.onUserAddSaveLoading}>保存</Button>
                                    <Button onClick={() => this.setState({ onAddState: false })}>取消</Button>
                                </Space>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    width={800}
                    visible={onImportState}
                    onCancel={() => this.setState({ onImportState: false })}
                    title="导入客户"
                    footer={null}>
                    <div className="client-setting-user-import">
                        <Table 
                        dataSource={this.state.clientsNotImport}
                        rowKey="id"
                        pagination={false}
                        rowSelection={{
                            type: 'checkbox',
                            onChange: this.onClientsAllSelectedChange,
                            selectedRowKeys: this.state.clientIdsImport
                        }}>
                            <Column title="姓名" dataIndex="name"/>
                            <Column title="电话" dataIndex="mobile"/>
                            <Column title="邮箱" dataIndex="email"/>
                            <Column title="职位" dataIndex="duty"/>
                            <Column title="公司" dataIndex="company"/>
                        </Table>
                        <div style={{textAlign: 'right', marginTop: '10px'}}>
                            <Space>
                                <Button onClick={() => this.setState({onImportState: false, clientIdsImport: []})}>取消</Button>
                                <Button type="primary" onClick={this.onUserImportSave} loading={this.state.onUserImportSaveLoading}>保存</Button>
                            </Space>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default withRoutePane(SettingClientPage, '客户管理');