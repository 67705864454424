import axios from "../axios"
import { AxiosResponse } from "axios"
import qs from 'qs';

export function queryQuantityData(quantityId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/quantities/${quantityId}/data`, {
        params: params
    })
}

export function queryQuantityCurrentDataList(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/quantities/data/current`, {
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'comma'})
        }
    })
}

export function queryQuantityCurrentDataById(quantityId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/quantities/${quantityId}/data/current`, {
        params: params
    })
}

export function updateQuantityData(quantityId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/quantities/${quantityId}/data`, data, {
        params: params
    })
}

export function deleteQuantityData(quantityId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`/quantities/${quantityId}/data`, {
        params: params
    })
}

export function querySensorQuantityData(quantityId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/sensors/quantities/${quantityId}/data`, {
        params: params
    })
}

export function deleteSensorQuantityData(sensorQuantityId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`/sensors/quantities/${sensorQuantityId}/data`, {
        params: params
    })
}


export function downloadQuantityDataCsv(params: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/quantities/data/export`, {
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'comma'})
        },
        responseType: 'blob'
    })
}

export function downloadSensorQuantityDataCsv(params: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/sensors/quantities/data/export`, {
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'comma'})
        },
        responseType: 'blob'
    })
}