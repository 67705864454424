import React, { Component } from 'react'
import './global-install.less';
import { globalInstall } from '@/interface/install';
import { queryEnterprisesInstallations } from '@/config/api/global-install';
import { observer, inject } from 'mobx-react';
import UserStore from '@/store/user-store';
import { Table, Button, Modal } from 'antd';
import { queryGlobalDboxs, queryGlobalSensors } from '@/config/api/global-device';
import axios from '@/config/axios';
import { globalDbox } from '@/interface/dbox';
import { globalSensor } from '@/interface/sensor';
import InstallAddPanel from './install-add';
const { Column } = Table;

export const DeviceVarietyLevel = {
    DBOX: 1,
    SENSOR: 2,
}
interface IProps {
    userStore?: UserStore
}

interface IState {
    gInstalls: globalInstall[]
    gDboxs: globalDbox[]
    gSensors: globalSensor[],
    visible: boolean,
}

@inject('userStore')
@observer
export default class GlobalInstallPage extends Component<IProps, IState> {

    enterpriseId: number;
    state = {
        gInstalls: [],
        gDboxs: [],
        gSensors: [],
        visible: false,
    }

    componentDidMount() {
        this.findInstalls();
    }

    componentDidUpdate() {
        let eid = this.props.userStore._enterpriseId;
        if (this.enterpriseId !== eid) {
            this.enterpriseId = eid;
            this.findInstalls();
        }
    }

    findInstalls = () => {
        let eid = this.props.userStore._enterpriseId;
        if (!eid) return;
        let queryGlobalDboxPromise = queryGlobalDboxs();
        let queryGlobalSensorPromise = queryGlobalSensors();
        let queryEnterpriseInstallsPromise = queryEnterprisesInstallations(eid);
        axios.all([queryGlobalDboxPromise, queryGlobalSensorPromise, queryEnterpriseInstallsPromise]).then(res => {
            let gInstalls: globalInstall[] = res[2].data || [];
            let gDboxs: globalDbox[] = res[0].data || [];
            let gSensors: globalSensor[] = res[1].data || [];
            gInstalls.forEach(item => {
                if (item.level === DeviceVarietyLevel.DBOX) {
                    let dbox = gDboxs.find(dbox => dbox.type === item.device_type);
                    item.device_name = dbox?.name;
                    item.level_name = '采集箱';
                } else if (item.level === DeviceVarietyLevel.SENSOR) {
                    let sensor = gSensors.find(sensor => sensor.type === item.device_type);
                    item.device_name = sensor?.name;
                    item.level_name = '传感器';
                }
            })
            this.setState({
                gDboxs: gDboxs,
                gSensors: gSensors,
                gInstalls: gInstalls,
            })
        })
    }

    render() {
        let eid = this.props.userStore._enterpriseId;
        return (
            <div className="global-install-container">
                <div style={{display: 'none'}}>{eid}</div>
                <div style={{textAlign: 'right', marginBottom: '10px'}}>
                    <Button type="primary" onClick={() => this.setState({visible: true})}>添加</Button>
                </div>
                <Table
                    dataSource={this.state.gInstalls}
                    rowKey="id"
                    bordered
                    pagination={false}>
                    <Column title="安装方法名称" dataIndex="name"/>
                    <Column title="所属层级" dataIndex="level_name"/>
                    <Column title="设备类别" dataIndex="device_name" />
                    <Column title="附件" key="document"  render={record => (
                        <Button type="link" size="small">下载</Button>
                    )}/>
                </Table>

                <Modal
                visible={this.state.visible}
                footer={null}
                destroyOnClose
                onCancel={() =>this.setState({visible: false})}
                closable={false}>
                    <InstallAddPanel 
                    gDboxs={this.state.gDboxs} 
                    gSensors={this.state.gSensors}
                    onCancel={() => this.setState({visible: false})}/>
                </Modal>
            </div>
        )
    }
}
