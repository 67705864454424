import React, { Component, Fragment } from 'react'
import MobjectStore from '@/store/mobject-store'
import { inject, observer } from 'mobx-react'
import { ProjectContext } from '@/config/context';
import { quantityGroup, mobject, pattern } from '@/interface/mobject';
import { queryMobjectDetail, queryQuantityGroups, queryGroupPatternDetail, updatePatternParams } from '@/config/api/mobject';
import { queryQuantities } from '@/config/api/quantity';
import axios from '@/config/axios';
import './quantity-detail.less';
import classnames from 'classnames';
import { Button, Space, message, Table, Row, Col, Spin, Tooltip, Checkbox } from 'antd';
import { paramObj } from '@/interface/std';
import { paramDict2Array, paramArrary2Dict, handleQuantityName, Transfer2DisplayScale, transferParamsToDisplayScale, transferParamsToBackendScale } from '@/utils/utils';
import { warningGradeDict } from '@/utils/type';
import { NumericInput } from '..';
import { quantity, globalQuantity, quantityData, warningLimit } from '@/interface/quantity';
import { QuantityLevelTypes, DistributionTypes } from './quantity-pattern-setting';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import { EditOutlined, SyncOutlined } from '@ant-design/icons';
import LineChartBoard from '../charts/line-chart-board';
import QuantityDetailStore from '@/store/quantity-detail-store';
import { constage } from '@/interface/constage';
import { queryQuantityData, queryQuantityCurrentDataList } from '@/config/api/quantity-data';
import QuantityDataTool from './quantity-data-tool';
import dayjs from 'dayjs';
import AlertTag from '../alert-tag';
import QuantityDetailLimitPane from './quantity-detail-limit';
import { InlineMath } from 'react-katex';
import { patternPicStaticLocation } from '@/config/api/document';
import { QuantityName } from '../QuantityName';
import { ParamUnits } from '@/utils/unit-type';
import { Gprops } from '@/config/props';
import { withRouter } from 'react-router-dom';
import querystring from 'querystring';
import { queryProjectDetail } from '@/config/api/project';
import { project } from '@/interface/project';

const { Column } = Table;

interface IProps extends Gprops {
    mobjectStore?: MobjectStore
    quantityDetailStore?: QuantityDetailStore
    onSettingStateClick?: () => void,
    onGroupTabChange?: (key: string) => void,
}
interface IState {
    quantityGroups: quantityGroup[],
    currentQuantityGroup: quantityGroup,
    quantities: quantity[],
    quantityDataList: quantityData[],
    groupLoading: boolean,
    patternParams: paramObj[], // 缓存当前pattern的参数
    showDirectTab: boolean,
    showInitialAndLimitValue: boolean
    isEditting: boolean,
    constages: constage[],

    dataLoading: boolean,
    autoFlushData: boolean, // 后台自动刷新数据

    monitorType: number,
}
@inject('mobjectStore', 'quantityDetailStore')
@observer
class QuantityDetailPane extends Component<IProps, IState> {
    static contextType = ProjectContext;
    previousMobjectId: number = 0;
    projectGlobalQuantities = [];
    groupTabKey: string = null;
    timer: any;
    state = {
        quantityGroups: [],
        currentQuantityGroup: null,
        quantities: [],
        quantityDataList: [],
        groupLoading: false,
        patternParams: [],
        showDirectTab: false,
        showInitialAndLimitValue: false,
        isEditting: false,
        constages: [],

        dataLoading: false,
        autoFlushData: false,

        monitorType: 2,
    }
    isAutoDataLoading = false;

    componentDidMount() {
        const projectId = this.context;
        queryProjectDetail(projectId).then(res => {
            let projectInfo: project = res.data;
            this.setState({
                monitorType: projectInfo?.monitor_type || 2,
            })
        })
        
        queryProjectGlobalQuantities(this.context)
            .then(res => {
                this.projectGlobalQuantities = res.data;

                // 自动切换到制定的group tab
                let locationSearch = this.props.location.search.substr(1);
                let queryParams = querystring.parse(locationSearch);
                if (!!queryParams.group) {
                    let group = queryParams.group;
                    this.groupTabKey = group as string;
                    if (group === 'dq') {
                        // 转到测量量
                        this.generateData(null, true);
                        this.onDirectTabClick();
                    } else if (group === 'limit') {
                        // 转到阈值
                        this.generateData(null, true);
                        this.onInitialAndLimitValueTabClick();
                    } else if (!!+group) {
                        this.generateData(+group, false);
                    }
                } else {
                    this.generateData();
                }
            })

        this.timer = setInterval(
            () => {
                if (!!this.state.showInitialAndLimitValue) return;
                if (!this.state.autoFlushData) return;
                if (this.isAutoDataLoading) return;
                this.isAutoDataLoading = true;
                this.generateQuantityAndData(this.state.quantities || [], false);
            },
            1000 * 10,
        );
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }


    componentDidUpdate() {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        if (this.previousMobjectId !== mobjectId) {
            this.previousMobjectId = mobjectId;

            let locationSearch = this.props.location.search.substr(1);
            let queryParams = querystring.parse(locationSearch);
            let group = queryParams.group;
            if (this.state.showDirectTab || (!!group && group === 'dq')) {
                this.generateData(null, true);
                this.onDirectTabClick();
            } else if (this.state.showInitialAndLimitValue || (!!group && group === 'limit')) {
                this.generateData(null, true);
                this.onInitialAndLimitValueTabClick()
            } else {
                this.setState({
                    quantityGroups: [],
                    currentQuantityGroup: null,
                    showDirectTab: false,
                    showInitialAndLimitValue: false,
                    patternParams: [],
                    quantities: [],
                })
                this.generateData(+group);
            }
        }
    }

    generateData = (groupId: number = null, noCurrent: boolean = false) => {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        this.previousMobjectId = mobjectId;
        let mobjectObj: mobject;
        queryMobjectDetail(mobjectId)
            .then(res => {
                let mobjectDetail: mobject = res.data;
                // mobjectDetail.id = mobjectId; // TODO: 开发阶段模拟数据采用
                mobjectObj = mobjectDetail;

                let queryQuantityGroupsPromise = queryQuantityGroups(mobjectId, {
                    project_id: this.context,
                    mobject_id: mobjectId,
                });
                let globalQuantitiesPromise = queryProjectGlobalQuantities(this.context);
                return axios.all([queryQuantityGroupsPromise, globalQuantitiesPromise])
            }).then(res => {
                let quantityGroups: quantityGroup[] = res[0].data || [];
                quantityGroups = quantityGroups.sort((a, b) => b.level - a.level);
                this.projectGlobalQuantities = res[1].data;
                let constages: constage[] = [...(mobjectObj?.constages || [])];
                constages.sort((a, b) => dayjs(a.start_time).valueOf() - dayjs(b.start_time).valueOf());
                this.setState({
                    quantityGroups: quantityGroups,
                    constages: constages,
                })
                if (noCurrent) {
                    return;
                }
                let current = quantityGroups.length > 0 ? quantityGroups[0] : null;
                if (!!+groupId) {
                    current = quantityGroups?.find(item => item.id === groupId) || null;
                    this.setState({
                        currentQuantityGroup: current
                    })
                }
                this.findQuantityGroupContent(current);
            })
    }

    findQuantityGroupContent = (group: quantityGroup) => {
        if (!group) return;
        this.setState({
            groupLoading: true,
        })
        let queryGroupPatternPromise = queryGroupPatternDetail(group.mobject_id, group.id);
        let queryQuantitiesPromise = queryQuantities(this.context, {
            mobject_id: group.mobject_id,
            group_id: group.id,
            activated: true,
        });
        axios.all([queryGroupPatternPromise, queryQuantitiesPromise])
            .then(res => {
                group.current_pattern = res[0].data || null;
                let quantities: quantity[] = res[1].data || [];
                quantities = quantities.filter((q: quantity) => q.level !== QuantityLevelTypes.DIRECT_Q);
                group.quantities = quantities;
                let params = paramDict2Array(group.current_pattern?.params);
                params = transferParamsToDisplayScale(params);
                this.setState({
                    currentQuantityGroup: Object.assign({}, group),
                    patternParams: params,
                })
                this.generateQuantityAndData(quantities);
            }).finally(() => {
                this.setState({
                    groupLoading: false,
                })
            })

    }

    generateQuantityAndData = (quantities: quantity[], loading: boolean = true) => {
        let projectId = this.context;
        let promiseList = [];
        this.setState({
            dataLoading: loading,
        })
        quantities = quantities.map(item => Object.assign({}, item));
        quantities.forEach((q: quantity) => {
            q.constages = this.state.constages;
            let gQuantity: globalQuantity = this.projectGlobalQuantities.find(item => item.type === q.type);
            if (gQuantity) {
                q.chinese_name = gQuantity.chinese_name;
                q.unit_type = gQuantity.unit_type;
                q.precision = gQuantity.precision;
                let scale = Transfer2DisplayScale(q.unit_type);
                q.scale = scale;
                q.valid_data = gQuantity.valid_data;
            }
            let params = {
                start_time: this.props.quantityDetailStore._startTime?.getTime() || null,
                end_time: this.props.quantityDetailStore._endTime?.getTime() || null,
                sampling_period: this.props.quantityDetailStore._samplingPeriod || null,
                data_type: this.props.quantityDetailStore._dataType || 1,
            }
            if (params.start_time == null) {
                params['limit'] = 300;
            }
            else if (params.sampling_period == null) {
                let end = params.end_time;
                if (end == null) {
                    end = new Date().getTime();
                }
                let start = params.start_time;
                // TODO: 计算降采样
                params.sampling_period = this.calculateDownSample(start, end);
                this.props.quantityDetailStore.setSamplingPeriod(params.sampling_period);
            }
            if (params.sampling_period === -1) {
                params.sampling_period = null;
            }
            let promise = queryQuantityData(q.id, params);
            promiseList.push(promise);
        })
        let qids = quantities.map(item => item.id);
        let queryQuantityCurrentDataPromise = queryQuantityCurrentDataList({
            project_id: projectId,
            quantity_ids: qids,
        })
        queryQuantityCurrentDataPromise.then(res => {
            let dataList = res.data || [];
            dataList.forEach(item => {
                if (!item) {
                    return;
                }
                let id = +item['id'];
                let value = +item['value'];
                let q: quantity = quantities.find(qs => qs.id === id);
                q.current_value = value * q.scale;
                if (q.valid_data != null && Math.abs(q.valid_data) < Math.abs(value)) {
                    q.current_grade = -1;
                } else if (!q.is_evaluate) {
                    q.current_grade = 0;
                } else {
                    q.current_grade = this.checkQuantityEvaluation(q.warning_limit, value, q.constages);
                }
            })
            this.setState({
                quantities: [...quantities]
            })
            return axios.all([...promiseList]);
        }).then(res => {
            let data: quantityData[] = res.map(item => item.data).filter((item: quantityData) => !!item.id) || [];
            quantities.forEach((q: quantity, idx) => {
                let qData = data[idx];
                if (!!qData && !!qData.data) {
                    qData.data = qData.data?.map(item => {
                        item.value *= q.scale
                        return item;
                    }) || [];
                    qData.max = qData.max ? qData.max * q.scale : null;
                    qData.min = qData.min ? qData.min * q.scale : null;
                    qData.mean = qData.mean ? qData.mean * q.scale : null;
                    qData.precision = q.precision;
                    qData.unit_type = q.unit_type;
                    qData.chinese_name = q.chinese_name;
                    qData.warning_limit = q.warning_limit;
                    qData.is_evaluate = q.is_evaluate;
                    qData.scale = q.scale;
                }
                // q.quantityData = data[idx];
            })
            quantities = quantities.sort((a, b) => b.current_grade - a.current_grade);
            this.setState({
                quantities: [...quantities],
                quantityDataList: data,
                dataLoading: false,
            })
        }).finally(() => {
            this.setState({
                dataLoading: false,
            })
            this.isAutoDataLoading = false;
        })
    }

    checkQuantityEvaluation = (warningLimits: warningLimit[], value: number, constages: constage[]) => {
        let time = dayjs().valueOf();
        if (constages.length === 0) return 0;
        let stage: constage = constages.find((item: constage) => dayjs(item.start_time).valueOf() <= time && dayjs(item.end_time).valueOf() >= time);
        if (!stage) stage = constages[constages.length - 1];
        let limit = warningLimits.find((item: warningLimit) => item.stage_id === stage.id)?.values;
        if (!limit)
            return 0;
        if (Math.abs(limit['t5']) < 1e-9)
            return 0;
        if (!value)
            return 0;
        if (limit['t5'] <= Math.abs(value)) {
            return 5;
        } else if (limit['t4'] <= Math.abs(value)) {
            return 4;
        } else if (limit['t3'] <= Math.abs(value)) {
            return 3;
        } else if (limit['t2'] <= Math.abs(value)) {
            return 2;
        } else {
            return 1;
        }
    }

    calculateDownSample = (start: number, end: number) => {
        const date1 = dayjs(start);
        const date2 = dayjs(end);
        if (date2.diff(date1, 'M') >= 2) {
            return 86400;
        }
        if (date2.diff(date1, 'M') >= 1) {
            return 43200;
        }
        if (date2.diff(date1, 'd') >= 10) {
            return 3600;
        }
        if (date2.diff(date1, 'd') >= 1) {
            return 300;
        }
        return null;
    }


    onGroupTabChange = (group: quantityGroup) => {
        if (this.state.currentQuantityGroup?.id === group.id) return;
        this.setState({
            showDirectTab: false,
            showInitialAndLimitValue: false,
            currentQuantityGroup: null,
            quantities: [],
            patternParams: [],
        })
        if (this.props.onGroupTabChange) {
            this.props.onGroupTabChange(group.id.toString());
        }
        this.findQuantityGroupContent(group);
    }

    onDirectTabClick = () => {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        if (this.state.isEditting) {
            this.onPatternParamEditCancel();
        }
        if (this.props.onGroupTabChange) {
            this.props.onGroupTabChange('dq');
        }
        this.setState({
            showDirectTab: true,
            showInitialAndLimitValue: false,
            currentQuantityGroup: null,
            quantities: [],
        })
        queryQuantities(this.context, {
            mobject_id: mobjectId,
            level: QuantityLevelTypes.DIRECT_Q,
        }).then(res => {
            let quantities: quantity[] = res.data || [];
            this.generateQuantityAndData(quantities);
        })
    }

    onInitialAndLimitValueTabClick = () => {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        if (this.state.isEditting) {
            this.onPatternParamEditCancel();
        }
        if (this.props.onGroupTabChange) {
            this.props.onGroupTabChange('limit');
        }
        this.setState({
            showDirectTab: false,
            showInitialAndLimitValue: true,
            currentQuantityGroup: null,
            quantities: [],
        })
        queryQuantities(this.context, {
            mobject_id: mobjectId,
        }).then(res => {
            let quantities: quantity[] = res.data || [];
            this.generateQuantityAndData(quantities);
        })
    }

    // 当模式参数发生变更时，重新生成并同步模式参数字典
    onParamsInputChange = (value, item: paramObj) => {
        item.value = value;
        this.setState({
            patternParams: [...this.state.patternParams]
        })
    }

    onPatternParamEditCancel = () => {
        let pattern = this.state.currentQuantityGroup?.current_pattern;
        let params = paramDict2Array(pattern?.params);
        params = transferParamsToDisplayScale(params);
        this.setState({
            isEditting: false,
            patternParams: params
        })
    }

    onPatternParamEditSave = () => {
        let params = this.state.patternParams;
        params = params.map(item => ({ name: item.name, value: +item.value }))
        params = transferParamsToBackendScale(params);
        let currentPattern: pattern = this.state.currentQuantityGroup?.current_pattern;
        if (currentPattern) {
            updatePatternParams(currentPattern.id, {
                params: paramArrary2Dict(params)
            }, {
                project_id: this.context
            })
                .then(res => {
                    currentPattern.params = params;
                    this.setState({
                        isEditting: false,
                        currentQuantityGroup: Object.assign({}, this.state.currentQuantityGroup)
                    })
                    // console.log(this.state.currentQuantityGroup.current_pattern.params)
                }).catch(err => {
                    message.error('更新失败');
                })
        }
    }

    onQueryParamsChange = () => {
        this.generateQuantityAndData(this.state.quantities);
    }

    transferQUantityNameWithChinese = (record: quantity) => {
        if (record.location && record.location !== '') {
            return (
                <Fragment>
                    <span>{record.chinese_name}</span>
                    <InlineMath math={handleQuantityName(record.name)} />
                    <span>-{record.location}</span>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <span>{record.chinese_name}</span>
                    <InlineMath math={handleQuantityName(record.name)} />
                </Fragment>
            )
        }
    }

    afterDeleteData = () => {
        this.generateQuantityAndData(this.state.quantities);
    }

    render() {
        let mobjectDetail = this.props.mobjectStore._mobjectDetail;
        if (this.previousMobjectId !== mobjectDetail?.id) return null;
        let { currentQuantityGroup } = this.state;
        const PatternInfo = (
            <Row gutter={20}>
                <Col xs={24} xxl={11}>
                    <div className="pattern">
                        <div className="figure" style={{ height: '240px', width: '320px' }}>
                            {
                                currentQuantityGroup?.current_pattern?.figure_url ?
                                    <img width="100%" height="100%" alt="模式图" src={`${patternPicStaticLocation}${currentQuantityGroup.current_pattern?.figure_url}`} />
                                    :
                                    null
                            }
                        </div>
                        {/* {this.state.currentQuantityGroup?.distribution_type !== DistributionTypes.MICRO && ( */}
                        {true && (
                            <div className="pattern-content">
                                <div className="pattern-name">
                                    {mobjectDetail?.name}.
                                    {this.state.currentQuantityGroup?.current_pattern?.name}
                                </div>
                                {this.state.patternParams.length > 0 && (
                                    <Fragment>
                                        <div className="title">
                                            定位参数
                                        <EditOutlined className={classnames({
                                            'params-edit-btn': true,
                                            'isEditting': this.state.isEditting
                                        })} onClick={() => { this.setState({ isEditting: true }) }} />
                                        </div>
                                        {this.state.patternParams?.map((item: paramObj, idx: number) => (
                                            <div className="params-item" key={idx}>
                                                <span className="label">{
                                                    <InlineMath math={handleQuantityName(item.name)} />
                                                }</span>
                                                <NumericInput
                                                    readOnly={!this.state.isEditting}
                                                    size="small"
                                                    defaultValue={item.value}
                                                    value={item.value.toString()}
                                                    onChange={(value) => this.onParamsInputChange(value, item)}
                                                    suffix={<span>{ParamUnits[item.name]}</span>} />
                                            </div>
                                        ))}
                                        <Space className={classnames({
                                            'editting-btn-group': true,
                                            'isEditting': this.state.isEditting
                                        })}>
                                            <Button size="small" onClick={this.onPatternParamEditCancel}>取消</Button>
                                            <Button size="small" type="primary" onClick={this.onPatternParamEditSave}>保存</Button>
                                        </Space>
                                    </Fragment>
                                )}

                            </div>
                        )}
                    </div>

                </Col>
                <Col xs={24} xxl={13}>
                    <div className="pattern-content quantity-evaluate">
                        {/* <div className="title">指标</div> */}
                        <Table
                            dataSource={this.state.quantities}
                            rowKey="id"
                            pagination={false}
                            rowClassName={(record, index) => (
                                index % 2 !== 0 ? 'ribbon-row' : ''
                            )}>
                            <Column
                                title="指标"
                                key="quantity_name"
                                width={150}
                                align="left"
                                render={(record: quantity) => <QuantityName record={record} />} />
                            <Column title="当前数值" width={150} key="current_value" align="right" render={(record: quantity) => (
                                record.current_value ?
                                    `${record.current_value?.toFixed(+record.precision) || ''} ${record.unit_type || ''}`
                                    :
                                    ``
                            )} />
                            {/* <Column title="单位" width={80} dataIndex="unit_type" align="center" /> */}
                            <Column title="评估" key="current_grade" align="center" render={(record: quantity) => (
                                <AlertTag current_grade={record.current_grade} />
                            )} />
                            <Column title="响应" className="response-column" key="current_grade" align="center" render={(record: quantity) => {
                                let desp = warningGradeDict[record.current_grade || 0]['description'] || '';
                                let desp_use = warningGradeDict[record.current_grade || 0]['description_use'] || '';
                                if (this.state.monitorType === 2) return desp;
                                return desp_use;
                                // warningGradeDict[record.current_grade || 0]['description']
                            }} />
                        </Table>
                    </div>
                </Col>
            </Row>

        )
        return (
            <div className="quantity-display-container">
                <div className="quantity-group-container">
                    <div className="quantity-group-select">
                        <div className='group-tab-item button'>
                            <Button type="primary" onClick={this.props.onSettingStateClick}>配置指标</Button>
                        </div>
                        {this.state.quantityGroups.map((group: quantityGroup) => (
                            <div key={group.id} className={classnames({
                                'group-tab-item': true,
                                'group-tab-item-active': group.id === this.state.currentQuantityGroup?.id
                            })} onClick={() => this.onGroupTabChange(group)}>
                                {group.name}
                            </div>
                        ))}
                        <div key='direct' className={classnames({
                            'group-tab-item': true,
                            'group-tab-item-active': this.state.showDirectTab,
                            'item-hidden': this.state.quantityGroups.length === 0,
                        })} onClick={this.onDirectTabClick}>
                            {'测量值'}
                        </div>
                        <div className="group-tab-item-divider"></div>
                        <div key='limitvalue' className={classnames({
                            'group-tab-item': true,
                            'group-tab-item-active': this.state.showInitialAndLimitValue,
                            'item-hidden': this.state.quantityGroups.length === 0,
                        })} onClick={this.onInitialAndLimitValueTabClick}>
                            {'初值&阈值'}
                        </div>
                    </div>
                    <div className="quantity-group-content">
                        {this.state.showDirectTab || this.state.showInitialAndLimitValue ? null : PatternInfo}
                        {this.state.showInitialAndLimitValue ?
                            (
                                <QuantityDetailLimitPane constages={this.state.constages} />
                            )
                            :
                            (
                                <Fragment>

                                    <div className="quantity-data-tools">
                                        <Space>
                                            <Tooltip title="更新数据">
                                                <Button
                                                    shape="circle"
                                                    type="primary"
                                                    icon={<SyncOutlined spin={this.state.dataLoading} />}
                                                    onClick={() => this.generateQuantityAndData(this.state.quantities || [])}></Button>
                                            </Tooltip>
                                            <Checkbox checked={this.state.autoFlushData} onChange={() => { this.setState({ autoFlushData: !this.state.autoFlushData }) }}>后台刷新</Checkbox>
                                        </Space>
                                        <div className="fill-remaining-space"></div>
                                        <QuantityDataTool constages={this.state.constages} onQueryParamsChange={this.onQueryParamsChange} />
                                    </div>
                                    <Spin spinning={this.state.dataLoading}>
                                        {this.state.quantityDataList.map((q: quantityData, idx) => (
                                            q.data ?
                                                <div key={q.id} className={classnames({
                                                    'chart-ribbon-row': true
                                                })}>
                                                    <LineChartBoard
                                                        q={q}
                                                        constages={this.state.constages}
                                                        afterDelete={this.afterDeleteData} />
                                                </div>
                                                :
                                                null
                                        ))}
                                    </Spin>

                                </Fragment>
                            )
                        }

                    </div>
                </div>

            </div>
        )
    }
}

export default withRouter(QuantityDetailPane);