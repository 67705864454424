import React, { Component } from 'react'
import './sensor-add.less';
import { Select, message, Form, Row, Col, Input, InputNumber, Space, Button, Radio } from 'antd';
import { globalSensor } from '@/interface/sensor';
import UserStore from '@/store/user-store';
import { globalInstall } from '@/interface/install';
import { inject, observer } from 'mobx-react';
import { ProjectContext } from '@/config/context';
import { FormInstance } from 'antd/lib/form';
import { queryEnterprisesInstallations } from '@/config/api/global-install';
import { createSensors } from '@/config/api/sensor';
import axios from '@/config/axios';
import { queryDboxs } from '@/config/api/dbox';
import { dbox } from '@/interface/dbox';
import { DeviceVarietyLevel } from '@/routes/global-install/global-install';
const { Option } = Select;
interface IProps {
    onCancel?: () => void
    onSave?: () => void
    gSensors: globalSensor[]
    userStore?: UserStore
}

interface IState {
    gSensorTypes: any[]
    gSensorMarkings: string[]
    gInstalls: globalInstall[]
    loading: boolean
    dboxs: dbox[]
}
@inject('userStore')
@observer
export default class SensorAddPanel extends Component<IProps, IState> {
    static contextType = ProjectContext;
    formRef = React.createRef<FormInstance>();
    state = {
        gSensorTypes: [],
        gSensorMarkings: [],
        gInstalls: [],
        loading: false,
        dboxs: [],
    }

    componentDidMount() {
        let gSensors = this.props.gSensors;
        let gSensorTypes = Array.from(new Set(
            gSensors.map(item => (`${item.type}__${item.name}`))
        )).map(item => {
            let tmp = item.split('__');
            return { label: tmp[1], value: +tmp[0] };
        })
        this.setState({
            gSensorTypes: gSensorTypes,
        })
        this.findDboxs();
    }

    findDboxs = () => {
        let projectId = this.context;
        queryDboxs({
            project_id: projectId,
        }).then(res => {
            this.setState({
                dboxs: res.data || []
            })
        })
    }

    onTypeChange = (value) => {
        let gSensors = this.props.gSensors;
        let gSensorMarkings = gSensors.filter(item => item.type === value).map(item => item.marking);
        this.setState({
            gSensorMarkings: gSensorMarkings,
        })
        this.formRef.current?.setFieldsValue({
            manufacturer: null,
            range: null,
            precision: null,
        })
        queryEnterprisesInstallations(this.props.userStore._enterpriseId)
            .then(res => {
                let gInstalls = res.data || [];
                gInstalls = gInstalls.filter(item => item.level === DeviceVarietyLevel.SENSOR);
                this.setState({
                    gInstalls: gInstalls
                })
            })
    }

    onMarkingChange = (value) => {
        let gSensor = this.props.gSensors.find(item => item.marking === value);
        this.formRef.current?.setFieldsValue({
            manufacturer: gSensor.manufacturer,
            range: gSensor.range,
            precision: gSensor.precision,
        })
    }

    onSave = (values) => {
        this.setState({
            loading: true,
        })
        let { copy, ...data } = values;
        data = {
            ...data,
            manage_status: 1,
            project_id: this.context,
        }
        let promiseList = [];
        for (let idx = 0; idx < copy + 1; idx++) {
            if (idx >= 1) {
                promiseList.push(createSensors({...data, name: `${data.name}-${idx}`}));
            } else {
                promiseList.push(createSensors(data));
            }
            
        }
        axios.all([...promiseList])
            .then(res => {
                this.setState({
                    loading: false,
                })
                this.props.onSave();
            }).catch(err => {
                message.error('创建失败');
                console.log(err);
                this.setState({
                    loading: false,
                })
            })
    }

    render() {
        return (
            <div className="sensor-add-container">
                <Form
                    ref={this.formRef}
                    labelCol={{ span: 5 }}
                    autoComplete="off"
                    onFinish={this.onSave}>
                    <Row gutter={10}>
                        <Col xs={24} xl={12}>
                            <Form.Item label="编号" name="name" rules={[{ required: true }]}>
                                <Input placeholder="请输入传感器在项目中的编号" />
                            </Form.Item>
                            <Form.Item label="传感器类型" name="type" rules={[{ required: true }]}>
                                <Select onChange={this.onTypeChange}>
                                    {this.state.gSensorTypes.map((item, idx) => (
                                        <Option key={idx} value={item.value}>{item.label}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="型号" name="marking" rules={[{ required: true }]}>
                                <Select onChange={this.onMarkingChange}>
                                    {this.state.gSensorMarkings.map(item => (
                                        <Option key={item} value={item}>{item}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="厂家" name="manufacturer">
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item label="量程" name="range">
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item label="精度" name="precision">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={12}>
                            <Form.Item label="采集箱" name="dbox_id">
                                <Select allowClear>
                                    {this.state.dboxs.map(item => (
                                        <Option key={item.id} value={item.id}>{`${item.name}`}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="安装方法" name="install_id">
                                <Select onChange={this.onTypeChange}>
                                    {this.state.gInstalls.map(item => (
                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="工作状态" name="work_status" initialValue={0}>
                                <Radio.Group className="sensor-work-radio" size="small" buttonStyle="solid">
                                    <Radio.Button value={0}>离线</Radio.Button>
                                    <Radio.Button value={1}>工作</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="复制个数" name="copy" initialValue={0}>
                                <InputNumber min={0} step={1} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'right' }}>
                        <Space size="large">
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>保存</Button>
                            <Button onClick={this.props.onCancel} >取消</Button>
                        </Space>
                    </div>
                </Form>
            </div >
        )
    }
}
