import axios from "../axios"
import { AxiosResponse } from "axios"
import qs from 'qs';

export function queryMobjects(params: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/mobjects`, {
        params: params
    })
}

export function queryMobjectDetail(mobjectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/mobjects/${mobjectId}`, {
        params: params
    })
}
// 创建对象
export function createMobjects(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/mobjects`, data, {
        params: params
    })
}
// 更新对象信息数据
export function updateMobjectDetail(mobjectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/mobjects/${mobjectId}`, data, {
        params: params
    })
}
// 删除对象
export function deleteMobjectDetail(mobjectId: number): Promise<AxiosResponse<any>> {
    return axios.delete(`/mobjects/${mobjectId}`)
}
// 获取监测对象的频次
export function queryMobjectFrequency(mobjectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/mobjects/${mobjectId}/frequency`, {
        params: params
    })
}
// 更新监测对象的频次
export function updateMobjectFrequency(mobjectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/mobjects/${mobjectId}/frequency`, null, {
        params: params
    })
}

// 获取已经创建并激活的group列表
export function queryQuantityGroups(mobjectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/mobjects/${mobjectId}/quantity-groups`, {
        params: params
    })
}
// 新增group，后台查找是否有已经钝化的相同type的group，有则激活
export function createQuantityGroup(mobjectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/mobjects/${mobjectId}/quantity-groups`, data, {
        params: params
    })
}

// 删除group，实际上在后台做了钝化
export function deleteQuantityGroup(mobjectId: number, groupId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`/mobjects/${mobjectId}/quantity-groups/${groupId}`, {
        params: params
    })
}

// 获取对象底下的所有pattern列表
export function queryMobjectPatternList(mobjectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/mobjects/${mobjectId}/patterns`, {
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'comma'})
        }
    })
}
// 获取group对应的pattern
export function queryGroupPatternDetail(mobjectId: number, groupId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/mobjects/${mobjectId}/quantity-groups/${groupId}/pattern`, {
        params: params
    })
}

export function createPattern(mobjectId: number, groupId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    if (!!data.id) {data.id = null};
    return axios.post(`/mobjects/${mobjectId}/quantity-groups/${groupId}/patterns`, data, {
        params: params
    })
}

export function updatePatternParams(patternId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/patterns/${patternId}`, data, {
        params: params
    })
}

export function deletePattern(patternId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`/patterns/${patternId}`, {
        params: params
    })
}

export function copyMobject(mobjectId: number, name: string): Promise<AxiosResponse<any>> {
    return axios.post(`/mobjects/${mobjectId}/duplication`, null, {
        params: {
            name: name,
        }
    })
}