export interface IFMenuBase {
    key: string;
    title: string;
    icon?: string;
    component?: string;
    query?: string;
    auth?: string;
    route?: string;
    /** 是否登录校验，true不进行校验（访客） */
    login?: boolean;
    default_open?: boolean;
    admin?: boolean
}

export interface IFMenu extends IFMenuBase {
    subs?: IFMenu[];
}

const menus: IFMenu[] = [
    {key: '/dashboard', title: '控制台', icon: 'icon-dashboard'},
    {
        key: '/base', 
        title: '项目概况', 
        icon: 'icon-02', 
        default_open: true,
        subs: [
            { key: '/information', title: '基本信息'},
            { key: '/standards', title: '规范依据'},
            { key: '/conditions', title: '现场条件'},
        ]
    },
    {
        key: '/models', 
        title: '结构模型', 
        icon: 'icon-jiegou', 
        subs: [
            { key: '/models/sections', title: '截面'},
            { key: '/models/materials', title: '材料'},
            { key: '/models/nodes', title: '节点'},
            { key: '/models/elements', title: '单元'},
        ]
    },
    {key: '/constages', title: '施工步', icon: 'icon-anzhuangshigong'},
    {key: '/drawings', title: '监测施工图', icon: 'icon-jianshe'},
    {
        key: '/mobjects', 
        title: '对象指标', 
        icon: 'icon-jiance', 
        default_open: true,
        subs: [
            { key: '/mobjects/structures', title: '结构'},
            { key: '/mobjects/members', title: '构件'},
            { key: '/mobjects/joints', title: '节点'},
            { key: '/mobjects/environments', title: '环境'},
        ]
    },
    {
        key: '/devices', 
        title: '设备', 
        icon: 'icon-shuju', 
        default_open: true,
        subs: [
            { key: '/dboxs', title: '采集箱'},
            { key: '/sensors', title: '传感器'},
        ]
    },
    {key: '/sites', title: '现场管理', icon: 'icon-jijuweixiushenqing'},
    {key: '/quantity-link', title: '指标关联', icon: 'icon-link'},
    {key: '/quantity-query', title: '查询指标', icon: 'icon-chaxuncaijia'},
    {key: '/reports', title: '监测报告', icon: 'icon-baogao'},
];

export default menus;


export const GlobalMenus: IFMenu[] = [
    {key: '/projects', title: '项目列表', icon: 'icon-xiangmu1'},
    {
        key: '/global', 
        title: '全局库', 
        icon: 'icon-02', 
        default_open: true,
        subs: [
            { key: '/global/quantities', title: '指标库', icon: 'icon-canshu', },
            { key: '/global/sections', title: '截面库', icon: 'icon-mian' },
            { key: '/global/materials', title: '材料库', icon: 'icon-zu1'},
            { key: '/global/devices', title: '设备库', icon: 'icon-shebei1'},
            { key: '/global/installs', title: '安装方法', icon: 'icon-anzhuanggongju'},
            { key: '/global/standards', title: '规范依据', icon: 'icon-biaozhun'},
            { key: '/global/planbs', title: '应急预案库', icon: 'icon-yingjiyuan'},
        ]
    },
    {key: '/enterprise', title: '企业管理', icon: 'icon-yonghuguanli', admin: true},
]

export const BackendMenus: IFMenu[] = [
    {key: '/backend/enterprises', title: '企业列表', icon: 'icon-qiye'},
    {key: '/backend/users', title: '用户列表', icon: 'icon-fuzerenicon'},
]