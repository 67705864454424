export const warningGradeDict = {
    '-1': {
        'color': '#f68240',
        'alias': '测点损坏',
        'res': '测点异常',
        'description': '',
        'description_use': '',
    },
    0: {
        'color': '#c8c8c8',
        'alias': '不评估',
        'res': '无',
        'description': '',
        'description_use': '',
    },
    1: {
        'color': '#00944a',
        'alias': '一级', 
        'res': '非常安全',
        'description': '可正常施工',
        'description_use': '可正常使用',
    },
    2: {
        'color': '#4fb981',
        'alias': '二级',
        'res': '安全',
        'description': '可正常施工，须注意现场情况',
        'description_use': '可正常使用，须关注重点指标变化状态',
    },
    3: {
        'color': '#efa829',
        'alias': '三级',
        'res': '重点关注',
        'description': '谨慎施工，宜重点关注指标异常的区域',
        'description_use': '可正常使用，宜重点关注指标异常的区域',
    },
    4: {
        'color': '#f68240',
        'alias': '四级',
        'res': '预警',
        'description': '限制施工，即时伺服计算评估，通知危险点位置，准备临时加固措施',
        'description_use': '限制使用，降低结构负载，通知使用人员潜在危险点位置，准备临时加固措施',
    },
    5: {
        'color': '#e45331',
        'alias': '五级',
        'res': '紧急',
        'description': '通知作业人员撤离，立即采取临时加固措施',
        'description_use': '通知使用人员撤离，立即采取临时加固措施'
    },
}

export const SectionTypes = {
    1: { name: 'HR-H', alias: '热轧H型钢', shape_type: 1, active: true, },
    2: { name: 'HR-T', alias: '热轧T型钢' },
    3: { name: 'HR-I', alias: '热轧工字钢', shape_type: 1 },
    4: { name: 'HR-C', alias: '热轧槽钢' },
    5: { name: 'HR-L', alias: '等边角钢' },
    6: { name: 'HR-L1', alias: '不等边角钢' },
    7: { name: 'HR-CT', alias: '无缝钢管', shape_type: 3, active: true, },
    8: { name: 'HR-F', alias: '扁钢' },

    21: { name: 'CF-C', alias: '卷边C型钢', shape_type: 4, active: true, },
    22: { name: 'CF-C1', alias: 'C型钢', shape_type: 4 },
    23: { name: 'CF-Z', alias: '斜卷边Z型钢', shape_type: 4, active: true, },
    24: { name: 'CF-Z1', alias: '直卷边Z型钢', shape_type: 4 },
    25: { name: 'CF-ST', alias: '方钢管', shape_type: 2 },
    26: { name: 'CF-RT', alias: '矩形管', shape_type: 2 },
    27: { name: 'CF-L', alias: '等边L形' },
    28: { name: 'CF-L1', alias: '卷边等边L形' },

    41: { name: 'WS-H', alias: '焊接H形', shape_type: 1, active: true, },
    42: { name: 'WS-T', alias: '焊接T形' },
    43: { name: 'WS-RT', alias: '焊接箱形', shape_type: 2, active: true, },
    44: { name: 'WS-RT1', alias: '外伸箱形' },
    45: { name: 'WS-CT', alias: '焊接圆管', shape_type: 3, active: true, },
    46: { name: 'WS-CR', alias: '十字形' },
    47: { name: 'WS-TC', alias: '日字形' },
    48: { name: 'WS-C', alias: '焊接C形' },

    922: { name: 'LC-DD2', alias: '双肢双连-槽钢', shape_type: 5, active: true, },

    951: { name: 'LC-FP1', alias: '四肢正连-4圆管', shape_type: 6, active: true, },

    171: {name: 'GE-R', alias: '实心矩形', shape_type: 2, active: true, },
    172: {name: 'GE-C', alias: '实心圆形', shape_type: 3, active: true, },
}

export const MaterialTypes = {
    1: { name: 'STEEL', alias: '钢', active: true },
    2: { name: 'CONCRETE', alias: '混凝土', active: true },
    3: { name: 'REBAR', alias: '钢筋', active: true },
    4: { name: 'PRE-REBAR', alias: '预应力钢筋' },
    5: { name: 'STEEL-LINK', alias: '钢拉杆' },
    6: { name: 'SUS', alias: '不锈钢' },
    7: { name: 'ALUM-ALLOY', alias: '铝合金' },
}

export const SectionShapeTypes = {
    1: 'H形',
    2: '矩形',
    3: '圆形',
    4: '槽形',
    5: '双肢格构',
    6: '四肢格构',
}

export const monitorType = {
    1: '试验',
    2: '施工过程',
    3: '健康运营',
    4: '全过程',
}

export const monitorRank = {
    1: '一级',
    2: '二级',
    3: '三级',
}

export const sensorWorkStatus = {
    '0': {
        'color':'#B3B5B9',
        'value': '离线',       
    },
    '1': {
        'color':'#009547',
        'value': '工作',
    },
    '2': {
        'color':'#f68240',
        'value': '异常',
    },
}
    
export const dboxWorkStatus = {
    '0': {
        'color':'#B3B5B9',
        'value': '离线',       
    },
    '1': {
        'color':'#009547',
        'value': '工作',
    },
    // '3': {
    //     'color':'#00a1d3',
    //     'value': '在线',
    // },
}


export const deviceManageStatus = {
    1: '在库',
    2: '已安装',
    3: '已验收',
    4: '返工或维修',
    5: '已拆除',
    6: '已回收',
    7: '报废或丢失',
}


export const MobjectBaseType = {
    '1': {
        'alias': '节点',
        'name': 'joints',
        'figureUrl': 'https://monika.tncet.com/api/static/pFigures/15/5baa1a7cbfea46d7bd36a0d0d2692555.jpg',
    },
    '2': {
        'alias': '构件',
        'name': 'members',
        'figureUrl': 'https://monika.tncet.com/api/static/pFigures/15/5baa1a7cbfea46d7bd36a0d0d2692555.jpg',
    },
    '3': {
        'alias': '结构',
        'name': 'structures',
        'figureUrl': 'https://monika.tncet.com/api/static/pFigures/15/5baa1a7cbfea46d7bd36a0d0d2692555.jpg',
    },
    '4': {
        'alias': '环境',
        'name': 'environments',
        'figureUrl': 'https://monika.tncet.com/api/static/pFigures/15/5baa1a7cbfea46d7bd36a0d0d2692555.jpg',
    },
}

export const ProcedureType = {
    '1': '方案',
    '2': '安装',
    '3': '数据采集',
    '4': '评估',
}

export const QuantityLevelAlias = {
    0: '信号量',
    1: '测量值',
    2: '数据源',
    3: '监测指标',
}

export const UserTypes = {
    0: 'root',
    1: 'admin',
    2: '工程师',
    3: '现场',
    4: '客户',
}

export const SectionProtoName = {
    1: 'HR-H',
    2: 'HR-T',
    3: 'HR-I',
    4: 'HR-C',
    5: 'HR-L',
    6: 'HR-L1',
    7: 'HR-CT',
    8: 'HR-F',

    21: 'CF-C',
    22: 'CF-C1',
    23: 'CF-Z',
    24: 'CF-Z1',
    25: 'CF-ST',
    26: 'CF-RT',
    27: 'CF-L',
    28: 'CF-L1',

    41: 'WS-H',
    42: 'WS-T',
    43: 'WS-RT',
    44: 'WS-RT1',
    45: 'WS-CT',
    46: 'WS-CR',
    47: 'WS-TC',
    48: 'WS-C',

    61: 'SC-H1',

    922: 'LC-DD2',
    951: 'LC-FP1',


    111: 'RC-R1',
    112: 'RC-R2',
    113: 'RC-T',
    114: 'RC-C',

    171: 'GE-R',
    172: 'GE-C',
}