import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { RProps } from '@/config/props';
import { ProjectContext } from '@/config/context';
import './constage-detail.less';
import { Row, Col, Spin } from 'antd';
import { constage } from '@/interface/constage';
import { queryConstageDetail, deleteConstage } from '@/config/api/constage';
import { ConstageDetailInfo, ConstageDetailEdit, ConstageMobjectPanel } from '@/components';

interface IProps extends RProps {

}

interface IState {
    constageDetail: constage
    isEdit: boolean
}
@inject('routeStore')
@observer
export default class ConstageDetail extends Component<IProps, IState> {
    stageId: number;
    tab_uuid: string;
    static contextType = ProjectContext;
    state = {
        constageDetail: null,
        isEdit: false,
    }

    constructor(props) {
        super(props);
        let id = this.props.match.params['stageId'];
        this.stageId = id;
        this.tab_uuid = this.props.routeStore.addRoutePane(`施工步${id}`, this.props.match.url);

    }

    componentDidMount() {
        let id = this.props.match.params['stageId'];
        this.generateData(id);
    }

    componentDidUpdate() {
        let id = this.props.match.params['stageId'];
        if (this.stageId !== id) {
            this.stageId = id;
            this.generateData(id);
        }
    }

    generateData = (id) => {
        queryConstageDetail(this.context, id)
            .then(res => {
                let stage: constage = res.data;
                this.props.routeStore.changeRoutePaneName(this.tab_uuid, `施工步${stage.code}`);
                // let fig = stage?.figures[0] || null;
                this.setState({
                    constageDetail: stage,
                })
            })
    }

    onSave = () => {
        this.setState({
            isEdit: false,
        })
        this.generateData(this.stageId);
    }
    
    onDelete = () => {
        deleteConstage(this.context, this.stageId)
        .then(res => {
            this.props.routeStore.removeRoutePane(this.tab_uuid);
            this.props.history.push(`/projects/${this.context}/constages`);
        })
    }

    render() {
        let { constageDetail } = this.state;
        if (!constageDetail) {
            return <Spin size="large" />;
        }
        return (
            <div className="constage-detail-container">
                <Row gutter={10}>
                    <Col xs={24} xxl={17}>
                        {!this.state.isEdit && (
                            <ConstageDetailInfo 
                            constageDetail={constageDetail} 
                            onDelete={this.onDelete}
                            editClick={() => this.setState({
                                isEdit: true
                            })}/>
                        )}
                        {this.state.isEdit && (
                            <ConstageDetailEdit
                            constageDetail={constageDetail}
                            onCancel={() => this.setState({isEdit: false})}
                            onSave={this.onSave}/>
                        )}
                    </Col>
                    <Col xs={24} xxl={7}>
                        <ConstageMobjectPanel constageDetail={constageDetail}/>
                    </Col>
                </Row>
            </div>
        )
    }
}
