import React, { Component } from 'react'
import { mobject } from '@/interface/mobject';
import classnames from 'classnames';
import { Input, Button, Empty, Spin } from 'antd';

import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
interface IProps {
    mobjects: mobject[]
    onChange: (mobject: mobject) => void
    selectedMobjectId: number
    onItemAddClick?: () => void
    loading?: boolean
}

interface IState {
    searchValue: string
}
export default class MobjectListPane extends Component<IProps, IState> {
    static defaultProps = {
        loading: false
    }

    state = {
        searchValue: '',
    }

    onItemClick = (mobject: mobject) => {
        this.props.onChange(mobject);
    }

    onItemAddClick = () => {
        this.props.onItemAddClick()
    }

    onSearchValueChange = (e) => {
        this.setState({
            searchValue: e.target.value,
        })
    }

    render() {
        // if (this.props.loading) {
        //     return (
        //         <Spin/>
        //     )
        // }

        const loop = data => data.filter(item => item.name?.indexOf(this.state.searchValue) >= 0);
        if (this.props.mobjects?.length === 0 && !this.props.loading) {
            return (
                <div className="mobject-select-blank">
                    {/* <Empty /> */}
                    <Empty
                        imageStyle={{
                            height: 100,
                        }}
                        description={
                            <span>
                                还没有监测对象哦
                            </span>
                        }
                    >
                        <Button type="primary" icon={<PlusOutlined />} style={{ width: '200px', height: '100px', fontSize: '22px' }} onClick={this.onItemAddClick}>
                            新建
                        </Button>
                    </Empty>

                </div>
            )
        }
        return (
            <div className={classnames({
                'mobject-select-pane': true,
            })}>
                {/* <div className="title">监测对象</div> */}
                <Input placeholder="搜索" suffix={<SearchOutlined />} className="search-input" onChange={this.onSearchValueChange}/>
                <Button style={{ width: '100%', margin: '8px 0' }} type="dashed" size="small" icon={<PlusOutlined />} onClick={this.onItemAddClick}>添加</Button>
                <div className="mobject-select-list">
                    {loop(this.props.mobjects).map((item: mobject, idx) => (
                        <div key={item.id} className={classnames({
                            'mobject-select-item': true,
                            'mobject-select-item-active': this.props.selectedMobjectId === item.id,
                        })} onClick={() => this.onItemClick(item)}>{item.name}</div>
                    ))}
                </div>
            </div>
        )
    }
}
