import React, { Component } from 'react'
import { queryProjectDetail, queryProjectFigures, updateProjectDetail, deleteProjectFigures, createProjectFigures } from '@/config/api/project'
import { ProjectContext } from '@/config/context';
import { 
    queryProjectPeople, 
    queryProjectSchedules, 
    queryProjectPlanbs, 
    deleteProjectPeople, 
    createProjectPeople, 
    updateProjectPeople, 
    deleteProjectSchedule, 
    updateProjectSchedule, 
    createProjectSchedule, 
    createProjectPlanb, 
    deleteProjectPlanb,
    updateProjectPlanb} from '@/config/api/plan';
import axios from '@/config/axios';
import { project } from '@/interface/project';
import { projectPeople, projectSchedule, projectPlanb, globalPlanb } from '@/interface/plan';
import { figure } from '@/interface/document';
import { Form, Checkbox, Space, Button, Radio, Input, Upload, Modal, Table, message } from 'antd';
import { paramDict2Array, leftZeroInt } from '@/utils/utils';
import { DatePicker, NumericInput } from '..';
import { PlusOutlined, ImportOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { inject, observer } from 'mobx-react';
import RouteStore from '@/store/route-store';
import { FormInstance } from 'antd/lib/form';
import dayjs from 'dayjs';
import { figureUploadUrl, figureStaticLocation } from '@/config/api/document';
import ProjectStore from '@/store/project-store';
import { AxiosResponse } from 'axios';
import { queryEnterprisePlanbs } from '@/config/api/global-planb';
import UserStore from '@/store/user-store';
const { TextArea } = Input;
const { Column } = Table;
export const StructureTypes = {
    '1': '桁架',
    '2': '网壳',
    '3': '网架',
    '4': '框架剪力墙',
    '5': '框架',
    '6': '空间结构',
    '7': '高层结构',
    '8': '砖混结构',
    '9': '排架',
    '10': '砌体结构',
    '11': '其他',
}

export const StructureTypeOriginMap = {
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5': false,
    '6': false,
    '7': false,
    '8': false,
    '9': false,
    '10': false,
    '11': false,
}

export const ConstructionTypes = {
    '1': '整体吊装',
    '2': '分块吊装',
    '3': '整体滑移',
    '4': '分块滑移',
    '5': '散装',
    '6': '顶升',
    '7': '运营维护',
    '8': '其他',
}

export const ConstructionTypeOriginMap = {
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5': false,
    '6': false,
    '7': false,
    '8': false,
}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

interface IProps {
    routeStore?: RouteStore,
    projectStore?: ProjectStore,
    userStore?: UserStore,
    afterSave?: () => void,
}

interface IState {
    projectInfo: project,
    projectPeopleList: projectPeople[],
    projectScheduleList: projectSchedule[],
    projectPlanbList: projectPlanb[],
    projectFigures: figure[],
    fileList: any[],
    previewVisible: boolean,// 图片预览弹窗可见
    previewImage: string,// 图片预览地址
    saveLoading: boolean,

    planbImportVisible: boolean, 
    globalPlanbs: globalPlanb[],
    globalPlanbSelected: number[],
}

@inject('routeStore', 'projectStore', 'userStore')
@observer
export default class ProjectInfoEdit extends Component<IProps, IState> {
    static contextType = ProjectContext;
    peopleDeleteList: number[] = [];
    scheduleDeleteList: number[] = [];
    planbDeleteList: number[] = [];
    projectFigureDeleteIdList: number[] = [];
    peopleAddMaxId: number = 0;
    scheduleAddMaxId: number = 0;
    planbAddMaxId: number = 0;

    formRef = React.createRef<FormInstance>();
    state = {
        projectInfo: null,
        projectPeopleList: [],
        projectScheduleList: [],
        projectPlanbList: [],
        projectFigures: [],
        fileList: [],
        previewVisible: false,
        previewImage: '',
        saveLoading: false,

        planbImportVisible: false,
        globalPlanbs: [],
        globalPlanbSelected: [],
    }
    componentDidMount() {
        this.generateData();
        this.findGlobalPlanbs();
    }

    findGlobalPlanbs = () => {
        let eid = this.props.userStore._enterpriseId;
        queryEnterprisePlanbs(eid)
        .then(res => {
            this.setState({
                globalPlanbs: res.data || [],
            })
        })
    }

    generateData = () => {
        let projectId = this.context;
        let queryProjectPromise = queryProjectDetail(projectId);
        let queryProjectPeoplePromise = queryProjectPeople(projectId);
        let queryProjectSchedulePromise = queryProjectSchedules(projectId);
        let queryProjectPlanbPromise = queryProjectPlanbs(projectId);
        let queryProjectFiguresPromise = queryProjectFigures(projectId);
        axios.all([queryProjectPromise,
            queryProjectPeoplePromise,
            queryProjectSchedulePromise,
            queryProjectPlanbPromise,
            queryProjectFiguresPromise])
            .then(res => {
                let projectInfo: project = res[0].data;
                projectInfo.structure_type = Object.assign(StructureTypeOriginMap, projectInfo?.structure_type);
                projectInfo.construction_type = Object.assign(ConstructionTypeOriginMap, projectInfo?.construction_type);
                // 人员计划和应急预案排序并生成序列号
                let originSchedules: projectSchedule[] = res[2].data || [];
                let orderedSchedules = this.orderLinkedList(originSchedules);
                let originPlanbs: projectPlanb[] = res[3].data || [];
                let orderedPlanbs = this.orderLinkedList(originPlanbs);
                // 组织form-figure数据
                let projectFigures: figure[] = res[4].data || [];
                let fileList = projectFigures.map(item => ({
                    uid: item.id,
                    name: item.name,
                    status: 'done',
                    url: `${figureStaticLocation}${item.url}`
                }))
                // 组织form的数据
                let data = {
                    name: projectInfo?.name,
                    structure_type: paramDict2Array(projectInfo?.structure_type).filter(item => item.value).map(item => item.name),
                    construction_type: paramDict2Array(projectInfo?.construction_type).filter(item => item.value).map(item => item.name),
                    monitor_type: projectInfo?.monitor_type,
                    monitor_rank: projectInfo?.monitor_rank,
                    start_time: projectInfo?.start_time ? dayjs(projectInfo?.start_time || null) : dayjs(),
                    period: projectInfo?.period,
                    weight: projectInfo?.weight,
                    size: projectInfo?.size,
                    technical_requirement: projectInfo?.technical_requirement,
                    economic_requirement: projectInfo?.economic_requirement,
                    schedule_requirement: projectInfo?.schedule_requirement,

                    client_name: projectInfo?.client_name,
                    project_address: projectInfo?.project_address,
                    order_date: projectInfo?.order_date ? dayjs(projectInfo?.order_date) : null,
                }
                this.formRef.current?.setFieldsValue(data);
                this.setState({
                    projectInfo: projectInfo,
                    projectPeopleList: res[1].data || [],
                    projectScheduleList: orderedSchedules,
                    projectPlanbList: orderedPlanbs,
                    projectFigures: projectFigures,
                    fileList: fileList
                })
            })
    }

    orderLinkedList = (originList: any[]) => {
        let listIdMap = {};
        originList.forEach(item => {
            listIdMap[item.id] = item;
        })
        let res = [];
        for (let idx = 0; idx < originList.length; idx++) {
            if (originList[idx].previous_id === null) {
                res.push(Object.assign(originList[idx], { sid: 1 }));
                break;
            }
        }
        let idx = 0;
        while (res[idx] && res[idx].next_id) {
            let id = res[idx].next_id;
            res.push(Object.assign(listIdMap[id], { sid: idx + 2 }));
            idx++;
        }
        return res;
    }

    onSave = (values) => {
        this.setState({
            saveLoading: true,
        })
        let projectInfoData = values;
        let strutMap = {...StructureTypeOriginMap};
        projectInfoData.structure_type.forEach(item => {
            strutMap[item] = true;
        })
        let constrMap = {...ConstructionTypeOriginMap};
        projectInfoData.construction_type.forEach(item => {
            constrMap[item] = true;
        })
        projectInfoData.structure_type = strutMap;
        projectInfoData.construction_type = constrMap;
        let updateProjectInfoPromise = updateProjectDetail(this.context, projectInfoData);
        let deleteFiguresPromiseList = this.projectFigureDeleteIdList.map(id => deleteProjectFigures(this.context, id));
        let createFiguresPromiseList = this.state.fileList
        .filter(item => item.response)
        .map(item => item.response.data)
        .map(item => createProjectFigures(this.context, item));
        let deletePeoplePromiseList = this.peopleDeleteList.map(id => deleteProjectPeople(this.context, id));
        let createPeoplePromiseList = this.state.projectPeopleList
            .filter(item => item.id < 0)
            .map(item => createProjectPeople(this.context, {
                name: item.name,
                duty: item.duty,
                title: item.title,
                work_type: item.work_type
            }));
        let changePeoplePromiseList = this.state.projectPeopleList
            .filter(item => item.id >= 0)
            .map(item => updateProjectPeople(this.context, item.id, {
                name: item.name,
                duty: item.duty,
                title: item.title,
                work_type: item.work_type
            }));
        let deleteSchedulePromiseList = this.scheduleDeleteList.map(id => deleteProjectSchedule(this.context, id));
        let changeSchedulePromiseList = this.state.projectScheduleList
        .filter(item => item.id >= 0)
        .map(item => updateProjectSchedule(this.context, item.id, {
            content: item.content,
            duration: item.duration,
            remark: item.remark,
        }));
        let deletePlanbPromiseList = this.planbDeleteList.map(id => deleteProjectPlanb(this.context, id));
        let changePlanbPromiseList = this.state.projectPlanbList
        .filter(item => item.id >= 0)
        .map(item => updateProjectPlanb(this.context, item.id, {
            content: item.content,
        }));
        console.log(deleteSchedulePromiseList.length)
        this.deleteSchedule(deleteSchedulePromiseList, 0)
        .then(res => {
            console.log('aaa')
            console.log(res);
            return this.deletePlanb(deletePlanbPromiseList, 0);
        }).then(res => {
            console.log('bbb')
            console.log(res)
            return axios.all([
                updateProjectInfoPromise,
                ...deleteFiguresPromiseList,
                ...createFiguresPromiseList,
                ...deletePeoplePromiseList,
                ...createPeoplePromiseList,
                ...changePeoplePromiseList,
                // ...deleteSchedulePromiseList,
                ...changeSchedulePromiseList,
                // ...deletePlanbPromiseList,
                ...changePlanbPromiseList,
            ])
        }).then(res => {
            this.props.projectStore.setProjectName(values.name);
            // 递归创建schedule和planb，因为需要previous_id
            let createScheduleList = this.state.projectScheduleList.filter(item => item.id < 0);
            let createPlanbList = this.state.projectPlanbList.filter(item => item.id < 0);
            if (createScheduleList.length > 0) {
                this.createSchedule(createScheduleList, 0);
            } else if (createPlanbList.length > 0) {
                this.createPlanb(createPlanbList, 0);
            } else {
                this.setState({
                    saveLoading: false,
                })
                this.props.afterSave();
            }
        }).catch(err => {
            message.error('更新失败');
            this.setState({
                saveLoading: false,
            })
        })
    }

    deleteSchedule = (sPromiseList: Promise<AxiosResponse<any>>[], idx) => {
        if (sPromiseList.length === 0 || sPromiseList.length <= idx) {
            return Promise.resolve(true);
        }
        let promise = sPromiseList[idx];
        return promise.then(res => {
            return this.deleteSchedule(sPromiseList, idx+1);
        })
    }

    deletePlanb = (pPromiseList: Promise<AxiosResponse<any>>[], idx) => {
        if (pPromiseList.length === 0 || pPromiseList.length <= idx) {
            return Promise.resolve(true);
        }
        let promise = pPromiseList[idx];
        return promise.then(res => {
            return this.deletePlanb(pPromiseList, idx+1);
        })
    }

    createSchedule =(sList: projectSchedule[], idx) => {
        let sche = sList[idx];
        let cid = this.state.projectScheduleList.findIndex(item => item.id === sche.id);
        if (this.state.projectScheduleList[cid-1]) {
            sche.previous_id = this.state.projectScheduleList[cid-1].id >= 0 ? this.state.projectScheduleList[cid-1].id : null;
        }
        if (this.state.projectScheduleList[cid+1]) {
            sche.next_id = this.state.projectScheduleList[cid+1].id >= 0 ? this.state.projectScheduleList[cid+1].id : null;
        }
        createProjectSchedule(this.context, {
            content: sche.content,
            duration: sche.duration,
            remark: sche.remark,
            previous_id: sche.previous_id || null,
            next_id: sche.next_id || null,
        }).then(res => {
            let new_id: number = res.data;
            sche.id = new_id;
            idx++;
            if (idx < sList.length) {
                this.createSchedule(sList, idx);
            } else {
                // 递归创建planb
                let createPlanbList = this.state.projectPlanbList.filter(item => item.id < 0);
                if (createPlanbList.length > 0) {
                    this.createPlanb(createPlanbList, 0);
                } else {
                    this.setState({
                        saveLoading: false,
                    })
                    this.props.afterSave();
                }
            }
        }).catch(err => {
            message.error('时间计划更新失败');
            this.setState({
                saveLoading: false,
            })
        })
    }

    createPlanb = (pList: projectPlanb[], idx) => {
        let planb = pList[idx];
        let cid = this.state.projectPlanbList.findIndex(item => item.id === planb.id);
        if (this.state.projectPlanbList[cid-1]) {
            planb.previous_id = this.state.projectPlanbList[cid-1].id >= 0 ? this.state.projectPlanbList[cid-1].id : null;
        }
        if (this.state.projectPlanbList[cid+1]) {
            planb.next_id = this.state.projectPlanbList[cid+1].id >= 0 ? this.state.projectPlanbList[cid+1].id : null;
        }
        console.log({
            content: planb.content,
            previous_id: planb.previous_id || null,
            next_id: planb.next_id || null,
        })
        createProjectPlanb(this.context, {
            content: planb.content,
            previous_id: planb.previous_id || null,
            next_id: planb.next_id || null,
        }).then(res => {
            let new_id: number = res.data;
            planb.id = new_id;
            idx++;
            if (idx < pList.length) {
                this.createPlanb(pList, idx);
            } else {
                this.setState({
                    saveLoading: false,
                })
                this.props.afterSave();
            }
        }).catch(err => {
            message.error('应急预案更新失败');
            this.setState({
                saveLoading: false,
            })
        })
    }

    // ============== upload figures ================
    normFile = e => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange = ({ file, fileList }) => {
        this.setState({
            fileList: fileList
        })
    }
    handleRemove = (file) => {
        if (!file.response) {
            this.projectFigureDeleteIdList.push(file.uid);
        }
        return true;
    }


    // ==================== people table ==================
    onPeopleChange = (e, tag: string, people: projectPeople) => {
        let next = e.target.value;
        people[tag] = next || '';
        this.setState({
            projectPeopleList: [...this.state.projectPeopleList]
        })
    }

    onPeopleDeleteClick = (people: projectPeople) => {
        if (people.id > 0) {
            this.peopleDeleteList.push(people.id);
        }
        this.setState({
            projectPeopleList: this.state.projectPeopleList.filter(item => item.id !== people.id)
        })
    }

    onPeopleAddClick = () => {
        this.peopleAddMaxId--;
        let res = {
            id: this.peopleAddMaxId,
            name: '',
            duty: '',
            title: '',
            work_type: '',
        }
        this.setState({
            projectPeopleList: [...this.state.projectPeopleList, res]
        })
    }

    // ================ schedule table =====================
    onScheduleChange = (e, tag: string, schedule: projectSchedule) => {
        let next = e.target.value;
        schedule[tag] = next || '';
        this.setState({
            projectScheduleList: [...this.state.projectScheduleList]
        })
    }

    onScheduleDeleteClick = (schedule: projectSchedule) => {
        if (schedule.id > 0) {
            this.scheduleDeleteList.push(schedule.id);
        }
        let result = this.state.projectScheduleList.filter(item => item.id !== schedule.id);
        result = this.reOrderScheduleSid(result);
        this.setState({
            projectScheduleList: result
        })
    }

    onScheduleAddClick = () => {
        this.scheduleAddMaxId--;
        let res = {
            id: this.scheduleAddMaxId,
            content: '',
            duration: '',
            remark: '',
            next_id: null,
            previous_id: null,
            sid: null,
        }
        let result = [...this.state.projectScheduleList, res];
        result = this.reOrderScheduleSid(result);
        this.setState({
            projectScheduleList: result
        })
    }

    reOrderScheduleSid = (schedules: projectSchedule[]) => {
        let idx = 0;
        schedules.forEach(item => {
            item.sid = ++idx;
        })
        return [...schedules];
    }

    // ============== project planb ==============
    onPlanbChange = (e, tag: string, planb: projectPlanb) => {
        let next = e.target.value;
        planb[tag] = next || '';
        this.setState({
            projectPlanbList: [...this.state.projectPlanbList]
        })
    }

    onPlanbDeleteClick = (planb: projectPlanb) => {
        if (planb.id > 0) {
            this.planbDeleteList.push(planb.id);
        }
        let result = this.state.projectPlanbList.filter(item => item.id !== planb.id);
        result = this.reOrderPlanbSid(result);
        this.setState({
            projectPlanbList: result
        })
    }

    onPlanbAddClick = () => {
        this.planbAddMaxId--;
        let res = {
            id: this.planbAddMaxId,
            content: '',
            next_id: null,
            previous_id: null,
            sid: null,
        }
        let result = [...this.state.projectPlanbList, res];
        result = this.reOrderPlanbSid(result);
        this.setState({
            projectPlanbList: result
        })
    }

    reOrderPlanbSid = (planbs: projectPlanb[]) => {
        let idx = 0;
        planbs.forEach(item => {
            item.sid = ++idx;
        })
        return [...planbs];
    }

    onImportPlanbSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            globalPlanbSelected: [...selectedRowKeys]
        })
    }

    onGlobalPlanbImportClick = () => {
        let ids: number[] = this.state.globalPlanbSelected;
        let planbs: globalPlanb[] = this.state.globalPlanbs.filter((planb: globalPlanb) => ids.some(x => x === planb.id)) || [];
        let result: projectPlanb[] = [...this.state.projectPlanbList];
        planbs.forEach(item => {
            this.planbAddMaxId--;
            let res = {
                id: this.planbAddMaxId,
                content: item.content,
                next_id: null,
                previous_id: null,
                sid: null,
            }
            result = [...result, res];
            result = this.reOrderPlanbSid(result);
        })
        this.setState({
            projectPlanbList: result,
            planbImportVisible: false,
        })
    }

    render() {
        return (
            <div className="project-info-edit-container">
                <Form className="project-form-group" autoComplete="off" ref={this.formRef} onFinish={this.onSave} labelCol={{ span: 3 }}>
                    <div className="project-form">
                        <Form.Item name="name" label="项目名称" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="client_name" label="委托单位" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="project_address" label="工程地点" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="order_date" label="委托日期" rules={[{required: true}]}>
                            <DatePicker />
                        </Form.Item>
                        <Form.Item name="structure_type" label="结构类别">
                            <Checkbox.Group>
                                {paramDict2Array(this.state.projectInfo?.structure_type).map((item, idx) => (
                                    <Checkbox key={idx} value={item.name}>{StructureTypes[item.name]}</Checkbox>
                                ))}
                            </Checkbox.Group>
                        </Form.Item>
                        <Form.Item name="construction_type" label="施工技术">
                            <Checkbox.Group>
                                {paramDict2Array(this.state.projectInfo?.construction_type).map((item, idx) => (
                                    <Checkbox key={idx} value={item.name}>{ConstructionTypes[item.name]}</Checkbox>
                                ))}
                            </Checkbox.Group>
                        </Form.Item>
                        <Form.Item name="monitor_type" label="监测类别" rules={[{required: true}]}>
                            <Radio.Group>
                                <Radio value={1}>试验</Radio>
                                <Radio value={2}>施工过程</Radio>
                                <Radio value={3}>健康运营</Radio>
                                <Radio value={4}>全过程</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="monitor_rank" label="监测等级" rules={[{required: true}]}>
                            <Radio.Group>
                                <Radio value={1}>一级</Radio>
                                <Radio value={2}>二级</Radio>
                                <Radio value={3}>三级</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="start_time" label="开始时间" style={{ marginRight: '24px' }} rules={[{required: true}]}>
                            <DatePicker />
                        </Form.Item>
                        <Form.Item name="period" label="监测周期(天)" rules={[{required: true}]}>
                            <NumericInput />
                        </Form.Item>
                        <Form.Item name="weight" label="总重量">
                            <Input />
                        </Form.Item>
                        <Form.Item name="size" label="平面尺寸">
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="fileList"
                            label="上传项目图片"
                            valuePropName="fileList"
                            getValueFromEvent={this.normFile}
                        >
                            <ImgCrop aspect={16 / 9} modalTitle="图片裁剪">
                                <Upload
                                    action={figureUploadUrl}
                                    listType="picture-card"
                                    fileList={this.state.fileList}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleChange}
                                    onRemove={this.handleRemove}
                                >
                                    {this.state.fileList.length >= 5 ? null : (
                                        <div>
                                            <PlusOutlined />
                                            <div className="ant-upload-text">Upload</div>
                                        </div>
                                    )}
                                </Upload>
                            </ImgCrop>
                        </Form.Item>
                        <Form.Item name="technical_requirement" label="技术要求">
                            <TextArea rows={4} />
                        </Form.Item>
                        <Form.Item name="economic_requirement" label="经济要求">
                            <TextArea rows={4} />
                        </Form.Item>
                        <Form.Item name="schedule_requirement" label="进度要求">
                            <TextArea rows={4} />
                        </Form.Item>
                        <div className="table-top-operations">
                            <Button type="link" size="small" icon={<PlusOutlined />} onClick={this.onPeopleAddClick}>添加</Button>
                        </div>
                        <div className="table-pane people">
                            <div className="title">人员计划</div>

                            <Table
                                className="info-table-edit fill-remaining-space"
                                dataSource={this.state.projectPeopleList}
                                rowKey="id"
                                pagination={false}
                                rowClassName={(record, index) => (
                                    index % 2 !== 0 ? 'ribbon-row' : ''
                                )}>
                                <Column title="姓名" key="name" render={(record: projectPeople, idx) => (
                                    <Input value={record.name} style={{ maxWidth: '160px' }} onChange={(value) => this.onPeopleChange(value, 'name', record)} />
                                )} />
                                <Column title="职务" key="duty" render={(record: projectPeople, idx) => (
                                    <Input value={record.duty} style={{ maxWidth: '160px' }} onChange={(value) => this.onPeopleChange(value, 'duty', record)} />
                                )} />
                                <Column title="职称" key="title" render={(record: projectPeople, idx) => (
                                    <Input value={record.title} style={{ maxWidth: '160px' }} onChange={(value) => this.onPeopleChange(value, 'title', record)} />
                                )} />
                                <Column title="工作方式" key="work_type" render={(record: projectPeople, idx) => (
                                    <Input value={record.work_type} style={{ maxWidth: '160px' }} onChange={(value) => this.onPeopleChange(value, 'work_type', record)} />
                                )} />
                                <Column title="操作" key="operations" width={90} align="center" render={(record: projectPeople, idx) => (
                                    <Space>
                                        <Button type="link" size="small" onClick={() => this.onPeopleDeleteClick(record)}>删除</Button>
                                    </Space>
                                )} />
                            </Table>
                        </div>
                        <div className="table-top-operations">
                            <Button type="link" size="small" icon={<PlusOutlined />} onClick={this.onScheduleAddClick}>添加</Button>
                        </div>
                        <div className="table-pane schedule">
                            <div className="title">时间计划</div>
                            <Table
                                className="info-table-edit fill-remaining-space"
                                dataSource={this.state.projectScheduleList}
                                rowKey="id"
                                pagination={false}
                                rowClassName={(record, index) => (
                                    index % 2 !== 0 ? 'ribbon-row' : ''
                                )}>
                                <Column title="工作步" key="sid" render={(record: projectSchedule) => (
                                    leftZeroInt(record.sid, 2)
                                )} />
                                <Column title="工作内容" key="content" render={(record: projectSchedule) => (
                                    <Input value={record.content} style={{ maxWidth: '160px' }} onChange={(value) => this.onScheduleChange(value, 'content', record)} />
                                )} />
                                <Column title="天数" key="duration" render={(record: projectSchedule) => (
                                    <Input value={record.duration} style={{ maxWidth: '160px' }} onChange={(value) => this.onScheduleChange(value, 'duration', record)} />
                                )} />
                                <Column title="备注" key="remark" render={(record: projectSchedule) => (
                                    <Input value={record.remark} onChange={(value) => this.onScheduleChange(value, 'remark', record)} />
                                )} />
                                <Column title="操作" key="operations" width={90} align="center" render={(record: projectPeople, idx) => (
                                    <Space>
                                        <Button type="link" size="small" onClick={() => this.onScheduleDeleteClick(record)}>删除</Button>
                                    </Space>
                                )} />
                            </Table>
                        </div>
                        <div className="table-top-operations">
                            <Space>
                                <Button type="link" size="small" icon={<ImportOutlined />} onClick={() => this.setState({planbImportVisible: true})}>导入</Button>
                                <Button type="link" size="small" icon={<PlusOutlined />} onClick={this.onPlanbAddClick}>添加</Button>
                            </Space>
                        </div>
                        <div className="table-pane schedule">
                            <div className="title">应急预案</div>
                            <Table
                                className="info-table-edit fill-remaining-space"
                                dataSource={this.state.projectPlanbList}
                                rowKey="id"
                                pagination={false}
                                rowClassName={(record, index) => (
                                    index % 2 !== 0 ? 'ribbon-row' : ''
                                )}>
                                <Column title="序号" key="sid" width={60} render={(record: projectPlanb) => (
                                    leftZeroInt(record.sid, 2)
                                )} />
                                <Column title="内容" key="content" render={(record: projectPlanb) => (
                                    <Input value={record.content} onChange={(value) => this.onPlanbChange(value, 'content', record)} />
                                )} />
                                <Column title="操作" width={90} key="operations" align="center" render={(record: projectPlanb, idx) => (
                                    <Space>
                                        <Button type="link" size="small" onClick={() => this.onPlanbDeleteClick(record)}>删除</Button>
                                    </Space>
                                )} />
                            </Table>
                        </div>
                    </div>
                    <div>
                        <div style={{ textAlign: 'right', marginBottom: '24px' }}>
                            <Space size="large">
                                <Button onClick={this.props.afterSave}>取消</Button>
                                <Button type="primary" htmlType="submit" loading={this.state.saveLoading}>保存</Button>
                            </Space>
                        </div>
                    </div>
                </Form>

                <Modal
                    visible={this.state.previewVisible}
                    footer={null}
                    onCancel={this.handleCancel}
                    width={600}
                    style={{ top: 20 }}
                    destroyOnClose
                >
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>

                <Modal
                    visible={this.state.planbImportVisible}
                    closable
                    title="导入应急预案"
                    width={900}
                    destroyOnClose
                    onOk={this.onGlobalPlanbImportClick}
                    okText="导入"
                    onCancel={() => this.setState({planbImportVisible: false})}
                    style={{top: 20}}>
                    <Table
                        rowKey="id"
                        className="select-warning-user-table"
                        pagination={false}
                        dataSource={this.state.globalPlanbs}
                        rowSelection={{
                            type: 'checkbox',
                            onChange: this.onImportPlanbSelectChange,
                            selectedRowKeys: this.state.globalPlanbSelected
                        }}
                    >
                        <Column title="内容" dataIndex="content" ellipsis/>
                    </Table>
                </Modal>
            </div>
        )
    }
}
