import React, { Component } from "react"
import { inject, observer } from "mobx-react";
import { RProps } from "@/config/props";

interface IProps extends RProps {
}
    
export function withRoutePane(WrappedComponent, title?: string)  {

    @inject('routeStore')
    @observer
    class routePane extends Component<IProps> {
        constructor(props) {
            super(props);
            this.props.routeStore.addRoutePane(title, this.props.match.url);
        }

        render() {
            return (
                <WrappedComponent {...this.props} />
            );
        }
    }

    return routePane;
}