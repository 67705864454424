import { observable, action } from "mobx";

export const Fiber_Sensor_types = [19, 20, 21, 24, 26]
class SensorStore {
    @observable _dataType: number;
    @action setDataType = (value: number) => {
        this._dataType = value;
    }

    @observable _samplingPeriod: number;
    @action setSamplingPeriod = (value: number) => {
        this._samplingPeriod = value;
    }

    @observable _startTime: Date;
    @action setStartTime = (value: Date) => {
        this._startTime = value;
    }

    @observable _endTime: Date;
    @action setEndTime = (value: Date) => {
        this._endTime = value;
    }

    @observable _forceRender: boolean = false;
    @action setForceRender = (value: boolean) => {
        this._forceRender = value;
    }
}

export default SensorStore;