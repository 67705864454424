import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Tabs } from 'antd';
import axios from '../../config/axios';
import { ProjectContext } from '@/config/context';
import { globalQuantity, quantityData } from '@/interface/quantity';
import { queryDashboardQuantitiesTypes, queryDashboardQuantitiesStatistics, queryDashboardQuantitiesMax } from '@/config/api/dashboard';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import IntervalChart from '../charts/interval-chart';
import { Transfer2DisplayScale } from '@/utils/utils';
import { queryQuantityData } from '@/config/api/quantity-data';
import LineChartDashboard from '../charts/line-chart-dashboard';
import { queryMobjectDetail } from '@/config/api/mobject';
import { mobject } from '@/interface/mobject';
import { withRouter } from 'react-router-dom';
import { Gprops } from '@/config/props';

const { TabPane } = Tabs;

interface IProps extends Gprops {
    projectId: number,
    chartHeight?: number,
}
interface IState {
    targetQuantities?: globalQuantity[],
    statistics: any[],
    selectedType: number,
    quantityData: quantityData,
}

class QuantityBoard extends Component<IProps, IState> {

    static contextType = ProjectContext;
    timer: any;
    state = {
        targetQuantities: [],
        statistics: [],
        selectedType: null,
        quantityData: null,
    }
    selectedTargeQuantity: globalQuantity = null;

    componentDidMount() {
        this.findProjectQuantityTypesUsing();
        this.timer = setInterval(
            () => this.findQuantityStatistics(this.state.selectedType),
            1000 * 60,
        );
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    findProjectQuantityTypesUsing = () => {
        let projectId = this.props.projectId;
        let projectQuantityListPromise = queryProjectGlobalQuantities(projectId);
        let queryQuantityTypesPromise = queryDashboardQuantitiesTypes({
            project_id: projectId
        });
        axios.all([projectQuantityListPromise, queryQuantityTypesPromise])
            .then((res) => {
                let globalquantities: globalQuantity[] = res[0].data;
                let types: [] = res[1].data;
                let targetQuantities: globalQuantity[] = types.map((type, idx) => {
                    return globalquantities.filter(item => item.type === type)[0];
                });
                let selectedType = targetQuantities.length > 0 ? targetQuantities[0].type : null;
                this.setState({
                    targetQuantities: targetQuantities,
                    selectedType: selectedType,
                })
                if (selectedType) {
                    this.selectedTargeQuantity = targetQuantities.find(item => item.type === selectedType);
                    this.findQuantityStatistics(selectedType);
                }
            })
    }

    findQuantityStatistics = (type: number) => {
        let projectId = this.props.projectId;
        let queryQuantityStatisticPromise = queryDashboardQuantitiesStatistics({
            project_id: projectId,
            quantity_type: type,
        });
        let queryMaxQuantityPromise = queryDashboardQuantitiesMax({
            project_id: projectId,
            quantity_type: type,
        })
        axios.all([queryQuantityStatisticPromise, queryMaxQuantityPromise]).then(res => {
            let statistics = res[0].data;
            let maxId: number = res[1].data;
            statistics.forEach(item => {
                let start: number = item['start'];
                let end: number = item['end'];
                let unit = this.selectedTargeQuantity.unit_type;
                let scale = Transfer2DisplayScale(unit);
                let precision = +this.selectedTargeQuantity.precision;
                start = start * scale;
                end = end * scale;
                item['type'] = `${start.toFixed(precision)}~${end.toFixed(precision)}`;
            })
            this.setState({
                statistics: statistics
            })
            if (maxId > 0) {
                this.findQuantityData(maxId);
            }
        })
    }

    findQuantityData = (id: number) => {
        let quantityData = null;
        queryQuantityData(id, {
            limit: 200,
        }).then(res => {
            let data: quantityData = res.data;
            let selectedTargeQuantity = this.selectedTargeQuantity;
            if (!!selectedTargeQuantity) {
                data.precision = selectedTargeQuantity.precision;
                data.unit_type = selectedTargeQuantity.unit_type;
                data.chinese_name = selectedTargeQuantity.chinese_name;
                data.scale = Transfer2DisplayScale(data.unit_type);
                data.max = data.max ? data.max * data.scale : null;
                data.min = data.min ? data.min * data.scale : null;
                data.mean = data.mean ? data.mean * data.scale : null;
            }
            // data.max *= data.scale;
            // data.min *= data.scale;
            // data.mean *= data.scale;
            data.data.forEach(item => {
                item.value = item.value * data.scale;
            })
            // this.setState({
            //     quantityData: data
            // })
            quantityData = data;
            let mobjectId = data.mobject_id;
            return queryMobjectDetail(mobjectId);
        }).then(res => {
            let mobjectDetail: mobject = res.data;
            quantityData.mobject_name = mobjectDetail?.name || '';
            quantityData.mobject = mobjectDetail;
            this.setState({
                quantityData: quantityData,
            })
        })
    }

    onTabChange = (activeKey) => {
        this.setState({
            selectedType: +activeKey,
        })
        this.selectedTargeQuantity = this.state.targetQuantities.find(item => item.type === +activeKey);
        this.findQuantityStatistics(+activeKey);
    }

    render() {
        // const operations = (
        //     <Space size={18} className="extra-button-group">
        //         <Button type="primary">评估概览</Button>
        //         <Button type="primary">设备概览</Button>
        //     </Space>
        // );
        let { selectedType, statistics, quantityData } = this.state;
        return (
            <div className="dashboard-card quantity">
                <Tabs activeKey={`${selectedType}`} onChange={this.onTabChange}>
                    {this.state.targetQuantities.map((item: globalQuantity, index) => (
                        <TabPane tab={`${item?.chinese_name}`} key={item.type.toString()}>
                        </TabPane>  //map函数返回数组                       
                    ))}
                </Tabs>
                <Row gutter={10}>
                    <Col span={10}>
                        {!!statistics && statistics.length > 0 && (
                            <IntervalChart data={statistics} height={this.props.chartHeight}/>
                        )}
                    </Col>
                    <Col span={14}>
                        {!!quantityData && (
                            <LineChartDashboard quantityData={quantityData} height={this.props.chartHeight}/>
                        )}
                    </Col>
                </Row>

            </div>
        )
    }
}

export default withRouter(QuantityBoard);