import React, { Component } from 'react'
import DeviceChart from './device-chart'
import { sensor } from '@/interface/sensor';
import { dbox } from '@/interface/dbox';
import { ProjectContext } from '@/config/context';
import { queryDboxs } from '@/config/api/dbox';
import { querySensors } from '@/config/api/sensor';
import axios from '@/config/axios';
import { DboxStatusTypesEnum } from '@/routes/dbox/dbox-list';
import { SensorStatusTypesEnum } from '@/routes/sensor/sensor-list';
import { Button } from 'antd';
import { Gprops } from '@/config/props';
import { withRouter } from 'react-router-dom';
import {RightOutlined} from '@ant-design/icons';
import './device-board.less';

interface IProps extends Gprops {

}
interface IState {
    sensors: sensor[];
    dboxs: dbox[];
}
class DeviceBoard extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        sensors: [],
        dboxs: [],
    }
    componentDidMount() {
        this.generateData();
    }

    generateData = () => {
        let projectId = this.context;
        let queryDboxsPromise = queryDboxs({
            project_id: projectId,
        });
        let querySensorsPromise = querySensors({
            project_id: projectId,
        })
        axios.all([queryDboxsPromise, querySensorsPromise])
            .then(res => {
                let dboxs = res[0].data;
                let sensors = res[1].data;
                this.setState({
                    sensors,
                    dboxs,
                })
            })
    }

    render() {
        let {sensors, dboxs} = this.state;
        let dboxWorkingCount = dboxs.filter(item => item.work_status === DboxStatusTypesEnum.ONLINE)?.length || 0;
        let sensorWorkingCount = sensors.filter(item => item.work_status === SensorStatusTypesEnum.ONLINE)?.length || 0;
        return (
            <div className="dashboard-card device">
                <div className="header">
                    <div className="title">设备运行</div>
                    <Button type="link" onClick={() => this.props.history.push(`/projects/${this.context}/sites`)}>详情<RightOutlined /></Button>
                </div>
                <div className="device-group" style={{ padding: '0 12px' }}>
                    <div>
                        <DeviceChart title="采集箱" working={dboxWorkingCount} count={dboxs?.length || 0} id='dbox' color='#0a9afe' />

                    </div>
                    <div>
                        <DeviceChart title="传感器" working={sensorWorkingCount} count={sensors?.length || 0} id='sensor' color='#73d13d' />
                    </div>
                </div>
                {/* <Button onClick={this.onClick}>Test</Button> */}
            </div>
        )
    }
}

export default withRouter(DeviceBoard);