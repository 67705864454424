import axios from "../axios"
import { AxiosResponse } from "axios"

export function querySensors(params: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/sensors`, {
        params: params
    })
}

export function querySensorDetail(sensorId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/sensors/${sensorId}`, {
        params: params
    })
}

export function downloadSensorsCsv(projectId: number): Promise<AxiosResponse<any>> {
    return axios.get(`/project/${projectId}/sensors/export`, {
        params: {
            project_id: projectId
        },
        responseType: 'blob'
    })
}

export function createSensors(data: any, params?: {}): Promise<AxiosResponse<any>> {
    if (!!data.id) {data.id = null};
    return axios.post(`/sensors`, data, {
        params: params
    })
}

export function updateSensor(sensorId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/sensors/${sensorId}`, data, {
        params: params
    })
}

export function deleteSensor(sensorId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`/sensors/${sensorId}`, {
        params: params
    })
}

export function queryNoChannelSensors(params: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/sensors/no-channel`, {
        params: params
    })
}

export function querySensorQuantities(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/sensor-quantities`, {
        params: params
    })
}



export function querySensorQuantityDetail(projectId: number, sensorQuantitiyId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/sensor-quantities/${sensorQuantitiyId}`, {
        params: params
    })
}

export function updateSensorQuantityDetail(projectId: number, sensorQuantitiyId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/projects/${projectId}/sensor-quantities/${sensorQuantitiyId}`, data, {
        params: params
    })
}

export function querySensorManageHistory(id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/sensors/${id}/sensor-manage`, {
        params: params
    })
}

export function updateSensorManageHistory(id: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/sensors/${id}/sensor-manage`, data, {
        params: params
    })
}

export function querySensorFormulas(sensorId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/sensors/${sensorId}/formula`, {
        params: params
    })
}

export function updateSensorFormula(sensorId: number, sensorQuantitiyId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/sensors/${sensorId}/formula/${sensorQuantitiyId}`, data, {
        params: params
    })
}

export function sensorOffline(sensorId: number): Promise<AxiosResponse<any>> {
    return axios.post(`/sensors/${sensorId}/offline`, null);
}

export function sensorWork(sensorId: number): Promise<AxiosResponse<any>> {
    return axios.post(`/sensors/${sensorId}/work`, null);
}

export function sensorAbnormal(sensorId: number): Promise<AxiosResponse<any>> {
    return axios.post(`/sensors/${sensorId}/abnormal`, null);
}

export function sensorTest(sensorId: number): Promise<AxiosResponse<any>> {
    return axios.get(`/sensors/${sensorId}/test`);
}

export function sensorInitialize(sensorId: number): Promise<AxiosResponse<any>> {
    return axios.post(`/sensors/${sensorId}/initialize`);
}