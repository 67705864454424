import React, { Component } from 'react'
import './section-panel.less';
import { Form, Input, Select, Button, Space, message } from 'antd';
import { section } from '@/interface/model';
import { FormInstance } from 'antd/lib/form';
import { paramDict2Array, paramArrary2Dict, Transfer2DisplayScale, Transfer2BackEndScale, NumFixed } from '@/utils/utils';
import { UpOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import { SectionTypes, SectionShapeTypes, SectionProtoName } from '@/utils/type';
import { calculateSectionParamOut, queryPrototypeSectionParams } from '@/config/api/global-secmat';
import { NumericInput } from '..';
import { sectionPicStaticLocation } from '@/config/api/document';
import { InlineMath } from 'react-katex';
import { ParamUnits } from '@/utils/unit-type';
const { Option } = Select;

interface IProps {
    sectionDetail: section
    onSave?: (values: section) => void
    onEdit?: () => void
    onCancel?: () => void,
    readOnly?: boolean
    newType?: number // 创建新截面时传默认type
    saveLoading?: boolean

    isEditing: boolean
    sidHidden?: boolean
}

interface IState {
    expand: boolean

    calculateError: boolean // 控制是否计算错误
}


export default class SectionPanel extends Component<IProps, IState> {
    formRef = React.createRef<FormInstance>();
    secDetail: section = null;
    paramIn: any[] = []
    paramOut: any[] = []
    state = {
        expand: false,
        calculateError: false
    }

    componentDidMount() {
        this.secDetail = this.props.sectionDetail;
        this.initForm()
    }

    componentDidUpdate() {
        if (!!this.props.newType) {
            return;
        }
        let secDetail = this.props.sectionDetail;
        if (this.secDetail?.sid !== secDetail.sid) {
            this.secDetail = this.props.sectionDetail
            this.initForm()
        }
    }

    initForm = () => {
        if (!!this.props.newType) {
            let type = this.props.newType
            // 获取当前type对应的params
            queryPrototypeSectionParams({
                type: type,
            }).then(res => {
                // let maxSid = pSections.map(item => item.sid).reduce((a,b) => a > b ? a : b);
                let param_in = paramDict2Array(res.data?.param_in)
                .map(item => Object.assign(item, { value: null }))
                .filter(item => item.name !== '_id')
                .sort((a, b) => a.name.indexOf('T_sec') >= 0 ? 1 : -1);
                this.paramIn = param_in;
                let shape_type = SectionTypes[type].shape_type;
                this.formRef.current?.setFieldsValue({
                    type: type,
                    shape_type: shape_type,
                    param_in: param_in
                })
            })
        } else {
            let secDetail = this.props.sectionDetail;
            let paramIn = paramDict2Array(secDetail?.param_in) || [];
            let paramOut = paramDict2Array(secDetail?.param_out) || [];
            paramIn.forEach(item => {
                let name = item.name;
                let unit = ParamUnits[name];
                let scale = Transfer2DisplayScale(unit);
                item.value *= scale;
                // item.value = +item.value.toFixed(1);
                item.value = NumFixed(item.value, 6);
            })
            paramIn.sort((a, b) => a.name.indexOf('T_sec') >= 0 ? 1 : -1);
            paramOut.forEach(item => {
                let name = item.name;
                let unit = ParamUnits[name];
                let scale = Transfer2DisplayScale(unit);
                item.value *= scale;
            })
            this.paramIn = paramIn;
            this.paramOut = paramOut;
            this.formRef.current?.setFieldsValue({
                name: secDetail?.name,
                type: secDetail?.type,
                shape_type: secDetail?.shape_type,
                sid: secDetail?.sid,
                param_in: paramIn,
                param_out: paramOut,
            })
        }

    }

    onCancel = () => {
        if (!!this.props.newType) {
            this.props.onCancel();
        } else {
            this.initForm()
            this.props.onCancel();
        }
    }

    onSave = (values) => {
        if (this.state.calculateError) {
            message.error('截面参数计算错误');
            return;
        }
        let param_in = values?.param_in || [];
        let param_out = values?.param_out || [];
        param_in = param_in.map(item => {
            let name = item.name;
            let unit = ParamUnits[name];
            let scale = Transfer2BackEndScale(unit);
            return {
                name: name,
                value: item.value*scale,
            }
        })
        param_out = param_out.map(item => {
            let name = item.name;
            let unit = ParamUnits[name];
            let scale = Transfer2BackEndScale(unit);
            return {
                name: name,
                value: item.value*scale,
            }
        })
        let data = Object.assign(values, {
            param_in: paramArrary2Dict(param_in),
            param_out: paramArrary2Dict(param_out),
        })
        if (this.props.onSave) {
            this.props.onSave(data)
        }
    }

    onTypeSelectChange = (value) => {
        let shape_type = SectionTypes[value]?.shape_type;
        this.formRef.current.setFieldsValue({
            shape_type: shape_type
        })
    }

    onParamInChange = () => {
        let { isEditing } = this.props;
        isEditing = isEditing || !!this.props.newType;
        if (!isEditing) return;
        let paramIn = this.formRef.current.getFieldValue('param_in');
        // 判断数组的值是否发生变化
        if (JSON.stringify(paramIn) === JSON.stringify(this.paramIn)) return;
        for (let idx = 0; idx < paramIn.length; idx++) {
            const item = paramIn[idx];
            if (item.name.indexOf('T_sec')>=0) {
                continue;
            }
            if (!item.value) {
                return;
            }
        }
        paramIn = paramIn.map(item => {
            let name = item.name;
            let unit = ParamUnits[name];
            let scale = Transfer2BackEndScale(unit);
            return {
                name: name,
                value: item.value*scale,
            }
        })
        calculateSectionParamOut({
            param_in: paramArrary2Dict(paramIn),
            type: this.props.sectionDetail?.type || this.props.newType,
        })
            .then(res => {
                let paramOut = paramDict2Array(res.data || []);
                paramOut.forEach(item => {
                    let name = item.name;
                    let unit = ParamUnits[name];
                    let scale = Transfer2DisplayScale(unit);
                    item.value *= scale;
                })
                this.paramOut = paramOut;
                this.formRef.current.setFieldsValue({
                    param_out: paramOut
                })
                this.setState({
                    calculateError: false
                })
            }).catch(err => {
                console.log(err)
                this.setState({
                    calculateError: true,
                })
                this.formRef.current.setFieldsValue({
                    param_out: []
                })
                message.error('截面计算错误');
            })
    }

    onEditClick = () => {
        if (this.props.onEdit) {
            this.props.onEdit();
        }
    }

    render() {
        let { expand } = this.state;
        let isEditing = this.props.isEditing;
        isEditing = isEditing || !!this.props.newType;
        let typeOptions = paramDict2Array(SectionTypes).filter(item => item.value.active).map((item, idx) => (
            <Option key={idx} value={+item.name}>{item.value.alias}</Option>
        ))
        let shapeTypeOptions = paramDict2Array(SectionShapeTypes).map((item, idx) => (
            <Option key={idx} value={+item.name}>{item.value}</Option>
        ))
        return (
            <div className="section-panel">
                <Form ref={this.formRef} labelCol={{ span: 6 }} autoComplete="off" onFinish={this.onSave}>
                    {!this.props.readOnly && (
                        <div className="operations">
                            <div className="title">信息</div>
                            {!isEditing && <Button type="primary" size="small" icon={<EditOutlined />} onClick={this.onEditClick}></Button>}
                            {isEditing && (
                                <Space>
                                    <Button type="primary" htmlType="submit" loading={this.props.saveLoading}>保存</Button>
                                    <Button onClick={this.onCancel}>取消</Button>
                                </Space>
                            )}
                        </div>
                    )}
                    <div className="top">
                        <div className="figure-shell">
                            <div className="figure">
                                {this.props.sectionDetail ? (
                                    <img width="100%" height="100%" alt="截面图片" src={`${sectionPicStaticLocation}${SectionProtoName[this.props.sectionDetail?.type]}.png`} />
                                ) : (
                                    <img width="100%" height="100%" alt="截面图片" src={`${sectionPicStaticLocation}${SectionProtoName[this.props.newType]}.png`} />
                                )}
                            </div>
                        </div>
                        <div className="properties">
                            {(!this.props.newType && !this.props.readOnly && !this.props.sidHidden) && (
                                <Form.Item label="截面号" name="sid" rules={[{ required: true, message: '截面号不能为空' }]}>
                                    <Input readOnly />
                                </Form.Item>
                            )}
                            <Form.Item label="名称" name="name" rules={[{ required: true, message: '截面名称不能为空' }]}>
                                <Input readOnly={!isEditing} />
                            </Form.Item>
                            <Form.Item label="类别" name="type">
                                {/* <Select disabled={!isEditing} onChange={this.onTypeSelectChange}>
                                    {typeOptions}
                                </Select> */}
                                <Select disabled showArrow={false}>
                                    {typeOptions}
                                </Select>
                            </Form.Item>
                            <Form.Item label="形状" name="shape_type">
                                <Select disabled showArrow={false}>
                                    {shapeTypeOptions}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>

                    <div className="param-in">
                        <div className="title">输入参数</div>
                        <div>
                            <Form.List name="param_in">
                                {(fields, _) => (
                                    fields.map((field, idx) => (
                                        /T_sec/.test(this.paramIn[idx]?.name) ?
                                            <Form.Item
                                                key={field.key}
                                                {...field}
                                                label={this.paramIn[idx]?.name.replace(/T_sec/, '规范截面类别')}
                                                name={[field.name, 'value']}
                                                rules={[{ required: true, message: '参数不能为空' }]}>
                                                <Select disabled={!isEditing}>
                                                    <Option value={1}>a类</Option>
                                                    <Option value={2}>b类</Option>
                                                    <Option value={3}>c类</Option>
                                                    <Option value={4}>d类</Option>
                                                </Select>
                                            </Form.Item>
                                            :
                                            <Form.Item
                                                key={field.key}
                                                {...field}
                                                label={<InlineMath math={this.paramIn[idx]?.name || ''} />}
                                                name={[field.name, 'value']}
                                                rules={[{ required: true, message: '参数不能为空' }]}>
                                                <NumericInput
                                                    readOnly={!isEditing}
                                                    onBlur={this.onParamInChange}
                                                    suffix={<span>{ParamUnits[this.paramIn[idx]?.name]}</span>} />
                                            </Form.Item>
                                    ))
                                )}
                            </Form.List>
                        </div>
                    </div>
                    <div className="param-out">
                        <div className="title">输出参数</div>
                        <div>
                            <Form.List name="param_out">
                                {(fields, _) => (
                                    fields.slice(0, expand ? fields.length : 5).map((field, idx) => (
                                        <Form.Item
                                            key={field.key}
                                            {...field}
                                            label={<InlineMath math={this.paramOut[idx]?.name || ''} />} name={[field.name, 'value']}>
                                            <Input readOnly suffix={<InlineMath math={ParamUnits[this.paramOut[idx]?.name]} />} />
                                        </Form.Item>
                                    ))
                                )}
                            </Form.List>
                        </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                this.setState({
                                    expand: !expand
                                })
                            }}
                        >
                            {expand ? <span><UpOutlined />折叠</span> : <span><DownOutlined />展开</span>}
                        </Button>
                    </div>
                </Form>

            </div>
        )
    }
}
