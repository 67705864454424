import React, { Component } from 'react'
import { drawing } from '@/interface/constage'
import { Button } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { figureStaticLocation } from '@/config/api/document';
interface IProps {
    drawingDetail: drawing
    goNext?: () => void
    goPrevious?: () => void
    onLinkState: boolean
}


export default class DrawingFigure extends Component<IProps> {
    render() {
        let drawingDetail = this.props.drawingDetail;
        let onLinkState = this.props.onLinkState;
        return (
            <div className="drawing-figure">
                <div>
                    <Button
                        disabled={onLinkState}
                        type="link"
                        size="large"
                        style={{ height: '100%' }}
                        onClick={this.props.goPrevious}
                        icon={<CaretLeftOutlined />}></Button>
                </div>
                <div className="fill-remaining-space">
                    <div className="figure">
                        {
                            drawingDetail ?
                                <img width="100%" height="100%" alt="模式图" src={`${figureStaticLocation}${drawingDetail.url}`} />
                                :
                                null
                        }
                    </div>
                </div>
                <div>
                    <Button
                        disabled={onLinkState}
                        type="link"
                        size="large"
                        style={{ height: '100%' }}
                        onClick={this.props.goNext}
                        icon={<CaretRightOutlined />}></Button>
                </div>
            </div>
        )
    }
}
