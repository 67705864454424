import axios from "../axios"
import { AxiosResponse } from "axios"

export function queryProcedures(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/procedures`, {
        params: params
    })
}

export function createProcedures(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/projects/${projectId}/procedures`, data, {
        params: params
    })
}

export function updateProcedureDetail(projectId: number, procedureId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/projects/${projectId}/procedures/${procedureId}`, data, {
        params: params
    })
}

export function deleteProcedure(projectId: number, procedureId: number,): Promise<AxiosResponse<any>> {
    return axios.delete(`/projects/${projectId}/procedures/${procedureId}`)
}