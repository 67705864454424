import React, { Component } from 'react'
import { Space, Button, Form, Input, Upload, message, Modal } from 'antd'
import DatePicker from '../DatePicker'
import { FormInstance } from 'antd/lib/form'
import ImgCrop from 'antd-img-crop';
import { PlusOutlined } from '@ant-design/icons';
import { constage } from '@/interface/constage';
import dayjs from 'dayjs';
import { updateConstage } from '@/config/api/constage';
import { ProjectContext } from '@/config/context';
import { figureUploadUrl, figureStaticLocation } from '@/config/api/document';
const { TextArea } = Input;
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}


interface IProps {
    constageDetail: constage,
    onCancel?: () => void,
    onSave?: () => void,
}

interface IState {
    fileList: any[]
    previewVisible: boolean
    previewImage: string
    loading: boolean
}
export default class ConstageDetailEdit extends Component<IProps, IState> {
    formRef = React.createRef<FormInstance>();
    figureDeleteIdList: number[] = [];
    static contextType = ProjectContext;
    detail: constage;
    state = {
        fileList: [],
        previewVisible: false,
        previewImage: '',
        loading: false,
    }

    componentDidMount() {
        this.detail = this.props.constageDetail;
        this.generateForm();
    }

    componentDidUpdate() {
        if (!this.detail) {
            this.detail = this.props.constageDetail;
            this.generateForm();
        }
    }

    generateForm = () => {
        let detail = this.props.constageDetail;
        if (!detail) return;
        let fileList = detail.figures.map((item, idx) => ({
            uid: item.id,
            id: item.id,
            name: item.name,
            status: 'done',
            url: `${figureStaticLocation}${item.url}`,
            originUrl: item.url,
        }))
        this.setState({
            fileList: fileList
        })
        this.formRef.current?.setFieldsValue({
            name: detail.name,
            code: detail.code,
            content: detail.content,
            start_time: dayjs(detail.start_time),
            end_time: dayjs(detail.end_time),
            fileList: fileList
        })
    }

    onSave = (values) => {
        if (!this.props.constageDetail) {
            message.error('发生错误');
            return;
        }
        this.setState({
            loading: true,
        })
        let figures1 = this.state.fileList.filter(item => item.response && item.status === 'done').map(item => {
            let res = item.response.data;
            return {
                id: res.id,
                url: res.url,
                name: res.name,
            }
        }) || [];
        let figures2 = this.state.fileList.filter(item => !item.response && item.status === 'done').map(item => ({
            id: item.id,
            url: item.originUrl,
            name: item.name,
        })) || [];
        let figures = [...figures1, ...figures2];
        let data = {
            name: values.name,
            code: values.code,
            content: values.content,
            start_time: dayjs(values.start_time).valueOf(),
            end_time: dayjs(values.end_time).valueOf(),
            figures: figures
        }
        updateConstage(this.context, this.props.constageDetail.id, data)
        .then(res => {
            this.setState({
                loading: false,
            })
            this.props.onSave();
        }).catch(err => {
            message.error('更新失败');
            console.log(err);
            this.setState({
                loading: false,
            })
        })
    }

    // ============== upload figures ================
    normFile = e => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange = ({ file, fileList }) => {
        this.setState({
            fileList: fileList
        })
    }
    handleRemove = (file) => {
        if (!file.response) {
            this.figureDeleteIdList.push(file.uid);
        }
        return true;
    }


    render() {
        let {loading} = this.state;
        return (
            <div className="constage-info">
                <div className="edit-content">
                    <Form labelCol={{ span: 3 }} ref={this.formRef} onFinish={this.onSave}>
                        <div className="edit-header" style={{ textAlign: 'right', marginBottom: '10px' }}>
                            <Space>
                                <Button type="primary" htmlType="submit" loading={loading}>保存</Button>
                                <Button onClick={this.props.onCancel}>取消</Button>
                            </Space>
                        </div>
                        <Form.Item label="施工步编号" name="code" rules={[{required: true}]}>
                            <Input placeholder="如：CS01/LS01等" />
                        </Form.Item>
                        <Form.Item label="名称" name="name" rules={[{required: true}]}>
                            <Input placeholder="如：卸载" />
                        </Form.Item>
                        <Form.Item label="内容描述" name="content" rules={[{required: true}]}>
                            <TextArea rows={4} />
                        </Form.Item>
                        <Form.Item label="起始时间" name="start_time" rules={[{required: true}]}>
                            <DatePicker showTime />
                        </Form.Item>
                        <Form.Item label="结束时间" name="end_time" rules={[{required: true}]}>
                            <DatePicker showTime />
                        </Form.Item>

                        <Form.Item
                            name="fileList"
                            label="上传示意图"
                            valuePropName="fileList"
                            getValueFromEvent={this.normFile}
                        >
                            <ImgCrop aspect={16 / 9} modalTitle="图片裁剪">
                                <Upload
                                    action={figureUploadUrl}
                                    listType="picture-card"
                                    fileList={this.state.fileList}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleChange}
                                    onRemove={this.handleRemove}
                                >
                                    {this.state.fileList.length >= 3 ? null : (
                                        <div>
                                            <PlusOutlined />
                                            <div className="ant-upload-text">Upload</div>
                                        </div>
                                    )}
                                </Upload>
                            </ImgCrop>
                        </Form.Item>
                    </Form>
                </div>

                <Modal
                    title="图片详览"
                    width={800}
                    footer={null}
                    visible={this.state.previewVisible}
                    //   onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <img width="100%" alt="照片" src={this.state.previewImage} />
                </Modal>
            </div>
        )
    }
}
