import React, { Component } from 'react'
import { Steps } from 'antd'
import { QuantityGroupSetting, QuantityPatternSetting, QuantityLimitSetting } from '..';
import './quantity-step.less';
import { inject, observer } from 'mobx-react';
import MobjectStore from '@/store/mobject-store';
import { updateMobjectDetail } from '@/config/api/mobject';

const { Step } = Steps;

export const SettingStepType = {
    Step_1: 0,
    Step_2: 1,
    Step_3: 2,
    Result: 3,
}

interface IProps {
    mobjectStore?: MobjectStore
    onMissionCompleted?: () => void
}

@inject('mobjectStore')
@observer
export default class QuantityStepPane extends Component<IProps> {

    state = {
        currentStep: SettingStepType.Step_1
    }
    mobjectId: number = 0;
    componentDidMount() {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        this.mobjectId = mobjectId;
        this.findStep();
    }

    componentDidUpdate() {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        if (this.mobjectId !== mobjectId) {
            this.mobjectId = mobjectId;
            this.findStep();
        }
    }

    findStep = () => {
        let mobject = this.props.mobjectStore._mobjectDetail;
        if (!!mobject) {
            this.setState({
                currentStep: mobject.setting_step
            })
        }
    }

    gotoStep = (value) => {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        if (mobjectId) {
            updateMobjectDetail(mobjectId, {
                setting_step: value
            }).then(res => { })
        }
        this.setState({
            currentStep: value
        })
    }

    render() {
        let _ = +this.props.mobjectStore._selectedMobjectId;
        // 可以把SETTING-FINISH改成number来存储步骤进度，0表示配置完成, TODO: 模拟数据暂时用state
        const currentStep = this.state.currentStep;
        let stepContents = [
            <QuantityGroupSetting onSave={() => this.gotoStep(SettingStepType.Step_2)} />,
            <QuantityPatternSetting onSave={() => this.gotoStep(SettingStepType.Step_3)} onPrevious={() => this.gotoStep(SettingStepType.Step_1)} />,
            <QuantityLimitSetting onPrevious={() => this.gotoStep(SettingStepType.Step_2)} onSave={this.props.onMissionCompleted} />
        ]
        return (
            <div className="quantity-step-container">
                <div className="quantity-step-header">
                    <Steps current={currentStep}>
                        <Step title="选择指标" />
                        <Step title="模式&参数" />
                        <Step title="初值&评估阈值" />
                    </Steps>
                </div>
                <div className="steps-content">
                    {stepContents[currentStep]}
                </div>

            </div>
        )
    }
}
