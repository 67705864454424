import axios from "../axios"
import { AxiosResponse } from "axios"

export function queryProjectGlobalQuantities(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/global-quantities`, {
        params: params
    })
}

export function updateProjectGlobalQuantities(projectId: number, quantityId: number, data: any,params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/projects/${projectId}/global-quantities/${quantityId}`, data, {
        params: params
    })
}