import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.less';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import PassportLayout from './layouts/passport';
import GlobalLayout from './layouts/global';
import MainLayout from './layouts/main';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { inject, observer } from 'mobx-react';
import { userCheckLogin } from './config/api/user';
import UserStore from './store/user-store';
import appStore, { USER_ID } from './store/app-store';
import BackendLayout from './layouts/backend';
import { user } from './interface/user';
import NativeAuthLayout from './layouts/native-auth';
import DashboardDark from './routes/dashboard/dashboard-dark';
// if (process.env.NODE_ENV === 'development') {
//     require('./mock/mock.js')
// }

interface IProps {
    userStore?: UserStore
}
@inject('userStore')
@observer
class App extends Component<IProps> {

    timer$: any;
    constructor(props) {
        super(props)
        this.checkLogin();
    }

    componentDidMount() {
        this.timer$ = setInterval(
            () => this.checkLogin(),
            1000 * 60,
        )
    }

    componentWillUnmount() {
        clearInterval(this.timer$);
    }

    checkLogin = () => {
        userCheckLogin()
        .then(res => {
            let userDetail: user = res.data;
            appStore.set(USER_ID, userDetail.id);
            this.props.userStore.setEnterpriseId(userDetail.enterprise_id);
            this.props.userStore.setUserInfo(userDetail);
            this.props.userStore.setIsLogin(true);
        }).catch(err => {
            this.props.userStore.setIsLogin(false);
        })
    }

    render() {
        return (
            <ConfigProvider locale={zhCN}>
                <div className="tn-base" >
                    <Router>
                        <Switch>
                            <Route path='/native/license' component={NativeAuthLayout}/>
                            <Route path='/projects/:id/dashboard'>
                                <DashboardDark/>
                            </Route>
                            {/* <Route path='/projects/:id/dashboard' component={DashboardDark} /> */}
                            <Route path='/projects/:id' component={MainLayout} />
                            <Route path='/passport' component={PassportLayout} />
                            <Route path='/projects' component={GlobalLayout} />
                            <Route path='/global' component={GlobalLayout} />
                            <Route path='/enterprise' component={GlobalLayout} />
                            <Route path='/backend' component={BackendLayout} />
                            <Redirect to="/projects" />
                        </Switch>
                    </Router>
                </div>
            </ConfigProvider>
        )
    }
}
// function App() {
//     return (
//         <ConfigProvider locale={zhCN}>
//             <div className="base" >
//                 <Router>
//                     <Switch>
//                         <Route path='/projects/:id' component={MainLayout} />
//                         <Route path='/passport' component={PassportLayout} />
//                         <Route path='/' component={GlobalLayout} />
//                     </Switch>
//                     {/* <Button>button</Button> */}
//                 </Router>
//             </div>
//         </ConfigProvider>

//         // <div className="App">
//         //   <header className="App-header">
//         //     <img src={logo} className="App-logo" alt="logo" />
//         //     <p>
//         //       Edit <code>src/App.tsx</code> and save to reload.
//         //     </p>
//         //     <a
//         //       className="App-link"
//         //       href="https://reactjs.org"
//         //       target="_blank"
//         //       rel="noopener noreferrer"
//         //     >
//         //       Learn React
//         //     </a>
//         //   </header>
//         // </div>
//     );
// }

export default App;
