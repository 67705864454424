import axios from "../axios";
import { AxiosResponse } from "axios";

export function queryGlobalMobjectParams(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/global/mobjects/params`, {
        params: params
    });
}

// 根据对象属性参数获取全局quantity-group-list
export function queryGlobalQuantityGroups(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/global/mobjects/quantity-groups`, {
        params: params
    });
}
// 根据对象属性参数及group_type获取全局pattern
export function queryGlobalPatterns(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/global/mobjects/patterns`, {
        params: params
    });
}