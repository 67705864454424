import axios from "../axios"
import { AxiosResponse } from "axios"

export function queryGlobalStandards(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/global/standards`, {
        params: params
    })
}

export function queryEnterprisesStandards(enterprise_id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/enterprises/${enterprise_id}/standards`, {
        params: params
    })
}

export function createEnterprisesStandards(enterprise_id: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/enterprises/${enterprise_id}/standards`, data, {
        params: params
    })
}

export function updateEnterpriseStandard(enterprise_id: number, enterpriseStandardId: number, data: any): Promise<AxiosResponse<any>> {
    return axios.put(`/enterprises/${enterprise_id}/standards/${enterpriseStandardId}`, data)
}

export function deleteEnterpriseStandard(enterprise_id: number, enterpriseStandardId: number, ): Promise<AxiosResponse<any>> {
    return axios.delete(`/enterprises/${enterprise_id}/standards/${enterpriseStandardId}`)
}

export function queryGlobalProcedures(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/global/procedures`, {
        params: params
    })
}

export function queryEnterprisesProcedures(enterprise_id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/enterprises/${enterprise_id}/procedures`, {
        params: params
    })
}

export function createEnterprisesProcedures(enterprise_id: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/enterprises/${enterprise_id}/procedures`, data, {
        params: params
    })
}

export function deleteEnterpriseProcedure(enterprise_id: number, globalProcedureId: number, ): Promise<AxiosResponse<any>> {
    return axios.delete(`/enterprises/${enterprise_id}/procedures/${globalProcedureId}`)
}

