import React, { Component } from 'react'
import { Form, Input, Select, Space, Button, message } from 'antd'
import { paramDict2Array } from '@/utils/utils';
import { monitorType } from '@/utils/type';
import DatePicker from '../DatePicker';
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';
import { user } from '@/interface/user';
import { queryUsers } from '@/config/api/user';
import { createProject } from '@/config/api/project';
import { NumericInput } from '..';
const { Option } = Select;

interface IProps {
    userStore?: UserStore
    onCancel?: () => void
    afterSave?: () => void
}
interface IState {
    userList: user[]
    loading: boolean
}
@inject('userStore')
@observer
export default class ProjectAdd extends Component<IProps, IState> {

    enterpriseId: number;
    state = {
        userList: [],
        loading: false,
    }

    componentDidMount() {
        this.enterpriseId = this.props.userStore._enterpriseId;
        this.findUsers();
    }

    componentDidUpdate() {
        if (this.enterpriseId !== this.props.userStore._enterpriseId) {
            this.enterpriseId = this.props.userStore._enterpriseId;
            this.findUsers();
        }
    }


    findUsers = () => {
        let eid = this.props.userStore._enterpriseId;
        queryUsers({
            enterprise_id: eid,
        }).then(res => {
            let users: user[] = res.data || [];
            users = users.filter(item => item.type === 2 || item.type === 3 || item.type === 1);
            this.setState({
                userList: users
            })
        })
    }

    onfinish = (values) => {
        this.setState({
            loading: true,
        })
        let eid = this.props.userStore._enterpriseId;
        let data = Object.assign(values, { enterprise_id: eid, active: true });
        createProject(data)
            .then(res => {
                this.setState({
                    loading: false,
                })
                this.props.afterSave();
            }).catch(err => {
                message.error('创建失败');
                this.setState({
                    loading: false,
                })
            })
    }

    render() {
        let eid = this.props.userStore._enterpriseId;
        return (
            <div>
                <Form labelCol={{ span: 5 }} onFinish={this.onfinish} autoComplete="off">
                    <Form.Item label="项目名称" name="name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="监测等级" name="monitor_rank" initialValue={1} rules={[{ required: true }]}>
                        <Select>
                            <Option value={1}>一级</Option>
                            <Option value={2}>二级</Option>
                            <Option value={3}>三级</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="监测类别" name="monitor_type" initialValue={1} rules={[{ required: true }]}>
                        <Select>
                            {paramDict2Array(monitorType).map((item, idx) => (
                                <Option value={+item.name} key={idx}>{item.value}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="开始时间" name="start_time" rules={[{ type: 'object', required: true }]}>
                        <DatePicker format="YYYY-MM-DD" style={{ width: '200px' }} />
                    </Form.Item>
                    <Form.Item label="监测周期(天)" name="period" rules={[{ required: true }]}>
                        <NumericInput />
                    </Form.Item>
                    <Form.Item label="负责人" name="leader_id">
                        <Select>
                            {this.state.userList.map((item, idx) => (
                                <Option value={item.id} key={idx}>{item.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <div style={{ textAlign: 'right', marginTop: '24px' }}>
                        <Space size="large">
                            <Button onClick={this.props.onCancel} >取消</Button>
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>保存</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        )
    }
}
