import React, { Component } from 'react'
import LineChart from './line-chart';
import { quantityData } from '@/interface/quantity';
import { QuantityName } from '../QuantityName';
import './line-chart-dashboard.less';
import { useParams, withRouter } from 'react-router-dom';
import { Gprops } from '@/config/props';
import { MobjectBaseType } from '@/utils/type';
import { ProjectContext } from '@/config/context';

interface IProps extends Gprops {
    quantityData: quantityData,
    height?: number
}
class LineChartDashboard extends Component<IProps> {
    static contextType = ProjectContext;

    ref = React.createRef<HTMLDivElement>();
    state = {
        clientWidth: 0,
    }

    componentDidMount() {
        // if (this.ref.current.clientWidth) {
        //     let width = this.ref.current.clientWidth
        //     this.setState({
        //         clientWidth: width
        //     })
        // }
    }

    goToMobjectDetail = () => {
        let record = this.props.quantityData;
        if (!record.mobject) return;
        let mobject = record.mobject;
        let typeName = MobjectBaseType[mobject?.type]['name'];
        let projectId = +this.props.match.params['id'];
        if (isNaN(projectId)) return;
        let url = `/projects/${projectId}/mobjects/${typeName}/${mobject.id}?tab=2&group=${record.group_id}`;
        this.props.history.push(url);
    }


    render() {
        let q = this.props.quantityData;
        return (
            <div className="line-chart-dashboard-container">
                <LineChart width={this.state.clientWidth} height={this.props.height || 270} q_data={q} precision={+q.precision} />
                <div className="legend">
                    <div className="legend-group">
                        <div className="label">最大值</div>
                        <div className="content">{q.max?.toFixed(+q.precision)}</div>
                    </div>
                    <div className="legend-group">
                        <div className="label">最小值</div>
                        <div className="content">{q.min?.toFixed(+q.precision)}</div>
                    </div>
                    <div className="legend-group">
                        <div className="label">平均值</div>
                        <div className="content">{q.mean?.toFixed(+q.precision)}</div>
                    </div>
                    {q.unit_type && q.unit_type !== '' && (
                        <div className="legend-group">
                            <div className="label">单位</div>
                            <div className="content">{q.unit_type}</div>
                        </div>
                    )}
                    <div className="title" onClick={this.goToMobjectDetail}>
                        <span style={{marginRight: '4px'}}>{q.mobject_name}</span>
                        <QuantityName showChineseName={false} record={q} />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(LineChartDashboard);