import axios from "../axios"
import { AxiosResponse } from "axios"

export function queryEnterprisesInstallations(enterprisesId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/enterprises/${enterprisesId}/installations`, {
        params: params
    })
}

export function createEnterprisesInstallations(enterprisesId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/enterprises/${enterprisesId}/installations`, data, {
        params: params
    })
}

export function queryEnterprisesInstallationsDetail(enterprisesId: number, installId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/enterprises/${enterprisesId}/installations/${installId}`, {
        params: params
    })
}

export function deleteEnterpriseInstallationDetail(enterprisesId: number, installId: number,): Promise<AxiosResponse<any>> {
    return axios.delete(`/enterprises/${enterprisesId}/installations/${installId}`)
}

export function updateEnterpriseInstallation(enterprisesId: number, installId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/enterprises/${enterprisesId}/installations/${installId}`, {
        params:  params
    })
}