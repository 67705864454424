import React, { Component } from 'react'
import { Button, Row, Col, Carousel, Descriptions } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import { project } from '@/interface/project';
import { figure } from '@/interface/document';
import { paramDict2Array } from '@/utils/utils';
import { StructureTypes, ConstructionTypes } from './project-info-edit';
import { monitorType, monitorRank } from '@/utils/type';
import dayjs from 'dayjs';
import { figureStaticLocation } from '@/config/api/document';

interface IProps {
    projectInfo: project
    projectFigures: figure[]
    onEditClick?: () => void
}

export default class ProjectInfoBaseBoard extends Component<IProps> {
    render() {
        const structTypes = paramDict2Array(this.props.projectInfo?.structure_type).filter(item => item.value) || [];
        const construTypes = paramDict2Array(this.props.projectInfo?.construction_type).filter(item => item.value) || [];
        return (
            <div className="project-info-board">
                <div className="header" style={{ paddingBottom: '4px', marginTop: '-6px' }}>
                    <div className="title">项目介绍</div>
                    <div>
                        <Button type="primary" size="middle" icon={<EditOutlined />} onClick={this.props.onEditClick}></Button>
                    </div>
                </div>
                <div>
                    <Row gutter={10}>
                        <Col xl={13} xs={24}>
                            <Descriptions bordered size="middle" column={2}>
                                <Descriptions.Item label="结构类别" span={2}>
                                    <div style={{ display: 'flex' }}>
                                        {structTypes.map((item, idx) => (
                                            <div key={idx}>
                                                {StructureTypes[item.name]}
                                                {idx < structTypes.length - 1 ? <span>、</span> : null}
                                            </div>
                                        ))}
                                    </div>
                                </Descriptions.Item>
                                <Descriptions.Item label="施工技术" span={2}>
                                    <div style={{ display: 'flex' }}>
                                        {construTypes.map((item, idx) => (
                                            <div key={idx}>
                                                {ConstructionTypes[item.name]}
                                                {idx < construTypes.length - 1 ? <span>、</span> : null}
                                            </div>
                                        ))}
                                    </div>
                                </Descriptions.Item>
                                <Descriptions.Item label="监测类别">
                                    {monitorType[this.props.projectInfo?.monitor_type]}监测
                                </Descriptions.Item>
                                <Descriptions.Item label="监测等级">
                                    {monitorRank[this.props.projectInfo?.monitor_rank]}
                                </Descriptions.Item>
                                <Descriptions.Item label="总重量">
                                    {this.props.projectInfo?.weight}
                                </Descriptions.Item>
                                <Descriptions.Item label="平面尺寸">
                                    {this.props.projectInfo?.size}
                                </Descriptions.Item>
                                <Descriptions.Item label="开始时间">
                                    {dayjs(this.props.projectInfo?.start_time).format('YYYY年MM月DD日')}
                                </Descriptions.Item>
                                <Descriptions.Item label="周期">
                                    {this.props.projectInfo?.period} 天
                                </Descriptions.Item>
                                <Descriptions.Item label="委托单位" span={2}>
                                    {this.props.projectInfo?.client_name}
                                </Descriptions.Item>
                                <Descriptions.Item label="工程地点">
                                    {this.props.projectInfo?.project_address}
                                </Descriptions.Item>
                                <Descriptions.Item label="委托时间">
                                    {this.props.projectInfo?.order_date ? dayjs(this.props.projectInfo?.order_date).format('YYYY年MM月DD日') : null}
                                </Descriptions.Item>
                            </Descriptions>
                            {/* <div style={{paddingLeft: '16px'}}>
                                <div className="info-item">
                                    <div className="label">结构类别</div>
                                    <div className="content">
                                        <div style={{ display: 'flex' }}>
                                            {structTypes.map((item, idx) => (
                                                <div key={idx}>
                                                    {StructureTypes[item.name]}
                                                    {idx < structTypes.length - 1 ? <span>、</span> : null}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="info-item">
                                    <div className="label">施工技术</div>
                                    <div className="content">
                                        <div style={{ display: 'flex' }}>
                                            {construTypes.map((item, idx) => (
                                                <div key={idx}>
                                                    {ConstructionTypes[item.name]}
                                                    {idx < construTypes.length - 1 ? <span>、</span> : null}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="info-item">
                                    <div className="label">监测类别</div>
                                    <div className="content">
                                        {monitorType[this.props.projectInfo?.monitor_type]}监测
                                </div>
                                </div>
                                <div className="info-item">
                                    <div className="label">监测等级</div>
                                    <div className="content">
                                        {monitorRank[this.props.projectInfo?.monitor_rank]}
                                    </div>
                                </div>
                                <div className="info-item">
                                    <div className="label">总重量</div>
                                    <div className="content">
                                        {this.props.projectInfo?.weight}
                                    </div>
                                </div>
                                <div className="info-item">
                                    <div className="label">尺寸</div>
                                    <div className="content">
                                        {this.props.projectInfo?.size}
                                    </div>
                                </div>
                                <div className="info-item">
                                    <div className="label">开始时间</div>
                                    <div className="content">
                                        {dayjs(this.props.projectInfo?.start_time).format('YYYY年MM月DD日')}
                                    </div>
                                </div>
                                <div className="info-item">
                                    <div className="label">监测周期</div>
                                    <div className="content">
                                        {this.props.projectInfo?.period} 天
                                </div>
                                </div>
                            </div> */}
                        </Col>
                        <Col xl={11} xs={24}>
                            <div>
                                <Carousel autoplay>
                                    {this.props.projectFigures.length > 0 ? this.props.projectFigures.map((figure: figure, idx: number) => (
                                        <div key={figure.id}>
                                            <div className="figure">
                                                <img width="100%" height="100%" alt="项目示意图" src={`${figureStaticLocation}${figure.url}`} />
                                            </div>
                                        </div>
                                    )) : (
                                            <div >
                                                <div className="figure">
                                                </div>
                                            </div>
                                        )}
                                </Carousel>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
