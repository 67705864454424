import React, { Component } from 'react'
import { ProjectContext } from '@/config/context';
import axios from '@/config/axios';
import { queryGlobalStandards, queryEnterprisesStandards, queryGlobalProcedures, queryEnterprisesProcedures } from '@/config/api/global-standard';
import { globalProcedure, globalStandard } from '@/interface/procedure';
import { Select, Form, Space, Button } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { ProcedureType } from '@/utils/type';
import { paramDict2Array } from '@/utils/utils';


const { Option } = Select;

interface IProps {
    // currentProcedure: procedure
    saveLoading?: boolean
    onSave?: (values) => void
    onCancel?: () => void
}
interface IState {
    globalProcedures: globalProcedure[]
    globalStandards: globalStandard[]
    displayGProcedures: globalProcedure[]
    displayGStandardCodes: string[]
    displayGStandardItem: globalStandard[]
}

export default class ProcedureEdit extends Component<IProps, IState> {
    formRef = React.createRef<FormInstance>();
    static contextType = ProjectContext; // TODO: 改成enterpriseId
    constructor(props) {
        super(props);
        this.state = {
            globalProcedures: [],
            globalStandards: [],
            displayGProcedures: [],

            displayGStandardCodes: [],
            displayGStandardItem: [],
        }
    }

    componentDidMount() {
        this.findProcedure();
    }

    findProcedure = () => {
        let globalProcedurePromise = queryGlobalProcedures();
        let enterprisePromise = queryEnterprisesProcedures(this.context);
        let globalStandardsPromise = queryGlobalStandards();
        let enterpriseStandardsPromise = queryEnterprisesStandards(this.context);
        // let procedurePromise = queryProcedures(this.context);
        axios.all([globalProcedurePromise, enterprisePromise, globalStandardsPromise, enterpriseStandardsPromise])
            .then((res) => {
                let gProcedures: globalProcedure[] = res[0].data;
                let eProcedures: globalProcedure[] = res[1].data;
                let gStandards: globalStandard[] = res[2].data;
                let eStandards: globalStandard[] = res[3].data;

                let globalProcedures: globalProcedure[] = [...gProcedures, ...eProcedures];
                let globalStandardes: globalStandard[] = [...gStandards, ...eStandards];
                this.setState({
                    globalProcedures: globalProcedures,
                    globalStandards: globalStandardes,
                    // procedures: procedures,
                })
            })
    }

    onProcedureTypeChange = (value) => {
        let displayGProcedures = this.state.globalProcedures.filter(item => item.type === value);
        this.setState({
            displayGProcedures: displayGProcedures
        })
    }

    onStandardChange = (value) => {
        let standards = this.state.globalStandards.filter(item => item.name === value);
        this.setState({
            displayGStandardCodes: Array.from(new Set(standards.map(item => item.code)))
        })
    }

    onStandardCodeChange = (value) => {
        let name = this.formRef.current.getFieldValue('standard_name');
        let standards = this.state.globalStandards.filter(item => item.code === value && item.name === name);
        this.setState({
            displayGStandardItem: standards
        })
    }



    onSave = (values) => {
        this.props.onSave(values);
    }

    render() {
        let standardNames = Array.from(new Set(this.state.globalStandards.map(item => item.name)));
        return (
            <div>
                <div>
                    <Form ref={this.formRef} labelCol={{ span: 5 }} wrapperCol={{span: 19}} onFinish={this.onSave}>
                        <Form.Item name="type" label="技术点类别" rules={[{ required: true }]}>
                            <Select onChange={this.onProcedureTypeChange}>
                                {paramDict2Array(ProcedureType).map((item, idx) => (
                                    <Option key={idx} value={+item.name}>{item.value}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="content" label="技术点内容" rules={[{ required: true }]}>
                            <Select>
                                {this.state.displayGProcedures.map((item, idx) => (
                                    <Option key={idx} value={item.content}>{item.content}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="standard_name" label="规范标准" rules={[{ required: true }]}>
                            <Select onChange={this.onStandardChange}>
                                {standardNames.map((item, idx) => (
                                    <Option key={idx} value={item}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="standard_code" label="规范编号" rules={[{ required: true }]}>
                            <Select onChange={this.onStandardCodeChange}>
                                {this.state.displayGStandardCodes.map((item, idx) => (
                                    <Option key={idx} value={item}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="standard_item_number" label="条文号" rules={[{ required: true }]}>
                            <Select>
                                {this.state.displayGStandardItem.map((item, idx) => (
                                    <Option key={idx} value={item.item_number}>{item.item_number} {item.content}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <div style={{ textAlign: 'right', marginTop: '24px' }}>
                            <Space size="large">
                                <Button type="primary" htmlType="submit" loading={this.props.saveLoading}>保存</Button>
                                <Button onClick={this.props.onCancel}>取消</Button>
                            </Space>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}
