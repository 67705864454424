import React, { Component, Fragment, CSSProperties } from 'react';
import './dbox-sensor-pane.less';
import { dbox } from '@/interface/dbox';
import { sensor, globalSensor } from '@/interface/sensor';
import { querySensors, queryNoChannelSensors, updateSensor, sensorInitialize } from '@/config/api/sensor';
import { ProjectContext } from '@/config/context';
// import DboxSensor from './dbox-sensor';
// import DboxSensorBlank from './dbox-sensor-blank';
import { inject, observer } from 'mobx-react';
import GlobalDeviceStore from '@/store/global-device';
import { DndProvider, useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import classnames from 'classnames';
import { Space, Button, Empty, Checkbox, message, Spin } from 'antd';
import IconFont from '@/config/iconfont';
import { CloseCircleOutlined, LinkOutlined } from '@ant-design/icons';
import axios from '@/config/axios';
import { Gprops } from '@/config/props';
import { withRouter } from 'react-router-dom';
import { SensorStatusTypesEnum } from '@/routes/sensor/sensor-list';

export interface DboxChannelObj {
    channel: number
    sensor?: sensor
}

interface DragItem {
    type: string | string[],
    sensor: sensor,
    channel?: number,
    channelObj?: DboxChannelObj,
}

const ItemTypes = {
    DBOX_SENSOR: 'DboxSensor',
    NOCHANNEL_SENSOR: 'NoChannelSensor',
};

const DboxSensor = (props: {
    channelObj: DboxChannelObj,
    canDrag: boolean,
    isInitializing: boolean,
    onClick?: () => void,
    changeChannel?: (dropper: sensor, dropper_channel_to: number, dragger: sensor, dragger_channel_to: number) => void
}) => {

    const ref = React.useRef();
    let sensor = props.channelObj.sensor;
    let channel = props.channelObj.channel;

    const [{ isDragging }, drager] = useDrag({
        // 传递给drop组件的参数
        item: {
            type: ItemTypes.DBOX_SENSOR,
            sensor: sensor,
            channel: channel,
            channelObj: props.channelObj,
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        canDrag: monitor => {
            return props.canDrag
        }
    })

    const [collectProps, droper] = useDrop({
        accept: [ItemTypes.DBOX_SENSOR, ItemTypes.NOCHANNEL_SENSOR],
        collect: (monitor: DropTargetMonitor) => {
            const item: DragItem = monitor.getItem() || {};
            const current = props.channelObj.sensor || {}
            if (item?.sensor === current) return {};
            return {
                isOver: monitor.isOver(),

            }
        },
        drop: (item: any) => {
            props.changeChannel(sensor, item.channel, item.sensor, channel);
        }
    })

    // 同时作为drag和drop时需要包裹ref
    droper(drager(ref));

    return (
        <Spin spinning={props.isInitializing}>
            <div className={classnames({
                'channel-box': true,
                'sensor-working': sensor.work_status === 1,
                'sensor-offline': sensor.work_status === 0,
                'sensor-abnormal': sensor.work_status === 2,
                'will-drop': collectProps.isOver,
                'can-drag': props.canDrag,
            })} ref={ref} style={{
                opacity: isDragging ? 0.3 : 1
            }} onClick={props.onClick}>
                <div>{sensor.type_name}</div>
                <div>{sensor.name}</div>
                {/* <div className="hover-display">{sensor.marking}</div> */}
                <div className="sensor-status-tag"></div>
            </div>
        </Spin>
    );
}

const DboxSensorBlank = (props: { channelObj: DboxChannelObj, setupChannel: (item: sensor, channel: number) => void }) => {

    const [collectProps, droper] = useDrop({
        accept: [ItemTypes.DBOX_SENSOR, ItemTypes.NOCHANNEL_SENSOR],
        collect: (monitor: DropTargetMonitor) => {
            return {
                isOver: monitor.isOver(),

            }
        },
        drop: (item: any) => {
            props.setupChannel(item.sensor, props.channelObj.channel)
        }
    })

    return (
        <div ref={droper} className={classnames({
            'channel-box': true,
            'sensor-blank': true,
            'will-drop': collectProps.isOver,
        })} />
    );
}

const NoChannelSensor = (props: {
    sensor: sensor,
    canDrag: boolean,
    globalSensors: globalSensor[],
    style?: CSSProperties,
    onCheckboxChange: (sensor: sensor, checked: boolean) => void
}) => {

    let sensor = props.sensor;
    let gsensor = props.globalSensors.filter(item => item.type === sensor.type)[0];
    sensor.type_name = gsensor ? gsensor.name : '未知传感器';

    const [{ isDragging }, drager] = useDrag({
        // 传递给drop组件的参数
        item: {
            type: ItemTypes.NOCHANNEL_SENSOR,
            sensor: sensor,
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        canDrag: monitor => {
            return props.canDrag
        }
    })

    function onCheckboxChange(e) {
        props.onCheckboxChange(sensor, e.target.checked);
    }

    return (
        <div className={classnames({
            'nochannel-sensor-item': true
        })} style={{
            opacity: isDragging ? 0.3 : 1,
            ...props.style
        }} ref={drager}>
            <Checkbox onChange={onCheckboxChange}>{`${sensor.type_name}${sensor.marking}`}</Checkbox>
            <div className="fill-remaining-space"></div>
            <div>{sensor.name}</div>
        </div>
    )
}

interface IProps extends Gprops {
    dbox: dbox
    globalDeviceStore?: GlobalDeviceStore
    afterSave: () => void
}
interface IState {
    channelObjs: DboxChannelObj[]
    canDrag: boolean
    noChannelSensors: sensor[]
    loading: boolean

    sensorInitialLoading: boolean
    currentInitialChannel: number
}

@inject('globalDeviceStore')
@observer
class DboxSensorPane extends Component<IProps, IState> {

    static contextType = ProjectContext;
    sensors: sensor[] = []; // 已被关联的传感器列表
    checkedNoChannelSensors: sensor[] = []; // 暂存即将要被关联的打勾传感器
    noChannelSensors: sensor[] = []; // 右侧没有关联的传感器列表
    dboxId: number;
    state = {
        channelObjs: [],
        canDrag: false,
        noChannelSensors: [],
        loading: false,

        sensorInitialLoading: false,
        currentInitialChannel: null,
    }

    componentDidMount() {
        this.dboxId = this.props.dbox?.id;
        this.findDboxSensors();
        this.findNoChannelSensors();
    }

    componentDidUpdate() {
        if (this.dboxId !== this.props.dbox?.id) {
            this.dboxId = this.props.dbox?.id;
            this.findDboxSensors();
            this.findNoChannelSensors();
        }
    }

    findDboxSensors = () => {
        let channelCount = this.props.dbox.channel_count;
        querySensors({
            project_id: this.context,
            dbox_id: this.props.dbox.id,
        }).then(res => {
            this.sensors = res.data;
            this.sensors = this.sensors.filter(item => !!item.channel);
            if (!!this.props.dbox.channel_range) {
                this.generateDboxChannelDIY(this.props.dbox.channel_range);
                return;
            }
            this.generateDboxChannel(channelCount);
        })
    }

    findNoChannelSensors = () => {
        queryNoChannelSensors({
            project_id: this.context,
        }).then(res => {
            let noChannelSensors = res.data;
            noChannelSensors = noChannelSensors.sort((a, b) => a.name > b.name ? 1 : -1);
            this.noChannelSensors = noChannelSensors;
            this.setState({
                noChannelSensors: noChannelSensors
            })
        })
    }

    // 生成左侧通道列表，重置通道也可用
    generateDboxChannel = (channelCount: number) => {
        let channelObjList: DboxChannelObj[] = [];
        for (let idx = 0; idx < channelCount; idx++) {
            let channel = idx + 1;
            let sensor = this.sensors.filter(sensor => sensor.channel === channel)[0];
            if (!!sensor) {
                let gsensor = this.props.globalDeviceStore._globalSensors.filter(item => item.type === sensor.type)[0];
                sensor.type_name = gsensor ? gsensor.name : '未知传感器';
            }
            let channelObj = {
                channel: channel,
                sensor: sensor
            }
            channelObjList.push(channelObj);
        }
        this.setState({
            channelObjs: channelObjList
        })
    }

    generateDboxChannelDIY = (channelRange: string) => {
        // 1-5,10,15-20
        if (channelRange == null || channelRange == '') return;
        let tmp = channelRange.split(',') || [];
        let channelList: number[] = [];
        tmp.forEach(str => {
            if (!!+str) {
                channelList.push(+str);
            } else if (str.indexOf('-') > 0) {
                let tmp2 = str.split('-') || [];
                if (tmp2.length !== 2 || !!!+tmp2[0] || !!!+tmp2[1]) {
                    return;
                }
                for(let i=+tmp2[0]; i<= +tmp2[1]; i++) {
                    channelList.push(i);
                }
            }
        })
        let channelObjList: DboxChannelObj[] = [];
        channelList.forEach(channel => {
            let sensor = this.sensors.filter(sensor => sensor.channel === channel)[0];
            if (!!sensor) {
                let gsensor = this.props.globalDeviceStore._globalSensors.filter(item => item.type === sensor.type)[0];
                sensor.type_name = gsensor ? gsensor.name : '未知传感器';
            }
            let channelObj = {
                channel: channel,
                sensor: sensor
            }
            channelObjList.push(channelObj);
        })
        this.setState({
            channelObjs: channelObjList
        })
    }

    // 将传感器放入空的通道
    setupChannel = (sensor: sensor, channel: number) => {
        sensor.channel = channel;
        sensor.dbox_id = this.props.dbox.id;
        if (this.sensors.findIndex(item => item.id === sensor.id) < 0) {
            this.sensors.push(sensor);
            this.noChannelSensors = this.noChannelSensors.filter(item => item.id !== sensor.id);
            this.setState({
                noChannelSensors: this.noChannelSensors
            })
        }
        if (!!this.props.dbox.channel_range) {
            this.generateDboxChannelDIY(this.props.dbox.channel_range);
            return;
        }
        this.generateDboxChannel(this.props.dbox.channel_count);
    }

    // 传感器通道交换 或者 未关联与已关联替换
    changeChannel = (dropper: sensor, dropper_channel_to: number, dragger: sensor, dragger_channel_to: number) => {
        dragger.channel = dragger_channel_to;

        dropper.channel = dropper_channel_to ? dropper_channel_to : -1;
        if (dropper.channel === -1) {
            // dropper被替换出去
            dropper.dbox_id = -1;
            this.sensors = this.sensors.filter(item => item.id !== dropper.id);
            this.noChannelSensors = [dropper, ...this.noChannelSensors];
            // console.log(dropper);
            this.setState({
                noChannelSensors: this.noChannelSensors
            })
        }
        if (dragger.dbox_id !== this.props.dbox.id) {
            // dragger从未关联列表拖拽进来
            dragger.dbox_id = this.props.dbox.id;
            this.noChannelSensors = this.noChannelSensors.filter(item => item.id !== dragger.id);
            this.sensors = [dragger, ...this.sensors];
            // console.log('aa');
            // console.log(dragger);
            this.setState({
                noChannelSensors: this.noChannelSensors
            })
        }
        if (!!this.props.dbox.channel_range) {
            this.generateDboxChannelDIY(this.props.dbox.channel_range);
            return;
        }
        this.generateDboxChannel(this.props.dbox.channel_count);
    }

    // 释放通道
    releaseChannel = (sensor: sensor) => {
        sensor.channel = -1;
        sensor.dbox_id = -1;
        this.noChannelSensors = [sensor, ...this.noChannelSensors];
        this.sensors = this.sensors.filter(item => item.id !== sensor.id);
        this.setState({
            noChannelSensors: this.noChannelSensors
        });
        if (!!this.props.dbox.channel_range) {
            this.generateDboxChannelDIY(this.props.dbox.channel_range);
            return;
        }
        this.generateDboxChannel(this.props.dbox.channel_count);
    }


    onMultiLinkChannelClick = () => {
        // 寻找blank通道
        let channelObjs = this.findBlankChannels(this.checkedNoChannelSensors.length);
        this.checkedNoChannelSensors.forEach((sensor, idx) => {
            let channelObj = channelObjs[idx];
            if (!!channelObj) {
                sensor.channel = channelObj.channel;
                sensor.dbox_id = this.props.dbox.id;
                this.noChannelSensors = this.noChannelSensors.filter(item => item.id !== sensor.id);
                this.sensors = [sensor, ...this.sensors];
            }
        })
        this.setState({
            noChannelSensors: this.noChannelSensors,
        })
        this.checkedNoChannelSensors = [];

        if (!!this.props.dbox.channel_range) {
            this.generateDboxChannelDIY(this.props.dbox.channel_range);
            return;
        }
        this.generateDboxChannel(this.props.dbox.channel_count);

    }

    findBlankChannels = (count: number) => {
        let channelObjs: DboxChannelObj[] = [];
        for (let idx = 0; idx < this.state.channelObjs.length; idx++) {
            const channelObj: DboxChannelObj = this.state.channelObjs[idx];
            if (!!channelObj.sensor) {
                continue;
            } else {
                channelObjs.push(channelObj);
            }
            if (channelObjs.length === count) return channelObjs;
        }
        return channelObjs;
    }

    onCheckboxChangeClick = (sensor: sensor, checked: boolean) => {
        if (checked) {
            this.checkedNoChannelSensors = [...this.checkedNoChannelSensors, sensor];
        } else {
            this.checkedNoChannelSensors = this.checkedNoChannelSensors.filter(item => item.id !== sensor.id);
        }
    }

    startDrag = () => {
        this.setState({
            canDrag: true
        })
    }

    cancelDrag = () => {
        this.setState({
            canDrag: false
        })
        this.findDboxSensors();
    }

    onSave = () => {
        this.setState({
            loading: true,
        })
        // 1 把格子里的传感器更新一遍
        let channelObjs = this.state.channelObjs;
        let channelPromiseList = channelObjs
            .filter((item: DboxChannelObj) => !!item.sensor)
            .map((item: DboxChannelObj) => updateSensor(item.sensor.id, {
                dbox_id: item.sensor.dbox_id,
                channel: item.channel
            })) || [];
        // 2 把release的传感器更新一遍
        let noChannelPromiseList = this.noChannelSensors
            .filter(item => item.channel === -1 || item.dbox_id === -1)
            .map(item => updateSensor(item.id, {
                dbox_id: -1,
                channel: -1
            })) || [];

        axios.all([...channelPromiseList, ...noChannelPromiseList])
            .then(res => {
                this.setState({
                    canDrag: false,
                    loading: false,
                })
                this.findDboxSensors();
                this.findNoChannelSensors();
                if (this.props.afterSave) {
                    this.props.afterSave();
                }
            }).catch(err => {
                message.error('更新通道失败');
                console.log(err);
                this.setState({
                    loading: false,
                })
            })
    }

    onSensorClick = (value: sensor) => {
        if (this.state.canDrag) {
            return;
        }
        this.props.history.push(`/projects/${this.context}/sensors/${value.id}`)
    }

    onSensorInitialMultiClick = () => {

        let channelObjs: DboxChannelObj[] = this.state.channelObjs;
        channelObjs = channelObjs.filter(item => !!item.sensor) || [];
        channelObjs = channelObjs.filter(item => item.sensor.work_status !== SensorStatusTypesEnum.ONLINE) || [];
        if (channelObjs.length <= 0) {
            message.info('当前无传感器通道需要操作。');
            return;
        }
        this.setState({
            sensorInitialLoading: true,
        })

        this.onSensorInitial(0, channelObjs);
    }

    onSensorInitial = (idx: number, channelObjs: DboxChannelObj[]) => {
        if (idx >= channelObjs.length) {
            this.setState({
                sensorInitialLoading: false,
                currentInitialChannel: null,
            })
            message.info('操作已完成');
            return;
        }
        let obj = channelObjs[idx];
        let channel = obj.channel;
        let sensor = obj.sensor;
        this.setState({
            currentInitialChannel: channel,
        })
        sensorInitialize(sensor.id)
            .then(res => {
                sensor.work_status = SensorStatusTypesEnum.ONLINE;
                this.setState({
                    channelObjs: [...channelObjs],
                })
                updateSensor(sensor.id, {
                    work_status: SensorStatusTypesEnum.ONLINE
                }).then(r => { });
                this.onSensorInitial(idx + 1, channelObjs);

            }).catch(err => {
                sensor.work_status = SensorStatusTypesEnum.ABNORMAL;
                this.setState({
                    channelObjs: [...channelObjs],
                })
                updateSensor(sensor.id, {
                    work_status: SensorStatusTypesEnum.ABNORMAL
                }).then(r => { });
                this.onSensorInitial(idx + 1, channelObjs);
            }).finally(() => {

            })
    }

    render() {

        return (
            <DndProvider backend={HTML5Backend}>
                <div className="button-pane">
                    <Space size="large" className={classnames({
                        'can-drag': this.state.canDrag,
                        'normal': true,
                    })}>
                        <Button type="primary" onClick={this.startDrag}>管理通道</Button>
                        <Button 
                        danger 
                        onClick={this.onSensorInitialMultiClick}
                        loading={this.state.sensorInitialLoading}>
                            {this.state.sensorInitialLoading ? '正在批量操作，请勿离开' : '信号批量归零'}
                        </Button>
                    </Space>
                    <Space size="large" className={classnames({
                        'can-drag': this.state.canDrag,
                        'save': true
                    })}>
                        <Button onClick={this.cancelDrag}>取消</Button>
                        <Button type="primary" onClick={this.onSave} loading={this.state.loading}>保存</Button>
                        <span>tips: 支持拖拽配置传感器通道</span>
                    </Space>
                </div>
                <div className={classnames({
                    'dbox-sensor-pane-group': true,
                    'can-drag': this.state.canDrag,
                })}>
                    <div className="dbox-sensor-pane">
                        <div className="sensor-item-list">
                            {this.state.channelObjs.map((channelObj: DboxChannelObj, idx) => {
                                return (
                                    <div className="dbox-sensor-item" key={channelObj.channel}>
                                        <span className="channel-title">{channelObj.channel}.</span>
                                        {!!channelObj.sensor ?
                                            <Fragment>
                                                <DboxSensor
                                                    channelObj={channelObj}
                                                    canDrag={this.state.canDrag}
                                                    isInitializing={this.state.currentInitialChannel === channelObj.channel}
                                                    changeChannel={this.changeChannel}
                                                    onClick={() => this.onSensorClick(channelObj.sensor)}
                                                />
                                                <CloseCircleOutlined className={classnames({
                                                    'release-icon': true,
                                                    'can-drag': this.state.canDrag,
                                                })} onClick={() => this.releaseChannel(channelObj.sensor)} />
                                            </Fragment>
                                            :
                                            <DboxSensorBlank channelObj={channelObj} setupChannel={this.setupChannel} />
                                        }
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="unlink-sensor-list">
                        <div style={{ height: '100%', backgroundColor: 'white' }}>
                            <div className="title">
                                <IconFont type="icon-jiantouarrowhead7" style={{ marginRight: '4px' }} />
                                <div>未关联传感器列表</div>
                                <div className="fill-remaining-space"></div>
                                <Button icon={<LinkOutlined />} type="link" onClick={this.onMultiLinkChannelClick}>关联</Button>
                            </div>
                            <div className="list-pane">

                                {this.state.noChannelSensors.length <= 0 ?
                                    <Empty description='暂无未关联传感器' />
                                    :
                                    this.state.noChannelSensors.map((item: sensor, idx) => {
                                        return (
                                            <NoChannelSensor
                                                key={item.id}
                                                sensor={item}
                                                canDrag={this.state.canDrag}
                                                globalSensors={this.props.globalDeviceStore._globalSensors}
                                                onCheckboxChange={this.onCheckboxChangeClick}
                                                style={{
                                                    backgroundColor: idx % 2 === 0 ? 'white' : '#f2f5f8'
                                                }} />
                                        )
                                    })}
                            </div>
                        </div>

                    </div>
                </div>

            </DndProvider>

        )
    }
}

export default withRouter(DboxSensorPane);