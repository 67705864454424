import React, { PureComponent, Fragment } from 'react'
import { qDataBase, DataPoint } from '@/interface/quantity'
import { Table } from 'antd';
import Column from 'antd/lib/table/Column';
import dayjs from 'dayjs';
import './data-table.less';
import { inject, observer } from 'mobx-react';
import QuantityDetailStore from '@/store/quantity-detail-store';

interface IProps {
    q_datas: qDataBase[],
    quantityDetailStore?: QuantityDetailStore
}

interface IState {
    dataSet: any[]
}
@inject('quantityDetailStore')
@observer
export default class DataTablePane extends PureComponent<IProps, IState> {
    state = {
        dataSet: [],
    }
    quantityTitleToRender: string = null;

    componentDidMount() {
        this.quantityTitleToRender = this.props.q_datas.map(item => item.id).toString();
        this.generateDataTable();
    }

    componentDidUpdate() {
        let quantityTitleToRender = this.props.q_datas.map(item => item.id).toString();
        let forceRender = this.props.quantityDetailStore._forceTableRender;
        if (this.quantityTitleToRender !== quantityTitleToRender || forceRender) {
            this.quantityTitleToRender = quantityTitleToRender;
            this.props.quantityDetailStore.setForceTableRender(false);
            this.generateDataTable();
        }
    }

    generateDataTable = () => {
        let q_datas = this.props.q_datas || [];
        let timeLineTmp = {};
        q_datas.forEach((q_data: qDataBase, idx) => {
            if (!q_data.data) return;
            let precision = q_data.precision;
            q_data.data.forEach((data: DataPoint) => {
                if (!timeLineTmp[data.time]) {
                    timeLineTmp[data.time] = {};
                }
                timeLineTmp[data.time][`${idx}-${q_data.name}`] = data.value.toFixed(+precision);
            })
        })
        // let dataSet = paramDict2Array(timeLineTmp);

        let dataSet = [];
        for (let key of Object.keys(timeLineTmp)) {
            dataSet.push({ time: key, ...timeLineTmp[key] });
        }
        dataSet = dataSet.sort((a, b) => a.time - b.time);
        this.setState({
            dataSet: dataSet,
        })
    }

    render() {
        let { q_datas } = this.props;
        return (
            <div className="data-table-container">
                <Table
                    scroll={{x: '1400px', y: 'calc(100vh - 220px)'}}
                    dataSource={this.state.dataSet}
                    rowKey='time'
                    pagination={false}
                >
                    <Column 
                    width={190} 
                    title="时间" 
                    dataIndex="time" 
                    fixed="left"
                    render={record => dayjs(+record).format('YYYY-MM-DD HH:mm:ss')} />
                    {q_datas.map((item, idx) => (
                        <Column width={190} key={item.id} title={(
                            <Fragment>
                                {item.mobject_name || ''}
                                {item.chinese_name || ''}
                                {item.location || ''}
                                {item.unit_type && `(${item.unit_type})`}
                            </Fragment>

                        )} dataIndex={`${idx}-${item.name}`} />
                    ))}
                </Table>
            </div>
        )
    }
}
