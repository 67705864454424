import React, { Component } from 'react'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { IFRouteBase } from '@/config/route-list';
import { Gprops } from '@/config/props';
import AllRoutes from '../../routes';
import UserStore from '@/store/user-store';
import { inject, observer } from 'mobx-react';
import { userCheckLogin } from '@/config/api/user';
import { user } from '@/interface/user';
import { UserTypeEnum } from '@/routes/login/login';

interface GRouterProps extends Gprops {
    routeList: IFRouteBase[],
    userStore?: UserStore
}

@inject('userStore')
@observer
class BRouter extends Component<GRouterProps> {

    componentDidMount() {
        userCheckLogin()
        .then(res => {
            let userDetail: user = res.data;
            if (userDetail.type !== UserTypeEnum.ROOT) {
                this.props.userStore.setIsLogin(false);
            }
        }).catch(err => {
            this.props.userStore.setIsLogin(false);
        })
    }

    createRoute = (item: IFRouteBase) => {
        if (item.component) {
            // const MyComponent = item.component && AllRoutes[item.component];
            const MyComponent = AllRoutes[item.component];
            return (
                <Route exact={item.exact} key={item.url} path={`${this.props.match.url}${item.url}`} render={(props) => (
                    <MyComponent {...props}/>
                )}/>
            )
        }
        return null;
    }
    
    render() {
        const { _isLogin } = this.props.userStore;
        if (!_isLogin) {
            return <Redirect to='/passport/login'/>
        }
        return (
            <Switch>
                {this.props.routeList.map((item: IFRouteBase, index: number) => this.createRoute(item))}
                <Redirect to={`${this.props.match.url}/enterprises`}/>
            </Switch>
        )
    }
}

export default withRouter(BRouter);