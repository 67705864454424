import React, { Component } from 'react'
import { withRoutePane } from '@/components/hoc/routePane'
import { Gprops, RProps } from '@/config/props';
import { ProjectContext } from '@/config/context';
import { drawing, constage } from '@/interface/constage';
import { queryDrawings, queryConstages } from '@/config/api/constage';
import { DrawingEdit, DrawingMobjectPanel } from '@/components';
import { Radio, Row, Col } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import IconFont from '@/config/iconfont';
import './drawing-list.less';
import axios from '@/config/axios';
import DrawingFigure from '@/components/drawing/drawing-figure';
import queryString from 'query-string';

interface IProps extends RProps {

}
interface IState {
    drawings: drawing[]
    isEdit: boolean
    currentDrawing: drawing
    onLinkState: boolean
}
class DrawingListPage extends Component<IProps, IState> {
    static contextType = ProjectContext;
    constages: constage[] = [];
    tab_uuid = '';
    state = {
        drawings: [],
        isEdit: false,
        currentDrawing: null,
        onLinkState: false,
    }

    constructor(props) {
        super(props);
        let uuid = this.props.routeStore.addRoutePane('监测施工图', this.props.match.url);
        this.tab_uuid = uuid;
    }

    componentDidMount() {
        let locationSearch = this.props.location.search.substr(1);
        let queryParams = queryString.parse(locationSearch);
        this.generateData(+queryParams.sid);
    }

    generateData = (sid?: number) => {
        let projectId = this.context;
        let queryConstagesPromise = queryConstages(projectId);
        let queryDrawingsPromise = queryDrawings(projectId);
        axios.all([queryConstagesPromise, queryDrawingsPromise])
            .then(res => {
                let constages: constage[] = res[0].data || [];
                let drawings: drawing[] = res[1].data || [];
                drawings.forEach(item => {
                    item.url = item.figure?.url || '';
                    item.name = item.figure?.name || '';
                })
                drawings = drawings.sort((a: drawing, b: drawing) => a.name > b.name ? 1 : -1);
                this.constages = constages;
                let currentDrawing = drawings.length > 0 ? drawings[0] : null;
                if (!!sid) {
                    let d = drawings.find(item => item.id === sid);
                    if (!!d) {
                        currentDrawing = d;
                    }
                }
                this.setState({
                    drawings: drawings,
                    currentDrawing: currentDrawing,
                    isEdit: drawings.length === 0
                })
            })
    }

    onRadioChange = (e) => {
        this.setState({
            isEdit: e.target.value
        })
        if (!e.target.value) {
            let current: drawing = this.state.currentDrawing;
            let id = current?.id || null;
            this.generateData(id);
        }
    }

    goNext = () => {
        let idx = this.state.drawings.findIndex(item => item.id === this.state.currentDrawing.id)
        if (idx < 0) return;
        idx += 1;
        if (idx >= this.state.drawings.length) {
            idx = 0;
        }
        let newData: drawing = this.state.drawings[idx];

        let url = this.props.match.url;
        let search = `?sid=${newData.id}`;
        this.props.routeStore.changeRoutePaneUrl(this.tab_uuid, `${url}${search}`);
        this.props.history.replace({ pathname: url, search: search });

        this.setState({
            currentDrawing: newData,
        })
    }

    goPrevious = () => {
        let idx = this.state.drawings.findIndex(item => item.id === this.state.currentDrawing.id)
        if (idx < 0) return;
        idx -= 1;
        if (idx < 0) {
            idx = this.state.drawings.length - 1;
        }
        let newData = this.state.drawings[idx];

        let url = this.props.match.url;
        let search = `?sid=${newData.id}`;
        this.props.routeStore.changeRoutePaneUrl(this.tab_uuid, `${url}${search}`);
        this.props.history.replace({ pathname: url, search: search });
        
        this.setState({
            currentDrawing: newData,
        })
    }

    render() {
        let { isEdit, currentDrawing, onLinkState } = this.state;
        if (isEdit) {
            return <DrawingEdit onEditChange={this.onRadioChange} />
        }
        let constage = this.constages.find(item => item.id === currentDrawing?.constage_id);
        return (
            <div className="drawing-display-container">
                <Row gutter={10}>
                    <Col span={18}>
                        <div className="drawing-figure">
                            <div className="header">
                                <Radio.Group defaultValue={false} buttonStyle="solid" onChange={this.onRadioChange}>
                                    <Radio.Button value={false}><PictureOutlined /></Radio.Button>
                                    <Radio.Button value={true}><IconFont type="icon-liebiao" /></Radio.Button>
                                </Radio.Group>
                                <div className="title">
                                    {`${constage?.code || ''} ${constage?.name || ''}`}
                                    <span>{currentDrawing?.name}</span>
                                </div>
                            </div>
                            <DrawingFigure 
                            onLinkState={onLinkState}
                            drawingDetail={currentDrawing}
                            goNext={this.goNext}
                            goPrevious={this.goPrevious}/>
                        </div>
                    </Col>
                    <Col span={6}>
                        <DrawingMobjectPanel 
                        onLinkState={onLinkState}
                        beginLink={() => this.setState({onLinkState: true})}
                        endLink={() => this.setState({onLinkState: false})}
                        currentDrawing={currentDrawing}/>
                    </Col>
                </Row>


            </div>
        )
    }
}

export default withRoutePane(DrawingListPage, '监测施工图');