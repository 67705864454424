import React, { Component } from 'react'
import { Button, Table, Modal, message, Space } from 'antd'
import { ProjectContext } from '@/config/context';
import { procedure, globalProcedure, globalStandard } from '@/interface/procedure';
import { queryProcedures, deleteProcedure, updateProcedureDetail, createProcedures } from '@/config/api/standard';
import axios from '@/config/axios';
import Column from 'antd/lib/table/Column';
import { ProcedureType } from '@/utils/type';
import './procedure-table.less';
import { EditableRow, EditableCell } from '..';
import UserStore from '@/store/user-store';
import { queryGlobalProcedures, queryEnterprisesProcedures, queryGlobalStandards, queryEnterprisesStandards } from '@/config/api/global-standard';
import { SourceType } from '../model/section-add';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ProcedureEdit from './procedure-edit';
import { inject, observer } from 'mobx-react';
import StandardStore from '@/store/standard-store';
interface IProps {
    userStore?: UserStore,
    standardStore?: StandardStore
}
interface IState {
    procedures: procedure[]
    globalProcedures: globalProcedure[]
    globalStandards: globalStandard[]
    currentGlobalCodes: string[]
    currentGlobalItems: globalStandard[]

    onAddState: boolean
    onEditState: boolean
    onDeleteState: boolean
    onAddSaveLoading: boolean
    loading: boolean

    procedureIdToDelete: number[]
    deleteLoading: boolean
}

@inject('standardStore')
@observer
export default class ProcedureTable extends Component<IProps, IState>{
    static contextType = ProjectContext;
    state = {
        procedures: [],
        globalProcedures: [],
        globalStandards: [],
        currentGlobalCodes: [],
        currentGlobalItems: [],

        onAddState: false,
        onEditState: false,
        onDeleteState: false,
        onAddSaveLoading: false,
        loading: false,

        procedureIdToDelete: [],
        deleteLoading: false,
    }

    componentDidMount() {
        this.findProcedures();
        this.findGlobalProcedures();
    }

    findProcedures = () => {
        const projectId = this.context;
        this.setState({
            loading: true,
        })
        queryProcedures(projectId)
            .then(res => {
                let procedures: procedure[] = res.data;
                this.setState({
                    procedures: procedures,
                    loading: false,
                })
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false,
                })
            })

    }

    findGlobalProcedures = () => {
        // let procedurePromise = queryProcedures(this.context);
        let globalProcedurePromise = queryGlobalProcedures();
        let enterprisePromiseProcedures = queryEnterprisesProcedures(this.context);
        let globalStandardsPromise = queryGlobalStandards();
        let enterpriseStandardsPromise = queryEnterprisesStandards(this.context);
        axios.all([globalProcedurePromise, enterprisePromiseProcedures, globalStandardsPromise, enterpriseStandardsPromise])
            .then((res) => {
                // let procedures: procedure[] = res[0].data;
                let gProcedures: globalProcedure[] = res[0].data;
                let eProcedures: globalProcedure[] = res[1].data;
                let gStandards: globalStandard[] = res[2].data;
                let eStandards: globalStandard[] = res[3].data;
                gStandards = gStandards.map(item => Object.assign(item, { source: SourceType.DB, key: `g${item.id}` }));
                eStandards = eStandards.map(item => Object.assign(item, { source: SourceType.CUSTOM, key: `e${item.id}` }));
                let globalProcedures: globalProcedure[] = [...gProcedures, ...eProcedures];
                let globalStandardes: globalStandard[] = [...gStandards, ...eStandards];
                this.setState({
                    globalProcedures: globalProcedures,
                    globalStandards: globalStandardes,
                })
            })
    }

    onAddSave = (values) => {
        const projectId = this.context;
        this.setState({
            onAddSaveLoading: true
        })
        createProcedures(projectId, values)
            .then(res => {
                this.setState({
                    onAddSaveLoading: false,
                    onAddState: false,
                })
                this.props.standardStore.setForceRender(true);
                this.findProcedures();
            }).finally(() => {
                this.setState({
                    onAddSaveLoading: false
                })
            })
    }

    handleSave = (row: procedure) => {
        let id = row.id;
        let newData = [...this.state.procedures];
        let index = newData.findIndex(item => item.id === id);
        let item: procedure = newData[index];
        newData.splice(index, 1, { ...item, ...row })
        this.setState({
            procedures: [...newData]
        })
        let data = {
            content: row.content,
            standard_name: row.standard_name,
            standard_code: row.standard_code
        }
        updateProcedureDetail(this.context, id, data)
            .then(res => {
                this.props.standardStore.setForceRender(true);
            }).catch(err => {
                message.error('更新失败');
                newData.splice(index, 1, { ...row, ...item })
                this.setState({
                    procedures: [...newData]
                })
            })
    }

    onDoubleClick = (record) => {
        let global_codes = Array.from(new Set(
            this.state.globalStandards.filter(item => item.name === record.standard_name).map(item => item.code)
        ));
        let globalItem_numbers = this.state.globalStandards
            .filter(item => item.name === record.standard_name && item.code === record.standard_code);
        this.setState({
            currentGlobalCodes: global_codes,
            currentGlobalItems: globalItem_numbers,
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            procedureIdToDelete: [...selectedRowKeys]
        })
    }

    onDelete = () => {
        this.setState({
            deleteLoading: true,
        })
        const projectId = this.context
        let deletePromiseList = this.state.procedureIdToDelete.map(id => deleteProcedure(projectId, id));
        axios.all([...deletePromiseList])
            .then(res => {
                this.findProcedures();
                this.setState({
                    procedureIdToDelete: [],
                    onDeleteState: false,
                    deleteLoading: false,
                })
                this.props.standardStore.setForceRender(true);
            })
    }

    render() {
        // let procedureTypeOptions = paramDict2Array(ProcedureType)?.map((item, idx) => (
        //     { value: +item.name, label: item.value }
        // )) || [];
        // let procedureOptions = this.state.globalProcedures?.map(item => (
        //     { value: item.content, label: item.content }
        // )) || [];
        let procedureOptionsStandard_name = Array.from(new Set(this.state.globalStandards?.map(item => item.name))).map(item => (
            { value: item, label: item }
        )) || [];
        let currentGlobalCodes = this.state.currentGlobalCodes?.map((item, idx) => (
            { value: item, label: item }
        )) || [];
        let currentGlobalItems = this.state.currentGlobalItems?.map((item: globalStandard, idx) => (
            { value: item.item_number, label: `${item.item_number}${item.content}` }
        ))

        let { onAddState, onAddSaveLoading, onEditState, onDeleteState, deleteLoading, procedureIdToDelete } = this.state;
        // console.log(procedureOptionsStandard_name)
        // let standard_code = this.state.procedures?.map((item, idx) => ({value: item.standard_code, label: item.standard_code})) || [];
        return (
            <div className="standard-card">
                <div className="header">
                    <div className="title">工作规范约束</div>
                    <div className="extra">
                        {/* <Button type="link" onClick={this.onEdit} icon={<EditOutlined />}>编辑</Button> */}
                        {!(onEditState || onDeleteState) && (
                            <Space size="middle">
                                <Button type="primary" icon={<PlusOutlined />} onClick={() => this.setState({ onAddState: true })}></Button>
                                <Button icon={<EditOutlined />} onClick={() => this.setState({ onEditState: true })}></Button>
                                <Button danger icon={<DeleteOutlined />} onClick={() => this.setState({ onDeleteState: true })}></Button>
                            </Space>
                        )}
                        {onEditState && (
                            <Button type="primary" onClick={() => this.setState({ onEditState: false })}>完成</Button>
                        )}
                        {onDeleteState && (
                            <Space size="middle">
                                <Button type="primary" onClick={this.onDelete} loading={deleteLoading}>确定</Button>
                                <Button onClick={() => this.setState({ onDeleteState: false })}>取消</Button>
                            </Space>
                        )}
                    </div>
                </div>
                <div className="procedure-content" >
                    <Table
                        dataSource={this.state.procedures}
                        pagination={false}
                        rowKey="id"
                        loading={this.state.loading}
                        components={{
                            body: {
                                row: EditableRow,
                                cell: EditableCell,
                            }
                        }}
                        rowClassName={(record, index) => (
                            'editable-row'
                        )}
                        rowSelection={onDeleteState && {
                            type: 'checkbox',
                            onChange: this.onSelectChange,
                            selectedRowKeys: procedureIdToDelete
                        }}>
                        <Column
                            title="技术点类别"
                            dataIndex="type"
                            width="10%"
                            render={(record) => (ProcedureType[record])} />

                        <Column
                            title="技术点内容"
                            ellipsis={true}
                            key="content"
                            width="20%"
                            render={(record: globalProcedure) => (record.content)} />
                        <Column
                            title="规范标准"
                            ellipsis={true}
                            key="standard_name"
                            width="40%"
                            onCell={(record: procedure, idx) => ({
                                record,
                                editable: onEditState,
                                type: 'Select',
                                options: procedureOptionsStandard_name,
                                dataIndex: 'standard_name',
                                title: '规范标准',
                                handleSave: this.handleSave,
                                // onChange: () => this.onDoubleClick(record),
                                // onDoubleClick: () => this.onStandardcode(record),
                            })}
                            render={(record: procedure) => (record.standard_name)}
                        />
                        <Column
                            title="规范编号"
                            ellipsis={true}
                            key="standard_code"
                            width="15%"
                            onCell={(record: globalStandard, idx) => ({
                                record,
                                editable: onEditState,
                                type: 'Select',
                                options: currentGlobalCodes,
                                dataIndex: 'standard_code',
                                title: '规范编号',
                                handleSave: this.handleSave,
                                onDoubleClick: () => this.onDoubleClick(record),
                            })}
                            render={(record: procedure) => (record.standard_code)}
                        />
                        {/* <Column title="规范编号" key="device_status" render={(record: procedure) => (record.standard_code)} /> */}
                        <Column
                            title="条文号"
                            ellipsis={true}
                            key="standard_item_number"
                            width="15%"
                            onCell={(record: globalStandard, idx) => ({
                                record,
                                editable: onEditState,
                                type: 'Select',
                                options: currentGlobalItems,
                                dataIndex: 'standard_item_number',
                                title: '条文号',
                                handleSave: this.handleSave,
                                onDoubleClick: () => this.onDoubleClick(record),
                            })}
                            render={(record: procedure) => (record.standard_item_number)}
                        />
                    </Table>
                </div>
                <Modal
                    title="添加规范约束"
                    visible={onAddState}
                    destroyOnClose
                    footer={null}
                    onCancel={() => this.setState({ onAddState: false })}>
                    <ProcedureEdit
                        saveLoading={onAddSaveLoading}
                        onSave={this.onAddSave}
                        onCancel={() => this.setState({ onAddState: false })} />
                </Modal>
            </div>
        )
    }
}
