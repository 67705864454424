import axios from "../axios"
import { AxiosResponse } from "axios"
import qs from 'qs';

export function querySiteConditions(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/projects/${projectId}/site-conditions`, {
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'comma'})
        }
    })
}

export function createSiteConditions(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/projects/${projectId}/site-conditions`, data, {
        params: params
    })
}

export function deleteSiteCondition(projectId: number, conditionId: number,): Promise<AxiosResponse<any>> {
    return axios.delete(`/projects/${projectId}/site-conditions/${conditionId}`)
}

// export function querySiteConditionsTags(params?: {}): Promise<AxiosResponse<any>> {
//     return axios.get(`/site-conditions/tags`, {
//         params: params
//     })
// }

