import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import MobjectStore from '@/store/mobject-store'
import { queryGlobalQuantityGroups } from '@/config/api/global-mobject'
import { globalQuantityGroup, quantityGroup, mobject } from '@/interface/mobject'
import { MobjectBaseType } from '@/utils/type'
import { queryQuantityGroups, queryMobjectDetail, createQuantityGroup, deleteQuantityGroup } from '@/config/api/mobject'
import { ProjectContext } from '@/config/context'
import axios from '@/config/axios'
import { queryMaterialBySid } from '@/config/api/model'
import { material } from '@/interface/model'
import { Checkbox, Button, message } from 'antd'
import { mobjectPicStaticLocation } from '@/config/api/document'

export const MobjectTypeEnum = {
    JOINT: 1,
    MEMBER: 2,
    STRUCTURE: 3,
    ENVIRONMENT: 4,
}

export const QuantityLevelEnum = {
    DQ: 3,
    POINT: 4,
    CROSS_SECTION: 5,
    ELEMENT: 6,
    STRUCTURE: 7,
    ENVIRONMENT: 8,
}

// TODO: 等指标group的type及关联确定后修改
export const QuantityGroupTypes = {
    POINT_STRESS_STL: 401,
    FORCE_1_STL: 501,
    FORCE_2_STL: 502,
    FORCE_3_STL: 503,
    MEMBER_STRESS_STL: 601,
}

export const QuantityGroupTypeLinks = [
    {
        type: QuantityGroupTypes.MEMBER_STRESS_STL,
        changedLevel: QuantityLevelEnum.ELEMENT,
        link: QuantityGroupTypes.FORCE_1_STL,
        targetLevel: QuantityLevelEnum.CROSS_SECTION,
    },
    {
        type: QuantityGroupTypes.MEMBER_STRESS_STL,
        changedLevel: QuantityLevelEnum.ELEMENT,
        link: QuantityGroupTypes.POINT_STRESS_STL,
        targetLevel: QuantityLevelEnum.POINT,
    },
    {
        type: QuantityGroupTypes.FORCE_1_STL,
        changedLevel: QuantityLevelEnum.CROSS_SECTION,
        link: QuantityGroupTypes.POINT_STRESS_STL,
        targetLevel: QuantityLevelEnum.POINT,
    },
    {
        type: QuantityGroupTypes.FORCE_2_STL,
        changedLevel: QuantityLevelEnum.CROSS_SECTION,
        link: QuantityGroupTypes.POINT_STRESS_STL,
        targetLevel: QuantityLevelEnum.POINT,
    },
    {
        type: QuantityGroupTypes.FORCE_3_STL,
        changedLevel: QuantityLevelEnum.CROSS_SECTION,
        link: QuantityGroupTypes.POINT_STRESS_STL,
        targetLevel: QuantityLevelEnum.POINT,
    },
    // {
    //     type: QuantityGroupTypes.MEMBER_STRESS_STL,
    //     links: [
    //         QuantityGroupTypes.FORCE_1_STL,
    //         QuantityGroupTypes.POINT_STRESS_STL
    //     ],
    //     forceLink: true,
    // },
    // {
    //     type: QuantityGroupTypes.FORCE_1_STL, 
    //     links: [
    //         QuantityGroupTypes.POINT_STRESS_STL
    //     ],
    //     forceLink: true,
    // },
    // {
    //     type: QuantityGroupTypes.FORCE_2_STL, 
    //     links: [
    //         QuantityGroupTypes.POINT_STRESS_STL
    //     ],
    //     forceLink: true,
    // },
    // {
    //     type: QuantityGroupTypes.FORCE_3_STL, 
    //     links: [
    //         QuantityGroupTypes.POINT_STRESS_STL
    //     ],
    //     forceLink: true,
    // },
    // {
    //     type: QuantityGroupTypes.POINT_STRESS_STL,
    //     links: [
    //         QuantityGroupTypes.FORCE_1_STL,
    //         QuantityGroupTypes.FORCE_2_STL,
    //         QuantityGroupTypes.FORCE_3_STL,
    //     ],
    //     forceLink: false,
    // }
]

interface IProps {
    mobjectStore?: MobjectStore
    onSave: () => void,
}

interface IState {
    globalQuantityGroups: globalQuantityGroup[]
    quantityGroups: quantityGroup[]
    selectedQuantityTypes: { [key: number]: number[] }
}
@inject('mobjectStore')
@observer
export default class QuantityGroupSetting extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        globalQuantityGroups: [],
        quantityGroups: [],
        selectedQuantityTypes: {}
    }
    // selectedQuantityTypes: { [key: number]: number[] } = {};
    previousMobjectId: number = 0;

    componentDidMount() {
        this.generateData();
    }

    componentDidUpdate() {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        if (this.previousMobjectId !== mobjectId) {
            this.setState({
                globalQuantityGroups: [],
                quantityGroups: [],
            })
            this.generateData();
        }
    }

    generateData = () => {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        this.previousMobjectId = mobjectId;
        let mobjectObj;
        queryMobjectDetail(mobjectId)
            .then(res => {
                let mobjectDetail: mobject = res.data;
                // mobjectDetail.id = mobjectId; // TODO: 开发阶段模拟数据采用
                mobjectObj = mobjectDetail;
                // this.props.mobjectStore.setMojectDetail(mobjectDetail);
                return mobjectDetail;
            }).then((mobjectDetail: mobject) => {

                if (!!mobjectDetail.material_sid) {
                    this.findMaterialsToGenerateGroups(mobjectDetail);
                } else {
                    this.generateQGroups(mobjectDetail, null)
                }
            })
        // return queryMaterialBySid(mobjectDetail.material_sid, this.context)
        // }).then(res => {
        // let mobjectDetail = this.props.mobjectStore._mobjectDetail;
        //     let mobjectDetail = mobjectObj;
        //     let materialDetail: material = res.data;
        //     let params = {
        //         mobject_type: mobjectDetail.type,
        //         mobject_subtype: mobjectDetail.sub_type,
        //         material_type: materialDetail.type
        //     };
        //     let globalQuantityGroupPromise = queryGlobalQuantityGroups(params);

        //     let quantityGroupPromise = queryQuantityGroups(mobjectId, {
        //         project_id: this.context,
        //         mobject_id: mobjectId,
        //     });
        //     return axios.all([globalQuantityGroupPromise, quantityGroupPromise]);
        // }).then(res => {
        //     this.setState({
        //         globalQuantityGroups: res[0].data,
        //         quantityGroups: res[1].data,
        //     })
        //     // 设置默认选中的groups
        //     let defaultValues = Array.from(new Set(this.state.quantityGroups.map((item: quantityGroup) => item.type)));
        //     defaultValues.forEach(item => {
        //         let key = Math.round(item/100);
        //         if (!this.state.selectedQuantityTypes[key]) {
        //             this.state.selectedQuantityTypes[key] = [];
        //         }
        //         this.state.selectedQuantityTypes[key].push(item);
        //     })
        //     this.setState({
        //         selectedQuantityTypes: {...this.state.selectedQuantityTypes}
        //     })
        // })
    }

    findMaterialsToGenerateGroups = (mobjectDetail: mobject) => {
        queryMaterialBySid(mobjectDetail.material_sid, this.context)
            .then(res => {
                let materialDetail: material = res.data;
                this.generateQGroups(mobjectDetail, materialDetail)
            })
    }

    generateQGroups = (mobjectObj: mobject, materialDetail: material) => {
        let mobjectId = mobjectObj?.id;
        let mobjectDetail = mobjectObj;
        let params = {
            mobject_type: mobjectDetail.type,
            mobject_subtype: mobjectDetail.sub_type,
            material_type: materialDetail?.type || null,
        };
        let globalQuantityGroupPromise = queryGlobalQuantityGroups(params);
        let quantityGroupPromise = queryQuantityGroups(mobjectId, {
            project_id: this.context,
            mobject_id: mobjectId,
        });
        axios.all([globalQuantityGroupPromise, quantityGroupPromise])
            .then(res => {
                this.setState({
                    globalQuantityGroups: res[0].data,
                    quantityGroups: res[1].data,
                })
                // 设置默认选中的groups
                let defaultValues = Array.from(new Set(this.state.quantityGroups.map((item: quantityGroup) => item.type)));
                defaultValues.forEach(item => {
                    let key = Math.round(item / 100);
                    if (!this.state.selectedQuantityTypes[key]) {
                        this.state.selectedQuantityTypes[key] = [];
                    }
                    this.state.selectedQuantityTypes[key].push(item);
                })
                this.setState({
                    selectedQuantityTypes: { ...this.state.selectedQuantityTypes }
                })
            })

    }

    onChange = (checkedValues: number[], idx) => {
        let selectedQuantityTypes = { ...this.state.selectedQuantityTypes };
        selectedQuantityTypes[idx] = checkedValues;
        // 将下级指标强制关联
        let forceLinks = QuantityGroupTypeLinks.filter(item => item.changedLevel === idx);
        forceLinks.forEach(item => {
            if (checkedValues.some(x => x === item.type)) {
                const targetIdx = item.targetLevel;
                let values = selectedQuantityTypes[targetIdx] || [];
                const targetLink = item.link;
                selectedQuantityTypes[targetIdx] = Array.from(new Set([...values, targetLink]));
            }
        })
        this.setState({
            selectedQuantityTypes: { ...selectedQuantityTypes }
        })
    }

    onSaveAndNext = () => {
        let mobjectDetail = this.props.mobjectStore._mobjectDetail;
        if (Object.keys(this.state.selectedQuantityTypes).length === 0 && this.state.quantityGroups.length !== 0) {
            // 表示没有更新,直接进入下一步
            this.props.onSave();

        } else if (Object.keys(this.state.selectedQuantityTypes).length === 0 && this.state.quantityGroups.length === 0) {
            // 表示没有勾选，提示
            message.error('请至少选择一个指标！');
            return;
        } else {
            // 对比出增加的和减少的group
            let types: number[] = [];

            for (let key of Object.keys(this.state.selectedQuantityTypes)) {
                types = types.concat(this.state.selectedQuantityTypes[key]);
            }
            // 获取当前勾选的types, 去重
            types = Array.from(new Set(types));
            // 初始值
            let defaultValues = Array.from(new Set(this.state.quantityGroups.map((item: quantityGroup) => item.type)));
            // 交集
            let mixes = types.filter(x => defaultValues.some(y => y === x));
            // 计算新增的types types-mixes
            let addTypes = types.filter(x => !mixes.some(y => y === x));
            // 计算将要删除的types  default-mixes
            let cutTypes = defaultValues.filter(x => !mixes.some(y => y === x));

            // 创建请求
            let createGroupPromiseList = [];
            addTypes.forEach(value => {
                let data = this.state.globalQuantityGroups.find(item => item.type === value);
                data = Object.assign(data, { project_id: this.context, id: null });
                console.log(data);
                let promise = createQuantityGroup(mobjectDetail.id, data);
                createGroupPromiseList.push(promise);
            })
            let deleteGroupPromiseList = [];
            cutTypes.forEach(value => {
                let data = this.state.quantityGroups.filter(item => item.type === value)[0];
                let promise = deleteQuantityGroup(mobjectDetail.id, data?.id);
                deleteGroupPromiseList.push(promise);
            })
            axios.all([...createGroupPromiseList, ...deleteGroupPromiseList])
                .then(res => {
                    return queryQuantityGroups(mobjectDetail.id, {
                        project_id: this.context,
                    });
                }).then(res => {
                    this.setState({
                        quantityGroups: res.data,
                    })
                    this.props.onSave();
                })
        }
    }

    render() {
        let mobjectDetail = this.props.mobjectStore._mobjectDetail;
        if (this.previousMobjectId !== mobjectDetail?.id) return null;

        if (!mobjectDetail) return null;
        // let figureSrc = mobjectDetail?.figure_url
        // if (figureSrc === '' || !figureSrc) {
        //     figureSrc = MobjectBaseType[mobjectDetail.type]['figureUrl'];
        // }
        let figureSrc = `${mobjectPicStaticLocation}${mobjectDetail?.sub_type}.png`
        if (mobjectDetail.type !== MobjectTypeEnum.MEMBER) {
            figureSrc = null;
        }
        let gElementGroups = this.state.globalQuantityGroups
            .filter((item: globalQuantityGroup) => item.level === QuantityLevelEnum.ELEMENT)
            .map((item: globalQuantityGroup) => ({
                label: item.name,
                value: item.type,
            }));
        let gStructureGroups = this.state.globalQuantityGroups
            .filter((item: globalQuantityGroup) => item.level === QuantityLevelEnum.STRUCTURE)
            .map((item: globalQuantityGroup) => ({
                label: item.name,
                value: item.type,
            }));
        let gCrossSectionGroups = this.state.globalQuantityGroups
            .filter((item: globalQuantityGroup) => item.level === QuantityLevelEnum.CROSS_SECTION)
            .map((item: globalQuantityGroup) => ({
                label: item.name,
                value: item.type,
            }));
        let gPointGroups = this.state.globalQuantityGroups
            .filter((item: globalQuantityGroup) => item.level === QuantityLevelEnum.POINT)
            .map((item: globalQuantityGroup) => ({
                label: item.name,
                value: item.type,
            }));
        let gEnvironmentGroups = this.state.globalQuantityGroups
            .filter((item: globalQuantityGroup) => item.level === QuantityLevelEnum.ENVIRONMENT)
            .map((item: globalQuantityGroup) => ({
                label: item.name,
                value: item.type,
            }));

        let defaultValues = Array.from(new Set(this.state.quantityGroups.map((item: quantityGroup) => item.type)));
        return (
            <Fragment>
                <div className="quantity-step-item step-one">
                    <div className="mobject-figure">
                        {figureSrc && (
                            <img alt="对象示意图" width={320} height={240} src={figureSrc} />
                        )}
                    </div>
                    <div className="quantity-group-content">
                        {
                            mobjectDetail.type === MobjectTypeEnum.ENVIRONMENT &&
                            (
                                <div>
                                    <div className="title">环境指标</div>
                                    <div className="group-list">
                                        <Checkbox.Group
                                            options={gEnvironmentGroups}
                                            defaultValue={defaultValues}
                                            value={this.state.selectedQuantityTypes[QuantityLevelEnum.ENVIRONMENT]}
                                            onChange={(e: any) => this.onChange(e, QuantityLevelEnum.ENVIRONMENT)} />
                                    </div>
                                </div>
                            )
                        }
                        {
                            mobjectDetail.type === MobjectTypeEnum.STRUCTURE &&
                            (
                                <div>
                                    <div className="title">结构指标</div>
                                    <div className="group-list">
                                        <Checkbox.Group
                                            options={gStructureGroups}
                                            defaultValue={defaultValues}
                                            value={this.state.selectedQuantityTypes[QuantityLevelEnum.STRUCTURE]}
                                            onChange={(e: any) => this.onChange(e, QuantityLevelEnum.STRUCTURE)} />
                                    </div>
                                </div>
                            )
                        }
                        {
                            mobjectDetail.type === MobjectTypeEnum.JOINT &&
                            (
                                <div>
                                    <div className="title">节点指标</div>
                                    <div className="group-list">
                                        <Checkbox.Group
                                            options={gElementGroups}
                                            defaultValue={defaultValues}
                                            value={this.state.selectedQuantityTypes[QuantityLevelEnum.ELEMENT]}
                                            onChange={(e: any) => this.onChange(e, QuantityLevelEnum.ELEMENT)} />
                                    </div>
                                </div>
                            )
                        }
                        {
                            mobjectDetail.type === MobjectTypeEnum.MEMBER &&
                            (
                                <Fragment>
                                    <div>
                                        <div className="title">构件指标</div>
                                        <div className="group-list">
                                            <Checkbox.Group
                                                options={gElementGroups}
                                                defaultValue={defaultValues}
                                                value={this.state.selectedQuantityTypes[QuantityLevelEnum.ELEMENT]}
                                                onChange={(e: any) => this.onChange(e, QuantityLevelEnum.ELEMENT)} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="title">截面指标</div>
                                        <div className="group-list">
                                            <Checkbox.Group
                                                options={gCrossSectionGroups}
                                                defaultValue={defaultValues}
                                                value={this.state.selectedQuantityTypes[QuantityLevelEnum.CROSS_SECTION]}
                                                onChange={(e: any) => this.onChange(e, QuantityLevelEnum.CROSS_SECTION)} />
                                        </div>
                                    </div>
                                </Fragment>


                            )
                        }
                        <div>
                            <div className="title">点指标</div>
                            <div className="group-list">
                                <Checkbox.Group
                                    options={gPointGroups}
                                    defaultValue={defaultValues}
                                    value={this.state.selectedQuantityTypes[QuantityLevelEnum.POINT]}
                                    onChange={(e: any) => this.onChange(e, QuantityLevelEnum.POINT)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="steps-action">
                    <Button type="primary" onClick={this.onSaveAndNext}>保存并下一步</Button>
                </div>
            </Fragment>

        )
    }
}
