import React, { Component } from 'react'
import { withRoutePane } from '@/components/hoc/routePane'
import './quantity-query.less';
import { Tabs, Row, Col, Tree, Button, Modal, Checkbox, Space, Tooltip, Spin, Select, Dropdown, Menu, Input, message } from 'antd';
import { queryMobjects } from '@/config/api/mobject';
import { ProjectContext } from '@/config/context';
import { querySensors, querySensorQuantities } from '@/config/api/sensor';
import axios from '@/config/axios';
import { mobject } from '@/interface/mobject';
import { sensor, sensorQuantity } from '@/interface/sensor';
import { quantity, globalQuantity, qDataBase, DataPoint, multiDataPoint } from '@/interface/quantity';
import { queryQuantities } from '@/config/api/quantity';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import { Transfer2DisplayScale, handleQuantityName } from '@/utils/utils';
import { QuantityName } from '@/components/QuantityName';
import { FilterOutlined, SyncOutlined, DownOutlined } from '@ant-design/icons';
import { InlineMath } from 'react-katex';
import { queryConstages } from '@/config/api/constage';
import { constage } from '@/interface/constage';
import { inject, observer } from 'mobx-react';
import QuantityDetailStore from '@/store/quantity-detail-store';
import QuantityDataTool from '@/components/mobject/quantity-data-tool';
import { queryQuantityData, querySensorQuantityData, downloadQuantityDataCsv, downloadSensorQuantityDataCsv } from '@/config/api/quantity-data';
import dayjs from 'dayjs';
import LineChartBoard from '@/components/charts/line-chart-board';
import { QuantityLevelTypes } from '@/components/mobject/quantity-pattern-setting';
import MultiLineChart from '@/components/charts/multi-line-chart';
import DataTablePane from '@/components/charts/data-table';
const { TabPane } = Tabs;
const { Option } = Select;
const { Search } = Input;

export declare type QueryDisplayStyle = 'single' | 'multi' | 'table';

interface IProps {
    quantityDetailStore?: QuantityDetailStore
}
interface IState {
    mobjects: mobject[]
    quantities: quantity[]
    sensors: sensor[]
    sensorQuantities: sensorQuantity[]
    constages: constage[]

    quantityTreeData: any[]
    sensorQuantityTreeData: any[]
    checkedQuantityTreeKeys: string[] // 选中的指标物理量
    checkedSensorQuantityTreeKeys: string[] // 选中的传感器物理量

    expendedQuantityKeys: string[] // 展开的指标树
    expendedSQKeys: string[] // 展开的传感器树
    treeLoading: boolean

    createdQuantityTypes: globalQuantity[] // 弹窗中展示的指标类别
    selectModalVisible: boolean
    selectedQuantityTypes: number[] // 弹窗中选中的指标类别

    quantityDataList: qDataBase[]
    queryLoading: boolean

    displayStyle: QueryDisplayStyle

    multiDataList: multiDataPoint[]

    qSearchValue: string
    sqSearchValue: string

    exportLoading: boolean,
    autoFlushData: boolean, // 后台自动刷新数据
}

@inject('quantityDetailStore')
@observer
class QuantityQueryPage extends Component<IProps, IState> {
    static contextType = ProjectContext;
    projectQuantities: globalQuantity[] = [];
    timer: any;
    isAutoDataLoading = false;
    state = {
        mobjects: [],
        quantities: [],
        sensors: [],
        sensorQuantities: [],
        constages: [],

        quantityTreeData: [],
        sensorQuantityTreeData: [],
        checkedQuantityTreeKeys: [],
        checkedSensorQuantityTreeKeys: [],
        expendedQuantityKeys: [],
        expendedSQKeys: [],
        treeLoading: false,

        createdQuantityTypes: [],
        selectModalVisible: false,
        selectedQuantityTypes: [],

        quantityDataList: [],
        queryLoading: false,

        displayStyle: 'single' as QueryDisplayStyle,

        multiDataList: [],

        qSearchValue: '',
        sqSearchValue: '',

        exportLoading: false,
        autoFlushData: false,
    }

    componentDidMount() {
        this.generateData();
        this.findConstages();

        this.timer = setInterval(
            () => {
                if (!this.state.autoFlushData) return;
                if (this.isAutoDataLoading) return;
                this.isAutoDataLoading = true;
                this.generateQuantityData(false);
            },
            1000 * 15,
        );
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    generateData = () => {
        this.setState({
            treeLoading: true,
        })
        let projectId = this.context;
        let queryMobjectPromise = queryMobjects({
            project_id: projectId
        });
        let querySensorPromise = querySensors({
            project_id: projectId,
        });
        let queryProjectQuantityPromise = queryProjectGlobalQuantities(projectId);
        let queryQuantityPromise = queryQuantities(projectId, {
            level: QuantityLevelTypes.OTHER_Q,
            activated: true,
        });
        let querySensorQuantityPromise = querySensorQuantities(projectId);
        axios.all([
            queryMobjectPromise,
            querySensorPromise,
            queryProjectQuantityPromise,
            queryQuantityPromise,
            querySensorQuantityPromise])
            .then(res => {
                let mobjects = res[0].data || [];
                let sensors = res[1].data || [];
                this.projectQuantities = res[2].data || [];
                let quantities: quantity[] = res[3].data || [];
                let sensorQuantities: sensorQuantity[] = res[4].data || [];
                quantities = quantities.sort((a, b) => b.qt_type - a.qt_type);
                quantities.forEach(item => {
                    let pQuantity = this.projectQuantities.find(p => p.type === item.type);
                    if (pQuantity) {
                        item.chinese_name = pQuantity.chinese_name;
                        item.unit_type = pQuantity.unit_type;
                        item.precision = pQuantity.precision;
                        let scale = Transfer2DisplayScale(item.unit_type);
                        item.scale = scale;
                    }
                });
                sensorQuantities.forEach(item => {
                    let pQuantity = this.projectQuantities.find(p => p.type === item.type);
                    if (pQuantity) {
                        item.chinese_name = pQuantity.chinese_name;
                        item.unit_type = pQuantity.unit_type;
                        item.precision = pQuantity.precision;
                        let scale = Transfer2DisplayScale(item.unit_type);
                        item.scale = scale;
                    }
                })
                this.setState({
                    mobjects: mobjects,
                    sensors: sensors,
                    quantities: quantities,
                    sensorQuantities: sensorQuantities,
                    treeLoading: false,
                })
                this.generateQuantityTypes();
                this.generateQuantityTree();
                this.generateSensorQuantityTree();
            })
    }

    findConstages = () => {
        let projectId = this.context;
        queryConstages(projectId)
            .then(res => {
                let constages = res.data || [];
                this.setState({
                    constages: constages
                })
            })
    }

    // 生成类别选择弹框中的类别列表
    generateQuantityTypes = () => {
        let qTypes = Array.from(new Set(this.state.quantities.map(q => q.type)));
        let sqTypes = Array.from(new Set(this.state.sensorQuantities.map(sq => sq.type)));
        let types = [...qTypes, ...sqTypes];
        let pQuantities = this.projectQuantities
            .filter(p => types.some(t => t === p.type))
            .sort((a, b) => (b.level > a.level) || (b.qt_type > a.qt_type) ? 1 : -1);
        this.setState({
            createdQuantityTypes: pQuantities,
        })
    }

    // 生成指标选择树
    generateQuantityTree = () => {
        let tree = this.state.mobjects.map((m: mobject) => {
            let children = this.state.quantities
                .filter((q: quantity) => q.mobject_id === m.id)
                .map((q: quantity) => {
                    q.mobject_name = m.name;
                    q.mobject_id = m.id;
                    q.mobject_type = m.type;
                    return {
                        title: <QuantityName record={q} />,
                        key: `q_${q.id}`,
                        q: q,
                        isLeaf: true,
                    }
                }) || [];
            return {
                title: m.name,
                key: `m_${m.id}`,
                children: children,
            }
        })
        tree = tree.sort((a,b) => a.title > b.title ? 1 : -1);
        this.setState({
            quantityTreeData: tree,
        })
    }

    // 生成传感器物理量选择树
    generateSensorQuantityTree = () => {
        let tree = this.state.sensors.map((s: sensor) => {
            let children = this.state.sensorQuantities
                .filter((sq: sensorQuantity) => sq.sensor_id === s.id)
                .map((sq: sensorQuantity) => {
                    sq.mobject_name = s.name;
                    return {
                        title: <QuantityName record={sq} />,
                        key: `sq_${sq.id}`,
                        q: sq,
                        isLeaf: true,
                    }
                }) || [];
            return {
                title: s.name,
                key: `s_${s.id}`,
                children: children,
            }
        })
        this.setState({
            sensorQuantityTreeData: tree,
        })
    }

    // 类别选择弹框中进行选择时
    onModalSelectedQuantityTypesChange = (values) => {
        this.setState({
            selectedQuantityTypes: values
        })
    }

    // 选择树选中节点时
    onSelectNodeCheck = (checkedKeys, tag: string) => {
        if (tag === 'q') {
            this.setState({
                checkedQuantityTreeKeys: checkedKeys
            })
        }
        if (tag === 'sq') {
            this.setState({
                checkedSensorQuantityTreeKeys: checkedKeys
            })
        }
    }

    // 弹窗选择类别确认
    onModalSelectedQuantityTypesConfirm = () => {
        let selectedQuantityTypes: number[] = this.state.selectedQuantityTypes;
        let checkedQuantityTreeKeys: string[] = this.state.checkedQuantityTreeKeys // 选中的指标物理量
        let checkedSensorQuantityTreeKeys: string[] = this.state.checkedSensorQuantityTreeKeys // 选中的传感器物理量

        // 指标物理量取并集
        let tmp1 = this.state.quantities.filter(x => selectedQuantityTypes.some(y => x.type === y)).map(q => `q_${q.id}`);
        checkedQuantityTreeKeys = Array.from(new Set(checkedQuantityTreeKeys.concat(tmp1)));
        // 传感器物理量keys取并集
        let tmp2 = this.state.sensorQuantities.filter(x => selectedQuantityTypes.some(y => x.type === y)).map(q => `sq_${q.id}`);
        checkedSensorQuantityTreeKeys = Array.from(new Set(checkedSensorQuantityTreeKeys.concat(tmp2)));
        this.setState({
            checkedQuantityTreeKeys: checkedQuantityTreeKeys,
            checkedSensorQuantityTreeKeys: checkedSensorQuantityTreeKeys,
            selectedQuantityTypes: [],
            selectModalVisible: false,
        })
    }

    onQueryParamsChange = () => {
        // this.generateQuantityAndData(this.state.quantities);
        this.generateQuantityData();
    }

    generateQuantityData = (loading: boolean=true) => {
        this.setState({
            queryLoading: loading,
        })
        let params = {
            start_time: this.props.quantityDetailStore._startTime?.getTime() || null,
            end_time: this.props.quantityDetailStore._endTime?.getTime() || null,
            sampling_period: this.props.quantityDetailStore._samplingPeriod || null,
            data_type: this.props.quantityDetailStore._dataType || 1,
        }
        if (params.start_time == null) {
            params['limit'] = 300;
        }
        else if (params.sampling_period == null) {
            let end = params.end_time;
            if (end == null) {
                end = new Date().getTime();
            }
            let start = params.start_time;
            // TODO: 计算降采样
            params.sampling_period = this.calculateDownSample(start, end);
            this.props.quantityDetailStore.setSamplingPeriod(params.sampling_period);
        }
        if (params.sampling_period === -1) {
            params.sampling_period = null;
        }
        let quantityDataPromiseList = this.state.checkedQuantityTreeKeys
            .filter(key => key.indexOf('q_') >= 0)
            .map(key => {
                let id = +key.replace('q_', '');
                return queryQuantityData(id, params)
            }) || [];
        let sensorDataPromiseList = this.state.checkedSensorQuantityTreeKeys
            .filter(key => key.indexOf('sq_') >= 0)
            .map(key => {
                let id = +key.replace('sq_', '');
                return querySensorQuantityData(id, params)
            }) || [];
        let result: qDataBase[] = [];
        axios.all([...quantityDataPromiseList])
            .then(res => {
                let data: qDataBase[] = res.map(item => item.data).filter((item: qDataBase) => !!item.id) || [];
                data.forEach((qData: qDataBase, idx) => {
                    let q = this.state.quantities.find(item => item.id === qData.id);
                    if (!q) {
                        return;
                    }
                    if (!!qData.data) {
                        qData.data = qData.data?.map(item => {
                            item.value *= q.scale
                            return item;
                        }) || [];
                    }
                    qData.max = qData.max ? qData.max * q.scale : null;
                    qData.min = qData.min ? qData.min * q.scale : null;
                    qData.mean = qData.mean ? qData.mean * q.scale : null;
                    qData.precision = q.precision;
                    qData.unit_type = q.unit_type;
                    qData.chinese_name = q.chinese_name;
                    qData.mobject_name = q.mobject_name;
                    qData.mobject_id = q.mobject_id;
                    qData.mobject_type = q.mobject_type;
                    qData.isSensor = false;

                    qData.warning_limit = q.warning_limit;
                    qData.is_evaluate = q.is_evaluate;
                    qData.scale = q.scale;
                })
                result = result.concat(data);
                return axios.all([...sensorDataPromiseList]);
            }).then(res => {
                let data: qDataBase[] = res.map(item => item.data).filter((item: qDataBase) => !!item.id) || [];
                data.forEach((qData: qDataBase, idx) => {
                    let q = this.state.sensorQuantities.find(item => item.id === qData.id);
                    if (!q) {
                        return;
                    }
                    if (!!qData.data) {
                        qData.data = qData.data?.map(item => {
                            item.value *= q.scale
                            return item;
                        }) || [];
                    }
                    qData.max = qData.max ? qData.max * q.scale : null;
                    qData.min = qData.min ? qData.min * q.scale : null;
                    qData.mean = qData.mean ? qData.mean * q.scale : null;
                    qData.precision = q.precision;
                    qData.unit_type = q.unit_type;
                    qData.chinese_name = q.chinese_name;
                    qData.mobject_name = q.mobject_name;
                    qData.isSensor = true;
                })
                result = result.concat(data);
                this.setState({
                    quantityDataList: result,
                    queryLoading: false,
                })
                this.generateMultiDatas();
                if (this.state.displayStyle === 'table') {
                    this.props.quantityDetailStore.setForceTableRender(true);
                }
            }).finally(() => {
                this.setState({
                    queryLoading: false,
                })
                this.isAutoDataLoading = false;
            })
    }

    calculateDownSample = (start: number, end: number) => {
        const date1 = dayjs(start);
        const date2 = dayjs(end);
        if (date2.diff(date1, 'M') >= 2) {
            return 86400;
        }
        if (date2.diff(date1, 'M') >= 1) {
            return 43200;
        }
        if (date2.diff(date1, 'd') >= 10) {
            return 3600;
        }
        if (date2.diff(date1, 'd') >= 1) {
            return 300;
        }
        return null;
    }

    generateMultiDatas = () => {
        let multiDatas = [];
        this.state.quantityDataList.forEach((item: qDataBase, idx) => {
            let data: DataPoint[] = item.data || [];
            data.forEach((point) => {
                let tmp = {
                    time: point.time,
                    value: point.value,
                    name: `${item.mobject_name}-${item.name}-${item.location || ''}`,
                }
                multiDatas.push(tmp);
            })
        })
        this.setState({
            multiDataList: multiDatas,
        })
    }

    afterDataDelete = () => {
        this.generateQuantityData();
    }

    onQuantityExpand = (expandedKeys) => {
        this.setState({
            expendedQuantityKeys: expandedKeys
        })
    }

    onSensorQuantityExpand = (expandedKeys) => {
        this.setState({
            expendedSQKeys: expandedKeys
        })
    }

    onQChange = e => {
        const { value } = e.target;
        this.setState({
            qSearchValue: value,
        });
    };

    onSQChange = e => {
        const { value } = e.target;
        this.setState({
            sqSearchValue: value,
        });
    };

    exportQuantityDataAll = () => {
        const projectId = this.context;
        this.setState({
            exportLoading: true,
        })

        let params = {
            project_id: projectId,
            start_time: this.props.quantityDetailStore._startTime?.getTime() || null,
            end_time: this.props.quantityDetailStore._endTime?.getTime() || null,
            sampling_period: this.props.quantityDetailStore._samplingPeriod || null,
            data_type: this.props.quantityDetailStore._dataType || 1,
        }
        downloadQuantityDataCsv(params).then(res => {
            const blob = new Blob([res.data]);
            const link = document.createElement('a');
            let contentDisposition = res.headers['content-disposition'];  
            let filename = /filename="(.*)"/.exec(contentDisposition)[1] || '指标数据.csv';
            link.setAttribute('href', window.URL.createObjectURL(blob));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(err => {
            console.log(err);
            message.error('导出失败');
        }).finally(() => {
            this.setState({
                exportLoading: false,
            })
        })
    }

    exportSensorQuantityDataAll = () => {
        const projectId = this.context;
        this.setState({
            exportLoading: true,
        })

        let params = {
            project_id: projectId,
            start_time: this.props.quantityDetailStore._startTime?.getTime() || null,
            end_time: this.props.quantityDetailStore._endTime?.getTime() || null,
            sampling_period: this.props.quantityDetailStore._samplingPeriod || null,
            data_type: this.props.quantityDetailStore._dataType || 1,
        }
        downloadSensorQuantityDataCsv(params).then(res => {
            const blob = new Blob([res.data]);
            const link = document.createElement('a');
            let contentDisposition = res.headers['content-disposition'];  
            let filename = /filename="(.*)"/.exec(contentDisposition)[1] || '传感器数据.csv';
            link.setAttribute('href', window.URL.createObjectURL(blob));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(err => {
            console.log(err);
            message.error('导出失败');
        }).finally(() => {
            this.setState({
                exportLoading: false,
            })
        })
    }

    exportCurrentSelectedQuantityData = () => {
        // let qDatas: qDataBase[] = this.state.quantityDataList.filter((q: qDataBase) => !q.isSensor) || [];
        let qids = this.state.checkedQuantityTreeKeys
            .filter(key => key.indexOf('q_') >= 0)
            .map(key => {
                let id = +key.replace('q_', '');
                return id;
            }) || [];
        if (qids.length === 0) {
            message.info('请选择至少一条数据');
            return;
        }
        const projectId = this.context;
        this.setState({
            exportLoading: true,
        })

        let params = {
            project_id: projectId,
            start_time: this.props.quantityDetailStore._startTime?.getTime() || null,
            end_time: this.props.quantityDetailStore._endTime?.getTime() || null,
            sampling_period: this.props.quantityDetailStore._samplingPeriod || null,
            data_type: this.props.quantityDetailStore._dataType || 1,
            qids: qids,
        }
        downloadQuantityDataCsv(params).then(res => {
            const blob = new Blob([res.data]);
            const link = document.createElement('a');
            let contentDisposition = res.headers['content-disposition'];  
            let filename = /filename="(.*)"/.exec(contentDisposition)[1] || '指标数据.csv';
            link.setAttribute('href', window.URL.createObjectURL(blob));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(err => {
            console.log(err);
            message.error('导出失败');
        }).finally(() => {
            this.setState({
                exportLoading: false,
            })
        })
    }

    exportCurrentSelectedSensorQuantityData = () => {
        // let qDatas: qDataBase[] = this.state.quantityDataList.filter((q: qDataBase) => !!q.isSensor) || [];
        let qids = this.state.checkedSensorQuantityTreeKeys
        .filter(key => key.indexOf('sq_') >= 0)
        .map(key => {
            let id = +key.replace('sq_', '');
            return id;
        }) || [];
        if (qids.length === 0) {
            message.info('请选择至少一条数据');
            return;
        }
        const projectId = this.context;
        this.setState({
            exportLoading: true,
        })

        let params = {
            project_id: projectId,
            start_time: this.props.quantityDetailStore._startTime?.getTime() || null,
            end_time: this.props.quantityDetailStore._endTime?.getTime() || null,
            sampling_period: this.props.quantityDetailStore._samplingPeriod || null,
            data_type: this.props.quantityDetailStore._dataType || 1,
            qids: qids,
        }
        downloadSensorQuantityDataCsv(params).then(res => {
            const blob = new Blob([res.data]);
            const link = document.createElement('a');
            let contentDisposition = res.headers['content-disposition'];  
            let filename = /filename="(.*)"/.exec(contentDisposition)[1] || '传感器数据.csv';
            link.setAttribute('href', window.URL.createObjectURL(blob));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(err => {
            console.log(err);
            message.error('导出失败');
        }).finally(() => {
            this.setState({
                exportLoading: false,
            })
        })
    }

    render() {
        let {
            quantityTreeData,
            sensorQuantityTreeData,
            selectModalVisible,
            checkedQuantityTreeKeys,
            qSearchValue,
            sqSearchValue,
            checkedSensorQuantityTreeKeys } = this.state;

        const menu = (
            <Menu>
                <Menu.Item key="1" onClick={this.exportCurrentSelectedQuantityData}>
                    导出当前选择-指标
                </Menu.Item>
                <Menu.Item key="2" onClick={this.exportCurrentSelectedSensorQuantityData}>
                    导出当前选择-传感器
                </Menu.Item>
                <Menu.Item key="3" onClick={this.exportQuantityDataAll}>
                    导出全部-指标
                </Menu.Item>
                <Menu.Item key="4" onClick={this.exportSensorQuantityDataAll}>
                    导出全部-传感器
                </Menu.Item>
            </Menu>
        );

        const qLoop = data => data.filter(item => item.title.indexOf(qSearchValue) >= 0);

        const sqLoop = data => data.filter(item => item.title.indexOf(sqSearchValue) >= 0);

        return (
            <div className="quantity-query-container">
                <div className="query-select">
                    <Spin spinning={this.state.treeLoading}>
                        <Tabs
                            defaultActiveKey="1"
                            size="small"
                            tabBarExtraContent={
                                <Dropdown overlay={(
                                    <Menu className="quantity-query-filter-menu">
                                        <Menu.Item onClick={() => this.setState({ selectModalVisible: true })}>筛选</Menu.Item>
                                        <Menu.Item onClick={() => this.setState({
                                            checkedQuantityTreeKeys: [],
                                            checkedSensorQuantityTreeKeys: [],
                                        })}>清空</Menu.Item>
                                    </Menu>
                                )}>
                                    <Button type="link" icon={<FilterOutlined />} ></Button>
                                </Dropdown>
                            }>
                            <TabPane tab="对象" key="1">
                                <Search style={{ marginBottom: 8 }} size="small" value={qSearchValue} placeholder="搜索单元" onChange={this.onQChange} />
                                <Tree
                                    checkable
                                    selectable={false}
                                    treeData={qLoop(quantityTreeData)}
                                    onExpand={this.onQuantityExpand}
                                    expandedKeys={this.state.expendedQuantityKeys}
                                    checkedKeys={checkedQuantityTreeKeys}
                                    onCheck={(checkedKeys) => this.onSelectNodeCheck(checkedKeys, 'q')}
                                ></Tree>
                            </TabPane>
                            <TabPane tab="传感器" key="2">
                                <Search style={{ marginBottom: 8 }} size="small" value={sqSearchValue} placeholder="搜索传感器" onChange={this.onSQChange} />
                                <Tree
                                    checkable
                                    selectable={false}
                                    treeData={sqLoop(sensorQuantityTreeData)}
                                    onExpand={this.onSensorQuantityExpand}
                                    expandedKeys={this.state.expendedSQKeys}
                                    checkedKeys={checkedSensorQuantityTreeKeys}
                                    onCheck={(checkedKeys) => this.onSelectNodeCheck(checkedKeys, 'sq')}
                                ></Tree>
                            </TabPane>
                        </Tabs>
                    </Spin>

                </div>
                <div className="query-main fill-remaining-space">
                    <div className="header-operations">
                        <div className="operation-item">
                            <Space>
                                <Tooltip title="更新数据">
                                    <Button
                                        shape="circle"
                                        type="primary"
                                        icon={<SyncOutlined spin={this.state.queryLoading} />}
                                        onClick={() => this.generateQuantityData()}></Button>
                                </Tooltip>
                                <Select value={this.state.displayStyle} style={{ width: 120 }} onChange={(e) => this.setState({ displayStyle: e })}>
                                    <Option value="single">平铺</Option>
                                    <Option value="multi">同轴</Option>
                                    <Option value="table">表格</Option>
                                </Select>
                                <Dropdown overlay={menu}>
                                    <Button>
                                        数据导出<DownOutlined />
                                    </Button>
                                </Dropdown>
                                <Checkbox checked={this.state.autoFlushData} onChange={() => { this.setState({ autoFlushData: !this.state.autoFlushData }) }}>后台刷新</Checkbox>
                            </Space>
                        </div>
                        <div className="fill-remaining-space"></div>
                        <div className="operation-item">
                            <QuantityDataTool constages={this.state.constages} onQueryParamsChange={this.onQueryParamsChange} />
                        </div>
                    </div>
                    <div className="data-content">
                        <Spin spinning={this.state.queryLoading}>
                            {this.state.displayStyle === 'single' && (
                                this.state.quantityDataList.map((q: qDataBase) => (
                                    <div key={q.id}>
                                        <LineChartBoard
                                            q={q}
                                            constages={this.state.constages}
                                            afterDelete={this.afterDataDelete} isSensor={q.isSensor} />
                                    </div>
                                ))
                            )}
                            {this.state.displayStyle === 'multi' && this.state.multiDataList.length > 0 && (
                                <MultiLineChart q_data={this.state.multiDataList} precision={1} />
                            )}
                            {this.state.displayStyle === 'table' && (
                                <DataTablePane q_datas={this.state.quantityDataList} />
                            )}
                        </Spin>
                    </div>
                </div>

                <Modal
                    title="选择指标类别"
                    footer={null}
                    closable
                    onCancel={() => this.setState({ selectModalVisible: false })}
                    visible={selectModalVisible}>
                    <Checkbox.Group
                        value={this.state.selectedQuantityTypes}
                        onChange={this.onModalSelectedQuantityTypesChange}>
                        <Row>
                            {this.state.createdQuantityTypes.map((item: globalQuantity, idx) => (
                                <Col span={12} key={idx} style={{ marginBottom: '12px' }}>
                                    <Checkbox value={item.type}>
                                        {item.chinese_name}
                                        <InlineMath math={handleQuantityName(item.name)} />
                                    </Checkbox>
                                </Col>
                            ))}
                        </Row>
                    </Checkbox.Group>
                    <div style={{ textAlign: 'right', marginTop: '10px' }}>
                        <Space size="large">
                            <Button type="primary" onClick={this.onModalSelectedQuantityTypesConfirm}>确定</Button>
                            <Button onClick={() => this.setState({
                                selectModalVisible: false,
                                selectedQuantityTypes: []
                            })}>取消</Button>
                        </Space>
                    </div>
                </Modal>

                <Modal
                    visible={this.state.exportLoading}
                    footer={null}
                    title={null}
                    closable={false}
                    >
                    <Spin size="large" tip="正在下载数据...">
                        <div style={{height: '100px'}}></div>
                    </Spin>
                </Modal>
            </div>
        )
    }
}

export default withRoutePane(QuantityQueryPage, '指标查询');