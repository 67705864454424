import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { RProps } from '@/config/props';
import './section-detail.less';
import { section } from '@/interface/model';
import { querySectionBySid, updateSection } from '@/config/api/model';
import { ProjectContext } from '@/config/context';
import { SectionPanel } from '@/components';

interface IProps extends RProps {
}

interface IState {
    sectionDetail: section
    saveLoading: boolean
    isEditing: boolean
}

@inject('routeStore')
@observer
export default class SectionDetail extends Component<IProps, IState> {
    static contextType = ProjectContext;
    sid: number;
    state = {
        sectionDetail: null,
        saveLoading: false,
        isEditing: false,
    }

    constructor(props) {
        super(props);
        let id = this.props.match.params['sid'];
        this.props.routeStore.addRoutePane(`截面${id}`, this.props.match.url);
        this.sid = id;
        // this.generateData(id);
    }

    componentDidMount() {
        this.findSection();
    }

    findSection = () => {
        querySectionBySid(this.context, this.sid)
        .then(res => {
            this.setState({
                sectionDetail: res.data
            })
        })
    }

    componentDidUpdate() {
        let id = this.props.match.params['sid'];
        if (this.sid !== id) {
            this.sid = id;
            this.findSection();
        }
    }

    onSectionSave = (values) => {
        // console.log(values)
        this.setState({
            saveLoading: true,
        })
        let id = this.state.sectionDetail?.id;
        if (!id) {
            return;
        }
        updateSection(this.context, id, values)
        .then(res => {
            this.setState({
                saveLoading: false,
                isEditing: false,
            })
        })
    }

    render() {
        return (
            <div className="section-detail-container">
                <SectionPanel 
                sectionDetail={this.state.sectionDetail} 
                onSave={this.onSectionSave} 
                onCancel={() => this.setState({isEditing: false})}
                onEdit={() => this.setState({isEditing: true})}
                saveLoading={this.state.saveLoading}
                isEditing={this.state.isEditing}/>

            </div>
        )
    }
}
