import React, { Component } from 'react'
import { drawing, constage } from '@/interface/constage'
import { Table, Button, Modal, Space, Popconfirm, message, Upload, Radio } from 'antd'
import { queryDrawings, queryConstages, deleteDrawing, updateDrawing, createDrawings } from '@/config/api/constage';
import { ProjectContext } from '@/config/context';
import { InboxOutlined, PictureOutlined } from '@ant-design/icons';
import axios from '@/config/axios';
import './drawing-edit.less';
import { EditableRow, EditableCell } from '..';
import IconFont from '@/config/iconfont';
import { figureUploadUrl, figureStaticLocation } from '@/config/api/document';
const { Column } = Table;
const { Dragger } = Upload;

interface IProps {
    onEditChange?: (e) => void
}
interface IState {
    drawings: drawing[],
    constages: constage[],
    previewVisible: boolean
    previewImage: string
    onMultiDeleteState: boolean
    onUploadState: boolean
    drawingIdToDelete: number[]
    multiDeleteLoading: boolean
    createDrawingLoading: boolean
    fileList: any[]
}
export default class DrawingEdit extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        drawings: [],
        constages: [],
        previewVisible: false,
        previewImage: '',
        onMultiDeleteState: false,
        onUploadState: false,
        drawingIdToDelete: [],
        multiDeleteLoading: false,
        createDrawingLoading: false,
        fileList: []
    }
    componentDidMount() {
        this.generateData();
    }
    generateData = () => {
        let projectId = this.context;
        let queryConstagesPromise = queryConstages(projectId);
        let queryDrawingsPromise = queryDrawings(projectId);
        axios.all([queryConstagesPromise, queryDrawingsPromise])
            .then(res => {
                let constages: constage[] = res[0].data || [];
                let drawings: drawing[] = res[1].data || [];
                drawings.forEach(item => {
                    item.url = item.figure?.url || '';
                    item.name = item.figure?.name || '';
                })
                drawings = drawings.sort((a: drawing, b: drawing) => a.name > b.name ? 1 : -1);
                this.setState({
                    constages: constages,
                    drawings: drawings,
                })
            })
    }

    handleCancel = () => this.setState({ previewVisible: false });


    showModal = (e, url) => {
        e.stopPropagation();
        this.setState({
            previewVisible: true,
            previewImage: `${figureStaticLocation}${url}`,
        });
    };

    onSelectChange = (selectedRowKeys, selectedRows) => {
        // console.log(selectedRowKeys)
        this.setState({
            drawingIdToDelete: [...selectedRowKeys]
        })
    }

    onMultiDeleteCancel = () => {
        this.setState({
            drawingIdToDelete: [],
            onMultiDeleteState: false,
        })
    }

    onMultiDeleteConfirm = () => {
        this.setState({
            multiDeleteLoading: true,
        })
        let deletePromiseList = this.state.drawingIdToDelete.map(id => {
            // let drawing: drawing = this.state.drawings.find(item => item.id === id);
            return deleteDrawing(this.context, id);
        })
        axios.all([...deletePromiseList])
            .then(res => {
                this.setState({
                    drawingIdToDelete: [],
                    onMultiDeleteState: false,
                })
                this.generateData();
            }).finally(() => {
                this.setState({
                    multiDeleteLoading: false,
                })
            })
    }

    handleDelete = (values) => {
        //TODO
        deleteDrawing(this.context, values.id)
            .then(res => {
                this.setState({
                    drawings: this.state.drawings.filter(item => item.id !== values.id)
                })
            }).catch(err => {
                message.error('删除失败')
            })
    }

    generateConstageOptions = (record: drawing) => {
        let map = this.state.constages.map(item => (
            { value: item.id, label: `${item.code} ${item.name}` }
        ))
        return map
    }

    handleSave = (row: drawing) => {
        let id = row.id;
        let newData = [...this.state.drawings];
        let index = newData.findIndex(item => item.id === id);
        let item: drawing = newData[index];

        newData.splice(index, 1, { ...item, ...row })
        this.setState({
            drawings: [...newData]
        })
        let data = {
            constage_id: row.constage_id,
        }
        // TODO
        updateDrawing(this.context, id, data)
            .then(res => {

            }).catch(err => {
                message.error('更新失败');
                newData.splice(index, 1, {
                    ...row,
                    ...item,
                })
                this.setState({
                    drawings: [...newData]
                })
            })
    }

    onFileChange = (info) => {
        const { status } = info.file;
        if (status !== 'uploading') {
            // console.log(info.file, info.fileList);
            this.setState({
                fileList: [...this.state.fileList, info.file]
            })
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handleUploadingRemove = (file) => {
        this.setState({
            fileList: this.state.fileList.filter(item => item.uid !== file.uid)
        })
        return true;
    }


    createDrawings = () => {
        this.setState({
            createDrawingLoading: true,
        })
        let figures = this.state.fileList
        .filter(item => item.response)
        .map(item => item.response.data);
        if (figures.length === 0) {
            this.setState({
                createDrawingLoading: false,
                fileList: [],
                onUploadState: false,
            })
            return;
        }
        createDrawings(this.context, figures)
            .then(res => {
                this.setState({
                    createDrawingLoading: false,
                    fileList: [],
                    onUploadState: false,
                })
                this.generateData();
            }).catch(err => {
                console.log(err);
                message.error('保存失败');
                this.setState({
                    createDrawingLoading: false,
                })
            })
    }

    onUploadingCancel = () => {
        if (this.state.createDrawingLoading) return;
        this.setState({
            fileList: [],
            onUploadState: false,
        })
    }

    onRadioChange = (e) => {
        this.props.onEditChange(e);
    }

    render() {
        let { drawings, onMultiDeleteState, onUploadState } = this.state;
        return (
            <div className="drawing-edit-container">
                <div className="header">
                    {drawings?.length > 0 && (
                        <Radio.Group defaultValue={true} buttonStyle="solid" onChange={this.onRadioChange}>
                            <Radio.Button value={false}><PictureOutlined /></Radio.Button>
                            <Radio.Button value={true}><IconFont type="icon-liebiao" /></Radio.Button>
                        </Radio.Group>
                    )}
                    <div className="fill-remaining-space"></div>
                    {!onMultiDeleteState && !onUploadState && (
                        <Space>
                            <Button onClick={() => { this.setState({ onUploadState: true }) }}>上传</Button>
                            <Button danger onClick={() => { this.setState({ onMultiDeleteState: true }) }}>批量删除</Button>
                        </Space>
                    )}
                    {onMultiDeleteState && (
                        <Space size="large" style={{ marginLeft: '24px' }}>
                            <Button type="primary" onClick={this.onMultiDeleteConfirm} loading={this.state.multiDeleteLoading}>确定</Button>
                            <Button onClick={this.onMultiDeleteCancel}>取消</Button>
                        </Space>
                    )}
                    {onUploadState && (
                        <Space size="large" style={{ marginLeft: '24px' }}>
                            <Button type="primary" onClick={this.createDrawings} loading={this.state.createDrawingLoading}>完成</Button>
                            <Button onClick={this.onUploadingCancel}>取消</Button>
                        </Space>
                    )}
                </div>
                {onUploadState && (
                    <div className="upload-file">
                        <Dragger
                            name="file"
                            multiple={true}
                            action={figureUploadUrl}
                            accept="image/*"
                            onChange={this.onFileChange}
                            onRemove={this.handleUploadingRemove}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">点击或拖拽图片格式文件到此区域以上传</p>
                            <p className="ant-upload-hint">
                                支持一次上传多个文件，为保证展示效果，请使用横版A4比例图片
                            </p>
                        </Dragger>
                    </div>
                )}
                <Table
                    dataSource={drawings}
                    pagination={false}
                    rowSelection={this.state.onMultiDeleteState && {
                        type: 'checkbox',
                        onChange: this.onSelectChange,
                        selectedRowKeys: this.state.drawingIdToDelete
                    }}
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        }
                    }}
                    rowKey="id">
                    <Column title="名称" dataIndex="name" />
                    <Column
                        title="所属施工步"
                        key="constage_id"
                        width="25%"
                        onCell={(record: drawing, idx) => ({
                            record,
                            editable: true,
                            dataIndex: 'constage_id',
                            type: 'Select',
                            options: this.generateConstageOptions(record),
                            title: '所属施工步',
                            handleSave: this.handleSave,
                        })}
                        render={record => {
                            let constage = this.state.constages.find(item => item.id === record.constage_id);
                            return `${constage?.code || ''} ${constage?.name || ''}`;
                        }} />
                    <Column title="链接" dataIndex="url" align="center" render={record => (
                        <Button type="link" size="small" onClick={(e) => this.showModal(e, record)}>预览</Button>
                    )} />
                    <Column title="" key="operation" align="center" render={record => (
                        <Popconfirm title="确认删除" onConfirm={() => this.handleDelete(record)}>
                            <Button type="link" size="small" disabled={this.state.onMultiDeleteState}>删除</Button>
                        </Popconfirm>
                    )} />
                </Table>

                <Modal
                    title="图片详览"
                    width={800}
                    footer={null}
                    visible={this.state.previewVisible}
                    style={{ top: '20px' }}
                    //   onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <img width="100%" alt="照片" src={this.state.previewImage} />
                </Modal>
            </div>
        )
    }
}
