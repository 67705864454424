import React, { CSSProperties } from 'react'
import { Input } from 'antd';

interface IProps {
    onChange?: (value) => void,
    value?: string,
    onBlur?: () => void,
    size?: 'small' | 'middle' | 'large' | undefined,
    defaultValue?: string | number,
    disabled?: boolean
    style?: CSSProperties
    className?: string
    readOnly?: boolean
    suffix?: any
}

export const NumericInput: React.FC<IProps> = ({
    onChange,
    value,
    onBlur,
    defaultValue,
    style,
    className,
    readOnly,
    ...restProps
}) => {

    const onNumChange = e => {
        const value = e.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            onChange(value);
        }
    };

    const onNumBlur = () => {
        let valueTemp = `${value}`;
        if (valueTemp?.charAt(valueTemp.length - 1) === '.' || valueTemp === '-') {
            valueTemp = value.slice(0, -1);
        }
        valueTemp = valueTemp?.replace(/0*(\d+)/, '$1')
        onChange(Number(valueTemp));
        if (onBlur) {
            onBlur();
        }
    };

    return (
        <Input className="number-input"
            {...restProps}
            onChange={onNumChange}
            onBlur={onNumBlur}
            value={value}
            readOnly={readOnly}
            maxLength={25}
        />
    )
};

// export default class NumericInput extends Component<IProps> {

//     onNumChange = e => {
//         const value = e.target.value;
//         const reg = /^-?\d*(\.\d*)?$/;
//         if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
//             this.props.onChange(value);
//         }
//     };

//     onNumBlur = () => {
//         let valueTemp = `${this.props.value}`;
//         if (valueTemp?.charAt(valueTemp.length - 1) === '.' || valueTemp === '-') {
//             valueTemp = this.props.value.slice(0, -1);
//         }
//         valueTemp = valueTemp?.replace(/0*(\d+)/, '$1')
//         this.props.onChange(Number(valueTemp));
//         if (this.props.onBlur) {
//             this.props.onBlur();
//         }
//         if (this.props.onValueBlur) {
//             this.props.onValueBlur(valueTemp)
//         }
//     };

//     render() {
//         return (
//             <Input className="number-input"
//             {...this.props}
//             onChange={this.onNumChange}
//             onBlur={this.onNumBlur}
//             value={this.props.value}
//             readOnly={this.props.readOnly}
//             maxLength={25}
//         />
//         )
//     }
// }
