import React, { Component, Fragment } from 'react'
import { globalQuantity } from '@/interface/quantity'
import { queryGlobalQuantities } from '@/config/api/global-quantity'
import { Table } from 'antd'
import './global-quantities.less';
import { EditableRow, EditableCell } from '@/components';
import { UnitMap } from '@/utils/unit-type';
import { paramDict2Array, handleQuantityName, Transfer2DisplayScale } from '@/utils/utils';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import { InlineMath } from 'react-katex';
import { QuantityLevelAlias } from '@/utils/type';
const { Column } = Table;
interface IProps {

}
interface IState {
    gQuantites: globalQuantity[]
}
export default class GlobalQuantityPage extends Component<IProps, IState> {

    state = {
        gQuantites: []
    }

    componentDidMount() {
        this.findGlobalQuantities()
    }

    findGlobalQuantities = () => {
        queryGlobalQuantities()
            .then(res => {
                let gQuantites = res.data.map((item, idx) =>
                    Object.assign(item, {
                        id: idx,
                        t2: item.warning_limit_params['t2'],
                        t3: item.warning_limit_params['t3'],
                        t4: item.warning_limit_params['t4'],
                        t5: item.warning_limit_params['t5'],
                    })
                )
                gQuantites = gQuantites.sort((a, b) => b.level - a.level)
                this.setState({
                    gQuantites: gQuantites
                })
            })
    }

    generateUnitOptions = (record: globalQuantity) => {
        let map = paramDict2Array(UnitMap[record.unit_type]).map(item => (
            { value: item.value, label: item.name }
        ))
        return map
    }

    handleLimitFunctionName = (name: string) => {
        if (!name) {
            return name;
        }
        let tmp = name.split(/\//);
        let lowers: string = '';
        for (let idx=0;idx<tmp.length;idx++) {
            let res = handleQuantityName(tmp[idx]);
            if (idx > 0) {
                lowers +='/';
            }
            lowers += `{${res}}`
        }
        return lowers
    }

    render() {
        return (
            <div className="global-quantity-container">
                <Table
                    dataSource={this.state.gQuantites}
                    rowKey="id"
                    bordered
                    pagination={false}
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        }
                    }}
                    rowClassName={(record, index) => (
                        'editable-row'
                    )}
                >
                    <Column title="代号" dataIndex="name" render={record => (
                        <InlineMath math={handleQuantityName(record.replace('_mq', ''))} />
                    )} />
                    <Column title="名称" dataIndex="chinese_name" align="center" />
                    <Column title="层级" dataIndex="level" align="center" render={record => QuantityLevelAlias[record]} />

                    <Column title="显示单位" dataIndex="unit_type" width="10%" />
                    <Column title="评估阈值公式" dataIndex="evaluate_formula" render={(record) => (
                        record ? <InlineMath math={this.handleLimitFunctionName(record)}/> : null
                    )} />
                    <ColumnGroup title={
                        <Fragment>
                            <span>评估阈值参数</span>
                            <InlineMath math='\alpha' />
                        </Fragment>
                    }>
                        <Column title="二级" dataIndex="t2" width="10%" />
                        <Column title="三级" dataIndex="t3" width="10%" />
                        <Column title="四级" dataIndex="t4" width="10%" />
                        <Column title="五级" dataIndex="t5" width="10%" />
                    </ColumnGroup>
                    <Column
                        title="显示精度"
                        dataIndex="precision"
                        width="10%"
                        align="right"
                        render={(record, idx) => {
                            let tmp = Math.pow(10, record * -1);
                            return <div>{tmp.toFixed(record)}</div>
                        }} />
                    <Column
                        title="有效限值"
                        key="valid_data"
                        align="right"
                        render={(record, idx) => {
                            let scale = Transfer2DisplayScale(record.unit_type);
                            let vd = record.valid_data != null ? record.valid_data * scale : null;
                            let precision = Math.pow(10, record.precision * -1);
                            if (vd != null) return vd.toFixed(precision);
                            return null;
                        }}/>
                </Table>
            </div>
        )
    }
}
