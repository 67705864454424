import React, { Component } from 'react'
import { Space, Button, Radio, Input, Modal, message } from 'antd'
import IconFont from '@/config/iconfont'
import './project-list.less';
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';
import appStore, { PROJECT_DISPLAY_STYLE } from '@/store/app-store';
import ProjectListPanel from '@/components/project-list/project-list-panel';
import ProjectCardPanel from '@/components/project-list/project-card-panel';
import { project } from '@/interface/project';
import { queryProjects } from '@/config/api/project';
import ProjectAdd from '@/components/project-list/project-add';
import { UserTypeEnum } from '../login/login';
const { Search } = Input;
export declare type ProjectDisplayType = 'Card' | 'List';

interface IProps {
    userStore?: UserStore
}
interface IState {
    projectList: project[]
    displayProjectList: project[]
    searchValue: string
    projectAddModalVisible: boolean
}
@inject('userStore')
@observer
export default class ProjectListPage extends Component<IProps, IState> {

    state = {
        displayStyle: 'List' as ProjectDisplayType,
        projectList: [],
        displayProjectList: [],
        searchValue: null,
        projectAddModalVisible: false,
    }
    enterpriseId: number;
    userId: number;

    constructor(props) {
        super(props);
        let displayStyle = appStore.get(PROJECT_DISPLAY_STYLE);
        this.props.userStore.setProjectListDisplay(displayStyle || 'List')

    }

    componentDidMount() {
        this.enterpriseId = this.props.userStore._enterpriseId;
        this.userId = this.props.userStore._userInfo?.id;
        this.findProjects();
    }

    componentDidUpdate() {
        let eid = this.props.userStore._enterpriseId;
        let uid = this.props.userStore._userInfo?.id;
        if (this.enterpriseId !== eid || uid !== this.userId) {
            this.enterpriseId = eid;
            this.userId = uid;
            this.findProjects();
        }
    }

    findProjects = (search_name?: string) => {
        if (!this.enterpriseId) {
            return;
        }
        if (!this.userId) {
            return;
        }
        let eid = this.props.userStore._enterpriseId;
        let userId = this.props.userStore._userInfo?.id;
        queryProjects({
            enterprise_id: eid,
            user_id: userId,
            search_name: search_name || null,
        })
            .then(res => {
                let projects: project[] = res.data?.map(item => {
                    let p = item.project;
                    let leader = item.leader;
                    return Object.assign(p, { leader: leader, leader_name: leader?.name })
                })
                projects = projects.sort((a, b) => {
                    if (a.active > b.active) return -1;
                    if (a.active < b.active) return 1;
                    return 0;
                })
                this.setState({
                    projectList: projects,
                    displayProjectList: projects
                })
            })
    }

    onDisplayChange = (e) => {
        this.props.userStore.setProjectListDisplay(e.target.value)
    }

    onSearch = () => {
        let projects = this.state.projectList;
        this.setState({
            displayProjectList: projects.filter(item => item.name.indexOf(this.state.searchValue) >= 0)
        })
    }

    onAddClick = () => {
        let userType = this.props.userStore._userInfo?.type;
        if (!userType || userType === UserTypeEnum.CLIENT) {
            message.warning('暂无权限');
            return;
        }
        this.setState({
            projectAddModalVisible: true,
        })
    }

    afterSave = () => {
        this.setState({
            searchValue: null,
            projectAddModalVisible: false,
        })
        this.findProjects();
    }

    onSearchInputChange = (e) => {
        this.setState({
            searchValue: e.target.value
        })
    }

    render() {
        let displayStyle = this.props.userStore._projectListDisplay;
        let eid = this.props.userStore._enterpriseId;
        let uid = this.props.userStore._userInfo?.id;
        return (
            <div className="project-list-container">
                <div style={{ display: 'none' }}>{eid}{uid}</div>
                <div className="page-header">
                    <Space size="large">
                        <Search
                            placeholder="检索项目名称"
                            value={this.state.searchValue}
                            onChange={this.onSearchInputChange}
                            size="large"
                            onBlur={this.onSearch}
                            onPressEnter={this.onSearch} />
                            <Button type="dashed" size="large" icon={<IconFont type="icon-xinzeng" />} onClick={this.onAddClick}>创建项目</Button>
                        <Radio.Group
                            defaultValue={displayStyle}
                            buttonStyle="solid"
                            size="large"
                            onChange={this.onDisplayChange}>
                            <Radio.Button value="Card">
                                <IconFont type="icon-qiapianmoshi_kuai" />
                            </Radio.Button>
                            <Radio.Button value="List">
                                <IconFont type="icon-liebiao" />
                            </Radio.Button>
                        </Radio.Group>
                    </Space>
                </div>
                <div className="main">
                    {displayStyle === 'Card' && (
                        <ProjectCardPanel projectList={this.state.displayProjectList} />
                    )}
                    {displayStyle === 'List' && (
                        <ProjectListPanel projectList={this.state.displayProjectList} />
                    )}
                </div>

                <Modal
                    destroyOnClose
                    visible={this.state.projectAddModalVisible}
                    onCancel={() => this.setState({ projectAddModalVisible: false })}
                    footer={null}
                    title="创建新项目">
                    <ProjectAdd
                        onCancel={() => this.setState({ projectAddModalVisible: false })}
                        afterSave={this.afterSave} />
                </Modal>
            </div>
        )
    }
}
