import axios from "../axios"
import { AxiosResponse } from "axios"

export function queryReports(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`projects/${projectId}/reports`, {
        params: params
    })
}

export function createReport(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`projects/${projectId}/reports`, data, {
        params: params
    })
}

export function deleteReport(projectId: number, reportId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`projects/${projectId}/reports/${reportId}`, {
        params: params
    })
}

export function generateReports(projectId: number, type: number, data: any): Promise<AxiosResponse<any>> {
    return axios.post(`projects/${projectId}/reports/request`, data, {
        params: {
            type: type,
        }
    })
}

export function queryReportsGenerating(projectId: number, type: number): Promise<AxiosResponse<any>> {
    return axios.get(`projects/${projectId}/reports/request`, {
        params: {
            type: type,
        }
    })
}

export function downloadReport(projectId: number, reportId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`projects/${projectId}/reports/${reportId}/download`, {
        params: params,
        responseType: 'blob'
    })
}