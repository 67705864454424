import React, { Component } from 'react'
import { Form, Input, Select, Upload, Button, Space } from 'antd'
import { DeviceVarietyLevel } from './global-install';
import { FormInstance } from 'antd/lib/form';
import { globalDbox } from '@/interface/dbox';
import { globalSensor } from '@/interface/sensor';
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';
import {UploadOutlined} from '@ant-design/icons';
const { Option } = Select;

interface IProps {
    gDboxs: globalDbox[]
    gSensors: globalSensor[],
    userStore?: UserStore,
    onCancel?: () => void,
    afterSave?: () => void,
}
interface IState {
    level: number
}

@inject('userStore')
@observer
export default class InstallAddPanel extends Component<IProps, IState> {
    formRef = React.createRef<FormInstance>();
    state = {
        level: null
    }
    onLevelChange = (e) => {
        this.setState({
            level: e.target.value
        })
    }

    onFinish = (values) => {
        // TODO: 

    }

    render() {
        return (
            <div>
                <Form ref={this.formRef} onFinish={this.onFinish} autoComplete="off" labelCol={{span: 5}}>
                    <Form.Item label="方法名称" name="name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="设备层级" name="level" rules={[{ required: true }]}>
                        <Select onChange={this.onLevelChange}>
                            <Option value={DeviceVarietyLevel.DBOX}>采集箱</Option>
                            <Option value={DeviceVarietyLevel.SENSOR}>传感器</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="设备类别" name="device_type" rules={[{ required: true }]}>
                        <Select >
                            {this.state.level === DeviceVarietyLevel.DBOX && (
                                this.props.gDboxs.map(item => (
                                    <Option value={item.type}>{item.name}</Option>
                                ))
                            )}
                            {this.state.level === DeviceVarietyLevel.SENSOR && (
                                this.props.gSensors.map(item => (
                                    <Option value={item.type}>{item.name}</Option>
                                ))
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item label="上传" name="document">
                        <Upload action="/upload.do">
                            <Button>
                                <UploadOutlined />上传附件
                            </Button>
                        </Upload>
                    </Form.Item>
                    <div style={{ textAlign: 'right', marginTop: '24px' }}>
                        <Space size="large">
                            <Button onClick={this.props.onCancel} >取消</Button>
                            <Button type="primary" htmlType="submit">保存</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        )
    }
}
