import axios from "../axios"
import { AxiosResponse } from "axios"

export function queryNodes(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`projects/${projectId}/nodes`, {
        params: params
    })
}

export function createNode(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`projects/${projectId}/nodes`, data, {
        params: params
    })
}

export function updateNode(projectId: number, nodeId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`projects/${projectId}/nodes/${nodeId}`, data, {
        params: params
    })
}

export function deleteNode(projectId: number, nodeId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`projects/${projectId}/nodes/${nodeId}`, {
        params: params
    })
}

export function queryElements(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`projects/${projectId}/elements`, {
        params: params
    })
}

export function createElement(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`projects/${projectId}/elements`, data, {
        params: params
    })
}

export function updateElement(projectId: number, elementId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`projects/${projectId}/elements/${elementId}`, data, {
        params: params
    })
}

export function deleteElement(projectId: number, elementId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`projects/${projectId}/elements/${elementId}`, {
        params: params
    })
}

export function querySections(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`projects/${projectId}/sections`, {
        params: params
    })
}

export function querySectionBySid(projectId: number, sid: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`projects/${projectId}/sections/sids/${sid}`, {
        params: params
    })
}

export function querySectionDetail(projectId: number, sectionId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`projects/${projectId}/sections/${sectionId}`, {
        params: params
    })
}

export function createSection(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    if (!!data.id) {data.id = null};
    return axios.post(`projects/${projectId}/sections`, data, {
        params: params
    })
}

export function updateSection(projectId: number, sectionId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`projects/${projectId}/sections/${sectionId}`, data, {
        params: params
    })
}

export function deleteSection(projectId: number, sectionId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`projects/${projectId}/sections/${sectionId}`, {
        params: params
    })
}

export function queryMaterials(projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`projects/${projectId}/materials`, {
        params: params
    })
}

export function queryMaterialBySid(sid: number, projectId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`projects/${projectId}/materials/sids/${sid}`, {
        params: params
    })
}

export function createMaterial(projectId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    if (!!data.id) {data.id = null};
    return axios.post(`projects/${projectId}/materials`, data, {
        params: params
    })
}

export function updateMaterial(projectId: number, materialId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`projects/${projectId}/materials/${materialId}`, data, {
        params: params
    })
}

export function deleteMaterial(projectId: number, materialId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`projects/${projectId}/materials/${materialId}`, {
        params: params
    })
}