import React, { PureComponent } from 'react'
import { Chart } from '@antv/g2';

interface Iprops {
    title: string,
    working: number,
    count: number,
    id: string,
    color?: string, // 饼图占有区域的颜色
    textColor?: string, // 中间文字的颜色
    bgColor?: string, // 饼图的背景颜色
}

export default class DeviceChart extends PureComponent<Iprops> {

    deviceChart: Chart;

    componentDidMount() {
        const data = [
            { item: '正常', value: this.props.working },
            { item: '非正常', value: this.props.count - this.props.working },
        ];
        const chart = new Chart({
            container: this.props.id,
            autoFit: true,
            height: 110,
        });
        chart.legend(false);
        chart.coordinate('theta', {
            radius: 0.75,
            innerRadius: 0.6,
        });
        chart.data(data);
        // chart.scale('percent', {
        //     formatter: (val) => {
        //       val = val * 100 + '%';
        //       return val;
        //     },
        // });

        //   chart.tooltip({
        //     showTitle: false,
        //     showMarkers: false,
        //   });
        chart.coordinate('theta', {
            radius: 0.8,
            innerRadius: 0.7
        })

        chart
            .interval()
            .position('value')
            .color('item', [this.props.color ? this.props.color : '#0a9afe', this.props.bgColor ? this.props.bgColor : '#eceef1'])
            .adjust('stack');

        chart.annotation().text({
            position: ['50%', '50%'],
            content: `${this.props.working}/${this.props.count}`,
            style: {
                fontSize: 18,
                fill: this.props.textColor ? this.props.textColor : '#000',
                fontWeight: 500,
                textAlign: 'center'
            },
            offsetY: 2,
        })

        chart.interaction('element-active');

        chart.render();

        this.deviceChart = chart;
    }

    componentDidUpdate() {
        const data = [
            { item: '正常', value: this.props.working },
            { item: '非正常', value: this.props.count - this.props.working },
        ];
        // this.deviceChart.changeData(data);
        this.deviceChart.data(data);
        this.deviceChart.annotation().clear(true);
        this.deviceChart.annotation().text({
            position: ['50%', '50%'],
            content: `${this.props.working}/${this.props.count}`,
            style: {
                fontSize: 18,
                fill: this.props.textColor ? this.props.textColor : '#000',
                fontWeight: 500,
                textAlign: 'center'
            },
            offsetY: 2,
        })
        this.deviceChart.render();
    }


    render() {
        return (
            <React.Fragment>
                <div id={this.props.id}></div>
                <div style={{ textAlign: 'center' }}>{this.props.title}</div>
            </React.Fragment>

        )
    }
}
