import { QuantityBoard } from '@/components';
import AlertTag from '@/components/alert-tag';
import DeviceChart from '@/components/dashboard-card/device-chart';
import EvaluateBoard from '@/components/dashboard-card/evaluate-board';
import { queryDrawings, queryMobjectsByDrawingId } from '@/config/api/constage';
import { queryDboxs } from '@/config/api/dbox';
import { downloadUrlPrefix, figureStaticLocation } from '@/config/api/document';
import { queryMobjectDetail } from '@/config/api/mobject';
import { queryProjectDetail } from '@/config/api/project';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import { downloadReport, queryReports } from '@/config/api/report';
import { querySensors } from '@/config/api/sensor';
import { queryWarnings } from '@/config/api/warning';
import { drawing } from '@/interface/constage';
import { dbox } from '@/interface/dbox';
import { mobject } from '@/interface/mobject';
import { project } from '@/interface/project';
import { globalQuantity } from '@/interface/quantity';
import { report } from '@/interface/report';
import { sensor } from '@/interface/sensor';
import { warning } from '@/interface/warning';
import { monitorRank, monitorType } from '@/utils/type';
import { Button, Carousel, Col, Descriptions, List, Modal, Row, Spin } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { DboxStatusTypesEnum } from '../dbox/dbox-list';
import { SensorStatusTypesEnum } from '../sensor/sensor-list';
import './dashboard-dark.less'
import {
    PlayCircleOutlined,
} from '@ant-design/icons'
import { Player } from 'video-react';

function DashboardDark() {

    // @ts-ignore
    let { id } = useParams();
    const [project, setProject] = useState<project>();

    const [sensors, setSensors] = useState<sensor[]>([]);
    const [dboxs, setDboxs] = useState<dbox[]>([]);
    const [drawings, setDrawings] = useState<drawing[]>([]);
    const [warnings, setWarnings] = useState<warning[]>([]);
    const [reports, setReports] = useState<report[]>([]);
    const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
    const [videoOpen, setVideoOpen] = useState<boolean>(false);
    const [evaluateText, setEvaluateText] = useState<string>('');
    const [evaluateColor, setEvaluateColor] = useState<string>('');

    const history = useHistory();

    useEffect(() => {
        // let projectId = this.props.match.params['id'];
        let projectId = id;
        // console.log(projectId)
        queryProjectDetail(projectId)
            .then(res => {
                let projectDetail: project = res.data;
                setProject(projectDetail)
                // this.props.projectStore.setProjectName(projectDetail?.name);
                // this.props.projectStore.setProject(projectDetail);
                // let enterpriseId = projectDetail?.enterprise_id;
                // this.props.userStore.setEnterpriseId(enterpriseId);
            })
        let queryDboxsPromise = queryDboxs({
            project_id: projectId,
        });
        let querySensorsPromise = querySensors({
            project_id: projectId,
        })
        // 设备
        axios.all([queryDboxsPromise, querySensorsPromise]).then(res => {
            let dboxs = res[0].data;
            let sensors = res[1].data;
            setSensors(sensors);
            setDboxs(dboxs);
        })
        // 监测施工图
        queryDrawings(projectId).then(res => {
            let drawings: drawing[] = res.data || [];
            drawings.forEach(item => {
                item.url = item.figure?.url || '';
                item.name = item.figure?.name || '';
            })
            drawings = drawings.sort((a: drawing, b: drawing) => a.name > b.name ? 1 : -1);
            setDrawings(drawings);
        })
        // 历史报警
        findWarnings(projectId);
        // 文档
        queryReports(projectId)
            .then(res => {
                let data: [] = res.data || [];
                setReports(data);
            })
    }, [id])

    const findWarnings = (projectId) => {
        let warnings: warning[] = [];
        let pQuantities: globalQuantity[] = [];
        let queryPQuantityPromise = queryProjectGlobalQuantities(projectId);
        let queryWarningPromise = queryWarnings({
            project_id: projectId,
            size: 5
        })
        axios.all([queryPQuantityPromise, queryWarningPromise]).then(res => {
            warnings = res[1].data;
            pQuantities = res[0].data;
            let promiseList = warnings.map(item => {
                let mobjectId = item.monitor_object_id;
                return queryMobjectDetail(mobjectId);
            })
            return axios.all([...promiseList]);
        }).then(res => {
            let mobjects = res.map(item => item.data);
            warnings.forEach((item: warning, idx) => {
                if (mobjects[idx] == null) return;
                item.mobject_name = mobjects[idx]?.name || '';
                item.mobject_type = mobjects[idx]?.type || null;
                let pQuantity = pQuantities.find(p => p.name === item.quantity_name);
                item.chinese_name = pQuantity?.chinese_name;
            })
            setWarnings(warnings);
        })
    }

    const donwloadFile = (record: report) => {
        if (process.env.NODE_ENV === 'development') {
            const projectId = this.context;
            downloadReport(projectId, record.id).then(res => {
                if (res.status === 200) {
                    const url = URL.createObjectURL(res.data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = record.name;
                    link.click();
                    URL.revokeObjectURL(url);
                }
            })
        } else {
            const link = document.createElement('a');
            link.setAttribute('href', `/api${record.url}`);
            link.setAttribute('download', record.name);
            link.setAttribute('target', '_blank');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const gotoDrawingDetail = (e, record: warning) => {
        e.stopPropagation();
        const projectId = id;
        let mobjectId = record.monitor_object_id;
        let drawings: drawing[] = [];
        // this.setState({
        //     warningLoading: true,
        // })
        queryDrawings(projectId)
            .then(res => {
                drawings = res.data || [];
                let promiseList = drawings.map(item => queryMobjectsByDrawingId(projectId, item.id));
                return axios.all([...promiseList]);
            }).then(res => {
                for (let idx = 0; idx < res.length; idx++) {
                    let dra = drawings[idx];
                    let mobjects: mobject[] = res[idx].data || [];
                    let m = mobjects.find(mob => mob.id === mobjectId);
                    if (!!m) {
                        let url = `/projects/${projectId}/drawings?sid=${dra.id}`;
                        history.push(url);
                        break;
                    }
                }
                // this.setState({
                //     warningLoading: false,
                // })
            }).catch(err => {
                // this.setState({
                //     warningLoading: false,
                // })
            })
    }

    return (
        <div className='dashboard-dark-container'>
            <div className='dashboard-header'>
                <div className='backend-btn' onClick={() => {
                    history.push('./mobjects/members')
                }}>
                    后台
                </div>
                <div className='title' onClick={() => {
                    history.push('/projects')
                }}>同恩监测系统</div>
                <div>
                    <Button 
                        title='宣传视频'
                        type="link" 
                        icon={<PlayCircleOutlined style={{'fontSize': '22px', 'color': 'white'}}/>} 
                        className='video-btn'
                        onClick={() => {setVideoOpen(true)}}></Button>
                </div>
            </div>
            <div className='dashboard-main'>
                <div className="dashboard-chart-panel">
                    <div className="device-chart-panel">
                        <span className="other-title" onClick={() => {
                            history.push('./dboxs')
                        }}>设备运行情况</span>
                        <div className="device-chart">
                            <div style={{ width: '50%' }} onClick={() => {
                                history.push('./dboxs')
                            }}><DeviceChart title="采集箱" working={dboxs.filter(item => item.work_status === DboxStatusTypesEnum.ONLINE)?.length || 0} count={dboxs?.length || 0} id='dbox' color='#4facb9' bgColor='#324268' textColor='#ffffff' /></div>
                            <div style={{ width: '50%' }} onClick={() => {
                                history.push('./sensors')
                            }}><DeviceChart title="传感器" working={sensors.filter(item => item.work_status === SensorStatusTypesEnum.ONLINE)?.length || 0} count={sensors?.length || 0} id='sensor' color='#2c99e3' bgColor='#324268' textColor='#ffffff' /></div>
                        </div>
                    </div>
                    <div className="quantity-chart-panel">
                        <div className="frame-top">
                            <span className="other-title" onClick={() => {
                                history.push('./mobjects/members')
                            }}>数据监测</span>
                        </div>
                        <QuantityBoard projectId={id} chartHeight={260} />
                        {/* <div className="frame-bottom"></div> */}
                    </div>
                </div>
                <div className="dashboard-project-panel">
                    <div className="project-info-panel">
                        <div className="center-top">
                            <div className='title'>{project?.name}</div>
                        </div>
                        <div className="drawing-panel">
                            <span className="other-title"></span>
                            <Carousel autoplay>
                                {drawings.length > 0 ? drawings.map((drawing: drawing, idx: number) => (
                                    <div key={idx} onClick={() => {
                                        history.push(`./drawings?sid=${drawing.id}`)
                                    }}>
                                        <div className="figure">
                                            {!!drawing.url ?
                                                <img height="450px" width="800px" alt="模式图" src={`${figureStaticLocation}${drawing.url}`} />
                                                : null
                                            }
                                        </div>
                                    </div>
                                )) : (
                                    <div >
                                        <div className="figure">
                                        </div>
                                    </div>
                                )}
                            </Carousel>
                        </div>
                    </div>
                    <div className="quantity-evaluate-panel">
                        <span className="other-title">详细指标评估</span>
                        <div className="main">
                            <EvaluateBoard bordered={false} projectId={id} onEvaluationAllBtnClick={() => this.props.history.push(`/projects/${id}/evaluations`)} onReturnStatistic={(text, color) => {
                                setEvaluateText(text);
                                setEvaluateColor(color);
                            }} />
                            <div className="project-info">
                                <div className="evaluation-info">
                                    <span className="other-title">综合指标评估</span>
                                    <div className="evaluation">
                                        <img src="/assets/safe.png" />
                                        <div className="evaluation-text" style={{ color: `${evaluateColor}` }}>
                                            {evaluateText}
                                        </div>
                                    </div>
                                </div>
                                <div className="monitor-info">
                                    <span className="other-title">监测信息</span>
                                    <Descriptions column={1}>
                                        <Descriptions.Item label="监测类别">
                                            {project?.monitor_type ? monitorType[project?.monitor_type] : ''}监测
                                        </Descriptions.Item>
                                        <Descriptions.Item label="监测等级">
                                            {project?.monitor_rank ? monitorRank[project?.monitor_rank] : ''}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="开始时间">
                                            {dayjs(project?.start_time).format('YYYY-MM-DD')}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="周期">
                                            {project?.period}天
                                        </Descriptions.Item>
                                    </Descriptions>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="frame-bottom">
                    </div> */}
                </div>
                <div className="dashboard-evaluate-panel">
                    <div className="history-warning-panel">
                        <span className="other-title" onClick={() => {
                            history.push('./warnings')
                        }}>历史预警</span>
                        <div className="warning-list">
                            <List
                                dataSource={warnings}
                                split={false}
                                renderItem={item => (
                                    <List.Item key={item.id} style={{ padding: '6px 0' }} onClick={(e) => {
                                        gotoDrawingDetail(e, item)
                                    }}>
                                        <Row className="alert-item">
                                            <Col span={5}>
                                                <span>{item.mobject_name}</span>
                                            </Col>
                                            <Col span={6}>
                                                <span>{item.chinese_name}</span>
                                            </Col>
                                            <Col span={5}>
                                                <span>{<AlertTag current_grade={item.warning_grade} />}</span>
                                            </Col>
                                            <Col span={8}>
                                                <span>{dayjs(item.gmt_create).format('YYYY-MM-DD HH:mm')}</span>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )} />
                        </div>
                    </div>
                    <div className="device-exception-panel">
                        <span className="other-title">设备异常</span>
                        <div className="exception-list">

                        </div>
                    </div>
                    <div className="document-panel">
                        <div className="frame-top">
                            <span className="other-title">文档</span>
                        </div>
                        <div className="document-list">
                            <List dataSource={reports} split={false} renderItem={item => (
                                <List.Item key={item.id} style={{ padding: '6px 0' }}>
                                    <span className="report-item" onClick={() => donwloadFile(item)}>{item.name}</span>
                                </List.Item>
                            )} />
                        </div>
                        <div className="frame-middle"></div>
                        <div className="frame-bottom"></div>
                    </div>
                </div>
            </div>
            <Modal
                visible={downloadLoading}
                footer={null}
                title={null}
                closable={false}
            >
                <Spin size="large" tip="正在下载报告...">
                    <div style={{ height: '100px' }}></div>
                </Spin>
            </Modal>
            <Modal
                visible={videoOpen}
                footer={null}
                title={null}
                // style={{
                //     height: '100vh',
                //     width: '100vw',
                // }}
                width="1000px"
                centered={false}
                onCancel={() => {setVideoOpen(false)}}
                destroyOnClose
                // closable={false}
                >
                <Player
                    src="/api/static/docs/监测宣传视频.mp4"/>
            </Modal>
        </div>
    )
}

export default DashboardDark