import MenuStore from "./menu-store";
import RouteStore from "./route-store";
import GlobalDeviceStore from "./global-device";
import MobjectStore from './mobject-store';
import QuantityDetailStore from './quantity-detail-store';
import UserStore from "./user-store";
import ProjectStore from "./project-store";
import SensorStore from "./sensor-store";
import StandardStore from "./standard-store";

let menuStore = new MenuStore();
let routeStore = new RouteStore();
let globalDeviceStore = new GlobalDeviceStore();
let mobjectStore = new MobjectStore();
let quantityDetailStore = new QuantityDetailStore();
let userStore = new UserStore();
let projectStore = new ProjectStore();
let sensorStore = new SensorStore();
let standardStore = new StandardStore();

export default {
    menuStore,
    routeStore,
    globalDeviceStore,
    mobjectStore,
    quantityDetailStore,
    userStore,
    projectStore,
    sensorStore,
    standardStore,
}