import React, { Component } from 'react'
import InfoBoardShell from './info-board-shell'
import { Table } from 'antd'
import { projectSchedule } from '@/interface/plan';
import { leftZeroInt } from '@/utils/utils';
const { Column } = Table;
interface IProps {
    projectScheduleList: projectSchedule[]
}
export default class InfoScheduleTableBoard extends Component<IProps> {
    render() {
        return (
            <InfoBoardShell title="时间计划">
                <Table
                    className="info-table-display fill-remaining-space"
                    dataSource={this.props.projectScheduleList}
                    rowKey="id"
                    pagination={false}
                    rowClassName={(record, index) => (
                        index % 2 !== 0 ? 'ribbon-row' : ''
                    )}>
                    <Column title="工作步" key="sid" render={(record: projectSchedule) => (
                        leftZeroInt(record.sid, 2)
                    )} />
                    <Column title="工作内容" dataIndex="content" />
                    <Column title="天数" dataIndex="duration" />
                    <Column title="备注" dataIndex="remark" />
                </Table>
            </InfoBoardShell>
        )
    }
}
