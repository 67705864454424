import React, { Component } from 'react'
import './index.less'
import { Layout, Tabs, Button, BackTop } from 'antd'
import MainHeader from './header';
import { inject, observer } from 'mobx-react';
import { Gprops } from '../../config/props';
import RouteStore from '../../store/route-store';
import CRouter from './croute';
import routeList from '../../config/route-list';
import SiderMenu from './menu';
import { ProjectContext } from '../../config/context';
import { queryProjectDetail, queryProjects } from '@/config/api/project';
import { project } from '@/interface/project';
import UserStore from '@/store/user-store';
import { ClearOutlined } from '@ant-design/icons';
import ProjectStore from '@/store/project-store';

const { Sider, Content } = Layout;
const { TabPane } = Tabs;

interface Iprops extends Gprops {
    routeStore?: RouteStore,
    userStore?: UserStore,
    projectStore?: ProjectStore,
}

@inject('routeStore', 'userStore', 'projectStore')
@observer
export default class MainLayout extends Component<Iprops> {

    constructor(props) {
        super(props)
        // 添加默认的控制台页签
        this.props.routeStore.addRoutePane('控制台', `${this.props.match.url}/dashboard`, false);
    }

    componentDidMount() {
        // this.context.router.history.listen(()=>{})
        // this.props.history.listen((location)=>{
        //     let url = location.pathname;
        //     this.props.routeStore.selectedKey(url);
        // })
        let projectId = this.props.match.params['id'];
        queryProjectDetail(projectId)
            .then(res => {
                let projectDetail: project = res.data;
                this.props.projectStore.setProjectName(projectDetail?.name);
                this.props.projectStore.setProject(projectDetail);
                let enterpriseId = projectDetail?.enterprise_id;
                this.props.userStore.setEnterpriseId(enterpriseId);
            })
        this.checkProjects(+projectId);
    }

    componentWillUnmount() {
        this.props.routeStore.clearRoutePanes();
        this.props.projectStore.setProjectName("");
        this.props.projectStore.setProjectWorkStatus(false);
    }

    checkProjects = (projectId: number) => {
        let eid = this.props.userStore._enterpriseId;
        let userId = this.props.userStore._userInfo?.id;
        if (!eid) {
            return;
        }
        if (!userId) {
            return;
        }
        queryProjects({
            enterprise_id: eid,
            user_id: userId,
        }).then(res => {
            let pids: number[] = res.data?.map(item => {
                let p = item.project;
                return p.id;
            }) || [];
            if (!pids.some(x => x === projectId)) {
                this.props.history.push('/projects');
            }
        })
    }

    onChange = (uuid: string) => {
        let url = this.props.routeStore._routePanes.find(item => item.uuid === uuid).key;
        this.props.routeStore.selectedKey(uuid);
        this.props.history.push(url);
    }

    onEdit = (uuid, action) => {
        this[action](uuid);
    }

    remove = (uuid: string) => {
        let selectedKey = this.props.routeStore._selectedKey;
        let lastIndex;
        this.props.routeStore._routePanes.forEach((pane, i) => {
            if (pane.uuid === uuid) {
                lastIndex = i - 1;
            }
        });
        this.props.routeStore.removeRoutePane(uuid);
        if (uuid === selectedKey) {
            // 切换激活页签
            let key = this.props.routeStore._routePanes[lastIndex].key;
            // this.props.routeStore.selectedKey(key);
            // 重定向页面
            if (key.indexOf('/projects/') < 0) {
                key = `${this.props.match.url}${key}`;
            }
            this.props.history.push(key);
        }
    }

    onClearTabClick = () => {
        this.props.routeStore.clearClosableRoutePanes();
        // this.props.history.push(`${this.props.match.url}/dashboard`);
    }


    render() {
        return (
            <ProjectContext.Provider value={this.props.match.params['id']}>
                <Layout className="main-container">
                    <Sider className="sider" width={180}>
                        <div className="logo" onClick={() => this.props.history.push(`/projects`)}>
                            <img src="/assets/logo-white.png" alt="tncet" height="40px" />
                        </div>
                        <SiderMenu />
                    </Sider>
                    <Content className="content">
                        <MainHeader />
                        <Tabs
                            className="main-tabs"
                            type="editable-card"
                            activeKey={this.props.routeStore._selectedKey}
                            hideAdd
                            onChange={this.onChange}
                            onEdit={this.onEdit}
                            tabBarExtraContent={(
                                <Button type="link" onClick={this.onClearTabClick} icon={<ClearOutlined />}>全部关闭</Button>
                            )}>
                            {this.props.routeStore._routePanes.map(pane => (
                                <TabPane
                                    tab={pane.title}
                                    key={pane.uuid}
                                    closable={pane.closable} />
                            ))}
                        </Tabs>
                        <div className="page-route">
                            <CRouter routeList={routeList} />
                        </div>
                    </Content>
                </Layout>
            </ProjectContext.Provider>

        )
    }
}
