import axios from "../axios";
import { AxiosResponse } from "axios";

export function queryGlobalSensors(): Promise<AxiosResponse<any>> {
    return axios.get(`/global/sensors`);
}

export function queryGlobalDboxs(): Promise<AxiosResponse<any>> {
    return axios.get(`/global/dboxs`);
}

export function queryGlobalSensorsInDboxs(marking: string): Promise<AxiosResponse<any>> {
    return axios.get(`/global/dboxs/${marking}/sensors`);
}