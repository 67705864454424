import React, { Component, Fragment } from 'react'
import { withRoutePane } from '@/components/hoc/routePane'
import './setting-project.less';
import { Form, Select, Switch, Slider, Button, Table, Modal, Popconfirm, message } from 'antd';
import { ProjectContext } from '@/config/context';
import { queryUsers } from '@/config/api/user';
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';
import { queryProjectDetail } from '@/config/api/project';
import { project } from '@/interface/project';
import { user } from '@/interface/user';
import { UserTypeEnum } from '../login/login';
import { PlusOutlined } from '@ant-design/icons';
import Column from 'antd/lib/table/Column';
import { queryProjectWarningUsers, updateProjectSetting, createProjectWarningUser, deleteProjectWarningUser } from '@/config/api/project-setting';
import axios from '@/config/axios';
import { FormInstance } from 'antd/lib/form';
import { UserTypes } from '@/utils/type';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import { queryDashboardQuantities } from '@/config/api/dashboard';
import { QuantityLevelTypes } from '@/components/mobject/quantity-pattern-setting';
import { globalQuantity, quantity, quantityData } from '@/interface/quantity';
import { mobjectQuantity } from '@/components/dashboard-card/evaluate-board';
import { sleep, sleepFor, Transfer2DisplayScale } from '@/utils/utils';
import { DatePicker } from '@/components';
import dayjs from 'dayjs';
import QuantityDetailStore from '@/store/quantity-detail-store';
import { deleteQuantityData, queryQuantityData } from '@/config/api/quantity-data';
import { time } from 'console';
const { Option } = Select;
interface IProps {
    userStore?: UserStore,
    quantityDetailStore?: QuantityDetailStore,
}
interface Istate {
    projectDetail: project
    users: user[]
    warningUsers: user[]
    warningUsersLoading: boolean

    modalVisible: boolean
    modalSaveLoading: boolean
    warningUserIdSelected: number[]

    quantities: quantity[],
    deleteCounter: number,
    cleanLoading: boolean,
}
@inject('userStore', 'quantityDetailStore')
@observer
class SettingProjectPage extends Component<IProps, Istate> {
    static contextType = ProjectContext;
    formRef = React.createRef<FormInstance>();
    state = {
        projectDetail: null,
        users: [],
        warningUsers: [],
        warningUsersLoading: false,

        modalVisible: false,
        modalSaveLoading: false,
        warningUserIdSelected: [],

        quantities: [],
        deleteCounter: 0,
        cleanLoading: false,
    }
    componentDidMount() {
        this.generateData();
        this.generateQuantities();
    }

    generateData = () => {
        let projectId = this.context;
        queryProjectDetail(projectId)
            .then(res => {
                let projectDetail: project = res.data;
                let enterpriseId = projectDetail?.enterprise_id;
                this.props.userStore.setEnterpriseId(enterpriseId);

                this.setState({
                    projectDetail: projectDetail
                })
                this.formRef.current.setFieldsValue({
                    leader_id: projectDetail?.leader_id,
                    active: projectDetail?.active,
                    warning_start_rank: projectDetail?.warning_start_rank,
                    auto_warning: projectDetail?.auto_warning,
                })
                let queryUsersPromise = queryUsers({
                    enterprise_id: enterpriseId
                });
                let queryWarningUsersPromise = queryProjectWarningUsers(projectId);
                this.setState({
                    warningUsersLoading: true,
                })
                return axios.all([queryUsersPromise, queryWarningUsersPromise]);
            }).then(res => {
                let users = res[0].data || [];
                // users = users.filter(item => item.type === UserTypeEnum.ENGINEER || item.type === UserTypeEnum.SITE || item.type === UserTypeEnum.ADMIN);
                let warningUsers = res[1].data || [];
                this.setState({
                    users: users,
                    warningUsers: warningUsers,
                    warningUsersLoading: false,
                })
            }).catch(err => {
                this.setState({
                    warningUsersLoading: false,
                })
            })
    }

    generateQuantities = () => {
        const projectId = this.context;
        let queryProjectQuantitiesPromise = queryProjectGlobalQuantities(projectId);
        let queryQuantitiesPromise = queryDashboardQuantities({
            project_id: projectId,
            quantity_level: QuantityLevelTypes.OTHER_Q,
            activated: true,
            is_evaluate: true,
        });
        axios.all([queryProjectQuantitiesPromise, queryQuantitiesPromise])
        .then(res => {
            let pQuantities: globalQuantity[] = res[0].data || [];
            let data: mobjectQuantity[] = res[1].data;
            data = data.filter(item => !!item.mobject);
            let quantities = data.map((item: mobjectQuantity) => {
                let q = item.quantity;
                q.mobject = item.mobject;
                q.mobject_name = item.mobject?.name || '';
                let pQuantity = pQuantities.find(p => p.type === q.type);
                q.unit_type = pQuantity?.unit_type || '';
                q.chinese_name = pQuantity?.chinese_name || '';
                q.precision = pQuantity?.precision || '1';
                // let scale = this.unitmaps[gQuantity?.unit_type][quantity?.unit_type] || 1;
                let scale = Transfer2DisplayScale(q.unit_type);
                q.scale = scale;
                q.valid_data = pQuantity.valid_data;
                return q;
            });
            this.setState({
                quantities: quantities,
            })
            // let qids = quantities.map(item => item.id);
            // let queryQuantityCurrentDataPromise = queryQuantityCurrentDataList({
            //     project_id: projectId,
            //     quantity_ids: qids,
            // })
            // return queryQuantityCurrentDataPromise;
        })
    }



    onStartTimeChange = (value: dayjs.Dayjs) => {
        this.props.quantityDetailStore.setStartTime(value?.toDate() || null);
    }

    onEndTimeChange = (value: dayjs.Dayjs) => {
        this.props.quantityDetailStore.setEndTime(value?.toDate() || null);
    }

    timer$: any;
    cleanAbnormalData = () => {
        if (this.state.quantities.length === 0) return;
        this.setState({
            deleteCounter: 0,
            cleanLoading: true,
        });
        this.timer$ = setInterval(this.checkCleanCompleted, 1000);
        let params = {
            start_time: this.props.quantityDetailStore._startTime?.getTime() || null,
            end_time: this.props.quantityDetailStore._endTime?.getTime() || null,
            sampling_period: null,
            data_type: 1,
        }
        let quantities: quantity[] = this.state.quantities || [];
        // quantities = quantities.map(item => Object.assign({}, item));
        if (params.start_time == null) {
            params['limit'] = 300;
        } else {
            params['limit'] = 10000;
        } 
        for (let i = 0; i< quantities.length; i++) {
            let q = quantities[i];
            queryQuantityData(q.id, params).then(res => {
                let qdata: quantityData = res.data || null;
                if (qdata.id == null || q.valid_data == null || Math.abs(q.valid_data) < 1e-5) {
                    this.setState({deleteCounter: this.state.deleteCounter++});
                    return;
                }
                (async () => {
                    for (let j = 0; j < qdata.data.length; j++) {
                        let datapoint = qdata.data[j];
                        if (Math.abs(datapoint.value) > Math.abs(q.valid_data) ) {
                            let start = datapoint.time - 1000;
                            let end = start + 2000;
                            deleteQuantityData(q.id, {
                                start_time: start,
                                end_time: end,
                            }).then(res => {});
                            console.log(q.id, datapoint)
                            await sleep(100);
                        }
                    }
                })();
                this.setState({deleteCounter: this.state.deleteCounter++});
            })
        }
    }

    checkCleanCompleted = () => {
        let counter = this.state.deleteCounter;
        if (counter < this.state.quantities.length) return;
        this.setState({
            cleanLoading: false,
        })
        clearInterval(this.timer$);
    }

    onSave = () => {
        let values = this.formRef.current.getFieldsValue();
        updateProjectSetting(this.context, values)
            .then(res => {
                this.state.projectDetail = { ...this.state.projectDetail, ...values };
            })
    }

    showModal = () => {
        let selected = this.state.warningUsers.map(item => item.id);
        this.setState({
            warningUserIdSelected: selected,
            modalVisible: true,
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            warningUserIdSelected: [...selectedRowKeys]
        })
    }

    onWarningUserAdd = () => {
        this.setState({
            modalSaveLoading: true,
        })
        // 初始值
        let defaultValues = this.state.warningUsers.map(item => item.id);
        // 当前勾选的
        let currentValues = this.state.warningUserIdSelected;
        // 交集
        let mixes = currentValues.filter(x => defaultValues.some(y => y === x));
        // 计算新增的
        let addIds = currentValues.filter(x => !mixes.some(y => y === x));
        // 计算将要删除的types  default-mixes
        let cutIds = defaultValues.filter(x => !mixes.some(y => y === x));

        let createPromise = createProjectWarningUser(this.context, null, {
            uids: addIds,
        });
        let deletePromiseList = cutIds.map(item => deleteProjectWarningUser(this.context, item));
        axios.all([createPromise, ...deletePromiseList])
            .then(res => {
                this.generateData();
                this.setState({
                    warningUserIdSelected: [],
                    modalVisible: false,
                    modalSaveLoading: false,
                })
            }).catch(err => {
                console.log(err);
                message.error('更新失败');
                this.setState({
                    modalSaveLoading: false,
                })
            })
    }

    onWarningUserDelete = (row) => {
        let id = row?.id;
        deleteProjectWarningUser(this.context, id)
        .then(res => {
            let newData = [...this.state.warningUsers];
            newData = newData.filter(item => item.id !== id);
            this.setState({
                warningUsers: [...newData],
            })
        })
    }

    render() {
        let { users, warningUsers, modalVisible } = this.state;
        let userType = this.props.userStore._userInfo?.type || null;
        return (
            <div className="setting-project-container">
                <Form labelCol={{ span: 2 }} ref={this.formRef}>
                    <Form.Item label="项目负责人" name="leader_id">
                        <Select style={{ width: '200px' }} onChange={this.onSave}>
                            {users.map(item => (
                                <Option key={item.id} value={item.id}>{item.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="项目开关" name="active" valuePropName="checked">
                        <Switch onChange={this.onSave} />
                    </Form.Item>
                    <Form.Item label="起始报警等级" name="warning_start_rank">
                        <Slider
                            onChange={this.onSave}
                            style={{ width: '300px' }}
                            included={false}
                            dots
                            max={5}
                            min={2}
                            marks={{
                                2: '二级',
                                3: '三级',
                                4: '四级',
                                5: '五级',
                            }} />
                    </Form.Item>
                    <Form.Item label="报警自动托管" name="auto_warning" valuePropName="checked">
                        <Switch onChange={this.onSave} />
                    </Form.Item>
                </Form>
                <div className="clean-pane">
                    <Button className="clean-btn" onClick={this.cleanAbnormalData} loading={this.state.cleanLoading}>清理异常数据</Button>
                    <Fragment>
                            <DatePicker 
                            showTime 
                            placeholder="开始时间" 
                            onChange={this.onStartTimeChange}
                            value={this.props.quantityDetailStore._startTime ? dayjs(this.props.quantityDetailStore._startTime) : null}/>
                            <DatePicker 
                            showTime 
                            placeholder="结束时间" 
                            onChange={this.onEndTimeChange}
                            value={this.props.quantityDetailStore._endTime ? dayjs(this.props.quantityDetailStore._endTime) : null}/>
                        </Fragment>
                </div>
                <div className="warning-user-pane">
                    <div className="header">
                        <div className="title">报警接收人</div>
                        {userType && userType !== UserTypeEnum.CLIENT && (
                            <Button type="dashed" icon={<PlusOutlined />} onClick={this.showModal}></Button>
                        )}
                    </div>
                    <div className="warning-user-table">
                        <Table
                            dataSource={warningUsers}
                            loading={this.state.warningUsersLoading}
                            rowKey="id"
                            pagination={false}
                            style={{ maxHeight: '400px', overflow: 'auto' }}>
                            <Column title="姓名" dataIndex="name" />
                            <Column title="类别" dataIndex="type" render={record => UserTypes[record]} />
                            <Column title="电话" dataIndex="mobile" />
                            <Column title="邮箱" dataIndex="email" />
                            <Column title="" key="operation" render={record => (
                                <Popconfirm
                                    title="确认删除吗？"
                                    onConfirm={() => this.onWarningUserDelete(record)}
                                    okText="是"
                                    cancelText="否"
                                >
                                    <Button type="link" size="small">删除</Button>
                                </Popconfirm>
                            )} />
                        </Table>
                    </div>
                </div>

                <Modal
                    width={800}
                    visible={modalVisible}
                    title="请选择用户"
                    onOk={this.onWarningUserAdd}
                    confirmLoading={this.state.modalSaveLoading}
                    onCancel={() => this.setState({ modalVisible: false })}>
                    <Table
                        rowKey="id"
                        className="select-warning-user-table"
                        pagination={false}
                        dataSource={users}
                        rowSelection={{
                            type: 'checkbox',
                            onChange: this.onSelectChange,
                            selectedRowKeys: this.state.warningUserIdSelected
                        }}
                    >
                        <Column title="姓名" dataIndex="name" />
                        <Column title="类别" dataIndex="type" render={record => UserTypes[record]} />
                        <Column title="电话" dataIndex="mobile" />
                        <Column title="邮箱" dataIndex="email" />
                    </Table>
                </Modal>
            </div>
        )
    }
}

export default withRoutePane(SettingProjectPage, '项目设置');