import React, { Component } from 'react'
import InfoBoardShell from './info-board-shell'

interface IProps {
    title: string
    content?: string
}
export default class InfoRequirementBoard extends Component<IProps> {
    render() {
        return (
            <InfoBoardShell title={this.props.title}>
                <span className="info-wrap" style={{minHeight: '100px'}}>
                    {this.props.content}
                </span>
            </InfoBoardShell>
        )
    }
}
