import React, { Component } from 'react'
import { warningGradeDict } from '../utils/type';
import { Tag } from 'antd';

interface IProps {
    current_grade: number
}

export default class AlertTag extends Component<IProps> {
    render() {
        const data = warningGradeDict[this.props.current_grade];
        if (!data) {
            return null;
        }
        return (
            <Tag style={{marginRight: '0px', textAlign: 'center', padding:'0px', width: '55px'}} color={data['color']}>{data['res']}</Tag>
        )
    }
}
