import React, { Component } from 'react';
import './dbox-info.less';
import { dbox } from '@/interface/dbox';

interface IProps {
    dbox: dbox
}

export default class DboxInfo extends Component<IProps> {

    render() {
        let dbox = this.props.dbox;
        if (!dbox) return null;

        return (
            <div className="dbox-info-pane">
                <div className="title">基本信息</div>
                <div>
                    <div className="term">
                        <div className="label">名称</div>
                        <span className="detail">{dbox.type_name}</span>
                    </div>
                    <div className="term">
                        <div className="label">编号</div>
                        <span className="detail">{dbox.name}</span>
                    </div>
                    <div className="term">
                        <div className="label">型号</div>
                        <span className="detail">{dbox.marking}</span>
                    </div>
                    <div className="term">
                        <div className="label">厂家</div>
                        <span className="detail">{dbox.manufacturer}</span>
                    </div>
                    {!!dbox.channel_range ? (
                        <div className="term">
                            <div className="label">通道号</div>
                            <span className="detail">{dbox.channel_range}</span>
                        </div>
                    ) : (
                            <div className="term">
                                <div className="label">通道数</div>
                                <span className="detail">{dbox.channel_count}</span>
                            </div>
                        )}
                    <div className="term">
                        <div className="label">传输类型</div>
                        <span className="detail">{dbox.wireless_type}</span>
                    </div>
                    <div className="term">
                        <div className="label">地址码</div>
                        <span className="detail">
                            {dbox.address_code}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}
