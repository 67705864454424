import React, { Component } from 'react'
import { Gprops } from '../../config/props';
import { Form, Input, Checkbox, Button, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import './login.less';
import appStore, { REMEMBER_USERNAME, REMEMBER_TAG, REMEMBER_PASSWORD, AUTHORIZATION, USER_ID } from '@/store/app-store';
import { FormInstance } from 'antd/lib/form';
import CryptoJS from 'crypto-js';
import { userLogin } from '@/config/api/user';
import UserStore from '@/store/user-store';
import { inject, observer } from 'mobx-react';
import { user } from '@/interface/user';

export const UserTypeEnum = {
    ROOT: 0,
    ADMIN: 1,
    ENGINEER: 2,
    SITE: 3,
    CLIENT: 4,
}

interface IProps extends Gprops {
    userStore?: UserStore
}
@inject('userStore')
@observer
export default class Login extends Component<IProps> {
    formRef = React.createRef<FormInstance>();
    state = {
        // 登录的错误信息
        errorMessage: '',
        loading: false,
    }

    componentDidMount() {
        // 从本地存储中获取自动保存的账户信息
        let username = appStore.get(REMEMBER_USERNAME);
        let remember = appStore.get(REMEMBER_TAG);
        let password = appStore.get(REMEMBER_PASSWORD) || '';
        this.formRef.current?.setFieldsValue({
            username: username,
            remember: remember,
            password: CryptoJS.enc.Base64.parse(password).toString(CryptoJS.enc.Utf8),
        });
    }
    login = (values) => {
        //错误信息清空
        this.setState({
            errorMessage: '',
            loading: true,
        })

        let data = {
            username: values.username,
            password: CryptoJS.MD5(values.password).toString(),
        };
        userLogin(data)
            .then(res => {
                // 登录失败
                if (res.status !== 200) {
                    this.setState({
                        errorMessage: res?.message,
                        loading: false,
                    })
                    return;
                }
                let userDetail: user = res.data;
                appStore.set(AUTHORIZATION, userDetail.token);
                appStore.set(USER_ID, userDetail.id);
                this.props.userStore.setEnterpriseId(userDetail.enterprise_id);
                this.props.userStore.setUserInfo(userDetail);
                this.props.userStore.setIsLogin(true);
                if (values.remember) {
                    appStore.set(REMEMBER_USERNAME, values.username);
                    appStore.set(REMEMBER_PASSWORD, CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(values.password)));
                    appStore.set(REMEMBER_TAG, values.remember);
                } else {
                    appStore.remove(REMEMBER_USERNAME);
                    appStore.remove(REMEMBER_PASSWORD);
                    appStore.remove(REMEMBER_TAG);
                }
                let userType = userDetail.type;
                if (userType === UserTypeEnum.ROOT) {
                    this.props.history.push('/backend/enterprises');
                } else {
                    this.props.history.push('/projects');
                }
            }).catch(err => {
                this.setState({
                    errorMessage: '登录失败',
                    loading: false,
                })
            })
    }
    render() {
        return (
            <Form className="login-form" ref={this.formRef} onFinish={this.login} autoComplete="off">
                {!!this.state.errorMessage && (
                    <Alert
                        style={{marginBottom: '10px'}}
                        message={this.state.errorMessage}
                        type="error"
                        closable
                        onClose={() => this.setState({errorMessage: ''})}/>
                )}

                <Form.Item name="username" rules={[{ required: true, message: '用户名不能为空' }]}>
                    <Input prefix={<UserOutlined />} placeholder="用户名" />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: '密码不能为空' }]}>
                    <Input.Password prefix={<LockOutlined />} placeholder="密码" />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>记住我</Checkbox>
                    </Form.Item>
                    <a className="login-form-forgot" href="/passport/forget">
                        忘记密码?
                    </a>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
                        登 录
                    </Button>
                    {/* Or <a href="">register now!</a> */}
                </Form.Item>
            </Form>
        )
    }
}
