import React, { Component } from 'react'
import { withRoutePane } from '@/components/hoc/routePane'
import './site-condition.less';
import { querySiteConditions, deleteSiteCondition } from '@/config/api/site-conditions';
import { ProjectContext } from '@/config/context';
import { siteCondition } from '@/interface/site-condition';
import dayjs from 'dayjs';
import { message, Timeline } from 'antd';
import { ConditionHeader, ConditionItemAdd, ConditionItem } from '@/components';
interface IProps {

}
interface IState {
    siteConditions: siteCondition[],
    tags: string[],
    inputValue: string,
    isEditAdding: boolean,
}
class SiteCondition extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        siteConditions: [],
        tags: [],
        inputValue: null,
        isEditAdding: false,
    }
    componentDidMount() {
        this.generateData();
    }

    generateData = (tags?: string[], search_content?: string) => {
        querySiteConditions(this.context, {
            search_content: search_content || null,
            tags: tags
        }).then(res => {
            let conditions: siteCondition[] = res.data.map(item => Object.assign(item.condition, { user_name: item.user.name }))
            conditions = conditions.sort((a, b) => dayjs(+b.time).valueOf() - dayjs(+a.time).valueOf());
            this.setState({
                siteConditions: conditions,
            })
        })
    }

    onTagsChange = (tags: string[]) => {
        console.log(tags)
        this.setState({
            tags: tags
        })
        this.generateData(tags, this.state.inputValue)
    }

    onSearchContentChange = (value: string) => {
        this.setState({
            inputValue: value,
        })
        this.generateData(this.state.tags, value === '' ? null : value);
    }

    afterSave = () => {
        this.generateData(this.state.tags, this.state.inputValue);
        this.setState({ isEditAdding: false });
    }

    onDelete = (id: number) => {
        if (!id) {
            message.error(`当前id ${id}不存在`);
            return;
        }
        deleteSiteCondition(this.context, id)
            .then(res => {
                this.setState({
                    siteConditions: this.state.siteConditions.filter(item => item.id !== id)
                })
            })
    }

    render() {
        return (
            <div className="site-condition-container">
                <ConditionHeader
                    isEditAdding={this.state.isEditAdding}
                    onTagsChange={this.onTagsChange}
                    onSearchContentChange={this.onSearchContentChange}
                    onAddClick={() => { this.setState({ isEditAdding: true }) }}
                />
                {this.state.isEditAdding && (
                    <ConditionItemAdd
                        isEditAdding={this.state.isEditAdding}
                        onCancelClick={() => { this.setState({ isEditAdding: false }) }}
                        afterSave={this.afterSave}
                    />
                )}
                <div className="condition-item-group">
                    <Timeline>
                        {this.state.siteConditions.map(item => (
                            <Timeline.Item key={item.id} >
                                <ConditionItem siteConditionDetail={item} onDelete={this.onDelete} />
                            </Timeline.Item>
                        ))}
                    </Timeline>

                </div>
            </div>
        )
    }
}

export default withRoutePane(SiteCondition, '现场条件');