import React, { Component } from 'react'
import './global-planb.less';
import { Table, Checkbox, Button, Popconfirm, message } from 'antd';
import UserStore from '@/store/user-store';
import { observer, inject } from 'mobx-react';
import { globalPlanb } from '@/interface/plan';
import { queryEnterprisePlanbs, deleteEnterprisePlanb, updateEnterprisePlanb, createEnterprisePlanb } from '@/config/api/global-planb';
import { EditableRow, EditableCell } from '@/components';
import { PlusOutlined } from '@ant-design/icons';
const { Column } = Table;

interface IProps {
    userStore?: UserStore
}
interface IState {
    gPlanbs: globalPlanb[],
    loading: boolean,
}

@inject('userStore')
@observer
export default class GlobalPlanbPage extends Component<IProps, IState> {
    state = {
        gPlanbs: [],
        loading: false,
    }
    enterpriseId: number

    componentDidMount() {
        this.enterpriseId = this.props.userStore._enterpriseId;
        this.findPlanbs();
    }

    componentDidUpdate() {
        let eid = this.props.userStore._enterpriseId;
        if (this.enterpriseId !== eid) {
            this.enterpriseId = eid;
            this.findPlanbs();
        }
    }

    findPlanbs = () => {
        let eid = this.props.userStore._enterpriseId;
        if (!eid) return;
        queryEnterprisePlanbs(eid)
            .then(res => {
                let data = res.data || [];
                data = data.map((item, idx) => ({ ...item, key: idx + 1 }))
                this.setState({
                    gPlanbs: data
                })
            })
    }

    onDelete = (row) => {
        deleteEnterprisePlanb(this.enterpriseId, row.id)
            .then(res => {

                let data = this.state.gPlanbs.filter(item => item.id !== row.id);
                data.forEach((item, idx) => {
                    item.key = idx + 1;
                })
                this.setState({
                    gPlanbs: [...data]
                })
            })
    }

    handleSave = (row: globalPlanb) => {
        let id = row.id;
        // let key = row.key;
        let newData = [...this.state.gPlanbs];
        let index = newData.findIndex(item => item.id === id);
        let item: globalPlanb = newData[index];

        newData.splice(index, 1, { ...item, ...row })
        this.setState({
            gPlanbs: [...newData]
        })
        let data = {
            content: row.content,
        }
        updateEnterprisePlanb(this.props.userStore._enterpriseId, id, data)
            .then(res => {

            }).catch(err => {
                message.error('更新失败');
                newData.splice(index, 1, { ...row, ...item })
                this.setState({
                    gPlanbs: [...newData]
                })
            })
    }

    onCheckChange = (e, row) => {
        let is_import = e.target.checked;
        let id = row.id;
        row.is_import = is_import;
        this.setState({
            gPlanbs: [...this.state.gPlanbs]
        })
        let data = {
            is_import: is_import
        }
        updateEnterprisePlanb(this.props.userStore._enterpriseId, id, data)
            .then(res => {

            }).catch(err => {
                message.error('更新失败');
                row.is_import = !is_import;
                this.setState({
                    gPlanbs: [...this.state.gPlanbs]
                })
            })
    }

    onAddClick = () => {
        if (!this.props.userStore._enterpriseId) {
            message.error("发生错误");
            return;
        }
        this.setState({loading: true});
        let data = {
            content: '',
            is_import: true,
        }
        createEnterprisePlanb(this.props.userStore._enterpriseId, data)
        .then(res => {
            let newData = [...this.state.gPlanbs, {...data, id: res.data, key: this.state.gPlanbs.length+1}]
            this.setState({
                gPlanbs: newData,
                loading: false,
            })
        }).catch(err => {
            this.setState({loading: false})
            message.error('创建失败')
            console.log(err);
        })
    }

    render() {
        let eid = this.props.userStore._enterpriseId;
        return (
            <div className="global-planb-container">
                <div style={{display: 'none'}}>{eid}</div>
                <Table
                    dataSource={this.state.gPlanbs}
                    pagination={false}
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        }
                    }}
                    rowClassName={(record, index) => (
                        'editable-row'
                    )}
                    rowKey="id">
                    <Column title="序号" width="60px" dataIndex="key" />
                    <Column title="内容" dataIndex="content" onCell={(record: globalPlanb, idx) => ({
                        record,
                        editable: true,
                        dataIndex: 'content',
                        title: '内容',
                        handleSave: this.handleSave,
                    })} />
                    {/* <Column title="默认导入" width="140px" align="center" key="is_import" render={record => (
                        <Checkbox checked={record.is_import} onChange={(e) => this.onCheckChange(e, record)} />
                    )} /> */}
                    <Column title="操作" key="operation" render={record => (
                        <Popconfirm title="确定删除吗?" okText="是" cancelText="否" onConfirm={() => this.onDelete(record)}>
                            <Button type="link" size="small">删除</Button>
                        </Popconfirm>
                    )} />
                </Table>
                <div>
                    <Button 
                    className="add-btn" 
                    type="dashed" 
                    size="large" 
                    loading={this.state.loading}
                    icon={<PlusOutlined />} onClick={this.onAddClick}>添加</Button>

                </div>
            </div>
        )
    }
}
