

/*
 * Copyright:tncet.com
 * Author:Bighansen
 * Date:2020-01-10
 * 本地参数存储封装
 * 
*/

import { user } from "@/interface/user";

export const GLOBAL_NAMESPACE = 'monix.';
export const AUTHORIZATION = GLOBAL_NAMESPACE + 'authorization';
export const REMEMBER_USERNAME = GLOBAL_NAMESPACE + 'username';
export const REMEMBER_PASSWORD = GLOBAL_NAMESPACE + 'password';
export const REMEMBER_TAG = GLOBAL_NAMESPACE + 'remember';
export const USER = GLOBAL_NAMESPACE + 'user';
export const USER_ID = GLOBAL_NAMESPACE + 'userid';
export const USER_LOGIN = GLOBAL_NAMESPACE + 'login';

export const PROJECT_DISPLAY_STYLE = GLOBAL_NAMESPACE + 'project.list.display';
export const SITE_DISPLAY_STYLE = GLOBAL_NAMESPACE + 'project.sites.display';

class AppStore {
    private _token = '';
    private _user: user = null;

    public get(key: string) {
        return JSON.parse(localStorage.getItem(key) || 'null') || null;
    }

    public set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public remove(key: string): void {
        localStorage.removeItem(key);
    }

    get token(): string {
        // if (!this._token) {
        //     this._token = this.get(AUTHORIZATION) || '';
        // }
        this._token = this.get(AUTHORIZATION) || '';
        return this._token;
    }

    set token(value: string) {
        this.set(AUTHORIZATION, value);
    }

    get user(): user {
        if (!this._user) {
          this._user = this.get(USER) || null;
        }

        return this._user;
      }
    
    set user(value: user) {
        this._user = value;
        this.set(USER, this._user);
    }

    clear() {
        this._token = '';
        this._user = null;
        this.remove(AUTHORIZATION);
        this.remove(USER);
        this.remove(USER_ID);
    }
}

export default new AppStore();