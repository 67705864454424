import React, { Component, Fragment } from 'react'
import './dbox-add.less';
import { globalDbox } from '@/interface/dbox';
import { Form, Input, Select, Row, Col, Space, Button, InputNumber, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { NumericInput } from '..';
import { DboxTypesEnum } from '@/routes/dbox/dbox-list';
import { createDbox } from '@/config/api/dbox';
import { ProjectContext } from '@/config/context';
import { globalInstall } from '@/interface/install';
import { queryEnterprisesInstallations } from '@/config/api/global-install';
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';
import axios from '@/config/axios';

const { Option } = Select;
interface IProps {
    onCancel?: () => void
    onSave?: () => void
    gDboxs: globalDbox[]
    userStore?: UserStore
}

interface IState {
    gDboxTypes: any[]
    gDboxMarkings: string[]
    dboxType: number
    gInstalls: globalInstall[]
    loading: boolean
}
@inject('userStore')
@observer
export default class DboxAddPanel extends Component<IProps, IState> {
    static contextType = ProjectContext;
    formRef = React.createRef<FormInstance>();
    state = {
        gDboxTypes: [],
        gDboxMarkings: [],
        dboxType: DboxTypesEnum.STATIC,
        gInstalls: [],
        loading: false,
    }
    componentDidMount() {
        let gDboxs = this.props.gDboxs;
        let gDboxTypes = Array.from(new Set(
            gDboxs.map(item => (`${item.type}__${item.name}`))
        )).map(item => {
            let tmp = item.split('__');
            return { label: tmp[1], value: +tmp[0] };
        })
        this.setState({
            gDboxTypes: gDboxTypes,
        })
    }

    onTypeChange = (value) => {
        let gDboxs = this.props.gDboxs;
        let gDboxMarkings = gDboxs.filter(item => item.type === value).map(item => item.marking);
        this.setState({
            gDboxMarkings: gDboxMarkings,
            dboxType: value,
        })
        this.formRef.current?.setFieldsValue({
            manufacturer: null,
            channel_count: null,
            wireless_type: null,
        })
        queryEnterprisesInstallations(this.props.userStore._enterpriseId)
        .then(res => {
            this.setState({
                gInstalls: res.data || []
            })
        })
    }

    onMarkingChange = (value) => {
        let gDbox = this.props.gDboxs.find(item => item.marking === value);
        this.formRef.current?.setFieldsValue({
            manufacturer: gDbox.manufacturer,
            channel_count: gDbox.channel_count,
            wireless_type: gDbox.wireless_type,
        })
    }

    onSave = (values) => {
        this.setState({
            loading: true,
        })
        let { copy, ...data } = values;
        data = {
            ...data,
            project_id: this.context,
        }
        if (data.channel_range === '') {
            data.channel_range = null;
        }
        if (!!data.channel_range) {
            data.channel_range = data.channel_range.replace('，', ',');
        }
        let promiseList = [];
        for (let idx = 0; idx < copy + 1; idx++) {
            if (idx >= 1) {
                promiseList.push(createDbox({...data, name: `${data.name}-${idx}`}));
            } else {
                promiseList.push(createDbox(data));
            }
            
        }
        axios.all([...promiseList])
        .then(res => {
            this.setState({
                loading: false,
            })
            this.props.onSave();
        }).catch(err => {
            message.error('创建失败');
            console.log(err);
            this.setState({
                loading: false,
            })
        })
    }

    render() {
        const DboxStaticFrequencyOptions = (
            <Fragment>
                <Option value={1}>1 秒</Option>
                <Option value={2}>2 秒</Option>
                <Option value={5}>5 秒</Option>
                <Option value={10}>10 秒</Option>
                <Option value={30}>30 秒</Option>
                <Option value={60}>1 分钟</Option>
                <Option value={2 * 60}>2 分钟</Option>
                <Option value={5*60}>5 分钟</Option>
                <Option value={10*60}>10 分钟</Option>
                <Option value={15*60}>15 分钟</Option>
                <Option value={30*60}>30 分钟</Option>
                <Option value={60*60}>1 小时</Option>
                <Option value={360*60}>6 小时</Option>
                <Option value={1440*60}>24 小时</Option>
            </Fragment>
        )
        const DboxDynamicFrequencyOptions = (
            <Fragment>
                <Option value={1}>1 Hz</Option>
                <Option value={5}>5 Hz</Option>
                <Option value={10}>10 Hz</Option>
                <Option value={20}>20 Hz</Option>
                <Option value={30}>30 Hz</Option>
                <Option value={50}>50 Hz</Option>
                <Option value={100}>100 Hz</Option>
            </Fragment>
        )
        return (
            <div className="dbox-add-container">
                <Form
                    ref={this.formRef}
                    labelCol={{ span: 5 }}
                    autoComplete="off"
                    onFinish={this.onSave}>
                    <Row gutter={10}>
                        <Col xs={24} xl={12}>
                            <Form.Item label="编号" name="name" rules={[{ required: true }]}>
                                <Input placeholder="请输入采集箱在项目中的编号" />
                            </Form.Item>
                            <Form.Item label="采集箱类型" name="type" rules={[{ required: true }]}>
                                <Select onChange={this.onTypeChange}>
                                    {this.state.gDboxTypes.map(item => (
                                        <Option key={item.value} value={item.value}>{item.label}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="型号" name="marking" rules={[{ required: true }]}>
                                <Select onChange={this.onMarkingChange}>
                                    {this.state.gDboxMarkings.map(item => (
                                        <Option key={item} value={item}>{item}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="厂家" name="manufacturer">
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item label="通信类型" name="wireless_type">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={12}>

                            <Form.Item label="通道数" name="channel_count" rules={[{ required: true }]}>
                                <NumericInput />
                            </Form.Item>
                            <Form.Item label="自定义通道号" name="channel_range">
                                <Input placeholder="如：1-5,10,16-22 没有空格 没有中文逗号"/>
                            </Form.Item>
                            <Form.Item label="地址码" name="address_code" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="安装方法" name="install_id">
                                <Select onChange={this.onTypeChange}>
                                    {this.state.gInstalls.map(item => (
                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="采集频率" name="frequency" rules={[{ required: true }]}>
                                <Select style={{ width: 150 }} >
                                    {DboxStaticFrequencyOptions}
                                    {/* {this.state.dboxType === DboxTypesEnum.STATIC ? (
                                        DboxStaticFrequencyOptions
                                    ) :
                                        (
                                            DboxDynamicFrequencyOptions
                                        )} */}
                                </Select>
                            </Form.Item>
                            <Form.Item label="复制个数" name="copy" initialValue={0}>
                                <InputNumber min={0} step={1} />
                            </Form.Item>
                        </Col>
                    </Row>
                <div style={{ textAlign: 'right' }}>
                    <Space size="large">
                        <Button type="primary" htmlType="submit" loading={this.state.loading}>保存</Button>
                        <Button onClick={this.props.onCancel} >取消</Button>
                    </Space>
                </div>
                </Form>
            </div >
        )
    }
}
