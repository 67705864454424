import React, { Component } from 'react'
import './device-exception.less';
import dayjs from 'dayjs';
import { deviceException } from '@/interface/exception';

interface IProps {
    deviceExceptions?: deviceException[],
}
export default class DeviceException extends Component<IProps> {
    render() {
        return (
            <div className="device-exception-container">
                <div className="title">异常信息</div>
                <div className="content">
                    {this.props.deviceExceptions.map((item, index) => (
                        <div className="exception-item" key={index}>
                            <div>{item.content}</div>
                            <div>{dayjs(item.create_time).format('MM-DD HH:mm')}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}
