import React, { Component } from 'react'
import { queryGlobalProcedures, queryEnterprisesProcedures, queryGlobalStandards, queryEnterprisesStandards, updateEnterpriseStandard, deleteEnterpriseStandard, createEnterprisesProcedures, deleteEnterpriseProcedure } from '@/config/api/global-standard'
import { inject, observer } from 'mobx-react';
import UserStore from '@/store/user-store';
import axios from '@/config/axios';
import { globalProcedure, globalStandard } from '@/interface/procedure';
import { SourceType } from '@/components/model/section-add';
import { Table, Button, message, Popconfirm, Modal, Space, Row, Col, Tag, Input } from 'antd';
import './global-standard.less';
import { EditableRow, EditableCell } from '@/components';
import StandardAddPanel from './standard-add';
import { TweenOneGroup } from 'rc-tween-one';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
const { Column } = Table;
interface IProps {
    userStore?: UserStore
}

interface IState {
    gProcedures: globalProcedure[],
    gStandards: globalStandard[],
    modalVisible: boolean,

    onManageState: boolean,

    FAtagsInputVisible: boolean,
    FAtagsInputValue: string,
    FAtagsLoading: boolean,

    SBtagsInputVisible: boolean,
    SBtagsInputValue: string,
    SBtagsLoading: boolean,

    SJtagsInputVisible: boolean,
    SJtagsInputValue: string,
    SJtagsLoading: boolean,

    PGtagsInputVisible: boolean,
    PGtagsInputValue: string,
    PGtagsLoading: boolean,
}
@inject('userStore')
@observer
export default class GlobalStandardPage extends Component<IProps, IState> {
    FAtagsInputRef = React.createRef<Input>();
    SBtagsInputRef = React.createRef<Input>();
    SJtagsInputRef = React.createRef<Input>();
    PGtagsInputRef = React.createRef<Input>();
    state = {
        gProcedures: [],
        gStandards: [],
        modalVisible: false,

        onManageState: false,

        FAtagsInputVisible: false,
        FAtagsInputValue: '',
        FAtagsLoading: false,

        SBtagsInputVisible: false,
        SBtagsInputValue: '',
        SBtagsLoading: false,

        SJtagsInputVisible: false,
        SJtagsInputValue: '',
        SJtagsLoading: false,

        PGtagsInputVisible: false,
        PGtagsInputValue: '',
        PGtagsLoading: false,
    }
    enterpriseId: number;

    componentDidMount() {
        let eid = this.props.userStore._enterpriseId;
        this.enterpriseId = eid;
        this.generateData();
    }

    componentDidUpdate() {
        let eid = this.props.userStore._enterpriseId;
        if (eid !== this.enterpriseId) {
            this.enterpriseId = eid;
            this.generateData();
        }
    }

    generateData = () => {
        let eid = this.props.userStore._enterpriseId;
        if (!eid) return;
        let queryGlobalProcedurePromise = queryGlobalProcedures();
        let queryEnterProcedurePromise = queryEnterprisesProcedures(eid);
        let queryGlobalStandardPromise = queryGlobalStandards();
        let queryEnterpriseStandardPromise = queryEnterprisesStandards(eid);

        axios.all([
            queryGlobalProcedurePromise,
            queryEnterProcedurePromise,
            queryGlobalStandardPromise,
            queryEnterpriseStandardPromise])
            .then(res => {
                let gProcedures = res[0].data || [];
                let eProcedures = res[1].data || [];
                gProcedures = gProcedures.map(item => Object.assign(item, { source: SourceType.DB, key: `g${item.id}` }));
                eProcedures = eProcedures.map(item => Object.assign(item, { source: SourceType.CUSTOM, key: `e${item.id}` }));
                let gStandards = res[2].data || [];
                let eStandards = res[3].data || [];
                gStandards = gStandards.map(item => Object.assign(item, { source: SourceType.DB, key: `g${item.id}` }));
                eStandards = eStandards.map(item => Object.assign(item, { source: SourceType.CUSTOM, key: `e${item.id}` }));
                this.setState({
                    gProcedures: [...gProcedures, ...eProcedures],
                    gStandards: [...eStandards, ...gStandards]
                })
            })
    }

    handleSave = (row: globalStandard) => {
        let id = row.id;
        let key = row.key;
        let newData = [...this.state.gStandards];
        let index = newData.findIndex(item => item.key === key);
        let item: globalStandard = newData[index];

        newData.splice(index, 1, { ...item, ...row })
        this.setState({
            gStandards: [...newData]
        })
        let data = {
            name: row.name,
            code: row.code,
            item_number: row.item_number,
            content: row.content,
        }
        updateEnterpriseStandard(this.props.userStore._enterpriseId, id, data)
            .then(res => {

            }).catch(err => {
                message.error('更新失败');
                newData.splice(index, 1, { ...row, ...item })
                this.setState({
                    gStandards: [...newData]
                })
            })
    }

    onDelete = (row) => {
        if (!this.props.userStore._enterpriseId) {
            message.error('发生错误')
            return;
        }
        deleteEnterpriseStandard(this.props.userStore._enterpriseId, row.id)
            .then(res => {
                this.setState({
                    gStandards: this.state.gStandards.filter(item => item.key !== row.key)
                })
            })
    }

    afterSave = () => {
        this.setState({
            modalVisible: false,
        })
        this.generateData();
    }


    handleClose = (removedTag: globalProcedure) => {
        if (removedTag.source === SourceType.DB) {
            return;
        }
        let enterprise_id = this.enterpriseId;
        if (!enterprise_id) return;
        let gProcedures = this.state.gProcedures.filter(tag => tag.id !== removedTag.id);
        this.setState({ gProcedures });
        deleteEnterpriseProcedure(enterprise_id, removedTag.id)
        .then(res => {

        }).catch(err => {
            message.error('删除失败');
            console.log(err);
        })
    };

    showTagsInput = (tag: number) => {
        if (tag === 1) {
            this.setState({ FAtagsInputVisible: true }, () => this.FAtagsInputRef.current.focus())
        }
        if (tag === 2) {
            this.setState({ SBtagsInputVisible: true }, () => this.SBtagsInputRef.current.focus())
        }
        if (tag === 3) {
            this.setState({ SJtagsInputVisible: true }, () => this.SJtagsInputRef.current.focus())
        }
        if (tag === 4) {
            this.setState({ PGtagsInputVisible: true }, () => this.PGtagsInputRef.current.focus())
        }
        // this.setState({ tagsInputVisible: true }, () => this.tagsInputRef.current.focus());
    };

    handleTagsInputChange = (e, tag: number) => {
        if (tag === 1) {
            this.setState({ FAtagsInputValue: e.target.value });
        }
        if (tag === 2) {
            this.setState({ SBtagsInputValue: e.target.value });
        }
        if (tag === 3) {
            this.setState({ SJtagsInputValue: e.target.value });
        }
        if (tag === 4) {
            this.setState({ PGtagsInputValue: e.target.value });
        }
    };

    handleTagsInputConfirm = (tag: number) => {
        let enterprise_id = this.enterpriseId;
        if (!enterprise_id) {
            message.error('发生错误');
            return;
        }
        // 方案
        if (tag === 1) {
            const { FAtagsInputValue } = this.state;
            let { gProcedures } = this.state;

            this.setState({
                FAtagsLoading: true,
                FAtagsInputVisible: false,
            })
            if (FAtagsInputValue && !gProcedures.some(item => item.content === FAtagsInputValue)) {
                let data = {
                    type: tag,
                    content: FAtagsInputValue,
                    enterprise_id: enterprise_id
                }
                createEnterprisesProcedures(enterprise_id, data)
                    .then(res => {
                        const id = res.data;
                        let newTag = { ...data, id: id, source: SourceType.CUSTOM, key: `e${id}` };
                        gProcedures = [...gProcedures, newTag];
                        this.setState({
                            FAtagsLoading: false,
                            gProcedures: gProcedures
                        })
                    })
            } else {
                this.setState({
                    FAtagsLoading: false,
                })
            }
        }
        // 设备安装
        if (tag === 2) {
            const { SBtagsInputValue } = this.state;
            let { gProcedures } = this.state;

            this.setState({
                SBtagsLoading: true,
                SBtagsInputVisible: false,
            })
            if (SBtagsInputValue && !gProcedures.some(item => item.content === SBtagsInputValue)) {
                let data = {
                    type: tag,
                    content: SBtagsInputValue,
                    enterprise_id: enterprise_id
                }
                createEnterprisesProcedures(enterprise_id, data)
                    .then(res => {
                        const id = res.data;
                        let newTag = { ...data, id: id, source: SourceType.CUSTOM, key: `e${id}` };
                        gProcedures = [...gProcedures, newTag];
                        this.setState({
                            SBtagsLoading: false,
                            gProcedures: gProcedures
                        })
                    })
            } else {
                this.setState({
                    SBtagsLoading: false,
                })
            }
        }
        // 数据采集
        if (tag === 3) {
            const { SJtagsInputValue } = this.state;
            let { gProcedures } = this.state;

            this.setState({
                SJtagsLoading: true,
                SJtagsInputVisible: false,
            })
            if (SJtagsInputValue && !gProcedures.some(item => item.content === SJtagsInputValue)) {
                let data = {
                    type: tag,
                    content: SJtagsInputValue,
                    enterprise_id: enterprise_id
                }
                createEnterprisesProcedures(enterprise_id, data)
                    .then(res => {
                        const id = res.data;
                        let newTag = { ...data, id: id, source: SourceType.CUSTOM, key: `e${id}` };
                        gProcedures = [...gProcedures, newTag];
                        this.setState({
                            SJtagsLoading: false,
                            gProcedures: gProcedures
                        })
                    })
            } else {
                this.setState({
                    SJtagsLoading: false,
                })
            }
        }
        // 评估
        if (tag === 4) {
            const { PGtagsInputValue } = this.state;
            let { gProcedures } = this.state;

            this.setState({
                PGtagsLoading: true,
                PGtagsInputVisible: false,
            })
            if (PGtagsInputValue && !gProcedures.some(item => item.content === PGtagsInputValue)) {
                let data = {
                    type: tag,
                    content: PGtagsInputValue,
                    enterprise_id: enterprise_id
                }
                createEnterprisesProcedures(enterprise_id, data)
                    .then(res => {
                        const id = res.data;
                        let newTag = { ...data, id: id, source: SourceType.CUSTOM, key: `e${id}` };
                        gProcedures = [...gProcedures, newTag];
                        this.setState({
                            PGtagsLoading: false,
                            gProcedures: gProcedures
                        })
                    })
            } else {
                this.setState({
                    PGtagsLoading: false,
                })
            }
        }
    };

    render() {
        let eid = this.props.userStore._enterpriseId;
        let { onManageState, gProcedures } = this.state;
        return (
            <div className="global-standard-container">
                <div style={{ display: 'none' }}>{eid}</div>
                <div className="procedure-list">
                    <div className="title">监测技术点内容</div>
                    <TweenOneGroup
                        enter={{
                            scale: 1,
                            opacity: 0,
                            type: 'from',
                            duration: 300,
                        }}
                        leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                        appear={false}>
                        <Row gutter={[10, 10]}>
                            <Col span={6}>
                                <div className="procecure-pane">
                                    <div className="header">
                                        <div className="title">方案</div>
                                    </div>
                                    <div>
                                        {gProcedures.filter(p => p.type === 1).map(p => (
                                            <Tag
                                                style={{ marginBottom: '10px' }}
                                                key={p.key}
                                                color={p.source === SourceType.CUSTOM ? "geekblue" : "default"}
                                                closable={p.source === SourceType.CUSTOM}
                                                onClose={() => this.handleClose(p)}>
                                                {p.content}
                                            </Tag>
                                        ))}
                                        {this.state.FAtagsInputVisible && (
                                            <Input
                                                ref={this.FAtagsInputRef}
                                                type="text"
                                                size="small"
                                                style={{ width: 90 }}
                                                value={this.state.FAtagsInputValue}
                                                onChange={(e) => this.handleTagsInputChange(e, 1)}
                                                onBlur={() => this.handleTagsInputConfirm(1)}
                                                onPressEnter={() => this.handleTagsInputConfirm(1)}
                                            />
                                        )}
                                        {!this.state.FAtagsInputVisible && (
                                            <Tag
                                                onClick={() => this.showTagsInput(1)}
                                                className="site-tag-plus"
                                                icon={this.state.FAtagsLoading ? <SyncOutlined spin /> : null}>
                                                <PlusOutlined /> 新增
                                            </Tag>
                                        )}
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className="procecure-pane">
                                    <div className="header">
                                        <div className="title">设备安装</div>
                                    </div>
                                    <div>
                                    {gProcedures.filter(p => p.type === 2).map(p => (
                                            <Tag
                                                style={{ marginBottom: '10px' }}
                                                key={p.key}
                                                color={p.source === SourceType.CUSTOM ? "geekblue" : "default"}
                                                closable={p.source === SourceType.CUSTOM}
                                                onClose={() => this.handleClose(p)}>
                                                {p.content}
                                            </Tag>
                                        ))}
                                        {this.state.SBtagsInputVisible && (
                                            <Input
                                                ref={this.SBtagsInputRef}
                                                type="text"
                                                size="small"
                                                style={{ width: 90 }}
                                                value={this.state.SBtagsInputValue}
                                                onChange={(e) => this.handleTagsInputChange(e, 2)}
                                                onBlur={() => this.handleTagsInputConfirm(2)}
                                                onPressEnter={() => this.handleTagsInputConfirm(2)}
                                            />
                                        )}
                                        {!this.state.SBtagsInputVisible && (
                                            <Tag
                                                onClick={() => this.showTagsInput(2)}
                                                className="site-tag-plus"
                                                icon={this.state.SBtagsLoading ? <SyncOutlined spin /> : null}>
                                                <PlusOutlined /> 新增
                                            </Tag>
                                        )}
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className="procecure-pane">
                                    <div className="header">
                                        <div className="title">数据采集</div>
                                    </div>
                                    <div>
                                    {gProcedures.filter(p => p.type === 3).map(p => (
                                            <Tag
                                                style={{ marginBottom: '10px' }}
                                                key={p.key}
                                                color={p.source === SourceType.CUSTOM ? "geekblue" : "default"}
                                                closable={p.source === SourceType.CUSTOM}
                                                onClose={() => this.handleClose(p)}>
                                                {p.content}
                                            </Tag>
                                        ))}
                                        {this.state.SJtagsInputVisible && (
                                            <Input
                                                ref={this.SJtagsInputRef}
                                                type="text"
                                                size="small"
                                                style={{ width: 90 }}
                                                value={this.state.SJtagsInputValue}
                                                onChange={(e) => this.handleTagsInputChange(e, 3)}
                                                onBlur={() => this.handleTagsInputConfirm(3)}
                                                onPressEnter={() => this.handleTagsInputConfirm(3)}
                                            />
                                        )}
                                        {!this.state.SJtagsInputVisible && (
                                            <Tag
                                                onClick={() => this.showTagsInput(3)}
                                                className="site-tag-plus"
                                                icon={this.state.SJtagsLoading ? <SyncOutlined spin /> : null}>
                                                <PlusOutlined /> 新增
                                            </Tag>
                                        )}
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className="procecure-pane">
                                    <div className="header">
                                        <div className="title">评估</div>
                                    </div>
                                    <div>
                                    {gProcedures.filter(p => p.type === 4).map(p => (
                                            <Tag
                                                style={{ marginBottom: '10px' }}
                                                key={p.key}
                                                color={p.source === SourceType.CUSTOM ? "geekblue" : "default"}
                                                closable={p.source === SourceType.CUSTOM}
                                                onClose={() => this.handleClose(p)}>
                                                {p.content}
                                            </Tag>
                                        ))}
                                        {this.state.PGtagsInputVisible && (
                                            <Input
                                                ref={this.PGtagsInputRef}
                                                type="text"
                                                size="small"
                                                style={{ width: 90 }}
                                                value={this.state.PGtagsInputValue}
                                                onChange={(e) => this.handleTagsInputChange(e, 4)}
                                                onBlur={() => this.handleTagsInputConfirm(4)}
                                                onPressEnter={() => this.handleTagsInputConfirm(4)}
                                            />
                                        )}
                                        {!this.state.PGtagsInputVisible && (
                                            <Tag
                                                onClick={() => this.showTagsInput(4)}
                                                className="site-tag-plus"
                                                icon={this.state.PGtagsLoading ? <SyncOutlined spin /> : null}>
                                                <PlusOutlined /> 新增
                                            </Tag>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </TweenOneGroup>

                </div>

                <div className="standard-list">
                    <div className="header">
                        <div className="title">规范依据条目</div>
                        <div className="fill-remaining-space"></div>
                        {!onManageState && (
                            <div style={{ textAlign: 'right' }}>
                                <Space size="large">
                                    <Button onClick={() => this.setState({ onManageState: true })}>编辑</Button>
                                    <Button type="primary" onClick={() => this.setState({ modalVisible: true })}>添加</Button>
                                </Space>
                            </div>
                        )}
                        {onManageState && (
                            <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                                <Space size="large" >
                                    <span style={{ fontSize: '12px' }}>* 可编辑自定义内容</span>
                                    <Button onClick={() => this.setState({ onManageState: false })}>完成</Button>
                                </Space>
                            </div>
                        )}
                    </div>
                    <Table
                        dataSource={this.state.gStandards}
                        pagination={false}
                        rowKey="key"
                        components={{
                            body: {
                                row: EditableRow,
                                cell: EditableCell,
                            }
                        }}
                        rowClassName={(record, index) => (
                            'editable-row'
                        )}
                    >
                        <Column
                            title="名称"
                            key="name"
                            width="20%"
                            onCell={(record: globalStandard, idx) => ({
                                record,
                                editable: record.source === SourceType.CUSTOM && onManageState,
                                dataIndex: 'name',
                                title: '名称',
                                handleSave: this.handleSave,
                            })}
                            render={(record, idx) => (
                                record.source === SourceType.DB ? (
                                    <div className="display-cell-wrap">{record.name}</div>
                                ) :
                                    record.name
                            )}
                        />
                        <Column
                            title="编号"
                            key="code"
                            width="10%"
                            onCell={(record: globalStandard, idx) => ({
                                record,
                                editable: record.source === SourceType.CUSTOM && onManageState,
                                dataIndex: 'code',
                                title: '编号',
                                handleSave: this.handleSave,
                            })}
                            render={(record, idx) => (
                                record.source === SourceType.DB ? (
                                    <div className="display-cell-wrap">{record.code}</div>
                                ) :
                                    record.code
                            )}
                        />
                        <Column
                            title="条文号"
                            key="item_number"
                            width="10%"
                            onCell={(record: globalStandard, idx) => ({
                                record,
                                editable: record.source === SourceType.CUSTOM && onManageState,
                                dataIndex: 'item_number',
                                title: '条文号',
                                handleSave: this.handleSave,
                            })}
                            render={(record, idx) => (
                                record.source === SourceType.DB ? (
                                    <div className="display-cell-wrap">{record.item_number}</div>
                                ) :
                                    record.item_number
                            )}
                        />
                        <Column
                            title="内容描述"
                            ellipsis={true}
                            key="content"
                            onCell={(record: globalStandard, idx) => ({
                                record,
                                editable: record.source === SourceType.CUSTOM && onManageState,
                                dataIndex: 'content',
                                title: '内容描述',
                                handleSave: this.handleSave,
                            })}
                            render={(record, idx) => (
                                record.source === SourceType.DB ? (
                                    <div className="display-cell-wrap">{record.content}</div>
                                ) :
                                    record.content
                            )}
                        />
                        <Column title="来源" dataIndex="source" width="5%" render={(record, idx) => record === SourceType.DB ? '数据库' : '自定义'} />
                        <Column title="" key="operation" width="10%" render={record => (
                            record.source === SourceType.CUSTOM && (
                                <Popconfirm title="确定删除吗?" okText="是" cancelText="否" onConfirm={() => this.onDelete(record)}>
                                    <Button size="small" type="link">删除</Button>
                                </Popconfirm>
                            )
                        )} />
                    </Table>
                </div>


                <Modal
                    visible={this.state.modalVisible}
                    footer={null}
                    title="添加规范依据"
                    destroyOnClose
                    onCancel={() => this.setState({ modalVisible: false })}>
                    <StandardAddPanel
                        enterpriseId={eid}
                        onCancel={() => this.setState({ modalVisible: false })}
                        afterSave={this.afterSave} />
                </Modal>
            </div>
        )
    }
}
