import { updateSensor } from '@/config/api/sensor'
import { ProjectContext } from '@/config/context'
import { Gprops } from '@/config/props'
import { sensor } from '@/interface/sensor'
import SensorStore from '@/store/sensor-store'
import { Button, Form, message, Space } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { NumericInput } from '..'


interface IProps {
    sensorDetail: sensor
    afterSave?: (values: any) => void
    sensorStore?: SensorStore
}

interface IState {
    isEdit: boolean,
    saveLoading: boolean
}

@inject('sensorStore')
@observer
export default class SensorFiberParamsPane extends Component<IProps, IState> {
    static contextType = ProjectContext;
    formRef = React.createRef<FormInstance>();
    sensorId: number;
    state = {
        isEdit: false,
        saveLoading: false,
    }

    componentDidMount() {
        this.initialForm();
    }

    componentDidUpdate() {
        let sensorId = this.props.sensorDetail?.id;
        if (this.sensorId !== sensorId) {
            this.sensorId = sensorId;
            this.initialForm();
        }

    }

    initialForm = () => {
        this.formRef.current?.setFieldsValue({
            lambda1: this.props.sensorDetail.lambda1 || 0,
            lambda2: this.props.sensorDetail.lambda2 || 0,
        })
    }

    onSave = (values) => {
        let sensorDetail = this.props.sensorDetail;
        if (!sensorDetail || !sensorDetail.id) {
            message.error('发生错误');
            return;
        }
        this.setState({
            saveLoading: true,
        })
        let data = {
            lambda1: values.lambda1,
            lambda2: values.lambda2,
        }
        updateSensor(sensorDetail.id, data).then(r => {
            if (this.props.afterSave) {
                this.props.afterSave(values);
            }
            this.setState({
                saveLoading: false,
                isEdit: false,
            })
        }).catch(err => {
            message.error('更新失败');
            console.log(err);
            this.setState({
                saveLoading: false,
            })
        });
    }

    onCancel = () => {
        this.initialForm();
        this.setState({
            isEdit: false
        })
    }

    render() {
        let {isEdit} = this.state;
        return (
            <div>
                <Form size="small" labelCol={{ span: 5 }} wrapperCol={{ span: 14 }} ref={this.formRef} onFinish={this.onSave}>
                    <Form.Item label="波长1" name="lambda1">
                        <NumericInput readOnly={!isEdit} suffix={<span>nm</span>} />
                    </Form.Item>
                    <Form.Item label="波长2" name="lambda2">
                        <NumericInput readOnly={!isEdit} suffix={<span>nm</span>} />
                    </Form.Item>
                    {isEdit && (
                        <div>
                            <Space>
                                <Button type="primary" htmlType="submit" loading={this.state.saveLoading}>保存</Button>
                                <Button onClick={this.onCancel}>取消</Button>
                            </Space>
                        </div>
                    )}
                    {!isEdit && (
                        <Space size="middle">
                            <Button type="primary" onClick={() => this.setState({ isEdit: true })}>编辑</Button>
                        </Space>
                    )}
                </Form>
            </div>
        )
    }
}
