import React, { Component, CSSProperties } from 'react'

interface IProps {
    title: string
    style?: CSSProperties
}
export default class InfoBoardShell extends Component<IProps> {
    render() {
        return (
            <div className="project-info-board" style={this.props.style}>
                <div className="header">
                    <div className="title">{this.props.title}</div>
                </div>
                <div className="content">
                    {this.props.children}
                </div>
            </div>
        )
    }
}
