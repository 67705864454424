import { observable, action } from "mobx";


class QuantityDetailStore {
    @observable _dataType: number;
    @action setDataType = (value: number) => {
        this._dataType = value;
    }

    @observable _samplingPeriod: number;
    @action setSamplingPeriod = (value: number) => {
        this._samplingPeriod = value;
    }

    @observable _startTime: Date;
    @action setStartTime = (value: Date) => {
        this._startTime = value;
    }

    @observable _endTime: Date;
    @action setEndTime = (value: Date) => {
        this._endTime = value;
    }

    @observable _seletedConstageIdList: number[];
    @action setConstageIdList = (value: number[]) => {
        this._seletedConstageIdList = [...value];
    }

    @observable _forceTableRender: boolean;
    @action setForceTableRender = (value: boolean) => {
        this._forceTableRender = value;
    }
}

export default QuantityDetailStore;