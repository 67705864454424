import React, { Component } from 'react'
import { queryEnterprises } from '@/config/api/enterprise'
import { enterprise } from '@/interface/enterprise'
import { user } from '@/interface/user'
import { queryUsers, createUsers } from '@/config/api/user'
import axios from '@/config/axios'
import './backend-users.less';
import { Space, Button, Table, Form, Input, Select, message } from 'antd'
import { EditableRow, EditableCell } from '@/components'
import dayjs from 'dayjs'
import { UserTypes } from '@/utils/type'
import CryptoJS from 'crypto-js';
const { Column } = Table;
const { Option } = Select;
interface IProps {

}
interface IState {
    enterprises: enterprise[]
    users: user[]
    isAddState: boolean
    saveLoading: boolean
}
export default class BackendUserListPage extends Component<IProps, IState> {

    state = {
        enterprises: [],
        users: [],
        isAddState: false,
        saveLoading: false,
    }

    componentDidMount() {
        this.generateData();
    }

    generateData = () => {
        queryEnterprises()
            .then(res => {
                let enterprises = res.data || [];
                this.setState({
                    enterprises: enterprises
                })
                let promiseList = enterprises.map(item => queryUsers({
                    enterprise_id: item.id
                }));
                return axios.all([...promiseList]);
            }).then(res => {
                let users = [];
                res.forEach((item, idx) => {
                    let data = item.data;
                    let ep = this.state.enterprises[idx];
                    users = [...users, ...data.map(user => ({...user, enterprise_name: ep?.name || ''}))];
                })
                this.setState({
                    users: users
                })
            })
    }

    onSave = (values) => {
        this.setState({
            saveLoading: true,
        })
        let data = {...values, confirm_password: null};
        let password = CryptoJS.MD5(values.password).toString();
        data.password = password;
        createUsers(data)
        .then(res => {
            if (res.data === -1) {
                message.error('邮箱或电话已经被注册');
                this.setState({
                    saveLoading: false,
                })
                return;
            }
            this.setState({
                saveLoading: false,
                isAddState: false,
            })
            this.generateData();
        }).catch(err => {
            message.error('添加失败');
            console.log(err);
            this.setState({
                saveLoading: false,
            })
        })
    }
    render() {
        let { isAddState } = this.state;
        return (
            <div className="backend-users-container">
                <div className="page-header">
                    {!isAddState && (
                        <Space>
                            <Button type="primary" onClick={() => this.setState({ isAddState: true })}>添加</Button>
                        </Space>
                    )}
                </div>
                {isAddState && (
                    <div className="user-add">
                        <Form onFinish={this.onSave} labelCol={{ span: 2 }} wrapperCol={{ span: 10 }} autoComplete="off">
                            <Form.Item label="用户名" name="username" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="密码" name="password" rules={[{ required: true }]}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                label="确认密码"
                                name="confirm_password"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入确认密码',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('两次密码不一致');
                                        },
                                    }),
                                ]}
                                dependencies={['password']}
                                hasFeedback>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item label="类别" name="type">
                                <Select>
                                    <Option value={0}>系统root</Option>
                                    <Option value={1}>admin</Option>
                                    <Option value={2}>工程师</Option>
                                    <Option value={3}>现场</Option>
                                    <Option value={4}>客户</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="姓名" name="name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="所属企业" name="enterprise_id" rules={[{ required: true }]}>
                                <Select>
                                    {this.state.enterprises.map(item => (
                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="电话" name="mobile">
                                <Input />
                            </Form.Item>
                            <Form.Item label="邮箱" name="email">
                                <Input />
                            </Form.Item>
                            <Form.Item label="职位" name="duty">
                                <Input />
                            </Form.Item>
                            <Form.Item label="头衔" name="title">
                                <Input />
                            </Form.Item>
                            <div className="operations">
                                <Space>
                                    <Button type="primary" htmlType="submit" loading={this.state.saveLoading}>保存</Button>
                                    <Button onClick={() => this.setState({ isAddState: false })}>取消</Button>
                                </Space>
                            </div>
                        </Form>
                    </div>

                )}

                <div className="main">
                    <Table
                        rowKey="id"
                        pagination={false}
                        components={{
                            body: {
                                row: EditableRow,
                                cell: EditableCell,
                            }
                        }}
                        rowClassName={(record, index) => (
                            'editable-row'
                        )}
                        dataSource={this.state.users}>
                        <Column title="id" dataIndex="id" />
                        <Column title="用户名" dataIndex="username" />
                        <Column title="类别" dataIndex="type" render={record => UserTypes[record]}/>
                        <Column title="姓名" dataIndex="name" />
                        <Column title="企业" dataIndex="enterprise_name" />
                        <Column title="电话" dataIndex="mobile" />
                        <Column title="邮箱" dataIndex="email" />
                        <Column title="地址" dataIndex="address" />
                        <Column title="上次登录" dataIndex="last_login_time" render={record => dayjs(record).format('YYYY-MM-DD HH:mm')} />
                    </Table>
                </div>
            </div>
        )
    }
}
