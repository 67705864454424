import React, { Component } from 'react'
import { Form, Space, Button, Select } from 'antd'
import { element } from '@/interface/model'
import { ProjectContext } from '@/config/context';
import { FormInstance } from 'antd/lib/form';
import { NumericInput } from '..';
import { createElement, queryElements } from '@/config/api/model';

const { Option } = Select;
interface IProps {
    afterSave?: (value) => void
    onCancelClick?: () => void
}
interface IState {
    elementList: element[],
}

export default class ElementAddPanel extends Component<IProps, IState>{
    static contextType = ProjectContext;
    formRef = React.createRef<FormInstance>();
    state = {
        elementList: [],
    }





    findLastElement = () => {
        queryElements(this.context)
            .then(res => {
                this.setState({
                    elementList: res.data?.data,
                })
                var max = this.state.elementList[0]?.sid || 0;
                for (var i = 0; i < this.state.elementList.length; i++) {
                    var cur = this.state.elementList[i].sid;
                    max = max < cur ? cur : max;
                }
                this.formRef.current?.setFieldsValue({
                    sid: max + 1,
                })
            })
    }

    onSave = (value) => {
        let projectId = this.context;
        let data = value;
        // let sid = this.state.total + 1
        createElement(projectId, data)
            .then(res => {
                this.props.afterSave(Object.assign(value, {
                    id: res.data,
                    project_id: projectId
                }));
            })

    }
    componentDidMount() {

        this.findLastElement();
    }

    render() {
        return (
            <div>
                <Form onFinish={this.onSave} autoComplete="off" ref={this.formRef} labelCol={{ span: 5 }}>
                    <Form.Item name="sid" label="单元号"
                        rules={[{ required: true, message: '单元号不能为空' }]}>
                        <NumericInput />
                    </Form.Item>
                    <Form.Item name="type" label="类别"
                        rules={[{ required: true, message: '类别不能为空' }]}>
                        <Select>
                            <Option value={1}>梁单元</Option>
                            <Option value={2}>杆单元</Option>
                            <Option value={3}>索单元</Option>
                            <Option value={4}>板单元</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="node_1_sid" label="节点1"
                        rules={[{ required: true, message: '节点1不能为空' }]}>
                        <NumericInput />
                    </Form.Item>
                    <Form.Item name="node_2_sid" label="节点2"
                        rules={[{ required: true, message: '节点2不能为空' }]}>
                        <NumericInput />
                    </Form.Item>
                    <Form.Item name="node_3_sid" label="节点3"
                        rules={[{ required: true, message: '节点3不能为空' }]}>
                        <NumericInput />
                    </Form.Item>
                    <Form.Item name="node_4_sid" label="节点4"
                        rules={[{ required: true, message: '节点4不能为空' }]}>
                        <NumericInput />
                    </Form.Item>
                    <Form.Item name="section_sid" label="截面号"
                        rules={[{ required: true, message: '截面号不能为空' }]}>
                        <NumericInput />
                    </Form.Item>
                    <Form.Item name="material_sid" label="材料号"
                        rules={[{ required: true, message: '材料号不能为空' }]}>
                        <NumericInput />
                    </Form.Item>
                    <div style={{ textAlign: 'right', marginTop: '24px' }}>
                        <Space size="large">
                            <Button onClick={this.props.onCancelClick} >取消</Button>
                            <Button type="primary" htmlType="submit">保存</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        )
    }
}
