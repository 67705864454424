import React, { Component, Fragment } from 'react'
import { withRoutePane } from '@/components/hoc/routePane'
import { ProjectContext } from '@/config/context';
import { queryMobjects } from '@/config/api/mobject';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import { queryWarnings, sendWarningMessage } from '@/config/api/warning';
import axios from '@/config/axios';
import { warning } from '@/interface/warning';
import { mobject } from '@/interface/mobject';
import { globalQuantity, quantity } from '@/interface/quantity';
import { Transfer2DisplayScale, NumFixed } from '@/utils/utils';
import { Table, Button, Space, message, Modal, Spin } from 'antd';
import classnames from 'classnames';
import { MobjectBaseType, warningGradeDict } from '@/utils/type';
import { Gprops } from '@/config/props';
import Column from 'antd/lib/table/Column';
import dayjs from 'dayjs';
import './project-warning.less';
import { QuantityName } from '@/components/QuantityName';
import AlertTag from '@/components/alert-tag';
import { queryQuantityDetail } from '@/config/api/quantity';
import { queryDrawings, queryMobjectsByDrawingId } from '@/config/api/constage';
import { drawing } from '@/interface/constage';
import { RightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { queryProjectDetail } from '@/config/api/project';
import { project } from '@/interface/project';
const { confirm } = Modal;
interface IProps extends Gprops {

}

interface IState {
    warnings: warning[],
    warningLoading: boolean,
    sendMsgLoading: boolean,
    monitorType: number,
}
class ProjectWarningPage extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        warnings: [],
        warningLoading: false,
        sendMsgLoading: false,
        monitorType: 2,
    }

    componentDidMount() {

        const projectId = this.context;
        queryProjectDetail(projectId).then(res => {
            let projectInfo: project = res.data;
            this.setState({
                monitorType: projectInfo?.monitor_type || 2,
            })
        })
        
        this.generateData();
    }

    generateData = () => {
        const projectId = this.context;
        this.setState({
            warningLoading: true,
        })
        let queryMobjectsPromise = queryMobjects({
            project_id: projectId,
        });
        let queryPQuantitiesPromise = queryProjectGlobalQuantities(projectId);
        let queryWarningPromise = queryWarnings({
            project_id: projectId,
        });
        axios.all([queryMobjectsPromise, queryPQuantitiesPromise, queryWarningPromise])
            .then(res => {
                let mobjects: mobject[] = res[0].data || [];
                let pQuantities = res[1].data || [];
                let warnings: warning[] = res[2].data || [];
                warnings.forEach(item => {
                    item.name = item.quantity_name;
                    let m = mobjects.find(x => x.id === item.monitor_object_id);
                    let pq: globalQuantity = pQuantities.find(x => x.name === item.quantity_name);
                    if (!!m) {
                        item.mobject_name = m.name;
                        item.mobject_type = m.type;
                    }
                    if (!!pq) {
                        item.precision = pq.precision;
                        item.unit_type = pq.unit_type;
                        item.chinese_name = pq.chinese_name;
                        let scale = Transfer2DisplayScale(item.unit_type);
                        item.scale = scale;
                        item.value = NumFixed(scale * item.value, 8);
                    }
                })
                this.setState({
                    warnings: warnings,
                    warningLoading: false,
                })
            })
    }

    goToMobjectDetail = (record: warning) => {
        let quantityId = record.quantity_id;
        queryQuantityDetail(this.context, quantityId)
            .then(res => {
                let q: quantity = res.data;
                if (!q) return;
                let groupId = q.group_id;
                let mobjectId = record.monitor_object_id;
                let typeName = MobjectBaseType[record.mobject_type]['name'];
                let url = `/projects/${this.context}/mobjects/${typeName}/${mobjectId}?tab=2&group=${groupId}`;
                this.props.history.push(url);
            })

    }

    gotoDrawingDetail = (e, record: warning) => {
        e.stopPropagation();
        const projectId = this.context;
        let mobjectId = record.monitor_object_id;
        let drawings: drawing[] = [];
        this.setState({
            warningLoading: true,
        })
        queryDrawings(projectId)
            .then(res => {
                drawings = res.data || [];
                let promiseList = drawings.map(item => queryMobjectsByDrawingId(projectId, item.id));
                return axios.all([...promiseList]);
            }).then(res => {
                for (let idx = 0; idx < res.length; idx++) {
                    let dra = drawings[idx];
                    let mobjects: mobject[] = res[idx].data || [];
                    let m = mobjects.find(mob => mob.id === mobjectId);
                    if (!!m) {
                        let url = `/projects/${this.context}/drawings?sid=${dra.id}`;
                        this.props.history.push(url);
                        break;
                    }
                }
                this.setState({
                    warningLoading: false,
                })
            }).catch(err => {
                this.setState({
                    warningLoading: false,
                })
            })
    }

    sendWarningMsg = (warningId: number) => {
        const projectId = this.context;
        this.setState({
            sendMsgLoading: true,
        })
        sendWarningMessage(warningId, projectId)
            .then(res => {
                if (!!res.data) {
                    message.success('发送成功!');
                } else {
                    message.error('发送失败');
                }

            }).catch(err => {
                message.error('发送失败');
            }).finally(() => {
                this.setState({
                    sendMsgLoading: false,
                })
            })
    }

    showSendMessageConfirm = (record: warning) => {
        confirm({
            title: '确认发送报警信息吗?',
            icon: <ExclamationCircleOutlined />,
            content: `${record.mobject_name}.${record.quantity_name}.${record.location}, 
            数值：${record.value.toFixed(+record.precision)}${record.unit_type}, 
            等级: ${record.warning_grade}`,
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk: () => this.sendWarningMsg(record.id),
        });
    }

    render() {
        let { warnings, warningLoading } = this.state;
        return (
            <div className="project-warning-container">
                <Spin spinning={this.state.sendMsgLoading}>
                    <div>

                    </div>
                    <div>
                        <Table
                            dataSource={warnings}
                            loading={warningLoading}
                            rowKey="id"
                            rowClassName={(record, index) => classnames({
                                'ribbon-row': index % 2 !== 0,
                                'row-click': true,
                            })}
                            // onRow={record => {
                            //     return {
                            //         onClick: () => this.goToMobjectDetail(record),
                            //     }
                            // }}
                            pagination={warnings.length < 30 ? false : ({
                                pageSize: 30
                            })}
                        >
                            <Column title="时间" dataIndex="gmt_create" width="10%" render={record => dayjs(record).format('YYYY-MM-DD HH:mm:ss')} />
                            <Column
                                title="监测对象"
                                key="mobject_name"
                                align="center"
                                width={250}
                                render={(record: warning) => (
                                    <Fragment>
                                        <span style={{ marginRight: '4px' }}>{record.mobject_name}</span>
                                        <Button className="locale-btn" size="small" type="link" onClick={(e) => this.gotoDrawingDetail(e, record)}>位置信息</Button>
                                    </Fragment>
                                )}
                            />
                            <Column title="指标" key="quantity_name" width="10%" render={(record: warning) => (
                                <QuantityName record={record} />
                            )} />
                            <Column
                                title="数值"
                                key="value"
                                align="right"
                                render={(record: warning) => (
                                    `${record.value.toFixed(+record.precision)}`
                                )} />
                            <Column
                                title="单位"
                                key="unit"
                                align="center"
                                render={(record: warning) => (
                                    `${record.unit_type}`
                                )} />
                            <Column
                                title="报警等级"
                                key="warning_grade"
                                align="center"
                                width="10%"
                                render={(record: warning) => (
                                    <AlertTag current_grade={record.warning_grade} />
                                )} />
                            <Column title="响应" key="current_grade_react" align="left" render={(record: warning) => {
                                let desp = warningGradeDict[record.warning_grade || 0]['description'] || '';
                                let desp_use = warningGradeDict[record.warning_grade || 0]['description_use'] || '';
                                if (this.state.monitorType === 2) return desp;
                                return desp_use;
                                // warningGradeDict[record.warning_grade || 0]['description'] || ''
                            }} />
                            <Column title="" key="operations" align="center" render={(record: warning) => (
                                <Space size="large">
                                    <Button type="link" size="small" onClick={() => this.showSendMessageConfirm(record)}>发送报警</Button>
                                    <Button size="small" type="link" onClick={() => this.goToMobjectDetail(record)}>
                                        详情<RightOutlined />
                                    </Button>
                                </Space>

                            )} />
                        </Table>
                    </div>
                </Spin>

            </div>
        )
    }
}

export default withRoutePane(ProjectWarningPage, '历史报警');