import React, { Component, Fragment } from 'react'
import { withRoutePane } from '../../components/hoc/routePane'
import './material-page.less';
import { material } from '@/interface/model';
import { Gprops } from '@/config/props';
import { Button, Space, Table, Popconfirm, message } from 'antd';
import axios from '@/config/axios';
import { deleteMaterial, queryMaterials } from '@/config/api/model';
import { ProjectContext } from '@/config/context';
import { SourceType } from '@/components/model/section-add';
import classnames from 'classnames';
import MaterialAdd from '@/components/model/material-add';
const { Column } = Table;
interface IProps extends Gprops {

}
interface IState {
    materialList: material[],
    loading: boolean
    multiDeleteLoading: boolean
    onMultiDeleteState: boolean
    materialIdToDelete: number[]
    onModalVisible: boolean
    isAdding: boolean
}

class MaterialPage extends Component<IProps, IState> {
    static contextType = ProjectContext;

    state = {
        materialList: [],
        loading: false,
        multiDeleteLoading: false,
        onMultiDeleteState: false,
        materialIdToDelete: [],
        onModalVisible: false,
        isAdding: false,
    }
    
    componentDidMount() {
        this.findMaterials();
    }

    findMaterials = () => {
        this.setState({
            loading: true,
        })
        queryMaterials(this.context, {
            // page: this.state.page,
            // size: this.state.size,
        }).then(res => {
            this.setState({
                materialList: res.data,
            })
        }).finally(() => {
            this.setState({
                loading: false,
            })
        })
    }

    handleDelete = (e, values) => {
        e.stopPropagation();
        deleteMaterial(this.context, values.id)
            .then(res => {
                this.setState({
                    materialList: this.state.materialList.filter(item => item.id !== values.id)
                })
            }).catch(err => {
                message.error('删除失败')
            })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        // console.log(selectedRowKeys)
        this.setState({
            materialIdToDelete: [...selectedRowKeys]
        })
    }

    onMultiDeleteCancel = () => {
        this.setState({
            materialIdToDelete: [],
            onMultiDeleteState: false,
        })
    }

    onMultiDeleteConfirm = () => {
        if (this.state.materialIdToDelete.length === 0) {
            return;
        }
        this.setState({
            multiDeleteLoading: true,
        })
        let deletePromiseList = this.state.materialIdToDelete.map(id => deleteMaterial(this.context, id))
        axios.all([...deletePromiseList])
            .then(res => {
                this.setState({
                    materialIdToDelete: [],
                    onMultiDeleteState: false,
                })
                this.findMaterials();
            }).finally(() => {
                this.setState({
                    multiDeleteLoading: false,
                })
            })
    }
    
    afterMaterialAdd = () => {
        this.findMaterials();
        this.setState({
            isAdding: false
        })
    }

    render() {
        let { isAdding } = this.state;
        if (isAdding) {
            return (
                <MaterialAdd 
                onCancel={() => this.setState({isAdding: false})}
                afterSave={this.afterMaterialAdd}/>
            )
        }
        return (
            <div className="material-page-container">
                <div className="header">
                    {!this.state.onMultiDeleteState && (
                        <Fragment>
                            {/* <Space>
                                <Button shape="round">导入</Button>
                                <Button shape="round">导出</Button>

                            </Space> */}
                            <Space style={{ marginLeft: '24px' }}>
                                <Button type="primary" onClick={() => this.setState({ isAdding: true })}>添加</Button>
                                <Button danger onClick={() => { this.setState({ onMultiDeleteState: true }) }}>批量删除</Button>
                            </Space>
                        </Fragment>
                    )}
                    {this.state.onMultiDeleteState && (
                        <Space size="large" style={{ marginLeft: '24px' }}>
                            <Button type="primary" onClick={this.onMultiDeleteConfirm} loading={this.state.multiDeleteLoading}>确定</Button>
                            <Button onClick={this.onMultiDeleteCancel}>取消</Button>
                        </Space>
                    )}
                </div>

                <Table
                    rowSelection={this.state.onMultiDeleteState && {
                        type: 'checkbox',
                        onChange: this.onSelectChange,
                        selectedRowKeys: this.state.materialIdToDelete
                    }}
                    dataSource={this.state.materialList}
                    rowKey="id"
                    loading={this.state.loading}
                    pagination={false}
                    rowClassName={(record, index) => (
                        classnames({
                            'ribbon-row': index % 2 !== 0,
                            'select-row': !this.state.onMultiDeleteState
                        })
                    )}
                    onRow={record => {
                        return {
                            onClick: _ => {
                                if (this.state.onMultiDeleteState) return;
                                this.props.history.push(`${this.props.match.url}/sid/${record.sid}`);
                            },
                        }
                    }}>
                    <Column title="材料号" dataIndex="sid"  width='20%'/>
                    <Column title="类型" dataIndex="type" width='20%' render={record => record} />
                    <Column title="名称" dataIndex="name"/>
                    <Column title="名称" dataIndex="source" render={(record) => record === SourceType.DB ? '数据库' : '自定义'}/>
                    <Column title="操作" key="operations" align="center" width={150} render={record => (
                        <Space style={{zIndex: 2}}>
                            <Popconfirm title="确认删除" onCancel={e => e.stopPropagation()} onConfirm={(e) => this.handleDelete(e, record)}>
                                <Button type="link" size="small" onClick={(e) => e.stopPropagation()} disabled={this.state.onMultiDeleteState}>删除</Button>
                            </Popconfirm>
                        </Space>
                    )} />
                </Table>
            </div>
        )
    }
}

export default withRoutePane(MaterialPage, '材料列表');