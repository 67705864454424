import React, { Component } from 'react'
import { Form, Input, Space, Button, message } from 'antd'
import { createEnterprisesStandards } from '@/config/api/global-standard'

interface IProps {
    onCancel?: () => void
    afterSave?: () => void
    enterpriseId: number
}

interface IState {
    loading: boolean
}

export default class StandardAddPanel extends Component<IProps, IState> {
    state = {
        loading: false,
    }
    onSave = (values) => {
        this.setState({
            loading: true,
        })
        let data = {...values, enterprise_id: this.props.enterpriseId}
        createEnterprisesStandards(this.props.enterpriseId, data)
        .then(res => {
            this.setState({
                loading: false,
            })
            this.props.afterSave();
        }).catch(err => {
            message.error('创建失败');
            console.log(err);
            this.setState({
                loading: false,
            })
        })
    }

    render() {
        return (
            <div>
                <Form autoComplete="off" labelCol={{ span: 5 }} onFinish={this.onSave}>
                    <Form.Item label="名称" name="name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="编号" name="code" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="条文号" name="item_number" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="内容描述" name="content" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <div style={{ textAlign: 'right', marginTop: '24px' }}>
                        <Space size="large">
                            <Button onClick={this.props.onCancel} >取消</Button>
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>保存</Button>
                        </Space>
                    </div>
                </Form>


            </div>
        )
    }
}
