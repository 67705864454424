import { observable, action } from "mobx";
import { v4 as uuidv4 } from 'uuid';

interface TabObj {
    title: string,
    key: string,
    closable: boolean,
    uuid?: string
}

class RouteStore {
    @observable _routePanes: TabObj[] = [];
    @action addRoutePane = (name: string, url: string, closable: boolean = true) => {
        let idx = this.checkPaneNew(url);
        let uuid = '';
        if (idx < 0) {
            uuid = uuidv4();
            this._routePanes = [...this._routePanes, {title: name, key: url, closable: closable, uuid: uuid}];
        } else {
            uuid = this._routePanes[idx].uuid;
        }
        this.selectedKey(uuid);
        return uuid;
    }
    @action removeRoutePane = (uuid: string) => {
        this._routePanes = this._routePanes.filter(pane => pane.uuid !== uuid);
    }
    @action changeRoutePaneUrl = (uuid: string, url: string) => {
        let routePane = this._routePanes.filter(item => item.uuid === uuid)[0];
        if (!!routePane) {
            routePane.key = url;
        }
    }
    @action changeRoutePaneName = (uuid: string, name: string) => {
        let routePane = this._routePanes.filter(item => item.uuid === uuid)[0];
        if (!!routePane) {
            routePane.title = name;
        }
    }

    @observable _selectedKey: string = '';
    @action selectedKey = (value: string) => {
        this._selectedKey = value;
    }

    @action clearRoutePanes = () => {
        this._routePanes = [];
    }
    @action clearClosableRoutePanes = () => {
        this._routePanes = this._routePanes.filter(pane => !pane.closable || pane.uuid === this._selectedKey);
    }

    
    checkPaneNew = (url: string) => {
        let res = -1;
        this._routePanes.forEach((routePane, idx) => {
            if (routePane.key === url) {
                res = idx;
                return;
            }
        })
        return res;
    }

    getTabUrl = (uuid: string) => {
        const obj = this._routePanes.find(pane => pane.uuid === uuid);
        if (!!obj) return obj.key;
        return '';
    }
}

export default RouteStore;