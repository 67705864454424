import React, { Component } from 'react'
import { Tabs, Button, Table, Modal, message, Space, Popconfirm } from 'antd';
import { observer, inject } from 'mobx-react';
import { material, globalMaterial } from '@/interface/model';
import UserStore from '@/store/user-store';
import { queryGlobalMaterials, queryEnterprisesMaterials, createEnterprisesMaterial, updateEnterpriseMaterialDetail, deleteEnterpriseMaterialDetail } from '@/config/api/global-secmat';
import axios from '@/config/axios';
import { SourceType } from '@/components/model/section-add';
import { paramDict2Array } from '@/utils/utils';
import { MaterialTypes } from '@/utils/type';
import { PlusOutlined } from '@ant-design/icons';
import MaterialPanel from '@/components/model/material-panel';
import './global-material.less';
import { UserTypeEnum } from '../login/login';

const { Column } = Table;
const { TabPane } = Tabs;
interface IProps {
    userStore?: UserStore
}

interface IState {
    selectedMaterialType: number,
    gMaterials: globalMaterial[],
    displayGMaterials: globalMaterial[],

    readOnlyModalVisible: boolean
    createModalVisible: boolean
    currentMaterial: material // 控制查看详情

    eMaterialSaveLoading: boolean // 控制截面弹窗保存键的loading

    pageSize: number

    loading: boolean
    saveLoading: boolean // 自定义截面保存loading
    isEditing: boolean // 截面编辑状态
}

@inject('userStore')
@observer
export default class GlobalMaterialPage extends Component<IProps, IState> {
    state = {
        selectedMaterialType: 1,
        gMaterials: [],
        displayGMaterials: [],

        readOnlyModalVisible: false,
        createModalVisible: false,
        currentMaterial: null,

        eMaterialSaveLoading: false,

        pageSize: 30,

        loading: false,
        saveLoading: false,
        isEditing: false,
    }
    enterPriseId: number;

    componentDidMount() {
        this.enterPriseId = this.props.userStore._enterpriseId;
        this.findMaterials(this.state.selectedMaterialType)
    }

    componentDidUpdate() {
        let enterPriseId = this.props.userStore._enterpriseId;
        if (enterPriseId !== this.enterPriseId) {
            this.enterPriseId = this.props.userStore._enterpriseId;
            this.findMaterials(this.state.selectedMaterialType)
        }
    }

    findMaterials = (type: number) => {
        if (!this.enterPriseId) {
            return;
        }
        let gMaterialsPromise = queryGlobalMaterials();
        let eMaterialsPromise = queryEnterprisesMaterials(this.enterPriseId);

        axios.all([gMaterialsPromise, eMaterialsPromise])
            .then(res => {
                let gMaterials: globalMaterial[] = res[0].data || [];
                let eMaterials: globalMaterial[] = res[1].data || [];

                gMaterials = gMaterials.map(item => Object.assign(item, { key: `g${item.type}-${item.id}`, source: SourceType.DB }))
                eMaterials = eMaterials.map(item => Object.assign(item, { key: `e${item.type}-${item.id}`, source: SourceType.CUSTOM }))
                let materials = [...gMaterials, ...eMaterials]
                this.setState({
                    gMaterials: materials,
                    displayGMaterials: materials.filter(item => item.type === this.state.selectedMaterialType),
                })
            })
    }

    onMaterialTypeChange = (key) => {
        let type = +key;
        this.setState({
            displayGMaterials: this.state.gMaterials.filter(item => item.type === type),
            selectedMaterialType: type
        })
    }

    showMaterialModal = (row) => {
        this.setState({
            readOnlyModalVisible: true,
            currentMaterial: row
        })
    }

    showCreateMaterialModal = () => {
        this.setState({
            createModalVisible: true,
        })
    }

    addNewEnterpriseMaterial = (value) => {
        this.setState({
            eMaterialSaveLoading: true,
        })
        let data = Object.assign(value, { enterprise_id: this.props.userStore._enterpriseId })
        createEnterprisesMaterial(this.props.userStore._enterpriseId, data)
            .then(res => {
                let id = res.data;
                let key = `e${data.type}-${id}`;
                data = Object.assign(data, {
                    id: res.data,
                    key: key,
                    source: SourceType.CUSTOM,
                })
                this.setState({
                    createModalVisible: false,
                    gMaterials: [data, ...this.state.gMaterials],
                })
                if (data.type === this.state.selectedMaterialType) {
                    this.setState({
                        displayGMaterials: [data, ...this.state.displayGMaterials]
                    })
                }
            }).finally(() => {
                this.setState({
                    eMaterialSaveLoading: false,
                })
            })

    }

    onEnterpriseMaterialSave = (values) => {
        if (!this.state.currentMaterial) return;
        let mat = this.state.currentMaterial;
        let id = this.state.currentMaterial?.id;
        if (!id) {
            return;
        }
        if (this.state.currentMaterial.source === SourceType.DB) return;
        if (!this.enterPriseId) return;
        this.setState({
            saveLoading: true,
        })
        updateEnterpriseMaterialDetail(this.enterPriseId, id, values)
            .then(res => {
                mat.name = values.name;
                mat.param_in = values.param_in;
                this.setState({
                    saveLoading: false,
                    isEditing: false,
                    gMaterials: [...this.state.gMaterials]
                })
                message.success('保存成功');
            }).catch(err => {
                message.error('保存失败');
                this.setState({
                    saveLoading: false,
                    isEditing: false,
                })
            })
    }

    onMaterialDelete = (record: globalMaterial) => {
        if (record.source === SourceType.DB) return;
        if (!this.enterPriseId) return;
        deleteEnterpriseMaterialDetail(this.enterPriseId, record.id)
        .then(res => {
            const key = `e${record.type}-${record.id}`;
            let gMaterials = this.state.gMaterials.filter(item => item.key !== key);
            this.setState({
                gMaterials: gMaterials,
                displayGMaterials: gMaterials.filter(item => item.type === this.state.selectedMaterialType),
            })
        })
    }


    render() {
        let eid = this.props.userStore._enterpriseId;
        let userType = this.props.userStore._userInfo?.type;
        let { selectedMaterialType, pageSize } = this.state
        let tabbars = paramDict2Array(MaterialTypes).filter(item => item.value.active).map((item, idx) => (
            <TabPane tab={item.value.alias} key={item.name} />
        ))
        return (
            <div className="global-material-container">
                <div style={{ display: 'none' }}>{eid}</div>
                <div className="material-library">
                    <Tabs
                        defaultActiveKey={selectedMaterialType.toString()}
                        type="card"
                        onChange={this.onMaterialTypeChange}
                        tabBarExtraContent={(
                            <Button size="small" type="link" icon={<PlusOutlined />} onClick={this.showCreateMaterialModal}>自定义</Button>
                        )}>
                        {tabbars}
                    </Tabs>
                    <Table
                        dataSource={this.state.displayGMaterials}
                        rowKey="key"
                        pagination={{ pageSize: pageSize, onShowSizeChange: (current, size) => this.setState({ pageSize: size }) }}>
                        <Column title="编号" dataIndex="key" />
                        <Column title="材料名称" dataIndex="name" />
                        <Column title="来源" dataIndex="source" render={(record, idx) => record === SourceType.DB ? '数据库' : '自定义'} />
                        <Column title="" key="operations" render={(record, idx) => (
                            <Space>
                                <Button type="link" size="small" onClick={() => this.showMaterialModal(record)}>详情</Button>
                                {record.source === SourceType.CUSTOM && userType <= UserTypeEnum.ENGINEER && (
                                    <Popconfirm title="确认删除吗?" okText="是" cancelText="否" onConfirm={() => this.onMaterialDelete(record)}>
                                        <Button type="link" size="small" danger>删除</Button>
                                    </Popconfirm>
                                )}
                            </Space>
                        )} />
                    </Table>
                </div>

                <Modal
                    visible={this.state.readOnlyModalVisible}
                    destroyOnClose
                    width={800}
                    closable={false}
                    style={{ top: '20px' }}
                    onCancel={() => this.setState({ readOnlyModalVisible: false })}
                    footer={null}>
                    <MaterialPanel
                        isEditing={this.state.isEditing}
                        materialDetail={this.state.currentMaterial}
                        readOnly={this.state.currentMaterial?.source === SourceType.DB}
                        onCancel={() => this.setState({ isEditing: false })}
                        onEdit={() => this.setState({ isEditing: true })}
                        onSave={this.onEnterpriseMaterialSave}
                        saveLoading={this.state.saveLoading}
                        sidHidden
                    />
                </Modal>

                <Modal
                    visible={this.state.createModalVisible}
                    destroyOnClose
                    width={800}
                    closable={false}
                    style={{ top: '20px' }}
                    footer={null}>
                    <MaterialPanel
                        saveLoading={this.state.eMaterialSaveLoading}
                        materialDetail={this.state.currentMaterial}
                        newType={this.state.selectedMaterialType}
                        onCancel={() => this.setState({ createModalVisible: false })}
                        onSave={this.addNewEnterpriseMaterial} />
                </Modal>
            </div>
        )
    }
}
