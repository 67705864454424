import React, { Component } from 'react'
import MobjectStore from '@/store/mobject-store'
import { observer, inject } from 'mobx-react'
import { ProjectContext } from '@/config/context';
import { updateMobjectFrequency, queryMobjectFrequency } from '@/config/api/mobject';
import { queryQuantities, updateQuantities, downloadQuantityWLCsv } from '@/config/api/quantity';
import { quantity, globalQuantity, warningLimit, quantityLimit } from '@/interface/quantity';
import { constage } from '@/interface/constage';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import { Table, Spin, Empty, Switch, Button, Space, Select, message, Upload, Tooltip } from 'antd';
import axios from '@/config/axios';
import { EditableRow, EditableCell } from '..';
import { CheckOutlined, CloseOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Transfer2DisplayScale, NumFixed } from '@/utils/utils';
import { queryConstages } from '@/config/api/constage';
import { QuantityLevelTypes } from './quantity-pattern-setting';
import { QuantityName } from '../QuantityName';
import appStore, { AUTHORIZATION } from '@/store/app-store';
const { Column } = Table;
const { Option } = Select;
interface IProps {
    mobjectStore?: MobjectStore
    onSave?: () => void,
    onPrevious?: () => void,
}
interface IState {
    quantities: quantity[]
    constages: constage[]
    loading: boolean,
    currentConstage: constage,
    frequency: number
    displayQuantityLimits: quantityLimit[]

    uploadUrl: string,
    exportLoading: boolean,
}
@inject('mobjectStore')
@observer
export default class QuantityLimitSetting extends Component<IProps, IState> {
    static contextType = ProjectContext;
    previousMobjectId: number = 0;
    projectGlobalQuantities: globalQuantity[] = [];
    globalQuantities: globalQuantity[] = [];
    // unitmaps = {};
    state = {
        quantities: [],
        constages: [],
        loading: false,
        currentConstage: null,
        frequency: null,
        displayQuantityLimits: [],
        exportLoading: false,
        uploadUrl: '',
    }

    componentDidMount() {
        let token = appStore.get(AUTHORIZATION) || '';
        let url = `/api/quantities/warning-limit/import?project_id=${this.context}&token=${token}`;
        this.setState({
            uploadUrl: url,
        })
        this.setState({
            loading: true,
        })
        // this.findMobjectQuantities();
        this.generateData();
    }

    componentDidUpdate() {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        if (this.previousMobjectId !== mobjectId) {
            this.previousMobjectId = mobjectId;
            this.setState({
                quantities: [],
                constages: [],
                loading: true,
                currentConstage: null,
            })
            // this.findMobjectQuantities();
            this.generateData();

        }
    }

    generateData = () => {
        const projectId = this.context;
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        if (!mobjectId) return;
        let queryPQuantitiesPromise = queryProjectGlobalQuantities(projectId);
        let queryConstagesPromise = queryConstages(projectId, {
            mobject_id: mobjectId,
        });
        let queryFrequencyPromise = queryMobjectFrequency(mobjectId);
        axios.all([queryPQuantitiesPromise, queryConstagesPromise, queryFrequencyPromise])
            .then(res => {
                this.projectGlobalQuantities = res[0].data || [];
                let constages = res[1].data || [];
                let frequency = res[2].data;
                this.setState({
                    constages: constages,
                    currentConstage: constages[0] || null,
                    frequency: frequency,
                })
                this.findQuantities();
            })
    }

    findQuantities = () => {
        this.setState({
            loading: true,
        })
        const projectId = this.context;
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        if (!mobjectId) return;

        queryQuantities(this.context, {
            mobject_id: mobjectId,
            activated: true,
            level: QuantityLevelTypes.OTHER_Q
        }).then(res => {
            let quantities: quantity[] = res.data || [];
            quantities = quantities.sort((a, b) => b.qt_type - a.qt_type);
            quantities.forEach((q: quantity) => {
                let pQuantity: globalQuantity = this.projectGlobalQuantities.find(item => item.type === q.type);
                q.chinese_name = pQuantity?.chinese_name || '';
                q.unit_type = pQuantity?.unit_type || '';
                q.precision = pQuantity?.precision || '1';
                let scale = Transfer2DisplayScale(q.unit_type);
                q.scale = scale;
                // 将数据换算成展示单位对应数值
                q.warning_limit.forEach((item: warningLimit) => {
                    for (let key of Object.keys(item.values)) {
                        item.values[key] = NumFixed(item.values[key] * q.scale, 8);
                    }
                })
                q.initial_value = NumFixed(q.initial_value * q.scale, 8);
            })
            quantities = quantities.sort((a, b) => b.qt_type - a.qt_type);
            // this.quantities = quantities;
            this.setState({
                quantities: quantities,
            })
            if (!!this.state.currentConstage) {
                this.generateDisplayQuantityLimit(this.state.currentConstage.id);
            }
        }).finally(() => {
            this.setState({
                loading: false,
            })
        })
    }

    generateDisplayQuantityLimit(constageId: number) {
        let quantities = this.state.quantities;
        let display = quantities.map(q => {
            let warningLimit = q.warning_limit.find(item => item.stage_id === constageId)?.values;
            return {
                ...q,
                t2: warningLimit ? warningLimit['t2'] : null,
                t3: warningLimit ? warningLimit['t3'] : null,
                t4: warningLimit ? warningLimit['t4'] : null,
                t5: warningLimit ? warningLimit['t5'] : null,
            }
        }) || [];
        this.setState({
            displayQuantityLimits: display
        })
    }

    onConstageSelectChange = (e) => {
        this.setState({
            currentConstage: this.state.constages.find(item => item.id === e)
        })
        this.generateDisplayQuantityLimit(e);
    }

    handleRowEdit = (row: quantityLimit, tag: string) => {
        let id = row.id;
        let newData = [...this.state.displayQuantityLimits];
        let index = newData.findIndex(item => item.id === id);
        let item: quantityLimit = newData[index];

        newData.splice(index, 1, { ...item, ...row })


        let qw = row;
        let scale = qw.scale;
        let constageId = this.state.currentConstage.id;
        let limit = {
            t2: qw.t2 || 0,
            t3: qw.t3 || 0,
            t4: qw.t4 || 0,
            t5: qw.t5 || 0,
        }
        let warningLimits = [...qw.warning_limit];
        let wl = warningLimits.find(item => item.stage_id === constageId);
        if (wl) {
            wl.values = limit;
        } else {
            warningLimits.push({
                values: limit,
                stage_id: constageId,
            })
        }
        let q: quantity = this.state.quantities.find(item => item.id === id);
        q.initial_value = qw.initial_value || 0;
        q.warning_limit = warningLimits;
        q.is_evaluate = qw.is_evaluate || false;

        this.setState({
            displayQuantityLimits: [...newData],
            quantities: [...this.state.quantities]
        })
    }

    onQuantitiesSave = () => {
        if (!this.state.frequency) {
            message.warning('请选择监测对象的“评估频次”')
            return;
        }
        // let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        let promiseList = [];
        this.state.quantities.forEach((q: quantity) => {
            let limit = Object.assign([], q.warning_limit);
            if (!!limit) {
                limit.forEach(item => {
                    for (let key of Object.keys(item.values)) {
                        item.values[key] = NumFixed(item.values[key] / q.scale, 8);
                    }
                })
            }
            let data = Object.assign({}, {
                is_evaluate: q.is_evaluate,
                initial_value: NumFixed(q.initial_value / q.scale, 8),
                warning_limit: limit,
                id: q.id,
            })
            let promise = updateQuantities(this.context, q.id, data);
            promiseList.push(promise);
        })
        // return;
        // let updateFrequencyPromise = updateMobjectFrequency(mobjectId, this.state.frequency);
        axios.all([...promiseList]).then(res => {
            this.props.onSave();
        }).catch(err => {
            message.error('保存失败');
            console.log(err);
            // 把单位换算回去
            this.state.quantities.forEach((q: quantity) => {
                let limit = Object.assign([], q.warning_limit);
                if (!!limit) {
                    limit.forEach(item => {
                        for (let key of Object.keys(item.values)) {
                            item.values[key] = NumFixed(item.values[key] * q.scale, 8);
                        }
                    })
                }
            })
        })

    }

    onFrequencyChange = (value: number) => {
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        updateMobjectFrequency(mobjectId, {
            frequency: value
        })
            .then(res => {
                this.setState({
                    frequency: value
                })
            })
    }

    exportWarningLimit = () => {
        let current: constage = this.state.currentConstage;
        if (!current) return;
        const projectId = this.context;
        let mobjectId = +this.props.mobjectStore._selectedMobjectId;
        this.setState({
            exportLoading: true,
        })
        downloadQuantityWLCsv(projectId, current.id, mobjectId).then(res => {
            const blob = new Blob([res.data]);
            const link = document.createElement('a');
            const filename = `${current.code}.csv`;
            // const filename = '传感器表.csv';
            link.setAttribute('href', window.URL.createObjectURL(blob));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(err => {
            console.log(err);
            message.error('导出失败');
        }).finally(() => {
            this.setState({
                exportLoading: false,
            })
        })
    }

    onImportWLCsvChange = (info) => {
        if (info.file.status !== 'uploading') {
            // console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            let current: constage = this.state.currentConstage;
            if (!current) return;
            this.findQuantities();
            message.success(`导入成功`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    beforeUpload = (file) => {
        let current: constage = this.state.currentConstage;
        if (!current) return false;
        return true;
    }

    render() {
        let mobjectDetail = this.props.mobjectStore._mobjectDetail;
        if (this.previousMobjectId !== mobjectDetail?.id) return null;
        let { displayQuantityLimits } = this.state;
        return (
            <div className="quantity-step-item step-three">
                <div>
                    <div className="operations">
                        <Space>
                            <Button shape="round" onClick={this.exportWarningLimit}>导出</Button>
                            <Upload
                                name="file"
                                action={this.state.uploadUrl}
                                accept='.csv, .txt'
                                showUploadList={false}
                                onChange={this.onImportWLCsvChange}
                                beforeUpload={this.beforeUpload}
                            >
                                <Button shape="round">导入</Button>
                            </Upload>
                            <Tooltip title="请以创建的施工步编号命名文件，如CS-01.csv">
                                <Button size="small" type="link" icon={<InfoCircleFilled />}></Button>
                            </Tooltip>
                            <span>评估频次:</span>
                            <Select
                                placeholder="评估频次"
                                style={{ width: 140 }}
                                value={this.state.frequency}
                                onChange={this.onFrequencyChange}>
                                <Option value={1} label="1秒">1秒</Option>
                                <Option value={5} label="5秒">5秒</Option>
                                <Option value={10} label="10秒">10秒</Option>
                                <Option value={30} label="30秒">30秒</Option>
                                <Option value={60} label="1分钟">1分钟</Option>
                                <Option value={300} label="5分钟">5分钟</Option>
                                <Option value={600} label="10分钟">10分钟</Option>
                                <Option value={900} label="15分钟">15分钟</Option>
                                <Option value={1800} label="30分钟">30分钟</Option>
                                <Option value={3600} label="1小时">1小时</Option>
                                <Option value={21600} label="6小时">6小时</Option>
                                <Option value={43200} label="12小时">12小时</Option>
                                <Option value={86400} label="24小时">24小时</Option>
                            </Select>
                        </Space>
                        <Select value={this.state.currentConstage?.id} style={{ width: '280px' }} onChange={this.onConstageSelectChange}>
                            {this.state.constages.map((item: constage, idx) => (
                                <Option value={item.id} key={idx}>{`${item.code}${item.name}`}</Option>
                            ))}
                        </Select>
                        {/* <Radio.Group value={this.state.currentConstage?.id} buttonStyle="solid" onChange={this.onConstageSelectChange}>
                            {this.state.constages.map((item: constage) => (
                                <Radio.Button value={item.id} key={item.id}>{`${item.code}${item.name}`}</Radio.Button>
                            ))}
                        </Radio.Group> */}


                    </div>
                    <Spin spinning={this.state.loading}>
                        {this.state.quantities.length === 0 ?
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
                            (
                                <div className="quantity-limit-tables">
                                    <Table
                                        dataSource={displayQuantityLimits}
                                        rowKey="id"
                                        pagination={false}
                                        bordered
                                        components={{
                                            body: {
                                                row: EditableRow,
                                                cell: EditableCell,
                                            }
                                        }}
                                    // rowClassName={(record, index) => (
                                    //     index % 2 !== 0 ? 'ribbon-row' : ''
                                    // )}
                                    >
                                        <Column
                                            title="指标"
                                            key="quantity_name"
                                            render={record => (
                                                <QuantityName record={record} />
                                            )} />
                                        <Column
                                            title="单位"
                                            dataIndex="unit_type"
                                            align="center"
                                            width={100} />
                                        <Column
                                            title={`初值(${this.state.constages[0]?.code || ''})`}
                                            dataIndex='initial_value'
                                            width="15%"
                                            align="right"
                                            onCell={(record: quantityLimit, idx) => ({
                                                record,
                                                editable: true,
                                                validateDisable: true,
                                                dataIndex: 'initial_value',
                                                title: '初值',
                                                directEdit: true,
                                                handleSave: (e) => this.handleRowEdit(e, 'initial_value'),
                                            })}
                                        />
                                        <Column
                                            title="二级阈值"
                                            dataIndex='t2'
                                            width="15%"
                                            align="right"
                                            onCell={(record: quantityLimit, idx) => ({
                                                record,
                                                editable: true,
                                                validateDisable: true,
                                                dataIndex: 't2',
                                                title: '二级阈值',
                                                directEdit: true,
                                                handleSave: (e) => this.handleRowEdit(e, 't2'),
                                            })}
                                        />
                                        <Column
                                            title="三级阈值"
                                            dataIndex='t3'
                                            width="15%"
                                            align="right"
                                            onCell={(record: quantityLimit, idx) => ({
                                                record,
                                                editable: true,
                                                validateDisable: true,
                                                dataIndex: 't3',
                                                title: '三级阈值',
                                                directEdit: true,
                                                handleSave: (e) => this.handleRowEdit(e, 't3'),
                                            })}
                                        />
                                        <Column
                                            title="四级阈值"
                                            dataIndex='t4'
                                            width="15%"
                                            align="right"
                                            onCell={(record: quantityLimit, idx) => ({
                                                record,
                                                editable: true,
                                                validateDisable: true,
                                                dataIndex: 't4',
                                                title: '四级阈值',
                                                directEdit: true,
                                                handleSave: (e) => this.handleRowEdit(e, 't4'),
                                            })}
                                        />
                                        <Column
                                            title="五级阈值"
                                            dataIndex='t5'
                                            width="15%"
                                            align="right"
                                            onCell={(record: quantityLimit, idx) => ({
                                                record,
                                                editable: true,
                                                validateDisable: true,
                                                dataIndex: 't5',
                                                title: '五级阈值',
                                                directEdit: true,
                                                handleSave: (e) => this.handleRowEdit(e, 't5'),
                                            })}
                                        />
                                        <Column
                                            title="是否报警"
                                            key="is_evaluate"
                                            width={100}
                                            align="center"
                                            render={record => (
                                                <Switch
                                                    checkedChildren={<CheckOutlined />}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    checked={record.is_evaluate}
                                                    onChange={(value) => this.handleRowEdit({ ...record, is_evaluate: value }, 'is_evaluate')} />
                                            )}
                                        />
                                    </Table>
                                </div>
                            )
                        }

                    </Spin>
                    <div className="steps-action">
                        <Space size="large">
                            <Button onClick={this.props.onPrevious}>上一步</Button>
                            <Button type="primary" onClick={this.onQuantitiesSave}>保存</Button>
                        </Space>
                    </div>
                </div>



            </div>
        )
    }
}
