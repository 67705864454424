import axios from "../axios"
import { AxiosResponse } from "axios"

export function queryEnterprisePlanbs(enterpriseId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/enterprises/${enterpriseId}/planb`, {
        params: params
    })
}

export function createEnterprisePlanb(enterpriseId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/enterprises/${enterpriseId}/planb`, data, {
        params: params
    })
}

export function updateEnterprisePlanb(enterpriseId: number, planbId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/enterprises/${enterpriseId}/planb/${planbId}`, data, {
        params: params
    })
}

export function deleteEnterprisePlanb(enterpriseId: number, planbId: number): Promise<AxiosResponse<any>> {
    return axios.delete(`/enterprises/${enterpriseId}/planb/${planbId}`)
}