import React, { Component, Fragment } from 'react'
import './quantity-link.less';
import { withRoutePane } from '@/components/hoc/routePane';
import { Gprops } from '@/config/props';
import { dbox, globalDbox } from '@/interface/dbox';
import { sensor, globalSensor, sensorQuantity } from '@/interface/sensor';
import { ProjectContext } from '@/config/context';
import { queryDboxs } from '@/config/api/dbox';
import { querySensors, querySensorQuantities, updateSensorQuantityDetail } from '@/config/api/sensor';
import { queryGlobalDboxs, queryGlobalSensors } from '@/config/api/global-device';
import axios from '@/config/axios';
import { message, Input, Space, Table } from 'antd';
import { EditableRow, EditableCell } from '@/components';
import { QuantityLevelTypes } from '@/components/mobject/quantity-pattern-setting';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import { globalQuantity, quantity } from '@/interface/quantity';
import { InlineMath } from 'react-katex';
import { handleQuantityName } from '@/utils/utils';
import { queryMobjects } from '@/config/api/mobject';
import { QuantityName } from '@/components/QuantityName';
import { queryQuantities } from '@/config/api/quantity';
const { Search } = Input;
const { Column } = Table;
interface IState {
    dboxs: dbox[],
    sensors: sensor[],
    gSensors: globalSensor[],
    gDboxs: globalDbox[],

    tableLoading: boolean,
    searchValue: string,

    sensorQuantities: sensorQuantity[],
    displaySQ: sensorQuantity[],
    currentDqList: quantity[],
    dqList: quantity[],
}
class QuantityLinkPage extends Component<Gprops, IState> {
    static contextType = ProjectContext;
    state = {
        dboxs: [],
        sensors: [],
        gSensors: [],
        gDboxs: [],

        tableLoading: false,
        searchValue: '',

        sensorQuantities: [],
        displaySQ: [],
        currentDqList: [],
        dqList: [],
    }
    pQuantities: globalQuantity[] = [];

    componentDidMount() {
        this.generateData();
    }

    generateData = () => {
        this.setState({
            tableLoading: true,
        })
        let project_id = this.context;
        let queryDboxPromise = queryDboxs({
            project_id: project_id,
        });
        let querySensorPromise = querySensors({
            project_id: project_id,
        });
        let queryGlobalDboxsPromise = queryGlobalDboxs();
        let queryGlobalSensorsPromise = queryGlobalSensors();
        let querySensorQuantitiesPromise = querySensorQuantities(project_id, {
            level: QuantityLevelTypes.SENSOR_MQ,
        });
        let queryProjectGlobalQuantitiesPromise = queryProjectGlobalQuantities(project_id);
        let queryMobjectsPromise = queryMobjects({
            project_id: this.context,
        });
        let queryDqListPromise = queryQuantities(this.context, {
            level: QuantityLevelTypes.DIRECT_Q,
        });
        axios.all([
            queryDboxPromise,
            querySensorPromise,
            queryGlobalDboxsPromise,
            queryGlobalSensorsPromise,
            querySensorQuantitiesPromise,
            queryProjectGlobalQuantitiesPromise,
            queryMobjectsPromise,
            queryDqListPromise])
            .then(res => {
                let sensors = res[1].data || [];
                let gSensors = res[3].data || [];
                let mobjects = res[6].data || [];
                sensors.forEach((item: sensor) => {
                    let gSensor = gSensors.find(gs => gs.type === item.type);
                    item.type_name = gSensor?.name;
                    let mobject = mobjects.find(m => m.id === item.mobject_id);
                    item.mobject_name = mobject?.name || null;
                })
                sensors = sensors.sort((a, b) => a.dbox_id - b.dbox_id);
                let dboxs = res[0].data || [];
                let gDboxs = res[2].data || [];
                dboxs.forEach(item => {
                    let gDbox = gDboxs.find(gd => gd.type === item.type);
                    item.type_name = gDbox?.name;
                })
                let sQuantities: sensorQuantity[] = res[4].data || [];
                this.pQuantities = res[5].data || [];
                sQuantities.forEach(item => {
                    let sensorId = item.sensor_id;
                    let sensor = sensors.find(s => s.id === sensorId);
                    item.sensor = sensor;
                    let pq = this.pQuantities.find(p => p.type === item.type);
                    item.chinese_name = pq.chinese_name;
                })
                sQuantities = sQuantities.filter(item => !!item.sensor);
                sQuantities = sQuantities.sort((a, b) => a.sensor?.name > b.sensor?.name ? 1 : -1);
                let displayList: sensorQuantity[] = sQuantities.filter(item => item.name.indexOf(this.state.searchValue) >= 0);
                this.setState({
                    dboxs: dboxs,
                    gDboxs: gDboxs,
                    sensors: sensors,
                    gSensors: gSensors,
                    sensorQuantities: sQuantities,
                    tableLoading: false,
                    dqList: res[7].data || [],
                    displaySQ: displayList,
                })
                // let displayDboxs = dboxs.filter(item => item.name.indexOf(this.state.searchValue) >= 0) || [];
                // let displaySensors = sensors.filter(item => item.name.indexOf(this.state.searchValue) >= 0) || [];
                // this.setState({
                //     displayDboxs: displayDboxs,
                //     displaySensors: displaySensors
                // })
            }).catch(err => {
                console.log(err);
                message.error('获取数据失败')
                this.setState({
                    tableLoading: false,
                })
            })
    }

    handleSearchChange = (value) => {
        let sQuantities = this.state.sensorQuantities;
        let displayList: sensorQuantity[] = sQuantities.filter(item => item.sensor?.name.indexOf(value) >= 0);
        this.setState({
            displaySQ: displayList
        })
    }

    goToSensorDetail = (record) => {
        let url = `/projects/${this.context}/sensors/${record.id}`;
        this.props.history.push(url);
    }

    findDboxName = (record: sensor) => {
        let dboxId = record?.dbox_id;
        if (!dboxId) return '';
        let dbox = this.state.dboxs.find(item => item.id === dboxId);
        return `${dbox?.name}`;
    }

    onCellDoubleClick = (row: sensorQuantity) => {
        let sensor = row.sensor;
        if (!sensor || !sensor.mobject_id) {
            this.setState({
                currentDqList: [],
            })
            return;
        }
        let mobjectId = sensor.mobject_id;
        // console.log(this.state.dqList)
        let currentDqList = this.state.dqList.filter((item: quantity) => {
            if (item.mobject_id !== mobjectId) return false;
            if (item.mq_id === null) return true;
            if (item.mq_id !== null && item.id === row.dq_id) return true;
            return false;
        }) || [];
        currentDqList.forEach((item: quantity) => {
            item.mobject_name = row.sensor?.mobject_name || '';
        })
        this.setState({
            currentDqList: currentDqList
        })
        // queryQuantities(this.context, {
        //     mobject_id: mobjectId,
        //     level: QuantityLevelTypes.DIRECT_Q,
        // }).then(res => {
        //     this.setState({
        //         currentDqList: res.data || [],
        //     })
        // })
    }

    handleSave = (row: sensorQuantity) => {
        let id = row.id;
        if (!row.dq_id) return;
        let newData = [...this.state.sensorQuantities];
        let index = newData.findIndex(item => item.id === id);
        let item: sensorQuantity = newData[index];
        newData.splice(index, 1, { ...item, ...row })
        let displayList: sensorQuantity[] = newData.filter(item => item.name.indexOf(this.state.searchValue) >= 0);
        this.setState({
            sensorQuantities: [...newData],
            displaySQ: displayList,
        })
        let data = {
            dq_id: row.dq_id
        }
        updateSensorQuantityDetail(this.context, row.id, data)
            .then(res => {
                let dq: quantity = this.state.dqList.find((q: quantity) => q.id === row.dq_id);
                if (!!dq) {
                    dq.mq_id = row.id;
                    this.setState({
                        dqList: [...this.state.dqList]
                    })
                }
                if (row.dq_id === -1) {
                    row.dq_id = null;
                    queryQuantities(this.context, {
                        level: QuantityLevelTypes.DIRECT_Q,
                    }).then(res => {
                        this.setState({
                            dqList: res.data || [],
                        })
                    })
                }

            })
            .catch(err => {
                message.error('更新失败');
                console.log(err);
                newData.splice(index, 1, {
                    ...row,
                    ...item
                })
                let displayList: sensorQuantity[] = newData.filter(item => item.name.indexOf(this.state.searchValue) >= 0);
                this.setState({
                    sensorQuantities: [...newData],
                    displaySQ: displayList,
                })
            })
    }

    render() {
        let dqOptionList: any[] = [
            ...this.state.currentDqList.map((item: quantity) => {
                return (
                    { value: item.id, label: (
                        <Fragment>
                            <span>{item.mobject_name}-</span>
                            <QuantityName record={item} />
                        </Fragment>
                    ) }
                )
            }),
            { value: -1, label: '取消关联' },
        ];
        // if (dqOptionList.length > 0) {
        //     dqOptionList = [
        //         ...dqOptionList,
        //         { value: -1, label: '取消关联' },
        //     ]
        // }
        return (
            <div className="quantity-link-container">
                <div className="page-header">
                    <div className="fill-remaining-space"></div>
                    <Space>
                        <Search
                            placeholder="检索传感器编号"
                            value={this.state.searchValue}
                            onChange={(e) => this.setState({ searchValue: e.target.value })}
                            onPressEnter={() => this.handleSearchChange(this.state.searchValue)}
                            onBlur={() => this.handleSearchChange(this.state.searchValue)} />
                    </Space>
                </div>
                <div className="main">
                    <Table
                        rowKey="id"
                        pagination={false}
                        components={{
                            body: {
                                row: EditableRow,
                                cell: EditableCell,
                            }
                        }}
                        loading={this.state.tableLoading}
                        rowClassName={(record, index) => (
                            'editable-row'
                        )}
                        dataSource={this.state.displaySQ}
                    >
                        <Column title="所属传感器" key="sensor_name" render={(record: sensorQuantity) => (
                            `${record.sensor?.name}`
                        )} />
                        <Column
                            title="测量值代号"
                            dataIndex="name"
                            filters={Array.from(new Set(this.state.displaySQ.map((item: sensorQuantity) => (item.name.replace('_mq', '')))))
                                .map(item => ({ text: item, value: item }))
                            }
                            onFilter={(value: string, record: sensorQuantity) =>
                                record.name.indexOf(value) === 0
                            }
                            render={record => (
                                <InlineMath math={handleQuantityName(record.replace('_mq', ''))} />
                            )} />
                        <Column title="名称" dataIndex="chinese_name" />
                        <Column title="采集箱" key="dbox" render={(record: sensorQuantity) => this.findDboxName(record.sensor)} />
                        <Column title="通道号" key="channel" render={(record: sensorQuantity) => record.sensor?.channel} />
                        <Column title="监测对象" key="mobject" render={(record: sensorQuantity) => record.sensor?.mobject_name} />
                        <Column title="位置代号" key="location" render={(record: sensorQuantity) => record.sensor?.location} />
                        <Column
                            title="关联指标"
                            key="dq_id"
                            width="15%"
                            onCell={((record: sensorQuantity, idx) => ({
                                record,
                                editable: true,
                                dataIndex: 'dq_id',
                                type: 'Select',
                                options: dqOptionList,
                                validateDisable: true,
                                title: '关联指标',
                                handleSave: this.handleSave,
                                onDoubleClick: () => this.onCellDoubleClick(record)
                            }))}
                            render={(record: sensorQuantity) => {
                                if (!record.dq_id) return null;
                                let dq = this.state.dqList.find(item => item.id === record.dq_id);
                                return (
                                    <Fragment>
                                        {dq && (<span>{record.sensor?.mobject_name}-</span>)}
                                        <QuantityName record={dq} />
                                    </Fragment>
                                );
                            }} />
                    </Table>
                </div>
            </div>
        )
    }
}

export default withRoutePane(QuantityLinkPage, '传感器指标关联');