import React, { Component } from 'react'
import { mobject } from '@/interface/mobject'
import { Empty, Button, Tabs } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import classnames from 'classnames';
const { TabPane } = Tabs;
interface IProps {
    mobjects: mobject[]
    onChange: (mobject: mobject) => void
    selectedMobjectId: number
    onItemAddClick?: () => void
    loading?: boolean
}

export default class MobjectListHorizon extends Component<IProps> {
    static defaultProps = {
        loading: false
    }


    onItemClick = (mobject: mobject) => {
        this.props.onChange(mobject);
    }

    onItemAddClick = () => {
        this.props.onItemAddClick()
    }

    onItemChange = (e) => {
        let mob = this.props.mobjects.find(item => item.id.toString() === e);
        if (!mob) return;
        this.props.onChange(mob);
    }

    render() {

        if (this.props.mobjects?.length === 0 && !this.props.loading) {
            return (
                <div className="mobject-select-blank">
                    {/* <Empty /> */}
                    <Empty
                        imageStyle={{
                            height: 100,
                        }}
                        description={
                            <span>
                                还没有监测对象哦
                            </span>
                        }
                    >
                        <Button type="primary" icon={<PlusOutlined />} style={{ width: '200px', height: '100px', fontSize: '22px' }} onClick={this.onItemAddClick}>
                            新建
                        </Button>
                    </Empty>

                </div>
            )
        }

        return (
            <div className="mobject-list-horizon-container">
                <Tabs 
                onChange={this.onItemChange} 
                activeKey={`${this.props.selectedMobjectId}`}
                tabBarExtraContent={
                    <Button type="dashed" size="small" icon={<PlusOutlined />} onClick={this.onItemAddClick}>添加</Button>
                }>
                    {this.props.mobjects.map((item: mobject, idx) => (
                        <TabPane tab={item.name} key={`${item.id}`}>

                        </TabPane>
                    ))}
                </Tabs>
            </div>

        )
    }
}
