import React, { Component, Fragment } from 'react'
import { Table, Tag, Progress } from 'antd'
import { project } from '@/interface/project'
import { monitorRank, monitorType } from '@/utils/type';
import dayjs from 'dayjs';
import { Gprops } from '@/config/props';
import { withRouter } from 'react-router-dom';
const { Column } = Table;
interface IProps extends Gprops {
    projectList: project[]
}
class ProjectListPanel extends Component<IProps> {

    onClick = (row) => {

    }
    render() {
        return (
            <div className="project-list-panel">
                <Table 
                dataSource={this.props.projectList}
                pagination={false}
                onRow = {record => {
                    return {
                        onClick: _ => {
                            this.props.history.push(`/projects/${record.id}/dashboard`);
                        },
                    }
                }}
                rowClassName={(record, index) => (
                    'project-list-row'
                )}
                rowKey="id">
                    <Column title="项目名称" dataIndex="name"/>
                    <Column title="负责人" dataIndex="leader_name"/>
                    <Column title="监测类别" dataIndex="monitor_type" render={record => monitorType[record]}/>
                    <Column title="监测等级" dataIndex="monitor_rank" render={record => monitorRank[record]}/>
                    <Column title="状态" width={200} key="status" render={(record: project) => (
                        <Fragment>
                            {record.active ? <Tag color="#00944a">进行中</Tag> : <Tag color="#727272">关闭</Tag>}
                            {record.working && (<Tag color="#ef8b52">正在监测</Tag>)}
                        </Fragment>
                    )}/>
                    <Column title="进度" key="progress" width='20%' render={(record: project) => {
                        let start_time = record.start_time || dayjs();
                        let period = record.period;
                        let percent = 0;
                        let diff = dayjs().diff(dayjs(start_time), 'day') / period * 100;
                        if (diff > 0) percent = +diff.toFixed(0);
                        if (percent > 100) percent = 100
                        return <Progress percent={percent}/>
                    }}/>
                </Table>
            </div>
        )
    }
}

export default withRouter(ProjectListPanel);