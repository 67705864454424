import React, { Component } from 'react'
import { Row, Col } from 'antd'
import { ProgressBoard, DeviceBoard, AlertHistoryBoard, ReportBoard, ProjectBoard, EvaluateBoard, QuantityBoard } from '../../components';
import './dashboard.less';
import DashboardEvaluatePage from './dashboard-evaluate';
import { inject, observer } from 'mobx-react';
import { RProps } from '@/config/props';
import UserStore from '@/store/user-store';
import { ProjectContext } from '@/config/context';

interface IProps extends RProps {
    userStore?: UserStore
}
interface IState {
    dashboardLocation: number,
}

const UserTypes = {
    CLIENT: 4,
    SITE: 3,
    ENGINEER: 2,
    ADMIN: 1,
}

@inject('routeStore', 'userStore')
@observer
export default class Dashboard extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        dashboardLocation: 1,
    }
    constructor(props) {
        super(props);
        this.props.routeStore.addRoutePane(`控制台`, this.props.match.url);
    }

    componentDidMount() {
        let user = this.props.userStore._userInfo;
        let userType = user?.type || 2;
        // if (userType === UserTypes.SITE) {
        //     this.setState({
        //         dashboardLocation: 3
        //     })
        // }
        if (userType === UserTypes.CLIENT) {
            this.setState({
                dashboardLocation: 2
            })
        }
    }
    render() {
        let user = this.props.userStore._userInfo;
        if (!user) return null;
        // if (this.state.dashboardLocation === 3) {
        //     return <DashboardDevicePage/>
        // }
        // if (this.state.dashboardLocation === 2) {
        //     return <DashboardEvaluatePage onMainBtnClick={() => this.setState({ dashboardLocation: 1 })} />
        // }
        return (
            <Row gutter={[10, 10]} className="dashboard-container">
                <Col xs={24} xxl={18}>
                    <Row gutter={10}>
                        <Col span={8}>
                            <ProjectBoard />
                        </Col>
                        <Col span={16}>
                            <EvaluateBoard bordered={true} projectId={this.context} onEvaluationAllBtnClick={() => this.props.history.push(`/projects/${this.context}/evaluations`)} />
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col span={24}>
                            <QuantityBoard projectId={this.context} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} xxl={6}>
                    <Row gutter={10}>
                        <Col xs={12} xxl={24}>
                            <ProgressBoard />
                        </Col>
                        <Col xs={12} xxl={24}>
                            <DeviceBoard />
                        </Col>
                        <Col xs={12} xxl={24}>
                            <AlertHistoryBoard />
                        </Col>
                        <Col xs={12} xxl={24}>
                            <ReportBoard />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
