import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react';
import { RProps } from '@/config/props';
import { dbox, manageHistory } from '@/interface/dbox';
import { Spin, Row, Col, Switch, Button, Select, message, Tooltip } from 'antd';
import { queryDboxDetail, queryDboxManageHistory, updateDboxManageHistory, dboxOnline, dboxOffline, updateDbox, dboxInitialize, dboxSynch } from '@/config/api/dbox';
import './dbox-detail.less';
import DeviceFigure from '@/components/device/device-figure';
import DboxInfo from '@/components/device/dbox-info';
import GlobalDeviceStore from '@/store/global-device';
import { queryGlobalDboxs, queryGlobalSensors } from '@/config/api/global-device';
import dayjs from 'dayjs';
import DeviceManageHistory from '@/components/device/device-manage-history';
import DeviceException from '@/components/device/device-exception';
import { queryExceptions } from '@/config/api/exception';
import { deviceException } from '@/interface/exception';
import DboxSensorPane from '@/components/device/dbox-sensor-pane';
import axios from '@/config/axios';
import { DboxTypesEnum, DboxStatusTypesEnum } from './dbox-list';
import { deviceManageStatus } from '@/utils/type';
import { DeviceVarietyLevel } from '../global-install/global-install';
import { figureUploadUrl } from '@/config/api/document';
import { ProjectContext } from '@/config/context';
import { InfoCircleOutlined, SyncOutlined } from '@ant-design/icons';

const { Option } = Select;

interface IProps extends RProps {
    globalDeviceStore?: GlobalDeviceStore
}

interface IState {
    dbox: dbox,
    statusHistory: manageHistory[],
    dboxExceptions: deviceException[],
    statusLoading: boolean,
    frequencyLoading: boolean,
    initializeLoading: boolean,

    dboxSyncLoading: boolean,
}

@inject('routeStore', 'globalDeviceStore')
@observer
export default class DboxDetailPage extends Component<IProps, IState> {
    static contextType = ProjectContext;
    state = {
        dbox: null,
        statusHistory: [],
        dboxExceptions: [],
        statusLoading: false,
        frequencyLoading: false,
        initializeLoading: false,

        dboxSyncLoading: false,
    }
    dboxId: number;
    tab_uuid: string;

    constructor(props) {
        super(props);
        let id = this.props.match.params['dboxId'];
        this.tab_uuid = this.props.routeStore.addRoutePane(`采集箱${id}`, this.props.match.url);
        this.dboxId = id;

    }

    componentDidMount() {
        let id = this.props.match.params['dboxId'];
        this.generateData(id);
    }

    componentDidUpdate() {
        let id = this.props.match.params['dboxId'];
        this.tab_uuid = this.props.routeStore.addRoutePane(`采集箱${id}`, this.props.match.url);
        if (this.dboxId !== id) {
            this.dboxId = id;
            this.generateData(id);
        }
    }

    generateData = (id) => {
        this.loadDbox(id);
        this.findDboxManageHistory(id);
        this.findDboxExceptionList(id);
    }

    loadDbox = (id: number) => {
        if (this.props.globalDeviceStore._globalDboxs.length === 0 || this.props.globalDeviceStore._globalSensors.length === 0) {
            // 如果未加载过全局设备，则先请求
            let globalDboxPromise = queryGlobalDboxs();
            let globalSensorPromise = queryGlobalSensors();
            axios.all([globalDboxPromise, globalSensorPromise])
                .then(res => {
                    this.props.globalDeviceStore.globalDboxs(res[0].data);
                    this.props.globalDeviceStore.globalSensors(res[1].data);
                    this.findDboxDetail(id);
                })
        } else {
            this.findDboxDetail(id);
        }
    }

    findDboxDetail = (id: number) => {
        queryDboxDetail(id)
            .then(res => {
                let dbox: dbox = res.data;
                this.props.routeStore.changeRoutePaneName(this.tab_uuid, `采集箱${dbox?.name}`);
                let global = this.props.globalDeviceStore._globalDboxs.filter(item => item.type === dbox.type)[0];
                dbox.type_name = global ? global.name : '';
                this.setState({
                    dbox: dbox,
                })
            })
    }

    findDboxManageHistory = (dboxId: number) => {
        queryDboxManageHistory(dboxId, {
            size: 5,
        })
            .then(res => {
                let data: manageHistory[] = res.data;
                data = data.sort((a, b) => dayjs(b.create_time).valueOf() - dayjs(a.create_time).valueOf());
                this.setState({
                    statusHistory: data.slice(0, 5)
                })
            })
    }

    changeDboxManageStatus = (value) => {
        let manage_content = deviceManageStatus[value];
        updateDboxManageHistory(this.dboxId, null, {
            manage_status: value,
            manage_content: manage_content,
        }).then(res => {
            this.findDboxManageHistory(this.dboxId);
        })
    }

    findDboxExceptionList = (dboxId: number) => {
        queryExceptions({
            project_id: this.context,
            device_id: dboxId,
            device_variety: DeviceVarietyLevel.DBOX,
            size: 7,
        }).then(res => {
            let data: deviceException[] = res.data;
            this.setState({
                dboxExceptions: data.slice(0, 7),
            })
        })
    }

    onDboxStatusChange = (checked) => {
        this.setState({
            statusLoading: true,
        })
        if (checked) {
            dboxOnline(this.dboxId)
                .then(res => {
                    let dbox = this.state.dbox;
                    dbox.work_status = DboxStatusTypesEnum.ONLINE;
                    this.setState({
                        statusLoading: false,
                        dbox: { ...dbox }
                    })
                }).catch(err => {
                    message.error('更新失败');
                    this.setState({
                        statusLoading: false,
                    })
                })
        } else {
            dboxOffline(this.dboxId)
                .then(res => {
                    let dbox = this.state.dbox;
                    dbox.work_status = DboxStatusTypesEnum.OFFLINE;
                    this.setState({
                        statusLoading: false,
                        dbox: { ...dbox }
                    })
                }).catch(err => {
                    message.error('更新失败');
                    this.setState({
                        statusLoading: false,
                    })
                })
        }
    }

    onFrequencyChange = (value) => {
        this.setState({
            frequencyLoading: true,
        })
        let data = {
            frequency: value
        }
        updateDbox(this.dboxId, data)
            .then(res => {
                let dbox = this.state.dbox;
                dbox.frequency = value;
                this.setState({
                    dbox: { ...dbox },
                    frequencyLoading: false,
                })
                this.onDboxSyncClick();
            }).catch(err => {
                message.error('更新失败');
                this.setState({
                    frequencyLoading: false,
                })
            })
    }

    onInitialClick = () => {
        if (this.state.dbox.work_status === DboxStatusTypesEnum.ONLINE) {
            message.warning('请先关闭采集箱!');
            return;
        }
        this.setState({
            initializeLoading: true,
        })
        dboxInitialize(this.dboxId)
            .then(res => {
                if (res.data === false) {
                    message.error('初始化失败');
                    this.setState({
                        initializeLoading: false,
                    })
                    return;
                }
                let dbox = this.state.dbox;
                dbox.is_init = true;
                message.success('采集箱初始化完成');
                this.setState({
                    initializeLoading: false,
                    dbox: { ...dbox }
                })
            }).catch(err => {
                message.error('初始化失败');
                console.log(err);
                this.setState({
                    initializeLoading: false,
                })
            })
    }

    saveDboxFigure = (url) => {
        updateDbox(this.dboxId, {
            figure_url: url
        }).then(res => {
            this.state.dbox.figure_url = url;
            this.setState({
                dbox: { ...this.state.dbox }
            })
        })
    }

    onDboxSyncClick = () => {
        let id = this.dboxId;
        if (!id) return;
        this.setState({
            dboxSyncLoading: true,
        });
        dboxSynch(id)
            .then(res => {
                if (!res.data) {
                    throw new Error('采集箱同步失败');
                }
                message.success('数据上载同步成功!');
                let d: dbox = { ...this.state.dbox };
                d.is_synch = true;
                this.setState({
                    dbox: d,
                })
            }).catch(err => {
                // if (!!err.data) {
                //     message.error(err.data?.message);
                // } else {
                //     message.error(err);
                // }
                console.log(err);
                let d: dbox = { ...this.state.dbox };
                d.is_synch = false;
                this.setState({
                    dbox: d,
                })
            }).finally(() => {
                this.setState({
                    dboxSyncLoading: false,
                });
            })
    }

    render() {
        if (!this.state.dbox) {
            return <Spin size="large" />;
        }
        let dbox: dbox = this.state.dbox;
        let { dboxSyncLoading } = this.state;

        const DboxStaticFrequencyOptions = (
            <Fragment>
                <Option value={1}>1 秒</Option>
                <Option value={2}>2 秒</Option>
                <Option value={5}>5 秒</Option>
                <Option value={10}>10 秒</Option>
                <Option value={30}>30 秒</Option>
                <Option value={60}>1 分钟</Option>
                <Option value={2 * 60}>2 分钟</Option>
                <Option value={5 * 60}>5 分钟</Option>
                <Option value={10 * 60}>10 分钟</Option>
                <Option value={15 * 60}>15 分钟</Option>
                <Option value={30 * 60}>30 分钟</Option>
                <Option value={60 * 60}>1 小时</Option>
                <Option value={360 * 60}>6 小时</Option>
                <Option value={1440 * 60}>24 小时</Option>
            </Fragment>
        )
        const DboxDynamicFrequencyOptions = (
            <Fragment>
                <Option value={1}>1 Hz</Option>
                <Option value={2}>2 Hz</Option>
                <Option value={5}>5 Hz</Option>
                <Option value={10}>10 Hz</Option>
                <Option value={20}>20 Hz</Option>
                {/* <Option value={30}>30 Hz</Option> */}
                <Option value={50}>50 Hz</Option>
                <Option value={100}>100 Hz</Option>
            </Fragment>
        )

        return (
            <div className="dbox-detail-container">
                <Row gutter={[10, 10]} className="content">
                    <Col xxl={16} xs={24}>
                        <div className="card properties">
                            {/* TODO */}
                            <DeviceFigure
                                uploadUrl={figureUploadUrl}
                                imgUrl={dbox.figure_url}
                                afterUpload={this.saveDboxFigure} />
                            <div className="info fill-remaining-space">
                                <DboxInfo dbox={dbox} />
                                <div className="operation-pane">
                                    <div className="term">
                                        <div className="label">初始化</div>
                                        <span className="detail">
                                        <Button
                                            type={dbox.is_init ? 'dashed' : 'primary'}
                                            size="small"
                                            shape="round"
                                            onClick={this.onInitialClick}
                                            loading={this.state.initializeLoading}>执行</Button>
                                        </span>
                                    </div>
                                    {dbox.is_init && (
                                        <div className="term">
                                            <div className="label">开关</div>
                                            <span className="detail">
                                                <Switch
                                                    checkedChildren="开启"
                                                    unCheckedChildren="关闭"
                                                    checked={dbox.work_status === DboxStatusTypesEnum.ONLINE}
                                                    loading={this.state.statusLoading}
                                                    onChange={this.onDboxStatusChange} />
                                            </span>
                                        </div>
                                    )}
                                    {/* <div className="term">
                                        <div className="label">开关</div>

                                        {dbox.is_init && (
                                            <span className="detail">
                                                <Switch
                                                    checkedChildren="开启"
                                                    unCheckedChildren="关闭"
                                                    checked={dbox.work_status === DboxStatusTypesEnum.ONLINE}
                                                    loading={this.state.statusLoading}
                                                    onChange={this.onDboxStatusChange} />
                                            </span>
                                        )}
                                        <span className="detail">{
                                            dbox.is_init ?
                                                <Switch
                                                    checkedChildren="开启"
                                                    unCheckedChildren="关闭"
                                                    checked={dbox.work_status === DboxStatusTypesEnum.ONLINE}
                                                    loading={this.state.statusLoading}
                                                    onChange={this.onDboxStatusChange} /> :
                                                <Button
                                                    type="primary"
                                                    onClick={this.onInitialClick}
                                                    loading={this.state.initializeLoading}>初始化采集箱</Button>
                                        }</span>
                                    </div> */}
                                    <div className="term">
                                        {dbox.type === DboxTypesEnum.STATIC ? (
                                            <div className="label">测量间隔</div>
                                        ) : (<div className="label">测量频次</div>)}
                                        <span className="detail">{
                                            <Select
                                                style={{ width: 100 }}
                                                defaultValue={dbox.frequency}
                                                size="small"
                                                loading={this.state.frequencyLoading}
                                                onChange={this.onFrequencyChange}>
                                                    {DboxStaticFrequencyOptions}
                                                {/* {dbox.type === DboxTypesEnum.STATIC ? (
                                                    DboxStaticFrequencyOptions
                                                ) :
                                                    (
                                                        DboxDynamicFrequencyOptions
                                                    )} */}
                                            </Select>
                                        }</span>
                                    </div>
                                    <div className="term">
                                        <div className="label">电压</div>
                                        <span className="detail">{dbox.voltage} V</span>
                                    </div>
                                    <div className="term">
                                        <div className="label">上次测量时间</div>
                                        <span className="detail">
                                            {dbox.last_measure_time ? (
                                                dayjs(dbox.last_measure_time).format('YYYY-MM-DD HH:mm')

                                            ) : '无'}
                                        </span>
                                    </div>
                                    {(!dbox.is_synch || dboxSyncLoading) && (
                                        <div className="term">
                                            {!dboxSyncLoading && (
                                                <Fragment>
                                                    <Tooltip title="指：数据库中的配置与采集箱硬件配置不一致">
                                                        <InfoCircleOutlined style={{ color: '#ff4d4f' }} />
                                                    </Tooltip>
                                                    <div>未与硬件同步</div>
                                                </Fragment>
                                            )}
                                            {dboxSyncLoading && (
                                                <div>正在与硬件同步</div>
                                            )}
                                            <span className="detail">
                                                <Tooltip title="重新上载">
                                                    <Button
                                                        type="link"
                                                        onClick={this.onDboxSyncClick}
                                                        icon={<SyncOutlined spin={dboxSyncLoading} />}></Button>
                                                </Tooltip>
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </Col>
                    <Col xs={12} xxl={4}>
                        <DeviceManageHistory
                            onChange={this.changeDboxManageStatus}
                            currentStatus={dbox.manage_status}
                            statusHistory={this.state.statusHistory} />
                    </Col>
                    <Col xs={12} xxl={4}>
                        <DeviceException deviceExceptions={this.state.dboxExceptions} />
                    </Col>
                </Row>
                <div className="sensors-pane">
                    <DboxSensorPane
                        dbox={this.state.dbox}
                        afterSave={this.onDboxSyncClick} />
                </div>
            </div>
        )
    }
}
