import axios from "../axios"
import { AxiosResponse } from "axios"

export function updateNativeLicense(license: string, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/native/license`, null, {
        params: {
            license: license,
            ...params,
        }
    })
}