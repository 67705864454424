import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { ProjectContext } from '@/config/context';
import { RProps } from '@/config/props';
import { material } from '@/interface/model';
import { queryMaterialBySid, updateMaterial } from '@/config/api/model';
import './material-detail.less';
import MaterialPanel from '@/components/model/material-panel';

interface IProps extends RProps {
}

interface IState {
    materialDetail: material
    saveLoading: boolean
    isEditing: boolean
}

@inject('routeStore')
@observer
export default class MaterialDetail extends Component<IProps, IState> {
    static contextType = ProjectContext;
    sid: number;
    state = {
        materialDetail: null,
        saveLoading: false,
        isEditing: false,
    }

    constructor(props) {
        super(props);
        let id = this.props.match.params['sid'];
        this.props.routeStore.addRoutePane(`材料${id}`, this.props.match.url);
        this.sid = id;
        // this.generateData(id);
    }

    componentDidMount() {
        this.findMaterial();
    }

    findMaterial = () => {
        queryMaterialBySid(this.sid, this.context)
        .then(res => {
            console.log(res.data)
            this.setState({
                materialDetail: res.data
            })
        })
    }

    componentDidUpdate() {
        let id = this.props.match.params['sid'];
        if (this.sid !== id) {
            this.sid = id;
            this.findMaterial();
        }
    }

    onMaterialSave = (values) => {
        this.setState({
            saveLoading: true,
        })
        console.log(values)
        let id = this.state.materialDetail?.id;
        if (!id) {
            return;
        }
        updateMaterial(this.context, id, values)
        .then(res => {
            this.setState({
                saveLoading: false,
                isEditing: false,
            })
        })
    }
    
    render() {
        return (
            <div className="material-detail-container">
                <MaterialPanel 
                isEditing={this.state.isEditing}
                materialDetail={this.state.materialDetail} 
                onCancel={() => this.setState({isEditing: false})}
                onEdit={() => this.setState({isEditing: true})}
                onSave={this.onMaterialSave}
                saveLoading={this.state.saveLoading}
                />
            </div>
        )
    }
}
