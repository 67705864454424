import React, { Component } from 'react'
import { Form, Select, Space, Button, Input, message } from 'antd'
import './sensor-position.less';
import { globalInstall } from '@/interface/install';
import { sensor } from '@/interface/sensor';
import { queryMobjects, queryMobjectPatternList } from '@/config/api/mobject';
import { ProjectContext } from '@/config/context';
import { mobject, pattern } from '@/interface/mobject';
import { FormInstance } from 'antd/lib/form';
import { updateSensor } from '@/config/api/sensor';
import { DistributionTypes } from '../mobject/quantity-pattern-setting';
const { Option } = Select;

interface IProps {
    gInstalls?: globalInstall[]
    sensorDetail?: sensor
    onCancel?: () => void
    afterSave?: (data: any) => void
}
interface IState {
    saveLoading: boolean,
    mobjects: mobject[],
    patterns: pattern[],
}
export default class SensorPositionPane extends Component<IProps, IState> {
    static contextType = ProjectContext;
    formRef = React.createRef<FormInstance>();
    state = {
        saveLoading: false,
        mobjects: [],
        patterns: [],
    }

    componentDidMount() {
        let sensorDetail = this.props.sensorDetail;
        this.formRef.current.setFieldsValue({
            mobject_id: sensorDetail?.mobject_id,
            group_id: sensorDetail?.group_id,
            location: sensorDetail?.location,
            install_id: sensorDetail?.install_id,
        })
        if (sensorDetail?.mobject_id) {
            let mobjectId = sensorDetail?.mobject_id;
            queryMobjectPatternList(mobjectId, {
                project_id: this.context
            }).then(res => {
                let patterns = res.data || [];
                patterns = patterns.filter(item => 
                    item.distribution_type === DistributionTypes.SENSOR_DIRECT || 
                    item.distribution_type === DistributionTypes.SENSOR_MICRO);
                this.setState({
                    patterns: patterns
                })
            })
        }
        this.generateData();
    }

    generateData = () => {
        queryMobjects({
            project_id: this.context,

        }).then(res => {
            let mobjects = res.data || [];
            this.setState({
                mobjects: mobjects
            })
        })
    }

    onMobjectSelectChange = (value) => {
        let mobjectId = value;
        queryMobjectPatternList(mobjectId, {
            project_id: this.context
        }).then(res => {
            let patterns = res.data || [];
            patterns = patterns.filter(item => 
                item.distribution_type === DistributionTypes.SENSOR_DIRECT || 
                item.distribution_type === DistributionTypes.SENSOR_MICRO);
            this.formRef.current.setFieldsValue({
                group_id: null,
            })
            this.setState({
                patterns: patterns
            })
        })
    }

    onSave = (values) => {
        if (!this.props.sensorDetail || !this.props.sensorDetail.id) {
            message.error('发生错误');
            return;
        }
        let currentPattern: pattern = this.state.patterns.find(item => item.quantity_group_id === values.group_id);
        let data = {
            install_id: values.install_id || null,
            group_id: currentPattern?.quantity_group_id || null,
            location: values.location,
            mobject_id: values.mobject_id,
        };
        updateSensor(this.props.sensorDetail.id, data)
        .then(res => {
            this.props.afterSave(data);
        })
    }

    render() {
        let { mobjects, patterns } = this.state;
        return (
            <div className="sensor-position-container">
                <Form wrapperCol={{ span: 14 }} size="small" autoComplete="off" ref={this.formRef} onFinish={this.onSave}>
                    <Form.Item label="监测对象" name="mobject_id">
                        <Select
                            placeholder="可键入搜索"
                            showSearch
                            onChange={this.onMobjectSelectChange}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {mobjects?.map(item => (
                                <Option key={item.id} value={item.id}>{item.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="分布模式" name="group_id">
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {patterns?.map(item => (
                                <Option key={item.id} value={item.quantity_group_id}>{item.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="安装位置" name="location">
                        <Input placeholder="如 a/b/c/d 等" />
                    </Form.Item>
                    {/* <Form.Item label="安装方法" name="install_id">
                        <Select>
                            {this.props.gInstalls?.map(item => (
                                <Option key={item.id} value={item.id}>{item.name}</Option>
                            ))}
                        </Select>
                    </Form.Item> */}

                    <div>
                        <Space>
                            <Button type="primary" htmlType="submit" loading={this.state.saveLoading}>保存</Button>
                            <Button onClick={this.props.onCancel}>取消</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        )
    }
}
