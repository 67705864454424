import React, { Component } from 'react'
import { ProjectContext } from '../../config/context';
import { quantity, globalQuantity, warningLimit } from '../../interface/quantity';
import axios from '../../config/axios';
import { mobject } from '../../interface/mobject';
import './evaluate-board.less';
import { Table, Tooltip, Button } from 'antd';
import AlertTag from '../alert-tag';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import { constage } from '@/interface/constage';
import dayjs from 'dayjs';
import { Transfer2DisplayScale } from '@/utils/utils';
import { QuantityName } from '../QuantityName';
import { queryDashboardQuantities } from '@/config/api/dashboard';
import { QuantityLevelTypes } from '../mobject/quantity-pattern-setting';
import { queryQuantityCurrentDataList } from '@/config/api/quantity-data';
import { RightOutlined } from '@ant-design/icons';
import { Gprops } from '@/config/props';
import { withRouter } from 'react-router-dom';
import { MobjectBaseType } from '@/utils/type';
import classnames from 'classnames';

const { Column } = Table;

interface IProps extends Gprops {
    projectId: number,
    bordered: boolean,
    onEvaluationAllBtnClick?: () => void,
    onReturnStatistic?: (text: string, color: string) => void
}

interface IState {
    quantities: quantity[],
    displayQuantities: quantity[],
    evaluationCount: number[],
    loading: boolean
}

export interface mobjectQuantity {
    mobject: mobject,
    quantity: quantity,
    grade: number,
}


class EvaluateBoard extends Component<IProps, IState> {
    static contextType = ProjectContext;
    projectGlobalQuantities: globalQuantity[] = [];
    timer: any;
    state = {
        quantities: [],
        displayQuantities: [],
        evaluationCount: [0, 0, 0, 0, 0],
        loading: false,
    }

    componentDidMount() {
        this.generateDataV2();
        // this.timer = setInterval(
        //     () => this.generateData(),
        //     1000 * 60,
        // );
    }

    componentWillUnmount() {
        // clearInterval(this.timer);
    }

    generateData = () => {
        this.setState({
            loading: true
        })
        // const projectId = this.context;
        const projectId = this.props.projectId;
        let queryProjectQuantitiesPromise = queryProjectGlobalQuantities(projectId);
        let queryQuantitiesPromise = queryDashboardQuantities({
            project_id: projectId,
            quantity_level: QuantityLevelTypes.OTHER_Q,
            is_evaluate: true,
        });
        console.log('start____________________')
        axios.all([queryProjectQuantitiesPromise, queryQuantitiesPromise])
            .then(res => {
                console.log(res);
                let pQuantities: globalQuantity[] = res[0].data || [];
                this.projectGlobalQuantities = pQuantities;
                let data: mobjectQuantity[] = res[1].data || [];
                data = data.filter(item => !!item.mobject);
                let quantities = data.map((item: mobjectQuantity) => {
                    let q = item.quantity;
                    q.mobject = item.mobject;
                    q.mobject_name = item.mobject?.name || '';
                    let constages = item.mobject?.constages || [];
                    constages = constages.sort((a, b) => dayjs(a.start_time).valueOf() - dayjs(b.start_time).valueOf());
                    q.constages = [...constages];

                    let pQuantity = pQuantities.find(p => p.type === q.type);
                    q.unit_type = pQuantity?.unit_type || '';
                    q.chinese_name = pQuantity?.chinese_name || '';
                    q.precision = pQuantity?.precision || '1';
                    // let scale = this.unitmaps[gQuantity?.unit_type][quantity?.unit_type] || 1;
                    let scale = Transfer2DisplayScale(q.unit_type);
                    q.scale = scale;
                    q.valid_data = pQuantity.valid_data;
                    return q;
                });
                quantities = quantities.filter(item => item.is_evaluate) || [];
                this.setState({
                    quantities: quantities,
                })
                let qids = quantities.map(item => item.id);
                let queryQuantityCurrentDataPromise = queryQuantityCurrentDataList({
                    project_id: projectId,
                    quantity_ids: qids,
                })
                return queryQuantityCurrentDataPromise;
            }).then(res => {
                // 请求当前数值和评估等级
                let dataList = res.data || [];
                let evaluationCount = this.state.evaluationCount;
                evaluationCount = [0, 0, 0, 0, 0];
                dataList.forEach(item => {
                    if (!item) {
                        return;
                    }
                    let id = +item['id'];
                    let value = +item['value'];
                    let q: quantity = this.state.quantities.find(qs => qs.id === id);
                    if (!!q) {
                        q.current_value = value * q?.scale || null;
                        if (q.valid_data != null && Math.abs(q.valid_data) < Math.abs(value)) {
                            q.current_grade = -1;
                        } else if (!q.is_evaluate) {
                            q.current_grade = 0;
                        } else {
                            q.current_grade = this.checkQuantityEvaluation(q.warning_limit, value, q.constages);
                        }
                        if (q.current_grade > 0) {
                            evaluationCount[q.current_grade - 1]++;
                        }
                    }

                })
                let quantities: quantity[] = this.state.quantities;
                quantities = quantities.sort((a, b) => a.current_grade < b.current_grade || !a.current_grade ? 1 : -1);
                this.setState({
                    evaluationCount: [...evaluationCount],
                    quantities: [...quantities],
                    displayQuantities: quantities.slice(0, 8),
                    loading: false
                })
            })
    }

    generateDataV2 = () => {
        this.setState({
            loading: true
        })
        // const projectId = this.context;
        const projectId = this.props.projectId;
        let queryProjectQuantitiesPromise = queryProjectGlobalQuantities(projectId);
        let queryQuantitiesPromise = queryDashboardQuantities({
            project_id: projectId,
            quantity_level: QuantityLevelTypes.OTHER_Q,
            is_evaluate: true,
        });
        let evaluationCount = this.state.evaluationCount;
        evaluationCount = [0, 0, 0, 0, 0];
        axios.all([queryProjectQuantitiesPromise, queryQuantitiesPromise])
            .then(res => {
                let pQuantities: globalQuantity[] = res[0].data || [];
                this.projectGlobalQuantities = pQuantities;
                let data: mobjectQuantity[] = res[1].data;
                data = data.filter(item => !!item.mobject);


                let quantities = data.map((item: mobjectQuantity) => {
                    let q = item.quantity;
                    q.mobject = item.mobject;
                    q.mobject_name = item.mobject?.name || '';
                    let pQuantity = pQuantities.find(p => p.type === q.type);
                    q.unit_type = pQuantity?.unit_type || '';
                    q.chinese_name = pQuantity?.chinese_name || '';
                    q.precision = pQuantity?.precision || '1';
                    let scale = Transfer2DisplayScale(q.unit_type);
                    q.scale = scale;
                    q.valid_data = pQuantity.valid_data;
                    // 后端直接传回当前等级
                    q.current_grade = item.grade;
                    // // 统计总数
                    // if (q.current_grade > 0) {
                    //     evaluationCount[q.current_grade - 1]++;
                    // }

                    return q;
                });

                this.setState({
                    quantities: quantities,
                    // evaluationCount: [...evaluationCount],
                })
                let qids = quantities.map(item => item.id);
                let queryQuantityCurrentDataPromise = queryQuantityCurrentDataList({
                    project_id: projectId,
                    quantity_ids: qids,
                })
                return queryQuantityCurrentDataPromise;
            }).then(res => {
                // 请求当前数值和评估等级
                let dataList = res.data || [];
                // let evaluationCount = this.state.evaluationCount;
                // evaluationCount = [0, 0, 0, 0, 0];
                dataList.forEach(item => {
                    if (!item) {
                        return;
                    }
                    let id = +item['id'];
                    let value = +item['value'];
                    let q: quantity = this.state.quantities.find(qs => qs.id === id);
                    if (!!q) {
                        q.current_value = value * q?.scale || null;
                        // if (!q.is_evaluate) {
                        //     q.current_grade = 0;
                        // } else {
                        //     q.current_grade = this.checkQuantityEvaluation(q.warning_limit, value, q.constages);
                        // }
                        if (q.valid_data != null && Math.abs(q.valid_data) < Math.abs(value)) {
                            q.current_grade = -1;
                        } else if (q.current_grade > 0) {
                            evaluationCount[q.current_grade - 1]++;
                        }
                    }

                })
                let quantities: quantity[] = this.state.quantities;
                quantities = quantities.sort((a, b) => a.current_grade < b.current_grade || !a.current_grade ? 1 : -1);
                this.setState({
                    quantities: [...quantities],
                    displayQuantities: quantities.slice(0, 8),
                    loading: false,
                    evaluationCount: [...evaluationCount],
                })
                if (this.props.onReturnStatistic) {
                    let maxCount = Math.max(...evaluationCount);
                    let maxCountIndex = evaluationCount.indexOf(maxCount);
                    let text = '';
                    let color = '';
                    switch (maxCountIndex) {
                        case 0:
                            text = "非常安全";
                            color = "#00944a";
                            break;
                        case 1:
                            text = "安全";
                            color = "#4fb981";
                            break;
                        case 2:
                            text = "重点关注";
                            color = "#efa829";
                            break;
                        case 3:
                            text = "预警";
                            color = "#f68240";
                            break;
                        case 4:
                            text = "紧急";
                            color = "#e45331";
                            break;
                    }
                    this.props.onReturnStatistic(text, color);
                }
            })
    }


    checkQuantityEvaluation = (warningLimits: warningLimit[], value: number, constages: constage[]) => {

        let time = dayjs().valueOf();
        if (constages.length === 0) return 0;
        let stage: constage = constages.find((item: constage) => dayjs(item.start_time).valueOf() <= time && dayjs(item.end_time).valueOf() >= time);
        if (!stage) stage = constages[constages.length - 1];
        let limit = warningLimits.find((item: warningLimit) => item.stage_id === stage.id)?.values;
        if (!limit)
            return 0;
        if (Math.abs(limit['t5']) < 1e-9)
            return 0;
        if (!value)
            return 0;
        if (limit['t5'] <= Math.abs(value)) {
            return 5;
        } else if (limit['t4'] <= Math.abs(value)) {
            return 4;
        } else if (limit['t3'] <= Math.abs(value)) {
            return 3;
        } else if (limit['t2'] <= Math.abs(value)) {
            return 2;
        } else {
            return 1;
        }
    }

    goToMobjectDetail = (record: quantity) => {
        if (!record.mobject) return;
        if (!record.group_id) return;
        let mobject = record.mobject;
        let typeName = MobjectBaseType[mobject?.type]['name'];
        let url = `/projects/${this.props.projectId}/mobjects/${typeName}/${mobject.id}?tab=2&group=${record.group_id}`;
        this.props.history.push(url);
    }

    render() {
        return (
            <div className="dashboard-card evaluate">
                <div className="evaluate-header">
                    <div className="title">指标评估</div>
                    <Button type="link" onClick={this.props.onEvaluationAllBtnClick}>全部<RightOutlined /></Button>
                </div>
                <div className="evaluate-content">
                    <div className="evaluate-table">
                        <Table
                            bordered={this.props.bordered}
                            dataSource={this.state.displayQuantities}
                            rowKey="id"
                            loading={this.state.loading}
                            pagination={false}
                            rowClassName={(record, index) => classnames({
                                'ribbon-row': index % 2 !== 0,
                                'row-click': true,
                            })}
                            onRow={record => {
                                return {
                                    onClick: () => this.goToMobjectDetail(record),
                                }
                            }}
                        >
                            <Column title="监测对象" dataIndex="mobject_name" align="center" />
                            <Column title="监测指标" key="quantity_name" align="center" render={(record: quantity) => <QuantityName record={record} />} />
                            <Column title="当前数值" key="current_value" align="right" render={(record: quantity) => (
                                `${record.current_value?.toFixed(+record.precision) || ''} ${record.unit_type}`
                            )} />
                            {/* <Column title="单位" dataIndex="unit_type" align="left" /> */}
                            <Column title="评估" key="current_grade" align="center" render={(record: quantity) => (
                                <AlertTag current_grade={record?.current_grade} />
                            )} />
                        </Table>
                    </div>
                    <div className="evaluate-statistic">
                        <Tooltip title="紧急">
                            <div className="pane t5">
                                {/* <div className="title">紧急</div> */}
                                <div className="count">{this.state.evaluationCount[4]}</div>
                            </div>
                        </Tooltip>
                        <Tooltip title="预警">
                            <div className="pane t4">
                                {/* <div className="title">预警</div> */}
                                <div className="count">{this.state.evaluationCount[3]}</div>
                            </div>
                        </Tooltip>
                        <Tooltip title="重点关注">
                            <div className="pane t3">
                                {/* <div className="title">重点关注</div> */}
                                <div className="count">{this.state.evaluationCount[2]}</div>
                            </div>
                        </Tooltip>
                        <Tooltip title="安全">
                            <div className="pane t2">
                                {/* <div className="title">安全</div> */}
                                <div className="count">{this.state.evaluationCount[1]}</div>
                            </div>
                        </Tooltip>
                        <Tooltip title="非常安全">
                            <div className="pane t1">
                                {/* <div className="title">非常安全</div> */}
                                <div className="count">{this.state.evaluationCount[0]}</div>
                            </div>
                        </Tooltip>

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(EvaluateBoard);