import React, { Component } from 'react'
import { Row, Col, Form, Input, Select, Space, Button, message } from 'antd'
import { MobjectType } from '@/routes/mobject/mobject-page';
import { mobject } from '@/interface/mobject';
import { queryNodes, queryElements, querySections, queryMaterials } from '@/config/api/model';
import { node, element, section, material } from '@/interface/model';
import './mobject-info-edit.less'
import { FormInstance } from 'antd/lib/form';
import { paramDict2Array, handleQuantityName, transferParamsToDisplayScale, transferParamsToBackendScale } from '@/utils/utils';
import { NumericInput } from '..';
import { MobjectSubtypeName } from './mobject-info';
import { constage } from '@/interface/constage';
import dayjs from 'dayjs';
import { queryConstages } from '@/config/api/constage';
import { ProjectContext } from '@/config/context';
import { queryGlobalMobjectParams } from '@/config/api/global-mobject';
import { InlineMath } from 'react-katex';
import { ParamUnits } from '@/utils/unit-type';
const { Option } = Select;

interface IProps {
    mobjectDetail?: mobject,
    isEdit?: boolean
    onCancel?: () => void
    onSave?: (values) => void
    projectId: number,
    saveLoading: boolean
}

interface IState {
    nodes: node[],
    elements: element[]
    sections: section[],
    materials: material[],
    data: {},
    constages: constage[],

    paramsPrototype: any,
}


export default class MobjectInfoEdit extends Component<IProps, IState> {
    static contextType = ProjectContext;
    static defaultProps = {
        isEdit: true
    }
    formRef = React.createRef<FormInstance>();
    // paramsPrototype = {};
    state = {
        nodes: [],
        elements: [],
        sections: [],
        materials: [],
        data: null,
        constages: [],

        paramsPrototype: {},
    }

    componentDidMount() {
        queryConstages(this.context).then(res => {
            let data = Object.assign({}, this.props.mobjectDetail);
            let params = paramDict2Array(data.params);
            params = transferParamsToDisplayScale(params);
            data = Object.assign(data, {
                params: params,
                stage_ids: data.constages?.map(item => item.id)
            })
            this.formRef.current?.setFieldsValue(data)
            this.setState({
                data: Object.assign({}, data),
                constages: res.data || []
            })
        })
        if (!!this.props.mobjectDetail?.sub_type) {
            let value = this.props.mobjectDetail?.sub_type;
            this.onSubTypeChange(value);
        }
        this.onMaterialSearch();
        this.onSectionSearch();
    }

    onSave = (values) => {
        let stage_ids: number[] = values['stage_ids'] || [];
        let constages = this.state.constages.filter((c: constage) => stage_ids.some(y => c.id === y));
        let isConstageCross = this.checkConstageCross(constages);
        if (isConstageCross) {
            message.error('施工步时间有重叠，请检查!');
            return;
        }
        let params = [...values.params] || [];
        params = transferParamsToBackendScale(params);
        values = {...values, params: params}
        this.props.onSave(values);
    }

    checkConstageCross = (constages: constage[]) => {
        constages = constages.sort((a: constage, b: constage) => a.start_time > b.start_time ? 1 : -1)
        let res = false;
        if (constages.length <= 1) return res;
        for (let i = 1;i < constages.length;i++) {
            if (constages[i].start_time < constages[i-1].end_time) {
                res = true;
                break;
            }
        }
        return res;
    }

    onNodeSearch = (value) => {
        queryNodes(this.props.projectId, {
            search_sid: value,
        }).then(res => {
            this.setState({
                nodes: res.data?.data
            })

        })
    }

    onElementSearch = (value) => {
        queryElements(this.props.projectId, {
            search_sid: value,
        }).then(res => {
            this.setState({
                elements: res.data.data,
            })
        })
    }

    onMaterialSearch = (value?) => {
        queryMaterials(this.props.projectId, {
            search_sid: value,
        }).then(res => {
            this.setState({
                materials: res.data
            })
        })
    }

    onSectionSearch = (value?) => {
        querySections(this.props.projectId, {
            search_sid: value,
        }).then(res => {
            this.setState({
                sections: res.data
            })
        })
    }

    onFormValuesChange = (changeedValues, allValues) => {
        this.setState({
            data: Object.assign({}, allValues)
        })
    }

    onElementChange = (value) => {
        let ele: element = this.state.elements.find(item => item.sid === value);
        this.formRef.current.setFieldsValue({
            material_sid: ele?.material_sid,
            section_sid: ele?.section_sid
        })
    }

    onSubTypeChange = (value) => {
        let mobjectType = this.props.mobjectDetail?.type;
        queryGlobalMobjectParams({
            type: mobjectType,
            sub_type: value, // TODO: 把请求params放到新建的modal里
        }).then(res => {
            let paramsPrototype = res.data;
            // this.paramsPrototype = paramDict2Array(paramsPrototype);
            
            // 为了让弹窗中的参数名称正常显示，放在了state里
            let params = transferParamsToDisplayScale(paramDict2Array(paramsPrototype));
            this.setState({
                paramsPrototype: params
            })
            this.formRef.current.setFieldsValue({
                params: params,
            })
        })
    }


    render() {
        return (
            <div className="mobject-info-edit">
                <Form ref={this.formRef} onFinish={this.onSave} onValuesChange={this.onFormValuesChange} autoComplete="off">
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item labelCol={{ span: 5 }} label="编号" name="name" rules={[
                                { required: true, message: '编号不能为空' }
                            ]}>
                                <Input placeholder="如：001" />
                            </Form.Item>
                            <Form.Item labelCol={{ span: 5 }} label="层级" name="type" rules={[
                                { required: true, message: '层级不能为空' }
                            ]}>
                                <Select disabled>
                                    <Option value={MobjectType.joints.type}>节点</Option>
                                    <Option value={MobjectType.members.type}>构件</Option>
                                    <Option value={MobjectType.structures.type}>结构</Option>
                                    <Option value={MobjectType.environments.type}>环境</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item labelCol={{ span: 5 }} label="类别" name="sub_type" rules={[
                                { required: true, message: '类别不能为空' }
                            ]}>
                                <Select onChange={this.onSubTypeChange}>
                                    {paramDict2Array(MobjectSubtypeName[this.state.data?.type])
                                        .map((item, idx) => (
                                            <Option key={idx} value={+item.name}>{item.value}</Option>
                                        ))}
                                </Select>
                            </Form.Item>
                            {this.state.data?.type === MobjectType.joints.type ? (
                                <Form.Item labelCol={{ span: 5 }} label="节点号" name="ref_node_sid">
                                    <Select
                                        showSearch
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={this.onNodeSearch}
                                        defaultActiveFirstOption={false}
                                        notFoundContent={null}>
                                        {this.state.nodes?.map((d: node, idx) => <Option key={idx} value={d.sid}>{d.sid}</Option>)}
                                    </Select>
                                </Form.Item>
                            ) : null}
                            {this.state.data?.type === MobjectType.members.type ? (
                                <Form.Item labelCol={{ span: 5 }} label="单元号" name="ref_member_sid">
                                    <Select
                                        showSearch
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={this.onElementSearch}
                                        onChange={this.onElementChange}
                                        defaultActiveFirstOption={false}
                                        notFoundContent={null}>
                                        {this.state.elements?.map((d: element, idx) => <Option key={idx} value={d.sid}>{`${d.sid}`}</Option>)}
                                    </Select>
                                </Form.Item>
                            ) : null}
                            {this.state.data?.type === MobjectType.joints.type || this.state.data?.type === MobjectType.members.type ? (
                                <Form.Item labelCol={{ span: 5 }} label="材料号" name="material_sid">
                                    <Select
                                        showSearch
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={this.onMaterialSearch}
                                        defaultActiveFirstOption={false}
                                        notFoundContent={null}>
                                        {this.state.materials.map((d: material, idx) => <Option key={idx} value={d.sid}>{`${d.sid}-${d.name}`}</Option>)}
                                    </Select>
                                </Form.Item>
                            ) : null}
                            {this.state.data?.type === MobjectType.members.type ? (
                                <Form.Item labelCol={{ span: 5 }} label="截面号" name="section_sid">
                                    <Select
                                        showSearch
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={this.onSectionSearch}
                                        defaultActiveFirstOption={false}
                                        notFoundContent={null}>
                                        {this.state.sections.map((d: section, idx) => <Option key={idx} value={d.sid}>{`${d.sid}-${d.name}`}</Option>)}
                                    </Select>
                                </Form.Item>
                            ) : null}

                        </Col>
                        <Col span={10} offset={2} className="params">
                            <div style={{ marginBottom: '12px' }}>参数:</div>
                            <Form.List name="params">
                                {(fields, { add, remove }) => {
                                    return (
                                        <div>
                                            {fields.map((field, idx) => (
                                                /if_fati/.test(this.state.paramsPrototype[idx]?.name) ?
                                                    <Form.Item
                                                        labelCol={{ span: 7 }}
                                                        {...field}
                                                        label={
                                                            '验算疲劳'
                                                        }
                                                        name={[field.name, 'value']}
                                                        rules={[{ required: true, message: '参数不能为空' }]}>
                                                        <Select size="small">
                                                            <Option value={0}>否</Option>
                                                            <Option value={1}>是</Option>
                                                        </Select>
                                                    </Form.Item>
                                                    :
                                                    <Form.Item
                                                        labelCol={{ span: 7 }}
                                                        {...field}
                                                        label={
                                                            <InlineMath math={handleQuantityName(this.state.paramsPrototype[idx]?.name)} />
                                                        }
                                                        name={[field.name, 'value']}
                                                        rules={[{ required: true, message: '参数不能为空' }]}>
                                                        <NumericInput 
                                                        size="small" 
                                                        suffix={<span>{ParamUnits[this.state.paramsPrototype[idx]?.name]}</span>}/>
                                                    </Form.Item>
                                            ))}
                                        </div>
                                    )
                                }}
                            </Form.List>
                        </Col>
                        <Col span={24} style={{ marginTop: '24px' }}>
                            <Form.Item label="施工步" name="stage_ids">
                                <Select mode="multiple" placeholder="选择所属施工阶段，不允许时间重叠">
                                    {this.state.constages.map((d: constage, idx) => (
                                        <Option key={idx} value={d.id}>
                                            {`${d.code}${d.name} ${dayjs(d.start_time).format('YYYY-MM-DD HH:mm')}~${dayjs(d.end_time).format('YYYY-MM-DD HH:mm')}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'right', marginTop: '24px' }}>
                        <Space size="large">
                            <Button onClick={this.props.onCancel}>取消</Button>
                            <Button type="primary" htmlType="submit" loading={this.props.saveLoading}>保存</Button>
                        </Space>
                    </div>
                </Form>
            </div>

        )
    }
}
