import axios from "../axios"
import { AxiosResponse } from "axios"

export function queryGlobalQuantities(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/global/quantities`, {
        params: params
    })
}

export function queryUnitmaps(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/global/quantities/unitmaps`, {
        params: params
    })
}
