import React, { Component } from 'react'
import { quantity } from '@/interface/quantity';
import { queryMobjectDetail, queryQuantityGroups, queryMobjectPatternList } from '@/config/api/mobject';
import { DistributionTypes } from '../mobject/quantity-pattern-setting';
import { queryGlobalSensors } from '@/config/api/global-device';
import axios from '@/config/axios';
import { mobject, quantityGroup, pattern } from '@/interface/mobject';
import { globalSensor, sensor } from '@/interface/sensor';
import { querySensors } from '@/config/api/sensor';
import classnames from 'classnames';
import { patternPicStaticLocation } from '@/config/api/document';
import { Gprops } from '@/config/props';
import { withRouter } from 'react-router-dom';
import { ProjectContext } from '@/config/context';

interface IProps extends Gprops {
    selectedQuantity: quantity
}
interface IState {
    quantityGroups: quantityGroup[]
    mobjectDetail: mobject
}


class EvaluateSensorBoard extends Component<IProps, IState> {
    static contextType = ProjectContext;
    mobjectId: number = null;
    globalSensors: globalSensor[] = [];
    state = {
        quantityGroups: [],
        mobjectDetail: null,
    }

    componentDidMount() {
        let mobjectId = +this.props.selectedQuantity?.mobject_id;
        this.mobjectId = mobjectId;
        this.generateData();
    }

    componentDidUpdate() {
        let mobjectId = +this.props.selectedQuantity?.mobject_id;
        if (mobjectId !== this.mobjectId) {
            this.mobjectId = mobjectId;
            this.generateData();
        }
    }
    generateData = () => {
        let mobjectId = +this.props.selectedQuantity?.mobject_id;
        if (!mobjectId) return;

        // let mobjectObj: mobject;
        let queryMobjectPromise = queryMobjectDetail(mobjectId);
        let queryQuantityGroupsPromise = queryQuantityGroups(mobjectId, {
            project_id: this.context,
        });
        let queryPatternsPromise = queryMobjectPatternList(mobjectId, {
            distribution_type: [DistributionTypes.SENSOR_DIRECT, DistributionTypes.SENSOR_MICRO],
            project_id: this.context,
        });
        let queryGlobalSensorsPromise = queryGlobalSensors();
        axios.all([
            queryMobjectPromise,
            queryQuantityGroupsPromise,
            queryPatternsPromise,
            queryGlobalSensorsPromise]).then(res => {
                let mobjectDetail: mobject = res[0].data;
                let quantityGroups: quantityGroup[] = res[1].data;
                let patterns: pattern[] = res[2].data;
                this.globalSensors = res[3].data;

                quantityGroups = quantityGroups.filter(item =>
                    item.distribution_type === DistributionTypes.SENSOR_DIRECT ||
                    item.distribution_type === DistributionTypes.SENSOR_MICRO);
                // mobjectObj = mobjectDetail;
                let querySensorsPromiseList = [];
                quantityGroups.forEach((group: quantityGroup) => {
                    let currentPattern = patterns.find(item => item.id === group.pattern_id);
                    group.current_pattern = currentPattern;
                    let promise = querySensors({
                        group_id: group.id,
                        project_id: this.context,
                    })
                    querySensorsPromiseList.push(promise);
                })
                this.setState({
                    mobjectDetail: mobjectDetail,
                    quantityGroups: quantityGroups,
                });
                // this.findSensors();
                return axios.all([...querySensorsPromiseList])
            }).then(res => {
                this.state.quantityGroups.forEach((group: quantityGroup, idx) => {
                    let sensors: sensor[] = res[idx].data;
                    group.sensors = sensors;
                })
                this.setState({
                    quantityGroups: [...this.state.quantityGroups]
                })
            })
    }

    findSensorTypeName = (type: number) => {
        let global = this.globalSensors.filter(item => item.type === type)[0];
        return global ? global.name : '';
    }

    render() {
        return (
            <div className="dashboard-evaluate-card sensor">
                <div className="header">
                    <div className="title">传感器分布</div>
                </div>
                <div className="content">
                    <div className="patterns">
                        {this.state.quantityGroups.map((group: quantityGroup) => (
                            group.current_pattern ?
                                (
                                    <div className={classnames({
                                        'pattern-item': true,
                                        'pattern-item-horizon': true,
                                    })} key={group.id}>
                                        <div className="figure" style={{ height: '150px', width: '200px' }}>
                                            <div>
                                                <img
                                                    width="100%"
                                                    height="100%"
                                                    alt="模式图"
                                                    src={`${patternPicStaticLocation}${group.current_pattern?.figure_url}`} />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="pattern-name" >{group.current_pattern?.name}</div>
                                            {group.sensors?.map((item: sensor, idx) => (
                                                <div
                                                    className="can-click"
                                                    key={idx}
                                                    style={{ lineHeight: '1.8' }}
                                                    onClick={() => this.props.history.push(`/projects/${this.context}/sensors/${item.id}`)}>
                                                    <span>{this.findSensorTypeName(item.type)}</span>
                                                    <span>{`-${item.location}`}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )
                                :
                                null
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(EvaluateSensorBoard);