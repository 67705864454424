import React, { Component } from 'react'
import { Tabs, List, Button, Row, Col } from 'antd'
import axios from '../../config/axios';
import { warning } from '../../interface/warning';
import { ProjectContext } from '../../config/context';
import './alert-board.less';
import IconFont from '../../config/iconfont';
import dayjs from 'dayjs';
import AlertTag from '../alert-tag';
import { queryGlobalSensors, queryGlobalDboxs } from '@/config/api/global-device';
import { inject, observer } from 'mobx-react';
import GlobalDeviceStore from '@/store/global-device';
import { globalDbox } from '@/interface/dbox';
import { globalSensor } from '@/interface/sensor';
import { deviceException } from '@/interface/exception';
import { queryExceptions } from '@/config/api/exception';
import { queryWarnings } from '@/config/api/warning';
import { queryMobjectDetail } from '@/config/api/mobject';
import { queryProjectGlobalQuantities } from '@/config/api/quantity-setting';
import { globalQuantity, quantity } from '@/interface/quantity';
import { Gprops } from '@/config/props';
import { withRouter } from 'react-router-dom';
import { MobjectBaseType } from '@/utils/type';
import { queryQuantityDetail } from '@/config/api/quantity';

const { TabPane } = Tabs;

interface IProps extends Gprops {
    globalDeviceStore?: GlobalDeviceStore,
}

interface IState {
    warnings?: warning[],
    exceptions?: deviceException[]
    warningLoading?: boolean
}

@inject('globalDeviceStore')
@observer
class AlertHistoryBoard extends Component<IProps, IState> {

    static contextType = ProjectContext;
    globalDboxs: globalDbox[] = [];
    globalSensors: globalSensor[] = [];
    pQuantities: globalQuantity[] = [];
    state = {
        warnings: [],
        exceptions: [],
        warningLoading: false,
    }

    componentDidMount() {
        const project_id = this.context;
        this.findWarnings();
        let dboxPromise = queryGlobalDboxs();
        let sensorPromise = queryGlobalSensors();
        let exceptionPromise = queryExceptions({
            project_id: project_id,
            size: 5
        });
        axios.all([dboxPromise, sensorPromise, exceptionPromise])
            .then((res) => {
                this.globalDboxs = res[0].data;
                this.globalSensors = res[1].data;
                // 存入全局store
                this.props.globalDeviceStore.globalDboxs(this.globalDboxs);
                this.props.globalDeviceStore.globalSensors(this.globalSensors);
                let exceptions: [] = res[2].data;
                this.setState({
                    exceptions: exceptions
                });
            })
    }

    findWarnings = () => {
        const project_id = this.context;
        let warnings: warning[] = [];
        this.setState({
            warningLoading: true,
        })
        let queryPQuantityPromise = queryProjectGlobalQuantities(project_id);
        let queryWarningPromise = queryWarnings({
            project_id: project_id,
            size: 5
        })
        axios.all([queryPQuantityPromise, queryWarningPromise]).then(res => {
            warnings = res[1].data;
            this.pQuantities = res[0].data;
            let promiseList = warnings.map(item => {
                let mobjectId = item.monitor_object_id;
                return queryMobjectDetail(mobjectId);
            })
            return axios.all([...promiseList]);
        }).then(res => {
            let mobjects = res.map(item => item.data);
            warnings.forEach((item: warning, idx) => {
                if (mobjects[idx] == null) return;
                item.mobject_name = mobjects[idx]?.name || '';
                item.mobject_type = mobjects[idx]?.type || null;
                let pQuantity = this.pQuantities.find(p => p.name === item.quantity_name);
                item.chinese_name = pQuantity?.chinese_name;
            })
            this.setState({
                warnings: warnings,
                warningLoading: false,
            })
        })
    }

    transformDeviceName = (deviceVariety: number, deviceType: number) => {
        if (deviceVariety === 1) {
            let res = this.globalDboxs.filter(item => item.type === deviceType);
            return res.length > 0 ? res[0].name : '未知采集箱';
        } else {
            let res = this.globalSensors.filter(item => item.type === deviceType);
            return res.length > 0 ? res[0].name : '未知传感器';
        }
    }

    onWarningClick = (value: warning) => {
        let quantityId = value.quantity_id;
        queryQuantityDetail(this.context, quantityId)
        .then(res => {
            let q: quantity = res.data;
            if (!q) return;
            let groupId = q.group_id;
            let mobjectId = value.monitor_object_id;
            let typeName = MobjectBaseType[value.mobject_type]['name'];
            let url = `/projects/${this.context}/mobjects/${typeName}/${mobjectId}?tab=2&group=${groupId}`;
            this.props.history.push(url);
        })
    }

    render() {
        return (
            <div className="dashboard-card alert">
                <Tabs defaultActiveKey="1" tabBarExtraContent={
                    <Button type="link" icon={<IconFont type="icon-right" />}></Button>
                }>
                    <TabPane tab="历史报警" key="1">
                        <List 
                        dataSource={this.state.warnings} 
                        split={false} 
                        loading={this.state.warningLoading}
                        renderItem={item => (
                            <List.Item key={item.id} style={{ padding: '6px 0' }}>
                                <Row className="alert-item" onClick={() => this.onWarningClick(item)}>
                                    <Col span={5}>
                                        <span>{item.mobject_name}</span>
                                    </Col>
                                    <Col span={6}>
                                        <span>{item.chinese_name}</span>
                                    </Col>
                                    <Col span={5}>
                                        <span>{<AlertTag current_grade={item.warning_grade} />}</span>
                                    </Col>
                                    <Col span={8}>
                                        <span>{dayjs(item.gmt_create).format('MM-DD HH:mm')}</span>
                                    </Col>
                                </Row>
                                {/* <div className="alert-item">
                                    <span>{item.mobject_name}</span>
                                    <span>{item.chinese_name}</span>
                                    <span>{<AlertTag current_grade={item.warning_grade} />}</span>
                                    <span>{dayjs(item.gmt_create).format('MM-DD HH:mm')}</span>
                                </div> */}
                            </List.Item>
                        )} />
                    </TabPane>
                    <TabPane tab="设备异常" key="2">
                        <List 
                        dataSource={this.state.exceptions} 
                        split={false} 
                        renderItem={item => (
                            <List.Item key={item.id} style={{ padding: '6px 0' }}>
                                <div className="alert-item">
                                    <span>{item.content}</span>
                                    <span>{this.transformDeviceName(item.device_variety, item.type)}</span>
                                    <span>{dayjs(item.create_time).format('MM-DD HH:mm')}</span>
                                </div>
                            </List.Item>
                        )} />
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

export default withRouter(AlertHistoryBoard);