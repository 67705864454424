import React, { Component } from 'react'
import { project } from '@/interface/project'
import { Col, Row, Progress, Divider, Button, Tag } from 'antd'
import dayjs from 'dayjs';
import { monitorRank, monitorType } from '@/utils/type';
import IconFont from '@/config/iconfont';
import { withRouter } from 'react-router-dom';
import { Gprops } from '@/config/props';

interface IProps extends Gprops {
    projectList: project[]
}
class ProjectCardPanel extends Component<IProps> {

    calculateProgress = (record) => {
        let start_time = record.start_time || dayjs();
        let period = record.period;
        let percent = 0;
        let diff = dayjs().diff(dayjs(start_time), 'day') / period * 100;
        if (diff > 0) percent = +diff.toFixed(0);
        if (percent > 100) percent = 100;
        return percent;
    }

    render() {
        return (
            <div className="project-card-panel">
                <Row gutter={24}>
                    {this.props.projectList.map((item, idx) => (
                        <Col xxl={4} xs={12} xl={6} key={idx}>
                            <div className="project-card">
                                <div className="title">{item.name}</div>
                                <div>
                                    <Progress percent={this.calculateProgress(item)} />
                                </div>
                                <div className="info">
                                    <div><IconFont type="icon-fuzerenicon" style={{ marginRight: '4px' }} />{item.leader_name}</div>
                                    <div><IconFont type="icon-leibie" style={{ marginRight: '4px' }} />{monitorType[item.monitor_type]}</div>
                                </div>
                                <div className="info">
                                    <div>
                                        {item.active ? <Tag color="#00944a">进行中</Tag> : <Tag color="#727272">关闭</Tag>}
                                        {item.working && (<Tag color="#ef8b52">正在监测</Tag>)}
                                    </div>
                                    <div>{monitorRank[item.monitor_rank]}</div>
                                </div>
                                <Divider className="divider" />
                                <div>
                                    <Button
                                        className="enter-btn"
                                        onClick={() => this.props.history.push(`/projects/${item.id}/dashboard`)}>
                                        进入项目
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    ))}


                </Row>
            </div>
        )
    }
}

export default withRouter(ProjectCardPanel);