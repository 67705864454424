import axios from "../axios"
import { AxiosResponse } from "axios"

export function queryGlobalSections(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/global/sections`, {
        params: params
    })
}

export function queryGlobalSectionsDetail(sectionId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/global/sections/${sectionId}`, {
        params: params
    })
}

export function queryEnterprisesSections(enterpriseId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/enterprises/${enterpriseId}/sections`, {
        params: params
    })
}

export function createEnterprisesSection(enterpriseId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/enterprises/${enterpriseId}/sections`, data, {
        params: params
    })
}

export function queryEnterprisesSectionsDetail(enterpriseId: number,sectionId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/enterprises/${enterpriseId}/sections/${sectionId}`, {
        params: params
    })
}

export function updateEnterpriseSectionDetail(enterpriseId: number, sectionId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/enterprises/${enterpriseId}/sections/${sectionId}`, data, {
        params: params
    })
}

export function deleteEnterpriseSectionDetail(enterpriseId: number,sectionId: number, ): Promise<AxiosResponse<any>> {
    return axios.delete(`/enterprises/${enterpriseId}/sections/${sectionId}`)
}

export function queryPrototypeSectionParams(params: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/prototype/sections/params`, {
        params: params
    })
}

export function calculateSectionParamOut(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/global/sections/calculation`, data, {
        params: params
    })
}

export function queryGlobalMaterials(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/global/materials`, {
        params: params
    })
}


export function queryGlobalMaterialsDetail(materialId:number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/global/materials/${materialId}`, {
        params: params
    })
}

export function queryEnterprisesMaterials(enterpriseId:number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/enterprises/${enterpriseId}/materials`, {
        params: params
    })
}


export function createEnterprisesMaterial(enterpriseId:number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/enterprises/${enterpriseId}/materials`, data, {
        params: params
    })
}

export function queryEnterprisesMaterialsDetail(enterpriseId:number, materialId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/enterprises/${enterpriseId}/materials/${materialId}`, {
        params: params
    })
}

export function updateEnterpriseMaterialDetail(enterpriseId:number, materialId: number, data: any, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/enterprises/${enterpriseId}/materials/${materialId}`, data, {
        params: params
    })
}

export function deleteEnterpriseMaterialDetail(enterpriseId:number, materialId: number,): Promise<AxiosResponse<any>> {
    return axios.delete(`/enterprises/${enterpriseId}/materials/${materialId}`)
}

export function queryPrototypeMaterialsParams( params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/prototype/materials/params`, {
        params: params
    })
}