import React, { Component } from 'react'
import TextArea from 'antd/lib/input/TextArea'
import ImgCrop from 'antd-img-crop'
import { Tag, Upload, Space, Button, Input, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { TweenOneGroup } from 'rc-tween-one';
import { createSiteConditions } from '@/config/api/site-conditions';
import { ProjectContext } from '@/config/context';
import { figureUploadUrl } from '@/config/api/document';

interface IProps {
    isEditAdding: boolean,
    onCancelClick?: () => void,
    afterSave?: () => void,
}

interface IState {
    fileList: any[],
    previewVisible: boolean
    previewImage: string
    conditionContent: string,
    tagsInputVisible: boolean,
    tagsInputValue: string,
    tags: string[],
    saveLoading: boolean,
}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export default class ConditionItemAdd extends Component<IProps, IState> {
    static contextType = ProjectContext;
    tagsInputRef = React.createRef<Input>();
    state = {
        fileList: [],
        previewVisible: false,
        previewImage: '',
        conditionContent: '',
        tags: [],
        tagsInputVisible: false,
        tagsInputValue: '',
        saveLoading: false,
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleFileChange = ({ file, fileList }) => {
        this.setState({
            fileList: fileList
        })
    }

    handleInputChange = (e) => {
        this.setState({
            conditionContent: e.target.value
        })
    }

    // ============ tags ============
    handleClose = removedTag => {
        const tags = this.state.tags.filter(tag => tag !== removedTag);
        this.setState({ tags });
    };

    showTagsInput = () => {
        this.setState({ tagsInputVisible: true }, () => this.tagsInputRef.current.focus());
    };

    handleTagsInputChange = e => {
        this.setState({ tagsInputValue: e.target.value });
    };

    handleTagsInputConfirm = () => {
        const { tagsInputValue } = this.state;
        let { tags } = this.state;
        if (tagsInputValue && tags.indexOf(tagsInputValue) === -1) {
            tags = [...tags, tagsInputValue];
        }
        this.setState({
            tags,
            tagsInputVisible: false,
            tagsInputValue: '',
        });
    };

    // ========== save ============

    onSave = () => {
        this.setState({
            saveLoading: true,
        })
        let figures = this.state.fileList
        .filter(file => file.status === 'done')
        .map(item => item.response.data);
        let data = {
            content: this.state.conditionContent,
            tags: this.state.tags,
            figures: figures
        }
        createSiteConditions(this.context, data)
        .then(res => {
            this.setState({
                saveLoading: false,
            })
            this.props.afterSave();
        }).catch( () => {
            this.setState({
                saveLoading: false,
            })
        })
    }

    render() {
        const tags = this.state.tags;
        return (
            <div className="condition-add-container">
                <div className="tag-group">
                    <TweenOneGroup
                        enter={{
                            scale: 1,
                            opacity: 0,
                            type: 'from',
                            duration: 300,
                        }}
                        leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                        appear={false}>
                        {tags.map(tag => (
                            <span key={tag} style={{ display: 'inline-block' }}>
                                <Tag
                                    closable
                                    color="#0168B3"
                                    onClose={e => {
                                        e.preventDefault();
                                        this.handleClose(tag);
                                    }}>
                                    {tag}
                                </Tag>
                            </span>
                        ))}
                        {this.state.tagsInputVisible && (
                            <Input
                                ref={this.tagsInputRef}
                                type="text"
                                size="middle"
                                style={{ width: 116 }}
                                value={this.state.tagsInputValue}
                                onChange={this.handleTagsInputChange}
                                onBlur={this.handleTagsInputConfirm}
                                onPressEnter={this.handleTagsInputConfirm}
                            />
                        )}
                        {!this.state.tagsInputVisible && (
                            <Tag onClick={this.showTagsInput} className="site-tag-plus">
                                <PlusOutlined /> 添加新标签
                            </Tag>
                        )}
                    </TweenOneGroup>
                </div>
                <div>
                    <TextArea rows={4} placeholder="请输入现场条件内容" onChange={this.handleInputChange} />
                </div>
                <div className="figure-add-group">
                    <ImgCrop aspect={4 / 3} modalTitle="图片裁剪">
                        <Upload
                            action={figureUploadUrl}
                            listType="picture-card"
                            fileList={this.state.fileList}
                            onPreview={this.handlePreview}
                            onChange={this.handleFileChange}
                        >
                            {this.state.fileList.length >= 5 ? null : (
                                <div>
                                    <PlusOutlined />
                                    <div className="ant-upload-text">上传照片</div>
                                </div>
                            )}
                        </Upload>
                    </ImgCrop>
                </div>
                <div className="operations" style={{ textAlign: "right" }}>
                    {this.props.isEditAdding && (
                        <Space size="large">
                            <Button onClick={this.onSave} type="primary" loading={this.state.saveLoading}>保存</Button>
                            <Button onClick={this.props.onCancelClick}>取消</Button>
                        </Space>)}
                </div>

                <Modal
                    title="图片详览"
                    width={800}
                    footer={null}
                    visible={this.state.previewVisible}
                    //   onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <img width="100%" alt="照片" src={this.state.previewImage} />
                </Modal>
            </div>
        )
    }
}
