import { observable, action } from "mobx";
import { user } from "@/interface/user";
import { ProjectDisplayType } from "@/routes/project-list/project-list";
import appStore, { PROJECT_DISPLAY_STYLE, SITE_DISPLAY_STYLE, USER_LOGIN } from "./app-store";
import { SiteDisplayType } from "@/routes/sites/site-manage";

class UserStore {
    @observable _userInfo: user;
    @action setUserInfo = (value: user) => {
        this._userInfo = value;
        if (value) {
            this.setEnterpriseId(value.enterprise_id);
        }
    }

    @observable _enterpriseId: number;
    @action setEnterpriseId = (value: number) => {
        this._enterpriseId = value;
    }

    @observable _isLogin: boolean = appStore.get(USER_LOGIN) || false;
    @action setIsLogin = (value: boolean) => {
        appStore.set(USER_LOGIN, value);
        this._isLogin = value;
    }

    @observable _projectListDisplay: ProjectDisplayType;
    @action setProjectListDisplay = (value) => {
        appStore.set(PROJECT_DISPLAY_STYLE, value)
        this._projectListDisplay = value;
    }

    @observable _projectSiteDisplay: SiteDisplayType;
    @action setProjectSiteDisplay = (value) => {
        appStore.set(SITE_DISPLAY_STYLE, value)
        this._projectSiteDisplay = value;
    }
    
}

export default UserStore;